// region: base elements

html, body {
    width:  100%;
    height: 100%;
}

html {
    box-sizing:              border-box;
    font-size:               16px;
    hyphens:                 manual;
    text-size-adjust:        100%;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing:  subpixel-antialiased;
    text-rendering:          optimizeLegibility;

    @media (min-width: 1401px) {font-size: 20px;}
    @media (max-width: 1400px) {font-size: 19px;}
    @media (max-width: 1024px) {font-size: 18px;}
    @media (max-width: 768px) {font-size: 17px;}
    @media (max-width: 520px) {font-size: 16px;}
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    @include font-weight-variable(400);
    overflow-x:         hidden;
    overflow-y:         scroll;
    -ms-overflow-style: none;
    background-color:   #ffffff;
    font-family:        $font-montserrat;
    color:              $hiper-blue;
    line-height:        1.6;
    letter-spacing:     var(--letter-spacing-small);
    opacity:            1;
    visibility:         visible;
}

p, li, th, td {
    margin:         0 auto;
    padding:        0;
    padding-bottom: .7em;
    line-height:    1.6;
}

p:last-child {
    padding-bottom: 0;
}

.align-center {
    text-align: center;
}

a {
    border-bottom:   2px solid rgba($hiper-red, .75);
    color:           inherit;
    text-decoration: none;
    transition:      .2s all $ease-in-out-cubic;

    &:hover {
        border-bottom-color: $color-transparent-red;
        color:               $hiper-red;
    }

    &:focus {
        outline: 5px auto $hiper-seablue;
    }
}

.bullet-list {
    li {
        position:       relative;
        padding-bottom: .5em;
        padding-left:   1em;
    }

    li::before {
        @include  font-weight-variable(900);
        position: absolute;
        left:     0;
        color:    $hiper-red;
        content:  '\2022';
    }
}

hr {
    height:           1px;
    border:           0;
    background-color: $hiper-gray;

    @at-root .content--slim & {
        max-width: 28em;
    }

    @media (min-width: 521px) {
        margin:    2em auto;
        max-width: calc(100% - 4em);
    }

    @media (max-width: $phone) {
        margin:    1em auto;
        max-width: calc(100% - 2em);
    }
}

strong, b {
    @include font-weight-variable(600);
}

.fw-500, b.fw-500, strong.fw-500 {
    @include font-weight-variable(500);
}

.fw-700, b.fw-700, strong.fw-700 {
    @include font-weight-variable(700);
}

.fw-800, b.fw-800, strong.fw-800 {
    @include font-weight-variable(800);
}

u {
    text-decoration:       underline;
    text-decoration-color: inherit;
    text-underline-offset: .15em;
}

img {
    max-width: 100%;
}

pre {
    display:          block;
    padding:          5px;
    background-color: rgba(white, .5);
    font-family:      $font-monaco;
    font-size:        16px;
    font-weight:      normal;
    text-align:       left;
}

// endregion: base elements

// region: tables

table {
    width:         100%;
    margin:        auto;
    border-radius: .5em;
}

th, td {
    padding:    .5em 1em;
    border:     0 solid transparent;
    text-align: left;

    @media (max-width: $phone) {
        padding: .2em .4em;
    }
}

th {
    background: black;
    color:      white;
}

tr {
    $border-radius:    .5em;
    $border-thickness: 2px;

    th:first-child,
    td:first-child {
        border-left-width: $border-thickness;
    }

    th:last-child,
    td:last-child {
        border-right-width: $border-thickness;
    }

    &:first-child {
        th, td {
            border-top-width: $border-thickness;

            &:first-child {
                border-radius: $border-radius 0 0 0;
            }

            &:last-child {
                border-radius: 0 $border-radius 0 0;
            }

            &:only-child {
                border-radius: $border-radius $border-radius 0 0;
            }
        }
    }

    &:last-child {
        th, td {
            border-bottom-width: $border-thickness;

            &:first-child {
                border-radius: 0 0 0 $border-radius;
            }

            &:last-child {
                border-radius: 0 0 $border-radius 0;
            }

            &:only-child {
                border-radius: 0 0 $border-radius $border-radius;
            }
        }
    }

    &:only-child {
        th, td {
            border-top-width:    $border-thickness;
            border-bottom-width: $border-thickness;

            &:first-child {
                border-radius: $border-radius 0 0 $border-radius;
            }

            &:last-child {
                border-radius: 0 $border-radius $border-radius 0;
            }

            &:only-child {
                border-radius: $border-radius;
            }
        }
    }
}

.table--plain {
    $border-radius:    .5em;
    $border-thickness: 2px;

    margin-bottom:  1em;
    font-family:    $font-cervo;
    font-weight:    400;

    td, th {
        padding:      em(5px) em(10px);
        border-color: $hiper-seablue;
        font-size:    1em;
        color:        inherit;
    }

    tr:first-child {
        th, td {
            border-top-width: $border-thickness;
        }

        th:first-child, td:first-child {
            border-left-width: $border-thickness;
            border-radius:     $border-radius 0 0 0;
        }

        th:last-child, td:last-child {
            border-right-width: $border-thickness;
            border-radius:      0 $border-radius 0 0;
        }

        th:only-child, td:only-child {
            border-left-width:  $border-thickness;
            border-right-width: $border-thickness;
            border-radius:      $border-radius $border-radius 0 0;
        }
    }

    tr:last-child {
        th, td {
            border-bottom-width: $border-thickness;
        }

        th:first-child, td:first-child {
            border-left-width: $border-thickness;
            border-radius:     0 0 0 $border-radius;
        }

        th:last-child, td:last-child {
            border-right-width: $border-thickness;
            border-radius:      0 0 $border-radius 0;
        }

        th:only-child, td:only-child {
            border-left-width:  $border-thickness;
            border-right-width: $border-thickness;
            border-radius:      0 0 $border-radius $border-radius;
        }
    }

    tr:only-child {
        th:first-child, td:first-child {
            border-radius: $border-radius 0 0 $border-radius;
        }

        th:last-child, td:last-child {
            border-radius: 0 $border-radius $border-radius 0;
        }

        th:only-child, td:only-child {
            border-width:  $border-thickness;
            border-radius: $border-radius;
        }
    }

    tr:nth-child(even) {
        td, th {
            background-color: lighten($hiper-gray, 10%);
        }
    }
}

// endregion: tables

// region: headings

.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding:        0 0 .2rem 0;
    font-family:    $font-cervo;
    text-align:     center;
    text-transform: uppercase;
    font-weight:    300;
    line-height:    1.1;
    letter-spacing: var(--letter-spacing-big);

    &.heading--montserrat,
    &.heading--paragraph {
        font-family:    $font-montserrat;
        @include        font-weight-variable(600);
        line-height:    1.25;
        text-transform: none;
        letter-spacing: normal;
    }

    &.heading--paragraph {
        @include     font-weight-variable(400);
        line-height: 1.6;
    }

    a {
        border: 0;
    }

    .no-wrap {
        white-space: nowrap;
    }

    @media (max-width: $phone) {
        --letter-spacing-big: -.05rem;
    }
}

h1.heading--montserrat {font-size: 1.2em;}

h1, h2, h3, h4, h5, h6 {
    b, strong {font-weight: 400;}

    &.heading--montserrat b,
    &.heading--montserrat strong {
        @include font-weight-variable(800);
    }

    &.heading--paragraph b,
    &.heading--paragraph strong {
        @include font-weight-variable(600);
    }
}

h2 {
    font-size: 2.5em;

    em {
        color: inherit;
    }

    &.heading--montserrat {font-size: 1.2em;}
}

h3 {
    padding-top: 1rem;
    font-size:   1.5em;

    &.heading--montserrat {font-size: 1.2em;}
}

h4 {
    padding-top: 1rem;
    font-size:   1.2em;
}

.heading--bigger {
    font-size: em(56px);

    &.heading--montserrat,
    &.heading--paragraph {font-size: 2em;}

    @media (max-width: $phone) {
        font-size: em(42px);

        &.heading--montserrat,
        &.heading--paragraph {font-size: 1.8em;}
    }
}

.heading--big {
    font-size: em(38px);

    &.heading--montserrat,
    &.heading--paragraph {font-size: 1.6em;}
}

.heading--medium {
    font-size: em(34px);

    &.heading--montserrat,
    &.heading--paragraph {font-size: 1.2em;}
}

.heading--small {
    font-size: em(30px);

    &.heading--montserrat,
    &.heading--paragraph {font-size: 1em;}

    @media (max-width: $phone) {
        --letter-spacing-big: -.03rem;
    }
}

.heading--smaller {
    font-size: em(24px);

    @media (max-width: $phone) {
        --letter-spacing-big: -.02rem;
    }
}

.heading--white {
    color: white;
}

.heading--red {
    color: $hiper-red;
}

.heading--blue {
    color: $hiper-blue;
}

.heading--seagreen {
    color: $hiper-seagreen;
}

.heading--left {
    text-align: left;
}

.heading__title {
    display:        block;
    margin-top:     .4rem;
    padding-bottom: em(10px, 16 * em(44px));
    font-family:    $font-montserrat;
    text-transform: none;
    font-size:      1.2rem;
    font-weight:    400;
    line-height:    1.4;
    letter-spacing: var(--letter-spacing-medium);

    .heading--bigger & {
        font-size: 1.3rem;

        @media (max-width: $phone) {
            margin-top: em(5px, 16 * em(22px));
            color:      inherit;
        }
    }

    .heading--small & {
        font-size: 1.1rem;

        @media (max-width: $phone) {
            margin-top: em(5px, 16 * em(22px));
            font-size:  1rem;
            color:      inherit;
        }
    }

    .heading--smaller & {
        font-size: 1.1rem;

        @media (max-width: $phone) {
            margin-top: em(5px, 16 * em(22px));
            font-size:  1rem;
            color:      inherit;
        }
    }

    .heading--montserrat & {
        @include   font-weight-variable(400);
        font-size: 1.1rem;

        @media (max-width: $phone) {
            margin-top: em(5px, 16 * em(22px));
            font-size:  1rem;
            color:      inherit;
        }
    }
}

.heading--tight {
    letter-spacing: var(--letter-spacing-small-always);
}

.heading--line-height {
    line-height: 1.2;
}

.hiper-text-stroke {
    @include hiper-text-stroke;

    &::after {
        background: linear-gradient(77.5deg, rgba(237, 19, 90, 0) 0%, rgba(237, 19, 90, 1) 80%);
    }
}

.hiper-text-stroke-white {
    @include hiper-text-stroke;

    &::after {
        background: linear-gradient(77.5deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 80%);
    }
}

// endregion: headings

// region: tech colors

.fiber {
    .tech-color { color:      $hiper-fiber; }
    .tech-bg    { background: $hiper-fiber; }
}

.coax {
    .tech-color { color:      $hiper-coax; }
    .tech-bg    { background: $hiper-coax; }
}

.dsl {
    .tech-color { color:      $hiper-dsl; }
    .tech-bg    { background: $hiper-dsl; }
}

.mbb {
    .tech-color { color:      $hiper-mbb; }
    .tech-bg    { background: $hiper-mbb; }
}

// endregion: tech colors

// region: grid

.grid {
    display:         flex;
    align-items:     center;
    justify-content: space-evenly;
    gap:             2em;

    > .icon {
        display: block;
    }
}

.grid__item-1 {
    flex: 1 1 0%;
}

.grid--small {
    display:         flex;
    align-items:     center;
    justify-content: space-evenly;
    gap:             1em;
}

.grid-triple,
.grid-triple-with-icons,
.grid-triple-with-count {
    align-items:     stretch;
    counter-reset:   grid;
    list-style-type: none;

    li {
        flex:          1 1 0px;
        border-radius: em(3px);
        padding:       em(15px) em(10px) em(7px) em(10px);

        & > :last-child {
            padding-bottom: 0;
        }
    }

    @at-root {
        $circle-size: 50px;

        .grid-triple-with-icons .icon,
        .grid-triple-with-count li::before {
            display: block;
            width:   em($circle-size);
            height:  em($circle-size);
            margin:  auto;
        }

        .grid-triple-with-count li::before {
            $fz:               40px;
            counter-increment: grid;
            content:           '';
            content:           counter(grid);
            font-family:       $font-cervo;
            font-size:         em($fz);
            width:             em($circle-size, $fz);
            border-radius:     em($circle-size / 2, $fz);
            text-align:        center;
            line-height:       em($circle-size, $fz);
            font-weight:       400;
            height:            em($circle-size, $fz);
        }
    }
}

.grid-triple,
.grid-triple-with-icons,
.grid-triple-with-count {
    $box-background: $hiper-pastel-lightblue;
    $box-color:      $hiper-blue;

    @media (max-width: 660px) {
        flex-direction: column;

        li {
            padding: 1em;
            margin:  0 0 1em 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.grid-row-mobile {
            li {
                position: relative;

                &::before,
                .icon {
                    position:  absolute;
                    transform: translateY(-50%);
                    top:       50%;
                }

                &::before {
                    left:        .4em;
                    padding-top: .075em;
                }

                .icon {
                    left: 1em;
                }

                h3 {
                    text-align: left;
                }
            }
        }
    }

    li {
        background: $box-background;
        color:      $box-color;
    }

    &.no-boxes {
        li {
            background: transparent;
        }
    }

    &.darkteal p:not(.usp-svg-image) {
        color: #ffffff;
    }

    @at-root {
        $circle-bg:    $hiper-blue-rich;
        $circle-color: $box-background;

        .grid-triple-with-count li::before {
            background: $circle-bg;
            color:      $circle-color;
        }

        .grid-triple-with-icons .icon {
            fill:  $circle-color;
            color: $circle-bg;
        }

        .grid-triple-with-count.grid-red li::before {
            background: $hiper-red;
            color:      #ffffff;
        }

        .grid-triple-with-icons.grid-red .icon {
            fill:  #ffffff;
            color: $hiper-red;
        }
    }
}

.grid-triple-with-icons,
.grid-triple-with-count {
    li {
        .heading:first-of-type {
            padding-top: em(10px);
        }
    }

    @media (max-width: 660px) {
        &.grid-row-mobile {
            li {
                padding-left: 5.125em;

                .heading:first-of-type {
                    padding-top: .35em;
                }
            }
        }
    }

    li {
        .heading {
            color: $hiper-blue;
        }
    }

    &.darkteal h3 {
        color: #ffffff;
    }
}

// endregion: grid

// region: menugrid

.menugrid {
    display:         flex;
    justify-content: center;
    align-items:     center;

    &.nowrap {
        flex-wrap: nowrap;
    }

    .item {
        display:          flex;
        flex-direction:   row;
        justify-content:  flex-start;
        align-items:      center;
        border:           0;
        border-radius:    .5em;
        background-color: #ffffff;
        box-shadow:       var(--box-shadow);
        transition:       box-shadow .2s $ease-in-out-cubic;

        .icon {
            flex-shrink: 0;
            display:     block;
            color:       transparent;
            fill:        $hiper-blue;
            transition:  fill .2s $ease-in-out-cubic;
        }

        .headline {
            display:        inline;
            margin:         0;
            border-bottom:  solid 2px rgba($hiper-red, 0);
            font-family:    $font-cervo;
            line-height:    1;
            color:          $hiper-blue;
            hyphens:        manual;
            font-weight:    300;
            letter-spacing: var(--letter-spacing-big);
            text-transform: uppercase;
            transition:     color .2s $ease-in-out-cubic,
                            border .2s $ease-in-out-cubic;
        }

        &:hover {
            box-shadow: var(--box-shadow-hover);

            .headline {
                border-bottom-color: $hiper-red;
                color:               $hiper-red;
            }

            .icon {fill: $hiper-red;}
        }
    }

    @media (min-width: 521px) {
        flex-wrap:   wrap;
        gap:         .5em;
        align-items: flex-start;

        .item {
            padding: 0 .75em 0 0;

            .icon {
                width:  3em;
                height: 3em;
            }

            .headline {
                padding:   .25em 0 0;
                font-size: 1.2em;
            }
        }
    }

    @media (max-width: $phone) {
        flex-wrap: wrap;
        gap:       .25em;

        .item {
            flex:    calc(50% - .25em) 1 1;
            gap:     .25em;
            padding: 0 .75em 0 .5em;
            width:   100%;

            .icon {
                width:  4em;
                height: 4em;
            }

            .headline { padding: .3em 0 0;}
        }
    }

    @media (max-width: 870px) {
        .item {
            font-size: .9em;

            .icon {font-size: .9em;}
        }
    }

    @media (max-width: 600px) {
        &.nowrap {
            flex-direction: column;
            align-items:    center;
        }
    }

    @media (max-width: 425px) {
        .item {font-size: .8em;}
    }
}

// endregion: menugrid

// region: flex

.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-center {
    justify-content: center;
    align-items:     center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-phone-column {
    flex-direction: row;

    @media (max-width: $phone) {
        flex-direction: column;
    }
}

.flex-phone-wrap {
    flex-wrap: nowrap;

    @media (max-width: $phone) {
        flex-wrap: wrap;
    }
}

// endregion: flex

// region: button

button {font: inherit;}

.button {
    display:          inline-block;
    padding:          .2em .75em;
    border:           0;
    border-radius:    .5rem;
    background-color: $hiper-red;
    font-family:      $font-cervo;
    font-weight:      300;
    color:            #ffffff;
    font-size:        1.2em;
    text-align:       center;
    text-decoration:  none;
    letter-spacing:   .05rem;
    text-transform:   uppercase;
    transition:       .2s all $ease-in-out-cubic;
    cursor:           pointer;

    @media (max-width: $phone) {
        letter-spacing: 0;
    }

    &:hover,
    &:focus {
        background-color: $hiper-darkred;
        color:            #ffffff;
        text-decoration:  none;

        .icon {color: transparent;}
    }

    &.with-icon {
        display:         inline-flex;
        justify-content: center;
        align-items:     center;
        gap:             .5em;

        .icon {
            display:     inline-block;
            width:       1.5em;
            height:      1.5em;
            flex-shrink: 0;
            flex-grow:   0;
            color:       transparent;
            fill:        #ffffff;
        }
    }

    &.blue {
        background-color: $hiper-blue;

        &:hover,
        &:focus {
            background-color: shade($hiper-blue, 10%);
        }
    }

    &.teal {
        background-color: $hiper-teal;

        &:hover,
        &:focus {
            background-color: $hiper-darkteal;
        }
    }

    &.gray {
        background-color: $hiper-lightergray;
        color:            $hiper-blue;
        box-shadow:       inset 0 0 0 1px $hiper-darkgray;

        .icon {fill: $hiper-blue;}

        &:hover,
        &:focus {
            background-color: $hiper-lightgray;

            .icon {fill: $hiper-blue;}
        }
    }

    &.button--montserrat {
        @include        font-weight-variable(500);
        padding:        .4em .75em;
        font-family:    $font-montserrat;
        font-size:      1em;
        text-transform: none;
        letter-spacing: normal;
    }

    &.big {
        padding:   .25em .75em;
        font-size: 1.4em;

        &.button--montserrat {
            padding:   .5em 1em;
            font-size: 1.2em;
        }
    }

    &.rounded {
        border-radius: 2em;
    }

    &.center {
        display:      block;
        margin-left:  auto;
        margin-right: auto;
        width:        fit-content;
    }
}

// endregion: button

// region: wrap-thing

.wrap-thing {
    overflow:      hidden;
    border-radius: 1em;

    img {display: block;}
}

.wrap-thing-bar {
    display:         flex;
    align-items:     center;
    justify-content: space-evenly;
    gap:             .5em;
    height:          4rem;
    font-family:     $font-cervo;
    font-size:       1.6em;
    line-height:     1.1;
    font-weight:     300;
    text-transform:  uppercase;
    color:           white;
    background:      $hiper-teal;

    a {
        display:         flex;
        justify-content: center;
        align-items:     center;
        border-bottom:   0;
        letter-spacing:  .05rem;

        &:hover {
            color: rgba(#ffffff, .75);

            .icon {
                fill: rgba(#ffffff, .75);
            }
        }
    }

    .icon {
        display:    inline-block;
        width:      1.5em;
        height:     1.5em;
        transition: inherit;
        fill:       #ffffff;
        color:      transparent;
    }
}

// endregion: wrap-thing

// region: icon-link

.icon-link {
    border:          0;
    text-decoration: none;

    > .icon {
        display:        inline-block;
        width:          em(18px);
        height:         em(18px);
        margin-left:    em(4px);
        vertical-align: middle;
        transition:     inherit;
    }

    > .text {
        border-bottom: em(2px) solid rgba($hiper-red, .75);
        transition:    inherit;
    }

    &:hover {
        > .icon {
            color: $hiper-blue;
        }

        > .text {
            border-color: $color-transparent-dark;
            color:        $hiper-red;
        }
    }
}

.icon-link-left {
    > .icon {
        margin-right: em(4px);
        margin-left:  0;
    }
}

.icon-link-rotate-180 {
    > .icon svg {
        transform: rotate(180deg);
    }
}

// endregion: icon-link

// region: image-link

.image-link {
    display:       block;
    position:      relative;
    border-bottom: 0;
    border-radius: em(4px);
    text-align:    center;

    img,
    .image-link--overlay-label,
    .image-link--text {
        text-transform: uppercase;
        font-family:    $font-cervo;
        transition:     .2s $ease-in-out-cubic;
    }

    img {
        transition-property: filter;
        display:             block;
        padding:             em(5px);
        border-radius:       em(5px);
        filter:              drop-shadow(2px 2px 4px rgba(#000000, .3));
    }

    .image-link--overlay-label {
        transition-property: background-color;
        border-radius:       em(3px);
        position:            absolute;
        top:                 50%;
        left:                50%;
        transform:           translate(-50%, -50%);
        padding:             .5em .8em;
        font-size:           1em;
        line-height:         .8;
        font-weight:         400;
        background-color:    $hiper-blue;
        color:               #ffffff;
        box-shadow:          0 5px 20px -10px rgba(#000000, .5);

        span {
            font-size:   2.6em;
            line-height: .8;
        }

        &::after {
            box-shadow: 0 0 25px 5px rgba(#000, .2);
            display:    block;
            height:     0;
            content:    '';
            position:   absolute;
            bottom:     0;
            left:       .5em;
            width:      calc(100% - 1em);
            z-index:    -1;
        }
    }

    .image-link--text {
        transition-property: border, background-color, color;
        display:             inline-block;
        padding:             0 0 em(5px) 0;
        letter-spacing:      .03rem;
    }

    &:hover,
    &:focus {
        background: lighten($hiper-gray, 8%);

        img {
            filter: drop-shadow(0 0 0 rgba(#000000, 0));
        }

        .image-link--overlay-label,
        .image-link--text {
            text-decoration: none;
        }

        .image-link--overlay-label {
            background-color: $hiper-red;
        }

        .image-link--text {
            border-bottom-color: $color-transparent-dark;
            color:               $hiper-red;
        }
    }
}

// endregion: image-link

.thin-slash {
    font-weight: 300;
}

.from-to {
    display:     inline-block;
    position:    relative;
    bottom:      em(3px);
    margin:      0 em(-3px);
    font-size:   .6em;
    line-height: .8em;
}

.strikethrough-price {
    display:  inline-block;
    position: relative;

    &::before {
        content:          '';
        position:         absolute;
        top:              50%;
        left:             0;
        transform:        translate(-5%, -50%) rotate(-10deg);
        width:            110%;
        height:           2px;
        border-radius:    1px;
        background-color: $hiper-red;
    }
}

.section-white,
.section-teal {
    .align-center {text-align: center;}
}

.section-white {
    background-color: #ffffff;
}

.section-teal {
    background-color: $hiper-teal;

    p {color: #ffffff;}
}

.tdc-fiber-delivery-warning {
    max-width:     30em;
    margin:        .5em auto .75em auto;
    background:    #ffffff;
    color:         $hiper-red;
    padding:       .5em 1em;
    border-radius: .5em;
}

// Sådan VILLE! de have den, sorry Kamil
.syd-energi-delivery-warning {
    margin-bottom:    .7em;
    background-color: $hiper-lightergray;
    padding:          .5em 1em;
    border-radius:    .5em;
}

// region: accordion

.accordion-container {
    border-bottom: 1px solid $hiper-gray;
}

.accordion {
    $transition: .3s $ease-in-out-cubic;

    display:         flex;
    flex-direction:  row;
    justify-content: space-between;
    align-items:     center;
    cursor:          pointer;
    width:           100%;
    border:          none;
    border-top:      1px solid $hiper-gray;

    &, &:hover {
        color: $hiper-blue;
    }

    h1, h2, h3, h4, h5, h6 {
        padding: 0;

        &.title--montserrat {
            @include        font-weight-variable(500);
            font-family:    $font-montserrat;
            font-size:      1em;
            text-transform: none;
            letter-spacing: normal;
        }
    }

    .title:not(.title--montserrat), .symbol {
        font-size: 1.5em;
    }

    .title {
        margin-right: 1em;
        text-align:   left;
        line-height:  1.2;
        transition:   color .1s $ease-in-out-cubic;

        &:not(.title--montserrat) {
            font-family:    $font-cervo;
            text-transform: uppercase;
            font-weight:    300;
        }

        &.title--montserrat {
            @include font-weight-variable(500);

            b, strong {
                @include font-weight-variable(650);
            }
        }
    }

    .symbol {
        height:     1em;
        position:   relative;
        transition: transform $transition;

        span {
            position:         absolute;
            display:          block;
            background-color: $hiper-blue;
            top:              50%;
            left:             0;
            transition:       transform $transition,
                              background-color $transition;
        }
    }
}

.accordion-content-container {
    $transition-delay: .2s;
    $transition:       $transition-delay $ease-in-out-cubic;

    text-align:        left;
    max-height:        0;

    &:not(.accordion-content-container--footer) {
        overflow:       hidden;
        opacity:        0;
        pointer-events: none;
        transition:     max-height $transition,
                        opacity $transition;

        &.active {
            opacity:        1;
            pointer-events: all;

            &.no-js-height {max-height: 999em;}
        }
    }
}

details summary {
    cursor: pointer;
}

@media (min-width: 521px) {
    .accordion {
        .title {
            max-width: calc(100% - 1em - 26px);
        }

        .symbol {
            width: 26px;

            span {
                width:         16px;
                height:        2px;
                border-radius: 1px;

                &:first-child {transform: translate(0, -50%)    rotate(45deg);}
                &:last-child  {transform: translate(10px, -50%) rotate(-45deg);}
            }
        }

        &.active {
            .symbol span {
                &:first-child {transform: translate(10px, -50%) rotate(45deg);}
                &:last-child  {transform: translate(0, -50%)    rotate(-45deg);}
            }
        }
    }

    .accordion:not(.accordion--footer) {
        padding: .8em 0;

        &:hover:not(.active) {
            .title       {color:            rgba($hiper-blue, .75);}
            .symbol span {background-color: rgba($hiper-blue, .75);}
        }
    }

    .accordion-content {
        padding: 0 1em 1em 1em;
    }
}

@media (max-width: $phone) {
    .accordion {
        padding: .5em 0;

        .title {
            max-width: calc(100% - 1em - 20px);
        }

        .symbol {
            width: 20px;

            span {
                width:  12px;
                height: 1px;

                &:first-child {transform: translate(0, -50%)   rotate(45deg);}
                &:last-child  {transform: translate(8px, -50%) rotate(-45deg);}
            }
        }

        &.active {
            .symbol span {
                &:first-child {transform: translate(8px, -50%) rotate(45deg);}
                &:last-child  {transform: translate(0, -50%)   rotate(-45deg);}
            }
        }
    }

    .accordion-content {
        padding: 0 .5em 1em .5em;
    }
}

// endregion: accordion

// region: job

.job-container {
    margin-bottom:    1.25em;
    padding:          1em;
    background-color: #ffffff;
    border-radius:    1em;

    a.job-link {
        display: block;
        border:  none;
    }

    h2 {
        padding-bottom: .7rem;
    }

    h3 {
        padding: .35rem 0 0 0;
    }

    p, li {
        font-size: .9em;
    }

    .cta {
        background-color: $hiper-red;
        padding:          .4em .4em .4em .8em;
        border-radius:    .5em;
        display:          flex;
        justify-content:  space-between;
        align-items:      center;

        p {
            color:     #ffffff;
            margin:    0;
            padding:   0;
        }

        .icon {
            width:  2em;
            height: 2em;
            color:  transparent;
            fill:   #ffffff;
        }
    }
}

// endregion: job

// region: coverage-map
.coverage-map-container {
    display:        flex;
    flex-direction: column;
    align-items:    center;
    width:          100%;

    .coverage-buttons, .coverage-map {
        max-width: 26em;
    }

    .coverage-buttons {
        display:         flex;
        flex-flow:       row wrap;
        justify-content: center;
        align-items:     center;
        gap:             .2em;
        margin-bottom:   1em;

        p {
            @include        font-weight-variable(500);
            display:        block;
            width:          100%;
            text-align:     center;
            padding-bottom: 0;
        }

        button {
            border-radius:    .75em;
            padding:          .1em .5em;
            cursor:           pointer;
            font-family:      $font-cervo;
            font-size:        1em;
            font-weight:      300;
            text-transform:   uppercase;
            letter-spacing:   .015em;
            color:            $hiper-blue;
            background-color: #ffffff;
            border:           none;
            box-shadow:       var(--box-shadow-small);
            transition:       color .2s ease,
                              background-color .2s ease,
                              box-shadow .2s ease;

            &:hover:not(.active) {
                box-shadow: var(--box-shadow-small-hover);
            }

            &.active {
                color: #ffffff;

                &.fiber {background-color: $hiper-fiber;}
                &.coax  {background-color: $hiper-coax;}
                &.dsl   {background-color: $hiper-dsl;}
                &.mbb   {background-color: $hiper-mbb;}
            }
        }

        &.coverage-buttons-primary {
            p      {font-size: 1.2em;}
            button {font-size: 1.4em;}
        }

        @media (max-width: $phone) {
            button {
                padding-top:   .2em;
                border-radius: .8em;
            }

            &.coverage-buttons-secondary button {
                font-size: 1.1em;
            }
        }
    }

    &.fiber-only .coverage-buttons-primary {display: none;}
    &:not(.fiber-only) .coverage-buttons-secondary .coverage-fiber {display: none;}

    .coverage-map {
        filter: drop-shadow(var(--box-shadow-small));
        width:  100%;

        svg {
            width:  100%;
            height: 100%;

            g {
                transition: opacity .2s ease;
            }

            .map-blank {
                fill: #ffffff;
            }

            .coverage-region {
                opacity: 0;

                &.active {opacity: 1;}
            }

            .coverage-fiber,
            .coverage-fiber-ef,
            .coverage-fiber-e,
            .coverage-fiber-f,
            .coverage-fiber-gc,
            .coverage-fiber-ne,
            .coverage-fiber-n,
            .coverage-fiber-rah,
            .coverage-fiber-sef,
            .coverage-fiber-tdc,
            .coverage-fiber-tm {
                fill: $hiper-fiber;
            }

            .coverage-coax {
                fill: $hiper-coax;
            }

            .coverage-dsl {
                fill: $hiper-dsl;
            }
        }
    }
}

#coverage-map-mbb {
    margin-bottom: .5em;
    width:         100%;
    border-radius: 1em;
    box-shadow:    var(--box-shadow);

    @media (min-width: 521px) {
        height: 600px;
    }

    @media (max-width: $phone) {
        height: calc(100vh - 12em);
    }
}

.coverage-map-disclaimer {
    font-size:  .7em;
    text-align: center;
    color:      rgba($hiper-blue, .75);
}

// endregion: coverage-map

// region: alert
.alert {
    text-align:    center;
    padding:       1em;
    margin-bottom: 1em;
    border-radius: .1875em;
    color:         white;

    &.error {
        background: $hiper-darkred;
    }

    &.success {
        background: $hiper-darkgreen;
    }
}

// endregion: alert

.id-anchor {
    display:    block;
    transform:  translateY(calc(-3.75em - .25em));
    visibility: hidden;
    grid-area:  anchor;
}

.id-anchor-address {
    transform: translateY(calc(-3.75em - 2.5em - .25em));
}

.page-waiting-list-index {
    .waiting-list-permissions-disclaimer {
        display:        block;
        font-size:      .7em;
        line-height:    1.2;
        letter-spacing: var(--letter-spacing-small-always)
    }

    .usp {
        padding: 1em 0;

        .text {
            width: 90%;
        }

        h4 {
            text-align:  left;
            padding-top: 0;
        }

        p {
            font-size: medium;
        }

        img {
            width: 90%;
        }

        @media (max-width: $tablet) {
            .flex {
                flex-direction: column;
            }

            img {
                width: 100%;
            }

            .text {
                width:         100%;
                margin-bottom: 1em;
            }

            h4 {
                padding-top: 1em;
            }
        }

        @media (max-width: $phone) {
            padding: 0 .5em 0 .5em;
        }
    }
}

.page-home,
.page-products,
.page-order {
    .header-steps {
        margin: 1em auto 0;
        max-width:  45em;
    }

    .grid--steps {
        display:         flex;
        justify-content: center;
        flex-flow:       row wrap;
        align-items:     stretch;
        gap:             1em 2em;
        margin:          1em auto;

        .grid--item {
            display:               grid;
            grid-template-columns: 3em auto;
            grid-template-rows:    min-content auto;
            grid-template-areas:   'num num' 'icon text';
            gap:                   .5em 1em;
            padding:               1em;
            width:                 16em;
            border-radius:         1em;
            box-shadow:            var(--box-shadow);

            .number {
                @include      font-weight-variable(600);
                grid-area:    num;
                align-self:   center;
                justify-self: center;
                display:      block;
                font-size:    2em;
                line-height:  1;
                color:        $hiper-red;
                text-align:   center;
            }

            .icon {
                grid-area: icon;
                display:   block;
                width:     3em;
                height:    3em;
            }

            h3, h4 {
                grid-area:  text;
                padding:    0;
                text-align: left;
                hyphens:    manual;

                span {
                    display: block;

                    &:first-child {
                        @include   font-weight-variable(600);
                        font-size: .9em;
                    }

                    &:last-child {
                        font-size: .8em;
                    }
                }
            }
        }
    }
}

// region: privacy page

.privacy,
.cookies {
    .heading--medium {
        padding-top: 1.4rem;

        &:first-of-type {padding-top: 0;}
    }

    .heading--small {padding-top: .7rem;}
}

// end region: privacy page

// region: page not found

.page-not-found-content {
    @media (min-width: 521px) {
        padding: 2em 1em;
    }

    @media (max-width: $phone) {
        padding: 1em;
    }
}

// end region: page not found
