.hero-container {
    display:          flex;
    flex-direction:   column;
    justify-content:  center;
    align-items:      center;
    position:         relative;
    background-color: $hiper-teal;
    gap:              1em;
    padding:          ($height-header-big + 1em) 0 1em;

    &.darkteal {
        background-color: $hiper-darkteal;
    }

    .hero-ribbon {
        $ribbon-color:    $hiper-darkteal;

        display:          flex;
        justify-content:  center;
        align-items:      center;
        width:            100%;
        padding:          .8em .5em;
        border:           none;
        gap:              .5em;
        position:         absolute;
        z-index:          1;
        top:              $height-header-big - 1em;
        left:             0;
        right:            0;
        box-shadow:       0 4px 24px rgba(#000000, .2);
        background-color: $ribbon-color;
        color:            #ffffff;

        h3 {
            font-size:   2.5em;
            font-weight: 400;
            line-height: 1;
            padding:     0;

            @media (max-width: $phone) {
                padding-top: .135em; // vertical center on mobile
            }
        }

        @media (min-width: 901px) {
            .hide-on-large-devices {
                display: none;
            }
        }

        @media (max-width: 900px) {
            .hide-on-small-devices {
                display: none;
            }
        }

        .arrow-container {
            width:      100%;
            text-align: center;
            position:   absolute;
            bottom:     0;
            z-index:    1;

            .arrow-down {
                width:        0;
                height:       0;
                position:     absolute;
                transform:    translateX(-50%);
                left:         50%;
                top:          0;
                border-left:  20px solid transparent;
                border-right: 20px solid transparent;
                border-top:   20px solid $ribbon-color;
            }
        }

        &.teal {
            $ribbon-color: $hiper-teal;

            background-color: $ribbon-color;
            .arrow-container .arrow-down {border-top-color: $ribbon-color;}
        }

        &.lightblue {
            $ribbon-color: $hiper-lightblue;

            background-color: $ribbon-color;
            .arrow-container .arrow-down {border-top-color: $ribbon-color;}
        }

        &.blue {
            $ribbon-color: darken($hiper-blue, 2.5%);

            background-color: $ribbon-color;
            .arrow-container .arrow-down {border-top-color: $ribbon-color;}
        }

        &.red {
            $ribbon-color: $hiper-red;

            background-color: $ribbon-color;
            .arrow-container .arrow-down {border-top-color: $ribbon-color;}
        }

        &.hero-ribbon--5g {
            top:      0;
            height:   3em;
            overflow: hidden;

            div {
                --animation-length: 30s;
                --text-2-start:     100%;
                --text-1-end:       calc(var(--text-2-start) * -1);

                @media (min-width: 1801px)                         {--animation-length: 50s;}
                @media (max-width: 1800px)                         {span:nth-child(4) {display: none;}}
                @media (max-width: 1300px) and (min-width: 1001px) {--text-2-start: 125%;}
                @media (max-width: 1000px)                         {span:nth-child(3) {display: none;}}
                @media (max-width: 750px) and (min-width: 601px)   {--text-2-start: 125%;}
                @media (max-width: 600px) and (min-width: 481px)   {--text-2-start: 150%;}
                @media (max-width: 480px)                          {span:nth-child(2) {display: none;}}
                @media (max-width: 350px)                          {--text-2-start: 125%;}
                @media (max-width: 300px)                          {--text-2-start: 150%;}

                position:        absolute;
                display:         flex;
                justify-content: space-around;
                align-items:     center;
                width:           var(--text-2-start);
                pointer-events:  none;

                &:nth-child(1) {
                    animation: text-1-move var(--animation-length) linear infinite;

                    @keyframes text-1-move {
                        0%   {left: 0;}
                        100% {left: var(--text-1-end);}
                    }
                }

                &:nth-child(2) {
                    left:      var(--text-2-start);
                    animation: text-2-move var(--animation-length) linear infinite;

                    @keyframes text-2-move {
                        0%   {left: var(--text-2-start);}
                        100% {left: 0;}
                    }
                }

                span   {@include font-weight-variable(600);}
                strong {@include font-weight-variable(700);}
            }
        }
    }

    .hero-background-wrapper {
        position: absolute;
        top:      0;
        left:     0;
        right:    0;
        bottom:   0;
        width:    100%;
        height:   100%;
        overflow: hidden;
    }

    .hero-background {
        pointer-events: none;
        position:       absolute;
        z-index:        0;

        .hero-image {
            max-width:  32em;
            max-height: 100%;
            width:      auto;
        }

        @media (max-width: 900px) {
            .hero-image {display: none;}
        }
    }

    .mvfp-23 {
        position: absolute;

        @media (min-width: 721px) {
            transform: translate(1em, -50%) rotate(8deg);
            top:       40%;
            left:      55%;
            max-width: 18em;
        }

        @media (max-width: 720px) and (min-width: 521px) {
            right:     2em;
            transform: translate(1em, -50%) rotate(8deg);
            max-width: 16em;
        }

        @media (max-width: $phone) {
            transform: translateX(-50%) rotate(8deg);
            top:       30%;
            left:      75%;
            max-width: 9em;
        }

        @media (max-width: 320px) {
            max-width: 5em;
        }
    }

    .splash-item {
        border-radius:    em(3px);
        position:         absolute;
        color:            #ffffff;
        background-color: $hiper-blue;
        box-shadow:       1px 3px 6px 1px rgba(#000, .3);
        text-align:       center;
        line-height:      1.1;

        & > span {
            font-size: 2em;
        }

        & > span.cervo {
            font-family:    $font-cervo;
            text-transform: uppercase;
            font-weight:    300;
        }

        strong {
            font-size:   2em;
            @include font-weight-variable(600);

            &.cervo {
                font-weight: 400;
            }
        }

        @media (min-width: 721px) {
            transform: translate(1em, -50%) rotate(-15deg);
            top:       40%;
            left:      50%;
        }

        @media (max-width: 720px) {
            transform: rotate(-7.5deg);
            top:       50%;
        }

        @media (max-width: 720px) and (min-width: 521px) {
            right: 2em;
        }

        @media (min-width: 521px) {
            padding: .8em 1.6em;

            .hide-on-large-devices {display: none;}
        }

        @media (max-width: $phone) {
            transform: translateX(-50%) rotate(-7.5deg);
            left:      75%;
            width:     max-content;
            padding:   .5em .5em .3em .5em;

            .hide-on-small-devices {display: none;}

            & > span {
                font-size: 1.6em;
            }
        }

        @media (max-width: 350px) {
            display: none;
        }
    }

    .stamp {
        $stamp-size: 10em;

        width:        $stamp-size;
        height:       $stamp-size;
        position:     absolute;
        z-index:      2;

        &, img {
            max-width:  240px;
            max-height: 240px;
        }

        img {
            position:      absolute;
            width:         $stamp-size;
            height:        $stamp-size;
            border-radius: 50%;
            box-shadow:    1px 3px 6px 3px rgba(#000, .3);
        }

        &.stamp--fly-in {
            &:not(.stamp--triple) img {
                $animation-duration:       .5s;
                animation-timing-function: ease-out;

                animation:                 splashFlyIn $animation-duration;
                -webkit-animation:         splashFlyIn $animation-duration;
                -moz-animation:            splashFlyIn $animation-duration;

                @keyframes splashFlyIn {
                    0%   {transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);}
                    100% {transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);}
                }
                @-webkit-keyframes splashFlyIn {
                    0%   {-webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);}
                    100% {-webkit-transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);}
                }
                @-moz-keyframes splashFlyIn {
                    0%   {-moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);}
                    100% {-moz-transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);}
                }
            }

            &.stamp--triple img {
                $animation-duration:       1.2s;
                animation-timing-function: ease-out;

                &:first-child {
                    $transformX:       -1em;
                    $transformY:       .8em;

                    transform:         translate($transformX, $transformY) rotate(-20deg);
                    -webkit-transform: translate($transformX, $transformY) rotate(-20deg);
                    -moz-transform:    translate($transformX, $transformY) rotate(-20deg);

                    animation:         splashFlyIn1 $animation-duration;
                    -webkit-animation: splashFlyIn1 $animation-duration;
                    -moz-animation:    splashFlyIn1 $animation-duration;

                    @keyframes splashFlyIn1 {
                        0%   {transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-30deg);}
                        40%  {transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-20deg);}
                        100% {transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-20deg);}
                    }
                    @-webkit-keyframes splashFlyIn1 {
                        0%   {-webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-30deg);}
                        40%  {-webkit-transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-20deg);}
                        100% {-webkit-transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-20deg);}
                    }
                    @-moz-keyframes splashFlyIn1 {
                        0%   {-moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-30deg);}
                        40%  {-moz-transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-20deg);}
                        100% {-moz-transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-20deg);}
                    }
                }

                &:nth-child(2) {
                    $transformX:       -.6em;
                    $transformY:       .3em;

                    transform:         translate($transformX, $transformY) rotate(-10deg);
                    -webkit-transform: translate($transformX, $transformY) rotate(-10deg);
                    -moz-transform:    translate($transformX, $transformY) rotate(-10deg);

                    animation:         splashFlyIn2 $animation-duration;
                    -webkit-animation: splashFlyIn2 $animation-duration;
                    -moz-animation:    splashFlyIn2 $animation-duration;

                    @keyframes splashFlyIn2 {
                        0%   {transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);}
                        30%  {transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);}
                        70%  {transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-10deg);}
                        100% {transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-10deg);}
                    }
                    @-webkit-keyframes splashFlyIn2 {
                        0%   {-webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);}
                        30%  {-webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);}
                        70%  {-webkit-transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-10deg);}
                        100% {-webkit-transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-10deg);}
                    }
                    @-moz-keyframes splashFlyIn2 {
                        0%   {-moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);}
                        30%  {-moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);}
                        70%  {-moz-transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-10deg);}
                        100% {-moz-transform: perspective(800px) translate3d($transformX, $transformY, 0) rotate(-10deg);}
                    }
                }

                &:nth-child(3) {
                    animation:         splashFlyIn3 $animation-duration;
                    -webkit-animation: splashFlyIn3 $animation-duration;
                    -moz-animation:    splashFlyIn3 $animation-duration;

                    @keyframes splashFlyIn3 {
                        0%   {transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);}
                        60%  {transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);}
                        100% {transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);}
                    }
                    @-webkit-keyframes splashFlyIn3 {
                        0%   {-webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);}
                        60%  {-webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);}
                        100% {-webkit-transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);}
                    }
                    @-moz-keyframes splashFlyIn3 {
                        0%   {-moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);}
                        60%  {-moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);}
                        100% {-moz-transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);}
                    }
                }
            }
        }

        &.stamp--fly-down img {
            $animation-duration:       .75s;

            animation-timing-function: $ease-out-expo;

            animation:                 splashFlyDown $animation-duration;
            -webkit-animation:         splashFlyDown $animation-duration;
            -moz-animation:            splashFlyDown $animation-duration;

            @keyframes splashFlyDown {
                0%   {transform: translateY(-50vh);opacity: 0;}
                50%  {opacity: 100%;}
                100% {transform: translateY(0);}
            }
            @-webkit-keyframes splashFlyDown {
                0%   {-webkit-transform: translateY(-50vh);opacity: 0;}
                50%  {opacity: 100%;}
                100% {-webkit-transform: translateY(0);}
            }
            @-moz-keyframes splashFlyDown {
                0%   {-moz-transform: translateY(-50vh);opacity: 0;}
                50%  {opacity: 100%;}
                100% {-moz-transform: translateY(0);}
            }
        }
    }

    .counter {
        color:     #ffffff;
        font-size: .9em;

        &:not(.counter--banner) {
            margin-bottom: 1em;
            margin-left:   -1em;
            padding:       .3rem .6rem 0;
            border-radius: .75rem;
            line-height:   1.9em;

            #time {
                padding:          .3rem .7rem;
                border-radius:    .5rem;
                background-color: $hiper-red;
                font-size:        1.1em;
            }
        }

        &.counter--banner {
            position:         absolute;
            top:              0;
            left:             0;
            right:            0;
            padding:          .75em 1em;
            width:            100%;
            background-color: $hiper-red;
            font-size:        1.2em;
            line-height:      1.2;
            text-align:       center;

            #time {
                padding:          0 0 0 .25em;
                border-radius:    0;
                background-color: transparent;
                font-size:        1em;
            }
        }

        #time {
            @include     font-weight-variable(600);
            font-family: $font-montserrat;
        }

        @media (min-width: 521px) {
            .hide-on-large-devices {display: none;}
        }

        @media (max-width: $phone) {
            .hide-on-small-devices {display: none;}

            &:not(.counter--banner) {
                font-size: .75em;

                #time {font-size: 1em;}
            }

            &.counter--banner {
                font-size:  1em;
                box-shadow: var(--box-shadow);

                #time {font-size: 1.2em;}
            }
        }
    }

    .hero-content, .conditions {
        z-index: 1;
    }

    .hero-content {
        width:           100%;
        display:         flex;
        flex-direction:  column;
        justify-content: center;
        align-items:     flex-start;

        h1 {
            margin:      0;
            padding:     0;
            color:       #ffffff;
            text-align:  left;
            line-height: .9;
        }

        h1 > span {
            display: block;
            width:   fit-content;
        }

        .hero-pre-speed {
            padding-bottom: .5em;
        }

        .hero-bullets {
            line-height: 1.6;
        }

        .hero-speed,
        .hero-price {
            font-weight: 400;
            margin-left: -.12em;
        }

        .hero-pre-speed,
        .hero-bullets,
        .hero-pre-price,
        .hero-price-disclaimer {
            @include        font-weight-variable(300);
            margin-left:    -.11em;
            font-family:    $font-montserrat;
            text-transform: none;
        }

        .hero-pre-price {
            padding: .2em 0;
        }

        .hero-price {
            position:    relative;
            white-space: nowrap;

            @at-root .price-comma-dash {
                letter-spacing: -0.15em;
            }

            @at-root .price-md {
                position:       absolute;
                top:            30%;
                right:          -1.2em;
                display:        block;
                font-family:    $font-montserrat;
                font-size:      0.12em;
                white-space:    nowrap;
                letter-spacing: 0;
                text-transform: lowercase;
            }
        }

        .strikethrough-price::before {
            width:            120%;
            height:           .06em;
            border-radius:    3px;
            background-color: $hiper-red;
            border-bottom:    none;
            top:              50%;
            transform:        rotate(-20deg) translate(10%, 50%);
            z-index:          1;
        }
    }

    &.windows .hero-content .hero-price {
        margin-top: -.1em;
    }

    .address-wrap {
        width:            100%;
        background-color: transparent;

        h2 {
            @include        font-weight-variable(500);
            margin:         0 auto;
            font-family:    $font-montserrat;
            text-align:     center;
            text-transform: none;
            line-height:    1.2;
            color:          #ffffff;
            position:       relative;
            z-index:        $z-index-address-picker;

            &.address-button {cursor: pointer;}

            .scroll-down-arrow {
                position:    relative;
                display:     inline-block;
                margin-left: .25em;
                height:      1em;
                width:       1em;

                &::before,
                &::after {
                    content:          '';
                    position:         absolute;
                    top:              0;
                    left:             0;
                    background-color: #ffffff;
                }

                animation:         scroll-down-arrow 1.5s ease-in-out infinite;
                -webkit-animation: scroll-down-arrow 1.5s ease-in-out infinite;
                -moz-animation:    scroll-down-arrow 1.5s ease-in-out infinite;

                @keyframes scroll-down-arrow {
                    0%   {transform: translateY(-.25em);}
                    50%  {transform: translateY(.25em);}
                    100% {transform: translateY(-.25em);}
                }
                @-webkit-keyframes scroll-down-arrow {
                    0%   {-webkit-transform: translateY(-.25em);}
                    50%  {-webkit-transform: translateY(.25em);}
                    100% {-webkit-transform: translateY(-.25em);}
                }
                @-moz-keyframes scroll-down-arrow {
                    0%   {-moz-transform: translateY(-.25em);}
                    50%  {-moz-transform: translateY(.25em);}
                    100% {-moz-transform: translateY(-.25em);}
                }
            }

            @media (min-width: 521px) {
                .scroll-down-arrow {
                    &::before,
                    &::after {
                        width:         16px;
                        height:        2px;
                        border-radius: 1px;
                    }

                    &::before {transform: translateY(18px) rotate(45deg);}
                    &::after  {transform: translate(10px, 18px) rotate(-45deg);}
                }
            }

            @media (max-width: $phone) {
                .scroll-down-arrow {
                    &::before,
                    &::after {
                        width:  12px;
                        height: 1px;
                    }

                    &::before {transform: translateY(10px) rotate(45deg);}
                    &::after  {transform: translate(8px, 10px) rotate(-45deg);}
                }
            }
        }
    }

    .conditions {
        @include     font-weight-variable(400);
        margin:      0 auto;
        color:       #ffffff;
        font-size:   .7em;
        line-height: 1.4;
        text-align:  center;

        span[class^='conditions--convert'] {
            display: none
        }
    }

    .specific-address-popup {
        @include         hidden;
        position:        fixed;
        top:             0;
        left:            0;
        z-index:         999;
        display:         flex;
        justify-content: center;
        align-items:     center;
        width:           100%;
        height:          100%;
        background:      rgba(0, 0, 0, .5);
        backdrop-filter: blur(2px);

        &.visible {
            @include visible;
        }

        button {
            font-family:      'montserrat', 'sans serif';
            font-weight:      300;
            background-color: transparent;
            border:           none;
            padding:          0;
            border-bottom:    2px solid rgba(237, 19, 90, .75);
            color:            inherit;
            text-decoration:  none;
            cursor:           pointer;
        }

        .popup-content {
            position:         relative;
            padding:          2em;
            width:            30em;
            max-width:        calc(100vw - 1em);
            border-radius:    .5em;
            background-color: #ffffff;
            text-align:       center;
        }

        .wl-overlay__close {
            top:       .5rem;
            right:     .75rem;
            font-size: 2em;
        }
    }

    &.hero-container--video {
        .hero-background {
            max-width:  1920px;
            max-height: 1080px;
            width:      auto;
            left:       50%;
            transform:  translateX(-50%);

            video {
                max-height:   1080px;
                height:       100%;
                width:        auto;
                aspect-ratio: 16 / 9;
            }

            &::before {
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            0;
                left:           0;
                right:          0;
                bottom:         0;
                pointer-events: none;
                background:     linear-gradient(0deg, rgba($hiper-teal, 1) 0%, rgba($hiper-teal, 0) 4em);
            }
        }

        @media (min-width: 1921px) {
            .hero-background::after {
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            0;
                left:           0;
                right:          0;
                bottom:         0;
                pointer-events: none;
                background:     linear-gradient(90deg,
                                rgba($hiper-teal, 1) 0%,
                                rgba($hiper-teal, 0) 10%,
                                rgba($hiper-teal, 0) 90%,
                                rgba($hiper-teal, 1) 100%);
            }
        }

        @media (min-width: 1495px) {
            .hero-background {
                top:    -9em;
                height: auto;
            }

            &.hero-container--video-dans .hero-background {
                top: -6em;
            }
        }

        @media (min-width: 721px) and (max-width: 1494px) {
            .hero-background {
                top:    -8em;
                height: calc(100% + 8em);
            }

            &.hero-container--video-dans .hero-background {
                top:    -4em;
                height: calc(100% + 4em);
            }
        }

        @media (min-width: 881px) and (max-width: 1130px) {
            &.hero-container--video-dans .hero-background {
                left: 40%;
            }
        }

        @media (max-width: 720px) {
            .hero-background {
                transform:      translateX(-66%);
                -moz-transform: translateX(-60%);
                left:           75%;
                top:            -7em;
                height:         calc(100% + 7em);

                &::before {
                    background: linear-gradient(0deg, rgba($hiper-teal, .5) 0%, rgba($hiper-teal, 0) 6em);
                }
            }
        }

        @media (max-width: $phone) {
            .hero-background {
                -moz-transform: translateX(-47.5%);
            }
        }

        @media (min-width: 651px) and (max-width: 880px) {
            &.hero-container--video-dans .hero-background {
                transform:    translateX(-53%);
                top:          0;
                left:         50%;
                width:        120%;
                height:       auto;

                &::before {
                    aspect-ratio: 16 / 9;
                    background:   linear-gradient(0deg, rgba($hiper-teal, 1) 0%, rgba($hiper-teal, 0) 4em);
                }

                video {
                    width:  100%;
                    height: auto;
                }
            }
        }

        @media (max-width: 650px) {
            &.hero-container--video-dans {
                .hero-background {
                    transform:      translate(-68%, -50%);
                    -moz-transform: translate(-68%, -50%);
                    left:           50%;
                    top:            50%;
                    width:          230%;
                    height:         auto;

                    &::before {
                        background: linear-gradient(0deg, rgba($hiper-teal, 1) 0%, rgba($hiper-teal, 0) 10%, rgba($hiper-teal, 0) 85%, rgba($hiper-teal, 1) 100%);
                    }

                    video {
                        opacity: .6;
                        width:   100%;
                        height:  auto;
                    }
                }

                .hero-content {
                    --fz-multiplier: .65;

                    align-items: center;

                    h1 {
                        text-align:  center;
                        text-shadow: 0 .4rem .8rem rgba(0, 0, 0, .25);
                    }

                    .hero-speed,
                    .hero-pre-price,
                    .hero-price,
                    .hero-price-disclaimer {
                        margin-left:  auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    &.hero-container--usp {
        padding-bottom: 1em;
        gap:            1em;

        .hero-background {
            max-height:          1080px;
            top:                 50%;
            left:                50%;
            background-repeat:   no-repeat;
            background-size:     cover;
            background-position: center center;

            @media (max-width: 470px) {
                aspect-ratio:     1;
                max-width:        1080px;
                height:           calc(100% + 2em);
                transform:        translate(-50%, calc(-50% - 1em));
                background-image: imgurl('pages/home/blank-scene-blur-mobile.jpg');

                .desktop {display: none;}
            }

            @media (min-width: 471px) {
                aspect-ratio:     16 / 9;
                max-width:        1920px;
                height:           120%;
                transform:        translate(-50%, -50%);
                background-image: imgurl('pages/home/blank-scene-blur-desktop.jpg');

                .mobile {display: none;}
            }

            @media (min-width: 960px) {
                &::after {
                    content:        '';
                    position:       absolute;
                    z-index:        1;
                    top:            0;
                    left:           -1px;
                    right:          -1px;
                    bottom:         0;
                    width:          calc(100% + 2px);
                    pointer-events: none;
                    background:     linear-gradient(90deg,
                                    rgba($hiper-teal, 1) 0%,
                                    rgba($hiper-teal, 0) 10%,
                                    rgba($hiper-teal, 0) 90%,
                                    rgba($hiper-teal, 1) 100%);
                }
            }

            @media (min-width: 1201px) {
                max-height:            150%;
                width:                 100%;
                height:                auto;
                background-position-y: 25%;
            }
        }

        .hero-content {
            margin-bottom: -1em;
            padding:       0 1rem;

            h1 {
                //@include        font-weight-variable(500);
                font-family:    $font-montserrat;
                text-transform: none;
                line-height:    1.1;
                text-align:     center;
                hyphens:        manual;
                margin:         0 auto;

                span {
                    display: unset;
                }

                .hiper-text-stroke::after {
                    z-index: -1;
                }

                & > strong  {font-size: 1.2em;}
                span strong {font-size: 1.5em;}

                strong {
                    @include font-weight-variable(700);
                }
            }
        }

        .address-wrap {
            margin: 1em 0;

            h2 {
                font-family:    $font-cervo;
                font-weight:    300;
                text-transform: uppercase;
                font-size:      3em;
                padding:        0;
            }
        }
    }

    &.hero-container--sharp-price {
        gap:     0;
        padding: 0;

        .hero-content {
            position:   relative;
            z-index:    0;
            overflow:   hidden;
            padding:    0;
            margin:     0;
            max-width:  none;
            width:      100%;
            height:     $height-header-big + 8em + 16em;
            background: radial-gradient(
                            ellipse farthest-corner at center,
                            lighten(saturate($hiper-teal, 5%), 20%) 0%,
                            darken($hiper-darkteal, 5%) 100%
                        );

            &.with-counter {
                height: $height-header-big + 8em + 16em + 3em;
            }

            img {
                position: absolute;
                bottom:   0;
            }

            .image-man {
                left:      50%;
                transform: translateX(calc(-50% + 10em));
                width:     20em;
            }

            .offer-container,
            .counter {
                position:  absolute;
                left:      50%;
                transform: translateX(calc(-50% - 8em));
            }

            .offer-container {
                top:    $height-header-big + 1em;
                width:  16em;
                height: 16em;

                .image-sign {width: 100%;}

                h1 {
                    position:    absolute;
                    left:        50%;
                    bottom:      8rem;
                    transform:   translate(-50%, 50%);
                    text-align:  center;
                    hyphens:     manual;
                    margin:      0 auto;

                    & > span {
                        margin: 0 auto
                    }

                    .hero-post-speed,
                    .hero-post-price {
                        @include        font-weight-variable(400);
                        font-family:    $font-montserrat;
                        text-transform: none;
                    }

                    .hero-speed      {font-size: 1.8em;}
                    .hero-post-speed {font-size: .95em;}
                    .hero-post-price {font-size: .9em;}

                    .price-comma-dash {position: absolute;}

                    .price-md {
                        right: unset;
                        left:  100%;
                    }
                }

                &.offer-container--99 {
                    h1 {
                        font-size: 1em;

                        .hero-price {
                            font-size: 10em;
                        }
                    }
                }

                &.offer-container--199 {
                    h1 {
                        font-size: 1.2em;

                        .hero-price {
                            margin-left: -.07em;
                            font-size:   8em;
                        }
                    }
                }

                div {
                    position:        absolute;
                    top:             -1em;
                    display:         flex;
                    justify-content: center;
                    align-items:     center;
                    border-radius:   50%;
                    background:      linear-gradient(
                                         0deg,
                                         darken(#ffffff, 20%) 0%,
                                         #ffffff 60%
                                     );
                }

                h2 {
                    padding:   .3em 0 0;
                    font-size: 1.4em;
                    color:     $hiper-bloodred;

                    .hiper-text-stroke::after {
                        background: linear-gradient(77.5deg,rgba($hiper-bloodred,0) 0,$hiper-bloodred 80%);
                    }
                }
            }

            .counter {
                top:           $height-header-big + 1em + 16em + 1em;
                margin:        0;
                padding:       0;
                border-radius: 0;
                font-size:     0.8em;
                text-align:    center;
                white-space:   nowrap;
            }

            @media (max-width: 600px) {
                .offer-container {
                    transform: translateX(calc(-50% - 6em));
                }

                .counter {
                    transform: translateX(-50%);
                }

                .image-man {
                    transform: translateX(calc(-50% + 8em));
                }
            }

            @media (max-width: 380px) {
                height: $height-header-big + 6em + 16em;
                
                &.with-counter {
                    height: $height-header-big + 6em + 16em + 5em;
                }

                .offer-container {
                    transform: translateX(-50%);
                }

                .image-man {display: none;}
            }
        }

        .address-wrap h2 {font-size:  1.6em;}

        .hero-bottom {
            margin-top:       -1.75em;
            width:            100%;
            background-color: $hiper-teal;
        }
    }

    &.hero-container--mvfp,
    &.hero-container--mvfp-generic,
    &.hero-container--hiperligfiber {
        .hero-background {
            top:                 50%;
            left:                50%;
            width:               100%;
            background-repeat:   no-repeat;
            background-size:     cover;
            background-position: center center;
        }

        .hero-content {
            display: flex;

            h1 {
                display:     block;
                flex-shrink: 0;
                flex-grow:   0;
            }

            .stamp-container img {
                max-width:    10em;
                max-height:   10em;
                margin-right: 2em;
                filter:       drop-shadow(var(--box-shadow));
            }
        }

        @media (min-width: 1921px) {
            .hero-background::after {
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            0;
                left:           -1px;
                right:          -1px;
                bottom:         0;
                width:          calc(100% + 2px);
                height:         100%;
                pointer-events: none;
                background:     linear-gradient(90deg,
                                rgba($hiper-darkteal, 1) 0%,
                                rgba($hiper-darkteal, 0) 10%,
                                rgba($hiper-darkteal, 0) 90%,
                                rgba($hiper-darkteal, 1) 100%);
            }
        }

        @media (min-width: 801px) and (max-width: 1920px) {
            .hero-background::after {
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            -1px;
                left:           0;
                right:          0;
                bottom:         -1px;
                width:          100%;
                height:         calc(100% + 2px);
                pointer-events: none;
                background:     linear-gradient(0deg,
                                rgba($hiper-darkteal, 1) 0%,
                                rgba($hiper-darkteal, 0) 10%,
                                rgba($hiper-darkteal, 0) 90%,
                                rgba($hiper-darkteal, 1) 100%);
            }
        }

        @media (min-width: 801px) {
            gap:            .5em;
            padding-bottom: .5em;

            .hero-background {
                transform:    translate(-50%, -50%);
                aspect-ratio: 16 / 9;
                max-width:    1920px;
            }

            .hero-content {
                flex-direction:  row;
                justify-content: flex-end;
                align-items:     center;
                margin-bottom:   1em;
            }

            .hero-content h1 {
                padding-right: 2.5em;
                font-size:     .9em;

                .hero-speed     {font-size: 4em;}
                .hero-pre-price {font-size: 1.6em;}
                .hero-price     {font-size: 14em;}

                .hero-price.hero-price--99 {
                    margin-left: -.05em;
                    font-size:   15em;
                    
                }
            }
        }
        

        @media (max-width: 800px) {
            padding-top: $height-header-big;

            .hero-background::after {
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            0;
                left:           0;
                right:          0;
                bottom:         -1px;
                width:          100%;
                height:         calc(100% + 1px);
                pointer-events: none;
                background:     linear-gradient(180deg,
                                rgba($hiper-darkteal, 1) 0%,
                                rgba($hiper-darkteal, 0) 6em,
                                rgba($hiper-darkteal, 0) 100%);
            }

            .hero-background {
                aspect-ratio: 9 / 16;
                max-width:    1080px;
            }

            .hero-content {
                justify-content: center;
                align-items:     center;
                padding-left:    .5em;
                padding-right:   .5em;
            }

            .hero-content h1 {
                width:      fit-content;
                text-align: center;

                & > span {
                    margin-left:  auto;
                    margin-right: auto;
                }

                .hero-price.hero-price--99 {
                    font-size: 16em;

                    @media (max-width: $phone) {
                        font-size: calc((13vw * 7.5) * var(--fz-multiplier));
                    }
                }
            }

            .hero-content .stamp-container img {
                max-width: 10em;
            }

            @media (min-width: 521px) {
                .hero-background {
                    transform: translate(-50%, -50%);
                }

                .hero-content {
                    flex-direction: row;
                    gap:            2.5em;
                }

                .hero-content .stamp-container img {
                    width:  10em;
                    height: 10em;
                }
            }

            @media (max-width: $phone) {
                .hero-background {
                    top:       unset;
                    bottom:    0;
                    transform: translateX(-50%);
                }

                .hero-content {
                    flex-direction: column;
                    gap:            1em;
                }

                .hero-content .stamp-container img {
                    margin-right: 0;
                }
            }
        }
    }

    &.hero-container--fibia-fiber {
        .hero-content .hero-price-disclaimer {
            line-height: 1.2;
        }
    }

    &.hero-container--mvfp {
        @media (min-width: 801px) {
            .hero-background {
                background-image: imgurl('campaigns/mvfp/hero-mvfp-desktop.jpg');
            }
        }

        @media (max-width: 800px) {
            .hero-background {
                background-image: imgurl('campaigns/mvfp/hero-mvfp-mobile.jpg');
            }

            .hero-content {
                @media (min-width: 521px) {
                    margin-bottom: 4em;
                }

                @media (max-width: $phone) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.hero-container--mvfp-generic {
        @media (min-width: 801px) {
            .hero-background {
                background-image: imgurl('campaigns/mvfp/hero-mvfp-generic-desktop.jpg');
            }
        }

        @media (max-width: 800px) {
            .hero-background {
                background-image: imgurl('campaigns/mvfp/hero-mvfp-generic-tablet.jpg');
            }
        }

        @media (max-width: $phone) {
            .hero-background {
                background-image: imgurl('campaigns/mvfp/hero-mvfp-generic-mobile.jpg');
            }

            .hero-content {
                @media (min-width: 521px) {
                    margin-bottom: 4em;
                }

                @media (max-width: $phone) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.hero-container--hiperligfiber {
        &.tdcnet-fpp {
            .stamp-container .tdcnet-fpp {
                display:        block;
                filter:         drop-shadow(var(--box-shadow));
                pointer-events: none;
            }
        }

        @media (min-width: 801px) {
            .hero-background-wrapper::before {
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            0;
                left:           0;
                right:          0;
                bottom:         0;
                pointer-events: none;
                background:     linear-gradient(0deg, rgba($hiper-teal, .75) 8em, rgba($hiper-teal, 0) 14em);
            }

            .hero-background {
                background-image: imgurl('campaigns/hiperligfiber/hero-hiper-lig-fiber-desk.jpg');
            }

            &.tdcnet-fpp {
                .hero-content {
                    flex-direction: column-reverse;
                    margin-bottom:  0;
                    align-items:    end;
                }

                .stamp-container {
                    margin-right: 2.5em;

                    .tdcnet-fpp {
                        height:    6.5em;
                        transform: scale(1.6);
                    }
                }

            }
        }

        @media (max-width: 800px) {
            .hero-background {
                background-image: imgurl('campaigns/hiperligfiber/hero-hiper-lig-fiber-mob.jpg');
                opacity:          .75;

                &::before {
                    content:        '';
                    position:       absolute;
                    z-index:        1;
                    top:            0;
                    left:           0;
                    right:          0;
                    bottom:         0;
                    pointer-events: none;
                    background:     linear-gradient(0deg, rgba($hiper-teal, 1) 0%, rgba($hiper-teal, 0) 50%);
                }
            }

            &.tdcnet-fpp .hero-content {
                flex-direction: column-reverse;
                gap:            0;
                margin-bottom:  0;

                .stamp-container .tdcnet-fpp {
                    transform: scale(1.6);
                }

                @media (min-width: 521px) {
                    .stamp-container .tdcnet-fpp {
                        height: 6em;
                    }
                }

                @media (max-width: $phone) {
                    .stamp-container {
                        margin-bottom: -1em;

                        .tdcnet-fpp {
                            height: 22vw;
                        }
                    }
                }
            }
        }
    }

    &.hero-container--gradient {

        @media (min-width: 801px) {
            .hero-background-wrapper::before {
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            0;
                left:           0;
                right:          0;
                bottom:         0;
                pointer-events: none;
                background:     linear-gradient(0deg, rgba($hiper-teal, .75) 8em, rgba($hiper-teal, 0) 14em);
            }
        }

        @media (max-width: 800px) {
            .hero-background::before {           
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            0;
                left:           0;
                right:          0;
                bottom:         0;
                pointer-events: none;
                background:     linear-gradient(0deg, rgba($hiper-teal, 1) 0%, rgba($hiper-teal, 0) 50%);
            }
        }
    }

    &.hero-container--5g-internet {
        .hero-background {
            top:                 50%;
            left:                50%;
            width:               100%;
            background-repeat:   no-repeat;
            background-size:     cover;
            background-position: center center;

            &::after {
                content:        '';
                position:       absolute;
                z-index:        1;
                top:            0;
                left:           -1px;
                right:          -1px;
                bottom:         0;
                width:          calc(100% + 2px);
                height:         100%;
                pointer-events: none;
            }
        }

        .hero-content {
            h1 {
                display:     block;
                flex-shrink: 0;
                flex-grow:   0;

                .hero-unlimited-data,
                .hero-pre-price,
                .hero-price .price-md,
                .hero-post-price {
                    line-height: 1.1;
                }

                .hero-unlimited-data,
                .hero-post-price {
                    font-family:    $font-montserrat;
                    letter-spacing: normal;
                    text-transform: none;
                }

                .hero-unlimited-data {
                    @include font-weight-variable(500);
                    padding:          .25em .75em;
                    border-radius:    2em;
                    background-color: #ffffff;
                    color:            $hiper-darkteal;
                }

                .hero-pre-price,
                .hero-price .price-md,
                .hero-post-price {
                    @include font-weight-variable(400);
                }

                .hero-pre-price {
                    padding: .5em 0;
                }

                .hero-price .price-md {
                    text-align:     left;
                    text-transform: none;
                }

                .hero-unlimited-data {font-size: 1em;}
                .hero-pre-price      {font-size: 1.4em;}
                .hero-price          {font-size: 12em;}
                .hero-post-price     {font-size: 1.2em;}
            }
        }

        @media (min-width: 801px) {
            gap:            .5em;
            padding-top:    7em;
            padding-bottom: .5em;

            .hero-background-wrapper::after {
                content:        '';
                position:       absolute;
                z-index:        0;
                top:            0;
                left:           0;
                right:          0;
                bottom:         -1px;
                width:          100%;
                height:         calc(100% + 1px);
                pointer-events: none;
                background:     linear-gradient(0deg,
                    rgba($hiper-darkteal, 1) 6em,
                    rgba($hiper-darkteal, 0) 12em,
                    rgba($hiper-darkteal, 0) 100%);
            }

            .hero-background {
                transform:        translate(-50%, -50%);
                aspect-ratio:     16 / 9;
                max-width:        1920px;
                width:            unset;
                height:           100%;
                background-image: imgurl('campaigns/mbb/mbb-hero-desktop.jpg');

                &::after {
                    background: linear-gradient(90deg,
                        rgba($hiper-darkteal, 1) 0%,
                        rgba($hiper-darkteal, 0) 10%,
                        rgba($hiper-darkteal, 0) 90%,
                        rgba($hiper-darkteal, 1) 100%);
                }
            }

            .hero-content {
                align-items: flex-end;
            }

            .hero-content h1 {
                width:     18em;
                font-size: 1em;

                .hero-price {
                    .price-comma-dash {
                        letter-spacing: -.125em;
                    }

                    .price-md {
                        display:        inline-block;
                        position:       relative;
                        top:            unset;
                        right:          unset;
                        left:           -1.8em;
                        font-size:      0.1em;
                    }
                }
            }

            @media (max-width: 960px) {
                .hero-content h1 {font-size: .8em;}
            }
        }

        @media (max-width: 800px) {
            padding-top:  7em;

            .hero-background {
                top:              calc(50% - 5em);
                transform:        translate(-50%, -50%);
                aspect-ratio:     9 / 16;
                max-width:        800px;
                background-image: imgurl('campaigns/mbb/mbb-hero-mobile.jpg');


                &::after {
                    background: linear-gradient(0deg,
                        $hiper-darkteal 0,
                        rgba($hiper-darkteal, 0) 8em);
                }
            }

            .hero-content {
                padding-left:  2em;
                padding-right: 2em;
            }

            .hero-content h1 {
                width:     18em;
                font-size: 1em;

                .hero-pre-price,
                .hero-price {
                    text-shadow: var(--box-shadow);
                }

                .hero-price .price-md {
                    top:       20%;
                    right:     -2.6em;
                    font-size: .09em;
                }
            }

            &.ab-test-container-b {
                .hero-background-wrapper {
                    $image-teal: #0f4b56; // Colorpicked from image

                    background:  radial-gradient(
                                         circle,
                                         $hiper-teal 25%,
                                         $image-teal 100%
                                 );

                    &::after {
                        content:        '';
                        position:       absolute;
                        z-index:        0;
                        top:            0;
                        left:           0;
                        right:          0;
                        bottom:         -1px;
                        width:          100%;
                        height:         calc(100% + 1px);
                        pointer-events: none;
                        background:     linear-gradient(0deg,
                            rgba($hiper-darkteal, 1) 0%,
                            rgba($hiper-darkteal, 0) 25%);
                    }
                }

                .hero-background {display: none;}

                .hero-content {
                    align-items:   center;
                    padding-left:  .5em;
                    padding-right: .5em;

                    h1 {
                        text-align: center;

                        & > span {
                            margin-left:  auto;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 521px) {
        .hero-content {
            max-width:     calc(var(--hero-max-width) + 3rem);
            padding:       0 2.5em;
            margin-bottom: 2.5em;

            .hero-pre-speed,
            .hero-pre-price {
                font-size: 2em;
            }

            .hero-bullets {
                font-size: 1.2em;
            }

            .hero-speed {
                font-size: 5em;
            }

            .hero-price {
                font-size: 13em;

                &.hero-price-long {
                    font-size: 8em;
                }

                .price-md {
                    @include font-weight-variable(300);
                }
            }

            .hero-price-disclaimer {
                font-size:   1.6em;
                padding-top: 1.5em;

                &.hero-price-disclaimer--small {
                    font-size: 1em;
                }
            }
        }

        &.linux .hero-content .hero-price-disclaimer,
        &.mac .hero-content .hero-price-disclaimer {
            padding-top: .5em;
        }

        .address-wrap {
            max-width: calc(var(--hero-max-width) + 3rem);

            h2 {
                padding-left:   2.5rem;
                padding-right:  2.5rem;
                padding-bottom: .5em;
                font-size:      1.4em;
            }

            .address-widget:not(.sticky) {
                width: calc(100% - 3em);
            }
        }

        .conditions {
            max-width: calc(var(--hero-max-width) + 3rem);
            padding:   0 1.5rem;
        }

        @media (max-width: 1750px) {
            .hero-content h1 {font-size: .9em;}
        }

        @media (max-width: 1650px) {
            padding-top: ($height-header-big + .5em);
            gap:         .5em;

            .hero-content {
                margin-bottom: 2em;

                h1 {font-size: .8em;}
            }
        }

        &.hero-container--usp {
            padding-top: 9em;

            .hero-content h1 {
                font-size: 2em;
            }

            .address-wrap {
                margin: 1em 0;

                h2 {
                    margin-bottom: .25rem;

                    .scroll-down-arrow {
                        width: 33px;

                        &::before,
                        &::after {
                            width:         24px;
                            height:        4px;
                            border-radius: 2px;
                        }

                        &::before {transform: translate(-3px, .6em) rotate(45deg);}
                        &::after  {transform: translate(12px, .6em) rotate(-45deg);}
                    }
                }
            }

            .stamp {
                font-size: .9em;
            }
        }

        &.hero-container--sharp-price {
            .hero-content .offer-container {
                h1 {
                    line-height: .9;

                    .hero-post-price {margin-top: -.8em;}
                }

                div {
                    right:  -4em;
                    width:  7em;
                    height: 7em;
                }
            }

            .address-wrap {margin-top: -4.5em;}

            .hero-bottom {
                padding: calc(1em + 1.75em) 0 1em;
            }
        }
    }

    @media (max-width: $phone) {
        .hero-content {
            max-width:     calc(var(--hero-max-width) + 2rem);
            padding:       0 1.5em;
            margin-bottom: 1.5em;

            --fz-multiplier: .5;

            .hero-pre-speed,
            .hero-pre-price {
                font-size: calc((2vw * 6.25) * var(--fz-multiplier));
            }

            .hero-bullets {
                font-size: calc((1.2vw * 6.25) * var(--fz-multiplier));
            }

            .hero-speed {
                font-size: calc((5vw * 6.25) * var(--fz-multiplier));
            }

            .hero-price {
                font-size: calc((13vw * 6.25) * var(--fz-multiplier));

                &.hero-price-long {
                    font-size: calc((8vw * 6.25) * var(--fz-multiplier));
                }
            }

            .hero-price-disclaimer {
                padding-top: .6em;
                font-size:   calc((1.6vw * 6.25) * var(--fz-multiplier));

                &.hero-price-disclaimer--small {
                    font-size: calc((1vw * 6.25) * var(--fz-multiplier));
                }
            }
        }

        &.windows .hero-content .hero-price-disclaimer {
            padding-top: 1.5em;
        }

        .address-wrap {
            max-width: calc(var(--hero-max-width) + 2rem);

            h2 {
                font-size:      1em;
                padding-left:   1.5rem;
                padding-right:  1.5rem;
                padding-bottom: .25em;
            }

            .address-widget:not(.sticky) {
                width: calc(100% - 2em);
            }
        }

        .conditions {
            max-width: calc(var(--hero-max-width) + 2rem);
            padding:   0 1rem;
        }

        &.hero-container--video {
            padding-top: $height-header-big + 1.5em;
            gap:         .5em;

            .hero-content {
                margin-bottom: 2.5em;
            }
        }

        &.hero-container--usp {
            padding-top: 8em;

            .hero-content h1 {
                font-size: 1.6em;
            }

            .address-wrap h2 {
                font-size:     2.6em;
                margin-bottom: -.15em;

                .scroll-down-arrow {
                    width: 26px;

                    &::before,
                    &::after {
                        width:         18px;
                        height:        2px;
                        border-radius: 1px;
                    }

                    &::before {transform: translate(-3px, .6em) rotate(45deg);}
                    &::after  {transform: translate(9px,  .6em) rotate(-45deg);}
                }
            }
        }

        &.hero-container--sharp-price {
            .hero-content .offer-container {
                h1 {
                    line-height: .95;

                    .hero-post-price {margin-top: -1em;}
                }

                div {
                    right:  -3em;
                    width:  6em;
                    height: 6em;
                }
            }

            .address-wrap {margin-top: -4em;}

            .hero-bottom {
                padding: calc(.5em + 1.75em) 0 .5em;
            }
        }
    }

    &.hero-container--usp {
        @media (min-height: 900px) {
            .hero-content {padding-top: 5em;}

            .address-wrap {
                margin-top:     .5em;
                padding-bottom: 5em;
            }
        }
    }
}

/* ab-hero-test */
.hero-container.hero-ab-test {
    .hero-background img {
        max-width: 122em;

        @media (max-width: 1030px) {
            transform: translateX(-10%);
        }

        @media (max-width: $tablet) {
            transform: translateX(-10%) translateY(-40px);
            max-width: 95em;
        }

        @media (max-width: $phone) {
            transform: translateX(-90px) translateY(62px);
        }
    }

    .hero-speed {
        font-size:     3em;
        margin-left:   -.04em;
        margin-bottom: .3em;
        font-weight:   300;

        @media (max-width: $phone) {
            font-size: 12vw;
        }
    }

    .hero-price {
        font-size:  10em;
        margin-top: .2em;

        @media (max-width: $phone) {
            font-size: 8em;
        }
    }

    .hero-bullets {
        @include font-weight-variable(400);
        @media  (min-width: 401px) {font-size: 1em;}
        @media  (max-width: 400px) {font-size: .9em;}

        .icon {
            display:  inline-block;
            position: relative;
            top:      .45em;
            width:    1.5em;
            height:   1.5em;
            color:    transparent;
            fill:     #ffffff;
        }
    }

    .stamp {
        transform: translate(1em, -50%);
        top:       35%;
        left:      50%;

        @media (max-width: $tablet) {
            top:  57%;
            left: 35%;
        }

        @media (max-width: $phone) {
            left: 45%;               
        }

        @media (max-width: $phone-small) {
            display: none;                
        }
    }      
}

.show-manual-address .hero-container {
    @media (min-width: 521px) {
        gap: 1em;

        .hero-content {
            margin-bottom: 3em;
        }
    }
}
