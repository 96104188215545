.nedbrud {
    padding-top:      $height-header-big;
    width:            100%;
    background-color: $hiper-teal;

    h1 {
        padding:          1em .5em;
        background-color: $hiper-pale-yellow;
        color:            #000000;
        font-size:        2em;
        line-height:      1.1;

        a {
            color:         $hiper-red;
            border-bottom: $hiper-red .1em solid;
        }
    }
}

// region: header

.site-main {
    padding-top: $height-header;
}

.site-header {
    position:         fixed;
    right:            0;
    left:             0;
    z-index:          $z-index-header;
    background-color: $hiper-teal;
    background-image: linear-gradient(180deg, rgba($hiper-teal, 0) 25%, rgba($hiper-teal, 0) 100%);
    transition:       height $header-transition,
                      background-color $header-transition,
                      background-image $header-transition;

    &, .site-navigation-container {
        height: $height-header;
    }

    .site-logo {
        height: $height-header - 1em;
    }

    &.site-header--big {
        background-color: transparent;
        background-image: linear-gradient(180deg, rgba($hiper-teal, .75) 25%, rgba($hiper-teal, 0) 100%);

        &, .site-navigation-container {
            height: $height-header-big;
        }

        .site-logo {
            height: $height-header;
        }
    }
}

.site-navigation-container,
.site-navigation,
.site-navigation-menu {
    display: flex;
}

.site-navigation-container,
.site-navigation {
    flex-direction: row;
    align-items:    center;
}

.site-navigation-container {
    max-width:       60em;
    margin:          auto;
    padding:         0 2em;
    justify-content: space-between;
    transition:      height $header-transition,
                     padding $header-transition;

    li {
        margin:     0;
        padding:    0;
        transition: background .15s ease;
    }

    a {
        display: block;
        border:  0;
    }
}

.navigation-search,
.burger-menu-container {
    transition: font-size $header-transition;
}

.burger-menu-container {
    display: none;
}

.site-logo {
    transition: height $header-transition,
                transform $header-transition;

    a {
        display:     flex;
        width:       100%;
        height:      100%;
        align-items: center;
    }

    svg {
        padding-bottom: em(5px);
    }

    svg, image {
        width:      100%;
        max-height: 100%; // http://stackoverflow.com/a/12631326/954798
    }

    // scss-lint:disable IdSelector
    #site-logo-title,
    #site-logo-desc {
        display: none; // Don't show in browsers not supporting SVG
    }

    // scss-lint:enable IdSelector

    .hiper-type {
        fill: white;
    }
}

.navigation-link {
    border-radius: .5rem;

    & > a .menu-label,
    & .dropdown-button-container a .menu-label {
        transition: font-size $header-transition;
    }

    a {
        font-family:    $font-cervo;
        font-size:      em(17px);
        font-weight:    300;
        line-height:    1;
        text-transform: uppercase;
        letter-spacing: var(--letter-spacing-medium);
        color:          #ffffff;
    }

    &.navigation-link--dropdown {
        position: relative;

        .dropdown-button-container {
            display:         flex;
            flex-direction:  row;
            align-items:     center;
            justify-content: center;
        }

        .dropdown-icon {
            width:    1em;
            height:   1em;

            .dropdown-button {
                opacity:  0;
                position: absolute;
                z-index:  $z-index-menu-icon;
                display:  block;
                padding:  0;
                width:    inherit;
                height:   inherit;
            }

            span {
                pointer-events:   none;
                display:          block;
                position:         absolute;
                top:              50%;
                left:             0;
                height:           2px;
                border-radius:    1px;
                background-color: #ffffff;
                transition:       transform .2s ease;
            }
        }

        .dropdown {
            display: none;
        }
    }

    &.current {
        background: $hiper-red;
    }
}

.navigation-search {
    padding:    0;
    background: transparent;
    border:     none;
    cursor:     pointer;

    &, .icon {
        display: block;
        width:   1em;
        height:  1em;
    }

    .icon {
        color: transparent;
    }
}

// Burger menu nav start/stop
$burgerStart: 730px;
$burgerStop:  $burgerStart + 1px;
/* --- IF CHANGED: remember to adjust behavior.js --- */

@media (min-width: 650px) {
    .site-header.site-header--big {
        .navigation-link > a .menu-label,
        .navigation-link .dropdown-button-container a .menu-label {
            font-size: 1.2em;
        }
    }
}

@media (min-width: $burgerStop) {
    .page-home .site-header {
        .site-navigation-menu        {animation: fadeInMenu   .4s     $ease-in-out-quad;}
        .navigation-search-container {animation: fadeInSearch .4s .4s $ease-in-out-quad backwards;}

        @keyframes fadeInMenu {
            0%   {transform: translateX(4.5em);}
            100% {transform: translateX(0);}
        }

        @keyframes fadeInSearch {
            0% {
                transform: translateY(-5em);
                opacity:   0;
            }
            100% {
                transform: translateY(0);
                opacity:   1;
            }
        }
    }

    .site-header {
        .navigation-search {
            font-size: 1.5em;
        }

        &.site-header--big .navigation-search {
            font-size: 2em;
        }
    }

    .site-navigation {
        gap: 1em;
    }

    .site-navigation-menu {
        flex-direction:  row;
        justify-content: center;
        align-items:     center;
        gap:             .25em;
    }

    .navigation-link {
        a {
            padding: .5em .6em;
        }

        &.navigation-link--dropdown {
            .dropdown-button-container a {
                padding-right: .2em;
            }

            .dropdown-icon {
                margin-right: .6em;
                position:     relative;

                span {
                    width: .5em;

                    &:nth-child(2) {
                        transform: translateX(.1em) rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: translateX(.4em) rotate(-45deg);
                    }
                }
            }

            .dropdown {
                position: absolute;
                left:     0;
                width:    max-content;

                li {
                    background-color: $hiper-teal;

                    &:first-child {
                        margin-top:    .2em;
                        border-radius: .5rem .5rem 0 0;

                        a {
                            padding-top: .8em;
                        }
                    }

                    &:last-child  {
                        border-radius: 0 0 .5rem .5rem;

                        a {
                            padding-bottom: .8em;
                        }
                    }

                    &:only-child {
                        border-radius: .5rem;

                        a {
                            padding-top:    .8em;
                            padding-bottom: .8em;
                        }
                    }

                    &.current {
                        background-color: $hiper-red;
                    }

                    &:not(.current):hover {
                        background-color: $hiper-darkteal;
                    }
                }

                a {
                    padding-top:    .4em;
                    padding-bottom: .4em;
                }
            }

            &:hover {
                .dropdown-icon span {
                    &:nth-child(2) {
                        transform: translateX(.4em) rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: translateX(.1em) rotate(-45deg);
                    }
                }

                .dropdown {display: block;}
            }
        }

        &.current-desktop {
            background: $hiper-red;
        }

        &:not(.current):hover {
            background-color: rgba(#ffffff, .1);
        }

        &.current:hover {
            background-color: $hiper-darkred;
            transition:       background-color .25s $ease-in-out-cubic;
        }

        &.service-status-mobile {display: none;}
    }

    .navigation-search-container {
        border-left:  .1em solid #ffffff;
        padding-left: 1.6em;
    }

    .navigation-search {
        margin-left: -.2em;

        .icon {
            transition: transform .2s $ease-in-out-quad;
        }

        &:hover .icon {
            transform: rotate(-15deg) scale(1.25);
        }
    }
}

@media (max-width: $burgerStart) {
    .site-header {
        .burger-menu-container,
        .navigation-search {
            font-size: 2.5em;
        }

        &.site-header--big {
            .burger-menu-container,
            .navigation-search {
                font-size: 3em;
            }
        }
    }

    .site-logo {
        z-index: $z-index-header;

        .site-header.site-header--big & {
            transform: translateY(-.4em);
        }
    }

    .burger-menu-container {
        --bm-w: .8em;
        --bm-h: .05em;
        --bm-g: .3em;
    }

    .site-navigation-container {
        .site-header:not(.site-header--big) & {
            padding: 0 1.5em;
        }

        &.menu-open {
            .burger-menu-container {
                span:nth-child(2) {
                    transform: translateY(-50%) rotate(-45deg);
                }

                span:nth-child(3) {
                    transform: translateY(-50%) rotate(45deg);
                }

                span:nth-child(4) {
                    transform: translateY(calc(-50% + var(--bm-g))) scaleY(0);
                    opacity:   0;
                }
            }
        }
    }

    .site-navigation {
        flex-direction: row-reverse;
        gap:            1em;
    }

    .burger-menu-container {
        display:  block;
        width:    var(--bm-w);
        height:   var(--bm-w);
        position: relative;
        z-index:  $z-index-header;

        .burger-menu {
            position:   absolute;
            z-index:    $z-index-menu-icon;
            display:    block;
            padding:    0;
            width:      inherit;
            height:     inherit;
            border:     none;
            background: transparent;
        }

        span {
            display:          block;
            position:         absolute;
            top:              50%;
            left:             0;
            transform:        translateY(-50%);
            width:            var(--bm-w);
            height:           var(--bm-h);
            border-radius:    calc(var(--bm-h) / 2);
            background-color: #ffffff;
            pointer-events:   none;
            transition:       width .2s ease,
                              transform .2s ease,
                              opacity .2s ease;

            &:nth-child(2) {
                transform: translateY(calc(-50% - var(--bm-g)));
            }

            &:nth-child(4) {
                transform-origin: bottom;
                transform: translateY(calc(-50% + var(--bm-g)));
            }
        }
    }

    .site-navigation-menu {
        flex-direction:  column;
        justify-content: flex-start;
        align-items:     flex-end;
        gap:             .5em;
        transform:       translateX(100%);
        position:        absolute;
        top:             0;
        right:           0;
        bottom:          0;
        left:            0;
        z-index:         $z-index-header-menu;
        overflow-y:      auto;
        background:      $hiper-darkteal;
        height:          100vh;
        padding:         $height-header-big 0 50px;
        transition:      transform .3s $ease-in-out-quad;

        &::after {
            content:          '';
            background-color: $hiper-teal;
            position:         absolute;
            top:              0;
            left:             0;
            right:            0;
            width:            100%;
            height:           $height-header;
        }

        .site-header.site-header--big & {
            padding: ($height-header-big + 1.5em) 0 50px;

            &::after {height: $height-header-big;}
        }

        .site-navigation-container.menu-open & {
            transform: translateX(0);
        }
    }

    .navigation-link {
        width:         100%;
        border-radius: 0;

        a {
            width:      100%;
            padding:    .8em 4em .5em .75em;
            text-align: right;
        }

        & > a .menu-label,
        .dropdown-button-container a .menu-label,
        .dropdown-icon {
            font-size: 3em;
        }

        &.navigation-link--dropdown {
            .dropdown-icon {
                position: absolute;
                top:      .8rem;
                right:    1.5rem;
                width:    0.65em;

                span {
                    width: 18px;

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: translateX(12px) rotate(-45deg);
                    }
                }
            }

            .dropdown {
                background-color: $hiper-darkteal;

                li.current {
                    background-color: lighten($hiper-red, 10%);
                }

                a {
                    padding-right:  1.5em;
                    padding-top:    .7em;
                    padding-bottom: .4em;

                    .menu-label {
                        font-size: 2em;
                    }
                }
            }

            &.active {
                .dropdown-icon span {
                    &:nth-child(2) {
                        transform: translateX(12px) rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                    }
                }

                .dropdown {display: block;}
            }
        }

        .service-status-desktop {display: none;}
    }

    .navigation-search-container {
        position: relative;
        z-index:  $z-index-header;
    }
}

.site-wrap {
    transition: .2s all $ease-in-out-quad;
}

// endregion: header

// region: footer

.footer {
    margin-top:       1em;
    width:            100%;
    color:            #ffffff;
    background-color: $hiper-teal;

    .footer-section-wrapper {
        width:           100%;
        display:         flex;
        justify-content: center;
        align-items:     center;

        &:first-child {background-color: $hiper-darkteal;}
    }

    p, a, li {
        padding: 0;
        margin:  0;
    }

    ul li {
        @include    font-weight-variable(300);
        text-align: left;
    }

    a        {border:      0;}
    strong   {@include     font-weight-variable(400);}
    .no-wrap {white-space: nowrap;}

    span.text,
    span.title {
        font-family:    $font-cervo;
        text-transform: uppercase;
        font-weight:    300;
        line-height:    1.2;
        letter-spacing: var(--letter-spacing-medium-always);
    }

    .footer-content .title {font-size: 1.2em;}

    .accordion-container--footer {
        .accordion--footer {
            background-color: transparent;
            color:            #ffffff;
            text-align:       left;
        }

        .accordion-content-container--footer {
            background-color: transparent;
            transition:       max-height .3s $ease-in-out-cubic;
        }
    }

    .footer-content ul li {
        padding-bottom: .25em;

        &:last-child {padding-bottom: 0;}
    }

    .footer-menu {
        ul button {
            padding:    0;
            width:      100%;
            border:     none;
            background: transparent;
            font:       inherit;
            text-align: left;
            color:      #ffffff;
            box-shadow: none;
            cursor:     pointer;
        }

        ul a,
        ul button {
            display:  block;
            position: relative;
            z-index:  0;

            &::after {
                display:          block;
                content:          '';
                position:         absolute;
                top:              -.1em;
                left:             -.2em;
                z-index:          -1;
                width:            calc(100% + .4em);
                height:           calc(100% + .2em);
                border-radius:    .5em;
                background-color: rgba(#ffffff, 0);
                transform-origin: center;
                transform:        scale(.9);
                transition:       background-color .2s ease,
                                  transform .2s ease;
            }

            &:hover {
                color: #ffffff;

                &::after {
                    background-color: rgba(#ffffff, .1);
                    transform:        scale(1);
                }
            }
        }
    }

    aside {
        background-color: $hiper-teal;
        border-radius:    .5em;

        a {
            border-bottom: 1px solid rgba(#ffffff, .5);

            &:hover {color: rgba(#ffffff, .75);}
        }
    }

    .footer-bottom {
        display:         flex;
        flex-direction:  row;
        justify-content: space-between;
        align-items:     center;
        width:           100%;
        gap:             .5em 1em;

        li {
            padding: 0;
            margin:  0;

            &:first-child {
                display:         flex;
                flex-direction:  row;
                justify-content: center;
                align-items:     center;
                gap:             .25em;
            }

            a {
                display: flex;

                svg {
                    margin:     0;
                    fill:       rgba(#ffffff, .5);
                    transition: fill .3s ease;
                }

                &.nuuday-logo svg .thin {
                    opacity: .5;
                }

                &:hover svg {
                    fill: rgba(#ffffff, 1);
                }
            }
        }
    }

    @media (min-width: 1001px) {
        .footer-section-wrapper:last-child {padding: 0 calc(148px - 4em);}

        .footer-content,
        .footer-bottom {
            padding:   1em 4em;
            max-width: 60em;
        }

        .footer-content {
            grid-template-columns: 2fr 1fr;
            gap:                   2em 0;
        }

        .footer-menu,
        aside {
            grid-template-rows: 1fr 1fr;
            gap:                1em 2em;
        }

        .footer-menu {
            grid-template-columns: 1fr 1fr;
            padding:               1em 0;
        }

        aside {padding: 1em 2em;}
    }

    @media (min-width: 769px) {
        .footer-content {
            &,
            .footer-menu,
            aside        {display:   grid;}
            ul li        {font-size: .8em;}
        }

        .accordion-container--footer {
            width: fit-content;
        }

        .title {
            display:        block;
            padding-bottom: .25rem;
        }

        // region: Disable accordion
        .accordion--footer {
            pointer-events: none;
            .symbol {display: none;}
        }
        .accordion-content-container--footer {max-height: none;}
        hr {display: none;}
        // end region: Disable accordion
    }

    @media (max-width: 1000px) and (min-width: 769px) {
        .footer-section-wrapper:last-child {padding: 0 calc(88px - 2em);}

        .footer-content,
        .footer-bottom {
            padding:   1.5em 2em;
            max-width: 40em;
        }

        .footer-content,
        .footer-menu,
        aside {gap: 1em 2em;}

        .footer-content {grid-template-columns: 1fr;}

        .footer-menu,
        aside        {grid-template-columns: 1fr 1fr;}
        .footer-menu {padding: 0   2em;}
        aside        {padding: 1em 2em;}
    }

    @media (max-width: $tablet) {
        .footer-content,
        .footer-bottom {max-width: 28em;}

        .footer-content {
            flex-direction: column;
            gap:            .5em;
        }

        aside {
            background: transparent;
            padding:    0;

            ul {margin-top: 1em;}
        }

        .accordion-container--footer {
            width: 100%;

            .accordion--footer {
                padding: .5em 0;

                .symbol span {
                    background-color: #ffffff;
                }
            }

            .accordion-content-container--footer {
                overflow: hidden;

                li {
                    padding-left: .5em;

                    &:last-child {padding-bottom: 1em;}
                }
            }
        }

            hr {
                max-width:        none;
                width:            100%;
                border:           0;
                background-color: rgba(#ffffff, .1);
                margin:           0;
            }
        }
    }

    @media (max-width: $tablet) and (min-width: 521px) {
        .footer-section-wrapper:last-child {padding: 0 calc(88px - 2em);}

        .footer-content,
        .footer-bottom {padding: 1em 2em;}
    }

    @media (min-width: 521px) {
        a.some-icon {
            width:  2em;
            height: 2em;
        }

        .nuuday-logo svg {
            max-width: 6em;
        }
    }

    @media (max-width: $phone) {
        .footer-content,
        .footer-bottom {width: 100%;}

        .footer-content {
            padding: 1em;
            gap:     1em;

            aside ul:first-child {
                margin-bottom: 1em;
            }
        }

        .footer-bottom {
            flex-direction: column;
            padding:        1em 0;

            a.some-icon {
                width:  3em;
                height: 3em;
            }

            .nuuday-logo svg {
                max-width: 8em;
            }
        }
    }

.site-footer__cookie-warning {
    display:          flex;
    width:            100%;
    min-height:       em(50px, 16px * em(14px));
    position:         fixed;
    right:            0;
    bottom:           0;
    left:             0;
    z-index:          $z-index-cookie-warning;
    align-items:      center;
    justify-content:  center;
    padding:          em(10px) em(35px);
    background-color: $color-woodsmoke;
    font-size:        em(14px);
    color:            rgba(white, .5);
    line-height:      1em;
    text-align:       center;

    @media (max-width: 980px) {
        padding-right: em(175px);
    }
}

.site-footer__accept-cookies {
    display:     block;
    position:    absolute;
    top:         0;
    left:        0;
    padding:     0 .2em;
    font-size:   em(40px);
    color:       $hiper-gray;
    line-height: 1em;
    cursor:      pointer;

    @media (min-width: $phone) {
        top:       50%;
        transform: translateY(-50%);
    }
}

// endregion: site-footer

// region: page header

.header {
    padding-top: 1em;
    text-align:  center;

    .heading {
        margin:  0;
        padding: 0;
    }

    @media (max-width: $tablet) {
        .breadcrumb {
            font-size: em(20px);
        }
    };

    @media (max-width: $phone) {
        .breadcrumb {
            font-size: em(17px);
        }
    };
}

.back-link-widget {
    display:         inline-flex;
    justify-content: center;
    align-items:     center;
    border-bottom:   1px solid $hiper-blue;
    font-size:       1.1em;
    line-height:     1.4;

    .icon {
        margin-left:  -.2em;
        margin-right: .1em;
        color:        transparent;
        fill:         $hiper-blue;
    }

    .text {
        @include    font-weight-variable(450);
        border:     none;
        font-size:  .8em;
        color:      $hiper-blue;
        transition: inherit;
    }

    &:hover {
        //background-color: $hiper-red;
        border-bottom-color: rgba($hiper-blue, .75);
        //.icon {color: $hiper-red;}
        .icon {
            color: transparent;
            fill:  rgba($hiper-blue, .75);
        }
        //.text {color: #ffffff;}
        .text {color: rgba($hiper-blue, .75);}
    }
}

// endregion: page header

// region: blurb

.blurb {
    max-width: 40em;
    margin:    auto;
    padding:   em(20px) 0;

    p {
        text-align: center;
    }

    @at-root #{&}--wide {
        max-width: em(1200px);
    }
}

// endregion: blurb

// region: content

.content {
    max-width: em(650px);
    margin:    auto;
    padding:   1em;

    @at-root .content--full-width {
        max-width: none;
        padding:   0;
    }

    @at-root .content--wide {
        max-width: em(1200px);
    }

    @at-root .content--nav {
        max-width: 60em;
        @media (min-width: 731px) {
            padding-left:  2em;
            padding-right: 2em;
        }

        @media (max-width: 730px) {
            padding-left:  1.5em;
            padding-right: 1.5em;
        }

        @media (max-width: $phone) {
            padding-left:  1em;
            padding-right: 1em;
        }
    }

    @at-root .content--medium {
        max-width: em(1000px);
    }

    @at-root .content--order {
        max-width: em(880px);
    }

    @at-root .content--narrow {
        max-width: em(800px);
    }

    @at-root .content--slim {
        max-width: em(550px);
    }

    @at-root .content--centered {
        text-align: center;
    }
}

// endregion: content
