.old-hero-image {
    height:              calc(100vh - 60px);
    min-height:          600px;
    margin:              0;
    background-repeat:   no-repeat;
    display:             flex;
    justify-content:     center;
    align-items:         center;
    background-size:     cover;
    position:            relative;
    padding-top:         2em;
    padding-bottom:      2em;

    @media (max-width: 799px) {
        background-image:      imgurl('campaigns/new_universe/hiper_mobile.jpg');
        background-attachment: local;
        background-position:   top center;
    }
    @media (min-width: 800px) {
        background-image:      imgurl('campaigns/new_universe/hiper_desktop.jpg');
        background-attachment: fixed;
        background-position:   center;
    }
}

.new-hero-content {
    max-width:             90%;
    margin:                0;
    padding:               2em 1em 1em 1em;
    background-color:      rgba(255, 255, 255, .87);
    backdrop-filter:       blur(2px);
    -moz-border-radius:    5px;
    -webkit-border-radius: 5px;
    border-radius:         5px;
    -khtml-border-radius:  5px;
    margin-top:            2em;
    margin-bottom:         2em;
	position: relative;

    @media (min-width: 800px) {
        margin: 0;
    }

    @media (min-width: 1000px) {
        width: 80%;
    }

    .text-container {
        display:         flex;
        width:           100%;
        position:        relative;
        justify-content: center;

        @media (max-width: 799px) {
            flex-direction:  column;
            align-items:     center;
        }

        @media (min-width: 800px) {
            flex-direction: row;
        }

        .column {
            height:     auto;
            text-align: center;
            padding:    0em;
        }



        .price-container-full {
            .price-container {
                display: flex;
                align-items: flex-start;

                h1, h2, p {
                    margin: 0;
                    padding: 0;
                }

                .speed {
                    display: flex;
                    flex-direction: column;
                    

                    h1 {
                        font-size: 5em;
                        line-height: .8;
                    }

                    .til-kun {
                        color: $hiper-blue;
                    }

                    &.center {
                        h1, .til-kun {
                            text-align: center;
                        }
                    }
    
                    &.right {
                        h1, .til-kun {
                            text-align: right;
                        }

                        .til-kun {
                            margin-right: 0.05em;
                        }
                    }
                }

                .price {
                    display: flex;
                    flex-direction: column;

                    h1 {
                        margin-bottom: .2em;
						
                    }

                    p {
                        text-align: left;
                        line-height: 1.2;
                    }
                }

                @media (max-width: 449px) {
                    flex-direction: column;
                    align-items: center;

                    .speed {
                        h1 {
                            font-size: 6em;
                        }

                        .til-kun {
                            font-size: 2em;
                            line-height: 1.4;
                            margin-top: -0.25em;
                            margin-bottom: .2em;
                        }

                        &.right {
                            h1, .til-kun {
                                text-align: center;
                                margin-right: 0;
                            }
                        }
                    }

                    .price {
                        align-items: center;
                        width: 100%;

                        h1 {
                            
							text-align: center;
                            font-size: 8em;
                            line-height: .7;

                            .comma-dash {
                                position: absolute;
                            }
                        }

                        p {
                            margin-top: -0.75em;
                        }
                    }
                }

                @media (min-width: 450px) {
                    flex-direction: row;

                    .speed {
                        margin-right: 1em;

                        .til-kun {
                            font-size: 3.1em;
                            line-height: .9;
                        }
                    }

                    .price {
                        h1 {
                            text-align: left;
                            font-size: 8.8em;
                            line-height: .75;
                        }

                        p {
                            margin-left: .5em;
                        }
                    }
                }
            }
        }
    }

    .hero-text {
        h1,
        h2,
        h3,
        p {
            text-align: center;
        }
    }

    .hastighed h1 {
        font-size:   4em;
        line-height: 0.3em;
        margin-top:  0.32em;
        text-align:  center;
        @media (min-width: 800px) {

            text-align: right;
        }
    }

    .hastighed-campaigns h1 {
        font-size:   4.2em;
        line-height: 0.3em;
        margin-top:  0.32em;
        text-align:  center;
        @media (min-width: 800px) {
            text-align: right;
        }
    }

    .sub-line {
        font-size:   2.9em;
        text-align:  center;
        line-height: 0.8em;
        @media (min-width: 800px) {
            text-align: right;
        }

	}
	
	.sub-line-long {
        font-size:   2.25em;
        text-align:  center;
        line-height: 0.8em;
        @media (min-width: 800px) {
            text-align: right;
        }

    }

    .sub-line-short {
        font-size:   2.9em;
        text-align:  center;
        line-height: 0.8em;
        @media (min-width: 800px) {
            text-align: right;
            font-size: 3.05em;
        }

    }

    .pris {
        width:      100%;
        margin-top: 2em;
        @media (min-width: 800px) {
            margin-left: 1.8em;
            margin-top:  2.8em;
        }
		
		@media (max-width: 799px) {
           
            margin-top:  3.5em;
			margin-bottom: 2em;
			
        }

        h1 {
            line-height: 0.15em;
            font-size:   8em;
            text-align:  center;
            @media (min-width: 800px) {
                text-align: left;
            }
			@media (max-width: 800px) {
               
				margin-top:  0.4em;
				margin-bottom: 0.1em;
            }
			
        }
    }

    .payoff h1 {
        font-size: 1.5em;

        @media (min-width: 450px) {
            font-size: 2em;
        }
        @media (min-width: 800px) {
            font-size: 3em;
        }
    }

    .hero-address {
        width:      100%;
        margin-top: 1em;

        h1 {
            font-size:  2em;
            padding:    0;
            margin-top: 1.5em;

            @media (min-width: 800px) {
                margin-top: 0em;
            }
        }

        h3 {
            padding: 0;
        }

        p {
            line-height: 1.3em;
        }
    }

    @at-root .comma-dash {
        letter-spacing: -0.1em;
        margin-right:   .1em;
    }

    .md {
        position:       relative;
        font-size:      0.2em;
        font-weight:    300;
        text-transform: lowercase;
        margin-top:     -1.8em;
        top:            0;
        display:        block;
        @media (min-width: 270px) {
            margin-left: 6.8em;
        }
        @media (min-width: 800px) {
            margin-left: 6.3em;
        }
    }

    .md-campaigns {
        position:       relative;
        font-size:      0.2em;
        font-weight:    300;
        text-transform: lowercase;
        margin-top:     -1.8em;
        top:            0;
        display:        block;

        @media (min-width: 270px) {
            margin-left: 6.3em;
        }
        @media (min-width: 800px) {
            margin-left: 5.7em;

        }

    }

    .splash {
        position: relative;

        .splash__wrapper {
            background-color: $hiper-blue;

            @media (max-width: 449px) {
                padding: 0.2em 1em 0.1em 1em;
            }
            @media (min-width: 450px) {
                padding: 0.4em 0.8em;
            }

            .splash__content {
                color: #fff;
                line-height: 1.2;
                padding: 0;
                white-space: nowrap;

                &.splash__content__small-text {
                    font-size: 2em;
                }
            }
        }

        @media (max-width: 799px) {
            display:         flex;
            justify-content: center;

            .splash__wrapper {
                margin-top: 1em;
            }
        }

        @media (min-width: 800px) {
            .splash__wrapper {
                position:        absolute;
                transform:       translate(0, -50%) rotate(15deg);
                top:             40%;
                left:            2em;
                box-shadow:      rgba(0, 0, 0, .3) 5px 5px 10px;
            }
        }
    }

    .splash-wrapper-fibernet {
        display: flex;
        justify-content: center;

        @media (min-width: 270px) {
            margin-top:      4em;
        }
        @media (min-width: 800px) {
            margin-top:      1em;
            position:        absolute;
            transform:       translate(-50%, -50%) rotate(15deg);
            top:             15%;
            left:            7em;
            box-shadow:      rgba(0, 0, 0, .3) 5px 5px 10px;
        }
        
        .spar-splash-fibernet {
            padding:          0.5em 0.7em 0 0.6em;
            background-color: #293342;
            width:            10em;

            h2 {
                color: #ffffff;
            }
        }
    }

    .splash-wrapper-fiberjylland {
        @media (min-width: 270px) {
            display:         flex;
            justify-content: center;
            margin-top:      4em;
        }
        @media (min-width: 800px) {
            display:         flex;
            justify-content: center;
            margin-top:      1em;
            position:        absolute;
            transform:       translate(-50%, -50%) rotate(15deg);
            top:             15%;
            left:            9em;
            box-shadow:      rgba(0, 0, 0, .3) 5px 5px 10px;
        }

        .spar-splash-fiberjylland {
            padding:          0.5em 0.7em 0 0.6em;
            background-color: #293342;
            width:            13em;

            h2 {
                color:     #ffffff;
                font-size: 2em;
            }
        }
    }
	
	.splash-wrapper-coax-fiber {
        @media (min-width: 270px) {
            display:         flex;
            justify-content: center;
            margin-top:      2em;
        }
        @media (min-width: 800px) {
            display:         flex;
            justify-content: center;
            margin-top:      1em;
            position:        absolute;
            transform:       translate(-50%, -50%) rotate(15deg);
            top:             15%;
            left:            9em;
            box-shadow:      rgba(0, 0, 0, .3) 5px 5px 10px;
        }

        .spar-splash-coax-fiber {
            padding:          0.5em 0.7em 0 0.6em;
            background-color: #293342;
            width:            13em;

            h2 {
                color:     #ffffff;
                font-size: 2em;
            }
        }
    }

    .splash-wrapper-order-now {
        display:         flex;
        justify-content: center;

        @media (min-width: 270px) {
            margin-top:      4em;
        }
        @media (min-width: 800px) {
            margin-top:      2em;
            position:        absolute;
            transform:       translate(-50%, -50%) rotate(15deg);
            top:             15%;
            left:            9em;
            box-shadow:      rgba(0, 0, 0, .3) 5px 5px 10px;
        }

        .spar-splash-order-now {
            padding:          0.4em 0.8em;
            background-color: $hiper-blue;

            h2 {
                color:     #ffffff;
                font-size: 1.5em;
                line-height: 1.2;
                font-weight: 400;
                padding: 0;
                white-space: nowrap;
            }
        }
    }
}


//new splash

.org {

    transform: translate(-50%, -50%) rotate(-15deg);
    top: 5em;
    left: 15%;
    font-size: 1.5em;

    @media (max-width: 1550px) {

        font-size: 1.2em;
        top: 8em;

    }

    @media (max-width: 1200px) {

        left: 10%;

    }

    @media (max-width: 799px) {


        top: 10em;
        left: 15%;


    }

    @media (max-width: 450px) {

        font-size: 1em;
        top: 45%;
        left: 3.25em;


    }
	
	@media (max-width: 320px) {

        font-size: 0.7em;
        top: 45%;
        left: 3.25em;


    }


}



