$phone-small:             350px;
$phone:                   520px;
$tablet:                  768px;
$desktop:                 1024px;

// 'Web-safe' font stacks
$font-system:             -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
$font-georgia:            Georgia, Times, 'Times New Roman', serif;
$font-helvetica:          'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-lucida:             'Lucida Grande', Tahoma, Verdana, Arial, sans-serif;
$font-verdana:            Verdana, 'Bitstream Vera Sans', sans-serif;
$font-monaco:             Monaco, Consolas, 'Courier New', Courier, 'Liberation Mono', monospace;
$font-mono:               $font-monaco;

$font-condensed-fallback: (
                              'League Gothic',
                              'HelveticaNeue-CondensedBold',
                              'AvenirNextCondensed-Bold',
                              'Futura-CondensedExtraBold',
                              'Ubuntu Condensed',
                              'Liberation Sans Narrow',
                              'Franklin Gothic Demi Cond',
                              'Arial Narrow',
                              sans-serif
                          );

$font-cervo:              'Cervo', $font-condensed-fallback;
$font-montserrat:         'Montserrat', $font-system;

$height-header-big:       em(120px);
$height-header:           em(60px);

$z-index-menu-icon:       45;
$z-index-menu-canvas:     40;
$z-index-header:          35;
$z-index-header-menu:     30;
$z-index-selfcare-menu:   25;
$z-index-overlay:         10;
$z-index-call-me:         7;
$z-index-cookie-warning:  6;
$z-index-address-picker:  5;

// Colors

$hiper-teal:              #4b848e;
$hiper-darkteal:          darken($hiper-teal, 5%);
$hiper-lightteal:         #7ea7ad;
$hiper-blue:              #303a4b;
$hiper-bluegray:          #344049;
$hiper-gray:              #dad9d7;
$hiper-lightgray:         #eeeeee;
$hiper-lightergray:       #f7f7f7;
$hiper-darkgray:          #999999;
$hiper-darkergray:        #666666;
$hiper-lightblue:         #7acfe0;
$hiper-red:               #ed135a;
$hiper-darkred:           darken($hiper-red, 5%);
$hiper-bloodred:          #be1421;
$hiper-slate:             #2e3139;
$hiper-seagreen:          #7ea7ad;
$hiper-seablue:           #83afc5;
$hiper-pastel-lightblue:  #a3d6df;
$hiper-darkblue:          #31393f;
$hiper-green:             #40b577;
$hiper-darkgreen:         #62a078;
$hiper-fiber:             $hiper-red;
$hiper-coax:              $hiper-lightblue;
$hiper-dsl:               $hiper-darkgray;
$hiper-mbb:               $hiper-teal;
$hiper-yellow:            #f3d908;
$hiper-pale-yellow:       #fee287;
$hiper-candyfloss:        #ffdddd;
$hiper-business:          #085c66;
$hiper-blue-rich:         #353d60;

$color-alto:              #dddddd;
$color-cod-gray:          #111111;
$color-dawn:              #a6a29a;
$color-dusty-gray:        #999999;
$color-eden:              #0f4a56;
$color-facebook:          #1877f2;
$color-gallery:           #eeeeee;
$color-glacier:           #83aec4;
$color-gray-nickel:       #c7c7c6;
$color-manatee:           #94979e;
$color-ming:              #3f818b;
$color-pewter:            #94a69a;
$color-quill-gray:        #d1d0ce;
$color-shark:             #252628;
$color-silver:            #bcbcbc;
$color-tundora:           #444444;
$color-wild-sand:         #f6f6f6;
$color-woodsmoke:         #1e2025;
$color-light-gray:        #959595;
$color-almost-black:      #404040;
$color-dark-grayish-blue: #4b5261;

$color-transparent-dark:  rgba(0, 0, 0, 0);
$color-transparent-light: rgba(255, 255, 255, 0);
$color-transparent-red:     rgba($hiper-red, 0);

$color-focus:             $hiper-lightteal;
$color-neutral:           $hiper-darkgray;
$color-error:             $hiper-red;

// https://github.com/thoughtbot/bourbon/blob/v4-stable/app/assets/stylesheets/addons/_timing-functions.scss
$ease-in-quad:            cubic-bezier(.550, .085, .680, .530);
$ease-in-cubic:           cubic-bezier(.550, .055, .675, .190);
$ease-in-quart:           cubic-bezier(.895, .030, .685, .220);
$ease-in-quint:           cubic-bezier(.755, .050, .855, .060);
$ease-in-sine:            cubic-bezier(.470, .000, .745, .715);
$ease-in-expo:            cubic-bezier(.950, .050, .795, .035);
$ease-in-circ:            cubic-bezier(.600, .040, .980, .335);
$ease-in-back:            cubic-bezier(.600, -.280, .735, .045);

$ease-out-quad:           cubic-bezier(.250, .460, .450, .940);
$ease-out-cubic:          cubic-bezier(.215, .610, .355, 1);
$ease-out-quart:          cubic-bezier(.165, .840, .440, 1);
$ease-out-quint:          cubic-bezier(.230, 1, .320, 1);
$ease-out-sine:           cubic-bezier(.390, .575, .565, 1);
$ease-out-expo:           cubic-bezier(.190, 1, .220, 1);
$ease-out-circ:           cubic-bezier(.075, .820, .165, 1);
$ease-out-back:           cubic-bezier(.175, .885, .320, 1.275);

$ease-in-out-quad:        cubic-bezier(.455, .030, .515, .955);
$ease-in-out-cubic:       cubic-bezier(.645, .045, .355, 1);
$ease-in-out-quart:       cubic-bezier(.770, .000, .175, 1);
$ease-in-out-quint:       cubic-bezier(.860, .000, .070, 1);
$ease-in-out-sine:        cubic-bezier(.445, .050, .550, .950);
$ease-in-out-expo:        cubic-bezier(1, .000, .000, 1);
$ease-in-out-circ:        cubic-bezier(.785, .135, .150, .860);
$ease-in-out-back:        cubic-bezier(.680, -.550, .265, 1.550);

$header-transition:       .3s $ease-in-out-quad;
$address-transition:      .2s $ease-in-out-quad;

:root {
    --letter-spacing-small:         0;
    --letter-spacing-medium:        0;
    --letter-spacing-big:           0;

    --letter-spacing-small-always:  .01rem;
    --letter-spacing-medium-always: .0275rem;
    --letter-spacing-big-always:    .045rem;

    @media (min-width: $tablet) {
        --letter-spacing-small:  .01rem;
        --letter-spacing-medium: .0275rem;
        --letter-spacing-big:    .045rem;
    }

    @media (min-width: 1201px) {
        --hero-max-width: 56rem;
    }

    @media (max-width: 1200px) {
        --hero-max-width: 48rem;
    }

    @media (min-width: 521px) {
        --box-shadow:                     0 .2em .6em rgba(0, 0, 0, .2);
        --box-shadow-hover:               0 .1em .3em rgba(0, 0, 0, .2);
        --box-shadow-hover-inverse:       0 .3em 1em rgba(0, 0, 0, .3);
        --box-shadow-small:               0 .1em .3em rgba(0, 0, 0, .2);
        --box-shadow-small-hover:         0 .05em .15em rgba(0, 0, 0, .1);
        --box-shadow-small-hover-inverse: 0 .15em .6em rgba(0, 0, 0, .25);
    }

    @media (max-width: $phone) {
        --box-shadow:                     0 .1em .3em rgba(0, 0, 0, .25);
        --box-shadow-hover:               0 .05em .15em rgba(0, 0, 0, .25);
        --box-shadow-hover-inverse:       0 .15em .6em rgba(0, 0, 0, .3);
        --box-shadow-small:               0 .05em .15em rgba(0, 0, 0, .25);
        --box-shadow-small-hover:         0 .025em .1em rgba(0, 0, 0, .25);
        --box-shadow-small-hover-inverse: 0 .075em .25em rgba(0, 0, 0, .3);
    }
}
