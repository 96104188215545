.service-status-wrapper {
    display:               grid;
    grid-template-columns: 1fr;
    align-items:           start;
    grid-gap:              1em;

    @media (max-width: 1029px) {
        grid-gap: .75em;
    }
}

.service-status--no-issues,
.service-status--mbb-button-container {
    margin-bottom: 1em;

    .content {
        border-radius:    1em;
        background-color: #ffffff;
        box-shadow:       var(--box-shadow);
    }
}

.service-status--mbb-button {
    box-shadow: var(--box-shadow);
}

.service-status-issues {
    margin:          auto;
    list-style-type: none;
    display:         flex;
    flex-direction:  column;
    gap:             .5em;
}

.service-status-issue,
.service-status-messages {
    transition: all .2s $ease-in-out-quad;
}

.service-status-issue,
.service-status-issue-message {
    .created_dtm,
    .status,
    .message_created_dtm {
        min-width:      6.5em;
        padding:        .2em .4em .1em;
        border-radius:  .25em;
        font-family:    $font-cervo;
        font-weight:    300;
        line-height:    1;
        text-align:     center;
        letter-spacing: .035rem;
        text-transform: uppercase;
    }
}

.service-status-issue {
    width:         100%;
    padding:       .7em 1em;
    border:        0;
    border-radius: .5em;
    background:    #ffffff;
    box-shadow:    var(--box-shadow);
    text-align:    left;

    &.service-status-issue__pinned {
        box-shadow: 0 0 0 2px $hiper-red,
                    var(--box-shadow);
    }

    .service-status-issue__main {
        display:         flex;
        align-items:     flex-start;
        gap:             .5em;
        text-decoration: none;
    }

    &:hover {
        color:  inherit;
        cursor: default;
    }

    &.service-status-issue__has-messages {
        &:hover:not(.service-status-issue__unfolded) {
            box-shadow: var(--box-shadow-hover);
            color:      inherit;
            cursor:     pointer;

            .read-more {
                border-bottom-color: $color-transparent-dark;
                color:               $hiper-red;
            }
        }
    }

    .created_dtm-and-status {
        display:        flex;
        flex-direction: column;
        gap:            .25em;
        padding-top:    .3em;
        transition:     inherit;
    }

    .created_dtm, .status {
        color: #ffffff;
    }

    .created_dtm {
        background: $hiper-blue;
    }

    &.service-status-issue__open .status {
        background-color: $hiper-red;
    }

    &.service-status-issue__closed .status {
        background-color: $hiper-green;
    }

    .details {
        display:        flex;
        flex:           1;
        flex-direction: column;

        * {line-height: 1.4;}

        .subject {
            @include        font-weight-variable(500);
            padding:        0 0 .35em 0;
            font-family:    $font-montserrat;
            font-size:      1em;
            color:          inherit;
            text-align:     left;
            letter-spacing: normal;
            text-transform: none;
        }

        span {
            padding-bottom: .35em;

            &:last-child {padding-bottom: 0;}
        }

        & > *:not(.subject) {
            font-size: .9em;
        }
    }

    .read-more {
        border-bottom: 2px solid $hiper-red;
        color:         inherit;
        white-space:   nowrap;
        transition:    all .2s $ease-in-out-quad;

        .hide {
            display: none;
        }

        .show {
            display: inline;
        }
    }

    @media (max-width: 700px) {
        text-align: center;

        .mobile-hide {
            display: none;
        }

        .service-status-issue__main {
            flex-direction: column;
            align-items:    center;
            justify-items:  center;
            gap:            .5em;
        }

        .created_dtm-and-status {
            flex-direction: row;
            flex-wrap:      wrap;
            padding-top:    0;
        }

        .status,
        .created_dtm {
            display:   block;
            min-width: 0;
            font-size: 1.2em;
        }

        .details {
            gap:           .25em;
            margin-bottom: 0;
            padding:       0;
            width:         100%;

            .area, .eta, .subject, .finished_dtm, .updated_dtm {
                display:    block;
                padding:    0;
                text-align: left;
            }
        }

        .updated_dtm--date {
            display: block;
        }

        .read-more {
            display: inline-block;

            &:first-letter {
                text-transform: capitalize;
            }
        }
    }
}

.service-status-map {
    width:      100%;
    max-width:  26em;
    max-height: 100%;
    margin:     auto;

    &.service-status-map__empty {
        max-width: 16em;
    }

    &:hover {
        .area.active .issues {
            animation: none;
        }
    }

    svg {
        width:      100%;
        max-height: 100%;
    }

    .area {
        fill:   $hiper-gray;
        cursor: default;

        .issues {
            transition: inherit;
        }

        .count-circle {
            transition: inherit;
        }

        .count {
            @include    font-weight-variable(600);
            font-size:  5em;
            transition: inherit;
        }
    }

    .area.active {
        fill:   $hiper-red;
        cursor: pointer;

        .count-circle {fill: $hiper-blue;}
        .count        {fill: #ffffff;}
        &:hover       {fill: $hiper-darkred;}
    }
}

.id-anchor--mbb-service-status {
    transform: translateY(calc(-3.75em - 2em));
}

#mbb-service-status {
    p {
        max-width:   38em;
        text-align:  center;
        line-height: 1.4;
    }

    iframe {
        border-radius: .5em;
        box-shadow:    var(--box-shadow);

        @media (min-width: 701px) {
            min-height: 560px;
            height:     calc(100vh - 3.75em - 8em);
        }

        @media (max-width: 700px) {
            height: calc(100vh - 3.75em - 2.5em - 56px - 40px - 3em);
        }
    }

}

.issues-finished {
    padding-top: 1em;

    .service-status-issue {
        .read-more {
            border-bottom-color: $hiper-darkgray;
        }

        &.service-status-issue__has-messages:hover {
            .read-more {
                border-bottom-color: rgba($hiper-darkgray, 0);
                color:               $hiper-darkgray;
            }
        }
    }
}

.issues-finished--list {
    transition: padding-top  .3s $ease-in-cubic,
                max-height .3s $ease-in-cubic,
                opacity .3s $ease-in-cubic;
}

.issues-finished--list__hidden .issues-finished--list {
    @include hidden;
    padding-top: 0;
    max-height:  0;
    opacity:     0;
}

.issues-finished--list__visible .issues-finished--list {
    @include visible;
    padding-top: 1.5em;
    max-height:  200em;
    opacity:     1;
}

.issues-finished--button {
    .hide {display: none;}
}

.issues-finished--list__visible {
    .issues-finished--button {
        background-color: $hiper-lightergray;
        box-shadow:       inset 0 0 0 1px $hiper-darkgray;
        color:            $hiper-blue;

        &:hover, &:focus {
            background-color: $hiper-lightgray;
        }

        .show {display: none;}
        .hide {display: inline;}
    }
}

.service-status-issue-messages {
    @include    hidden;
    max-height: 0;
    transition: all .3s $ease-out-circ;
    opacity:    0;
}

.service-status-issue__messages-visible {
    .service-status-issue {
        &.service-status-issue__has-messages:hover {
            background: $hiper-lightgray;
            cursor:     default;
        }
    }

    .read-more {
        .show {display: none;}
        .hide {display: inline;}
    }

    .service-status-issue-messages {
        @include    visible;
        max-height: 200em;
        transition: all .2s $ease-in-cubic;
        opacity:    1;
    }
}

.service-status-issue-message {
    display:     flex;
    align-items: flex-start;
    gap:         .5em;
    padding:     .9em 0 .5em;
    border-top:  1px solid $hiper-gray;
    text-align:  left;

    &:first-child {margin-top:    .5em;}
    &:last-child  {padding-bottom: 0;}

    .message_created_dtm,
    .message {
        display: block;
    }

    .message_created_dtm {
        background-color: $hiper-lightgray;
        box-shadow:       inset 0 0 0 1px $hiper-gray;
    }

    .message {
        font-size:   .9em;
        line-height: 1.4;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        align-items:    center;

        .message_created_dtm {
            display:   block;
            min-width: 0;
            font-size: 1.2em;
        }
    }
}
