@charset "UTF-8";
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote,
body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt,
em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6,
header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, main, mark,
menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span,
strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time,
tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main,
menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
}

:root {
  --letter-spacing-small: 0;
  --letter-spacing-medium: 0;
  --letter-spacing-big: 0;
  --letter-spacing-small-always: .01rem;
  --letter-spacing-medium-always: .0275rem;
  --letter-spacing-big-always: .045rem;
}
@media (min-width: 768px) {
  :root {
    --letter-spacing-small: .01rem;
    --letter-spacing-medium: .0275rem;
    --letter-spacing-big: .045rem;
  }
}
@media (min-width: 1201px) {
  :root {
    --hero-max-width: 56rem;
  }
}
@media (max-width: 1200px) {
  :root {
    --hero-max-width: 48rem;
  }
}
@media (min-width: 521px) {
  :root {
    --box-shadow: 0 .2em .6em rgba(0, 0, 0, .2);
    --box-shadow-hover: 0 .1em .3em rgba(0, 0, 0, .2);
    --box-shadow-hover-inverse: 0 .3em 1em rgba(0, 0, 0, .3);
    --box-shadow-small: 0 .1em .3em rgba(0, 0, 0, .2);
    --box-shadow-small-hover: 0 .05em .15em rgba(0, 0, 0, .1);
    --box-shadow-small-hover-inverse: 0 .15em .6em rgba(0, 0, 0, .25);
  }
}
@media (max-width: 520px) {
  :root {
    --box-shadow: 0 .1em .3em rgba(0, 0, 0, .25);
    --box-shadow-hover: 0 .05em .15em rgba(0, 0, 0, .25);
    --box-shadow-hover-inverse: 0 .15em .6em rgba(0, 0, 0, .3);
    --box-shadow-small: 0 .05em .15em rgba(0, 0, 0, .25);
    --box-shadow-small-hover: 0 .025em .1em rgba(0, 0, 0, .25);
    --box-shadow-small-hover-inverse: 0 .075em .25em rgba(0, 0, 0, .3);
  }
}

html, body {
  width: 100%;
  height: 100%;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  hyphens: manual;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
}
@media (min-width: 1401px) {
  html {
    font-size: 20px;
  }
}
@media (max-width: 1400px) {
  html {
    font-size: 19px;
  }
}
@media (max-width: 1024px) {
  html {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  html {
    font-size: 17px;
  }
}
@media (max-width: 520px) {
  html {
    font-size: 16px;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  background-color: #ffffff;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: #303a4b;
  line-height: 1.6;
  letter-spacing: var(--letter-spacing-small);
  opacity: 1;
  visibility: visible;
}

p, li, th, td {
  margin: 0 auto;
  padding: 0;
  padding-bottom: 0.7em;
  line-height: 1.6;
}

p:last-child {
  padding-bottom: 0;
}

.align-center {
  text-align: center;
}

a {
  border-bottom: 2px solid rgba(237, 19, 90, 0.75);
  color: inherit;
  text-decoration: none;
  transition: 0.2s all cubic-bezier(0.645, 0.045, 0.355, 1);
}
a:hover {
  border-bottom-color: rgba(237, 19, 90, 0);
  color: #ed135a;
}
a:focus {
  outline: 5px auto #83afc5;
}

.bullet-list li {
  position: relative;
  padding-bottom: 0.5em;
  padding-left: 1em;
}
.bullet-list li::before {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  position: absolute;
  left: 0;
  color: #ed135a;
  content: "•";
}

hr {
  height: 1px;
  border: 0;
  background-color: #dad9d7;
}
.content--slim hr {
  max-width: 28em;
}

@media (min-width: 521px) {
  hr {
    margin: 2em auto;
    max-width: calc(100% - 4em);
  }
}
@media (max-width: 520px) {
  hr {
    margin: 1em auto;
    max-width: calc(100% - 2em);
  }
}

strong, b {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}

.fw-500, b.fw-500, strong.fw-500 {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}

.fw-700, b.fw-700, strong.fw-700 {
  font-weight: 700;
  font-variation-settings: "wght" 700;
}

.fw-800, b.fw-800, strong.fw-800 {
  font-weight: 800;
  font-variation-settings: "wght" 800;
}

u {
  text-decoration: underline;
  text-decoration-color: inherit;
  text-underline-offset: 0.15em;
}

img {
  max-width: 100%;
}

pre {
  display: block;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  font-family: Monaco, Consolas, "Courier New", Courier, "Liberation Mono", monospace;
  font-size: 16px;
  font-weight: normal;
  text-align: left;
}

table {
  width: 100%;
  margin: auto;
  border-radius: 0.5em;
}

th, td {
  padding: 0.5em 1em;
  border: 0 solid transparent;
  text-align: left;
}
@media (max-width: 520px) {
  th, td {
    padding: 0.2em 0.4em;
  }
}

th {
  background: black;
  color: white;
}

tr th:first-child,
tr td:first-child {
  border-left-width: 2px;
}
tr th:last-child,
tr td:last-child {
  border-right-width: 2px;
}
tr:first-child th, tr:first-child td {
  border-top-width: 2px;
}
tr:first-child th:first-child, tr:first-child td:first-child {
  border-radius: 0.5em 0 0 0;
}
tr:first-child th:last-child, tr:first-child td:last-child {
  border-radius: 0 0.5em 0 0;
}
tr:first-child th:only-child, tr:first-child td:only-child {
  border-radius: 0.5em 0.5em 0 0;
}
tr:last-child th, tr:last-child td {
  border-bottom-width: 2px;
}
tr:last-child th:first-child, tr:last-child td:first-child {
  border-radius: 0 0 0 0.5em;
}
tr:last-child th:last-child, tr:last-child td:last-child {
  border-radius: 0 0 0.5em 0;
}
tr:last-child th:only-child, tr:last-child td:only-child {
  border-radius: 0 0 0.5em 0.5em;
}
tr:only-child th, tr:only-child td {
  border-top-width: 2px;
  border-bottom-width: 2px;
}
tr:only-child th:first-child, tr:only-child td:first-child {
  border-radius: 0.5em 0 0 0.5em;
}
tr:only-child th:last-child, tr:only-child td:last-child {
  border-radius: 0 0.5em 0.5em 0;
}
tr:only-child th:only-child, tr:only-child td:only-child {
  border-radius: 0.5em;
}

.table--plain {
  margin-bottom: 1em;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 400;
}
.table--plain td, .table--plain th {
  padding: 0.3125em 0.625em;
  border-color: #83afc5;
  font-size: 1em;
  color: inherit;
}
.table--plain tr:first-child th, .table--plain tr:first-child td {
  border-top-width: 2px;
}
.table--plain tr:first-child th:first-child, .table--plain tr:first-child td:first-child {
  border-left-width: 2px;
  border-radius: 0.5em 0 0 0;
}
.table--plain tr:first-child th:last-child, .table--plain tr:first-child td:last-child {
  border-right-width: 2px;
  border-radius: 0 0.5em 0 0;
}
.table--plain tr:first-child th:only-child, .table--plain tr:first-child td:only-child {
  border-left-width: 2px;
  border-right-width: 2px;
  border-radius: 0.5em 0.5em 0 0;
}
.table--plain tr:last-child th, .table--plain tr:last-child td {
  border-bottom-width: 2px;
}
.table--plain tr:last-child th:first-child, .table--plain tr:last-child td:first-child {
  border-left-width: 2px;
  border-radius: 0 0 0 0.5em;
}
.table--plain tr:last-child th:last-child, .table--plain tr:last-child td:last-child {
  border-right-width: 2px;
  border-radius: 0 0 0.5em 0;
}
.table--plain tr:last-child th:only-child, .table--plain tr:last-child td:only-child {
  border-left-width: 2px;
  border-right-width: 2px;
  border-radius: 0 0 0.5em 0.5em;
}
.table--plain tr:only-child th:first-child, .table--plain tr:only-child td:first-child {
  border-radius: 0.5em 0 0 0.5em;
}
.table--plain tr:only-child th:last-child, .table--plain tr:only-child td:last-child {
  border-radius: 0 0.5em 0.5em 0;
}
.table--plain tr:only-child th:only-child, .table--plain tr:only-child td:only-child {
  border-width: 2px;
  border-radius: 0.5em;
}
.table--plain tr:nth-child(even) td, .table--plain tr:nth-child(even) th {
  background-color: #f3f2f1;
}

.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0 0 0.2rem 0;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1.1;
  letter-spacing: var(--letter-spacing-big);
}
.heading.heading--montserrat, .heading.heading--paragraph,
h1.heading--montserrat,
h1.heading--paragraph,
h2.heading--montserrat,
h2.heading--paragraph,
h3.heading--montserrat,
h3.heading--paragraph,
h4.heading--montserrat,
h4.heading--paragraph,
h5.heading--montserrat,
h5.heading--paragraph,
h6.heading--montserrat,
h6.heading--paragraph {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-variation-settings: "wght" 600;
  line-height: 1.25;
  text-transform: none;
  letter-spacing: normal;
}
.heading.heading--paragraph,
h1.heading--paragraph,
h2.heading--paragraph,
h3.heading--paragraph,
h4.heading--paragraph,
h5.heading--paragraph,
h6.heading--paragraph {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  line-height: 1.6;
}
.heading a,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  border: 0;
}
.heading .no-wrap,
h1 .no-wrap,
h2 .no-wrap,
h3 .no-wrap,
h4 .no-wrap,
h5 .no-wrap,
h6 .no-wrap {
  white-space: nowrap;
}
@media (max-width: 520px) {
  .heading,
h1,
h2,
h3,
h4,
h5,
h6 {
    --letter-spacing-big: -.05rem;
  }
}

h1.heading--montserrat {
  font-size: 1.2em;
}

h1 b, h1 strong, h2 b, h2 strong, h3 b, h3 strong, h4 b, h4 strong, h5 b, h5 strong, h6 b, h6 strong {
  font-weight: 400;
}
h1.heading--montserrat b, h1.heading--montserrat strong, h2.heading--montserrat b, h2.heading--montserrat strong, h3.heading--montserrat b, h3.heading--montserrat strong, h4.heading--montserrat b, h4.heading--montserrat strong, h5.heading--montserrat b, h5.heading--montserrat strong, h6.heading--montserrat b, h6.heading--montserrat strong {
  font-weight: 800;
  font-variation-settings: "wght" 800;
}
h1.heading--paragraph b, h1.heading--paragraph strong, h2.heading--paragraph b, h2.heading--paragraph strong, h3.heading--paragraph b, h3.heading--paragraph strong, h4.heading--paragraph b, h4.heading--paragraph strong, h5.heading--paragraph b, h5.heading--paragraph strong, h6.heading--paragraph b, h6.heading--paragraph strong {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}

h2 {
  font-size: 2.5em;
}
h2 em {
  color: inherit;
}
h2.heading--montserrat {
  font-size: 1.2em;
}

h3 {
  padding-top: 1rem;
  font-size: 1.5em;
}
h3.heading--montserrat {
  font-size: 1.2em;
}

h4 {
  padding-top: 1rem;
  font-size: 1.2em;
}

.heading--bigger {
  font-size: 3.5em;
}
.heading--bigger.heading--montserrat, .heading--bigger.heading--paragraph {
  font-size: 2em;
}
@media (max-width: 520px) {
  .heading--bigger {
    font-size: 2.625em;
  }
  .heading--bigger.heading--montserrat, .heading--bigger.heading--paragraph {
    font-size: 1.8em;
  }
}

.heading--big {
  font-size: 2.375em;
}
.heading--big.heading--montserrat, .heading--big.heading--paragraph {
  font-size: 1.6em;
}

.heading--medium {
  font-size: 2.125em;
}
.heading--medium.heading--montserrat, .heading--medium.heading--paragraph {
  font-size: 1.2em;
}

.heading--small {
  font-size: 1.875em;
}
.heading--small.heading--montserrat, .heading--small.heading--paragraph {
  font-size: 1em;
}
@media (max-width: 520px) {
  .heading--small {
    --letter-spacing-big: -.03rem;
  }
}

.heading--smaller {
  font-size: 1.5em;
}
@media (max-width: 520px) {
  .heading--smaller {
    --letter-spacing-big: -.02rem;
  }
}

.heading--white {
  color: white;
}

.heading--red {
  color: #ed135a;
}

.heading--blue {
  color: #303a4b;
}

.heading--seagreen {
  color: #7ea7ad;
}

.heading--left {
  text-align: left;
}

.heading__title {
  display: block;
  margin-top: 0.4rem;
  padding-bottom: 0.2272727273em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: var(--letter-spacing-medium);
}
.heading--bigger .heading__title {
  font-size: 1.3rem;
}
@media (max-width: 520px) {
  .heading--bigger .heading__title {
    margin-top: 0.2272727273em;
    color: inherit;
  }
}
.heading--small .heading__title {
  font-size: 1.1rem;
}
@media (max-width: 520px) {
  .heading--small .heading__title {
    margin-top: 0.2272727273em;
    font-size: 1rem;
    color: inherit;
  }
}
.heading--smaller .heading__title {
  font-size: 1.1rem;
}
@media (max-width: 520px) {
  .heading--smaller .heading__title {
    margin-top: 0.2272727273em;
    font-size: 1rem;
    color: inherit;
  }
}
.heading--montserrat .heading__title {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  font-size: 1.1rem;
}
@media (max-width: 520px) {
  .heading--montserrat .heading__title {
    margin-top: 0.2272727273em;
    font-size: 1rem;
    color: inherit;
  }
}

.heading--tight {
  letter-spacing: var(--letter-spacing-small-always);
}

.heading--line-height {
  line-height: 1.2;
}

.hiper-text-stroke {
  position: relative;
}
.hiper-text-stroke::after {
  content: "";
  position: absolute;
  font-size: 1em;
  min-height: 2px;
  height: 0.07em;
  right: 0.1em;
  top: calc(100% - .15em);
  left: 0;
  transform: skewX(307.5deg);
}
.heading--montserrat .hiper-text-stroke::after {
  height: 0.1em;
}
.hiper-text-stroke::after {
  background: linear-gradient(77.5deg, rgba(237, 19, 90, 0) 0%, #ed135a 80%);
}

.hiper-text-stroke-white {
  position: relative;
}
.hiper-text-stroke-white::after {
  content: "";
  position: absolute;
  font-size: 1em;
  min-height: 2px;
  height: 0.07em;
  right: 0.1em;
  top: calc(100% - .15em);
  left: 0;
  transform: skewX(307.5deg);
}
.heading--montserrat .hiper-text-stroke-white::after {
  height: 0.1em;
}
.hiper-text-stroke-white::after {
  background: linear-gradient(77.5deg, rgba(255, 255, 255, 0) 0%, white 80%);
}

.fiber .tech-color {
  color: #ed135a;
}
.fiber .tech-bg {
  background: #ed135a;
}

.coax .tech-color {
  color: #7acfe0;
}
.coax .tech-bg {
  background: #7acfe0;
}

.dsl .tech-color {
  color: #999999;
}
.dsl .tech-bg {
  background: #999999;
}

.mbb .tech-color {
  color: #4b848e;
}
.mbb .tech-bg {
  background: #4b848e;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 2em;
}
.grid > .icon {
  display: block;
}

.grid__item-1 {
  flex: 1 1 0%;
}

.grid--small {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 1em;
}

.grid-triple,
.grid-triple-with-icons,
.grid-triple-with-count {
  align-items: stretch;
  counter-reset: grid;
  list-style-type: none;
}
.grid-triple li,
.grid-triple-with-icons li,
.grid-triple-with-count li {
  flex: 1 1 0px;
  border-radius: 0.1875em;
  padding: 0.9375em 0.625em 0.4375em 0.625em;
}
.grid-triple li > :last-child,
.grid-triple-with-icons li > :last-child,
.grid-triple-with-count li > :last-child {
  padding-bottom: 0;
}
.grid-triple-with-icons .icon,
.grid-triple-with-count li::before {
  display: block;
  width: 3.125em;
  height: 3.125em;
  margin: auto;
}

.grid-triple-with-count li::before {
  counter-increment: grid;
  content: "";
  content: counter(grid);
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 2.5em;
  width: 1.25em;
  border-radius: 0.625em;
  text-align: center;
  line-height: 1.25em;
  font-weight: 400;
  height: 1.25em;
}

@media (max-width: 660px) {
  .grid-triple,
.grid-triple-with-icons,
.grid-triple-with-count {
    flex-direction: column;
  }
  .grid-triple li,
.grid-triple-with-icons li,
.grid-triple-with-count li {
    padding: 1em;
    margin: 0 0 1em 0;
  }
  .grid-triple li:last-child,
.grid-triple-with-icons li:last-child,
.grid-triple-with-count li:last-child {
    margin-bottom: 0;
  }
  .grid-triple.grid-row-mobile li,
.grid-triple-with-icons.grid-row-mobile li,
.grid-triple-with-count.grid-row-mobile li {
    position: relative;
  }
  .grid-triple.grid-row-mobile li::before,
.grid-triple.grid-row-mobile li .icon,
.grid-triple-with-icons.grid-row-mobile li::before,
.grid-triple-with-icons.grid-row-mobile li .icon,
.grid-triple-with-count.grid-row-mobile li::before,
.grid-triple-with-count.grid-row-mobile li .icon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  }
  .grid-triple.grid-row-mobile li::before,
.grid-triple-with-icons.grid-row-mobile li::before,
.grid-triple-with-count.grid-row-mobile li::before {
    left: 0.4em;
    padding-top: 0.075em;
  }
  .grid-triple.grid-row-mobile li .icon,
.grid-triple-with-icons.grid-row-mobile li .icon,
.grid-triple-with-count.grid-row-mobile li .icon {
    left: 1em;
  }
  .grid-triple.grid-row-mobile li h3,
.grid-triple-with-icons.grid-row-mobile li h3,
.grid-triple-with-count.grid-row-mobile li h3 {
    text-align: left;
  }
}
.grid-triple li,
.grid-triple-with-icons li,
.grid-triple-with-count li {
  background: #a3d6df;
  color: #303a4b;
}
.grid-triple.no-boxes li,
.grid-triple-with-icons.no-boxes li,
.grid-triple-with-count.no-boxes li {
  background: transparent;
}
.grid-triple.darkteal p:not(.usp-svg-image),
.grid-triple-with-icons.darkteal p:not(.usp-svg-image),
.grid-triple-with-count.darkteal p:not(.usp-svg-image) {
  color: #ffffff;
}
.grid-triple-with-count li::before {
  background: #353d60;
  color: #a3d6df;
}

.grid-triple-with-icons .icon {
  fill: #a3d6df;
  color: #353d60;
}

.grid-triple-with-count.grid-red li::before {
  background: #ed135a;
  color: #ffffff;
}

.grid-triple-with-icons.grid-red .icon {
  fill: #ffffff;
  color: #ed135a;
}

.grid-triple-with-icons li .heading:first-of-type,
.grid-triple-with-count li .heading:first-of-type {
  padding-top: 0.625em;
}
@media (max-width: 660px) {
  .grid-triple-with-icons.grid-row-mobile li,
.grid-triple-with-count.grid-row-mobile li {
    padding-left: 5.125em;
  }
  .grid-triple-with-icons.grid-row-mobile li .heading:first-of-type,
.grid-triple-with-count.grid-row-mobile li .heading:first-of-type {
    padding-top: 0.35em;
  }
}
.grid-triple-with-icons li .heading,
.grid-triple-with-count li .heading {
  color: #303a4b;
}
.grid-triple-with-icons.darkteal h3,
.grid-triple-with-count.darkteal h3 {
  color: #ffffff;
}

.menugrid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menugrid.nowrap {
  flex-wrap: nowrap;
}
.menugrid .item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 0;
  border-radius: 0.5em;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.menugrid .item .icon {
  flex-shrink: 0;
  display: block;
  color: transparent;
  fill: #303a4b;
  transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.menugrid .item .headline {
  display: inline;
  margin: 0;
  border-bottom: solid 2px rgba(237, 19, 90, 0);
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  line-height: 1;
  color: #303a4b;
  hyphens: manual;
  font-weight: 300;
  letter-spacing: var(--letter-spacing-big);
  text-transform: uppercase;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.menugrid .item:hover {
  box-shadow: var(--box-shadow-hover);
}
.menugrid .item:hover .headline {
  border-bottom-color: #ed135a;
  color: #ed135a;
}
.menugrid .item:hover .icon {
  fill: #ed135a;
}
@media (min-width: 521px) {
  .menugrid {
    flex-wrap: wrap;
    gap: 0.5em;
    align-items: flex-start;
  }
  .menugrid .item {
    padding: 0 0.75em 0 0;
  }
  .menugrid .item .icon {
    width: 3em;
    height: 3em;
  }
  .menugrid .item .headline {
    padding: 0.25em 0 0;
    font-size: 1.2em;
  }
}
@media (max-width: 520px) {
  .menugrid {
    flex-wrap: wrap;
    gap: 0.25em;
  }
  .menugrid .item {
    flex: calc(50% - .25em) 1 1;
    gap: 0.25em;
    padding: 0 0.75em 0 0.5em;
    width: 100%;
  }
  .menugrid .item .icon {
    width: 4em;
    height: 4em;
  }
  .menugrid .item .headline {
    padding: 0.3em 0 0;
  }
}
@media (max-width: 870px) {
  .menugrid .item {
    font-size: 0.9em;
  }
  .menugrid .item .icon {
    font-size: 0.9em;
  }
}
@media (max-width: 600px) {
  .menugrid.nowrap {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 425px) {
  .menugrid .item {
    font-size: 0.8em;
  }
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-phone-column {
  flex-direction: row;
}
@media (max-width: 520px) {
  .flex-phone-column {
    flex-direction: column;
  }
}

.flex-phone-wrap {
  flex-wrap: nowrap;
}
@media (max-width: 520px) {
  .flex-phone-wrap {
    flex-wrap: wrap;
  }
}

button {
  font: inherit;
}

.button {
  display: inline-block;
  padding: 0.2em 0.75em;
  border: 0;
  border-radius: 0.5rem;
  background-color: #ed135a;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 300;
  color: #ffffff;
  font-size: 1.2em;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  transition: 0.2s all cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
@media (max-width: 520px) {
  .button {
    letter-spacing: 0;
  }
}
.button:hover, .button:focus {
  background-color: #d61051;
  color: #ffffff;
  text-decoration: none;
}
.button:hover .icon, .button:focus .icon {
  color: transparent;
}
.button.with-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}
.button.with-icon .icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  flex-shrink: 0;
  flex-grow: 0;
  color: transparent;
  fill: #ffffff;
}
.button.blue {
  background-color: #303a4b;
}
.button.blue:hover, .button.blue:focus {
  background-color: #2b3444;
}
.button.teal {
  background-color: #4b848e;
}
.button.teal:hover, .button.teal:focus {
  background-color: #42747d;
}
.button.gray {
  background-color: #f7f7f7;
  color: #303a4b;
  box-shadow: inset 0 0 0 1px #999999;
}
.button.gray .icon {
  fill: #303a4b;
}
.button.gray:hover, .button.gray:focus {
  background-color: #eeeeee;
}
.button.gray:hover .icon, .button.gray:focus .icon {
  fill: #303a4b;
}
.button.button--montserrat {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  padding: 0.4em 0.75em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  text-transform: none;
  letter-spacing: normal;
}
.button.big {
  padding: 0.25em 0.75em;
  font-size: 1.4em;
}
.button.big.button--montserrat {
  padding: 0.5em 1em;
  font-size: 1.2em;
}
.button.rounded {
  border-radius: 2em;
}
.button.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.wrap-thing {
  overflow: hidden;
  border-radius: 1em;
}
.wrap-thing img {
  display: block;
}

.wrap-thing-bar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.5em;
  height: 4rem;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 1.6em;
  line-height: 1.1;
  font-weight: 300;
  text-transform: uppercase;
  color: white;
  background: #4b848e;
}
.wrap-thing-bar a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0;
  letter-spacing: 0.05rem;
}
.wrap-thing-bar a:hover {
  color: rgba(255, 255, 255, 0.75);
}
.wrap-thing-bar a:hover .icon {
  fill: rgba(255, 255, 255, 0.75);
}
.wrap-thing-bar .icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  transition: inherit;
  fill: #ffffff;
  color: transparent;
}

.icon-link {
  border: 0;
  text-decoration: none;
}
.icon-link > .icon {
  display: inline-block;
  width: 1.125em;
  height: 1.125em;
  margin-left: 0.25em;
  vertical-align: middle;
  transition: inherit;
}
.icon-link > .text {
  border-bottom: 0.125em solid rgba(237, 19, 90, 0.75);
  transition: inherit;
}
.icon-link:hover > .icon {
  color: #303a4b;
}
.icon-link:hover > .text {
  border-color: rgba(0, 0, 0, 0);
  color: #ed135a;
}

.icon-link-left > .icon {
  margin-right: 0.25em;
  margin-left: 0;
}

.icon-link-rotate-180 > .icon svg {
  transform: rotate(180deg);
}

.image-link {
  display: block;
  position: relative;
  border-bottom: 0;
  border-radius: 0.25em;
  text-align: center;
}
.image-link img,
.image-link .image-link--overlay-label,
.image-link .image-link--text {
  text-transform: uppercase;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.image-link img {
  transition-property: filter;
  display: block;
  padding: 0.3125em;
  border-radius: 0.3125em;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.3));
}
.image-link .image-link--overlay-label {
  transition-property: background-color;
  border-radius: 0.1875em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5em 0.8em;
  font-size: 1em;
  line-height: 0.8;
  font-weight: 400;
  background-color: #303a4b;
  color: #ffffff;
  box-shadow: 0 5px 20px -10px rgba(0, 0, 0, 0.5);
}
.image-link .image-link--overlay-label span {
  font-size: 2.6em;
  line-height: 0.8;
}
.image-link .image-link--overlay-label::after {
  box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.2);
  display: block;
  height: 0;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0.5em;
  width: calc(100% - 1em);
  z-index: -1;
}
.image-link .image-link--text {
  transition-property: border, background-color, color;
  display: inline-block;
  padding: 0 0 0.3125em 0;
  letter-spacing: 0.03rem;
}
.image-link:hover, .image-link:focus {
  background: #eeedec;
}
.image-link:hover img, .image-link:focus img {
  filter: drop-shadow(0 0 0 rgba(0, 0, 0, 0));
}
.image-link:hover .image-link--overlay-label,
.image-link:hover .image-link--text, .image-link:focus .image-link--overlay-label,
.image-link:focus .image-link--text {
  text-decoration: none;
}
.image-link:hover .image-link--overlay-label, .image-link:focus .image-link--overlay-label {
  background-color: #ed135a;
}
.image-link:hover .image-link--text, .image-link:focus .image-link--text {
  border-bottom-color: rgba(0, 0, 0, 0);
  color: #ed135a;
}

.thin-slash {
  font-weight: 300;
}

.from-to {
  display: inline-block;
  position: relative;
  bottom: 0.1875em;
  margin: 0 -0.1875em;
  font-size: 0.6em;
  line-height: 0.8em;
}

.strikethrough-price {
  display: inline-block;
  position: relative;
}
.strikethrough-price::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-5%, -50%) rotate(-10deg);
  width: 110%;
  height: 2px;
  border-radius: 1px;
  background-color: #ed135a;
}

.section-white .align-center,
.section-teal .align-center {
  text-align: center;
}

.section-white {
  background-color: #ffffff;
}

.section-teal {
  background-color: #4b848e;
}
.section-teal p {
  color: #ffffff;
}

.tdc-fiber-delivery-warning {
  max-width: 30em;
  margin: 0.5em auto 0.75em auto;
  background: #ffffff;
  color: #ed135a;
  padding: 0.5em 1em;
  border-radius: 0.5em;
}

.syd-energi-delivery-warning {
  margin-bottom: 0.7em;
  background-color: #f7f7f7;
  padding: 0.5em 1em;
  border-radius: 0.5em;
}

.accordion-container {
  border-bottom: 1px solid #dad9d7;
}

.accordion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: none;
  border-top: 1px solid #dad9d7;
}
.accordion, .accordion:hover {
  color: #303a4b;
}
.accordion h1, .accordion h2, .accordion h3, .accordion h4, .accordion h5, .accordion h6 {
  padding: 0;
}
.accordion h1.title--montserrat, .accordion h2.title--montserrat, .accordion h3.title--montserrat, .accordion h4.title--montserrat, .accordion h5.title--montserrat, .accordion h6.title--montserrat {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  text-transform: none;
  letter-spacing: normal;
}
.accordion .title:not(.title--montserrat), .accordion .symbol {
  font-size: 1.5em;
}
.accordion .title {
  margin-right: 1em;
  text-align: left;
  line-height: 1.2;
  transition: color 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.accordion .title:not(.title--montserrat) {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
}
.accordion .title.title--montserrat {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.accordion .title.title--montserrat b, .accordion .title.title--montserrat strong {
  font-weight: 650;
  font-variation-settings: "wght" 650;
}
.accordion .symbol {
  height: 1em;
  position: relative;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.accordion .symbol span {
  position: absolute;
  display: block;
  background-color: #303a4b;
  top: 50%;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.accordion-content-container {
  text-align: left;
  max-height: 0;
}
.accordion-content-container:not(.accordion-content-container--footer) {
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: max-height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.accordion-content-container:not(.accordion-content-container--footer).active {
  opacity: 1;
  pointer-events: all;
}
.accordion-content-container:not(.accordion-content-container--footer).active.no-js-height {
  max-height: 999em;
}

details summary {
  cursor: pointer;
}

@media (min-width: 521px) {
  .accordion .title {
    max-width: calc(100% - 1em - 26px);
  }
  .accordion .symbol {
    width: 26px;
  }
  .accordion .symbol span {
    width: 16px;
    height: 2px;
    border-radius: 1px;
  }
  .accordion .symbol span:first-child {
    transform: translate(0, -50%) rotate(45deg);
  }
  .accordion .symbol span:last-child {
    transform: translate(10px, -50%) rotate(-45deg);
  }
  .accordion.active .symbol span:first-child {
    transform: translate(10px, -50%) rotate(45deg);
  }
  .accordion.active .symbol span:last-child {
    transform: translate(0, -50%) rotate(-45deg);
  }

  .accordion:not(.accordion--footer) {
    padding: 0.8em 0;
  }
  .accordion:not(.accordion--footer):hover:not(.active) .title {
    color: rgba(48, 58, 75, 0.75);
  }
  .accordion:not(.accordion--footer):hover:not(.active) .symbol span {
    background-color: rgba(48, 58, 75, 0.75);
  }

  .accordion-content {
    padding: 0 1em 1em 1em;
  }
}
@media (max-width: 520px) {
  .accordion {
    padding: 0.5em 0;
  }
  .accordion .title {
    max-width: calc(100% - 1em - 20px);
  }
  .accordion .symbol {
    width: 20px;
  }
  .accordion .symbol span {
    width: 12px;
    height: 1px;
  }
  .accordion .symbol span:first-child {
    transform: translate(0, -50%) rotate(45deg);
  }
  .accordion .symbol span:last-child {
    transform: translate(8px, -50%) rotate(-45deg);
  }
  .accordion.active .symbol span:first-child {
    transform: translate(8px, -50%) rotate(45deg);
  }
  .accordion.active .symbol span:last-child {
    transform: translate(0, -50%) rotate(-45deg);
  }

  .accordion-content {
    padding: 0 0.5em 1em 0.5em;
  }
}
.job-container {
  margin-bottom: 1.25em;
  padding: 1em;
  background-color: #ffffff;
  border-radius: 1em;
}
.job-container a.job-link {
  display: block;
  border: none;
}
.job-container h2 {
  padding-bottom: 0.7rem;
}
.job-container h3 {
  padding: 0.35rem 0 0 0;
}
.job-container p, .job-container li {
  font-size: 0.9em;
}
.job-container .cta {
  background-color: #ed135a;
  padding: 0.4em 0.4em 0.4em 0.8em;
  border-radius: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-container .cta p {
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.job-container .cta .icon {
  width: 2em;
  height: 2em;
  color: transparent;
  fill: #ffffff;
}

.coverage-map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.coverage-map-container .coverage-buttons, .coverage-map-container .coverage-map {
  max-width: 26em;
}
.coverage-map-container .coverage-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
  margin-bottom: 1em;
}
.coverage-map-container .coverage-buttons p {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  display: block;
  width: 100%;
  text-align: center;
  padding-bottom: 0;
}
.coverage-map-container .coverage-buttons button {
  border-radius: 0.75em;
  padding: 0.1em 0.5em;
  cursor: pointer;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 1em;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.015em;
  color: #303a4b;
  background-color: #ffffff;
  border: none;
  box-shadow: var(--box-shadow-small);
  transition: color 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;
}
.coverage-map-container .coverage-buttons button:hover:not(.active) {
  box-shadow: var(--box-shadow-small-hover);
}
.coverage-map-container .coverage-buttons button.active {
  color: #ffffff;
}
.coverage-map-container .coverage-buttons button.active.fiber {
  background-color: #ed135a;
}
.coverage-map-container .coverage-buttons button.active.coax {
  background-color: #7acfe0;
}
.coverage-map-container .coverage-buttons button.active.dsl {
  background-color: #999999;
}
.coverage-map-container .coverage-buttons button.active.mbb {
  background-color: #4b848e;
}
.coverage-map-container .coverage-buttons.coverage-buttons-primary p {
  font-size: 1.2em;
}
.coverage-map-container .coverage-buttons.coverage-buttons-primary button {
  font-size: 1.4em;
}
@media (max-width: 520px) {
  .coverage-map-container .coverage-buttons button {
    padding-top: 0.2em;
    border-radius: 0.8em;
  }
  .coverage-map-container .coverage-buttons.coverage-buttons-secondary button {
    font-size: 1.1em;
  }
}
.coverage-map-container.fiber-only .coverage-buttons-primary {
  display: none;
}
.coverage-map-container:not(.fiber-only) .coverage-buttons-secondary .coverage-fiber {
  display: none;
}
.coverage-map-container .coverage-map {
  filter: drop-shadow(var(--box-shadow-small));
  width: 100%;
}
.coverage-map-container .coverage-map svg {
  width: 100%;
  height: 100%;
}
.coverage-map-container .coverage-map svg g {
  transition: opacity 0.2s ease;
}
.coverage-map-container .coverage-map svg .map-blank {
  fill: #ffffff;
}
.coverage-map-container .coverage-map svg .coverage-region {
  opacity: 0;
}
.coverage-map-container .coverage-map svg .coverage-region.active {
  opacity: 1;
}
.coverage-map-container .coverage-map svg .coverage-fiber,
.coverage-map-container .coverage-map svg .coverage-fiber-ef,
.coverage-map-container .coverage-map svg .coverage-fiber-e,
.coverage-map-container .coverage-map svg .coverage-fiber-f,
.coverage-map-container .coverage-map svg .coverage-fiber-gc,
.coverage-map-container .coverage-map svg .coverage-fiber-ne,
.coverage-map-container .coverage-map svg .coverage-fiber-n,
.coverage-map-container .coverage-map svg .coverage-fiber-rah,
.coverage-map-container .coverage-map svg .coverage-fiber-sef,
.coverage-map-container .coverage-map svg .coverage-fiber-tdc,
.coverage-map-container .coverage-map svg .coverage-fiber-tm {
  fill: #ed135a;
}
.coverage-map-container .coverage-map svg .coverage-coax {
  fill: #7acfe0;
}
.coverage-map-container .coverage-map svg .coverage-dsl {
  fill: #999999;
}

#coverage-map-mbb {
  margin-bottom: 0.5em;
  width: 100%;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
@media (min-width: 521px) {
  #coverage-map-mbb {
    height: 600px;
  }
}
@media (max-width: 520px) {
  #coverage-map-mbb {
    height: calc(100vh - 12em);
  }
}

.coverage-map-disclaimer {
  font-size: 0.7em;
  text-align: center;
  color: rgba(48, 58, 75, 0.75);
}

.alert {
  text-align: center;
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 0.1875em;
  color: white;
}
.alert.error {
  background: #d61051;
}
.alert.success {
  background: #62a078;
}

.id-anchor {
  display: block;
  transform: translateY(calc(-3.75em - .25em));
  visibility: hidden;
  grid-area: anchor;
}

.id-anchor-address {
  transform: translateY(calc(-3.75em - 2.5em - .25em));
}

.page-waiting-list-index .waiting-list-permissions-disclaimer {
  display: block;
  font-size: 0.7em;
  line-height: 1.2;
  letter-spacing: var(--letter-spacing-small-always);
}
.page-waiting-list-index .usp {
  padding: 1em 0;
}
.page-waiting-list-index .usp .text {
  width: 90%;
}
.page-waiting-list-index .usp h4 {
  text-align: left;
  padding-top: 0;
}
.page-waiting-list-index .usp p {
  font-size: medium;
}
.page-waiting-list-index .usp img {
  width: 90%;
}
@media (max-width: 768px) {
  .page-waiting-list-index .usp .flex {
    flex-direction: column;
  }
  .page-waiting-list-index .usp img {
    width: 100%;
  }
  .page-waiting-list-index .usp .text {
    width: 100%;
    margin-bottom: 1em;
  }
  .page-waiting-list-index .usp h4 {
    padding-top: 1em;
  }
}
@media (max-width: 520px) {
  .page-waiting-list-index .usp {
    padding: 0 0.5em 0 0.5em;
  }
}

.page-home .header-steps,
.page-products .header-steps,
.page-order .header-steps {
  margin: 1em auto 0;
  max-width: 45em;
}
.page-home .grid--steps,
.page-products .grid--steps,
.page-order .grid--steps {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: stretch;
  gap: 1em 2em;
  margin: 1em auto;
}
.page-home .grid--steps .grid--item,
.page-products .grid--steps .grid--item,
.page-order .grid--steps .grid--item {
  display: grid;
  grid-template-columns: 3em auto;
  grid-template-rows: min-content auto;
  grid-template-areas: "num num" "icon text";
  gap: 0.5em 1em;
  padding: 1em;
  width: 16em;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
.page-home .grid--steps .grid--item .number,
.page-products .grid--steps .grid--item .number,
.page-order .grid--steps .grid--item .number {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  grid-area: num;
  align-self: center;
  justify-self: center;
  display: block;
  font-size: 2em;
  line-height: 1;
  color: #ed135a;
  text-align: center;
}
.page-home .grid--steps .grid--item .icon,
.page-products .grid--steps .grid--item .icon,
.page-order .grid--steps .grid--item .icon {
  grid-area: icon;
  display: block;
  width: 3em;
  height: 3em;
}
.page-home .grid--steps .grid--item h3, .page-home .grid--steps .grid--item h4,
.page-products .grid--steps .grid--item h3,
.page-products .grid--steps .grid--item h4,
.page-order .grid--steps .grid--item h3,
.page-order .grid--steps .grid--item h4 {
  grid-area: text;
  padding: 0;
  text-align: left;
  hyphens: manual;
}
.page-home .grid--steps .grid--item h3 span, .page-home .grid--steps .grid--item h4 span,
.page-products .grid--steps .grid--item h3 span,
.page-products .grid--steps .grid--item h4 span,
.page-order .grid--steps .grid--item h3 span,
.page-order .grid--steps .grid--item h4 span {
  display: block;
}
.page-home .grid--steps .grid--item h3 span:first-child, .page-home .grid--steps .grid--item h4 span:first-child,
.page-products .grid--steps .grid--item h3 span:first-child,
.page-products .grid--steps .grid--item h4 span:first-child,
.page-order .grid--steps .grid--item h3 span:first-child,
.page-order .grid--steps .grid--item h4 span:first-child {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-size: 0.9em;
}
.page-home .grid--steps .grid--item h3 span:last-child, .page-home .grid--steps .grid--item h4 span:last-child,
.page-products .grid--steps .grid--item h3 span:last-child,
.page-products .grid--steps .grid--item h4 span:last-child,
.page-order .grid--steps .grid--item h3 span:last-child,
.page-order .grid--steps .grid--item h4 span:last-child {
  font-size: 0.8em;
}

.privacy .heading--medium,
.cookies .heading--medium {
  padding-top: 1.4rem;
}
.privacy .heading--medium:first-of-type,
.cookies .heading--medium:first-of-type {
  padding-top: 0;
}
.privacy .heading--small,
.cookies .heading--small {
  padding-top: 0.7rem;
}

@media (min-width: 521px) {
  .page-not-found-content {
    padding: 2em 1em;
  }
}
@media (max-width: 520px) {
  .page-not-found-content {
    padding: 1em;
  }
}

.icon {
  color: #303a4b;
  fill: white;
}
.icon svg {
  width: 100%;
  height: 100%;
  overflow: visible;
}

/*
Size information for optical sizes, if ellipse is transparent.

Portrait:
    Ellipse is 1.333333 times taller than icon
    Icon is 0.75 times as tall as ellipse

Square:
    Ellipse is 1.6 times taller than icon
    Icon is 0.625 times as tall as ellipse

Landscape:
    Ellipse is 2 times taller than icon
    Icon is 0.5 times as tall as ellipse
*/
.icon-square-fill-fiber-fbsa,
.icon-square-fill-fiber,
.icon-fiber-fbsa,
.icon-fiber {
  color: #ed135a;
  fill: white;
}

.icon-square-fill-coax,
.icon-coax {
  color: #7acfe0;
  fill: white;
}

.icon-square-fill-dsl,
.icon-dsl {
  color: #999999;
  fill: white;
}

.icon-arrow {
  color: #ed135a;
  fill: white;
}

.icon-edit-pen {
  fill: #303a4b;
}

.icon-exclamation-mark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  color: #ffffff;
  background-color: #ed135a;
}
.icon-exclamation-mark span {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.75em;
}

.icon-wrench {
  color: #303a4b;
}

.icon-people {
  color: #94a69a;
}

.icon-fb {
  color: #1877f2;
}

.icon-dankort,
.icon-visa,
.icon-visa-electron,
.icon-mastercard {
  color: #4b848e;
}

.icon-rocket-color .rocket-body {
  fill: #ffffff;
}
.icon-rocket-color .rocket-wings {
  fill: #cccccc;
}
.icon-rocket-color .rocket-window {
  fill: #303a4b;
}
.icon-rocket-color .rocket-reflection {
  fill: none;
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  stroke-width: 0.125em;
}
.icon-rocket-color .rocket-flame-1 {
  fill: #eaaf1f;
}
.icon-rocket-color .rocket-flame-2 {
  fill: #e9314a;
}

input, textarea, select {
  width: 100%;
  background-color: white;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  letter-spacing: var(--letter-spacing-big);
  border: 1px solid #999999;
}

input {
  height: 2.5rem;
  margin: 0;
  padding: 0 0.625em;
  text-align: center;
}
input.error {
  box-shadow: 0 0 0 2px #ed135a;
  border-color: #ed135a;
}
input.error:focus {
  box-shadow: 0 0 0 2px #7ea7ad;
  border-color: #7ea7ad;
}
input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #7ea7ad;
  border-color: #7ea7ad;
}
.checkbox-label::before, input[type=text], input[type=password], input[type=number], input[type=email], input[type=tel] {
  border-radius: 0.5rem;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
input[type=password], input[type=number], input[type=email], input[type=tel] {
  -moz-appearance: textfield;
}
input[type=radio], input[type=checkbox] {
  width: 1px;
  height: 1px;
  position: absolute;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
.checkbox-label {
  color: #303a4b;
  cursor: pointer;
  user-select: none;
}
.checkbox-label:not(.checkbox-label--montserrat) {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 1.25em;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1.2;
}
.checkbox-label.checkbox-label--montserrat strong {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.checkbox-label.checkbox-label--box {
  display: block;
  min-height: 2.5rem;
  padding-top: 0.45rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 1px #999999;
  background-color: #f7f7f7;
  letter-spacing: 0.02rem;
  transition: all 0.12s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.checkbox-label i {
  font-family: inherit;
  font-weight: 300;
}
.checkbox-label::before {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  font-size: 1.6rem;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.5rem;
  float: left;
  background-color: #ffffff;
  color: #4b848e;
  line-height: 0.84;
  text-align: center;
  content: "";
  border: 1px solid #999999;
}
.checkbox-label a {
  color: #303a4b;
}
.checkbox-label a:hover {
  color: #ed135a;
  text-decoration: none;
}
.checkbox-label strong {
  font-weight: 400;
}

p.with-checkbox {
  color: #303a4b;
  user-select: none;
  position: relative;
}
p.with-checkbox .checkbox-label {
  cursor: pointer;
}
p.with-checkbox .checkbox-label::before {
  float: none;
  position: absolute;
  left: 0;
  margin: 0;
  text-indent: 0;
}
p.with-checkbox a {
  color: #303a4b;
}
p.with-checkbox a:hover {
  color: #ed135a;
  text-decoration: none;
}
p.with-checkbox .checkbox-label:not(.checkbox-label--montserrat) + a {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  text-transform: uppercase;
}
p.with-checkbox .checkbox-label:not(.checkbox-label--montserrat) strong, p.with-checkbox .checkbox-label:not(.checkbox-label--montserrat) + strong, p.with-checkbox .checkbox-label:not(.checkbox-label--montserrat) + a strong {
  font-weight: 400;
}
p.with-checkbox .checkbox-label.checkbox-label--montserrat strong, p.with-checkbox .checkbox-label.checkbox-label--montserrat + strong, p.with-checkbox .checkbox-label.checkbox-label--montserrat + a strong {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}

input[type=radio]:not(:disabled) + .checkbox-label--box:hover {
  background-color: #eeeeee;
}
input[type=radio] + .checkbox-label::before {
  border: none;
  box-shadow: inset 0 0 0 1px #999999;
  margin-top: 0.2rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.6rem;
}
input[type=radio]:checked + .checkbox-label::before {
  background-color: #42747d;
  box-shadow: inset 0 0 0 1px #999999, inset 0 0 0 4px #ffffff;
}
input[type=radio]:checked + .checkbox-label.checkbox-label--box {
  background-color: #4b848e;
  color: #ffffff;
  box-shadow: none;
}
input[type=radio]:checked + .checkbox-label.checkbox-label--box::before {
  box-shadow: inset 0 0 0 3px #ffffff;
}
input[type=radio]:checked:focus + .checkbox-label::before, input[type=radio].error:checked:focus + .checkbox-label::before {
  box-shadow: 0 0 0 2px #7ea7ad, inset 0 0 0 1px #999999, inset 0 0 0 4px #ffffff;
}
input[type=radio]:checked:focus + .checkbox-label.checkbox-label--box, input[type=radio].error:checked:focus + .checkbox-label.checkbox-label--box {
  box-shadow: 0 0 0 2px #7ea7ad;
}
input[type=radio]:checked:focus + .checkbox-label.checkbox-label--box::before, input[type=radio].error:checked:focus + .checkbox-label.checkbox-label--box::before {
  box-shadow: inset 0 0 0 3px #ffffff;
}
input[type=radio].error + .checkbox-label:not(.checkbox-label--box)::before {
  box-shadow: 0 0 0 2px #ed135a, inset 0 0 0 1px #999999;
}
input[type=radio].error + .checkbox-label.checkbox-label--box {
  box-shadow: 0 0 0 2px #ed135a;
}
input[type=radio].error:checked + .checkbox-label:not(.checkbox-label--box)::before {
  box-shadow: 0 0 0 2px #ed135a, inset 0 0 0 1px #999999, inset 0 0 0 4px #ffffff;
}
input[type=radio].error:checked + .checkbox-label.checkbox-label--box {
  box-shadow: 0 0 0 2px #ed135a;
}
input[type=checkbox]:checked + .checkbox-label::before {
  content: "×";
}
input[type=checkbox].error + .checkbox-label::before, input[type=checkbox].error:checked + .checkbox-label::before {
  box-shadow: 0 0 0 2px #ed135a;
}
input[type=checkbox]:focus + .checkbox-label::before, input[type=checkbox].error:focus + .checkbox-label::before, input[type=checkbox].error:checked:focus + .checkbox-label::before {
  box-shadow: 0 0 0 2px #7ea7ad;
}
input[type=radio]:disabled + .checkbox-label, input[type=checkbox]:disabled + .checkbox-label {
  opacity: 0.75;
  cursor: default;
}
input[type=text].inline {
  width: auto;
  padding: 0 0.3125em;
  background: transparent;
  font-size: 1em;
  font-weight: 500;
  line-height: 1em;
  text-align: left;
}
input[type=text].inline:active, input[type=text].inline:focus {
  background-color: white;
}

textarea {
  min-height: 6.25em;
  margin: 0;
  padding: 0.625em 0.625em;
  border-radius: 0.5rem 0.5rem 0 0.5rem;
  text-align: left;
}
textarea.error {
  box-shadow: 0 0 0 2px #ed135a;
}
textarea.error:focus {
  box-shadow: 0 0 0 2px #7ea7ad;
}
textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px #7ea7ad;
}

.continue-button-submit {
  width: 1px;
  height: 1px;
  position: absolute;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
.continue-button-submit:focus + .button {
  box-shadow: 0 0 0 2px #7ea7ad;
}

.icon.continue-button {
  display: block;
  width: 3.125em;
  height: 3.125em;
  margin: auto;
  padding: 0;
  cursor: pointer;
}

.submit-button-block,
.submit-button-flex,
.ordertake-submit {
  padding: 1em 0;
  text-align: center;
}
.submit-button-block .button,
.submit-button-flex .button,
.ordertake-submit .button {
  padding: 0.5em 1.5em;
}
.submit-button-block .button .text,
.submit-button-flex .button .text,
.ordertake-submit .button .text {
  font-size: 1.6em;
  line-height: 1.1;
}
@media (max-width: 520px) {
  .submit-button-block .button,
.submit-button-flex .button,
.ordertake-submit .button {
    padding-bottom: 0.2em;
  }
}

.submit-button-flex {
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

@media (min-width: 521px) {
  p.with-checkbox {
    padding-left: 1.9em;
  }

  .permissions-disclaimer {
    padding-left: 1.9em;
  }
}
@media (max-width: 520px) {
  p.with-checkbox {
    text-indent: 1.9em;
  }
}
select {
  display: inline-block;
  height: 2.5em;
  margin: 0;
  padding: 0 0.625em;
  border: 1px solid #999999;
  border-radius: 0.5rem;
  outline: none;
  text-align: center;
  text-align-last: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #7ea7ad;
  border-color: #7ea7ad;
}
select.error {
  box-shadow: 0 0 0 2px #ed135a;
  border-color: #ed135a;
}
select.error:focus {
  box-shadow: 0 0 0 2px #7ea7ad;
  border-color: #7ea7ad;
}

.select-wrap {
  display: block;
  position: relative;
  height: 2.5em;
  cursor: pointer;
}
.select-wrap::after {
  display: block;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  padding: 0.35em 0.75em 0 0.75em;
  border-radius: 0 0.5em 0.5em 0;
  background-color: #f6f6f6;
  font-size: 1em;
  color: #999999;
  line-height: 2em;
  content: "▼";
  pointer-events: none;
}

.yes-no {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.35em 1.5em;
  padding: 0 0 1em;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
  user-select: none;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 300;
}
.flatpickr-wrapper input {
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.flatpickr-wrapper.inline input[type=text] {
  display: none;
}

.flatpickr-calendar {
  max-height: 0;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 0;
  margin-top: 0.1875em;
  padding: 0.5em;
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: white;
  font-size: 1.25em;
  opacity: 0;
}
.flatpickr-calendar table, .flatpickr-calendar td, .flatpickr-calendar th {
  padding: 0;
  border: 0;
  border-radius: 0;
  background: transparent;
}
.flatpickr-calendar table {
  width: 100%;
  padding: 0;
  border-collapse: collapse;
}
.flatpickr-calendar th {
  padding: 0.3125em;
  color: black;
  text-align: center;
}
.flatpickr-calendar td, .flatpickr-calendar th {
  font-size: 1em;
}
.flatpickr-calendar td:hover .flatpickr-day {
  background-color: #83afc5;
}
.flatpickr-calendar .selected .flatpickr-day,
.flatpickr-calendar td.selected:hover .flatpickr-day {
  background-color: #ed135a;
  color: white;
}
.flatpickr-calendar .slot {
  cursor: pointer;
}
.flatpickr-calendar .disabled,
.flatpickr-calendar .disabled .flatpickr-day,
.flatpickr-calendar .disabled:hover .flatpickr-day {
  border-color: transparent;
  background: transparent;
  color: #dddddd;
  cursor: default;
}

.flatpickr-months {
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  padding: 0.2em 0.4em 0.2em 0.4em;
  font-size: 0.7em;
  text-decoration: none;
  cursor: pointer;
}
.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  color: #7acfe0;
}

.flatpickr-prev-month {
  float: left;
}

.flatpickr-next-month {
  float: right;
}

.open .flatpickr-calendar,
.inline .flatpickr-calendar {
  min-width: 17.5em;
  max-height: 19.875em;
  z-index: 100;
  opacity: 1;
}

.inline .flatpickr-calendar {
  position: static;
}

.flatpickr-day {
  display: block;
  width: 2.1875em;
  height: 1.5625em;
  margin: 2px;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 0.125em;
  background-color: #eeeeee;
  line-height: 1.5625em;
  text-align: center;
}
@media (max-width: 520px) {
  .flatpickr-day {
    width: 1.875em;
  }
}

.today .flatpickr-day {
  border-color: #d42c63;
}

td.today:hover .flatpickr-day {
  border-color: #d42c63;
  background-color: #d42c63;
  color: white;
}

::-webkit-input-placeholder {
  font-weight: 400;
  font-style: italic;
  color: #7ea7ad;
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-weight: 400;
  font-style: italic;
  color: #7ea7ad;
  text-align: center;
  opacity: 1;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 400;
  font-style: italic;
  color: #7ea7ad;
  text-align: center;
  opacity: 1;
}

:-ms-input-placeholder {
  font-weight: 400;
  font-style: italic;
  color: #7ea7ad;
  text-align: center;
}

::placeholder {
  font-weight: 400;
  font-style: italic;
  color: #7ea7ad;
  text-align: center;
}

.input-help-text {
  display: block;
  padding: 0.5em 0 0.25em 0;
  font-size: 0.9em;
  line-height: 1.4;
}

::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.form-footer {
  justify-content: space-between;
  padding-top: 2em;
}

button.unstyled-button {
  all: unset;
  cursor: pointer;
}
button.unstyled-button:focus {
  outline: 2px solid #7acfe0;
}

@media (max-width: 870px) {
  .form-footer__right {
    padding-top: 1.25em;
  }
}
@media (max-width: 520px) {
  .form-footer__left label {
    font-size: 1em;
  }
}
.nedbrud {
  padding-top: 7.5em;
  width: 100%;
  background-color: #4b848e;
}
.nedbrud h1 {
  padding: 1em 0.5em;
  background-color: #fee287;
  color: #000000;
  font-size: 2em;
  line-height: 1.1;
}
.nedbrud h1 a {
  color: #ed135a;
  border-bottom: #ed135a 0.1em solid;
}

.site-main {
  padding-top: 3.75em;
}

.site-header {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 35;
  background-color: #4b848e;
  background-image: linear-gradient(180deg, rgba(75, 132, 142, 0) 25%, rgba(75, 132, 142, 0) 100%);
  transition: height 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), background-image 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.site-header, .site-header .site-navigation-container {
  height: 3.75em;
}
.site-header .site-logo {
  height: 2.75em;
}
.site-header.site-header--big {
  background-color: transparent;
  background-image: linear-gradient(180deg, rgba(75, 132, 142, 0.75) 25%, rgba(75, 132, 142, 0) 100%);
}
.site-header.site-header--big, .site-header.site-header--big .site-navigation-container {
  height: 7.5em;
}
.site-header.site-header--big .site-logo {
  height: 3.75em;
}

.site-navigation-container,
.site-navigation,
.site-navigation-menu {
  display: flex;
}

.site-navigation-container,
.site-navigation {
  flex-direction: row;
  align-items: center;
}

.site-navigation-container {
  max-width: 60em;
  margin: auto;
  padding: 0 2em;
  justify-content: space-between;
  transition: height 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), padding 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.site-navigation-container li {
  margin: 0;
  padding: 0;
  transition: background 0.15s ease;
}
.site-navigation-container a {
  display: block;
  border: 0;
}

.navigation-search,
.burger-menu-container {
  transition: font-size 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.burger-menu-container {
  display: none;
}

.site-logo {
  transition: height 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.site-logo a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.site-logo svg {
  padding-bottom: 0.3125em;
}
.site-logo svg, .site-logo image {
  width: 100%;
  max-height: 100%;
}
.site-logo #site-logo-title,
.site-logo #site-logo-desc {
  display: none;
}
.site-logo .hiper-type {
  fill: white;
}

.navigation-link {
  border-radius: 0.5rem;
}
.navigation-link > a .menu-label, .navigation-link .dropdown-button-container a .menu-label {
  transition: font-size 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.navigation-link a {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 1.0625em;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-medium);
  color: #ffffff;
}
.navigation-link.navigation-link--dropdown {
  position: relative;
}
.navigation-link.navigation-link--dropdown .dropdown-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.navigation-link.navigation-link--dropdown .dropdown-icon {
  width: 1em;
  height: 1em;
}
.navigation-link.navigation-link--dropdown .dropdown-icon .dropdown-button {
  opacity: 0;
  position: absolute;
  z-index: 45;
  display: block;
  padding: 0;
  width: inherit;
  height: inherit;
}
.navigation-link.navigation-link--dropdown .dropdown-icon span {
  pointer-events: none;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 2px;
  border-radius: 1px;
  background-color: #ffffff;
  transition: transform 0.2s ease;
}
.navigation-link.navigation-link--dropdown .dropdown {
  display: none;
}
.navigation-link.current {
  background: #ed135a;
}

.navigation-search {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
.navigation-search, .navigation-search .icon {
  display: block;
  width: 1em;
  height: 1em;
}
.navigation-search .icon {
  color: transparent;
}

/* --- IF CHANGED: remember to adjust behavior.js --- */
@media (min-width: 650px) {
  .site-header.site-header--big .navigation-link > a .menu-label,
.site-header.site-header--big .navigation-link .dropdown-button-container a .menu-label {
    font-size: 1.2em;
  }
}
@media (min-width: 731px) {
  .page-home .site-header .site-navigation-menu {
    animation: fadeInMenu 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  .page-home .site-header .navigation-search-container {
    animation: fadeInSearch 0.4s 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) backwards;
  }
  @keyframes fadeInMenu {
    0% {
      transform: translateX(4.5em);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes fadeInSearch {
    0% {
      transform: translateY(-5em);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .site-header .navigation-search {
    font-size: 1.5em;
  }
  .site-header.site-header--big .navigation-search {
    font-size: 2em;
  }

  .site-navigation {
    gap: 1em;
  }

  .site-navigation-menu {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25em;
  }

  .navigation-link a {
    padding: 0.5em 0.6em;
  }
  .navigation-link.navigation-link--dropdown .dropdown-button-container a {
    padding-right: 0.2em;
  }
  .navigation-link.navigation-link--dropdown .dropdown-icon {
    margin-right: 0.6em;
    position: relative;
  }
  .navigation-link.navigation-link--dropdown .dropdown-icon span {
    width: 0.5em;
  }
  .navigation-link.navigation-link--dropdown .dropdown-icon span:nth-child(2) {
    transform: translateX(0.1em) rotate(45deg);
  }
  .navigation-link.navigation-link--dropdown .dropdown-icon span:nth-child(3) {
    transform: translateX(0.4em) rotate(-45deg);
  }
  .navigation-link.navigation-link--dropdown .dropdown {
    position: absolute;
    left: 0;
    width: max-content;
  }
  .navigation-link.navigation-link--dropdown .dropdown li {
    background-color: #4b848e;
  }
  .navigation-link.navigation-link--dropdown .dropdown li:first-child {
    margin-top: 0.2em;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .navigation-link.navigation-link--dropdown .dropdown li:first-child a {
    padding-top: 0.8em;
  }
  .navigation-link.navigation-link--dropdown .dropdown li:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .navigation-link.navigation-link--dropdown .dropdown li:last-child a {
    padding-bottom: 0.8em;
  }
  .navigation-link.navigation-link--dropdown .dropdown li:only-child {
    border-radius: 0.5rem;
  }
  .navigation-link.navigation-link--dropdown .dropdown li:only-child a {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
  .navigation-link.navigation-link--dropdown .dropdown li.current {
    background-color: #ed135a;
  }
  .navigation-link.navigation-link--dropdown .dropdown li:not(.current):hover {
    background-color: #42747d;
  }
  .navigation-link.navigation-link--dropdown .dropdown a {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }
  .navigation-link.navigation-link--dropdown:hover .dropdown-icon span:nth-child(2) {
    transform: translateX(0.4em) rotate(45deg);
  }
  .navigation-link.navigation-link--dropdown:hover .dropdown-icon span:nth-child(3) {
    transform: translateX(0.1em) rotate(-45deg);
  }
  .navigation-link.navigation-link--dropdown:hover .dropdown {
    display: block;
  }
  .navigation-link.current-desktop {
    background: #ed135a;
  }
  .navigation-link:not(.current):hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .navigation-link.current:hover {
    background-color: #d61051;
    transition: background-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .navigation-link.service-status-mobile {
    display: none;
  }

  .navigation-search-container {
    border-left: 0.1em solid #ffffff;
    padding-left: 1.6em;
  }

  .navigation-search {
    margin-left: -0.2em;
  }
  .navigation-search .icon {
    transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  .navigation-search:hover .icon {
    transform: rotate(-15deg) scale(1.25);
  }
}
@media (max-width: 730px) {
  .site-header .burger-menu-container,
.site-header .navigation-search {
    font-size: 2.5em;
  }
  .site-header.site-header--big .burger-menu-container,
.site-header.site-header--big .navigation-search {
    font-size: 3em;
  }

  .site-logo {
    z-index: 35;
  }
  .site-header.site-header--big .site-logo {
    transform: translateY(-0.4em);
  }

  .burger-menu-container {
    --bm-w: .8em;
    --bm-h: .05em;
    --bm-g: .3em;
  }

  .site-header:not(.site-header--big) .site-navigation-container {
    padding: 0 1.5em;
  }
  .site-navigation-container.menu-open .burger-menu-container span:nth-child(2) {
    transform: translateY(-50%) rotate(-45deg);
  }
  .site-navigation-container.menu-open .burger-menu-container span:nth-child(3) {
    transform: translateY(-50%) rotate(45deg);
  }
  .site-navigation-container.menu-open .burger-menu-container span:nth-child(4) {
    transform: translateY(calc(-50% + var(--bm-g))) scaleY(0);
    opacity: 0;
  }

  .site-navigation {
    flex-direction: row-reverse;
    gap: 1em;
  }

  .burger-menu-container {
    display: block;
    width: var(--bm-w);
    height: var(--bm-w);
    position: relative;
    z-index: 35;
  }
  .burger-menu-container .burger-menu {
    position: absolute;
    z-index: 45;
    display: block;
    padding: 0;
    width: inherit;
    height: inherit;
    border: none;
    background: transparent;
  }
  .burger-menu-container span {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: var(--bm-w);
    height: var(--bm-h);
    border-radius: calc(var(--bm-h) / 2);
    background-color: #ffffff;
    pointer-events: none;
    transition: width 0.2s ease, transform 0.2s ease, opacity 0.2s ease;
  }
  .burger-menu-container span:nth-child(2) {
    transform: translateY(calc(-50% - var(--bm-g)));
  }
  .burger-menu-container span:nth-child(4) {
    transform-origin: bottom;
    transform: translateY(calc(-50% + var(--bm-g)));
  }

  .site-navigation-menu {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0.5em;
    transform: translateX(100%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;
    overflow-y: auto;
    background: #42747d;
    height: 100vh;
    padding: 7.5em 0 50px;
    transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  .site-navigation-menu::after {
    content: "";
    background-color: #4b848e;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3.75em;
  }
  .site-header.site-header--big .site-navigation-menu {
    padding: 9em 0 50px;
  }
  .site-header.site-header--big .site-navigation-menu::after {
    height: 7.5em;
  }
  .site-navigation-container.menu-open .site-navigation-menu {
    transform: translateX(0);
  }

  .navigation-link {
    width: 100%;
    border-radius: 0;
  }
  .navigation-link a {
    width: 100%;
    padding: 0.8em 4em 0.5em 0.75em;
    text-align: right;
  }
  .navigation-link > a .menu-label,
.navigation-link .dropdown-button-container a .menu-label,
.navigation-link .dropdown-icon {
    font-size: 3em;
  }
  .navigation-link.navigation-link--dropdown .dropdown-icon {
    position: absolute;
    top: 0.8rem;
    right: 1.5rem;
    width: 0.65em;
  }
  .navigation-link.navigation-link--dropdown .dropdown-icon span {
    width: 18px;
  }
  .navigation-link.navigation-link--dropdown .dropdown-icon span:nth-child(2) {
    transform: rotate(45deg);
  }
  .navigation-link.navigation-link--dropdown .dropdown-icon span:nth-child(3) {
    transform: translateX(12px) rotate(-45deg);
  }
  .navigation-link.navigation-link--dropdown .dropdown {
    background-color: #42747d;
  }
  .navigation-link.navigation-link--dropdown .dropdown li.current {
    background-color: #f1427b;
  }
  .navigation-link.navigation-link--dropdown .dropdown a {
    padding-right: 1.5em;
    padding-top: 0.7em;
    padding-bottom: 0.4em;
  }
  .navigation-link.navigation-link--dropdown .dropdown a .menu-label {
    font-size: 2em;
  }
  .navigation-link.navigation-link--dropdown.active .dropdown-icon span:nth-child(2) {
    transform: translateX(12px) rotate(45deg);
  }
  .navigation-link.navigation-link--dropdown.active .dropdown-icon span:nth-child(3) {
    transform: rotate(-45deg);
  }
  .navigation-link.navigation-link--dropdown.active .dropdown {
    display: block;
  }
  .navigation-link .service-status-desktop {
    display: none;
  }

  .navigation-search-container {
    position: relative;
    z-index: 35;
  }
}
.site-wrap {
  transition: 0.2s all cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.footer {
  margin-top: 1em;
  width: 100%;
  color: #ffffff;
  background-color: #4b848e;
}
.footer .footer-section-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .footer-section-wrapper:first-child {
  background-color: #42747d;
}
.footer p, .footer a, .footer li {
  padding: 0;
  margin: 0;
}
.footer ul li {
  font-weight: 300;
  font-variation-settings: "wght" 300;
  text-align: left;
}
.footer a {
  border: 0;
}
.footer strong {
  font-weight: 400;
  font-variation-settings: "wght" 400;
}
.footer .no-wrap {
  white-space: nowrap;
}
.footer span.text,
.footer span.title {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: var(--letter-spacing-medium-always);
}
.footer .footer-content .title {
  font-size: 1.2em;
}
.footer .accordion-container--footer .accordion--footer {
  background-color: transparent;
  color: #ffffff;
  text-align: left;
}
.footer .accordion-container--footer .accordion-content-container--footer {
  background-color: transparent;
  transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.footer .footer-content ul li {
  padding-bottom: 0.25em;
}
.footer .footer-content ul li:last-child {
  padding-bottom: 0;
}
.footer .footer-menu ul button {
  padding: 0;
  width: 100%;
  border: none;
  background: transparent;
  font: inherit;
  text-align: left;
  color: #ffffff;
  box-shadow: none;
  cursor: pointer;
}
.footer .footer-menu ul a,
.footer .footer-menu ul button {
  display: block;
  position: relative;
  z-index: 0;
}
.footer .footer-menu ul a::after,
.footer .footer-menu ul button::after {
  display: block;
  content: "";
  position: absolute;
  top: -0.1em;
  left: -0.2em;
  z-index: -1;
  width: calc(100% + .4em);
  height: calc(100% + .2em);
  border-radius: 0.5em;
  background-color: rgba(255, 255, 255, 0);
  transform-origin: center;
  transform: scale(0.9);
  transition: background-color 0.2s ease, transform 0.2s ease;
}
.footer .footer-menu ul a:hover,
.footer .footer-menu ul button:hover {
  color: #ffffff;
}
.footer .footer-menu ul a:hover::after,
.footer .footer-menu ul button:hover::after {
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1);
}
.footer aside {
  background-color: #4b848e;
  border-radius: 0.5em;
}
.footer aside a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.footer aside a:hover {
  color: rgba(255, 255, 255, 0.75);
}
.footer .footer-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5em 1em;
}
.footer .footer-bottom li {
  padding: 0;
  margin: 0;
}
.footer .footer-bottom li:first-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
}
.footer .footer-bottom li a {
  display: flex;
}
.footer .footer-bottom li a svg {
  margin: 0;
  fill: rgba(255, 255, 255, 0.5);
  transition: fill 0.3s ease;
}
.footer .footer-bottom li a.nuuday-logo svg .thin {
  opacity: 0.5;
}
.footer .footer-bottom li a:hover svg {
  fill: white;
}
@media (min-width: 1001px) {
  .footer .footer-section-wrapper:last-child {
    padding: 0 calc(148px - 4em);
  }
  .footer .footer-content,
.footer .footer-bottom {
    padding: 1em 4em;
    max-width: 60em;
  }
  .footer .footer-content {
    grid-template-columns: 2fr 1fr;
    gap: 2em 0;
  }
  .footer .footer-menu,
.footer aside {
    grid-template-rows: 1fr 1fr;
    gap: 1em 2em;
  }
  .footer .footer-menu {
    grid-template-columns: 1fr 1fr;
    padding: 1em 0;
  }
  .footer aside {
    padding: 1em 2em;
  }
}
@media (min-width: 769px) {
  .footer .footer-content,
.footer .footer-content .footer-menu,
.footer .footer-content aside {
    display: grid;
  }
  .footer .footer-content ul li {
    font-size: 0.8em;
  }
  .footer .accordion-container--footer {
    width: fit-content;
  }
  .footer .title {
    display: block;
    padding-bottom: 0.25rem;
  }
  .footer .accordion--footer {
    pointer-events: none;
  }
  .footer .accordion--footer .symbol {
    display: none;
  }
  .footer .accordion-content-container--footer {
    max-height: none;
  }
  .footer hr {
    display: none;
  }
}
@media (max-width: 1000px) and (min-width: 769px) {
  .footer .footer-section-wrapper:last-child {
    padding: 0 calc(88px - 2em);
  }
  .footer .footer-content,
.footer .footer-bottom {
    padding: 1.5em 2em;
    max-width: 40em;
  }
  .footer .footer-content,
.footer .footer-menu,
.footer aside {
    gap: 1em 2em;
  }
  .footer .footer-content {
    grid-template-columns: 1fr;
  }
  .footer .footer-menu,
.footer aside {
    grid-template-columns: 1fr 1fr;
  }
  .footer .footer-menu {
    padding: 0 2em;
  }
  .footer aside {
    padding: 1em 2em;
  }
}
@media (max-width: 768px) {
  .footer .footer-content,
.footer .footer-bottom {
    max-width: 28em;
  }
  .footer .footer-content {
    flex-direction: column;
    gap: 0.5em;
  }
  .footer aside {
    background: transparent;
    padding: 0;
  }
  .footer aside ul {
    margin-top: 1em;
  }
  .footer .accordion-container--footer {
    width: 100%;
  }
  .footer .accordion-container--footer .accordion--footer {
    padding: 0.5em 0;
  }
  .footer .accordion-container--footer .accordion--footer .symbol span {
    background-color: #ffffff;
  }
  .footer .accordion-container--footer .accordion-content-container--footer {
    overflow: hidden;
  }
  .footer .accordion-container--footer .accordion-content-container--footer li {
    padding-left: 0.5em;
  }
  .footer .accordion-container--footer .accordion-content-container--footer li:last-child {
    padding-bottom: 1em;
  }
  .footer hr {
    max-width: none;
    width: 100%;
    border: 0;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0;
  }
}

@media (max-width: 768px) and (min-width: 521px) {
  .footer-section-wrapper:last-child {
    padding: 0 calc(88px - 2em);
  }

  .footer-content,
.footer-bottom {
    padding: 1em 2em;
  }
}
@media (min-width: 521px) {
  a.some-icon {
    width: 2em;
    height: 2em;
  }

  .nuuday-logo svg {
    max-width: 6em;
  }
}
@media (max-width: 520px) {
  .footer-content,
.footer-bottom {
    width: 100%;
  }

  .footer-content {
    padding: 1em;
    gap: 1em;
  }
  .footer-content aside ul:first-child {
    margin-bottom: 1em;
  }

  .footer-bottom {
    flex-direction: column;
    padding: 1em 0;
  }
  .footer-bottom a.some-icon {
    width: 3em;
    height: 3em;
  }
  .footer-bottom .nuuday-logo svg {
    max-width: 8em;
  }
}
.site-footer__cookie-warning {
  display: flex;
  width: 100%;
  min-height: 3.5714285714em;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  align-items: center;
  justify-content: center;
  padding: 0.625em 2.1875em;
  background-color: #1e2025;
  font-size: 0.875em;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1em;
  text-align: center;
}
@media (max-width: 980px) {
  .site-footer__cookie-warning {
    padding-right: 10.9375em;
  }
}

.site-footer__accept-cookies {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0.2em;
  font-size: 2.5em;
  color: #dad9d7;
  line-height: 1em;
  cursor: pointer;
}
@media (min-width: 520px) {
  .site-footer__accept-cookies {
    top: 50%;
    transform: translateY(-50%);
  }
}

.header {
  padding-top: 1em;
  text-align: center;
}
.header .heading {
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  .header .breadcrumb {
    font-size: 1.25em;
  }
}
@media (max-width: 520px) {
  .header .breadcrumb {
    font-size: 1.0625em;
  }
}

.back-link-widget {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #303a4b;
  font-size: 1.1em;
  line-height: 1.4;
}
.back-link-widget .icon {
  margin-left: -0.2em;
  margin-right: 0.1em;
  color: transparent;
  fill: #303a4b;
}
.back-link-widget .text {
  font-weight: 450;
  font-variation-settings: "wght" 450;
  border: none;
  font-size: 0.8em;
  color: #303a4b;
  transition: inherit;
}
.back-link-widget:hover {
  border-bottom-color: rgba(48, 58, 75, 0.75);
}
.back-link-widget:hover .icon {
  color: transparent;
  fill: rgba(48, 58, 75, 0.75);
}
.back-link-widget:hover .text {
  color: rgba(48, 58, 75, 0.75);
}

.blurb {
  max-width: 40em;
  margin: auto;
  padding: 1.25em 0;
}
.blurb p {
  text-align: center;
}
.blurb--wide {
  max-width: 75em;
}

.content {
  max-width: 40.625em;
  margin: auto;
  padding: 1em;
}
.content--full-width {
  max-width: none;
  padding: 0;
}

.content--wide {
  max-width: 75em;
}

.content--nav {
  max-width: 60em;
}
@media (min-width: 731px) {
  .content--nav {
    padding-left: 2em;
    padding-right: 2em;
  }
}
@media (max-width: 730px) {
  .content--nav {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media (max-width: 520px) {
  .content--nav {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.content--medium {
  max-width: 62.5em;
}

.content--order {
  max-width: 55em;
}

.content--narrow {
  max-width: 50em;
}

.content--slim {
  max-width: 34.375em;
}

.content--centered {
  text-align: center;
}

.page-home .usp-section {
  padding: 2em 0;
  max-height: none;
  background-color: #4b848e;
}
@media (max-width: 520px) {
  .page-home .usp-section {
    max-height: 0;
    display: none;
  }
}
.page-home .usp-section .trustpilot--link {
  border-bottom: 0;
}
.page-home .usp-section .trustpilot--link .text {
  border-bottom: 0.125em solid rgba(237, 19, 90, 0.75);
}
.page-home .usp-section .trustpilot--link .trustpilot--logo {
  display: inline-block;
  width: 100%;
  max-width: 4.75em;
  padding-left: 0.1em;
  fill: currentColor;
}
.page-home .usp-section .trustpilot--link .trustpilot--logo svg {
  vertical-align: text-bottom;
}
.page-home .usp-section .trustpilot--link:hover .text {
  border-bottom-color: rgba(0, 0, 0, 0);
}
.page-home .usp-section .trustpilot--link:hover .trustpilot--logo {
  fill: currentColor;
}
.page-home .usp-section .usps {
  display: flex;
  justify-content: center;
  margin: 0 0 1.25em 0;
  padding: 1.25em 0 0 0;
  transition: all 0.18s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
  --item-padding: 1.25em;
  --item-margin: 1.25em;
  --background: #333b48;
  --color-heading: white;
  --color-p: rgba(255, 255, 255, 0.75);
}
.page-home .usp-section .usps li {
  display: flex;
  width: 100%;
  max-width: 15.625em;
  flex: 1 1 100%;
  flex-flow: column;
  justify-content: space-between;
  margin: 0 0.2em;
  margin: 0 var(--item-margin);
  padding: var(--item-padding);
  border-radius: 0.3125em;
  background: var(--background);
}
.page-home .usp-section .usps li > :last-child {
  padding-bottom: 0;
}
.page-home .usp-section .usps .heading, .page-home .usp-section .usps p {
  width: 100%;
}
.page-home .usp-section .usps .heading {
  padding-bottom: 0.5em;
  font-size: 1.35em;
  font-weight: 300;
  color: var(--color-heading);
  text-align: left;
  letter-spacing: var(--letter-spacing-big);
}
.page-home .usp-section .usps .heading .icon {
  display: inline-block;
  width: 0.55em;
  height: 0.55em;
  margin-right: 0.25em;
  color: #ed135a;
  line-height: 0.55em;
}
.page-home .usp-section .usps p {
  font-size: 0.92em;
  color: var(--color-p);
}
@media (max-width: 768px) {
  .page-home .usp-section .usps {
    --item-padding: 0;
    --item-margin: .2em;
    --background: 0;
    --color-p: #303a4b;
    --color-heading: #303a4b;
  }
  .page-home .usp-section .usps li {
    display: block;
  }
  .page-home .usp-section .usps .heading {
    font-size: 1em;
  }
  .page-home .usp-section .usps p {
    font-size: 1em;
  }
}
.page-home .usp-section .usp-svg-image {
  width: 11em;
  height: 11em;
  position: relative;
}
.page-home .usp-section .usp-svg-image > svg {
  width: 11em;
  height: 11em;
}
.page-home .usp-section .usp-svg-image.customers .trustpilot--logo,
.page-home .usp-section .usp-svg-image.customers .trustpilot--stars,
.page-home .usp-section .usp-svg-image.customers .trustpilot--link {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.page-home .usp-section .usp-svg-image.customers .trustpilot--logo {
  top: 2.5em;
  width: 7em;
  margin: auto;
  text-align: center;
  display: block;
  fill: #ffffff;
}
.page-home .usp-section .usp-svg-image.customers .trustpilot--stars {
  top: 5em;
}
.page-home .usp-section .usp-svg-image.customers .trustpilot--link {
  top: 7em;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  color: #ffffff;
}
.page-home .usp-section .usp-svg-image.support .icon {
  position: absolute;
  top: 3.75em;
  width: 3.5em;
  height: 3.5em;
  color: rgba(53, 61, 96, 0.6);
  fill: rgba(255, 255, 255, 0.85);
}
.page-home .usp-section .usp-svg-image.support .icon-fb {
  left: 1.5em;
}
.page-home .usp-section .usp-svg-image.support .icon-phone {
  right: 1.5em;
}
.page-home .section-with-image {
  padding-top: 2em;
  padding-bottom: 2em;
}
.page-home .section-with-image .content .image {
  float: left;
  margin-right: 2em;
}
.page-home .section-with-image .content .image img {
  max-width: 24em;
  box-shadow: 0 0.158203125em 0.10546875em rgba(0, 0, 0, 0.022), 0 0.404296875em 0.26953125em rgba(0, 0, 0, 0.031), 0 0.83203125em 0.5546875em rgba(0, 0, 0, 0.039), 0 1.7109375em 1.140625em rgba(0, 0, 0, 0.048), 0 4.6875em 3.125em rgba(0, 0, 0, 0.07);
}
.page-home .section-with-image .content .heading {
  line-height: 1.1;
  text-align: left;
}
@media (max-width: 890px) {
  .page-home .section-with-image .content .image {
    display: none;
  }
  .page-home .section-with-image .content .heading {
    text-align: center;
  }
}

.page-home .content__product-list > .blurb,
.page-home .content__product-list > .blurb > .heading,
.page-products .content__product-list > .blurb,
.page-products .content__product-list > .blurb > .heading {
  padding-bottom: 0;
}

.about-us {
  margin-top: 2em;
}
.about-us h1, .about-us h2, .about-us h3 {
  padding-bottom: 0.7rem;
  line-height: 1.1;
}
.about-us h2 {
  padding-top: 0.7rem;
}
.about-us .loyalty-groups {
  margin-top: 2em;
}
.about-us .loyalty-groups sup {
  font-size: 0.4em;
  vertical-align: super;
}
.about-us .loyalty-groups .stamp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}
.about-us .loyalty-groups .stamp img {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  box-shadow: 1px 3px 6px 3px rgba(0, 0, 0, 0.3);
}
.about-us .loyalty-groups .stamp.stamp--multiple img:nth-child(2) {
  margin-left: -3em;
}
.about-us .loyalty-groups .stamp.stamp--multiple img:nth-child(3) {
  margin-left: -3em;
}
.about-us .loyalty-groups .stamp.stamp--multiple img:nth-child(4) {
  margin-left: -3em;
}
@media (max-width: 520px) {
  .about-us .loyalty-groups .stamp img {
    width: 7em;
    height: 7em;
  }
}

.page-press .item h2, .page-press .item h3, .page-press .item h4 {
  padding-bottom: 0;
}
.page-press .item h3 {
  padding-top: 1rem;
}
.page-press .item:nth-last-child(1) .wrap-thing-bar, .page-press .item:nth-last-child(2) .wrap-thing-bar {
  border-top: 2px solid #42747d;
}
.page-press .blurb {
  max-width: none;
}
.page-press .blurb p {
  max-width: 52em;
}
.page-press .blurb .nowrap {
  white-space: nowrap;
}
.page-press .content img {
  width: 100%;
  max-width: 28em;
}
.page-press .content p {
  color: #303a4b;
}
.page-press .grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 2em 4em;
}
.page-press .item {
  max-width: 620px;
  width: 24em;
}
.page-press .wrap-thing {
  box-shadow: var(--box-shadow);
}
@media (max-width: 880px) {
  .page-press .wrap-thing {
    max-width: 25em;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 520px) {
  .page-press .item {
    width: 100%;
  }
  .page-press .item h3 {
    padding-top: 0.75rem;
  }
}

.tp-reviews {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2em 1em;
}
@media (max-width: 520px) {
  .tp-reviews {
    flex-direction: column;
    align-items: center;
  }
}

.tp-review {
  flex: 1;
  display: block;
  border: none;
  margin: 0 0.5em;
  font-size: 0.95em;
  max-width: 18em;
}
@media (max-width: 1500px) {
  .tp-review:nth-last-child(1) {
    display: none;
  }
}
@media (max-width: 1000px) {
  .tp-review:nth-last-child(2) {
    display: none;
  }
}
@media (max-width: 750px) {
  .tp-review:nth-last-child(3) {
    display: none;
  }
}
@media (max-width: 520px) {
  .tp-review {
    margin: 0;
    font-size: 1.2em;
  }
  .tp-review:nth-child(1) {
    margin-bottom: 1.4em;
  }
}

.tp-review-title {
  font-size: 1em;
  letter-spacing: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0 0.5em 0;
}

.tp-review-stars {
  text-align: center;
  padding: 0 0 0.5em 0;
  line-height: 1;
}

.tp-review-text {
  letter-spacing: var(--letter-spacing-medium);
  padding-bottom: 0;
  text-align: center;
}

.section-tp-header {
  background-color: #4b848e;
  color: #ffffff;
  text-align: center;
}
.section-tp-header .tp-text {
  max-width: 30em;
}
.section-tp-header .blurb .content p:last-child {
  padding-bottom: 0;
}
.section-tp-header .trustpilot--logo {
  top: 2.5em;
  width: 6em;
  margin: auto;
  text-align: center;
  display: block;
  fill: #ffffff;
}
.section-tp-header .arrow-wrap-tp {
  position: relative;
  width: 100%;
  text-align: center;
}
.section-tp-header .arrow-wrap-tp .arrow-down-tp {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #4b848e;
}

.trustpilot-header {
  position: relative;
  padding: 2em 1em;
  background-color: #4b848e;
}
.trustpilot-header .blurb {
  padding: 0;
}
.trustpilot-header p {
  color: #ffffff;
}
.trustpilot-header p:nth-child(3) {
  line-height: 1.4;
}
.trustpilot-header p:nth-child(3) .star-count {
  padding: 0.2em 0.5em;
  background-color: #42747d;
  border-radius: 0.5em;
}
.trustpilot-header .arrow-container {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.trustpilot-header .arrow-container .arrow-down {
  width: 0;
  height: 0;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #4b848e;
}

.trustpilot-widget-wrap {
  background-color: #ffffff;
}
.trustpilot-widget-wrap .trustpilot-widget {
  padding: 2.5em 1em;
}

.section-about-internet {
  padding-top: 2em;
}
.section-about-internet .blurb {
  padding: 1.25em 0 0.5em;
}
.section-about-internet .content:first-of-type {
  padding-top: 0;
}

.content--narrow.content--trustpilot {
  padding-bottom: 0;
}
.content--narrow.content--trustpilot img {
  display: block;
  margin: 0 auto;
}
@media (min-width: 521px) {
  .content--narrow.content--trustpilot img {
    max-height: 40vh;
  }
}

.content--wide.content--trustpilot {
  padding-bottom: 0;
}

.page-employee-index .content .button.red .icon path {
  transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform: translateX(0);
}
.page-employee-index .content .button.red:hover .icon path:first-of-type {
  transform: translateX(100px);
}
.page-employee-index .content .button.red:hover .icon path:last-of-type {
  transform: translateX(-50px);
}

.order-wrapper .progress-bar,
.page-sbbu .progress-bar,
.page-order-receipt-selfcare-step .progress-bar {
  display: block;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.035em;
  line-height: 1.2;
}
.order-wrapper .progress-bar a, .order-wrapper .progress-bar span,
.page-sbbu .progress-bar a,
.page-sbbu .progress-bar span,
.page-order-receipt-selfcare-step .progress-bar a,
.page-order-receipt-selfcare-step .progress-bar span {
  display: inline-block;
}
.order-wrapper .progress-bar a,
.page-sbbu .progress-bar a,
.page-order-receipt-selfcare-step .progress-bar a {
  color: #ed135a;
  border: none;
  text-decoration: underline;
  text-decoration-color: #ed135a;
  text-underline-offset: 0.1em;
}
.order-wrapper .progress-bar a:hover,
.page-sbbu .progress-bar a:hover,
.page-order-receipt-selfcare-step .progress-bar a:hover {
  color: rgba(237, 19, 90, 0.75);
  text-decoration-color: rgba(237, 19, 90, 0.75);
}
.order-wrapper .progress-bar > span.item,
.page-sbbu .progress-bar > span.item,
.page-order-receipt-selfcare-step .progress-bar > span.item {
  color: rgba(48, 58, 75, 0.5);
}
.order-wrapper .progress-bar > span.item.current,
.page-sbbu .progress-bar > span.item.current,
.page-order-receipt-selfcare-step .progress-bar > span.item.current {
  color: #303a4b;
  font-weight: 400;
}
.order-wrapper .progress-bar .progress-arrow,
.page-sbbu .progress-bar .progress-arrow,
.page-order-receipt-selfcare-step .progress-bar .progress-arrow {
  position: relative;
  width: 0.5em;
  height: 0.5em;
}
.order-wrapper .progress-bar .progress-arrow::before, .order-wrapper .progress-bar .progress-arrow::after,
.page-sbbu .progress-bar .progress-arrow::before,
.page-sbbu .progress-bar .progress-arrow::after,
.page-order-receipt-selfcare-step .progress-bar .progress-arrow::before,
.page-order-receipt-selfcare-step .progress-bar .progress-arrow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 1px;
  background-color: #303a4b;
}
.order-wrapper .progress-bar .progress-arrow::before,
.page-sbbu .progress-bar .progress-arrow::before,
.page-order-receipt-selfcare-step .progress-bar .progress-arrow::before {
  transform: rotate(45deg);
}
.order-wrapper .progress-bar .progress-arrow::after,
.page-sbbu .progress-bar .progress-arrow::after,
.page-order-receipt-selfcare-step .progress-bar .progress-arrow::after {
  transform: translate(0, 5px) rotate(-45deg);
}
@media (min-width: 961px) {
  .order-wrapper .progress-bar,
.page-sbbu .progress-bar,
.page-order-receipt-selfcare-step .progress-bar {
    padding: 0.5em 0.75em 0.2em;
    width: fit-content;
    border-radius: 1em;
    box-shadow: var(--box-shadow);
  }
}

.order-wrapper {
  display: grid;
  max-width: 68em;
}
.order-wrapper input, .order-wrapper input::placeholder, .order-wrapper label, .order-wrapper label::placeholder, .order-wrapper .address-widget input, .order-wrapper .address-widget input::placeholder, .order-wrapper .address-widget li, .order-wrapper .address-widget li::placeholder {
  text-align: left;
}
.order-wrapper select {
  text-align: left;
  text-align-last: left;
}
.order-wrapper .heading {
  color: #303a4b;
}
.order-wrapper .heading:not(.heading--montserrat) {
  font-weight: 300;
}
.order-wrapper h1:not(.heading--big), .order-wrapper:not(.new-receipt-flow) .order-summary .accordion h2 {
  font-size: 2em;
  line-height: 0.8;
}
.order-wrapper .order-content h2,
.order-wrapper .order-content h3 {
  padding-bottom: 0.5rem;
}
.order-wrapper .order-content h2:not(.heading--montserrat),
.order-wrapper .order-content h3:not(.heading--montserrat) {
  font-size: 1.75em;
}
.order-wrapper .order-content h2:not(:first-child),
.order-wrapper .order-content h3:not(:first-child) {
  padding-top: 1rem;
}
.order-wrapper:not(.new-receipt-flow) .order-summary .accordion-container {
  border-bottom: none;
}
.order-wrapper:not(.new-receipt-flow) .order-summary .accordion-content-container {
  max-height: none;
  border: none;
  opacity: 1;
  overflow: visible;
  pointer-events: all;
  transition: max-height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.order-wrapper .order-summary .accordion .symbol {
  height: 0.6em;
}
.order-wrapper .campaign-code-container p:first-of-type {
  padding-bottom: 0;
}
.order-wrapper .campaign-code-container form {
  margin-top: 0.25em;
  padding-bottom: 0.45em;
}
.order-wrapper .campaign-code-container .campaign-code > div,
.order-wrapper .campaign-code-container .campaign-code-active > div {
  display: grid;
  align-items: center;
  width: 100%;
  height: 2.5rem;
}
.order-wrapper .campaign-code-container .campaign-code > div {
  grid-template-columns: auto 5em;
  gap: 0.25em;
}
.order-wrapper .campaign-code-container .campaign-code > div .code, .order-wrapper .campaign-code-container .campaign-code > div .button {
  padding: 0.2em 0.5em;
  height: 2.5rem;
  appearance: none;
}
.order-wrapper .campaign-code-container .campaign-code > div .button {
  width: auto;
  font-size: 1.2em;
  text-align: center;
  background-color: #4b848e;
}
.order-wrapper .campaign-code-container .campaign-code > div .button:hover {
  background-color: #42747d;
}
.order-wrapper .campaign-code-container #continue-campaign-code-warning {
  display: none;
}
.order-wrapper .campaign-code-container #continue-campaign-code-warning .red {
  color: #ed135a;
}
.order-wrapper .campaign-code-container #continue-campaign-code-warning.visible {
  display: block;
}
.order-wrapper .campaign-code-container .campaign-code-active > div {
  grid-template-columns: 2em auto 2em;
  padding: 0 0.25em;
  border-radius: 0.5em;
  background-color: #4b848e;
}
.order-wrapper .campaign-code-container .campaign-code-active > div .icon {
  display: block;
  width: 2em;
  height: 2em;
  color: transparent;
}
.order-wrapper .campaign-code-container .campaign-code-active > div .text {
  width: 100%;
  margin-right: 0.25em;
  padding: 0;
  font-size: 0.9em;
  color: #ffffff;
  text-transform: lowercase;
  letter-spacing: 0.05em;
}
.order-wrapper .campaign-code-container .campaign-code-active > div .remove {
  border-radius: 1em;
  border: 0;
  font-size: 1em;
  background-color: #42747d;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.order-wrapper .campaign-code-container .campaign-code-active > div .remove:hover {
  background-color: #4b848e;
}
.order-wrapper .campaign-code-container .campaign-code-active > div .remove, .order-wrapper .campaign-code-container .campaign-code-active > div .remove input {
  width: 2em;
  height: 2em;
  cursor: pointer;
}
.order-wrapper .campaign-code-container .campaign-code-active > div .remove input {
  appearance: none;
  opacity: 0;
}
.order-wrapper .campaign-code-container .campaign-code-active > div .remove::before, .order-wrapper .campaign-code-container .campaign-code-active > div .remove::after {
  content: "";
  height: 2px;
  width: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  pointer-events: none;
}
.order-wrapper .campaign-code-container .campaign-code-active > div .remove::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.order-wrapper .campaign-code-container .campaign-code-active > div .remove::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.order-wrapper .campaign-code-container .campaign-code-invalid {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  margin-top: -0.15rem;
  padding: 0;
  color: #ed135a;
}
.order-wrapper .flatpickr-wrapper .flatpickr-calendar {
  margin-top: 0.5em;
  padding: 0;
}
@media (max-width: 400px) {
  .order-wrapper .flatpickr-wrapper .flatpickr-calendar {
    min-width: 0;
  }
}
@media (max-width: 350px) {
  .order-wrapper .flatpickr-wrapper .flatpickr-calendar .flatpickr-day {
    width: calc(calc(100vw - 1em) / 7 - 4px );
  }
}
.order-wrapper .order-header {
  grid-area: header;
}
.order-wrapper .order-coop {
  grid-area: coop;
}
.order-wrapper .order-content {
  grid-area: content;
}
.order-wrapper .order-summary {
  grid-area: summary;
}
@media (min-width: 961px) {
  .order-wrapper {
    padding: 2em 0;
    grid-template-columns: auto 22em;
    grid-template-areas: "header header" "content summary";
  }
  .order-wrapper.order-wrapper--coop {
    grid-template-areas: "header header" "coop coop" "content summary";
  }
  .order-wrapper .order-header {
    padding: 0 1em 2em 1em;
  }
  .order-wrapper.order-wrapper--coop .order-header {
    padding: 0 1em 0.5em 1em;
  }
  .order-wrapper .order-content, .order-wrapper .order-summary {
    padding: 0 2em;
  }
  .order-wrapper .order-content {
    border-right: 1px solid #999999;
  }
  .order-wrapper h1, .order-wrapper:not(.new-receipt-flow) .order-summary .accordion h2 {
    padding-bottom: 1.5rem;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion, .order-wrapper:not(.new-receipt-flow) .order-summary .accordion-content {
    padding: 0;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion {
    display: block;
    border: none;
    pointer-events: none;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion .hide-on-large-devices,
.order-wrapper:not(.new-receipt-flow) .order-summary .accordion .symbol,
.order-wrapper:not(.new-receipt-flow) .order-summary .accordion .price {
    display: none;
  }
  .order-wrapper .campaign-code-container p:first-of-type {
    margin-top: -0.45em;
  }
}
@media (max-width: 960px) {
  .order-wrapper {
    padding: 0;
    grid-template-columns: 1fr;
    grid-template-areas: "summary" "header" "content";
  }
  .order-wrapper.order-wrapper--coop {
    grid-template-areas: "summary" "header" "coop" "content";
  }
  .order-wrapper .order-header {
    padding: 1em;
  }
  .order-wrapper.order-wrapper--coop .order-header {
    padding: 1em 1em 0.5em;
  }
  .order-wrapper .order-content {
    padding: 0 1em 1em;
  }
  .order-wrapper h1 {
    padding: 0.5rem 0 1rem;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary {
    background-color: #f7f7f7;
    border-bottom: 1px solid #dad9d7;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion {
    padding: 0.5em 1em;
    border-top: none;
    border-bottom: 1px solid #dad9d7;
    transition: border-bottom-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion, .order-wrapper:not(.new-receipt-flow) .order-summary .accordion:hover {
    color: #303a4b;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion .symbol, .order-wrapper:not(.new-receipt-flow) .order-summary .accordion h2 {
    display: inline-block;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion h2, .order-wrapper:not(.new-receipt-flow) .order-summary .accordion .price {
    line-height: 1.2;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion h2 {
    font-weight: 400;
    font-variation-settings: "wght" 400;
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 1.4em;
    text-transform: none;
    letter-spacing: normal;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion h2 .hide-on-small-devices {
    display: none;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion .price {
    font-weight: 500;
    font-variation-settings: "wght" 500;
    font-size: 1.2em;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion:hover:not(.active) .symbol span:first-child,
.order-wrapper:not(.new-receipt-flow) .order-summary .accordion:hover:not(.active) .symbol span:nth-child(2) {
    background-color: #303a4b;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion:hover:not(.active) h2 {
    color: #303a4b;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion:not(.active) h2 .hide-on-large-devices span:nth-child(2) {
    display: none;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion.active {
    border-bottom-color: transparent;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion.active h2 .hide-on-large-devices span:first-child {
    display: none;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion-content-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 1em;
    max-width: 26em;
    background-color: #f7f7f7;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .campaign-code-container, .order-wrapper:not(.new-receipt-flow) .order-summary .accordion-content {
    width: 100%;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion-content {
    padding: 0;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin-bottom 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion-content-container.active .accordion-content {
    max-height: 999em;
    opacity: 1;
    pointer-events: all;
    margin-bottom: 1em;
  }
}
@media (max-width: 520px) {
  .order-wrapper .order-header {
    padding: 1em 0.5em;
  }
  .order-wrapper.order-wrapper--coop .order-header {
    padding: 1em 0.5em 0.5em;
  }
  .order-wrapper .order-content {
    padding: 0 0.5em 1em 0.5em;
  }
  .order-wrapper .order-summary .accordion {
    padding: 0.5em;
  }
  .order-wrapper .order-summary .accordion-content-container {
    padding: 0 0.5em;
  }
  .order-wrapper:not(.new-receipt-flow) .order-summary .accordion h2 {
    font-size: 1.2em;
  }
}

.order-content .permissions-disclaimer {
  padding-bottom: 1em;
}
.order-content .order-submit-back {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 0.5em 1em;
  margin-top: 1em;
}
.order-content .order-submit-back .button {
  padding: 0.8em 1.6em;
  line-height: 0.8;
  text-align: center;
}
.order-content .order-submit-back .back {
  border: 1px solid #999999;
  background-color: #f7f7f7;
  color: #303a4b;
}
.order-content .order-submit-back .back:hover {
  background-color: #eeeeee;
}
.order-content .order-submit-back .submit {
  background-color: #4b848e;
}
.order-content .order-submit-back .submit:hover {
  background-color: #42747d;
}
.order-content .order-submit-back .submit.shimmer {
  position: relative;
  overflow: hidden;
}
.order-content .order-submit-back .submit.shimmer::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  pointer-events: none;
  animation: shimmer 4s linear 5;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 75%);
}
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  10% {
    transform: translateX(-100%);
  }
  25% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.order-content .order-submit-back .text {
  font-size: 1.6em;
  white-space: nowrap;
}
@media (min-width: 521px) {
  .order-content .order-submit-back #submit-form-button, .order-content .order-submit-back .button {
    flex: 0 1 10em;
  }
}
@media (max-width: 520px) {
  .order-content .order-submit-back #submit-form-button, .order-content .order-submit-back .button {
    width: 100%;
  }
}

.order-summary .accordion .star {
  margin-left: -0.15em;
  opacity: 0.75;
  font-size: 0.8em;
  vertical-align: top;
}
.order-summary p, .order-summary th, .order-summary td {
  font-size: 0.8em;
}
.order-summary p {
  line-height: 1.4;
}
.order-summary hr {
  margin: 1em 0;
  padding: 0;
  max-width: none;
  width: 100%;
  background-color: #999999;
}
.order-summary .changeable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}
.order-summary .changeable:first-child {
  padding-top: 0;
}
.order-summary .changeable p {
  flex: 1 1 calc(100% - 2.5em);
  padding: 0;
}
.order-summary .changeable a {
  border: 0;
  cursor: pointer;
}
.order-summary .changeable > a {
  width: 1.5em;
  height: 1.5em;
}
.order-summary .changeable .icon {
  width: 1.5em;
  height: 1.5em;
  color: #303a4b;
  fill: #ffffff;
  transition: color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.order-summary .changeable .icon:hover {
  color: #3a465b;
}
.order-summary .changeable .mailbox-address {
  text-transform: capitalize;
}
.order-summary .changeable.changeable--address {
  position: relative;
}
.order-summary .changeable.changeable--address .changeable-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.order-summary .changeable.changeable--address .changeable-warning {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  border-radius: 1em;
  background-color: #4b848e;
  padding: 0.5em;
}
@media (min-width: 961px) {
  .order-summary .changeable.changeable--address .changeable-warning {
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 2px 18px -2px rgba(0, 0, 0, 0.2), 0 1px 6px rgba(0, 0, 0, 0.2);
  }
}
@media (max-width: 960px) {
  .order-summary .changeable.changeable--address .changeable-warning {
    top: 0;
  }
}
.order-summary .changeable.changeable--address .changeable-warning p {
  padding: 0.5em 0;
  font-size: 0.9em;
  text-align: center;
  color: #ffffff;
}
.order-summary .changeable.changeable--address .changeable-warning div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
}
.order-summary .changeable.changeable--address .changeable-warning div a {
  flex: 1 1 50%;
  border: none;
  padding: 0.2em 0.4em;
  border-radius: 0.5rem;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 1.4em;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
}
.order-summary .changeable.changeable--address .changeable-warning div a.back {
  color: #303a4b;
  background-color: #ffffff;
}
.order-summary .changeable.changeable--address .changeable-warning div a.continue {
  color: #ffffff;
  border: 1px solid #ffffff;
}
.order-summary .summary-table {
  padding-bottom: 0;
}
@media (min-width: 961px) {
  .order-summary .changeable .mailbox-address {
    font-size: 0.8em;
  }
}
@media (max-width: 960px) {
  .order-summary .accordion-content-container > hr:last-of-type, .page-order-summary .order-summary .accordion-content hr:last-of-type {
    display: none;
  }
  .order-summary .campaign-code-container {
    padding: 0.25em 0 0;
    transition: border-top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .order-summary .accordion.active + .accordion-content-container .campaign-code-container {
    border-top: 1px solid #999999;
    padding: 1em 0;
  }
  .order-summary .changeable .mailbox-address {
    font-size: 0.9em;
  }
}

.order-usp {
  margin-top: 2em;
  padding-bottom: 0.5em;
  border-radius: 1em;
  background-color: #f7f7f7;
}

.page-order-products .content.content--order {
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
}

.page-order-products-show .site-wrap {
  padding-bottom: 10em;
}
@media (max-width: 870px) {
  .page-order-products-show .site-wrap {
    padding-bottom: 20em;
  }
}
.chosen-address {
  text-align: center;
  color: #303a4b;
}

.ab-test-container-a .chosen-address .vat-notice {
  font-size: 1.125em;
  padding-top: 2em;
}
.ab-test-container-a .chosen-address__address {
  font-size: 1.25em;
  letter-spacing: 0.035rem;
}

.ab-test-container-b .chosen-address {
  font-size: 1.1em;
}
.ab-test-container-b .chosen-address__address {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
@media (min-width: 751px) {
  .ab-test-container-b .hide-on-large-devices {
    display: none;
  }
}
@media (max-width: 750px) {
  .ab-test-container-b .hide-on-small-devices {
    display: none;
  }
}

.page-order section {
  height: 80vh;
}
.page-order section .content .heading {
  color: #303a4b;
}
.page-order section .content p {
  text-align: center;
}
.page-order section .content .address-lookup {
  display: flex;
  min-height: 100vh;
  min-height: calc(100vh - (4.375em + 3.125em));
  margin-top: -4.375em;
  margin-bottom: -3.125em;
  padding: 0;
}

.page-order-products-not-found .order-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
}
.page-order-products-not-found .order-wrapper .order-content {
  max-width: 40em;
  border-right: none;
}
@media (max-width: 960px) {
  .page-order-products-not-found .order-wrapper .order-content {
    padding: 2em 1em;
  }
}
@media (max-width: 520px) {
  .page-order-products-not-found .order-wrapper .order-content {
    padding: 2em 0.5em;
  }
}
.page-order-products-not-found .order-content > p:first-of-type {
  padding-bottom: 0;
}
.page-order-products-not-found .address, .page-order-products-not-found .new-address {
  text-align: center;
}
.page-order-products-not-found .address {
  padding-bottom: 0;
  font-size: 1.6em;
  line-height: 1.2;
  color: #303a4b;
}
.page-order-products-not-found .new-address {
  margin-bottom: 0.7em;
}
.page-order-products-not-found form {
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}
.page-order-products-not-found form p {
  margin: 0;
  padding: 0;
}
.page-order-products-not-found form .icon {
  width: 2.5rem;
  height: 2.5rem;
}
@media (min-width: 351px) {
  .page-order-products-not-found form p:first-of-type, .page-order-products-not-found form p:nth-of-type(2) {
    flex: 1 1 calc(50% - 1em);
  }
  .page-order-products-not-found form p:nth-of-type(3) {
    flex: 0 0 calc(100% - 3em);
  }
}
@media (max-width: 350px) {
  .page-order-products-not-found form p {
    flex: 0 0 100%;
  }
  .page-order-products-not-found form p:last-child {
    background-color: #ed135a;
    border-radius: 0.5em;
  }
}

.order-coop {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-coop .hiper-coop-logo {
  max-width: 50vw;
  width: 20em;
  margin-bottom: 0.5em;
}
@media (max-width: 520px) {
  .order-coop .hiper-coop-logo {
    max-width: 60vw;
  }
}

.page-order-products .hiper-coop-logo .white,
.order-coop .hiper-coop-logo .white {
  fill: #303a4b;
}

.page-order-coop .coop-card,
.page-order-coop #coop_membership_no {
  display: block;
  margin: 0 auto;
}
.page-order-coop #coop_membership_no {
  max-width: 21em;
}
.page-order-coop .order-content .accordion-container {
  margin-top: 2em;
}

.page-order-contact form p {
  padding-bottom: 1em;
}
.page-order-contact form p:last-of-type {
  padding-bottom: 0;
}
.page-order-contact form p.error-message {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  font-size: 0.8em;
  color: #ed135a;
}
.mailcheck-suggestion {
  display: none;
  text-align: center;
}
.mailcheck-suggestion.visible {
  display: block;
}

.sbbu-details {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  max-width: 32em;
  max-height: 0;
}
.sbbu-details.visible {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  max-height: 100em;
  margin-bottom: 1em;
  transition: max-height, opacity 0.5s ease;
}

.sbbu-details__hint {
  padding-top: 0.5em;
}

.small-label {
  color: #303a4b;
  text-align: center;
  padding: 1em 0 0 0;
}

.small-label--left {
  text-align: left;
}

.sbbu-details .small-label:first-child {
  padding-top: 0;
}

.page-order-installation .installation-banner {
  margin-bottom: 1em;
  padding: 0.8em 1.2em;
  border-radius: 0.5em;
  background: #d61051;
  color: #ffffff;
}
.page-order-installation .installation-banner .heading {
  color: inherit;
}
.page-order-installation .tech-call-before-label {
  display: inline-block;
  width: auto;
  position: relative;
}
.page-order-installation .inline.tech-call-before {
  width: 7.5em;
  padding-right: 1.5em;
}
.page-order-installation .inline.tech-call-before + .icon {
  display: inline-block;
  height: 1em;
  position: absolute;
  top: 0.375em;
  left: 6em;
}
.page-order-installation .yes-no {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.page-order-installation .yes, .page-order-installation .no {
  min-width: 10.6em;
}
.page-order-installation .installation-date {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  display: flex;
  margin-bottom: 1em;
  max-height: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s all cubic-bezier(0.645, 0.045, 0.355, 1);
}
.page-order-installation .installation-date div:first-child {
  width: 100%;
}
.page-order-installation .installation-date p {
  opacity: 0;
  transition: 0.3s all cubic-bezier(0.645, 0.045, 0.355, 1);
}
.page-order-installation .installation-date.visible {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  max-height: 100em;
}
.page-order-installation .installation-date.visible p {
  opacity: 1;
}
.page-order-installation .order-content li {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75em;
}
.page-order-installation .order-content li:last-child {
  padding-bottom: 1.4em;
}
.page-order-installation .order-content li .icon {
  width: 2.5em;
  height: 2.5em;
  flex-shrink: 0;
}
@media (max-width: 520px) {
  .page-order-installation .order-content li .icon {
    width: 3em;
    height: 3em;
  }
}
.page-order-installation .order-content li .text {
  width: 100%;
}

.select-shipping-address .input-container {
  padding-bottom: 0.25em;
}
.select-shipping-address .input-container label {
  display: block;
  padding-bottom: 0.25em;
  hyphens: manual;
  line-height: 1.4;
}
.select-shipping-address .input-with-button, .select-shipping-address .input-with-button .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-shipping-address .input-with-button input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.select-shipping-address .input-with-button .button-container {
  padding: 0 0.2em;
  height: 2.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px solid #999999;
  border-left: 0;
}
.select-shipping-address .input-with-button .icon-button {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  font-size: 1.75em;
  cursor: pointer;
}
.select-shipping-address .input-with-button .icon-button, .select-shipping-address .input-with-button .icon-button .icon {
  width: 1em;
  height: 1em;
}
.select-shipping-address .input-with-button .icon-button:hover .icon {
  transform: rotate(-15deg) scale(1.25);
  opacity: 0.75;
}
.select-shipping-address .input-with-button .icon {
  display: block;
  color: transparent;
  fill: #303a4b;
  transform-origin: center;
  transition: 0.15s transform cubic-bezier(0.645, 0.045, 0.355, 1), 0.15s opacity cubic-bezier(0.645, 0.045, 0.355, 1);
}
.select-shipping-address .mailbox-list {
  overflow-y: auto;
  border-bottom: 1px solid #dad9d7;
}
.select-shipping-address .mailbox-item {
  display: block;
  padding-bottom: 0;
  width: 100%;
  border-bottom: 1px solid #dad9d7;
}
.select-shipping-address .mailbox-item:last-child {
  border-bottom: none;
}
.select-shipping-address .mailbox-item .checkbox-label {
  position: relative;
  display: block;
  padding: 0.5em 0 0.5em calc(1.2rem + .5em);
  width: 100%;
  line-height: 1.25;
}
.select-shipping-address .mailbox-item .checkbox-label::before {
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  margin: 0;
}
.select-shipping-address .mailbox-item .mailbox-name,
.select-shipping-address .mailbox-item address {
  display: block;
  hyphens: manual;
}
.select-shipping-address .mailbox-item .mailbox-distance {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.select-shipping-address .mailbox-item .mailbox-address {
  text-transform: capitalize;
}
@media (min-width: 521px) {
  .select-shipping-address .mailbox-item address {
    font-size: 0.8em;
  }
}
@media (max-width: 520px) {
  .select-shipping-address .mailbox-list {
    max-height: 35vh;
  }
  .select-shipping-address .mailbox-item address {
    font-size: 0.9em;
  }
}

.order-content .strikethrough,
.order-summary .strikethrough,
.wl-overlay .strikethrough {
  position: relative;
}
.order-content .strikethrough::before,
.order-summary .strikethrough::before,
.wl-overlay .strikethrough::before {
  content: "";
  position: absolute;
  top: 100%;
  transform: translate(-5%, -0.6em) rotate(-17deg);
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: #ed135a;
}
@media (max-width: 520px) {
  .order-content .strikethrough::before,
.order-summary .strikethrough::before,
.wl-overlay .strikethrough::before {
    transform: translate(-5%, -0.55em) rotate(-17deg);
  }
}

.summary-table {
  border: 0;
}
.summary-table + .summary-table {
  margin-top: 1em;
}
@media (min-width: 961px) {
  .summary-table .star {
    display: none;
  }
}
.summary-table, .summary-table th, .summary-table tr, .summary-table td {
  border-radius: 0;
}
.summary-table th, .summary-table td {
  padding: 0.25em 0;
  border: 0;
  background-color: transparent;
  color: #303a4b;
  line-height: 1.2;
}
.summary-table th {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
.summary-table th:last-child {
  text-align: right;
}
.summary-table th[colspan="2"] {
  text-align: center;
}
.summary-table td {
  padding-left: 0;
  padding-right: 0;
  vertical-align: middle;
}
.summary-table td:first-child:not(:only-child) {
  padding-right: 0.4em;
}
.summary-table td:last-child:not(:only-child) {
  text-align: right;
  white-space: nowrap;
}
.summary-table small {
  font-size: 0.9em;
  color: rgba(48, 58, 75, 0.9);
}
.summary-table tbody > tr:first-child > td, .summary-table tfoot > tr:first-child > td {
  padding-top: 0.5em;
}
.summary-table tbody > tr:last-child > td, .summary-table tfoot > tr:last-child > td {
  padding-bottom: 0.5em;
}
.summary-table .discount-total td {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  vertical-align: middle;
  background-color: #40b577;
  color: #ffffff;
}
.summary-table .discount-total td:first-child {
  padding-top: 0.25em;
  padding-left: 0.4em;
  border-radius: 0.5em 0 0 0.5em;
}
.summary-table .discount-total td:last-child {
  padding-top: 0.25em;
  padding-right: 0.4em;
  border-radius: 0 0.5em 0.5em 0;
}
.summary-table .discount-total td:last-child > span {
  font-size: 1.5em;
}
.summary-table .discount {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  color: #40b577;
}
.summary-table tfoot td {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.summary-table tfoot .total-row:first-child td {
  border-top: 1px solid #dad9d7;
}
.summary-table tfoot .total-row:last-child td {
  border-bottom: 1px solid #dad9d7;
}
.summary-table tfoot .total-row td {
  padding-bottom: 0;
  border-radius: 0 !important;
}

.summary-table-expandable {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
}
.summary-table-expandable .summary-table > tbody > tr {
  background-color: transparent;
}
.summary-table-expandable .summary-table > tbody > tr > td {
  padding: 0;
  background-color: transparent;
}
.summary-table-expandable .summary-table > tbody > tr table td {
  font-size: 1em;
}
.summary-table-expandable .summary-table > tbody > tr table tr:last-child td {
  padding-bottom: 0.4em;
}
.summary-table-expandable .summary-table > tbody > tr div {
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), max-height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.expand-summary-table {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0 0.4em;
  width: 10em;
  border: 0;
  border-radius: 0 0 0.5em 0.5em;
  cursor: pointer;
  background-color: #4b848e;
  color: #ffffff;
  font: inherit;
  font-size: 0.7em;
  transition: background-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.expand-summary-table:hover {
  background-color: #42747d;
}
.expand-summary-table .show, .expand-summary-table .hide {
  margin-left: 0.25em;
}
.expand-summary-table .hide {
  display: none;
}
.expand-summary-table .icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
  transform: rotate(90deg);
  color: transparent;
  fill: #ffffff;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.expand-summary-table.visible .show {
  display: none;
}
.expand-summary-table.visible .hide {
  display: block;
}
.expand-summary-table.visible .icon {
  transform: rotate(-90deg);
}

.expand-summary-table.visible + .summary-table > tbody > tr div {
  opacity: 1;
  max-height: 50em;
}

.summary-vat {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  padding-top: 0.5em;
  text-align: right;
}
.order-content .summary-vat {
  font-size: 0.9em;
}

.permission-disclaimer-wrapper {
  padding: 1em 1.5em;
  background-color: #f6f6f6;
  margin-bottom: 1em;
  border-radius: 1rem;
}
.permission-disclaimer-wrapper p {
  line-height: 1.4;
}

.order-complete .with-checkbox {
  padding-top: 0;
  padding-bottom: 0;
}
.order-complete .with-checkbox:last-of-type {
  padding-bottom: 1rem;
}
.order-complete .ordertake-submit {
  padding-top: 2em;
}
.order-complete .permissions-disclaimer {
  max-width: 36em;
}
.order-complete .permissions-disclaimer p {
  font-size: 0.7em;
  line-height: 1.2;
  letter-spacing: var(--letter-spacing-small-always);
  color: rgba(48, 58, 75, 0.75);
}

.page-order-receipt .content:not(.new-receipt-flow) .hide-on-old,
.page-order-receipt .content.new-receipt-flow .hide-on-new,
.page-sbbu .content:not(.new-receipt-flow) .hide-on-old,
.page-sbbu .content.new-receipt-flow .hide-on-new,
.page-order-receipt-selfcare-step .content:not(.new-receipt-flow) .hide-on-old,
.page-order-receipt-selfcare-step .content.new-receipt-flow .hide-on-new {
  display: none;
}
.page-order-receipt .content.new-receipt-flow,
.page-sbbu .content.new-receipt-flow,
.page-order-receipt-selfcare-step .content.new-receipt-flow {
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 40.625em;
}
.page-order-receipt .content.new-receipt-flow h1,
.page-sbbu .content.new-receipt-flow h1,
.page-order-receipt-selfcare-step .content.new-receipt-flow h1 {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  display: block;
  padding: 0 0 0.25rem;
  font-size: 1.8em;
  line-height: 1.2;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-align: center;
  text-transform: none;
  letter-spacing: normal;
}
@media (max-width: 520px) {
  .page-order-receipt .content.new-receipt-flow,
.page-sbbu .content.new-receipt-flow,
.page-order-receipt-selfcare-step .content.new-receipt-flow {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .page-order-receipt .content.new-receipt-flow h1,
.page-sbbu .content.new-receipt-flow h1,
.page-order-receipt-selfcare-step .content.new-receipt-flow h1 {
    font-size: 1.4em;
  }
}

@media (min-width: 961px) {
  .page-order-summary .order-content .summary-table,
.page-order-summary .order-content .summary-table-expandable,
.page-order-summary .order-content .summary-vat {
    display: none;
  }
}
@media (max-width: 960px) {
  .page-order-summary .order-summary .summary-table,
.page-order-summary .order-summary .summary-table-expandable,
.page-order-summary .order-summary .summary-vat {
    display: none;
  }

  .page-order-payment .order-wrapper .order-summary .accordion,
.page-order-receipt .order-wrapper .order-summary .accordion {
    border-bottom: none !important;
  }

  .page-order-payment .order-wrapper .order-content h1 + p {
    font-size: 0.8em;
  }
}
.page-order-receipt .receipt-selfcare,
.page-order-receipt-selfcare-step .receipt-selfcare {
  border-radius: 1em;
  background-color: #f7f7f7;
  box-shadow: inset 0 0 0 2px #ed135a;
}
.page-order-receipt .receipt-selfcare > h2, .page-order-receipt .receipt-selfcare > p,
.page-order-receipt-selfcare-step .receipt-selfcare > h2,
.page-order-receipt-selfcare-step .receipt-selfcare > p {
  text-align: center;
}
.page-order-receipt .receipt-selfcare > h2,
.page-order-receipt-selfcare-step .receipt-selfcare > h2 {
  padding-bottom: 0.5rem;
  font-size: 1.6em;
}
.page-order-receipt .receipt-selfcare > p,
.page-order-receipt-selfcare-step .receipt-selfcare > p {
  max-width: 30em;
  line-height: 1.4;
}
.page-order-receipt .receipt-selfcare .flex,
.page-order-receipt-selfcare-step .receipt-selfcare .flex {
  justify-content: center;
  align-items: center;
  gap: 0.7em 0.5em;
}
@media (max-width: 520px) {
  .page-order-receipt .receipt-selfcare .flex,
.page-order-receipt-selfcare-step .receipt-selfcare .flex {
    flex-direction: column;
  }
}
.page-order-receipt .receipt-selfcare .image,
.page-order-receipt-selfcare-step .receipt-selfcare .image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 12em;
}
.page-order-receipt .receipt-selfcare .image img,
.page-order-receipt-selfcare-step .receipt-selfcare .image img {
  margin-top: -0.5em;
}
.page-order-receipt .receipt-selfcare .image .button,
.page-order-receipt-selfcare-step .receipt-selfcare .image .button {
  font-size: 1.2em;
}
.page-order-receipt .receipt-selfcare ul > p,
.page-order-receipt .receipt-selfcare ul li,
.page-order-receipt-selfcare-step .receipt-selfcare ul > p,
.page-order-receipt-selfcare-step .receipt-selfcare ul li {
  padding-bottom: 0;
}
.page-order-receipt .receipt-selfcare ul li,
.page-order-receipt-selfcare-step .receipt-selfcare ul li {
  position: relative;
  padding-left: 0.75em;
}
.page-order-receipt .receipt-selfcare ul li::before,
.page-order-receipt-selfcare-step .receipt-selfcare ul li::before {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  content: "•";
  position: absolute;
  left: 0;
  color: #ed135a;
}
.page-order-receipt .receipt-selfcare .button,
.page-order-receipt-selfcare-step .receipt-selfcare .button {
  cursor: default;
}
.page-order-receipt .new-receipt-flow .receipt-selfcare,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare {
  padding: 0;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
}
.page-order-receipt .new-receipt-flow .receipt-selfcare h2,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h2 {
  font-weight: 400;
  font-variation-settings: "wght" 400;
}
.page-order-receipt .new-receipt-flow .receipt-selfcare h2, .page-order-receipt .new-receipt-flow .receipt-selfcare h2 + p,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h2,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h2 + p {
  padding: 0 0 1rem;
}
.page-order-receipt .new-receipt-flow .receipt-selfcare .receipt-selfcare-steps,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare .receipt-selfcare-steps {
  width: fit-content;
  margin: 0 auto;
}
.page-order-receipt .new-receipt-flow .receipt-selfcare h3,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h3 {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  display: grid;
  grid-template-areas: "number text";
  align-items: center;
  padding: 0 0 0.75em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.1em;
  text-align: left;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: normal;
}
.page-order-receipt .new-receipt-flow .receipt-selfcare h3 .text,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h3 .text {
  display: block;
  grid-area: text;
  justify-self: start;
}
.page-order-receipt .new-receipt-flow .receipt-selfcare h3 .number,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h3 .number {
  display: block;
  grid-area: number;
  justify-self: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background-color: #ed135a;
  color: #ffffff;
  line-height: 1.45;
  text-align: center;
}

.page-order-receipt .receipt-sbbu,
.page-order-receipt .receipt-selfcare {
  margin-bottom: 2em;
}
.page-order-receipt .receipt-sbbu + h1,
.page-order-receipt .receipt-selfcare + h1 {
  padding-bottom: 0.5rem;
}
.page-order-receipt .receipt-selfcare-heading,
.page-order-receipt .receipt-sbbu .receipt-sbbu-step,
.page-order-receipt .receipt-sbbu .number {
  display: flex;
}
.page-order-receipt .receipt-selfcare-heading,
.page-order-receipt .receipt-sbbu .receipt-sbbu-step {
  justify-content: flex-start;
}
.page-order-receipt .receipt-selfcare-heading,
.page-order-receipt .receipt-sbbu .number {
  align-items: center;
}
.page-order-receipt .receipt-selfcare-heading {
  gap: 0.25em;
  padding-bottom: 0.5rem;
  font-size: 2em;
}
.page-order-receipt .receipt-sbbu .receipt-sbbu-step {
  align-items: stretch;
  margin-bottom: 1em;
  border-radius: 1em;
  box-shadow: inset 0 0 0 2px #ed135a;
}
.page-order-receipt .receipt-sbbu .receipt-sbbu-step .number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ed135a;
  border-radius: 1em 0 0 1em;
  color: #ffffff;
}
.page-order-receipt .receipt-sbbu .receipt-sbbu-step .number span {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-size: 2em;
}
.page-order-receipt .receipt-sbbu .receipt-sbbu-step .text {
  flex-grow: 2;
}
.page-order-receipt .receipt-sbbu .receipt-sbbu-step h3 {
  padding: 0 0 0.1em;
  font-size: 1.2em;
  line-height: 1.2;
}
.page-order-receipt .receipt-sbbu .receipt-sbbu-step p {
  line-height: 1.4;
}
.page-order-receipt .receipt-selfcare {
  max-width: 34em;
}
.page-order-receipt .content.new-receipt-flow {
  grid-template-columns: auto;
  grid-template-areas: "header" "content" "summary";
  padding-bottom: 2em;
}
.page-order-receipt .content.new-receipt-flow .order-header,
.page-order-receipt .content.new-receipt-flow .order-content,
.page-order-receipt .content.new-receipt-flow .order-summary {
  padding: 0;
}
.page-order-receipt .content.new-receipt-flow .order-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
  border: 0;
}
.page-order-receipt .content.new-receipt-flow .receipt-sbbu {
  max-width: 32em;
  width: 100%;
}
.page-order-receipt .content.new-receipt-flow .receipt-sbbu h1 + p {
  text-align: center;
}
.page-order-receipt .content.new-receipt-flow .receipt-sbbu .receipt-sbbu-step:first-of-type {
  margin-bottom: 0.5em;
}
.page-order-receipt .content.new-receipt-flow .receipt-selfcare {
  padding-top: 1em;
}
.page-order-receipt .content.new-receipt-flow .order-summary .accordion .title-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.page-order-receipt .content.new-receipt-flow .order-summary .accordion h2 {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  text-transform: none;
  letter-spacing: normal;
}
.page-order-receipt .content.new-receipt-flow .order-summary .accordion-content {
  padding: 0 0 0.5em;
}
.page-order-receipt .content.new-receipt-flow .order-summary hr {
  margin: 0.5em 0;
  background-color: #dad9d7;
}

.page-sbbu .content.new-receipt-flow h1 + p {
  padding-bottom: 0.5em;
}
@media (max-width: 520px) {
  .page-sbbu .content.new-receipt-flow h1 + p {
    font-size: 0.9em;
  }
}
.page-sbbu .content.new-receipt-flow h1 + p + .button {
  display: block;
  margin: 0 auto 1.5em;
  width: fit-content;
  padding: 0.25em 0.75em 0;
  font-size: 1.2em;
}

.page-order-receipt-selfcare-step h1 {
  padding-bottom: 1rem;
}
.page-order-receipt-selfcare-step .receipt-selfcare {
  margin: 0 auto 1em;
  width: fit-content;
}
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h1 {
  padding: 0;
}

@media (min-width: 521px) {
  .page-order-receipt .receipt-sbbu {
    max-width: 32em;
  }
  .page-order-receipt .receipt-sbbu .receipt-sbbu-step .number {
    flex: 0 0 4em;
  }
  .page-order-receipt .receipt-sbbu .receipt-sbbu-step .text {
    padding: 1em;
  }

  .page-order-receipt .new-receipt-flow .receipt-selfcare h2,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h2 {
    font-size: 1.4em;
  }
  .page-order-receipt .new-receipt-flow .receipt-selfcare h2 + p,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h2 + p {
    font-size: 0.8em;
  }
  .page-order-receipt .new-receipt-flow .receipt-selfcare > p:nth-of-type(2),
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare > p:nth-of-type(2) {
    padding: 0.5em 0 0.75em;
  }
  .page-order-receipt .new-receipt-flow .receipt-selfcare h3,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h3 {
    grid-template-columns: calc(1.6em * 1.5) auto;
    gap: 0.75em;
  }
  .page-order-receipt .new-receipt-flow .receipt-selfcare h3 .number,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h3 .number {
    font-size: 1.6em;
  }

  .page-order-receipt .receipt-selfcare {
    padding: 1em;
  }

  .page-order-receipt-selfcare-step .receipt-selfcare {
    padding: 1em 1.5em;
  }

  .page-order-receipt .progress-bar,
.page-sbbu .progress-bar,
.page-order-receipt-selfcare-step .progress-bar {
    margin-bottom: 2em;
  }
  .page-order-receipt .new-receipt-flow .progress-bar,
.page-sbbu .new-receipt-flow .progress-bar,
.page-order-receipt-selfcare-step .new-receipt-flow .progress-bar {
    margin-bottom: 3em;
  }
}
@media (max-width: 520px) {
  .page-order-receipt .receipt-sbbu .receipt-sbbu-step .number {
    flex: 0 0 3em;
  }
  .page-order-receipt .receipt-sbbu .receipt-sbbu-step .text {
    padding: 0.5em 0.75em;
  }
  .page-order-receipt .new-receipt-flow .receipt-sbbu h1 + p {
    font-size: 0.9em;
  }

  .page-order-receipt .new-receipt-flow .receipt-selfcare h2,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h2 {
    font-size: 1.2em;
  }
  .page-order-receipt .new-receipt-flow .receipt-selfcare h2 + p,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h2 + p {
    font-size: 0.9em;
  }
  .page-order-receipt .new-receipt-flow .receipt-selfcare > p:nth-of-type(2),
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare > p:nth-of-type(2) {
    padding: 0 0 1em;
  }
  .page-order-receipt .new-receipt-flow .receipt-selfcare h3,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h3 {
    grid-template-columns: calc(1.5em * 1.5) auto;
    gap: 0.5em;
  }
  .page-order-receipt .new-receipt-flow .receipt-selfcare h3 .number,
.page-order-receipt-selfcare-step .new-receipt-flow .receipt-selfcare h3 .number {
    font-size: 1.5em;
  }

  .page-order-receipt .receipt-selfcare,
.page-order-receipt-selfcare-step .receipt-selfcare {
    padding: 1em 0.5em;
  }

  .page-order-receipt .progress-bar,
.page-sbbu .progress-bar,
.page-order-receipt-selfcare-step .progress-bar {
    padding: 0 0 1em;
  }
}
@media (max-width: 520px) and (min-width: 351px) {
  .order-complete .checkbox-label,
.order-complete .checkbox-label + a {
    font-size: 1.1em;
  }
}
.no-js .page-selfcare-card-add .please-wait,
.no-js .page-order-payment .please-wait {
  display: none;
}

.js .page-selfcare-card-add .ordertake-submit .button,
.js .page-order-payment .ordertake-submit .button {
  display: none;
}
.js .page-selfcare-card-add .please-wait,
.js .page-order-payment .please-wait {
  text-align: center;
}

.products-show-call-me {
  max-width: 100%;
  max-height: auto;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 7;
  margin: auto;
  padding: 1.875em 0.625em 2.5em;
  background: #83afc5;
  text-align: center;
  transform-origin: bottom;
  animation-name: productsFiberBlurbAnimation;
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-delay: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: backwards;
}
.products-show-call-me.hidden {
  display: none;
}
.products-show-call-me .heading {
  padding-bottom: 0.625em;
  line-height: 1;
}
.products-show-call-me .grid {
  max-width: 700px;
  margin: auto;
}
.products-show-call-me .item {
  flex: 1 1 auto;
  padding: 0 0.3125em;
}
.products-show-call-me .form__sending-overlay {
  background-color: rgba(131, 175, 197, 0.9);
}
.products-show-call-me .form__not-sent-overlay,
.products-show-call-me .form__sent-overlay {
  background-color: #83afc5;
  color: white;
}
.products-show-call-me .form__not-sent-overlay p, .products-show-call-me .form__not-sent-overlay h3,
.products-show-call-me .form__sent-overlay p,
.products-show-call-me .form__sent-overlay h3 {
  margin: 0;
  padding: 0;
}
@media (max-width: 920px) {
  .products-show-call-me .wrap-me {
    display: block;
  }
  .products-show-call-me .item {
    padding-bottom: 0.3125em;
  }
}
@media (max-width: 520px) {
  .products-show-call-me {
    padding: 0.625em;
  }
  .products-show-call-me .heading {
    font-size: 1.5em;
  }
}

@keyframes productsFiberBlurbAnimation {
  0% {
    max-height: 0;
    transform: scaleX(0) scaleY(0.5);
    opacity: 0;
  }
  100% {
    max-height: 20em;
    transform: scaleX(1) scaleY(1);
    opacity: 1;
  }
}
.contact__grid-item {
  flex: 1 1 50%;
}

.contact__grid {
  margin-bottom: 1em;
  gap: 1em;
}
.contact__grid.error {
  position: relative;
}
.contact__grid.error .contact__grid-item {
  position: relative;
  z-index: 2;
}
.contact__grid.error:after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0.625em;
  left: 0;
  z-index: 1;
  border-radius: 0.3125em;
  box-shadow: 0 0 0 3px #dad9d7, 0 0 0 5px #ed135a;
  content: "";
}
@media (max-width: 870px) {
  .contact__grid.grid {
    display: flex;
  }
}

.page-order-contact .contact-info__address,
.page-order-contact .contact-info__personal-info {
  padding-bottom: 1em;
}
.page-order-contact .contact__invoice {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
  padding-bottom: 0;
  transition: 0.2s all cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
}
.page-order-contact .contact__invoice.visible {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  max-height: 100em;
  padding: 1em 0;
  transition: 0.2s all cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}
.page-order-contact .contact__invoice .address-widget-wrap {
  min-width: auto;
  height: auto;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget {
  position: static;
  max-width: none;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0.5em;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget #address-field,
.page-order-contact .contact__invoice .address-widget-wrap .address-widget .manual-address .street input,
.page-order-contact .contact__invoice .address-widget-wrap .address-widget .manual-address .manual-address__row:last-child input {
  border-radius: 0.5em;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget #address-field {
  width: 100%;
  border: 1px solid #999999;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget .dawa-address__propositions-wrap {
  margin-top: 0;
  background-color: #ffffff;
  border-radius: 0.25em 0.25em 0.5em 0.5em;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget .dawa-address__propositions-wrap li {
  color: rgba(0, 0, 0, 0.9);
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget .dawa-address__propositions-wrap li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget .dawa-address__propositions-wrap li:hover, .page-order-contact .contact__invoice .address-widget-wrap .address-widget .dawa-address__propositions-wrap li:focus {
  background-color: #ed135a;
  color: #ffffff;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget .dawa-address__propositions-wrap .dawa-address__bottom-bar {
  padding-top: 0;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget .dawa-address__propositions-wrap .dawa-address__bottom-bar .dawa-address__address-not-found {
  width: 100%;
  background-color: #83afc5;
  color: #ffffff;
  border-radius: 0 0 0.5em 0.5em;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget .dawa-address__propositions-wrap .dawa-address__bottom-bar .dawa-address__address-not-found:hover, .page-order-contact .contact__invoice .address-widget-wrap .address-widget .dawa-address__propositions-wrap .dawa-address__bottom-bar .dawa-address__address-not-found:focus {
  background-color: #6099b5;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget #manual-address {
  padding: 0;
}
.page-order-contact .contact__invoice .address-widget-wrap .address-widget #manual-address .manual-address__row {
  padding-right: 0;
}
.page-order-contact .address-widget__left {
  max-width: 100%;
}
@media (max-width: 520px) {
  .page-order-contact .address-widget__left {
    padding-bottom: 0;
  }
}
.page-order-contact .address-widget__right {
  display: none;
}
.page-order-contact .manual-address {
  padding-right: 0.635em;
}

.show-manual-address .page-order-contact .address-widget,
.page-order-contact .address-widget {
  margin-bottom: 1.2em;
  background-color: transparent;
}
.show-manual-address .page-order-contact .address-widget .icon-arrow,
.page-order-contact .address-widget .icon-arrow {
  color: #94979e;
}

.error_with_call_me {
  padding-top: 0.625em;
}

@media (min-width: 497px) {
  #creditcard-iframe {
    min-height: 550px;
  }
}
@media (max-width: 496px) {
  #creditcard-iframe {
    min-height: 530px;
  }
}

[class^=page-help] .blurb {
  padding: 0;
}

.page-help .header p {
  padding-top: 1em;
  line-height: 1.4;
  hyphens: manual;
}

[class^=page-help-] .header {
  padding: 0;
  width: 100%;
  background-color: #42747d;
}
[class^=page-help-] .header .breadcrumb-navigation,
[class^=page-help-] .header h1, [class^=page-help-] .header .summary {
  color: #ffffff;
}
[class^=page-help-] .header .breadcrumb-navigation {
  display: block;
  margin: 0;
  padding-bottom: 0.25em;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: 300;
  letter-spacing: 0.025em;
}
[class^=page-help-] .header .breadcrumb-navigation a, [class^=page-help-] .header .breadcrumb-navigation .breadcrumb-arrow {
  display: inline-block;
  line-height: 1.2;
}
[class^=page-help-] .header .breadcrumb-navigation a {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}
[class^=page-help-] .header .breadcrumb-navigation a:last-child {
  display: none;
}
[class^=page-help-] .header .breadcrumb-navigation a:hover {
  color: #ffffff;
  border-bottom-color: white;
}
[class^=page-help-] .header .breadcrumb-navigation .breadcrumb-arrow {
  position: relative;
  width: 0.5em;
  height: 0.5em;
}
[class^=page-help-] .header .breadcrumb-navigation .breadcrumb-arrow::before, [class^=page-help-] .header .breadcrumb-navigation .breadcrumb-arrow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 1px;
  background-color: #ffffff;
}
[class^=page-help-] .header .breadcrumb-navigation .breadcrumb-arrow::before {
  transform: rotate(45deg);
}
[class^=page-help-] .header .breadcrumb-navigation .breadcrumb-arrow::after {
  transform: translate(0, 5px) rotate(-45deg);
}
[class^=page-help-] .header h1 {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.2;
  text-transform: none;
  letter-spacing: normal;
  hyphens: manual;
}
[class^=page-help-] .header .summary {
  padding: 0;
  max-width: 28em;
  line-height: 1.4;
  hyphens: manual;
}

.help-search {
  margin: 1em auto 2em;
  max-width: 40em;
  width: 100%;
}
.page-help-category .help-search {
  margin-bottom: 0;
}

.help-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.help-content h1, .help-content h2, .help-content h3, .help-content h4, .help-content h5, .help-content h6 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 600;
  font-variation-settings: "wght" 600;
  line-height: 1.2;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  hyphens: manual;
}
.help-content h1 {
  font-size: 2em;
}
.help-content h2 {
  font-size: 1.4em;
}
.help-content h3 {
  font-size: 1.2em;
}
.help-content h4, .help-content h5, .help-content h6 {
  font-size: 1.1em;
}
.help-content h5 {
  color: #586a89;
}
.help-content h6 {
  color: #7083a4;
}

.help-menu {
  margin-bottom: 4em;
}
.help-menu:last-child {
  margin-bottom: 0;
}
.help-menu .item {
  margin: 0;
  padding: 0;
  width: 100%;
}
.help-menu .item a {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: none;
  color: #303a4b;
  line-height: 1.2;
}
.help-menu.help-menu--category, .help-menu.help-menu--most-viewed {
  display: grid;
  justify-items: center;
  align-items: stretch;
}
.help-menu.help-menu--category .item a, .help-menu.help-menu--most-viewed .item a {
  text-align: center;
}
.help-menu.help-menu--category {
  max-width: 44em;
}
.help-menu.help-menu--category .item:nth-child(1) {
  order: 1;
}
.help-menu.help-menu--category .item:nth-child(2) {
  order: 5;
}
.help-menu.help-menu--category .item:nth-child(3) {
  order: 2;
}
.help-menu.help-menu--category .item:nth-child(4) {
  order: 3;
}
.help-menu.help-menu--category .item:nth-child(5) {
  order: 6;
}
.help-menu.help-menu--category .item:nth-child(6) {
  order: 4;
}
.help-menu.help-menu--category .item h2, .help-menu.help-menu--category .item h3, .help-menu.help-menu--category .item a, .help-menu.help-menu--category .item .summary {
  text-align: center;
  color: #303a4b;
}
.help-menu.help-menu--category .item a {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 0.5em 0.75em;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.help-menu.help-menu--category .item a .icon, .help-menu.help-menu--category .item a h2 {
  transition: color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.help-menu.help-menu--category .item a .icon {
  margin-bottom: 0.5rem;
  width: 1em;
  height: 1em;
  color: #303a4b;
  fill: #ffffff;
}
.help-menu.help-menu--category .item a h2 {
  padding: 0;
  line-height: 1;
  font-weight: 300;
}
.help-menu.help-menu--category .item a .summary {
  color: rgba(48, 58, 75, 0.8);
  font-size: 0.8em;
  line-height: 1.4;
}
.help-menu.help-menu--category .item a:hover {
  box-shadow: var(--box-shadow-hover);
  color: #ed135a;
}
.help-menu.help-menu--category .item a:hover .icon, .help-menu.help-menu--category .item a:hover h2 {
  color: #ed135a;
}
.help-menu.help-menu--article .item a, .help-menu.help-menu--most-viewed .item a, .help-menu.help-menu--related .item a {
  display: block;
}
.help-menu.help-menu--article {
  width: 100%;
}
.help-menu.help-menu--article .item a {
  padding: 0.75em 0.5em;
  border-bottom: 1px solid #dad9d7;
  transition: color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.help-menu.help-menu--article .item a:hover {
  color: #ed135a;
}
.help-menu.help-menu--article .item:first-child a {
  border-top: 1px solid #dad9d7;
}
@media (min-width: 769px) {
  .help-menu.help-menu--article {
    max-width: 28em;
  }
}
@media (max-width: 768px) {
  .help-menu.help-menu--article {
    max-width: 24em;
  }
}
.help-menu.help-menu--most-viewed {
  max-width: 44em;
}
.help-menu.help-menu--related {
  padding-top: 0.5em;
}
.help-menu.help-menu--related .item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5em;
  padding: 0.5em 0;
  border-bottom: 1px solid #dad9d7;
  color: #303a4b;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.help-menu.help-menu--related .item:last-child {
  border-bottom: none;
}
.help-menu.help-menu--related .item:hover {
  color: rgba(48, 58, 75, 0.75);
  border-bottom-color: #dad9d7;
}
.help-menu.help-menu--related .item:hover .icon {
  fill: #ed135a;
  transform: translateX(0.2em);
}
.help-menu.help-menu--related .item .icon {
  flex-shrink: 0;
  display: block;
  width: 1em;
  height: 1em;
  color: transparent;
  fill: #303a4b;
  transform: translateX(0);
  transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.help-menu.help-menu--related .item .icon svg {
  transform: scale(1.5);
}
.help-menu.help-menu--related .item .text {
  font-size: 0.9em;
}

.help-article {
  display: grid;
  align-items: start;
}
.help-article h1, .help-article h2, .help-article h3, .help-article h4, .help-article h5, .help-article h6 {
  justify-self: start;
}
.help-article h1.center, .help-article h2.center, .help-article h3.center, .help-article h4.center, .help-article h5.center, .help-article h6.center {
  justify-self: center;
  text-align: center;
}
.help-article h1 b, .help-article h1 strong, .help-article h2 b, .help-article h2 strong, .help-article h3 b, .help-article h3 strong, .help-article h4 b, .help-article h4 strong, .help-article h5 b, .help-article h5 strong, .help-article h6 b, .help-article h6 strong {
  font-weight: 800;
  font-variation-settings: "wght" 800;
}

.help-article-content {
  grid-area: article;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2em 0;
  /* Column image max-width in px
            [1]       : 1200 (Use 760 if image with text)
          [1] [2]     : 760 (666px for 100% on mobile/@media (max-width: 700px))
        [1] [2] [3]   : 666
      [1] [2] [3] [4] : 666
  */
}
.help-article-content:first-child {
  padding-top: 0;
}
.help-article-content:not(:last-child) {
  margin-bottom: 1em;
}
.help-article-content .summary {
  justify-self: left;
}
.help-article-content h2, .help-article-content h3, .help-article-content h4, .help-article-content h5, .help-article-content h6 {
  padding-top: 0;
  padding-bottom: 0.7rem;
}
.help-article-content .accordion h2,
.help-article-content .accordion h3,
.help-article-content .accordion h4 {
  padding-bottom: 0;
}
.help-article-content .accordion-content :first-child h2:first-child,
.help-article-content .accordion-content :first-child h3:first-child,
.help-article-content .accordion-content :first-child h4:first-child {
  padding-top: 0.2em;
}
.help-article-content p, .help-article-content li {
  margin: 0;
}
@media (min-width: 1024px) {
  .help-article-content .prices-table th, .help-article-content .prices-table td,
.help-article-content p:not(.summary), .help-article-content li,
.help-article-content .accordion .title--montserrat {
    font-size: 0.9rem;
  }
  .help-article-content p:not(.summary), .help-article-content li {
    line-height: 1.8;
  }
}
@media (min-width: 1401px) {
  .help-article-content p:not(.summary), .help-article-content li {
    line-height: 2;
  }
}
.help-article-content .paragraph {
  width: 100%;
}
.help-article-content .paragraph h1, .help-article-content .paragraph h2, .help-article-content .paragraph h3, .help-article-content .paragraph h4, .help-article-content .paragraph h5, .help-article-content .paragraph h6 {
  padding-top: 1rem;
}
.help-article-content .paragraph h1:first-child, .help-article-content .paragraph h2:first-child, .help-article-content .paragraph h3:first-child, .help-article-content .paragraph h4:first-child, .help-article-content .paragraph h5:first-child, .help-article-content .paragraph h6:first-child {
  padding-top: 0;
}
.help-article-content .paragraph-image:not(:first-child) {
  margin-top: 0.95rem;
}
.help-article-content .paragraph-image:not(:last-child) {
  margin-bottom: 2rem;
}
.help-article-content .paragraph-image.paragraph-image--connect:not(:last-child) {
  margin-bottom: 0.7rem;
}
.help-article-content .button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5em;
  padding-bottom: 0.7em;
  width: 100%;
}
.help-article-content .button-container.pb-2 {
  padding-bottom: 1.4em;
}
.help-article-content .button-container:last-child {
  padding-bottom: 0;
}
.help-article-content .button-container .normal-fw {
  font-weight: 400;
  font-variation-settings: "wght" 400;
}
.help-article-content .button-container .icon-arrow-down {
  transform: rotate(90deg);
}
.help-article-content .button-container .button {
  justify-content: space-between;
  width: 100%;
  box-shadow: var(--box-shadow-small);
}
.help-article-content .button-container .button:hover {
  box-shadow: var(--box-shadow-small-hover);
}
.help-article-content .list {
  counter-reset: grid;
  display: table;
  break-inside: avoid-column;
  page-break-inside: avoid;
  padding-bottom: 0.7em;
}
.help-article-content .list:last-child {
  padding-bottom: 0;
}
.help-article-content .list p, .help-article-content .list h2, .help-article-content .list h3, .help-article-content .list h4, .help-article-content .list h5, .help-article-content .list h6 {
  text-align: left;
}
.help-article-content .list p:not(:last-child) {
  padding-bottom: 0.35em;
}
.help-article-content .list h2, .help-article-content .list h3, .help-article-content .list h4, .help-article-content .list h5, .help-article-content .list h6 {
  padding-top: 0.35rem;
  padding-bottom: 0.2rem;
}
.help-article-content .list h2:first-child, .help-article-content .list h3:first-child, .help-article-content .list h4:first-child, .help-article-content .list h5:first-child, .help-article-content .list h6:first-child {
  padding-top: 0;
}
.help-article-content .list li {
  position: relative;
  padding-left: 1.5em;
  padding-bottom: 0.35em;
}
.help-article-content .list li::before {
  position: absolute;
  left: 0;
  color: #ed135a;
}
.help-article-content .list li:last-child {
  padding-bottom: 0;
}
.help-article-content .list li.h2::before, .help-article-content .list li.h3::before, .help-article-content .list li.h4::before, .help-article-content .list li.h5::before, .help-article-content .list li.h6::before {
  line-height: 1.2;
}
.help-article-content .list li.h2::before {
  font-size: 1.4em;
}
.help-article-content .list li.h3::before {
  font-size: 1.2em;
}
.help-article-content .list li.h4::before, .help-article-content .list li.h5::before, .help-article-content .list li.h6::before {
  font-size: 1.1em;
}
.help-article-content .list.list--number li::before {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  counter-increment: grid;
  content: counter(grid) ".";
}
.help-article-content .list.list--bullet li::before {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  content: "•";
}
.help-article-content .list.list--image {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.help-article-content .list.list--image li {
  width: 100%;
  padding-bottom: 0.7em;
}
.help-article-content .list.list--image li:last-child {
  padding-bottom: 0;
}
.help-article-content .list.list--image li:last-child p:last-child {
  padding-bottom: 0;
}
.help-article-content .list.list--image li:last-child img:last-child {
  margin-bottom: 0;
}
.help-article-content .list.list--image img {
  margin-bottom: 1.4em;
}
.help-article-content .list .list--number li::before {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  content: counter(grid, lower-alpha) ".";
}
.help-article-content .list .list--bullet li::before {
  font-weight: 900;
  font-variation-settings: "wght" 900;
}
.help-article-content .list.list--bullet .list--bullet li::before {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  content: "⚬";
}
.help-article-content .list.list--compact li {
  padding-bottom: 0;
}
.help-article-content .list li.warning {
  list-style-type: none;
  counter-increment: grid -1;
}
.help-article-content .list li.warning::before {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  content: "!";
  top: 0.2em;
  left: -0.4em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background-color: #ed135a;
  color: #ffffff;
  text-align: center;
  line-height: 1.6;
}
.help-article-content .icon-inline {
  position: relative;
  display: inline-block;
  width: 1.2em;
  height: 1em;
  color: transparent;
  fill: #303a4b;
}
.help-article-content .icon-inline svg {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 1.5em;
  height: 1.5em;
  transform: translate(-50%, -45%);
}
.help-article-content code {
  font-family: Monaco, Consolas, "Courier New", Courier, "Liberation Mono", monospace;
  white-space: pre-wrap;
  letter-spacing: 0.1em;
  word-break: break-word;
}
.help-article-content code .nowrap {
  white-space: nowrap;
}
.help-article-content code.inline-code {
  display: inline;
  border-radius: 0.25em;
  padding: 0.1em 0.25em;
  background-color: #eeeeee;
  color: #303a4b;
}
.help-article-content p.center {
  text-align: center;
}
.help-article-content .prices-table {
  max-width: none;
}
.help-article-content .prices-table th, .help-article-content .prices-table td {
  vertical-align: baseline;
}
.help-article-content .prices-table td:last-child {
  width: 45%;
}
.help-article-content .prices-table td:not(.prices-table-coax300):last-child:not(:only-child) {
  white-space: wrap;
}
.help-article-content .comparing-chart {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}
.help-article-content .comparing-chart::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset -1em 0 0 -1em rgba(0, 0, 0, 0.3), inset 1em 0 0 -1em rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none;
}
.help-article-content .comparing-chart.shadow-right::after {
  box-shadow: inset -1em 0 1em -1em rgba(0, 0, 0, 0.3), inset 1em 0 0 -1em rgba(0, 0, 0, 0.3);
}
.help-article-content .comparing-chart.shadow-left::after {
  box-shadow: inset -1em 0 0 -1em rgba(0, 0, 0, 0.3), inset 1em 0 1em -1em rgba(0, 0, 0, 0.3);
}
.help-article-content .comparing-chart.shadow-right.shadow-left::after {
  box-shadow: inset -1em 0 1em -1em rgba(0, 0, 0, 0.3), inset 1em 0 1em -1em rgba(0, 0, 0, 0.3);
}
.help-article-content .comparing-chart-table-container {
  display: block;
  width: 100%;
  overflow-x: auto;
}
.help-article-content .comparing-chart-table {
  border-radius: 0;
  border-collapse: collapse;
}
.help-article-content .comparing-chart-table tr:first-child td, .help-article-content .comparing-chart-table tr:first-child th,
.help-article-content .comparing-chart-table tr:last-child td,
.help-article-content .comparing-chart-table tr:last-child th {
  border-radius: 0;
}
.help-article-content .comparing-chart-table th, .help-article-content .comparing-chart-table td {
  padding: 0.5em;
  vertical-align: middle;
  border: 1px solid #dad9d7;
}
.help-article-content .comparing-chart-table th p, .help-article-content .comparing-chart-table td p {
  line-height: 1.4;
}
.help-article-content .comparing-chart-table .icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1.5em;
  height: 1.5em;
  margin-bottom: 0.35em;
}
.help-article-content .comparing-chart-table .icon:last-child {
  margin-bottom: 0;
}
.help-article-content .comparing-chart-table .icon.icon-check {
  color: #40b577;
}
.help-article-content .comparing-chart-table .icon.icon-cross {
  color: #ed135a;
}
.help-article-content .comparing-chart-table thead th:first-child {
  background-color: transparent;
  border: none;
}
.help-article-content .comparing-chart-table thead th:nth-child(1n+2) {
  background-color: #eeeeee;
}
.help-article-content .comparing-chart-table thead th {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  text-align: center;
}
.help-article-content .comparing-chart-table tbody th {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  background-color: #eeeeee;
  color: #303a4b;
}
.help-article-content .comparing-chart--internet .comparing-chart-table thead th {
  border: none;
}
.help-article-content .comparing-chart--internet .comparing-chart-table thead th:nth-child(2) {
  border-radius: 0.5em 0 0 0;
}
.help-article-content .comparing-chart--internet .comparing-chart-table thead th:last-child {
  border-radius: 0 0.5em 0 0;
}
.help-article-content .comparing-chart--internet .comparing-chart-table thead th:nth-child(2) {
  background-color: #ed135a;
}
.help-article-content .comparing-chart--internet .comparing-chart-table thead th:nth-child(3) {
  background-color: #4b848e;
}
.help-article-content .comparing-chart--internet .comparing-chart-table thead th:nth-child(4) {
  background-color: #7acfe0;
}
.help-article-content .comparing-chart--internet .comparing-chart-table tbody tr:first-child td {
  border-top: none;
}
.help-article-content .comparing-chart--ip-address .comparing-chart-table th {
  background-color: #eeeeee;
  color: #303a4b;
}
.help-article-content .text-box {
  margin-bottom: 1em;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
  background-color: #ffffff;
}
.help-article-content .text-box:last-child {
  margin-bottom: 0;
}
.help-article-content .text-box.text-box--match-height {
  align-self: stretch;
}
.help-article-content .text-box.text-box--gray {
  background-color: #f7f7f7;
}
.help-article-content .text-box.text-box--teal {
  background-color: #4b848e;
}
.help-article-content .text-box.text-box--red {
  background-color: #ed135a;
}
.help-article-content .text-box.text-box--green {
  background-color: #40b577;
}
.help-article-content .text-box.text-box--teal, .help-article-content .text-box.text-box--red, .help-article-content .text-box.text-box--green {
  color: #ffffff;
}
.help-article-content .text-box.text-box--teal .list li::before, .help-article-content .text-box.text-box--red .list li::before, .help-article-content .text-box.text-box--green .list li::before {
  color: #ffffff;
}
.help-article-content .id-anchor.id-anchor--help {
  transform: translateY(calc(-3.75em - 4em));
}
.help-article-content .address-widget-wrap {
  width: 100%;
}
.installation-procedure-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1em 0;
  max-width: 38em;
}
.installation-procedure-container .installation-procedure {
  width: 6em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.installation-procedure-container .installation-procedure .icon, .installation-procedure-container .installation-procedure svg {
  width: 4em;
  height: 4em;
}
.installation-procedure-container .installation-procedure .icon {
  display: block;
  position: relative;
  color: #303a4b;
  fill: #ffffff;
}
.installation-procedure-container .installation-procedure .icon svg {
  position: relative;
  z-index: 1;
}
.installation-procedure-container .installation-procedure .icon::before {
  content: "";
  position: absolute;
  z-index: 0;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 6em;
  height: 0.5em;
  background-color: #2c3545;
}
.installation-procedure-container .installation-procedure p {
  padding-top: 0.25em;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
}
.installation-procedure-container .installation-procedure.installation-procedure-start .icon::before {
  left: 100%;
  width: 2em;
}
.installation-procedure-container .installation-procedure.installation-procedure-end .icon::before {
  left: 0%;
  width: 2em;
}
.installation-procedure-container .installation-procedure.installation-procedure-current .icon {
  color: #ed135a;
}

.help-article-content .big-steps {
  counter-reset: grid;
}
.help-article-content .big-steps li, .help-article-content .big-steps p {
  line-height: 1.6;
}
.help-article-content .big-steps > li {
  position: relative;
  padding: 1.5em 0 1.5em 3.75em;
  border-bottom: 1px solid #dad9d7;
}
.help-article-content .big-steps > li:last-child {
  border-bottom: none;
}
.help-article-content .big-steps > li::before {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  counter-increment: grid;
  content: counter(grid) ".";
  position: absolute;
  top: calc(-.35em / 6);
  left: 0;
  width: 1em;
  font-size: 3em;
  text-align: right;
  color: #ed135a;
}
.help-article-content .article {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: start;
  margin-bottom: 4em;
  width: 100%;
}
.help-article-content .article:last-child {
  margin-bottom: 0;
}
.help-article-content .article img {
  border-radius: 0.5em;
}
.help-article-content .article img.max-height {
  max-height: 50vh;
}
.help-article-content .article img.no-br {
  border-radius: 0;
}
.help-article-content .article img.br1 {
  border-radius: 1em;
}
.help-article-content .article img.box-shadow {
  box-shadow: var(--box-shadow);
}
.help-article-content .article img.mb0 {
  margin-bottom: 0;
}
.help-article-content .article.article--2 {
  gap: 1em;
}
@media (min-width: 701px) {
  .help-article-content .article.article--2 {
    grid-template-columns: 1fr 1fr;
  }
  .help-article-content .article.article--2 .paragraph h2, .help-article-content .article.article--2 .paragraph h3, .help-article-content .article.article--2 .paragraph h4, .help-article-content .article.article--2 .paragraph p, .help-article-content .article.article--2 .paragraph li {
    max-width: 24rem;
  }
  .help-article-content .article.article--2 .accordion-container {
    min-width: 0;
  }
  .help-article-content .article.article--align-text > div:nth-child(1),
.help-article-content .article.article--align-text > div:nth-child(3) {
    justify-self: end;
  }
  .help-article-content .article.article--align-text > div:nth-child(2),
.help-article-content .article.article--align-text > div:nth-child(4) {
    justify-self: start;
  }
  .help-article-content .article img.small {
    max-width: 22em;
  }
  .help-article-content .article.article--connect {
    margin-bottom: 2em;
  }
  .help-article-content .article.article--x-height img {
    margin-top: 0.65em;
  }
}
@media (max-width: 700px) {
  .help-article-content .article img.small {
    max-width: 18em;
    max-height: 50vh;
  }
  .help-article-content .article.article--connect {
    margin-bottom: 1em;
  }
  .help-article-content .article.article--2 {
    grid-template-columns: 1fr;
  }
  .help-article-content .article.article--2.article--phone-reverse > :nth-child(1) {
    grid-row: 2;
  }
  .help-article-content .article.article--2.article--phone-reverse > :nth-child(2) {
    grid-row: 1;
  }
}

.help-article-related {
  grid-area: sidebar;
}
.help-article-related h3 {
  padding: 0;
  font-size: 1.4em;
}

@media (min-width: 1161px) {
  .help-article {
    grid-template-areas: ". article sidebar";
    grid-template-columns: 1.5fr 3fr 1.5fr;
    justify-items: start;
    gap: 2em;
    max-width: 80em;
  }
}
@media (min-width: 1161px) and (max-width: 1620px) {
  .help-article {
    grid-template-areas: "article sidebar";
    grid-template-columns: 3fr 1.5fr;
    max-width: 60em;
  }
}
@media (min-width: 1161px) {
  .help-article-related {
    padding-left: 2em;
    border-left: 1px solid #dad9d7;
  }
}
@media (max-width: 1160px) {
  [class^=page-help-] .header .content,
.help-article {
    max-width: 40em;
  }

  .help-article {
    grid-template-areas: "article" "sidebar";
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
@media (min-width: 701px) {
  .help-search {
    padding: 1.5em 2.5em 2em;
  }

  .help-content {
    padding: 2em;
  }

  .help-menu.help-menu--category {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
  }
  .help-menu.help-menu--category .item a .icon {
    font-size: 4em;
  }
  .help-menu.help-menu--category .item a h2 {
    margin-bottom: 0.25rem;
    font-size: 1.2em;
  }
  .help-menu.help-menu--most-viewed {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 0.5em;
  }
  .help-menu.help-menu--most-viewed .item a {
    padding: 0.75em 0.5em;
    border-radius: 0.5em;
    box-shadow: var(--box-shadow);
    transition: background-color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .help-menu.help-menu--most-viewed .item a:hover {
    background-color: #ed135a;
    box-shadow: var(--box-shadow-hover);
    color: #ffffff;
  }

  .help-article-content .accordion-container {
    min-width: 26em;
  }
  .help-article-content .comparing-chart--ip-address .comparing-chart-table {
    table-layout: fixed;
    width: 46em;
  }
  .help-article-content .text-box {
    padding: 1em;
  }
  .help-article-content .text-box.text-box--space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (max-width: 700px) {
  .help-search {
    padding: 0.75em 1em 1.5em;
    max-width: calc(100% - 2em);
  }
  .help-search .flex p {
    font-size: 0.9em;
  }

  .help-content {
    padding-top: 2em;
    padding-bottom: 1em;
  }
}
@media (max-width: 700px) and (min-width: 521px) {
  .help-content {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}
@media (max-width: 700px) and (max-width: 520px) {
  .help-content {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0;
  }
  .help-content.help-article {
    padding-left: 0;
    padding-right: 0;
  }
  .help-content .help-article-content,
.help-content .help-article-related {
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media (max-width: 700px) {
  .help-menu.help-menu--category {
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
  }
  .help-menu.help-menu--category .item a .icon {
    font-size: 3em;
  }
  .help-menu.help-menu--category .item a h2 {
    font-size: 1em;
  }
  .help-menu.help-menu--most-viewed {
    grid-template-columns: 1fr;
  }
  .help-menu.help-menu--most-viewed .item a {
    padding: 0.75em 0.5em;
    border-bottom: 1px solid #dad9d7;
    transition: color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .help-menu.help-menu--most-viewed .item a:hover {
    color: #ed135a;
  }
  .help-menu.help-menu--most-viewed .item:last-child a {
    border: 0;
  }
  .help-menu.help-menu--related {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .help-article-content .comparing-chart--ip-address .comparing-chart-table {
    width: 36em;
  }
  .help-article-content .text-box {
    padding: 1em 0.75em;
  }
}
@media (min-width: 521px) {
  [class^=page-help-] .header .content h1 {
    font-size: 2em;
  }

  .help-article-content .hide-on-large-devices {
    display: none;
  }
}
@media (max-width: 520px) {
  [class^=page-help-] .header .content {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  [class^=page-help-] .header .content h1 {
    font-size: 1.6em;
  }

  .help-article-content .hide-on-small-devices {
    display: none;
  }
  .help-article-content .paragraph h1 + h2,
.help-article-content .paragraph h2 + h3,
.help-article-content .paragraph h3 + h4,
.help-article-content .paragraph h4 + h5,
.help-article-content .paragraph h5 + h6 {
    padding-top: 0.5rem;
  }
  .help-article-content .comparing-chart-table {
    font-size: 0.9em;
  }

  .help-article-related {
    padding: 1.5em 0 1em;
    width: 100%;
    background-color: #f7f7f7;
    border-top: 1px solid #dad9d7;
  }
}
.heading--faq {
  max-width: 14.7727272727em;
  margin: auto;
}

.faq-nowrap {
  white-space: nowrap;
}

.faq-question-list {
  font-size: 1.125em;
}

.faq-answer {
  margin-bottom: 2em;
  padding: 0.75em 1em;
  border-radius: 0.3125em;
  background-color: white;
}
.faq-answer p:last-child {
  padding-bottom: 0;
}
.faq-answer table:last-child {
  margin-bottom: 0;
}

.page-faq-payment-how-to .content .icon {
  width: 6.25em;
  height: 6.25em;
}

.faq-cards-grid {
  flex-flow: row wrap;
  gap: 1em;
}
.faq-cards-grid .icon {
  width: 6.25em;
  height: 6.25em;
}

[class^=page-contact] .header {
  padding-bottom: 1em;
}
[class^=page-contact] .header p {
  padding-top: 0.5em;
  line-height: 1.4;
  hyphens: manual;
}

.grid--contact {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: #303a4b;
  letter-spacing: var(--letter-spacing-big);
}
.grid--contact .grid-item {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
}
.grid--contact .grid-item:hover, .grid--contact .grid-item.active {
  color: #ed135a;
}
.grid--contact .grid-item:hover .icon, .grid--contact .grid-item.active .icon {
  fill: #ed135a;
}
.grid--contact .grid-item p {
  transition: color 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.grid--contact .grid-item .icon {
  transition: fill 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.grid--contact .nowrap {
  white-space: nowrap;
}
.grid--contact .text p:first-of-type {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-size: 0.9em;
}
.grid--contact p, .grid--contact a {
  display: block;
  line-height: 1.4;
}
.grid--contact p {
  margin: 0;
  padding-bottom: 0;
  font-size: 0.8em;
}
.grid--contact p strong {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.grid--contact a {
  border: none;
}
.grid--contact a, .grid--contact a * {
  transition: none;
}
.grid--contact .phone-number span:not(:last-child) {
  margin-right: 0.125em;
}
.grid--contact .icon {
  display: block;
  width: 3em;
  height: 3em;
  color: transparent;
  fill: #303a4b;
}

.contact-form {
  position: relative;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  text-align: center;
  max-width: 42em;
  margin: 0 auto;
}
.contact-form .contact-form__address p {
  padding-bottom: 0;
}
.contact-form input[type=submit] {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0.8rem 1.6rem;
  text-align: center;
  font-size: 1.6em;
  line-height: 0.8;
  color: #ffffff;
  cursor: pointer;
  appearance: none;
  background-color: #4b848e;
}
.contact-form input[type=submit]:hover {
  background-color: #42747d;
}

.contact-form__grid {
  display: grid;
  max-width: 100%;
  gap: 0.5em;
}
.contact-form__grid.contact-form__grid-2 {
  grid-template-columns: 1fr 1fr;
}
.contact-form__grid.contact-form__grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.contact-form__categories {
  text-align: left;
}

.contact-form__address {
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.2s opacity cubic-bezier(0.455, 0.03, 0.515, 0.955), 0.2s max-height cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.contact-form__address--visible {
  max-height: 100em;
  margin: 0;
  padding: 0;
  opacity: 1;
}

.contact-form__address p {
  padding-bottom: 0;
}

.contact-form__grid-address {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  margin-bottom: 1em;
  padding: 0.5em;
  border-radius: 0.75em;
  background-color: #eeeeee;
}

.contact-form__grid-address-item {
  display: block;
  margin: 0;
}

.contact-menu {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 1em auto;
}
.contact-menu div {
  text-align: left;
}
.contact-menu h2.heading--montserrat {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  hyphens: none;
}
.contact-menu ul {
  margin-top: 0.5em;
}
.contact-menu ul li {
  line-height: 1.4;
}
.contact-menu ul li:last-child {
  padding-bottom: 0;
}
.contact-menu .order-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.75em;
  border: none;
  background-color: #4b848e;
  box-shadow: var(--box-shadow);
  line-height: 1.4;
  text-align: center;
  color: #ffffff;
}
.contact-menu .order-link:hover {
  background-color: #42747d;
  box-shadow: var(--box-shadow-hover);
}
.contact-menu .order-link .text {
  font-size: 1.1em;
}
@media (min-width: 1001px) {
  .contact-menu div {
    padding: 0 2em;
    border-right: 1px solid #dad9d7;
  }
  .contact-menu div:first-child {
    padding-left: 0;
  }
  .contact-menu div:last-child {
    padding-right: 0;
    border-right: 0;
  }
  .contact-menu h2 {
    font-size: 1.2em;
    text-align: left;
  }
  .contact-menu hr {
    display: none;
  }
  .contact-menu .order-link {
    padding: 1em;
    max-width: 14em;
    border-radius: 1em;
  }
}
@media (max-width: 1000px) {
  .contact-menu {
    max-width: 26em;
    width: 100%;
    flex-direction: column;
    gap: 1.5em;
  }
  .contact-menu h2 br {
    display: none;
  }
  .contact-menu ul li {
    padding-bottom: 0.25em;
  }
  .contact-menu a {
    display: block;
    width: 100%;
    padding: 0.4em 0.6em;
    background-color: #eeeeee;
    border-radius: 0.5em;
    border: none;
  }
  .contact-menu hr {
    max-width: none;
    width: 100%;
    border: 0;
    margin: 0;
  }
  .contact-menu .order-link {
    padding: 0.75em 0.5em;
    width: 100%;
    border-radius: 0.5em;
  }
}

.contact-options-container {
  padding-bottom: 1em;
}
.contact-options-container .contact-options-button {
  font: inherit;
  color: inherit;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  display: block;
  margin: 0 auto 1em;
  padding: 0.5em 1.5em;
  width: fit-content;
  border-radius: 0.5em;
  cursor: pointer;
  background-color: #ffffff;
  border: none;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.contact-options-container .contact-options-button:hover {
  box-shadow: var(--box-shadow-hover);
}
.contact-options-container .contact-options-button.active, .contact-options-container .contact-options-button.active:hover {
  background-color: #ed135a;
  color: #ffffff;
}
.contact-options-container .contact-options {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.contact-options-container .contact-options.visible {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  max-height: 100em;
}

.contact-content {
  margin: 0 auto 2em;
  max-width: 30em;
}
.contact-content p:first-child, .contact-content .button:first-child {
  margin-top: 0;
}
.contact-content p {
  margin-top: 1em;
  padding-bottom: 0;
}
.contact-content ul li {
  position: relative;
  padding-left: 1em;
  padding-bottom: 0;
}
.contact-content ul li::before {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  content: "•";
  color: #ed135a;
  position: absolute;
  left: 0;
}
.contact-content .button {
  display: block;
  margin-top: 0.5em;
}
.contact-content .button:last-child {
  margin-bottom: 0;
}
.contact-content .address {
  font-weight: 450;
  font-variation-settings: "wght" 450;
  display: block;
  width: fit-content;
  padding: 0.2em 0.6em;
  border-radius: 0.5em;
  background-color: #eeeeee;
}
.contact-content.contact-order p, .contact-content.contact-order .call-button {
  margin-top: 0.7em;
}
.contact-content.contact-order p:first-child, .contact-content.contact-order .call-button:first-child {
  margin-top: 0;
}

.contact-sub {
  margin-bottom: 1em;
}
.contact-sub > p {
  line-height: 1.4;
}
@media (min-width: 701px) {
  .contact-sub.phone .grid-item--phone {
    display: block;
  }
  .contact-sub.chat .grid-item--chat {
    display: block;
  }
  .contact-sub.form .grid-item--form {
    display: block;
  }
}
@media (max-width: 700px) {
  .contact-sub.phone .grid-item--phone {
    display: flex;
  }
  .contact-sub.chat .grid-item--chat {
    display: flex;
  }
  .contact-sub.form .grid-item--form {
    display: flex;
  }
}
.contact-sub.contact-order {
  padding: 0 0.5em;
  margin-bottom: 2em;
}
.contact-sub.contact-order p {
  padding: 0;
  text-align: center;
}

.contact .closed {
  text-align: center;
}

.page-contact-contact-form .content {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.page-contact-contact-form .blurb p {
  margin: 0 auto;
  max-width: 30em;
  font-size: 0.9em;
}
.page-contact-contact-form .contact-form {
  padding: 0;
}

@media (min-width: 701px) {
  .grid--contact {
    flex-flow: row wrap;
    align-items: flex-start;
    gap: 2em;
    margin-top: 2em;
    margin-bottom: 1em;
    padding: 0 1em;
  }
  .grid--contact .grid-item {
    text-align: center;
    transition: box-shadow 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  .grid--contact .grid-item.hide-on-large-devices {
    display: none;
  }
  .grid--contact .icon {
    margin: 0 auto;
  }

  .contact-form {
    padding: 0 1em;
  }
  .contact-form.visible {
    padding: 0 1em;
  }
  .contact-form p,
.contact-form .contact-form__grid {
    padding-bottom: 1em;
  }
  .contact-form .contact-form--address-container p {
    padding-bottom: 0.25em;
  }

  .contact-content {
    padding: 1em;
    border-radius: 1em;
    box-shadow: var(--box-shadow);
  }

  .contact-sub .hide-on-large-devices {
    display: none;
  }
  .contact-sub > p {
    max-width: 30em;
    text-align: center;
  }
  .contact-sub .grid--contact {
    margin-top: 0;
  }

  .contact-search {
    padding: 1.5em 2.5em 2em;
  }
}
@media (max-width: 700px) {
  .grid--contact {
    flex-flow: column nowrap;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 1em;
    padding: 0 1em;
    width: fit-content;
  }
  .grid--contact .grid-item, .grid--contact a {
    width: 100%;
  }
  .grid--contact .grid-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25em;
    text-align: left;
  }
  .grid--contact .grid-item.hide-on-small-devices {
    display: none;
  }
  .grid--contact .text {
    font-size: 1.1em;
  }

  .contact-form {
    padding: 0 0.5em;
  }
  .contact-form.visible {
    padding: 0 0.5em;
  }
  .contact-form p,
.contact-form .contact-form__grid {
    padding-bottom: 0.5em;
  }
  .contact-form .contact-form--address-container p {
    padding-bottom: 0;
  }

  .contact-content {
    padding: 0 0.5em;
  }
  .contact-content.contact-order {
    padding: 1em 0.5em;
    max-width: calc(100% - 1em);
    border-radius: 1em;
    box-shadow: var(--box-shadow);
  }
  .contact-content .rto p {
    font-size: 1em;
  }

  .contact-content + hr {
    margin: 2em auto;
  }

  .contact-sub hr {
    margin: 0 auto 2em;
  }
  .contact-sub > p {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .contact-search {
    padding: 0.75em 1em 1.5em;
    max-width: calc(100% - 1em);
  }
  .contact-search p {
    font-size: 0.9em;
  }
}
@media (min-width: 601px) {
  .contact-form__grid-address-item:nth-child(1) {
    width: calc(calc(100% / 3 * 2) - .25em);
  }
  .contact-form__grid-address-item:nth-child(n+2) {
    width: calc(calc(100% - 1em) / 3);
  }
}
@media (max-width: 600px) {
  .contact-form__grid.contact-form__grid-3 {
    grid-template-columns: 1fr 1fr;
  }

  .contact-form__grid-address {
    gap: 0.5em;
  }

  .contact-form__grid-address-item:nth-child(1) {
    width: 100%;
  }
  .contact-form__grid-address-item:nth-child(n+2) {
    width: calc(calc(100% - .5em) / 2);
  }
}
@media (max-width: 400px) {
  .contact-form__categories .contact-form__grid-item .checkbox-label:not(.checkbox-label--montserrat) {
    font-size: 1em;
    padding-top: 0.65rem;
  }
  .contact-form__categories .contact-form__grid-item .checkbox-label:not(.checkbox-label--montserrat)::before {
    margin-top: 0;
  }
  .contact-form__categories .contact-form__grid-item .checkbox-label.checkbox-label--montserrat {
    font-size: 0.8em;
  }
}
.contact--simple > p {
  max-width: 40em;
  text-align: center;
}

.contact--simple,
.contact--order {
  padding: 1em 1em 2em;
}
.contact--simple .grid--contact,
.contact--order .grid--contact {
  width: fit-content;
}

.contact-search {
  margin: 0 auto 2em;
}

@media (max-width: 520px) {
  .phone-graph-desk {
    display: none;
  }
}

.phone-graph-mob {
  display: block;
  margin: 0 auto;
  max-width: 14em;
}
@media (min-width: 521px) {
  .phone-graph-mob {
    display: none;
  }
}

.page-products .products-hero {
  padding: 2em 0 1em;
}
.page-products .products-hero h1 {
  font-weight: 300;
  hyphens: manual;
}
.page-products .products-hero p {
  text-align: center;
}
.page-products .subheader p {
  text-align: center;
}
.page-products .faq .accordion-container + .blurb .address-button {
  box-shadow: var(--box-shadow);
}
.page-products .faq .accordion-container + .blurb .address-button:hover {
  box-shadow: var(--box-shadow-hover);
}

.products-head {
  padding: 2em 1em;
  background-color: #f7f7f7;
}
.products-head h2, .products-head p {
  color: #303a4b;
  text-align: center;
}

.page-home .products-wrapper {
  background-color: #f7f7f7;
}
.products-wrapper .products {
  --padding: 1rem;
  --border-radius: 1rem;
  --font-size-price: 5em;
  padding: 0 1em;
  display: flex;
  flex-direction: row;
}
@media (min-width: 1293px) {
  .products-wrapper .products {
    justify-content: center;
  }
}
@media (max-width: 1292px) {
  .products-wrapper .products {
    justify-content: flex-start;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-bottom: 0.5em;
  }
  .products-wrapper .products .product-list-item {
    scroll-snap-align: center;
    scroll-snap-stop: normal;
  }
}
@media (min-width: 521px) {
  .products-wrapper .products {
    gap: 2em;
  }
}
@media (max-width: 520px) {
  .products-wrapper .products {
    gap: 1em;
  }
}
.products-wrapper .products .discount {
  color: #40b577;
}
.products-wrapper .products .product-list-item {
  width: 20em;
}
.products-wrapper .products .product-list-item.product-list-item--discount .product-list-link {
  box-shadow: 0 0 0 4px #40b577, var(--box-shadow);
}
.products-wrapper .products .product-list-item.product-list-item--discount .product-list-link:hover {
  box-shadow: 0 0 0 4px #40b577, var(--box-shadow-hover-inverse);
}
.products-wrapper .products .product-list-item.product-list-item--discount .product-list-pre-link-wrap .pre-product {
  background-color: #40b577;
}
.products-wrapper .products .product-list-item .product-list-pre-link-wrap {
  line-height: 1.2;
  min-height: 1.6em;
}
@media (min-width: 751px) {
  .products-wrapper .products .product-list-item .product-list-pre-link-wrap {
    bottom: -1px;
  }
}
.products-wrapper .products .product-list-item .product-list-pre-link-wrap .pre-product {
  display: block;
  margin: 0 auto;
  padding: 0.25em 0.8em;
  width: fit-content;
  background-color: #4b848e;
  font-size: 0.9em;
}
.products-wrapper .products .product-list-item .product-list-card {
  overflow: hidden;
  width: 100%;
}
.products-wrapper .products .product-list-item .product-list-heading {
  padding: 1rem 0 0;
  font-size: 1.6em;
}
.products-wrapper .products .product-list-item .product-list-middle {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: var(--padding);
  min-height: 8em;
  overflow: hidden;
}
.products-wrapper .products .product-list-item .speed,
.products-wrapper .products .product-list-item .price {
  font-weight: 300;
}
.products-wrapper .products .product-list-item .speed.discount,
.products-wrapper .products .product-list-item .price.discount {
  font-weight: 400;
}
.products-wrapper .products .product-list-item .product-list-price-container {
  z-index: 1;
}
.products-wrapper .products .product-list-item .product-list-price-container .pre-price,
.products-wrapper .products .product-list-item .product-list-price-container .post-price,
.products-wrapper .products .product-list-item .product-list-price-container .min-price {
  line-height: 1.25;
}
.products-wrapper .products .product-list-item .product-list-price-container .pre-price,
.products-wrapper .products .product-list-item .product-list-price-container .post-price {
  font-size: 0.9em;
  padding: 0;
}
.products-wrapper .products .product-list-item .product-list-price-container .post-price {
  padding-top: 0.5em;
}
.products-wrapper .products .product-list-item .product-list-price-container .min-price {
  font-size: 0.75em;
}
.products-wrapper .products .product-list-item .product-list-price {
  justify-content: flex-start;
  padding: 0;
  height: calc(calc(var(--font-size-price) / 7) * 6);
  text-align: left;
}
.products-wrapper .products .product-list-item .product-list-image {
  position: relative;
  flex-grow: 1;
  height: 8em;
}
.products-wrapper .products .product-list-item .product-list-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: unset;
  height: 100%;
}
.products-wrapper .products .product-list-item .product-list-image .mbb-stamp {
  position: absolute;
  top: 50%;
  right: 25%;
  transform: translate(50%, -50%) rotate(7.5deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  font-size: 1.6em;
  background-color: #303a4b;
  box-shadow: var(--box-shadow);
}
.products-wrapper .products .product-list-item .product-list-image .mbb-stamp span {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  color: #ffffff;
}
.products-wrapper .products .product-list-item.fiber .product-list-image img {
  left: 60%;
  height: 8em;
}
.products-wrapper .products .product-list-item.mbb .product-list-image img, .products-wrapper .products .product-list-item.coax .product-list-image img {
  height: 10em;
}
.products-wrapper .products .product-list-item .product-list-tech {
  justify-content: center;
}
.products-wrapper .products .product-list-item.mbb .product-list-tech {
  justify-content: space-between;
}
.products-wrapper .products .product-list-item .product-list-button {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  padding: 0.6em 1em;
  background-color: #4b848e;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  text-transform: none;
  letter-spacing: normal;
}
.products-wrapper .products .product-list-item .product-list-link:hover .product-list-button {
  background-color: #42747d;
}
@media (max-width: 750px) {
  .products-wrapper .products .product-list-item {
    width: 100%;
    max-width: 20em;
  }
}
@media (max-width: 750px) and (max-width: calc(16px * 23)) {
  .products-wrapper .products .product-list-item {
    width: calc(100vw - 3em);
    max-width: none;
  }
}
@media (max-width: 750px) {
  .products-wrapper .products .product-list-item .product-list-card {
    width: 100%;
  }
}
@media (max-width: 750px) {
  .products-wrapper .products .product-list-item .product-list-middle {
    min-height: 12em;
  }
}
@media (max-width: 750px) {
  .products-wrapper .products .product-list-item .product-list-image .mbb-stamp {
    right: -0.5rem;
    transform: translateY(-50%) rotate(7.5deg);
    font-size: 2em;
  }
}
@media (max-width: 750px) {
  .products-wrapper .products .product-list-item.mbb .product-list-image, .products-wrapper .products .product-list-item.coax .product-list-image {
    height: 10em;
  }
  .products-wrapper .products .product-list-item.mbb .product-list-image img, .products-wrapper .products .product-list-item.coax .product-list-image img {
    height: 11em;
  }
}
.products-wrapper .products-showcase {
  --price-size: 6;
  max-width: 70em;
  margin: 0 auto;
}
@media (min-width: 1401px) {
  .products-wrapper .products-showcase {
    --space: 8em;
  }
}
@media (max-width: 1400px) {
  .products-wrapper .products-showcase {
    --space: 4em;
  }
}
@media (min-width: 961px) {
  .products-wrapper .products-showcase {
    --padding: 2em;
  }
}
@media (min-width: 961px) and (min-width: 1601px) {
  .products-wrapper .products-showcase {
    --padding: 3em;
  }
}
@media (max-width: 960px) {
  .products-wrapper .products-showcase {
    --padding: 1em;
  }
}
@media (max-width: 520px) {
  .products-wrapper .products-showcase {
    --price-size: 8;
  }
}
.products-wrapper .products-showcase .product {
  padding-bottom: 0;
}
.products-wrapper .products-showcase .product-box,
.products-wrapper .products-showcase .product-details {
  display: flex;
  align-items: center;
}
.products-wrapper .products-showcase .product-box,
.products-wrapper .products-showcase .image {
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
.products-wrapper .products-showcase .product-box {
  padding: var(--padding);
  background-color: #ffffff;
}
.products-wrapper .products-showcase .product-text .post-heading,
.products-wrapper .products-showcase .product-text .pre-products-details {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
.products-wrapper .products-showcase .product-text .post-heading {
  font-size: 1.2em;
}
.products-wrapper .products-showcase .product-text .pre-products-details {
  padding-bottom: 0;
  font-size: 1.4em;
}
.products-wrapper .products-showcase .product-details {
  gap: 2em;
  padding-bottom: 1em;
}
.products-wrapper .products-showcase .price-details p {
  padding-bottom: 0;
  line-height: 1.4;
}
.products-wrapper .products-showcase .price-details .price-container {
  font-size: calc(1em * var(--price-size));
  line-height: 0.8;
  white-space: nowrap;
}
.products-wrapper .products-showcase .price-details .price {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: var(--letter-spacing-big);
  color: #40b577;
}
.products-wrapper .products-showcase .price-details .comma-dash {
  margin-right: -0.2em;
  letter-spacing: -0.125em;
}
.products-wrapper .products-showcase .price-details .md {
  font-size: calc(1em / var(--price-size));
}
.products-wrapper .products-showcase .product-list-usp {
  margin: 0;
  padding: 0;
}
.products-wrapper .products-showcase .product-list-usp-item {
  justify-content: flex-start;
}
.products-wrapper .products-showcase .button {
  display: block;
  max-width: 100%;
  width: 10em;
}
.products-wrapper .products-showcase .image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.products-wrapper .products-showcase .mbb .image {
  background-image: url("../images/pages/home/product-showcase-5g.jpg");
}
.products-wrapper .products-showcase .fiber .image {
  background-image: url("../images/pages/home/product-showcase-fiber.jpg");
}
@media (min-width: 961px) {
  .products-wrapper .products-showcase {
    padding: 0 2em;
  }
  .products-wrapper .products-showcase .product {
    position: relative;
    margin-bottom: var(--space);
  }
  .products-wrapper .products-showcase .product:last-child {
    margin-bottom: 0;
  }
  .products-wrapper .products-showcase .product .image {
    position: absolute;
    right: 0;
    aspect-ratio: 4/3;
  }
  .products-wrapper .products-showcase .product.alt .product-box {
    justify-content: flex-end;
    margin-left: auto;
  }
  .products-wrapper .products-showcase .product.alt .image {
    right: unset;
    left: 0;
  }
  .products-wrapper .products-showcase .product-box,
.products-wrapper .products-showcase .product-details {
    justify-content: flex-start;
  }
  .products-wrapper .products-showcase .product-box {
    width: 83.25%;
  }
  .products-wrapper .products-showcase .product-text {
    width: calc(75% * .9);
  }
  .products-wrapper .products-showcase .price-details .time {
    font-size: 0.9em;
  }
  .products-wrapper .products-showcase .price-details .min-price {
    font-size: 0.8em;
  }
}
@media (min-width: 961px) and (min-width: 1401px) {
  .products-wrapper .products-showcase .product .image {
    bottom: calc(var(--padding) * -1);
    width: calc(55% - var(--padding));
  }
  .products-wrapper .products-showcase .product.alt .image {
    top: calc(var(--padding) * -1);
    bottom: unset;
  }
  .products-wrapper .products-showcase .product-text {
    width: 50%;
  }
}
@media (min-width: 961px) and (max-width: 1400px) {
  .products-wrapper .products-showcase .product .image {
    top: 50%;
    transform: translateY(-50%);
    width: calc(45% - var(--padding));
  }
}
@media (max-width: 960px) {
  .products-wrapper .products-showcase {
    --image-offset: 5em;
    --padding-ver: var(--padding);
    --padding-hor: var(--padding);
    padding: 0 1em;
  }
}
@media (max-width: 960px) and (min-width: 521px) {
  .products-wrapper .products-showcase {
    --padding-hor: calc(var(--padding) * 2);
  }
}
@media (max-width: 960px) {
  .products-wrapper .products-showcase .product {
    margin-top: calc(var(--image-offset) * -1);
    margin-bottom: 2em;
    width: 100%;
    max-width: 40em;
  }
  .products-wrapper .products-showcase .product:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 960px) {
  .products-wrapper .products-showcase .product-box {
    justify-content: center;
    padding: calc(calc(var(--padding-ver) * 1.5) + var(--image-offset)) var(--padding-hor) var(--padding-ver);
  }
}
@media (max-width: 960px) {
  .products-wrapper .products-showcase .price-details .min-price {
    font-size: 0.8em;
  }
}
@media (max-width: 960px) {
  .products-wrapper .products-showcase .button {
    margin: 0 auto;
  }
}
@media (max-width: 960px) {
  .products-wrapper .products-showcase .image {
    position: relative;
    bottom: calc(var(--image-offset) * -1);
    margin: 0 auto;
    max-width: 36em;
    width: calc(100% - calc(var(--padding-hor) * 2));
    aspect-ratio: 16/9;
  }
}
@media (max-width: 960px) and (max-width: 520px) {
  .products-wrapper .products-showcase .product-box,
.products-wrapper .products-showcase .product-details {
    justify-content: center;
  }
  .products-wrapper .products-showcase h3, .products-wrapper .products-showcase p {
    text-align: center;
    padding-bottom: 0;
  }
  .products-wrapper .products-showcase .product-details {
    gap: 1em;
  }
  .products-wrapper .products-showcase .product-list-usp {
    display: none;
  }
}
.products-wrapper .conditions {
  font-size: 0.7em;
  text-align: center;
  color: rgba(0, 0, 0, 0.75);
  max-width: 80em;
}

/* .js example
const elem = document.getElementById('ab-test-container');
elem.classList.remove("ab-test-container-a");
elem.classList.add("ab-test-container-b");
*/
.ab-test-container.ab-test-container-a .ab-test-object-b {
  display: none;
}
.ab-test-container.ab-test-container-b .ab-test-object-a {
  display: none;
}
.ab-test-container.ab-test-container-c .ab-test-object-d {
  display: none;
}
.ab-test-container.ab-test-container-d .ab-test-object-c {
  display: none;
}

.hero-container.hero-container--products {
  gap: 0;
  background-color: #f7f7f7;
}
.hero-container.hero-container--products .conditions {
  font-size: 0.7em;
  color: rgba(0, 0, 0, 0.75);
  max-width: 80em;
  margin-top: 1em;
}
@media (max-width: 920px) {
  .hero-container.hero-container--products .hero-background .stamp {
    display: none;
  }
}
@media (max-width: 520px) and (min-width: 351px) {
  .hero-container.hero-container--products .hero-background {
    right: 1em;
  }
  .hero-container.hero-container--products .hero-background img {
    max-height: 100%;
  }
}
.hero-container.hero-container--products .hero-content h1, .hero-container.hero-container--products .hero-content h2, .hero-container.hero-container--products .hero-content p {
  color: #303a4b;
}
.hero-container.hero-container--products .hero-content h1 .header-tech {
  font-size: 5em;
  font-weight: 300;
  margin-left: -0.05em;
  padding-bottom: 0.1em;
}
.hero-container.hero-container--products .hero-content h1 .header-sub {
  font-size: 1.5em;
  margin-left: -0.1em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
  padding-bottom: 1em;
  line-height: 1.2;
  font-weight: 400;
}
.hero-container.hero-container--products .hero-content h1 .no-wrap {
  white-space: nowrap;
}
@media (max-width: 400px) {
  .hero-container.hero-container--products .hero-content ul {
    font-size: 0.9em;
  }
}
.hero-container.hero-container--products .hero-content ul li {
  position: relative;
  padding: 0 0 0.5em 1.5em;
}
.hero-container.hero-container--products .hero-content ul li:last-child {
  padding-bottom: 0;
}
.hero-container.hero-container--products .hero-content ul li .icon {
  position: absolute;
  left: -0.25em;
  color: transparent;
  fill: #303a4b;
}
.hero-container.hero-container--products .hero-content ul li .icon, .hero-container.hero-container--products .hero-content ul li .icon svg {
  width: 1.5em;
  height: 1.5em;
}
.hero-container.hero-container--products .hero-content h2 {
  text-align: left;
  text-transform: none;
  padding-top: 0.8rem;
}
.hero-container.hero-container--products .hero-content h2 .before-after {
  font-size: 0.45em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.hero-container.hero-container--products.hero-container--products-coax .hero-content h1 .header-tech {
  font-size: 4em;
}
@media (max-width: 520px) {
  .hero-container.hero-container--products.hero-container--products-coax .hero-content h1 .header-tech {
    font-size: 14.5vw;
  }
  .hero-container.hero-container--products.hero-container--products-dsl .hero-content h1 .header-tech {
    font-size: 20vw;
  }
}
.hero-container.hero-container--products .address-wrap h2 {
  color: #303a4b;
}
.hero-container.hero-container--products .address-wrap .scroll-down-arrow::before,
.hero-container.hero-container--products .address-wrap .scroll-down-arrow::after {
  background-color: #303a4b;
}
@media (min-width: 521px) {
  .hero-container.hero-container--products .address-wrap .scroll-down-arrow::before,
.hero-container.hero-container--products .address-wrap .scroll-down-arrow::after {
    height: 1px;
  }
  .hero-container.hero-container--products .address-wrap .scroll-down-arrow::after {
    transform: translate(11px, 18px) rotate(-45deg);
  }
}
.hero-container.hero-container--products.hero-container--products-fiber .hero-background {
  max-width: 496px;
}
@media (min-width: 1201px) {
  .hero-container.hero-container--products.hero-container--products-fiber .hero-background {
    width: 24em;
    left: 50%;
    bottom: 6.8em;
    transform: translateX(3em);
  }
  .hero-container.hero-container--products.hero-container--products-fiber .hero-background .stamp {
    top: 20%;
    left: 0;
    transform: translateY(-50%);
  }
}
@media (max-width: 1200px) and (min-width: 921px) {
  .hero-container.hero-container--products.hero-container--products-fiber .hero-background {
    width: 24em;
    left: 50%;
    bottom: 6.8em;
  }
  .hero-container.hero-container--products.hero-container--products-fiber .hero-background .stamp {
    top: 0;
    right: 0;
  }
}
@media (max-width: 920px) and (min-width: 521px) {
  .hero-container.hero-container--products.hero-container--products-fiber .hero-background {
    width: 500px;
    left: 50%;
    bottom: 6.6em;
    transform: translateX(-10%);
  }
}
@media (max-width: 520px) and (min-width: 351px) {
  .hero-container.hero-container--products.hero-container--products-fiber .hero-background {
    display: none;
  }
}
@media (max-width: 350px) {
  .hero-container.hero-container--products.hero-container--products-fiber .hero-background {
    display: none;
  }
}
.hero-container.hero-container--products.hero-container--products-coax .hero-background {
  max-width: 422px;
}
@media (min-width: 921px) {
  .hero-container.hero-container--products.hero-container--products-coax .hero-background {
    width: 18em;
    left: 75%;
    bottom: 5em;
    transform: translateX(-50%);
  }
  .hero-container.hero-container--products.hero-container--products-coax .hero-background .stamp {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
@media (max-width: 920px) and (min-width: 521px) {
  .hero-container.hero-container--products.hero-container--products-coax .hero-background {
    width: 20em;
    left: 75%;
    bottom: 2em;
    transform: translateX(calc(-50% + 2em));
  }
}
@media (max-width: 760px) and (min-width: 521px) {
  .hero-container.hero-container--products.hero-container--products-coax .hero-background {
    width: 16em;
    bottom: 6em;
  }
}
@media (max-width: 520px) and (min-width: 351px) {
  .hero-container.hero-container--products.hero-container--products-coax .hero-background {
    display: none;
  }
}
@media (max-width: 350px) {
  .hero-container.hero-container--products.hero-container--products-coax .hero-background {
    display: none;
  }
}
.hero-container.hero-container--products.hero-container--products-dsl .hero-background {
  max-width: 496px;
}
@media (min-width: 1201px) {
  .hero-container.hero-container--products.hero-container--products-dsl .hero-background {
    width: 18em;
    left: 50%;
    bottom: 5em;
    transform: translateX(9em);
  }
  .hero-container.hero-container--products.hero-container--products-dsl .hero-background .stamp {
    top: 40%;
    right: -2em;
    transform: translateY(-50%);
  }
}
@media (max-width: 1200px) and (min-width: 921px) {
  .hero-container.hero-container--products.hero-container--products-dsl .hero-background {
    width: 16em;
    left: 50%;
    bottom: 5.5em;
    transform: translateX(8em);
  }
  .hero-container.hero-container--products.hero-container--products-dsl .hero-background .stamp {
    top: 45%;
    right: -2em;
    transform: translateY(-50%);
  }
}
@media (max-width: 920px) and (min-width: 521px) {
  .hero-container.hero-container--products.hero-container--products-dsl .hero-background {
    width: 19em;
    left: 50%;
    bottom: 4.5em;
  }
}
@media (max-width: 520px) and (min-width: 351px) {
  .hero-container.hero-container--products.hero-container--products-dsl .hero-background {
    height: 8em;
    bottom: 7em;
  }
}
@media (max-width: 350px) {
  .hero-container.hero-container--products.hero-container--products-dsl .hero-background {
    display: none;
  }
}

.page-products-fiber .blurb,
.page-products-coax .blurb,
.page-products-dsl .blurb {
  max-width: none;
  margin: 0;
}
.page-products-fiber .heading,
.page-products-coax .heading,
.page-products-dsl .heading {
  hyphens: manual;
}
.page-products-fiber .grid,
.page-products-coax .grid,
.page-products-dsl .grid {
  gap: 1em 2em;
}
.page-products-fiber .grid li,
.page-products-coax .grid li,
.page-products-dsl .grid li {
  margin: 0;
}
@media (min-width: 661px) {
  .page-products-fiber .grid:not(.grid-triple-with-icons) li,
.page-products-coax .grid:not(.grid-triple-with-icons) li,
.page-products-dsl .grid:not(.grid-triple-with-icons) li {
    width: 50%;
  }
}
@media (max-width: 660px) {
  .page-products-fiber .grid,
.page-products-coax .grid,
.page-products-dsl .grid {
    align-items: center;
  }
  .page-products-fiber .grid li,
.page-products-coax .grid li,
.page-products-dsl .grid li {
    width: 100%;
  }
}
.page-products-fiber .hero-container + .section-white .content,
.page-products-fiber .section-reasons .content,
.page-products-coax .hero-container + .section-white .content,
.page-products-coax .section-reasons .content,
.page-products-dsl .hero-container + .section-white .content,
.page-products-dsl .section-reasons .content {
  padding-bottom: 2em;
}
.page-products-fiber .hero-container + .section-white .grid li,
.page-products-fiber .section-includes .grid li,
.page-products-fiber .section-coverage .grid li,
.page-products-coax .hero-container + .section-white .grid li,
.page-products-coax .section-includes .grid li,
.page-products-coax .section-coverage .grid li,
.page-products-dsl .hero-container + .section-white .grid li,
.page-products-dsl .section-includes .grid li,
.page-products-dsl .section-coverage .grid li {
  padding: 0;
}
.page-products-fiber .section-reasons .grid li,
.page-products-coax .section-reasons .grid li,
.page-products-dsl .section-reasons .grid li {
  padding: 1em 0.8em;
  border-radius: 1em;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 6px 18px -4px rgba(0, 0, 0, 0.2), 0px 2px 32px -12px rgba(0, 0, 0, 0.2);
}
.page-products-fiber .section-reasons .grid li::before,
.page-products-coax .section-reasons .grid li::before,
.page-products-dsl .section-reasons .grid li::before {
  background: #303a4b;
  color: #ffffff;
}
.page-products-fiber .section-includes .grid:first-of-type,
.page-products-coax .section-includes .grid:first-of-type,
.page-products-dsl .section-includes .grid:first-of-type {
  margin-bottom: 2em;
}
.page-products-fiber .section-includes .grid li:first-child,
.page-products-coax .section-includes .grid li:first-child,
.page-products-dsl .section-includes .grid li:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  padding: 1em;
  border-radius: 1em;
}
.page-products-fiber .section-includes .grid li:nth-child(2),
.page-products-coax .section-includes .grid li:nth-child(2),
.page-products-dsl .section-includes .grid li:nth-child(2) {
  align-self: center;
}
.page-products-fiber .accordion-container .button,
.page-products-coax .accordion-container .button,
.page-products-dsl .accordion-container .button {
  display: block;
  margin-bottom: 0.7em;
  width: fit-content;
}
.page-products-fiber .accordion-container .button:not(:first-child),
.page-products-coax .accordion-container .button:not(:first-child),
.page-products-dsl .accordion-container .button:not(:first-child) {
  margin-top: -0.35em;
}
.page-products-fiber .accordion-container .button:last-child,
.page-products-coax .accordion-container .button:last-child,
.page-products-dsl .accordion-container .button:last-child {
  margin-bottom: 0;
}

.page-products-fiber .section-includes .grid:first-of-type li:first-child,
.page-products-dsl .section-includes .grid:first-of-type li:first-child {
  padding-top: 0;
  padding-bottom: 0;
}

.page-products-wifi-mesh .hero-container {
  border-radius: 1em;
  box-shadow: inset var(--box-shadow);
  background: radial-gradient(ellipse farthest-corner at center, #6d989f 0%, #4b848e 100%);
}
.page-products-wifi-mesh .hero-container .hero-content {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-areas: "h1 img" "h2 img";
  margin: 0;
  padding: 0;
  align-items: center;
}
.page-products-wifi-mesh .hero-container .hero-content h1, .page-products-wifi-mesh .hero-container .hero-content h2 {
  hyphens: manual;
}
.page-products-wifi-mesh .hero-container .hero-content h1 {
  grid-area: h1;
  line-height: 1;
}
.page-products-wifi-mesh .hero-container .hero-content h2 {
  grid-area: h2;
}
.page-products-wifi-mesh .hero-container .hero-content .image {
  grid-area: img;
}
@media (min-width: 801px) {
  .page-products-wifi-mesh .hero-container {
    margin: 1em auto 0;
    padding: 4em 2em;
    width: calc(100% - 2em);
  }
  .page-products-wifi-mesh .hero-container .hero-content {
    gap: 0 1em;
  }
  .page-products-wifi-mesh .hero-container .hero-content h1 {
    align-self: end;
    font-size: 3em;
  }
  .page-products-wifi-mesh .hero-container .hero-content h1 strong {
    font-size: 1.6em;
  }
  .page-products-wifi-mesh .hero-container .hero-content h2 {
    align-self: start;
  }
  .page-products-wifi-mesh .hero-container .hero-content .image {
    max-width: 18em;
  }
}
@media (max-width: 800px) {
  .page-products-wifi-mesh .hero-container {
    margin: 0.5em auto 0;
    padding: 4em 1em;
    width: calc(100% - 1em);
    overflow: hidden;
  }
  .page-products-wifi-mesh .hero-container .hero-content {
    gap: 0.5em;
  }
  .page-products-wifi-mesh .hero-container .hero-content h1 {
    font-size: 2.4em;
  }
  .page-products-wifi-mesh .hero-container .hero-content h1 strong {
    font-size: 2em;
  }
  .page-products-wifi-mesh .hero-container .hero-content .image {
    max-width: 12em;
    width: 200%;
  }
}
@media (max-width: 520px) {
  .page-products-wifi-mesh .hero-container {
    padding: 2em 0.75em 1em;
  }
  .page-products-wifi-mesh .hero-container .hide-on-small-devices {
    display: none;
  }
  .page-products-wifi-mesh .hero-container .hero-content {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "h1 img" "h2 h2";
  }
  .page-products-wifi-mesh .hero-container .hero-content h1 {
    font-size: 11vw;
  }
  .page-products-wifi-mesh .hero-container .hero-content h1 strong {
    font-size: 1.6em;
  }
  .page-products-wifi-mesh .hero-container .hero-content h2 {
    font-size: 1em;
  }
  .page-products-wifi-mesh .hero-container .hero-content .image {
    justify-self: center;
    max-width: 7em;
  }
}
.page-products-wifi-mesh .section-lightgray {
  background-color: #eeeeee;
}
.page-products-wifi-mesh .section-get,
.page-products-wifi-mesh .section-how,
.page-products-wifi-mesh .section-tips,
.page-products-wifi-mesh .section-faq,
.page-products-wifi-mesh .section-cta {
  padding-bottom: 1em;
}
.page-products-wifi-mesh .section-get .address-widget-wrap {
  margin-bottom: 0.7em;
}
.page-products-wifi-mesh .section-reasons {
  padding-bottom: 1.5em;
}
.page-products-wifi-mesh .section-reasons .content {
  max-width: none;
}
.page-products-wifi-mesh .section-reasons ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: center;
  align-items: stretch;
  gap: 1.5em;
}
.page-products-wifi-mesh .section-reasons ul li {
  flex: unset;
  margin: 0;
  padding: 1em;
  max-width: 20em;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
  background-color: #ffffff;
}
.page-products-wifi-mesh .section-reasons ul li .blurb {
  padding: 0 0 0.5em;
}
.page-products-wifi-mesh .section-reasons h3 {
  padding-bottom: 0;
}
.page-products-wifi-mesh .section-how .button {
  margin: -0.7em 0 1.4em;
  border-radius: 2em;
}
.page-products-wifi-mesh .section-tips {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.page-products-wifi-mesh .section-tips .content {
  padding-bottom: 2em;
  border-radius: 1em;
  background-color: #eeeeee;
}
.page-products-wifi-mesh .section-tips ul {
  margin-top: -0.35em;
  padding-bottom: 0.7em;
}
.page-products-wifi-mesh .section-tips ul li {
  position: relative;
  padding-left: 1.5em;
  padding-bottom: 0.35em;
}
.page-products-wifi-mesh .section-tips ul li::before {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  content: "•";
  position: absolute;
  left: 0;
  color: #ed135a;
}
.page-products-wifi-mesh .section-tips ul li:last-child {
  padding-bottom: 0;
}
.page-products-wifi-mesh .hardware-specifications-table td, .page-products-wifi-mesh .hardware-specifications-table th {
  border: 0;
  background-color: #ffffff;
  line-height: 1.4;
  color: #303a4b;
}
.page-products-wifi-mesh .hardware-specifications-table tr:first-child td, .page-products-wifi-mesh .hardware-specifications-table tr:first-child th, .page-products-wifi-mesh .hardware-specifications-table tr:last-child td, .page-products-wifi-mesh .hardware-specifications-table tr:last-child th {
  border-radius: 0;
}
.page-products-wifi-mesh .hardware-specifications-table tr:nth-child(odd) td, .page-products-wifi-mesh .hardware-specifications-table tr:nth-child(odd) th {
  background-color: #f7f7f7;
}
.page-products-wifi-mesh .hardware-specifications-table tr td, .page-products-wifi-mesh .hardware-specifications-table tr th {
  border-top: 1px solid #dad9d7;
}
.page-products-wifi-mesh .hardware-specifications-table tr:last-child td, .page-products-wifi-mesh .hardware-specifications-table tr:last-child th {
  border-bottom: 1px solid #dad9d7;
}
.page-products-wifi-mesh .hardware-specifications-table th {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  padding: 0.5em 0.25em 0.5em 0.5em;
}
.page-products-wifi-mesh .hardware-specifications-table td {
  padding: 0.5em 0.5em 0.5em 0.25em;
}
.page-products-wifi-mesh .section-cta .button {
  display: block;
  margin: 0 auto;
  padding: 0.5em 1em 0.3em;
  width: fit-content;
  border-radius: 0.5em;
  font-size: 1.6em;
  hyphens: manual;
  line-height: 1.2;
}
.page-products-wifi-mesh .section-cta .button .no-wrap {
  white-space: nowrap;
}

@media (min-width: 801px) {
  .page-products-mobile .hero-container.hero-container--5g-internet.without-bottom-fade .hero-background-wrapper::after,
.page-products-tdc5g .hero-container.hero-container--5g-internet.without-bottom-fade .hero-background-wrapper::after {
    content: none;
  }
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-background,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-background {
    background-image: url("../images/campaigns/mbb/5g-hero-background-desktop.jpg");
  }
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-content,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-content {
    align-items: flex-start;
    margin-bottom: 1em;
  }
}
@media (min-width: 801px) and (min-width: 1601px) {
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-content,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-content {
    max-width: 52em;
  }
}
@media (min-width: 801px) and (max-width: 1600px) and (min-width: 1201px) {
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-content,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-content {
    max-width: 60%;
  }
}
@media (min-width: 801px) and (max-width: 1200px) {
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-content,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-content {
    max-width: 75%;
  }
}
@media (max-width: 800px) {
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-background-wrapper::after,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-background-wrapper::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: calc(100% + 1px);
    pointer-events: none;
    background: linear-gradient(0deg, rgba(66, 116, 125, 0.8) 8em, rgba(66, 116, 125, 0) 14em);
  }
  .page-products-mobile .hero-container.hero-container--5g-internet.without-bottom-fade .hero-background-wrapper::after,
.page-products-tdc5g .hero-container.hero-container--5g-internet.without-bottom-fade .hero-background-wrapper::after {
    content: none;
  }
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-background,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-background {
    top: unset;
    transform: translateX(-50%);
    background-image: url("../images/campaigns/mbb/5g-hero-background-mobile.jpg");
    opacity: 0.8;
  }
}
@media (max-width: 800px) and (min-width: 521px) {
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-background,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-background {
    bottom: -25%;
  }
}
@media (max-width: 800px) and (max-width: 520px) {
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-background,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-background {
    bottom: 0;
  }
}
@media (max-width: 800px) {
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-content,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-content {
    align-items: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-content h1,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-content h1 {
    text-align: center;
  }
  .page-products-mobile .hero-container.hero-container--5g-internet .hero-content h1 > span,
.page-products-tdc5g .hero-container.hero-container--5g-internet .hero-content h1 > span {
    margin-left: auto;
    margin-right: auto;
  }
}

.mbb-hiper-quality .content,
.mbb-steps .content,
.mbb-includes .content,
.mbb-fact-box,
.mbb-router .content,
.mbb-advantages .content,
.mbb-what .content,
.mbb-faq .content {
  padding-top: 2em;
  padding-bottom: 2em;
}

.mbb-hiper-quality {
  background-color: #eeeeee;
}
.mbb-hiper-quality .content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mbb-hiper-quality .content img {
  flex-shrink: 0;
  width: 12em;
  height: 12em;
  filter: drop-shadow(var(--box-shadow));
}
.mbb-hiper-quality h2 {
  padding-bottom: 0.3em;
}
.mbb-hiper-quality h2 .large {
  font-size: 1.4em;
}

.mbb-steps .box-container, .mbb-steps .box,
.mbb-advantages .box-container,
.mbb-advantages .box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mbb-steps h2 {
  padding-bottom: 1em;
}
.mbb-steps .box-container {
  flex-wrap: wrap;
  gap: 2em;
}
.mbb-steps .box {
  flex-direction: column;
  gap: 0.5em;
}
.mbb-steps .box .image-container {
  position: relative;
}
.mbb-steps .box .image-container img {
  display: block;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
.mbb-steps .box .image-container .number {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  position: absolute;
  top: 0;
  left: 0.4em;
  font-size: 3em;
  line-height: 1.4;
  color: #ffffff;
  text-shadow: var(--box-shadow-small);
}
.mbb-steps .box p {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  text-align: center;
}

.mbb-includes .item,
.mbb-what .item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}
.mbb-includes .item:last-child,
.mbb-what .item:last-child {
  margin-bottom: 0;
}
.mbb-includes .item img,
.mbb-what .item img {
  flex-shrink: 0;
  box-shadow: var(--box-shadow);
}

.mbb-includes h2 {
  padding-bottom: 1em;
}
.mbb-includes img {
  max-width: 24em;
  width: 100%;
  border-radius: 1em;
}

.mbb-fact-box .content {
  border-radius: 1em;
  background-color: #4b848e;
  box-shadow: var(--box-shadow);
  color: #ffffff;
}

.mbb-router {
  background-color: #4b848e;
  color: #ffffff;
}
.mbb-router h2 {
  padding-bottom: 0.5em;
}
.mbb-router .text-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mbb-router .text-image-container .img {
  display: block;
  flex-shrink: 0;
}

.mbb-advantages h2 {
  padding-bottom: 0.5em;
}
.mbb-advantages .box-container {
  flex-direction: column;
}
.mbb-advantages .box {
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
.mbb-advantages .number {
  font-weight: 700;
  font-variation-settings: "wght" 700;
  flex-shrink: 0;
  width: 0.6em;
  font-size: 3em;
  text-align: center;
  color: #ed135a;
}

.mbb-faq h2 {
  padding-bottom: 0.5em;
}

.mbb-coverage .content:first-child {
  padding-top: 2em;
  padding-bottom: 0;
}
.mbb-coverage .content:last-child {
  padding-top: 0;
  padding-bottom: 2em;
}

@media (min-width: 751px) {
  .mbb-hiper-quality .content {
    gap: 2em;
  }

  .mbb-includes .item,
.mbb-what .item {
    gap: 2em;
  }
  .mbb-includes .item.alt,
.mbb-what .item.alt {
    flex-direction: row-reverse;
  }

  .mbb-fact-box .content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;
    padding: 1.5em 2em;
  }
  .mbb-fact-box .content .icon {
    flex-shrink: 0;
    display: block;
    width: 5em;
    height: 5em;
    fill: #ffffff;
    color: transparent;
  }
  .mbb-fact-box .content .icon svg {
    transform: scale(1.5);
  }

  .mbb-router .text-image-container {
    gap: 2em;
  }
  .mbb-router .text-image-container .hide-on-large-devices {
    display: none;
  }
  .mbb-router .text-image-container img {
    max-height: 50vh;
  }

  .mbb-advantages .box-container {
    gap: 1.25em;
  }
  .mbb-advantages .box {
    gap: 1.5em;
    padding: 1em 1.5em;
  }

  .mbb-coverage iframe {
    min-height: 560px;
    height: 60vh;
  }
}
@media (max-width: 750px) {
  .mbb-hiper-quality .content {
    flex-direction: column-reverse;
    gap: 1em;
  }

  .mbb-steps .box-container,
.mbb-includes .item,
.mbb-what .item {
    flex-direction: column;
  }

  .mbb-includes .item,
.mbb-what .item {
    gap: 0.7em;
  }

  .mbb-fact-box .content {
    padding: 1em;
  }
  .mbb-fact-box .content .icon {
    display: none;
  }

  .mbb-router .text-image-container {
    flex-direction: column;
    gap: 1em;
  }
  .mbb-router .text-image-container .hide-on-small-devices {
    display: none;
  }
  .mbb-router .text-image-container img {
    max-width: 24em;
    width: 100%;
    border-radius: 1em;
    box-shadow: var(--box-shadow);
  }

  .mbb-advantages .box-container {
    gap: 1em;
  }
  .mbb-advantages .box {
    gap: 1em;
    padding: 1em;
  }

  .mbb-coverage iframe {
    height: calc(100vh - 3.75em - 2.5em - 56px - 40px - 3em);
  }
}
@media (min-width: 661px) {
  .page-products-fiber .section-includes .grid:first-of-type li:first-child img,
.page-products-coax .section-includes .grid:first-of-type li:first-child img {
    max-height: 20em;
  }

  .page-products-dsl .section-includes .grid:first-of-type li:first-child img {
    max-height: 18em;
  }
}
@media (max-width: 660px) {
  .page-products-fiber .section-includes .grid:first-of-type li:first-child img {
    max-width: 16em;
    width: 75%;
  }

  .page-products-coax .section-includes .grid:first-of-type li:first-child img {
    max-width: 6em;
  }

  .page-products-dsl .section-includes .grid:first-of-type li:first-child img {
    max-width: 12em;
    width: 60%;
  }
}
@media (min-width: 521px) {
  .hero-container.hero-container--products {
    padding: 4em 0 1.5em 0;
  }
}
@media (max-width: 520px) {
  .hero-container.hero-container--products {
    padding: 3em 0 1.5em 0;
  }
  .hero-container.hero-container--products .hero-content h2 {
    font-size: 3em;
  }

  .page-products-wifi-mesh .blurb {
    padding-bottom: 0.5em;
  }
  .page-products-wifi-mesh .section-reasons ul {
    gap: 0.75em;
  }
}
.page-sbbu h1 + p {
  padding-bottom: 1em;
  text-align: center;
}

.page-sbbu .blurb + p,
.selfcare-page-sbbu .selfcare-page-summary {
  padding-bottom: 2em;
}

.sbbu-form {
  position: relative;
  text-align: left;
}
.sbbu-form fieldset,
.sbbu-form legend {
  width: 100%;
}
.sbbu-form legend + * {
  clear: both;
}
.sbbu-form p, .sbbu-form label, .sbbu-form input {
  line-height: 1.4;
}
.sbbu-form p {
  text-align: left;
  margin: 0;
}
.sbbu-form p.align-center {
  text-align: center;
}
.sbbu-form p.small {
  font-size: 0.8em;
}
.sbbu-form p.smaller {
  font-size: 0.7em;
  line-height: 1.2;
}
.sbbu-form p.no-space {
  padding-bottom: 0;
}
.sbbu-form input + p.small {
  padding-top: 0.25em;
}
.sbbu-form label {
  display: inline-block;
}
.sbbu-form input + label:not(.checkbox-label) {
  padding-top: 0.25em;
}
.sbbu-form input, .sbbu-form input::placeholder {
  text-align: left;
}
.sbbu-form input {
  box-sizing: border-box;
  padding: 0 0.4em;
}
.sbbu-form hr {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 521px) {
  .sbbu-form hr {
    margin: 2em auto;
  }
}
@media (max-width: 520px) {
  .sbbu-form hr {
    margin: 1em auto;
  }
}
.sbbu-form details {
  border-top: 1px solid #dad9d7;
  border-bottom: 1px solid #dad9d7;
}
.sbbu-form summary {
  display: grid;
  grid-template-columns: auto 1.5em;
  grid-template-areas: "text icon";
  align-items: center;
  gap: 0.5em;
  padding: 0.75em 0;
  width: 100%;
  color: #303a4b;
}
.sbbu-form summary .title {
  grid-area: text;
  line-height: 1.4;
}
.sbbu-form summary i {
  grid-area: icon;
  position: relative;
  display: block;
  width: 1em;
  height: 0.6em;
  font-size: 1.5em;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sbbu-form summary i::before, .sbbu-form summary i::after {
  content: "";
  display: block;
  width: 0.6em;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #303a4b;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.sbbu-form summary i::before {
  transform: translate(0, -50%) rotate(45deg);
}
.sbbu-form summary i::after {
  transform: translate(0.4em, -50%) rotate(-45deg);
}
.sbbu-form summary:hover {
  color: rgba(48, 58, 75, 0.75);
}
.sbbu-form summary:hover i::before, .sbbu-form summary:hover i::after {
  background-color: rgba(48, 58, 75, 0.75);
}
.sbbu-form summary + div {
  padding: 0 0 1em;
}
.sbbu-form details[open] > summary > i::before {
  transform: translate(0.4em, -50%) rotate(45deg);
}
.sbbu-form details[open] > summary > i::after {
  transform: translate(0, -50%) rotate(-45deg);
}
.sbbu-form .select-container {
  position: relative;
}
.sbbu-form .select-container:before {
  content: "▼";
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 1.5em;
  color: #83aec4;
  pointer-events: none;
}
.sbbu-form .select-container:hover:before {
  color: #6097b4;
}
.sbbu-form select {
  display: flex;
  overflow: hidden;
  text-align-last: unset;
  padding: 0 0.4em;
  text-transform: none;
}
.sbbu-form select::-ms-expand {
  display: none;
}
.sbbu-form select, .sbbu-form select option {
  text-align: left;
}
.sbbu-form .address {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;
}
.sbbu-form .installation-address {
  border: none;
}
.sbbu-form .installation-address .accordion,
.sbbu-form .installation-address summary {
  padding: 0.5em 0.5em 0.5em 0.4em;
  border: none;
  border-radius: 0.5em;
  color: #ffffff;
}
.sbbu-form .installation-address .accordion:not(.accordion--footer):hover:not(.active) .symbol span,
.sbbu-form .installation-address .accordion .symbol span,
.sbbu-form .installation-address .accordion i::before,
.sbbu-form .installation-address .accordion i::after,
.sbbu-form .installation-address summary:not(.accordion--footer):hover:not(.active) .symbol span,
.sbbu-form .installation-address summary .symbol span,
.sbbu-form .installation-address summary i::before,
.sbbu-form .installation-address summary i::after {
  background-color: #ffffff;
}
.sbbu-form .installation-address .accordion,
.sbbu-form .installation-address summary {
  background-color: #4b848e;
}
.sbbu-form .installation-address .accordion:hover,
.sbbu-form .installation-address summary:hover {
  background-color: #42747d;
}
.sbbu-form .installation-address .accordion:hover:not(.active) .title {
  color: #ffffff;
}
.sbbu-form .installation-address .accordion-content,
.sbbu-form .installation-address summary + div {
  padding: 0.5em 0 0 0;
}
.sbbu-form .input-container {
  margin-bottom: 1em;
}
.sbbu-form .input-container:last-child {
  margin-bottom: 0;
}
.sbbu-form .input-container p:not(:last-child) {
  padding-bottom: 0.35em;
}
@media (max-width: 400px) {
  .sbbu-form .flatpickr-wrapper .flatpickr-calendar {
    min-width: 0;
  }
}
@media (max-width: 350px) {
  .sbbu-form .flatpickr-wrapper .flatpickr-calendar {
    margin-top: 0.5em;
    padding: 0;
  }
  .sbbu-form .flatpickr-wrapper .flatpickr-calendar .flatpickr-day {
    width: calc(calc(100vw - 1em) / 7 - 4px );
  }
}
.sbbu-form .submit-button-flex {
  padding: 0 0 0.5em 0;
  flex-direction: column;
}
.sbbu-form .submit-button-flex + p {
  text-align: center;
}
@media (min-width: 521px) {
  .sbbu-form .address input:nth-child(1) {
    flex: calc(50% - .25em) 1 1;
  }
  .sbbu-form .address input:nth-child(2), .sbbu-form .address input:nth-child(3), .sbbu-form .address input:nth-child(4) {
    flex: calc(calc(50% - calc(.5em * 2.5)) / 3) 1 1;
  }
  .sbbu-form .address input:nth-child(5), .sbbu-form .address input:nth-child(6) {
    flex: calc(50% - .25em) 1 1;
  }
}
@media (max-width: 520px) {
  .sbbu-form input::placeholder {
    font-weight: 300;
    font-size: 0.9em;
  }
  .sbbu-form .address input:nth-child(1) {
    width: 100%;
  }
  .sbbu-form .address input:nth-child(2), .sbbu-form .address input:nth-child(3), .sbbu-form .address input:nth-child(4) {
    flex: calc(calc(100% - calc(.5em * 2)) / 3) 1 1;
  }
  .sbbu-form .address input:nth-child(5), .sbbu-form .address input:nth-child(6) {
    flex: calc(50% - .25em) 1 1;
  }
  .sbbu-form p.with-checkbox {
    text-indent: 0;
  }
  .sbbu-form p.with-checkbox label {
    text-indent: 1.9em;
  }
}

.sbbu-form:not(.sbbu-form-1-2-3) .hide-on-old {
  display: none;
}
.sbbu-form:not(.sbbu-form-1-2-3) h3:not(.title) {
  padding: 0 0 0.5rem 0;
  font-size: 2em;
}
.sbbu-form:not(.sbbu-form-1-2-3) h3 .number {
  display: none;
}
.sbbu-form:not(.sbbu-form-1-2-3) fieldset > .accordion-container {
  border: 0;
}
.sbbu-form:not(.sbbu-form-1-2-3) fieldset > .accordion-container > .accordion {
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  pointer-events: none;
}
.sbbu-form:not(.sbbu-form-1-2-3) fieldset > .accordion-container > .accordion .title {
  margin: 0;
  padding: 0 0 0.5rem 0;
  font-size: 2em;
}
.sbbu-form:not(.sbbu-form-1-2-3) fieldset > .accordion-container > .accordion .symbol {
  display: none;
}
.sbbu-form:not(.sbbu-form-1-2-3) fieldset > .accordion-container > .accordion-content-container {
  overflow: visible;
  opacity: 1;
  pointer-events: all;
  max-height: none;
}
.sbbu-form:not(.sbbu-form-1-2-3) fieldset > .accordion-container > .accordion-content-container > .accordion-content {
  padding: 0 0 1em;
}
.sbbu-form:not(.sbbu-form-1-2-3) .sbbu-consent p.with-checkbox {
  padding-bottom: 0;
}

.sbbu-form-1-2-3 .hide-on-new,
.sbbu-form-1-2-3 hr {
  display: none;
}
.sbbu-form-1-2-3 legend {
  float: left;
  width: 100%;
}
.sbbu-form-1-2-3 legend .title {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  display: block;
  padding-bottom: 0.1em;
  font-size: 1.2em;
}
.sbbu-form-1-2-3 h3 {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-align: left;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: normal;
}
.sbbu-form-1-2-3 h3 .number {
  display: inline;
  color: #ed135a;
}
.sbbu-form-1-2-3 label .title {
  display: block;
}
.sbbu-form-1-2-3 fieldset {
  margin-bottom: 1em;
}
.sbbu-form-1-2-3 fieldset:last-child {
  margin-bottom: 0;
}
.sbbu-form-1-2-3 fieldset > .accordion-container > .accordion-content-container > .accordion-content {
  padding: 0 0 1em;
}
.sbbu-form-1-2-3 .personal-information > .accordion-container > .accordion .title {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  padding-bottom: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  line-height: 1.4;
  text-transform: none;
}
.sbbu-form-1-2-3 .sbbu-consent p.with-checkbox {
  padding-bottom: 1em;
}
@media (min-width: 521px) {
  .sbbu-form-1-2-3 label .title {
    padding-bottom: 0.1em;
    font-size: 1.2em;
  }
  .sbbu-form-1-2-3 .sbbu-competitor > h3,
.sbbu-form-1-2-3 .sbbu-competitor > legend h3,
.sbbu-form-1-2-3 .sbbu-date > h3,
.sbbu-form-1-2-3 .sbbu-date > legend h3,
.sbbu-form-1-2-3 .sbbu-consent > h3,
.sbbu-form-1-2-3 .sbbu-consent > legend h3 {
    padding: 0 0 1rem;
    font-size: 1.5em;
  }
  .sbbu-form-1-2-3 h3 .number {
    font-size: 1.8em;
  }
  .sbbu-form-1-2-3 hr {
    display: none;
  }
  .sbbu-form-1-2-3 fieldset {
    margin-bottom: 1em;
  }
  .sbbu-form-1-2-3 fieldset.personal-information {
    margin-bottom: 2em;
  }
  .sbbu-form-1-2-3 fieldset:not(.personal-information) {
    padding: 0.5em 1em 1em;
    border-radius: 1em;
    box-shadow: var(--box-shadow);
  }
  .sbbu-form-1-2-3 .personal-information > .accordion-container > .accordion,
.sbbu-form-1-2-3 .personal-information > details > summary {
    padding: 0.5em 0;
  }
}
@media (max-width: 520px) {
  .sbbu-form-1-2-3 hr {
    margin: 2em auto 1.5em;
  }
  .sbbu-form-1-2-3 .sbbu-competitor + hr,
.sbbu-form-1-2-3 .sbbu-date + hr {
    display: block;
  }
  .sbbu-form-1-2-3 label .title {
    padding-bottom: 0.25em;
    font-size: 1.1em;
  }
  .sbbu-form-1-2-3 .sbbu-competitor > h3,
.sbbu-form-1-2-3 .sbbu-competitor > legend h3,
.sbbu-form-1-2-3 .sbbu-date > h3,
.sbbu-form-1-2-3 .sbbu-date > legend h3,
.sbbu-form-1-2-3 .sbbu-consent > h3,
.sbbu-form-1-2-3 .sbbu-consent > legend h3 {
    padding: 0 0 0.5em;
    font-size: 1.25em;
  }
  .sbbu-form-1-2-3 h3 .number {
    font-size: 2em;
  }
  .sbbu-form-1-2-3 fieldset {
    margin-bottom: 0.5em;
  }
  .sbbu-form-1-2-3 fieldset.personal-information {
    margin-bottom: 1.5em;
  }
  .sbbu-form-1-2-3 .personal-information > .accordion-container > .accordion,
.sbbu-form-1-2-3 .personal-information > details > summary {
    padding: 0.25em 0;
  }
  .sbbu-form-1-2-3 .personal-information > .accordion-container > .accordion .title,
.sbbu-form-1-2-3 .personal-information > details > summary .title {
    font-size: 0.9em;
  }
  .sbbu-form-1-2-3 .submit-button-flex {
    font-size: 1.2em;
  }
  .sbbu-form-1-2-3 .submit-button-flex .button {
    padding: 0.5em 1em;
  }
}

.sbbu-custom-provider-hidden {
  display: none;
}

.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #4b848e;
  gap: 1em;
  padding: 8.5em 0 1em;
}
.hero-container.darkteal {
  background-color: #42747d;
}
.hero-container .hero-ribbon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.8em 0.5em;
  border: none;
  gap: 0.5em;
  position: absolute;
  z-index: 1;
  top: 6.5em;
  left: 0;
  right: 0;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  background-color: #42747d;
  color: #ffffff;
}
.hero-container .hero-ribbon h3 {
  font-size: 2.5em;
  font-weight: 400;
  line-height: 1;
  padding: 0;
}
@media (max-width: 520px) {
  .hero-container .hero-ribbon h3 {
    padding-top: 0.135em;
  }
}
@media (min-width: 901px) {
  .hero-container .hero-ribbon .hide-on-large-devices {
    display: none;
  }
}
@media (max-width: 900px) {
  .hero-container .hero-ribbon .hide-on-small-devices {
    display: none;
  }
}
.hero-container .hero-ribbon .arrow-container {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
}
.hero-container .hero-ribbon .arrow-container .arrow-down {
  width: 0;
  height: 0;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #42747d;
}
.hero-container .hero-ribbon.teal {
  background-color: #4b848e;
}
.hero-container .hero-ribbon.teal .arrow-container .arrow-down {
  border-top-color: #4b848e;
}
.hero-container .hero-ribbon.lightblue {
  background-color: #7acfe0;
}
.hero-container .hero-ribbon.lightblue .arrow-container .arrow-down {
  border-top-color: #7acfe0;
}
.hero-container .hero-ribbon.blue {
  background-color: #2b3443;
}
.hero-container .hero-ribbon.blue .arrow-container .arrow-down {
  border-top-color: #2b3443;
}
.hero-container .hero-ribbon.red {
  background-color: #ed135a;
}
.hero-container .hero-ribbon.red .arrow-container .arrow-down {
  border-top-color: #ed135a;
}
.hero-container .hero-ribbon.hero-ribbon--5g {
  top: 0;
  height: 3em;
  overflow: hidden;
}
.hero-container .hero-ribbon.hero-ribbon--5g div {
  --animation-length: 30s;
  --text-2-start: 100%;
  --text-1-end: calc(var(--text-2-start) * -1);
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: var(--text-2-start);
  pointer-events: none;
}
@media (min-width: 1801px) {
  .hero-container .hero-ribbon.hero-ribbon--5g div {
    --animation-length: 50s;
  }
}
@media (max-width: 1800px) {
  .hero-container .hero-ribbon.hero-ribbon--5g div span:nth-child(4) {
    display: none;
  }
}
@media (max-width: 1300px) and (min-width: 1001px) {
  .hero-container .hero-ribbon.hero-ribbon--5g div {
    --text-2-start: 125%;
  }
}
@media (max-width: 1000px) {
  .hero-container .hero-ribbon.hero-ribbon--5g div span:nth-child(3) {
    display: none;
  }
}
@media (max-width: 750px) and (min-width: 601px) {
  .hero-container .hero-ribbon.hero-ribbon--5g div {
    --text-2-start: 125%;
  }
}
@media (max-width: 600px) and (min-width: 481px) {
  .hero-container .hero-ribbon.hero-ribbon--5g div {
    --text-2-start: 150%;
  }
}
@media (max-width: 480px) {
  .hero-container .hero-ribbon.hero-ribbon--5g div span:nth-child(2) {
    display: none;
  }
}
@media (max-width: 350px) {
  .hero-container .hero-ribbon.hero-ribbon--5g div {
    --text-2-start: 125%;
  }
}
@media (max-width: 300px) {
  .hero-container .hero-ribbon.hero-ribbon--5g div {
    --text-2-start: 150%;
  }
}
.hero-container .hero-ribbon.hero-ribbon--5g div:nth-child(1) {
  animation: text-1-move var(--animation-length) linear infinite;
}
@keyframes text-1-move {
  0% {
    left: 0;
  }
  100% {
    left: var(--text-1-end);
  }
}
.hero-container .hero-ribbon.hero-ribbon--5g div:nth-child(2) {
  left: var(--text-2-start);
  animation: text-2-move var(--animation-length) linear infinite;
}
@keyframes text-2-move {
  0% {
    left: var(--text-2-start);
  }
  100% {
    left: 0;
  }
}
.hero-container .hero-ribbon.hero-ribbon--5g div span {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
.hero-container .hero-ribbon.hero-ribbon--5g div strong {
  font-weight: 700;
  font-variation-settings: "wght" 700;
}
.hero-container .hero-background-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.hero-container .hero-background {
  pointer-events: none;
  position: absolute;
  z-index: 0;
}
.hero-container .hero-background .hero-image {
  max-width: 32em;
  max-height: 100%;
  width: auto;
}
@media (max-width: 900px) {
  .hero-container .hero-background .hero-image {
    display: none;
  }
}
.hero-container .mvfp-23 {
  position: absolute;
}
@media (min-width: 721px) {
  .hero-container .mvfp-23 {
    transform: translate(1em, -50%) rotate(8deg);
    top: 40%;
    left: 55%;
    max-width: 18em;
  }
}
@media (max-width: 720px) and (min-width: 521px) {
  .hero-container .mvfp-23 {
    right: 2em;
    transform: translate(1em, -50%) rotate(8deg);
    max-width: 16em;
  }
}
@media (max-width: 520px) {
  .hero-container .mvfp-23 {
    transform: translateX(-50%) rotate(8deg);
    top: 30%;
    left: 75%;
    max-width: 9em;
  }
}
@media (max-width: 320px) {
  .hero-container .mvfp-23 {
    max-width: 5em;
  }
}
.hero-container .splash-item {
  border-radius: 0.1875em;
  position: absolute;
  color: #ffffff;
  background-color: #303a4b;
  box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 1.1;
}
.hero-container .splash-item > span {
  font-size: 2em;
}
.hero-container .splash-item > span.cervo {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
}
.hero-container .splash-item strong {
  font-size: 2em;
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
.hero-container .splash-item strong.cervo {
  font-weight: 400;
}
@media (min-width: 721px) {
  .hero-container .splash-item {
    transform: translate(1em, -50%) rotate(-15deg);
    top: 40%;
    left: 50%;
  }
}
@media (max-width: 720px) {
  .hero-container .splash-item {
    transform: rotate(-7.5deg);
    top: 50%;
  }
}
@media (max-width: 720px) and (min-width: 521px) {
  .hero-container .splash-item {
    right: 2em;
  }
}
@media (min-width: 521px) {
  .hero-container .splash-item {
    padding: 0.8em 1.6em;
  }
  .hero-container .splash-item .hide-on-large-devices {
    display: none;
  }
}
@media (max-width: 520px) {
  .hero-container .splash-item {
    transform: translateX(-50%) rotate(-7.5deg);
    left: 75%;
    width: max-content;
    padding: 0.5em 0.5em 0.3em 0.5em;
  }
  .hero-container .splash-item .hide-on-small-devices {
    display: none;
  }
  .hero-container .splash-item > span {
    font-size: 1.6em;
  }
}
@media (max-width: 350px) {
  .hero-container .splash-item {
    display: none;
  }
}
.hero-container .stamp {
  width: 10em;
  height: 10em;
  position: absolute;
  z-index: 2;
}
.hero-container .stamp, .hero-container .stamp img {
  max-width: 240px;
  max-height: 240px;
}
.hero-container .stamp img {
  position: absolute;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  box-shadow: 1px 3px 6px 3px rgba(0, 0, 0, 0.3);
}
.hero-container .stamp.stamp--fly-in:not(.stamp--triple) img {
  animation-timing-function: ease-out;
  animation: splashFlyIn 0.5s;
  -webkit-animation: splashFlyIn 0.5s;
  -moz-animation: splashFlyIn 0.5s;
}
@keyframes splashFlyIn {
  0% {
    transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);
  }
  100% {
    transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);
  }
}
@-webkit-keyframes splashFlyIn {
  0% {
    -webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);
  }
  100% {
    -webkit-transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);
  }
}
@-moz-keyframes splashFlyIn {
  0% {
    -moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);
  }
  100% {
    -moz-transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);
  }
}
.hero-container .stamp.stamp--fly-in.stamp--triple img {
  animation-timing-function: ease-out;
}
.hero-container .stamp.stamp--fly-in.stamp--triple img:first-child {
  transform: translate(-1em, 0.8em) rotate(-20deg);
  -webkit-transform: translate(-1em, 0.8em) rotate(-20deg);
  -moz-transform: translate(-1em, 0.8em) rotate(-20deg);
  animation: splashFlyIn1 1.2s;
  -webkit-animation: splashFlyIn1 1.2s;
  -moz-animation: splashFlyIn1 1.2s;
}
@keyframes splashFlyIn1 {
  0% {
    transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-30deg);
  }
  40% {
    transform: perspective(800px) translate3d(-1em, 0.8em, 0) rotate(-20deg);
  }
  100% {
    transform: perspective(800px) translate3d(-1em, 0.8em, 0) rotate(-20deg);
  }
}
@-webkit-keyframes splashFlyIn1 {
  0% {
    -webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-30deg);
  }
  40% {
    -webkit-transform: perspective(800px) translate3d(-1em, 0.8em, 0) rotate(-20deg);
  }
  100% {
    -webkit-transform: perspective(800px) translate3d(-1em, 0.8em, 0) rotate(-20deg);
  }
}
@-moz-keyframes splashFlyIn1 {
  0% {
    -moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-30deg);
  }
  40% {
    -moz-transform: perspective(800px) translate3d(-1em, 0.8em, 0) rotate(-20deg);
  }
  100% {
    -moz-transform: perspective(800px) translate3d(-1em, 0.8em, 0) rotate(-20deg);
  }
}
.hero-container .stamp.stamp--fly-in.stamp--triple img:nth-child(2) {
  transform: translate(-0.6em, 0.3em) rotate(-10deg);
  -webkit-transform: translate(-0.6em, 0.3em) rotate(-10deg);
  -moz-transform: translate(-0.6em, 0.3em) rotate(-10deg);
  animation: splashFlyIn2 1.2s;
  -webkit-animation: splashFlyIn2 1.2s;
  -moz-animation: splashFlyIn2 1.2s;
}
@keyframes splashFlyIn2 {
  0% {
    transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);
  }
  30% {
    transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);
  }
  70% {
    transform: perspective(800px) translate3d(-0.6em, 0.3em, 0) rotate(-10deg);
  }
  100% {
    transform: perspective(800px) translate3d(-0.6em, 0.3em, 0) rotate(-10deg);
  }
}
@-webkit-keyframes splashFlyIn2 {
  0% {
    -webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);
  }
  30% {
    -webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);
  }
  70% {
    -webkit-transform: perspective(800px) translate3d(-0.6em, 0.3em, 0) rotate(-10deg);
  }
  100% {
    -webkit-transform: perspective(800px) translate3d(-0.6em, 0.3em, 0) rotate(-10deg);
  }
}
@-moz-keyframes splashFlyIn2 {
  0% {
    -moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);
  }
  30% {
    -moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-20deg);
  }
  70% {
    -moz-transform: perspective(800px) translate3d(-0.6em, 0.3em, 0) rotate(-10deg);
  }
  100% {
    -moz-transform: perspective(800px) translate3d(-0.6em, 0.3em, 0) rotate(-10deg);
  }
}
.hero-container .stamp.stamp--fly-in.stamp--triple img:nth-child(3) {
  animation: splashFlyIn3 1.2s;
  -webkit-animation: splashFlyIn3 1.2s;
  -moz-animation: splashFlyIn3 1.2s;
}
@keyframes splashFlyIn3 {
  0% {
    transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);
  }
  60% {
    transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);
  }
  100% {
    transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);
  }
}
@-webkit-keyframes splashFlyIn3 {
  0% {
    -webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);
  }
  60% {
    -webkit-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);
  }
  100% {
    -webkit-transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);
  }
}
@-moz-keyframes splashFlyIn3 {
  0% {
    -moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);
  }
  60% {
    -moz-transform: perspective(800px) translate3d(0, -150px, 900px) rotate(-10deg);
  }
  100% {
    -moz-transform: perspective(800px) translate3d(0, 0, 0) rotate(0deg);
  }
}
.hero-container .stamp.stamp--fly-down img {
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation: splashFlyDown 0.75s;
  -webkit-animation: splashFlyDown 0.75s;
  -moz-animation: splashFlyDown 0.75s;
}
@keyframes splashFlyDown {
  0% {
    transform: translateY(-50vh);
    opacity: 0;
  }
  50% {
    opacity: 100%;
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes splashFlyDown {
  0% {
    -webkit-transform: translateY(-50vh);
    opacity: 0;
  }
  50% {
    opacity: 100%;
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes splashFlyDown {
  0% {
    -moz-transform: translateY(-50vh);
    opacity: 0;
  }
  50% {
    opacity: 100%;
  }
  100% {
    -moz-transform: translateY(0);
  }
}
.hero-container .counter {
  color: #ffffff;
  font-size: 0.9em;
}
.hero-container .counter:not(.counter--banner) {
  margin-bottom: 1em;
  margin-left: -1em;
  padding: 0.3rem 0.6rem 0;
  border-radius: 0.75rem;
  line-height: 1.9em;
}
.hero-container .counter:not(.counter--banner) #time {
  padding: 0.3rem 0.7rem;
  border-radius: 0.5rem;
  background-color: #ed135a;
  font-size: 1.1em;
}
.hero-container .counter.counter--banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.75em 1em;
  width: 100%;
  background-color: #ed135a;
  font-size: 1.2em;
  line-height: 1.2;
  text-align: center;
}
.hero-container .counter.counter--banner #time {
  padding: 0 0 0 0.25em;
  border-radius: 0;
  background-color: transparent;
  font-size: 1em;
}
.hero-container .counter #time {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
@media (min-width: 521px) {
  .hero-container .counter .hide-on-large-devices {
    display: none;
  }
}
@media (max-width: 520px) {
  .hero-container .counter .hide-on-small-devices {
    display: none;
  }
  .hero-container .counter:not(.counter--banner) {
    font-size: 0.75em;
  }
  .hero-container .counter:not(.counter--banner) #time {
    font-size: 1em;
  }
  .hero-container .counter.counter--banner {
    font-size: 1em;
    box-shadow: var(--box-shadow);
  }
  .hero-container .counter.counter--banner #time {
    font-size: 1.2em;
  }
}
.hero-container .hero-content, .hero-container .conditions {
  z-index: 1;
}
.hero-container .hero-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.hero-container .hero-content h1 {
  margin: 0;
  padding: 0;
  color: #ffffff;
  text-align: left;
  line-height: 0.9;
}
.hero-container .hero-content h1 > span {
  display: block;
  width: fit-content;
}
.hero-container .hero-content .hero-pre-speed {
  padding-bottom: 0.5em;
}
.hero-container .hero-content .hero-bullets {
  line-height: 1.6;
}
.hero-container .hero-content .hero-speed,
.hero-container .hero-content .hero-price {
  font-weight: 400;
  margin-left: -0.12em;
}
.hero-container .hero-content .hero-pre-speed,
.hero-container .hero-content .hero-bullets,
.hero-container .hero-content .hero-pre-price,
.hero-container .hero-content .hero-price-disclaimer {
  font-weight: 300;
  font-variation-settings: "wght" 300;
  margin-left: -0.11em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
}
.hero-container .hero-content .hero-pre-price {
  padding: 0.2em 0;
}
.hero-container .hero-content .hero-price {
  position: relative;
  white-space: nowrap;
}
.price-comma-dash {
  letter-spacing: -0.15em;
}

.price-md {
  position: absolute;
  top: 30%;
  right: -1.2em;
  display: block;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.12em;
  white-space: nowrap;
  letter-spacing: 0;
  text-transform: lowercase;
}

.hero-container .hero-content .strikethrough-price::before {
  width: 120%;
  height: 0.06em;
  border-radius: 3px;
  background-color: #ed135a;
  border-bottom: none;
  top: 50%;
  transform: rotate(-20deg) translate(10%, 50%);
  z-index: 1;
}
.hero-container.windows .hero-content .hero-price {
  margin-top: -0.1em;
}
.hero-container .address-wrap {
  width: 100%;
  background-color: transparent;
}
.hero-container .address-wrap h2 {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  margin: 0 auto;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-align: center;
  text-transform: none;
  line-height: 1.2;
  color: #ffffff;
  position: relative;
  z-index: 5;
}
.hero-container .address-wrap h2.address-button {
  cursor: pointer;
}
.hero-container .address-wrap h2 .scroll-down-arrow {
  position: relative;
  display: inline-block;
  margin-left: 0.25em;
  height: 1em;
  width: 1em;
  animation: scroll-down-arrow 1.5s ease-in-out infinite;
  -webkit-animation: scroll-down-arrow 1.5s ease-in-out infinite;
  -moz-animation: scroll-down-arrow 1.5s ease-in-out infinite;
}
.hero-container .address-wrap h2 .scroll-down-arrow::before, .hero-container .address-wrap h2 .scroll-down-arrow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
}
@keyframes scroll-down-arrow {
  0% {
    transform: translateY(-0.25em);
  }
  50% {
    transform: translateY(0.25em);
  }
  100% {
    transform: translateY(-0.25em);
  }
}
@-webkit-keyframes scroll-down-arrow {
  0% {
    -webkit-transform: translateY(-0.25em);
  }
  50% {
    -webkit-transform: translateY(0.25em);
  }
  100% {
    -webkit-transform: translateY(-0.25em);
  }
}
@-moz-keyframes scroll-down-arrow {
  0% {
    -moz-transform: translateY(-0.25em);
  }
  50% {
    -moz-transform: translateY(0.25em);
  }
  100% {
    -moz-transform: translateY(-0.25em);
  }
}
@media (min-width: 521px) {
  .hero-container .address-wrap h2 .scroll-down-arrow::before, .hero-container .address-wrap h2 .scroll-down-arrow::after {
    width: 16px;
    height: 2px;
    border-radius: 1px;
  }
  .hero-container .address-wrap h2 .scroll-down-arrow::before {
    transform: translateY(18px) rotate(45deg);
  }
  .hero-container .address-wrap h2 .scroll-down-arrow::after {
    transform: translate(10px, 18px) rotate(-45deg);
  }
}
@media (max-width: 520px) {
  .hero-container .address-wrap h2 .scroll-down-arrow::before, .hero-container .address-wrap h2 .scroll-down-arrow::after {
    width: 12px;
    height: 1px;
  }
  .hero-container .address-wrap h2 .scroll-down-arrow::before {
    transform: translateY(10px) rotate(45deg);
  }
  .hero-container .address-wrap h2 .scroll-down-arrow::after {
    transform: translate(8px, 10px) rotate(-45deg);
  }
}
.hero-container .conditions {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  margin: 0 auto;
  color: #ffffff;
  font-size: 0.7em;
  line-height: 1.4;
  text-align: center;
}
.hero-container .conditions span[class^=conditions--convert] {
  display: none;
}
.hero-container .specific-address-popup {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
.hero-container .specific-address-popup.visible {
  opacity: 1;
  overflow: visible;
  visibility: visible;
}
.hero-container .specific-address-popup button {
  font-family: "montserrat", "sans serif";
  font-weight: 300;
  background-color: transparent;
  border: none;
  padding: 0;
  border-bottom: 2px solid rgba(237, 19, 90, 0.75);
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.hero-container .specific-address-popup .popup-content {
  position: relative;
  padding: 2em;
  width: 30em;
  max-width: calc(100vw - 1em);
  border-radius: 0.5em;
  background-color: #ffffff;
  text-align: center;
}
.hero-container .specific-address-popup .wl-overlay__close {
  top: 0.5rem;
  right: 0.75rem;
  font-size: 2em;
}
.hero-container.hero-container--video .hero-background {
  max-width: 1920px;
  max-height: 1080px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
}
.hero-container.hero-container--video .hero-background video {
  max-height: 1080px;
  height: 100%;
  width: auto;
  aspect-ratio: 16/9;
}
.hero-container.hero-container--video .hero-background::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(0deg, #4b848e 0%, rgba(75, 132, 142, 0) 4em);
}
@media (min-width: 1921px) {
  .hero-container.hero-container--video .hero-background::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(90deg, #4b848e 0%, rgba(75, 132, 142, 0) 10%, rgba(75, 132, 142, 0) 90%, #4b848e 100%);
  }
}
@media (min-width: 1495px) {
  .hero-container.hero-container--video .hero-background {
    top: -9em;
    height: auto;
  }
  .hero-container.hero-container--video.hero-container--video-dans .hero-background {
    top: -6em;
  }
}
@media (min-width: 721px) and (max-width: 1494px) {
  .hero-container.hero-container--video .hero-background {
    top: -8em;
    height: calc(100% + 8em);
  }
  .hero-container.hero-container--video.hero-container--video-dans .hero-background {
    top: -4em;
    height: calc(100% + 4em);
  }
}
@media (min-width: 881px) and (max-width: 1130px) {
  .hero-container.hero-container--video.hero-container--video-dans .hero-background {
    left: 40%;
  }
}
@media (max-width: 720px) {
  .hero-container.hero-container--video .hero-background {
    transform: translateX(-66%);
    -moz-transform: translateX(-60%);
    left: 75%;
    top: -7em;
    height: calc(100% + 7em);
  }
  .hero-container.hero-container--video .hero-background::before {
    background: linear-gradient(0deg, rgba(75, 132, 142, 0.5) 0%, rgba(75, 132, 142, 0) 6em);
  }
}
@media (max-width: 520px) {
  .hero-container.hero-container--video .hero-background {
    -moz-transform: translateX(-47.5%);
  }
}
@media (min-width: 651px) and (max-width: 880px) {
  .hero-container.hero-container--video.hero-container--video-dans .hero-background {
    transform: translateX(-53%);
    top: 0;
    left: 50%;
    width: 120%;
    height: auto;
  }
  .hero-container.hero-container--video.hero-container--video-dans .hero-background::before {
    aspect-ratio: 16/9;
    background: linear-gradient(0deg, #4b848e 0%, rgba(75, 132, 142, 0) 4em);
  }
  .hero-container.hero-container--video.hero-container--video-dans .hero-background video {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 650px) {
  .hero-container.hero-container--video.hero-container--video-dans .hero-background {
    transform: translate(-68%, -50%);
    -moz-transform: translate(-68%, -50%);
    left: 50%;
    top: 50%;
    width: 230%;
    height: auto;
  }
  .hero-container.hero-container--video.hero-container--video-dans .hero-background::before {
    background: linear-gradient(0deg, #4b848e 0%, rgba(75, 132, 142, 0) 10%, rgba(75, 132, 142, 0) 85%, #4b848e 100%);
  }
  .hero-container.hero-container--video.hero-container--video-dans .hero-background video {
    opacity: 0.6;
    width: 100%;
    height: auto;
  }
  .hero-container.hero-container--video.hero-container--video-dans .hero-content {
    --fz-multiplier: .65;
    align-items: center;
  }
  .hero-container.hero-container--video.hero-container--video-dans .hero-content h1 {
    text-align: center;
    text-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.25);
  }
  .hero-container.hero-container--video.hero-container--video-dans .hero-content .hero-speed,
.hero-container.hero-container--video.hero-container--video-dans .hero-content .hero-pre-price,
.hero-container.hero-container--video.hero-container--video-dans .hero-content .hero-price,
.hero-container.hero-container--video.hero-container--video-dans .hero-content .hero-price-disclaimer {
    margin-left: auto;
    margin-right: auto;
  }
}
.hero-container.hero-container--usp {
  padding-bottom: 1em;
  gap: 1em;
}
.hero-container.hero-container--usp .hero-background {
  max-height: 1080px;
  top: 50%;
  left: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 470px) {
  .hero-container.hero-container--usp .hero-background {
    aspect-ratio: 1;
    max-width: 1080px;
    height: calc(100% + 2em);
    transform: translate(-50%, calc(-50% - 1em));
    background-image: url("../images/pages/home/blank-scene-blur-mobile.jpg");
  }
  .hero-container.hero-container--usp .hero-background .desktop {
    display: none;
  }
}
@media (min-width: 471px) {
  .hero-container.hero-container--usp .hero-background {
    aspect-ratio: 16/9;
    max-width: 1920px;
    height: 120%;
    transform: translate(-50%, -50%);
    background-image: url("../images/pages/home/blank-scene-blur-desktop.jpg");
  }
  .hero-container.hero-container--usp .hero-background .mobile {
    display: none;
  }
}
@media (min-width: 960px) {
  .hero-container.hero-container--usp .hero-background::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: -1px;
    right: -1px;
    bottom: 0;
    width: calc(100% + 2px);
    pointer-events: none;
    background: linear-gradient(90deg, #4b848e 0%, rgba(75, 132, 142, 0) 10%, rgba(75, 132, 142, 0) 90%, #4b848e 100%);
  }
}
@media (min-width: 1201px) {
  .hero-container.hero-container--usp .hero-background {
    max-height: 150%;
    width: 100%;
    height: auto;
    background-position-y: 25%;
  }
}
.hero-container.hero-container--usp .hero-content {
  margin-bottom: -1em;
  padding: 0 1rem;
}
.hero-container.hero-container--usp .hero-content h1 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
  line-height: 1.1;
  text-align: center;
  hyphens: manual;
  margin: 0 auto;
}
.hero-container.hero-container--usp .hero-content h1 span {
  display: unset;
}
.hero-container.hero-container--usp .hero-content h1 .hiper-text-stroke::after {
  z-index: -1;
}
.hero-container.hero-container--usp .hero-content h1 > strong {
  font-size: 1.2em;
}
.hero-container.hero-container--usp .hero-content h1 span strong {
  font-size: 1.5em;
}
.hero-container.hero-container--usp .hero-content h1 strong {
  font-weight: 700;
  font-variation-settings: "wght" 700;
}
.hero-container.hero-container--usp .address-wrap {
  margin: 1em 0;
}
.hero-container.hero-container--usp .address-wrap h2 {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 3em;
  padding: 0;
}
.hero-container.hero-container--sharp-price {
  gap: 0;
  padding: 0;
}
.hero-container.hero-container--sharp-price .hero-content {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  max-width: none;
  width: 100%;
  height: 31.5em;
  background: radial-gradient(ellipse farthest-corner at center, #7db8c2 0%, #39656d 100%);
}
.hero-container.hero-container--sharp-price .hero-content.with-counter {
  height: 34.5em;
}
.hero-container.hero-container--sharp-price .hero-content img {
  position: absolute;
  bottom: 0;
}
.hero-container.hero-container--sharp-price .hero-content .image-man {
  left: 50%;
  transform: translateX(calc(-50% + 10em));
  width: 20em;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container,
.hero-container.hero-container--sharp-price .hero-content .counter {
  position: absolute;
  left: 50%;
  transform: translateX(calc(-50% - 8em));
}
.hero-container.hero-container--sharp-price .hero-content .offer-container {
  top: 8.5em;
  width: 16em;
  height: 16em;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container .image-sign {
  width: 100%;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h1 {
  position: absolute;
  left: 50%;
  bottom: 8rem;
  transform: translate(-50%, 50%);
  text-align: center;
  hyphens: manual;
  margin: 0 auto;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h1 > span {
  margin: 0 auto;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h1 .hero-post-speed,
.hero-container.hero-container--sharp-price .hero-content .offer-container h1 .hero-post-price {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h1 .hero-speed {
  font-size: 1.8em;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h1 .hero-post-speed {
  font-size: 0.95em;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h1 .hero-post-price {
  font-size: 0.9em;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h1 .price-comma-dash {
  position: absolute;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h1 .price-md {
  right: unset;
  left: 100%;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container.offer-container--99 h1 {
  font-size: 1em;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container.offer-container--99 h1 .hero-price {
  font-size: 10em;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container.offer-container--199 h1 {
  font-size: 1.2em;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container.offer-container--199 h1 .hero-price {
  margin-left: -0.07em;
  font-size: 8em;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container div {
  position: absolute;
  top: -1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(0deg, #cccccc 0%, #ffffff 60%);
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h2 {
  padding: 0.3em 0 0;
  font-size: 1.4em;
  color: #be1421;
}
.hero-container.hero-container--sharp-price .hero-content .offer-container h2 .hiper-text-stroke::after {
  background: linear-gradient(77.5deg, rgba(190, 20, 33, 0) 0, #be1421 80%);
}
.hero-container.hero-container--sharp-price .hero-content .counter {
  top: 25.5em;
  margin: 0;
  padding: 0;
  border-radius: 0;
  font-size: 0.8em;
  text-align: center;
  white-space: nowrap;
}
@media (max-width: 600px) {
  .hero-container.hero-container--sharp-price .hero-content .offer-container {
    transform: translateX(calc(-50% - 6em));
  }
  .hero-container.hero-container--sharp-price .hero-content .counter {
    transform: translateX(-50%);
  }
  .hero-container.hero-container--sharp-price .hero-content .image-man {
    transform: translateX(calc(-50% + 8em));
  }
}
@media (max-width: 380px) {
  .hero-container.hero-container--sharp-price .hero-content {
    height: 29.5em;
  }
  .hero-container.hero-container--sharp-price .hero-content.with-counter {
    height: 34.5em;
  }
  .hero-container.hero-container--sharp-price .hero-content .offer-container {
    transform: translateX(-50%);
  }
  .hero-container.hero-container--sharp-price .hero-content .image-man {
    display: none;
  }
}
.hero-container.hero-container--sharp-price .address-wrap h2 {
  font-size: 1.6em;
}
.hero-container.hero-container--sharp-price .hero-bottom {
  margin-top: -1.75em;
  width: 100%;
  background-color: #4b848e;
}
.hero-container.hero-container--mvfp .hero-background, .hero-container.hero-container--mvfp-generic .hero-background, .hero-container.hero-container--hiperligfiber .hero-background {
  top: 50%;
  left: 50%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.hero-container.hero-container--mvfp .hero-content, .hero-container.hero-container--mvfp-generic .hero-content, .hero-container.hero-container--hiperligfiber .hero-content {
  display: flex;
}
.hero-container.hero-container--mvfp .hero-content h1, .hero-container.hero-container--mvfp-generic .hero-content h1, .hero-container.hero-container--hiperligfiber .hero-content h1 {
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
}
.hero-container.hero-container--mvfp .hero-content .stamp-container img, .hero-container.hero-container--mvfp-generic .hero-content .stamp-container img, .hero-container.hero-container--hiperligfiber .hero-content .stamp-container img {
  max-width: 10em;
  max-height: 10em;
  margin-right: 2em;
  filter: drop-shadow(var(--box-shadow));
}
@media (min-width: 1921px) {
  .hero-container.hero-container--mvfp .hero-background::after, .hero-container.hero-container--mvfp-generic .hero-background::after, .hero-container.hero-container--hiperligfiber .hero-background::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: -1px;
    right: -1px;
    bottom: 0;
    width: calc(100% + 2px);
    height: 100%;
    pointer-events: none;
    background: linear-gradient(90deg, #42747d 0%, rgba(66, 116, 125, 0) 10%, rgba(66, 116, 125, 0) 90%, #42747d 100%);
  }
}
@media (min-width: 801px) and (max-width: 1920px) {
  .hero-container.hero-container--mvfp .hero-background::after, .hero-container.hero-container--mvfp-generic .hero-background::after, .hero-container.hero-container--hiperligfiber .hero-background::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: calc(100% + 2px);
    pointer-events: none;
    background: linear-gradient(0deg, #42747d 0%, rgba(66, 116, 125, 0) 10%, rgba(66, 116, 125, 0) 90%, #42747d 100%);
  }
}
@media (min-width: 801px) {
  .hero-container.hero-container--mvfp, .hero-container.hero-container--mvfp-generic, .hero-container.hero-container--hiperligfiber {
    gap: 0.5em;
    padding-bottom: 0.5em;
  }
  .hero-container.hero-container--mvfp .hero-background, .hero-container.hero-container--mvfp-generic .hero-background, .hero-container.hero-container--hiperligfiber .hero-background {
    transform: translate(-50%, -50%);
    aspect-ratio: 16/9;
    max-width: 1920px;
  }
  .hero-container.hero-container--mvfp .hero-content, .hero-container.hero-container--mvfp-generic .hero-content, .hero-container.hero-container--hiperligfiber .hero-content {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1em;
  }
  .hero-container.hero-container--mvfp .hero-content h1, .hero-container.hero-container--mvfp-generic .hero-content h1, .hero-container.hero-container--hiperligfiber .hero-content h1 {
    padding-right: 2.5em;
    font-size: 0.9em;
  }
  .hero-container.hero-container--mvfp .hero-content h1 .hero-speed, .hero-container.hero-container--mvfp-generic .hero-content h1 .hero-speed, .hero-container.hero-container--hiperligfiber .hero-content h1 .hero-speed {
    font-size: 4em;
  }
  .hero-container.hero-container--mvfp .hero-content h1 .hero-pre-price, .hero-container.hero-container--mvfp-generic .hero-content h1 .hero-pre-price, .hero-container.hero-container--hiperligfiber .hero-content h1 .hero-pre-price {
    font-size: 1.6em;
  }
  .hero-container.hero-container--mvfp .hero-content h1 .hero-price, .hero-container.hero-container--mvfp-generic .hero-content h1 .hero-price, .hero-container.hero-container--hiperligfiber .hero-content h1 .hero-price {
    font-size: 14em;
  }
  .hero-container.hero-container--mvfp .hero-content h1 .hero-price.hero-price--99, .hero-container.hero-container--mvfp-generic .hero-content h1 .hero-price.hero-price--99, .hero-container.hero-container--hiperligfiber .hero-content h1 .hero-price.hero-price--99 {
    margin-left: -0.05em;
    font-size: 15em;
  }
}
@media (max-width: 800px) {
  .hero-container.hero-container--mvfp, .hero-container.hero-container--mvfp-generic, .hero-container.hero-container--hiperligfiber {
    padding-top: 7.5em;
  }
  .hero-container.hero-container--mvfp .hero-background::after, .hero-container.hero-container--mvfp-generic .hero-background::after, .hero-container.hero-container--hiperligfiber .hero-background::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: calc(100% + 1px);
    pointer-events: none;
    background: linear-gradient(180deg, #42747d 0%, rgba(66, 116, 125, 0) 6em, rgba(66, 116, 125, 0) 100%);
  }
  .hero-container.hero-container--mvfp .hero-background, .hero-container.hero-container--mvfp-generic .hero-background, .hero-container.hero-container--hiperligfiber .hero-background {
    aspect-ratio: 9/16;
    max-width: 1080px;
  }
  .hero-container.hero-container--mvfp .hero-content, .hero-container.hero-container--mvfp-generic .hero-content, .hero-container.hero-container--hiperligfiber .hero-content {
    justify-content: center;
    align-items: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .hero-container.hero-container--mvfp .hero-content h1, .hero-container.hero-container--mvfp-generic .hero-content h1, .hero-container.hero-container--hiperligfiber .hero-content h1 {
    width: fit-content;
    text-align: center;
  }
  .hero-container.hero-container--mvfp .hero-content h1 > span, .hero-container.hero-container--mvfp-generic .hero-content h1 > span, .hero-container.hero-container--hiperligfiber .hero-content h1 > span {
    margin-left: auto;
    margin-right: auto;
  }
  .hero-container.hero-container--mvfp .hero-content h1 .hero-price.hero-price--99, .hero-container.hero-container--mvfp-generic .hero-content h1 .hero-price.hero-price--99, .hero-container.hero-container--hiperligfiber .hero-content h1 .hero-price.hero-price--99 {
    font-size: 16em;
  }
}
@media (max-width: 800px) and (max-width: 520px) {
  .hero-container.hero-container--mvfp .hero-content h1 .hero-price.hero-price--99, .hero-container.hero-container--mvfp-generic .hero-content h1 .hero-price.hero-price--99, .hero-container.hero-container--hiperligfiber .hero-content h1 .hero-price.hero-price--99 {
    font-size: calc((13vw * 7.5) * var(--fz-multiplier));
  }
}
@media (max-width: 800px) {
  .hero-container.hero-container--mvfp .hero-content .stamp-container img, .hero-container.hero-container--mvfp-generic .hero-content .stamp-container img, .hero-container.hero-container--hiperligfiber .hero-content .stamp-container img {
    max-width: 10em;
  }
}
@media (max-width: 800px) and (min-width: 521px) {
  .hero-container.hero-container--mvfp .hero-background, .hero-container.hero-container--mvfp-generic .hero-background, .hero-container.hero-container--hiperligfiber .hero-background {
    transform: translate(-50%, -50%);
  }
  .hero-container.hero-container--mvfp .hero-content, .hero-container.hero-container--mvfp-generic .hero-content, .hero-container.hero-container--hiperligfiber .hero-content {
    flex-direction: row;
    gap: 2.5em;
  }
  .hero-container.hero-container--mvfp .hero-content .stamp-container img, .hero-container.hero-container--mvfp-generic .hero-content .stamp-container img, .hero-container.hero-container--hiperligfiber .hero-content .stamp-container img {
    width: 10em;
    height: 10em;
  }
}
@media (max-width: 800px) and (max-width: 520px) {
  .hero-container.hero-container--mvfp .hero-background, .hero-container.hero-container--mvfp-generic .hero-background, .hero-container.hero-container--hiperligfiber .hero-background {
    top: unset;
    bottom: 0;
    transform: translateX(-50%);
  }
  .hero-container.hero-container--mvfp .hero-content, .hero-container.hero-container--mvfp-generic .hero-content, .hero-container.hero-container--hiperligfiber .hero-content {
    flex-direction: column;
    gap: 1em;
  }
  .hero-container.hero-container--mvfp .hero-content .stamp-container img, .hero-container.hero-container--mvfp-generic .hero-content .stamp-container img, .hero-container.hero-container--hiperligfiber .hero-content .stamp-container img {
    margin-right: 0;
  }
}
.hero-container.hero-container--fibia-fiber .hero-content .hero-price-disclaimer {
  line-height: 1.2;
}
@media (min-width: 801px) {
  .hero-container.hero-container--mvfp .hero-background {
    background-image: url("../images/campaigns/mvfp/hero-mvfp-desktop.jpg");
  }
}
@media (max-width: 800px) {
  .hero-container.hero-container--mvfp .hero-background {
    background-image: url("../images/campaigns/mvfp/hero-mvfp-mobile.jpg");
  }
}
@media (max-width: 800px) and (min-width: 521px) {
  .hero-container.hero-container--mvfp .hero-content {
    margin-bottom: 4em;
  }
}
@media (max-width: 800px) and (max-width: 520px) {
  .hero-container.hero-container--mvfp .hero-content {
    margin-bottom: 0;
  }
}
@media (min-width: 801px) {
  .hero-container.hero-container--mvfp-generic .hero-background {
    background-image: url("../images/campaigns/mvfp/hero-mvfp-generic-desktop.jpg");
  }
}
@media (max-width: 800px) {
  .hero-container.hero-container--mvfp-generic .hero-background {
    background-image: url("../images/campaigns/mvfp/hero-mvfp-generic-tablet.jpg");
  }
}
@media (max-width: 520px) {
  .hero-container.hero-container--mvfp-generic .hero-background {
    background-image: url("../images/campaigns/mvfp/hero-mvfp-generic-mobile.jpg");
  }
}
@media (max-width: 520px) and (min-width: 521px) {
  .hero-container.hero-container--mvfp-generic .hero-content {
    margin-bottom: 4em;
  }
}
@media (max-width: 520px) and (max-width: 520px) {
  .hero-container.hero-container--mvfp-generic .hero-content {
    margin-bottom: 0;
  }
}
.hero-container.hero-container--hiperligfiber.tdcnet-fpp .stamp-container .tdcnet-fpp {
  display: block;
  filter: drop-shadow(var(--box-shadow));
  pointer-events: none;
}
@media (min-width: 801px) {
  .hero-container.hero-container--hiperligfiber .hero-background-wrapper::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(75, 132, 142, 0.75) 8em, rgba(75, 132, 142, 0) 14em);
  }
  .hero-container.hero-container--hiperligfiber .hero-background {
    background-image: url("../images/campaigns/hiperligfiber/hero-hiper-lig-fiber-desk.jpg");
  }
  .hero-container.hero-container--hiperligfiber.tdcnet-fpp .hero-content {
    flex-direction: column-reverse;
    margin-bottom: 0;
    align-items: end;
  }
  .hero-container.hero-container--hiperligfiber.tdcnet-fpp .stamp-container {
    margin-right: 2.5em;
  }
  .hero-container.hero-container--hiperligfiber.tdcnet-fpp .stamp-container .tdcnet-fpp {
    height: 6.5em;
    transform: scale(1.6);
  }
}
@media (max-width: 800px) {
  .hero-container.hero-container--hiperligfiber .hero-background {
    background-image: url("../images/campaigns/hiperligfiber/hero-hiper-lig-fiber-mob.jpg");
    opacity: 0.75;
  }
  .hero-container.hero-container--hiperligfiber .hero-background::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(0deg, #4b848e 0%, rgba(75, 132, 142, 0) 50%);
  }
  .hero-container.hero-container--hiperligfiber.tdcnet-fpp .hero-content {
    flex-direction: column-reverse;
    gap: 0;
    margin-bottom: 0;
  }
  .hero-container.hero-container--hiperligfiber.tdcnet-fpp .hero-content .stamp-container .tdcnet-fpp {
    transform: scale(1.6);
  }
}
@media (max-width: 800px) and (min-width: 521px) {
  .hero-container.hero-container--hiperligfiber.tdcnet-fpp .hero-content .stamp-container .tdcnet-fpp {
    height: 6em;
  }
}
@media (max-width: 800px) and (max-width: 520px) {
  .hero-container.hero-container--hiperligfiber.tdcnet-fpp .hero-content .stamp-container {
    margin-bottom: -1em;
  }
  .hero-container.hero-container--hiperligfiber.tdcnet-fpp .hero-content .stamp-container .tdcnet-fpp {
    height: 22vw;
  }
}
@media (min-width: 801px) {
  .hero-container.hero-container--gradient .hero-background-wrapper::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(75, 132, 142, 0.75) 8em, rgba(75, 132, 142, 0) 14em);
  }
}
@media (max-width: 800px) {
  .hero-container.hero-container--gradient .hero-background::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(0deg, #4b848e 0%, rgba(75, 132, 142, 0) 50%);
  }
}
.hero-container.hero-container--5g-internet .hero-background {
  top: 50%;
  left: 50%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.hero-container.hero-container--5g-internet .hero-background::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
  width: calc(100% + 2px);
  height: 100%;
  pointer-events: none;
}
.hero-container.hero-container--5g-internet .hero-content h1 {
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-unlimited-data,
.hero-container.hero-container--5g-internet .hero-content h1 .hero-pre-price,
.hero-container.hero-container--5g-internet .hero-content h1 .hero-price .price-md,
.hero-container.hero-container--5g-internet .hero-content h1 .hero-post-price {
  line-height: 1.1;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-unlimited-data,
.hero-container.hero-container--5g-internet .hero-content h1 .hero-post-price {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  text-transform: none;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-unlimited-data {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  padding: 0.25em 0.75em;
  border-radius: 2em;
  background-color: #ffffff;
  color: #42747d;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-pre-price,
.hero-container.hero-container--5g-internet .hero-content h1 .hero-price .price-md,
.hero-container.hero-container--5g-internet .hero-content h1 .hero-post-price {
  font-weight: 400;
  font-variation-settings: "wght" 400;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-pre-price {
  padding: 0.5em 0;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-price .price-md {
  text-align: left;
  text-transform: none;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-unlimited-data {
  font-size: 1em;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-pre-price {
  font-size: 1.4em;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-price {
  font-size: 12em;
}
.hero-container.hero-container--5g-internet .hero-content h1 .hero-post-price {
  font-size: 1.2em;
}
@media (min-width: 801px) {
  .hero-container.hero-container--5g-internet {
    gap: 0.5em;
    padding-top: 7em;
    padding-bottom: 0.5em;
  }
  .hero-container.hero-container--5g-internet .hero-background-wrapper::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: calc(100% + 1px);
    pointer-events: none;
    background: linear-gradient(0deg, #42747d 6em, rgba(66, 116, 125, 0) 12em, rgba(66, 116, 125, 0) 100%);
  }
  .hero-container.hero-container--5g-internet .hero-background {
    transform: translate(-50%, -50%);
    aspect-ratio: 16/9;
    max-width: 1920px;
    width: unset;
    height: 100%;
    background-image: url("../images/campaigns/mbb/mbb-hero-desktop.jpg");
  }
  .hero-container.hero-container--5g-internet .hero-background::after {
    background: linear-gradient(90deg, #42747d 0%, rgba(66, 116, 125, 0) 10%, rgba(66, 116, 125, 0) 90%, #42747d 100%);
  }
  .hero-container.hero-container--5g-internet .hero-content {
    align-items: flex-end;
  }
  .hero-container.hero-container--5g-internet .hero-content h1 {
    width: 18em;
    font-size: 1em;
  }
  .hero-container.hero-container--5g-internet .hero-content h1 .hero-price .price-comma-dash {
    letter-spacing: -0.125em;
  }
  .hero-container.hero-container--5g-internet .hero-content h1 .hero-price .price-md {
    display: inline-block;
    position: relative;
    top: unset;
    right: unset;
    left: -1.8em;
    font-size: 0.1em;
  }
}
@media (min-width: 801px) and (max-width: 960px) {
  .hero-container.hero-container--5g-internet .hero-content h1 {
    font-size: 0.8em;
  }
}
@media (max-width: 800px) {
  .hero-container.hero-container--5g-internet {
    padding-top: 7em;
  }
  .hero-container.hero-container--5g-internet .hero-background {
    top: calc(50% - 5em);
    transform: translate(-50%, -50%);
    aspect-ratio: 9/16;
    max-width: 800px;
    background-image: url("../images/campaigns/mbb/mbb-hero-mobile.jpg");
  }
  .hero-container.hero-container--5g-internet .hero-background::after {
    background: linear-gradient(0deg, #42747d 0, rgba(66, 116, 125, 0) 8em);
  }
  .hero-container.hero-container--5g-internet .hero-content {
    padding-left: 2em;
    padding-right: 2em;
  }
  .hero-container.hero-container--5g-internet .hero-content h1 {
    width: 18em;
    font-size: 1em;
  }
  .hero-container.hero-container--5g-internet .hero-content h1 .hero-pre-price,
.hero-container.hero-container--5g-internet .hero-content h1 .hero-price {
    text-shadow: var(--box-shadow);
  }
  .hero-container.hero-container--5g-internet .hero-content h1 .hero-price .price-md {
    top: 20%;
    right: -2.6em;
    font-size: 0.09em;
  }
  .hero-container.hero-container--5g-internet.ab-test-container-b .hero-background-wrapper {
    background: radial-gradient(circle, #4b848e 25%, #0f4b56 100%);
  }
  .hero-container.hero-container--5g-internet.ab-test-container-b .hero-background-wrapper::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: calc(100% + 1px);
    pointer-events: none;
    background: linear-gradient(0deg, #42747d 0%, rgba(66, 116, 125, 0) 25%);
  }
  .hero-container.hero-container--5g-internet.ab-test-container-b .hero-background {
    display: none;
  }
  .hero-container.hero-container--5g-internet.ab-test-container-b .hero-content {
    align-items: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .hero-container.hero-container--5g-internet.ab-test-container-b .hero-content h1 {
    text-align: center;
  }
  .hero-container.hero-container--5g-internet.ab-test-container-b .hero-content h1 > span {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 521px) {
  .hero-container .hero-content {
    max-width: calc(var(--hero-max-width) + 3rem);
    padding: 0 2.5em;
    margin-bottom: 2.5em;
  }
  .hero-container .hero-content .hero-pre-speed,
.hero-container .hero-content .hero-pre-price {
    font-size: 2em;
  }
  .hero-container .hero-content .hero-bullets {
    font-size: 1.2em;
  }
  .hero-container .hero-content .hero-speed {
    font-size: 5em;
  }
  .hero-container .hero-content .hero-price {
    font-size: 13em;
  }
  .hero-container .hero-content .hero-price.hero-price-long {
    font-size: 8em;
  }
  .hero-container .hero-content .hero-price .price-md {
    font-weight: 300;
    font-variation-settings: "wght" 300;
  }
  .hero-container .hero-content .hero-price-disclaimer {
    font-size: 1.6em;
    padding-top: 1.5em;
  }
  .hero-container .hero-content .hero-price-disclaimer.hero-price-disclaimer--small {
    font-size: 1em;
  }
  .hero-container.linux .hero-content .hero-price-disclaimer, .hero-container.mac .hero-content .hero-price-disclaimer {
    padding-top: 0.5em;
  }
  .hero-container .address-wrap {
    max-width: calc(var(--hero-max-width) + 3rem);
  }
  .hero-container .address-wrap h2 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 0.5em;
    font-size: 1.4em;
  }
  .hero-container .address-wrap .address-widget:not(.sticky) {
    width: calc(100% - 3em);
  }
  .hero-container .conditions {
    max-width: calc(var(--hero-max-width) + 3rem);
    padding: 0 1.5rem;
  }
}
@media (min-width: 521px) and (max-width: 1750px) {
  .hero-container .hero-content h1 {
    font-size: 0.9em;
  }
}
@media (min-width: 521px) and (max-width: 1650px) {
  .hero-container {
    padding-top: 8em;
    gap: 0.5em;
  }
  .hero-container .hero-content {
    margin-bottom: 2em;
  }
  .hero-container .hero-content h1 {
    font-size: 0.8em;
  }
}
@media (min-width: 521px) {
  .hero-container.hero-container--usp {
    padding-top: 9em;
  }
  .hero-container.hero-container--usp .hero-content h1 {
    font-size: 2em;
  }
  .hero-container.hero-container--usp .address-wrap {
    margin: 1em 0;
  }
  .hero-container.hero-container--usp .address-wrap h2 {
    margin-bottom: 0.25rem;
  }
  .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow {
    width: 33px;
  }
  .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow::before, .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow::after {
    width: 24px;
    height: 4px;
    border-radius: 2px;
  }
  .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow::before {
    transform: translate(-3px, 0.6em) rotate(45deg);
  }
  .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow::after {
    transform: translate(12px, 0.6em) rotate(-45deg);
  }
  .hero-container.hero-container--usp .stamp {
    font-size: 0.9em;
  }
}
@media (min-width: 521px) {
  .hero-container.hero-container--sharp-price .hero-content .offer-container h1 {
    line-height: 0.9;
  }
  .hero-container.hero-container--sharp-price .hero-content .offer-container h1 .hero-post-price {
    margin-top: -0.8em;
  }
  .hero-container.hero-container--sharp-price .hero-content .offer-container div {
    right: -4em;
    width: 7em;
    height: 7em;
  }
  .hero-container.hero-container--sharp-price .address-wrap {
    margin-top: -4.5em;
  }
  .hero-container.hero-container--sharp-price .hero-bottom {
    padding: calc(1em + 1.75em) 0 1em;
  }
}
@media (max-width: 520px) {
  .hero-container .hero-content {
    max-width: calc(var(--hero-max-width) + 2rem);
    padding: 0 1.5em;
    margin-bottom: 1.5em;
    --fz-multiplier: .5;
  }
  .hero-container .hero-content .hero-pre-speed,
.hero-container .hero-content .hero-pre-price {
    font-size: calc((2vw * 6.25) * var(--fz-multiplier));
  }
  .hero-container .hero-content .hero-bullets {
    font-size: calc((1.2vw * 6.25) * var(--fz-multiplier));
  }
  .hero-container .hero-content .hero-speed {
    font-size: calc((5vw * 6.25) * var(--fz-multiplier));
  }
  .hero-container .hero-content .hero-price {
    font-size: calc((13vw * 6.25) * var(--fz-multiplier));
  }
  .hero-container .hero-content .hero-price.hero-price-long {
    font-size: calc((8vw * 6.25) * var(--fz-multiplier));
  }
  .hero-container .hero-content .hero-price-disclaimer {
    padding-top: 0.6em;
    font-size: calc((1.6vw * 6.25) * var(--fz-multiplier));
  }
  .hero-container .hero-content .hero-price-disclaimer.hero-price-disclaimer--small {
    font-size: calc((1vw * 6.25) * var(--fz-multiplier));
  }
  .hero-container.windows .hero-content .hero-price-disclaimer {
    padding-top: 1.5em;
  }
  .hero-container .address-wrap {
    max-width: calc(var(--hero-max-width) + 2rem);
  }
  .hero-container .address-wrap h2 {
    font-size: 1em;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.25em;
  }
  .hero-container .address-wrap .address-widget:not(.sticky) {
    width: calc(100% - 2em);
  }
  .hero-container .conditions {
    max-width: calc(var(--hero-max-width) + 2rem);
    padding: 0 1rem;
  }
  .hero-container.hero-container--video {
    padding-top: 9em;
    gap: 0.5em;
  }
  .hero-container.hero-container--video .hero-content {
    margin-bottom: 2.5em;
  }
  .hero-container.hero-container--usp {
    padding-top: 8em;
  }
  .hero-container.hero-container--usp .hero-content h1 {
    font-size: 1.6em;
  }
  .hero-container.hero-container--usp .address-wrap h2 {
    font-size: 2.6em;
    margin-bottom: -0.15em;
  }
  .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow {
    width: 26px;
  }
  .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow::before, .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow::after {
    width: 18px;
    height: 2px;
    border-radius: 1px;
  }
  .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow::before {
    transform: translate(-3px, 0.6em) rotate(45deg);
  }
  .hero-container.hero-container--usp .address-wrap h2 .scroll-down-arrow::after {
    transform: translate(9px, 0.6em) rotate(-45deg);
  }
  .hero-container.hero-container--sharp-price .hero-content .offer-container h1 {
    line-height: 0.95;
  }
  .hero-container.hero-container--sharp-price .hero-content .offer-container h1 .hero-post-price {
    margin-top: -1em;
  }
  .hero-container.hero-container--sharp-price .hero-content .offer-container div {
    right: -3em;
    width: 6em;
    height: 6em;
  }
  .hero-container.hero-container--sharp-price .address-wrap {
    margin-top: -4em;
  }
  .hero-container.hero-container--sharp-price .hero-bottom {
    padding: calc(.5em + 1.75em) 0 0.5em;
  }
}
@media (min-height: 900px) {
  .hero-container.hero-container--usp .hero-content {
    padding-top: 5em;
  }
  .hero-container.hero-container--usp .address-wrap {
    margin-top: 0.5em;
    padding-bottom: 5em;
  }
}

/* ab-hero-test */
.hero-container.hero-ab-test .hero-background img {
  max-width: 122em;
}
@media (max-width: 1030px) {
  .hero-container.hero-ab-test .hero-background img {
    transform: translateX(-10%);
  }
}
@media (max-width: 768px) {
  .hero-container.hero-ab-test .hero-background img {
    transform: translateX(-10%) translateY(-40px);
    max-width: 95em;
  }
}
@media (max-width: 520px) {
  .hero-container.hero-ab-test .hero-background img {
    transform: translateX(-90px) translateY(62px);
  }
}
.hero-container.hero-ab-test .hero-speed {
  font-size: 3em;
  margin-left: -0.04em;
  margin-bottom: 0.3em;
  font-weight: 300;
}
@media (max-width: 520px) {
  .hero-container.hero-ab-test .hero-speed {
    font-size: 12vw;
  }
}
.hero-container.hero-ab-test .hero-price {
  font-size: 10em;
  margin-top: 0.2em;
}
@media (max-width: 520px) {
  .hero-container.hero-ab-test .hero-price {
    font-size: 8em;
  }
}
.hero-container.hero-ab-test .hero-bullets {
  font-weight: 400;
  font-variation-settings: "wght" 400;
}
@media (min-width: 401px) {
  .hero-container.hero-ab-test .hero-bullets {
    font-size: 1em;
  }
}
@media (max-width: 400px) {
  .hero-container.hero-ab-test .hero-bullets {
    font-size: 0.9em;
  }
}
.hero-container.hero-ab-test .hero-bullets .icon {
  display: inline-block;
  position: relative;
  top: 0.45em;
  width: 1.5em;
  height: 1.5em;
  color: transparent;
  fill: #ffffff;
}
.hero-container.hero-ab-test .stamp {
  transform: translate(1em, -50%);
  top: 35%;
  left: 50%;
}
@media (max-width: 768px) {
  .hero-container.hero-ab-test .stamp {
    top: 57%;
    left: 35%;
  }
}
@media (max-width: 520px) {
  .hero-container.hero-ab-test .stamp {
    left: 45%;
  }
}
@media (max-width: 350px) {
  .hero-container.hero-ab-test .stamp {
    display: none;
  }
}

@media (min-width: 521px) {
  .show-manual-address .hero-container {
    gap: 1em;
  }
  .show-manual-address .hero-container .hero-content {
    margin-bottom: 3em;
  }
}

.page-campaign-fibernet {
  --line-display: inline;
  --heading-font-size: 2.8125em;
  --content-justify-content: space-between;
  --splash-bottom: 1.4em;
  --splash-right: auto;
  --splash-left: auto;
  --splash-font-size: 2.1875em;
}
@media (min-width: 450px) {
  .page-campaign-fibernet {
    --heading-font-size: 3.125em;
    --splash-bottom: 1.3em;
    --splash-font-size: 2.5em;
  }
}
@media (min-width: 620px) {
  .page-campaign-fibernet {
    --line-display: block;
    --heading-font-size: 3.75em;
    --content-justify-content: flex-end;
    --splash-bottom: .65em;
    --splash-right: .65em;
    --splash-left: auto;
  }
}
@media (min-width: 700px) {
  .page-campaign-fibernet {
    --heading-font-size: 4.0625em;
  }
}
.page-campaign-fibernet .fiber-network-hero {
  background-image: url("../images/campaigns/fibernet/fibernet-hero.jpg");
  background-size: cover;
  background-position: center;
  min-height: 25em;
  color: white;
}
.page-campaign-fibernet .fiber-network-hero .content {
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: var(--content-justify-content);
  min-height: 25em;
}
.page-campaign-fibernet .fiber-network-hero .line {
  display: var(--line-display);
}
.page-campaign-fibernet .fiber-network-hero .heading {
  color: white;
  font-size: var(--heading-font-size);
  text-align: left;
  line-height: 1;
  font-style: italic;
  color: white;
  text-shadow: 0 0.1em 0.25em rgba(190, 0, 90, 0.75);
  letter-spacing: var(--letter-spacing-big);
}
.page-campaign-fibernet .fiber-network-hero .conditions {
  width: 100%;
  font-size: 0.8em;
  text-shadow: 0 0.15em 0.5em rgba(0, 0, 0, 0.75);
}
.page-campaign-fibernet .fiber-network-hero .splash-fiber-network-before-now {
  position: absolute;
  bottom: var(--splash-bottom);
  right: var(--splash-right);
  left: var(--splash-left);
  background-color: #ed135a;
  border-radius: 0.1875em;
  font-size: var(--splash-font-size);
  font-style: italic;
  font-weight: 300;
  line-height: 1.2em;
  padding: 0.3em 0.8em 0.1em 0.8em;
  text-align: center;
  letter-spacing: var(--letter-spacing-big);
}
.page-campaign-fibernet .fiber-network-hero .splash-fiber-network-before-now .strikethrough-price:before {
  border-bottom-color: #303a4b;
  border-bottom-width: 0.1em;
  width: 110%;
  right: -5%;
  transform: skewY(-10deg) translate(0%, -50%);
}
.page-campaign-fibernet .section-reasons {
  padding-bottom: 1.875em;
}
.page-campaign-fibernet .section-white {
  padding-bottom: 1.875em;
}
.section-spies-beach {
  display: flex;
  background-image: url("../images/campaigns/fibernet/palms.jpg");
  background-size: cover;
  background-position: center;
  min-height: 28.125em;
  color: white;
}
.section-spies-beach .content {
  margin-bottom: 1em;
  background: rgba(237, 19, 90, 0.8);
  padding: 0.5em 1em;
}
.section-spies-beach .heading {
  color: inherit;
  padding: 0;
}
.section-spies-beach p {
  margin: 0;
  padding: 0;
  font-size: 1.2em;
}

.carousel-item .spies-splash,
.spies-splash {
  --spies-splash-position: absolute;
  --spies-splash-width: 55%;
  --spies-splash-font-size: .7em;
  --spies-splash-top: .5em;
  --spies-splash-right: .5em;
  --spies-line-display: inline;
  --spies-palms-display: none;
  --spies-logo: 4em;
  position: var(--spies-splash-position);
  width: var(--spies-splash-width);
  top: var(--spies-splash-top);
  right: var(--spies-splash-right);
  border-radius: 0.3em;
  background: #303a4b;
  color: white;
  display: flex;
  font-size: var(--spies-splash-font-size);
  border-bottom: 0;
}
@media (min-width: 620px) {
  .carousel-item .spies-splash,
.spies-splash {
    --spies-splash-position: absolute;
    --spies-splash-width: 16em;
    --spies-splash-font-size: .9em;
    --spies-splash-top: 1em;
    --spies-splash-right: 1em;
    --spies-line-display: block;
    --spies-logo: 5.25em;
  }
}
@media (min-width: 700px) {
  .carousel-item .spies-splash,
.spies-splash {
    --spies-palms-display: block;
    --spies-splash-width: 22.75em;
  }
}
@media (min-width: 1441px) {
  .carousel-item .spies-splash,
.spies-splash {
    --spies-splash-font-size: 1.05em;
    --spies-splash-top: 2em;
    --spies-splash-right: 2em;
  }
}
.carousel-item .spies-splash .line,
.spies-splash .line {
  display: var(--spies-line-display);
}
.carousel-item .spies-splash .palms,
.spies-splash .palms {
  display: var(--spies-palms-display);
  width: 6em;
  background-image: url("../images/campaigns/fibernet/palms.jpg");
  background-size: cover;
  background-position: left;
  background-clip: padding-box;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.carousel-item .spies-splash .not-palms,
.spies-splash .not-palms {
  font-style: italic;
  padding: 0.75em 1em;
}
.carousel-item .spies-splash .not-palms h3,
.spies-splash .not-palms h3 {
  margin: 0;
  padding: 0 0 0.5em 0;
  font-size: 1.4em;
  text-transform: none;
  text-align: left;
  font-weight: 200;
}
.carousel-item .spies-splash .not-palms p,
.spies-splash .not-palms p {
  padding: 0;
  margin: 0;
  font-size: 0.8em;
}
.carousel-item .spies-splash .not-palms .spies-logo,
.spies-splash .not-palms .spies-logo {
  max-width: var(--spies-logo);
  display: block;
  position: absolute;
  right: 1em;
  bottom: 0.5em;
}

.fibernet-insert {
  background-image: url("../images/campaigns/fibernet/fibernet-hero.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding-top: 8em;
  line-height: 1;
  text-shadow: 0 0.15em 0.5em rgba(0, 0, 0, 0.75);
}
.fibernet-insert .content {
  text-align: center;
}
.fibernet-insert .heading {
  color: inherit;
  padding: 0;
  margin: 0;
}
.fibernet-insert p {
  font-size: 1.4em;
  padding: 0;
  margin: 0;
}
.fibernet-insert .button {
  text-shadow: none;
  margin: 0.5em 0 0 0;
}

.fibernet-hero,
.carousel-item.fibernet-hero {
  --hero-background: #3f818b;
  --heading-font-size: 2.2em;
  --heading-padding-bottom: 0;
  --heading-prefix-font-size: .4em;
  --conditions-font-size: .7em;
  --badge-size: 6em;
  --badge-top: 1em;
  --badge-right: 1em;
  --speed-price-margin: 0;
  --speed-price-font-size: 2.2em;
  --blue-price-font-size: 2em;
  --blue-price-display: block;
  --blue-price-line-height: 1;
  background: var(--hero-background);
  background-size: cover;
  background-position: center;
}
@media (min-width: 450px) {
  .fibernet-hero,
.carousel-item.fibernet-hero {
    --hero-background: url("../images/campaigns/loyalty_group/hero-900.jpg");
    --badge-size: 8em;
    --badge-top: 2em;
    --badge-right: 2em;
    --speed-price-margin: 0;
    --speed-price-font-size: 2.5em;
  }
}
@media (min-width: 680px) {
  .fibernet-hero,
.carousel-item.fibernet-hero {
    --badge-size: 10em;
    --heading-padding-bottom: .3125em;
  }
}
@media (min-width: 820px) {
  .fibernet-hero,
.carousel-item.fibernet-hero {
    --hero-background: url("../images/campaigns/loyalty_group/hero-2000.jpg");
    --speed-price-font-size: 2.8em;
    --speed-price-margin: .75 0 0 0;
    --badge-size: 13em;
  }
}
@media (min-width: 3000px) {
  .fibernet-hero,
.carousel-item.fibernet-hero {
    --hero-background: url("../images/campaigns/loyalty_group/hero.jpg");
  }
}
.fibernet-hero .content,
.carousel-item.fibernet-hero .content {
  position: relative;
  width: 100%;
  height: 100%;
}
.fibernet-hero .left,
.carousel-item.fibernet-hero .left {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: flex-end;
  text-shadow: 0 0.025em 0.175em rgba(0, 0, 0, 0.5);
}
.fibernet-hero .left, .fibernet-hero .left *,
.carousel-item.fibernet-hero .left,
.carousel-item.fibernet-hero .left * {
  text-align: left;
}
.fibernet-hero .heading,
.carousel-item.fibernet-hero .heading {
  font-size: var(--heading-font-size);
  padding-bottom: var(--heading-padding-bottom);
}
.fibernet-hero .heading .line,
.carousel-item.fibernet-hero .heading .line {
  display: block;
}
.fibernet-hero .heading .prefix,
.carousel-item.fibernet-hero .heading .prefix {
  font-size: var(--heading-prefix-font-size);
  color: white;
  padding-bottom: 0.2em;
}
.fibernet-hero .heading .blue,
.carousel-item.fibernet-hero .heading .blue {
  color: #23d5d8;
}
.fibernet-hero .heading .red,
.carousel-item.fibernet-hero .heading .red {
  color: #ed135a;
}
.fibernet-hero .heading .white,
.carousel-item.fibernet-hero .heading .white {
  color: white;
}
.fibernet-hero .speed-price,
.carousel-item.fibernet-hero .speed-price {
  font-size: var(--speed-price-font-size);
  margin: var(--speed-price-margin);
  text-transform: uppercase;
  font-weight: 500;
  color: #d42c63;
  color: #ed135a;
}
.fibernet-hero .red-price,
.carousel-item.fibernet-hero .red-price {
  display: var(--blue-price-display);
  color: inherit;
  line-height: var(--blue-price-line-height);
}
.fibernet-hero .red-price .number,
.carousel-item.fibernet-hero .red-price .number {
  font-size: var(--blue-price-font-size);
}
.fibernet-hero .red-price .number .flag,
.carousel-item.fibernet-hero .red-price .number .flag {
  text-shadow: none;
  box-shadow: 0 0.025em 0.175em rgba(0, 0, 0, 0.5);
}
.fibernet-hero .conditions,
.carousel-item.fibernet-hero .conditions {
  width: 100%;
  font-size: var(--conditions-font-size);
  color: rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.5);
}
.fibernet-hero .badge,
.carousel-item.fibernet-hero .badge {
  display: block;
  position: absolute;
  top: var(--badge-top);
  right: var(--badge-right);
  width: var(--badge-size);
  height: var(--badge-size);
  border-radius: 50%;
  transform: rotate(10deg);
  border-bottom: 0;
  padding: 0;
  margin: 0;
}
.fibernet-hero .badge:hover,
.carousel-item.fibernet-hero .badge:hover {
  box-shadow: 0 0.25em 0.5em rgba(190, 0, 90, 0.75);
}
.fibernet-hero .badge svg,
.carousel-item.fibernet-hero .badge svg {
  width: inherit;
}

.loyalty-group-hero,
.carousel-item.loyalty-group-hero {
  --not-blue-background-image: url("../images/campaigns/loyalty_group/hero-900.jpg");
  --heading-font-size: 2.2em;
  --heading-prefix-font-size: .4em;
  --conditions-font-size: .7em;
  --badge-size: 6em;
  --badge-top: 1em;
  --badge-right: 1em;
  --speed-price-margin: .25em 0 0 0;
  --speed-price-font-size: 2.2em;
  --blue-price-font-size: 2em;
  --blue-price-display: block;
  --blue-price-line-height: 1;
  background-image: var(--not-blue-background-image);
  background-size: cover;
  background-position: center;
}
@media (min-width: 450px) {
  .loyalty-group-hero,
.carousel-item.loyalty-group-hero {
    --badge-size: 8em;
    --badge-top: 2em;
    --badge-right: 2em;
    --speed-price-margin: 0;
    --speed-price-font-size: 2.5em;
  }
}
@media (min-width: 680px) {
  .loyalty-group-hero,
.carousel-item.loyalty-group-hero {
    --badge-size: 10em;
  }
}
@media (min-width: 820px) {
  .loyalty-group-hero,
.carousel-item.loyalty-group-hero {
    --not-blue-background-image: url("../images/campaigns/loyalty_group/hero-2000.jpg");
    --speed-price-font-size: 2.8em;
    --speed-price-margin: .75 0 0 0;
    --badge-size: 13em;
  }
}
@media (min-width: 3000px) {
  .loyalty-group-hero,
.carousel-item.loyalty-group-hero {
    --not-blue-background-image: url("../images/campaigns/loyalty_group/hero.jpg");
  }
}
.loyalty-group-hero .content,
.carousel-item.loyalty-group-hero .content {
  position: relative;
  width: 100%;
  height: 100%;
}
.loyalty-group-hero .left,
.carousel-item.loyalty-group-hero .left {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: flex-end;
  text-shadow: 0 0.025em 0.175em rgba(0, 0, 0, 0.5);
}
.loyalty-group-hero .left, .loyalty-group-hero .left *,
.carousel-item.loyalty-group-hero .left,
.carousel-item.loyalty-group-hero .left * {
  text-align: left;
}
.loyalty-group-hero .heading,
.carousel-item.loyalty-group-hero .heading {
  font-size: var(--heading-font-size);
}
.loyalty-group-hero .heading .line,
.carousel-item.loyalty-group-hero .heading .line {
  display: block;
}
.loyalty-group-hero .heading .prefix,
.carousel-item.loyalty-group-hero .heading .prefix {
  font-size: var(--heading-prefix-font-size);
  color: white;
  padding-bottom: 0.2em;
}
.loyalty-group-hero .heading .blue,
.carousel-item.loyalty-group-hero .heading .blue {
  color: #23d5d8;
}
.loyalty-group-hero .heading .red,
.carousel-item.loyalty-group-hero .heading .red {
  color: #ed135a;
}
.loyalty-group-hero .speed-price,
.carousel-item.loyalty-group-hero .speed-price {
  font-size: var(--speed-price-font-size);
  margin: var(--speed-price-margin);
  text-transform: uppercase;
  font-weight: 500;
  color: white;
}
.loyalty-group-hero .red-price,
.carousel-item.loyalty-group-hero .red-price {
  display: var(--blue-price-display);
  color: #ed135a;
  line-height: var(--blue-price-line-height);
}
.loyalty-group-hero .red-price .number,
.carousel-item.loyalty-group-hero .red-price .number {
  font-size: var(--blue-price-font-size);
}
.loyalty-group-hero .conditions,
.carousel-item.loyalty-group-hero .conditions {
  width: 100%;
  font-size: var(--conditions-font-size);
  color: rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.5);
}
.loyalty-group-hero .badge,
.carousel-item.loyalty-group-hero .badge {
  display: block;
  position: absolute;
  top: var(--badge-top);
  right: var(--badge-right);
  width: var(--badge-size);
  height: var(--badge-size);
  border-radius: 50%;
  transform: rotate(10deg);
  border-bottom: 0;
  padding: 0;
  margin: 0;
}
.loyalty-group-hero .badge:hover,
.carousel-item.loyalty-group-hero .badge:hover {
  box-shadow: 0 0.25em 0.5em rgba(190, 0, 90, 0.75);
}
.loyalty-group-hero .badge svg,
.carousel-item.loyalty-group-hero .badge svg {
  width: inherit;
}

.nord-energi-hero,
.carousel-item.nord-energi-hero {
  --not-blue-background-image: url("../images/campaigns/loyalty_group/hero-900.jpg");
  --heading-font-size: 2.2em;
  --heading-prefix-font-size: .4em;
  --conditions-font-size: .7em;
  --badge-size: 6em;
  --badge-top: 1em;
  --badge-right: 1em;
  --speed-price-margin: .25em 0 0 0;
  --speed-price-font-size: 2.2em;
  --blue-price-font-size: 2em;
  --blue-price-display: block;
  --blue-price-line-height: 1;
  background-image: var(--not-blue-background-image);
  background-size: cover;
  background-position: center;
}
@media (min-width: 450px) {
  .nord-energi-hero,
.carousel-item.nord-energi-hero {
    --badge-size: 8em;
    --badge-top: 2em;
    --badge-right: 2em;
    --speed-price-margin: 0;
    --speed-price-font-size: 2.5em;
  }
}
@media (min-width: 680px) {
  .nord-energi-hero,
.carousel-item.nord-energi-hero {
    --badge-size: 10em;
  }
}
@media (min-width: 820px) {
  .nord-energi-hero,
.carousel-item.nord-energi-hero {
    --not-blue-background-image: url("../images/campaigns/loyalty_group/hero-2000.jpg");
    --speed-price-font-size: 2.8em;
    --speed-price-margin: .75 0 0 0;
    --badge-size: 13em;
  }
}
@media (min-width: 3000px) {
  .nord-energi-hero,
.carousel-item.nord-energi-hero {
    --not-blue-background-image: url("../images/campaigns/loyalty_group/hero.jpg");
  }
}
.nord-energi-hero .content,
.carousel-item.nord-energi-hero .content {
  position: relative;
  width: 100%;
}
.nord-energi-hero .left, .nord-energi-hero .left *,
.carousel-item.nord-energi-hero .left,
.carousel-item.nord-energi-hero .left * {
  text-align: left;
}
.nord-energi-hero .white,
.carousel-item.nord-energi-hero .white {
  color: white;
}
.nord-energi-hero .blue,
.carousel-item.nord-energi-hero .blue {
  color: #23d5d8;
}
.nord-energi-hero .red,
.carousel-item.nord-energi-hero .red {
  color: #ed135a;
}
.nord-energi-hero .heading,
.carousel-item.nord-energi-hero .heading {
  font-size: var(--heading-font-size);
}
.nord-energi-hero .heading .line,
.carousel-item.nord-energi-hero .heading .line {
  display: block;
}
.nord-energi-hero .heading .prefix,
.carousel-item.nord-energi-hero .heading .prefix {
  font-size: var(--heading-prefix-font-size);
  color: white;
  padding-bottom: 0.2em;
}
.nord-energi-hero .speed-price,
.carousel-item.nord-energi-hero .speed-price {
  font-size: var(--speed-price-font-size);
  margin: var(--speed-price-margin);
  text-transform: uppercase;
  font-weight: 500;
  color: white;
}
.nord-energi-hero .red-price,
.carousel-item.nord-energi-hero .red-price {
  display: var(--blue-price-display);
  color: #ed135a;
  line-height: var(--blue-price-line-height);
}
.nord-energi-hero .red-price .number,
.carousel-item.nord-energi-hero .red-price .number {
  font-size: var(--blue-price-font-size);
}
.nord-energi-hero .conditions,
.carousel-item.nord-energi-hero .conditions {
  width: 100%;
  font-size: var(--conditions-font-size);
  color: rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.5);
}
.nord-energi-hero .badge,
.carousel-item.nord-energi-hero .badge {
  display: block;
  position: absolute;
  top: var(--badge-top);
  right: var(--badge-right);
  width: var(--badge-size);
  height: var(--badge-size);
  border-radius: 50%;
  transform: rotate(10deg);
  border-bottom: 0;
  padding: 0;
  margin: 0;
}
.nord-energi-hero .badge:hover,
.carousel-item.nord-energi-hero .badge:hover {
  box-shadow: 0 0.25em 0.5em rgba(190, 0, 90, 0.75);
}
.nord-energi-hero .badge svg,
.carousel-item.nord-energi-hero .badge svg {
  width: inherit;
}

.old-hero-image {
  height: calc(100vh - 60px);
  min-height: 600px;
  margin: 0;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  position: relative;
  padding-top: 2em;
  padding-bottom: 2em;
}
@media (max-width: 799px) {
  .old-hero-image {
    background-image: url("../images/campaigns/new_universe/hiper_mobile.jpg");
    background-attachment: local;
    background-position: top center;
  }
}
@media (min-width: 800px) {
  .old-hero-image {
    background-image: url("../images/campaigns/new_universe/hiper_desktop.jpg");
    background-attachment: fixed;
    background-position: center;
  }
}

.new-hero-content {
  max-width: 90%;
  margin: 0;
  padding: 2em 1em 1em 1em;
  background-color: rgba(255, 255, 255, 0.87);
  backdrop-filter: blur(2px);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -khtml-border-radius: 5px;
  margin-top: 2em;
  margin-bottom: 2em;
  position: relative;
}
@media (min-width: 800px) {
  .new-hero-content {
    margin: 0;
  }
}
@media (min-width: 1000px) {
  .new-hero-content {
    width: 80%;
  }
}
.new-hero-content .text-container {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
}
@media (max-width: 799px) {
  .new-hero-content .text-container {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 800px) {
  .new-hero-content .text-container {
    flex-direction: row;
  }
}
.new-hero-content .text-container .column {
  height: auto;
  text-align: center;
  padding: 0em;
}
.new-hero-content .text-container .price-container-full .price-container {
  display: flex;
  align-items: flex-start;
}
.new-hero-content .text-container .price-container-full .price-container h1, .new-hero-content .text-container .price-container-full .price-container h2, .new-hero-content .text-container .price-container-full .price-container p {
  margin: 0;
  padding: 0;
}
.new-hero-content .text-container .price-container-full .price-container .speed {
  display: flex;
  flex-direction: column;
}
.new-hero-content .text-container .price-container-full .price-container .speed h1 {
  font-size: 5em;
  line-height: 0.8;
}
.new-hero-content .text-container .price-container-full .price-container .speed .til-kun {
  color: #303a4b;
}
.new-hero-content .text-container .price-container-full .price-container .speed.center h1, .new-hero-content .text-container .price-container-full .price-container .speed.center .til-kun {
  text-align: center;
}
.new-hero-content .text-container .price-container-full .price-container .speed.right h1, .new-hero-content .text-container .price-container-full .price-container .speed.right .til-kun {
  text-align: right;
}
.new-hero-content .text-container .price-container-full .price-container .speed.right .til-kun {
  margin-right: 0.05em;
}
.new-hero-content .text-container .price-container-full .price-container .price {
  display: flex;
  flex-direction: column;
}
.new-hero-content .text-container .price-container-full .price-container .price h1 {
  margin-bottom: 0.2em;
}
.new-hero-content .text-container .price-container-full .price-container .price p {
  text-align: left;
  line-height: 1.2;
}
@media (max-width: 449px) {
  .new-hero-content .text-container .price-container-full .price-container {
    flex-direction: column;
    align-items: center;
  }
  .new-hero-content .text-container .price-container-full .price-container .speed h1 {
    font-size: 6em;
  }
  .new-hero-content .text-container .price-container-full .price-container .speed .til-kun {
    font-size: 2em;
    line-height: 1.4;
    margin-top: -0.25em;
    margin-bottom: 0.2em;
  }
  .new-hero-content .text-container .price-container-full .price-container .speed.right h1, .new-hero-content .text-container .price-container-full .price-container .speed.right .til-kun {
    text-align: center;
    margin-right: 0;
  }
  .new-hero-content .text-container .price-container-full .price-container .price {
    align-items: center;
    width: 100%;
  }
  .new-hero-content .text-container .price-container-full .price-container .price h1 {
    text-align: center;
    font-size: 8em;
    line-height: 0.7;
  }
  .new-hero-content .text-container .price-container-full .price-container .price h1 .comma-dash {
    position: absolute;
  }
  .new-hero-content .text-container .price-container-full .price-container .price p {
    margin-top: -0.75em;
  }
}
@media (min-width: 450px) {
  .new-hero-content .text-container .price-container-full .price-container {
    flex-direction: row;
  }
  .new-hero-content .text-container .price-container-full .price-container .speed {
    margin-right: 1em;
  }
  .new-hero-content .text-container .price-container-full .price-container .speed .til-kun {
    font-size: 3.1em;
    line-height: 0.9;
  }
  .new-hero-content .text-container .price-container-full .price-container .price h1 {
    text-align: left;
    font-size: 8.8em;
    line-height: 0.75;
  }
  .new-hero-content .text-container .price-container-full .price-container .price p {
    margin-left: 0.5em;
  }
}
.new-hero-content .hero-text h1,
.new-hero-content .hero-text h2,
.new-hero-content .hero-text h3,
.new-hero-content .hero-text p {
  text-align: center;
}
.new-hero-content .hastighed h1 {
  font-size: 4em;
  line-height: 0.3em;
  margin-top: 0.32em;
  text-align: center;
}
@media (min-width: 800px) {
  .new-hero-content .hastighed h1 {
    text-align: right;
  }
}
.new-hero-content .hastighed-campaigns h1 {
  font-size: 4.2em;
  line-height: 0.3em;
  margin-top: 0.32em;
  text-align: center;
}
@media (min-width: 800px) {
  .new-hero-content .hastighed-campaigns h1 {
    text-align: right;
  }
}
.new-hero-content .sub-line {
  font-size: 2.9em;
  text-align: center;
  line-height: 0.8em;
}
@media (min-width: 800px) {
  .new-hero-content .sub-line {
    text-align: right;
  }
}
.new-hero-content .sub-line-long {
  font-size: 2.25em;
  text-align: center;
  line-height: 0.8em;
}
@media (min-width: 800px) {
  .new-hero-content .sub-line-long {
    text-align: right;
  }
}
.new-hero-content .sub-line-short {
  font-size: 2.9em;
  text-align: center;
  line-height: 0.8em;
}
@media (min-width: 800px) {
  .new-hero-content .sub-line-short {
    text-align: right;
    font-size: 3.05em;
  }
}
.new-hero-content .pris {
  width: 100%;
  margin-top: 2em;
}
@media (min-width: 800px) {
  .new-hero-content .pris {
    margin-left: 1.8em;
    margin-top: 2.8em;
  }
}
@media (max-width: 799px) {
  .new-hero-content .pris {
    margin-top: 3.5em;
    margin-bottom: 2em;
  }
}
.new-hero-content .pris h1 {
  line-height: 0.15em;
  font-size: 8em;
  text-align: center;
}
@media (min-width: 800px) {
  .new-hero-content .pris h1 {
    text-align: left;
  }
}
@media (max-width: 800px) {
  .new-hero-content .pris h1 {
    margin-top: 0.4em;
    margin-bottom: 0.1em;
  }
}
.new-hero-content .payoff h1 {
  font-size: 1.5em;
}
@media (min-width: 450px) {
  .new-hero-content .payoff h1 {
    font-size: 2em;
  }
}
@media (min-width: 800px) {
  .new-hero-content .payoff h1 {
    font-size: 3em;
  }
}
.new-hero-content .hero-address {
  width: 100%;
  margin-top: 1em;
}
.new-hero-content .hero-address h1 {
  font-size: 2em;
  padding: 0;
  margin-top: 1.5em;
}
@media (min-width: 800px) {
  .new-hero-content .hero-address h1 {
    margin-top: 0em;
  }
}
.new-hero-content .hero-address h3 {
  padding: 0;
}
.new-hero-content .hero-address p {
  line-height: 1.3em;
}
.comma-dash {
  letter-spacing: -0.1em;
  margin-right: 0.1em;
}

.new-hero-content .md {
  position: relative;
  font-size: 0.2em;
  font-weight: 300;
  text-transform: lowercase;
  margin-top: -1.8em;
  top: 0;
  display: block;
}
@media (min-width: 270px) {
  .new-hero-content .md {
    margin-left: 6.8em;
  }
}
@media (min-width: 800px) {
  .new-hero-content .md {
    margin-left: 6.3em;
  }
}
.new-hero-content .md-campaigns {
  position: relative;
  font-size: 0.2em;
  font-weight: 300;
  text-transform: lowercase;
  margin-top: -1.8em;
  top: 0;
  display: block;
}
@media (min-width: 270px) {
  .new-hero-content .md-campaigns {
    margin-left: 6.3em;
  }
}
@media (min-width: 800px) {
  .new-hero-content .md-campaigns {
    margin-left: 5.7em;
  }
}
.new-hero-content .splash {
  position: relative;
}
.new-hero-content .splash .splash__wrapper {
  background-color: #303a4b;
}
@media (max-width: 449px) {
  .new-hero-content .splash .splash__wrapper {
    padding: 0.2em 1em 0.1em 1em;
  }
}
@media (min-width: 450px) {
  .new-hero-content .splash .splash__wrapper {
    padding: 0.4em 0.8em;
  }
}
.new-hero-content .splash .splash__wrapper .splash__content {
  color: #fff;
  line-height: 1.2;
  padding: 0;
  white-space: nowrap;
}
.new-hero-content .splash .splash__wrapper .splash__content.splash__content__small-text {
  font-size: 2em;
}
@media (max-width: 799px) {
  .new-hero-content .splash {
    display: flex;
    justify-content: center;
  }
  .new-hero-content .splash .splash__wrapper {
    margin-top: 1em;
  }
}
@media (min-width: 800px) {
  .new-hero-content .splash .splash__wrapper {
    position: absolute;
    transform: translate(0, -50%) rotate(15deg);
    top: 40%;
    left: 2em;
    box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 10px;
  }
}
.new-hero-content .splash-wrapper-fibernet {
  display: flex;
  justify-content: center;
}
@media (min-width: 270px) {
  .new-hero-content .splash-wrapper-fibernet {
    margin-top: 4em;
  }
}
@media (min-width: 800px) {
  .new-hero-content .splash-wrapper-fibernet {
    margin-top: 1em;
    position: absolute;
    transform: translate(-50%, -50%) rotate(15deg);
    top: 15%;
    left: 7em;
    box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 10px;
  }
}
.new-hero-content .splash-wrapper-fibernet .spar-splash-fibernet {
  padding: 0.5em 0.7em 0 0.6em;
  background-color: #293342;
  width: 10em;
}
.new-hero-content .splash-wrapper-fibernet .spar-splash-fibernet h2 {
  color: #ffffff;
}
@media (min-width: 270px) {
  .new-hero-content .splash-wrapper-fiberjylland {
    display: flex;
    justify-content: center;
    margin-top: 4em;
  }
}
@media (min-width: 800px) {
  .new-hero-content .splash-wrapper-fiberjylland {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    position: absolute;
    transform: translate(-50%, -50%) rotate(15deg);
    top: 15%;
    left: 9em;
    box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 10px;
  }
}
.new-hero-content .splash-wrapper-fiberjylland .spar-splash-fiberjylland {
  padding: 0.5em 0.7em 0 0.6em;
  background-color: #293342;
  width: 13em;
}
.new-hero-content .splash-wrapper-fiberjylland .spar-splash-fiberjylland h2 {
  color: #ffffff;
  font-size: 2em;
}
@media (min-width: 270px) {
  .new-hero-content .splash-wrapper-coax-fiber {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }
}
@media (min-width: 800px) {
  .new-hero-content .splash-wrapper-coax-fiber {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    position: absolute;
    transform: translate(-50%, -50%) rotate(15deg);
    top: 15%;
    left: 9em;
    box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 10px;
  }
}
.new-hero-content .splash-wrapper-coax-fiber .spar-splash-coax-fiber {
  padding: 0.5em 0.7em 0 0.6em;
  background-color: #293342;
  width: 13em;
}
.new-hero-content .splash-wrapper-coax-fiber .spar-splash-coax-fiber h2 {
  color: #ffffff;
  font-size: 2em;
}
.new-hero-content .splash-wrapper-order-now {
  display: flex;
  justify-content: center;
}
@media (min-width: 270px) {
  .new-hero-content .splash-wrapper-order-now {
    margin-top: 4em;
  }
}
@media (min-width: 800px) {
  .new-hero-content .splash-wrapper-order-now {
    margin-top: 2em;
    position: absolute;
    transform: translate(-50%, -50%) rotate(15deg);
    top: 15%;
    left: 9em;
    box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 10px;
  }
}
.new-hero-content .splash-wrapper-order-now .spar-splash-order-now {
  padding: 0.4em 0.8em;
  background-color: #303a4b;
}
.new-hero-content .splash-wrapper-order-now .spar-splash-order-now h2 {
  color: #ffffff;
  font-size: 1.5em;
  line-height: 1.2;
  font-weight: 400;
  padding: 0;
  white-space: nowrap;
}

.org {
  transform: translate(-50%, -50%) rotate(-15deg);
  top: 5em;
  left: 15%;
  font-size: 1.5em;
}
@media (max-width: 1550px) {
  .org {
    font-size: 1.2em;
    top: 8em;
  }
}
@media (max-width: 1200px) {
  .org {
    left: 10%;
  }
}
@media (max-width: 799px) {
  .org {
    top: 10em;
    left: 15%;
  }
}
@media (max-width: 450px) {
  .org {
    font-size: 1em;
    top: 45%;
    left: 3.25em;
  }
}
@media (max-width: 320px) {
  .org {
    font-size: 0.7em;
    top: 45%;
    left: 3.25em;
  }
}

.hero-container.personalize {
  background-color: #42747d;
  background: linear-gradient(0deg, #4b848e 0%, #42747d 50%);
}
.hero-container.personalize .hero-background {
  max-height: 720px;
}
.hero-container.personalize .hero-background img {
  max-height: 100%;
}
@media (min-width: 1025px) {
  .hero-container.personalize.personalize--ef .hero-background {
    height: calc(100% - 4em);
    top: -1em;
    left: 50%;
    transform: translateX(-5%);
  }
  .hero-container.personalize.personalize--e .hero-background {
    height: calc(100% - 4em);
    top: -1em;
    left: 50%;
    transform: translateX(4em);
  }
  .hero-container.personalize.personalize--f .hero-background, .hero-container.personalize.personalize--tdc .hero-background {
    height: calc(100% - 10em);
    top: 1em;
    left: 50%;
    transform: translateX(-2em);
  }
  .hero-container.personalize.personalize--ne .hero-background, .hero-container.personalize.personalize--tm .hero-background {
    top: 10em;
    left: 50%;
    transform: translateX(10%);
  }
  .hero-container.personalize.personalize--n .hero-background {
    height: calc(100% - 10em);
    top: 2em;
    left: 50%;
    transform: translateX(4em);
  }
}
@media (min-width: 1025px) and (max-width: 1650px) {
  .hero-container.personalize.personalize--ne .hero-background, .hero-container.personalize.personalize--tm .hero-background {
    height: calc(100% - 4em);
    top: 8em;
  }
}
@media (max-width: 1024px) and (min-width: 521px) {
  .hero-container.personalize.personalize--ef .hero-background, .hero-container.personalize.personalize--e .hero-background {
    height: calc(100% - 10em);
    top: 1em;
    right: 1em;
  }
  .hero-container.personalize.personalize--f .hero-background, .hero-container.personalize.personalize--tdc .hero-background {
    max-width: 22em;
    width: 60%;
    top: 3em;
    right: 1em;
  }
  .hero-container.personalize.personalize--ne .hero-background {
    height: calc(100% - 6em);
    top: 9em;
    right: 25%;
    transform: translateX(25%);
  }
  .hero-container.personalize.personalize--n .hero-background {
    height: calc(100% - 12em);
    top: 2em;
    left: 55%;
  }
  .hero-container.personalize.personalize--tm .hero-background {
    height: calc(100% - 6em);
    top: 9em;
    left: 50%;
  }
}
@media (max-width: 520px) {
  .hero-container.personalize.personalize--ef .hero-background {
    width: 75%;
    top: 3em;
    right: 25%;
    transform: translateX(50%);
  }
  .hero-container.personalize.personalize--e .hero-background {
    width: 50%;
    top: 3em;
    right: 0.5em;
  }
  .hero-container.personalize.personalize--f .hero-background {
    width: 55%;
    top: 8em;
    right: 1em;
  }
  .hero-container.personalize.personalize--ne .hero-background {
    width: 55%;
    top: 11.5em;
    left: 50%;
    transform: translateX(-4em);
  }
  .hero-container.personalize.personalize--n .hero-background {
    width: 40%;
    top: 8.25em;
    left: 50%;
    transform: translateX(1.5em);
  }
  .hero-container.personalize.personalize--tdc .hero-background {
    width: 50%;
    top: 10em;
    right: 0.5em;
  }
  .hero-container.personalize.personalize--tm .hero-background {
    width: 50%;
    top: 8em;
    left: 50%;
    transform: translateX(1em);
  }
}

.hero-container.hero-container--usp.hero-container--ida h2,
.hero-container.hero-container--usp.hero-container--stepler h2 {
  margin: 0 auto;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.2em;
  text-transform: none;
  line-height: 1.6;
  text-align: center;
  hyphens: manual;
  color: #ffffff;
}
.hero-container.hero-container--usp.hero-container--ida h2 img,
.hero-container.hero-container--usp.hero-container--stepler h2 img {
  position: relative;
  display: inline-block;
  height: 1.4em;
}
.hero-container.hero-container--usp.hero-container--ida h2 .discount-code,
.hero-container.hero-container--usp.hero-container--stepler h2 .discount-code {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  padding: 0.1em 0.5em;
  border-radius: 1em;
  background-color: #ed135a;
}
@media (min-width: 521px) {
  .hero-container.hero-container--usp.hero-container--ida .hide-on-large-devices,
.hero-container.hero-container--usp.hero-container--stepler .hide-on-large-devices {
    display: none;
  }
}
@media (max-width: 520px) {
  .hero-container.hero-container--usp.hero-container--ida .hide-on-small-devices,
.hero-container.hero-container--usp.hero-container--stepler .hide-on-small-devices {
    display: none;
  }
}

.hero-container.hero-container--usp.hero-container--stepler h2 img {
  top: 0.24em;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
}

@media (min-width: 521px) {
  .hero-container.hero-container--usp.hero-container--ida h1 {
    padding-bottom: 0.1em;
  }
}
@media (max-width: 520px) {
  .hero-container.hero-container--usp.hero-container--ida h1 {
    padding-bottom: 0.25em;
  }
}
.hero-container.hero-container--usp.hero-container--ida h2 img {
  top: 0.3em;
  margin-left: 0.15em;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}

.hiper-coop-logo {
  margin-bottom: 1em;
  max-width: 500px;
}
@media (max-width: 768px) {
  .hiper-coop-logo {
    max-width: 350px;
  }
}
@media (max-width: 520px) {
  .hiper-coop-logo {
    max-width: 200px;
  }
}
.hiper-coop-logo .white {
  fill: #FFFFFF;
}
.hiper-coop-logo .coop {
  fill: #BE1F24;
}

.page-campaign-coop .hero-container .hero-fille-coop-h1, .page-campaign-tdcnet .hero-container .hero-fille-coop-h1 {
  font-size: 5.5em;
  font-weight: 300;
  line-height: 0.8;
  margin-left: -0.07em;
}
@media (max-width: 768px) {
  .page-campaign-coop .hero-container .hero-fille-coop-h1, .page-campaign-tdcnet .hero-container .hero-fille-coop-h1 {
    font-size: 5em;
  }
}
@media (max-width: 520px) {
  .page-campaign-coop .hero-container .hero-fille-coop-h1, .page-campaign-tdcnet .hero-container .hero-fille-coop-h1 {
    font-size: 3.4em;
  }
}
.page-campaign-coop .hero-container .hero-pre-price, .page-campaign-tdcnet .hero-container .hero-pre-price {
  color: #FFFFFF;
}
@media (max-width: 768px) {
  .page-campaign-coop .hero-container .hero-pre-price, .page-campaign-tdcnet .hero-container .hero-pre-price {
    font-size: 1.8em;
    padding-top: 0;
  }
}
@media (max-width: 520px) {
  .page-campaign-coop .hero-container .hero-pre-price, .page-campaign-tdcnet .hero-container .hero-pre-price {
    font-size: 1em;
  }
}
.page-campaign-coop .section-coop-card, .page-campaign-tdcnet .section-coop-card {
  background-color: #303a4b;
}
.page-campaign-coop .section-coop-card .coop-instructions, .page-campaign-tdcnet .section-coop-card .coop-instructions {
  background-color: #303a4b;
  display: flex;
  flex-direction: row;
  position: relative;
}
.page-campaign-coop .section-coop-card .coop-instructions .text, .page-campaign-tdcnet .section-coop-card .coop-instructions .text {
  margin-left: 0.3em;
}
.page-campaign-coop .section-coop-card .coop-instructions .text h2, .page-campaign-tdcnet .section-coop-card .coop-instructions .text h2 {
  color: #FFFFFF;
  text-align: left;
}
.page-campaign-coop .section-coop-card .coop-instructions .text p, .page-campaign-tdcnet .section-coop-card .coop-instructions .text p {
  color: #FFFFFF;
  max-width: 30em;
  font-weight: 100;
}
.page-campaign-coop .section-coop-card .coop-instructions .coop-card, .page-campaign-tdcnet .section-coop-card .coop-instructions .coop-card {
  max-width: 280px;
}
@media (max-width: 520px) {
  .page-campaign-coop .section-coop-card .coop-instructions, .page-campaign-tdcnet .section-coop-card .coop-instructions {
    flex-direction: column;
    text-align: center;
  }
  .page-campaign-coop .section-coop-card .coop-instructions .text, .page-campaign-tdcnet .section-coop-card .coop-instructions .text {
    margin-left: 0;
  }
  .page-campaign-coop .section-coop-card .coop-instructions .text h2, .page-campaign-tdcnet .section-coop-card .coop-instructions .text h2 {
    text-align: center;
  }
}
.page-campaign-coop section.light-grey-campaign-section, .page-campaign-tdcnet section.light-grey-campaign-section {
  background-color: #f7f7f7;
}
.page-campaign-coop .campaign-section-coop, .page-campaign-tdcnet .campaign-section-coop {
  position: relative;
  padding: 1.5em 1em;
}
.page-campaign-coop .campaign-section-coop .left, .page-campaign-tdcnet .campaign-section-coop .left {
  text-align: left;
}
.page-campaign-coop .campaign-section-coop .left h2, .page-campaign-tdcnet .campaign-section-coop .left h2 {
  font-size: 1em;
}
.page-campaign-coop .campaign-section-coop .left h2, .page-campaign-coop .campaign-section-coop .left h2 > span, .page-campaign-tdcnet .campaign-section-coop .left h2, .page-campaign-tdcnet .campaign-section-coop .left h2 > span {
  display: block;
  text-align: left;
}
.page-campaign-coop .campaign-section-coop .left .speed, .page-campaign-tdcnet .campaign-section-coop .left .speed {
  font-size: 5em;
  font-weight: 400;
  line-height: 1;
  padding: 0;
  text-align: left;
}
.page-campaign-coop .campaign-section-coop .left .price, .page-campaign-tdcnet .campaign-section-coop .left .price {
  width: fit-content;
  font-size: 10em;
  text-align: left;
  position: relative;
  font-weight: 400;
}
@media (max-width: 768px) {
  .page-campaign-coop .campaign-section-coop .left .price, .page-campaign-tdcnet .campaign-section-coop .left .price {
    text-align: center;
  }
}
.page-campaign-coop .campaign-section-coop .left .price .price-md, .page-campaign-tdcnet .campaign-section-coop .left .price .price-md {
  top: 34%;
}
@media (max-width: 520px) {
  .page-campaign-coop .campaign-section-coop .left .price .price-md, .page-campaign-tdcnet .campaign-section-coop .left .price .price-md {
    top: 30%;
  }
}
.page-campaign-coop .campaign-section-coop .left .filler, .page-campaign-tdcnet .campaign-section-coop .left .filler {
  font-size: 1.5em;
  line-height: 0.7em;
}
.page-campaign-coop .campaign-section-coop .left .filler,
.page-campaign-coop .campaign-section-coop .left .price-disclaimer, .page-campaign-tdcnet .campaign-section-coop .left .filler,
.page-campaign-tdcnet .campaign-section-coop .left .price-disclaimer {
  padding-bottom: 0.7em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
}
.page-campaign-coop .campaign-section-coop .left .price-disclaimer, .page-campaign-tdcnet .campaign-section-coop .left .price-disclaimer {
  line-height: 1.6;
}
.page-campaign-coop .campaign-section-coop .right, .page-campaign-tdcnet .campaign-section-coop .right {
  position: relative;
}
.page-campaign-coop .campaign-section-coop .right .mvfp-splash, .page-campaign-tdcnet .campaign-section-coop .right .mvfp-splash {
  position: absolute;
  top: -0.8em;
  left: 1.1em;
}
.page-campaign-coop .campaign-section-coop .right .mvfp-splash .mvfp, .page-campaign-tdcnet .campaign-section-coop .right .mvfp-splash .mvfp {
  max-width: 150px;
}
@media (max-width: 768px) {
  .page-campaign-coop .campaign-section-coop .right .mvfp-splash, .page-campaign-tdcnet .campaign-section-coop .right .mvfp-splash {
    left: -0.5em;
  }
  .page-campaign-coop .campaign-section-coop .right .mvfp-splash .mvfp, .page-campaign-tdcnet .campaign-section-coop .right .mvfp-splash .mvfp {
    max-width: 125px;
  }
}
@media (max-width: 520px) {
  .page-campaign-coop .campaign-section-coop .right .mvfp-splash, .page-campaign-tdcnet .campaign-section-coop .right .mvfp-splash {
    left: -1em;
  }
  .page-campaign-coop .campaign-section-coop .right .mvfp-splash .mvfp, .page-campaign-tdcnet .campaign-section-coop .right .mvfp-splash .mvfp {
    max-width: 100px;
  }
}
@media (max-width: 768px) {
  .page-campaign-coop .campaign-section-coop .speed,
.page-campaign-coop .campaign-section-coop .left,
.page-campaign-coop .campaign-section-coop .filler,
.page-campaign-coop .campaign-section-coop .price-disclaimer, .page-campaign-tdcnet .campaign-section-coop .speed,
.page-campaign-tdcnet .campaign-section-coop .left,
.page-campaign-tdcnet .campaign-section-coop .filler,
.page-campaign-tdcnet .campaign-section-coop .price-disclaimer {
    text-align: center;
  }
}
@media (max-width: 900px) {
  .page-campaign-coop .campaign-section-coop .router, .page-campaign-tdcnet .campaign-section-coop .router {
    max-width: 400px;
  }
}
@media (max-width: 520px) {
  .page-campaign-coop .campaign-section-coop .router, .page-campaign-tdcnet .campaign-section-coop .router {
    max-width: 300px;
  }
}
.page-campaign-coop .campaign-section-coop .conditions, .page-campaign-tdcnet .campaign-section-coop .conditions {
  font-size: 0.7em;
  max-width: var(--hero-max-width);
}
@media (max-width: 768px) {
  .page-campaign-coop .campaign-section-coop .flex, .page-campaign-tdcnet .campaign-section-coop .flex {
    flex-direction: column-reverse;
  }
}

.competition {
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.competition.competition--summer {
  max-height: 600px;
  flex-direction: row-reverse;
  align-items: stretch;
  background-color: #7acfe0;
  overflow: hidden;
}
.competition.competition--summer .competition-image {
  width: 50%;
  min-height: 40vw;
  max-height: 960px;
  background: url(../images/pages/home/gavekort.jpg) center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.competition.competition--summer .competition-text,
.competition.competition--summer .competition-image {
  max-width: 1920px;
}
.competition.competition--summer .competition-text {
  width: 50%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.competition.competition--summer .competition-text h1,
.competition.competition--summer .competition-text h2,
.competition.competition--summer .competition-text h3,
.competition.competition--summer .competition-text p {
  color: #fff;
}
.competition.competition--summer .competition-text .pre-title,
.competition.competition--summer .competition-text .after-title {
  font-size: 1.8em;
  font-weight: 400;
  line-height: 0.95;
}
.competition.competition--summer .competition-text .title {
  font-size: 3em;
  font-weight: 500;
  padding-bottom: 0.22em;
  color: #ed135a;
  line-height: 0.9;
}
.competition.competition--summer .competition-text .small-title {
  display: inline-block;
  background-color: #ed135a;
  padding: 0.2em 0.75em;
  border-radius: 0.1875em;
  font-weight: 400;
  font-style: italic;
  color: #fff;
  text-transform: uppercase;
  line-height: 0.95;
}
.competition.competition--summer .competition-text .competition-disclaimer {
  font-size: 0.9em;
  max-width: 28em;
  padding-top: 0.75em;
}
@media (max-width: 520px) {
  .competition.competition--summer .competition-text .pre-title {
    padding-bottom: 0.25em;
  }
  .competition.competition--summer .competition-text .title {
    padding-bottom: 0.15em;
  }
  .competition.competition--summer .competition-text .competition-disclaimer {
    padding-top: 0.5em;
  }
}
@media (min-width: 901px) {
  .competition.competition--summer .competition-text {
    min-height: 460px;
  }
  .competition.competition--summer .competition-text .right {
    min-width: 24em;
    padding-top: 1em;
    margin-left: 1em;
  }
}
@media (max-width: 768px) {
  .competition.competition--summer {
    flex-direction: column-reverse;
    max-height: none;
  }
  .competition.competition--summer .competition-text,
.competition.competition--summer .competition-image {
    width: 100%;
  }
  .competition.competition--summer .competition-content {
    min-height: 50vw;
    padding: 2em 1em;
  }
  .competition.competition--summer .competition-image {
    height: 70vw;
  }
}
.competition.competition--winter {
  flex-direction: column;
  align-items: center;
  background-color: #7acfe0;
  padding: 2em 1em;
}
.competition.competition--winter h2, .competition.competition--winter p, .competition.competition--winter span {
  color: #ffffff;
}
.competition.competition--winter h2 {
  font-size: 3em;
  line-height: 1;
}
.competition.competition--winter h2 > span {
  font-size: 0.8em;
  font-weight: 400;
}
.competition.competition--winter section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 1em;
}
.competition.competition--winter .competition-image {
  position: relative;
  margin: 1em 0;
}
.competition.competition--winter .competition-image .splash {
  width: 8em;
  height: 8em;
  border-radius: 4em;
  background-color: #ed135a;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-75%, -55%) rotate(-15deg);
}
.competition.competition--winter .competition-image .splash p {
  font-size: 1.4em;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 0.9;
}
.competition.competition--winter .competition-image .splash p span {
  font-size: 1.6em;
  margin-left: 0.075em;
}
.competition.competition--winter .competition-image + p {
  background-color: #ed135a;
  padding: 1em;
  border-radius: 0.3125em;
}
.competition.competition--winter .competition-image + p strong {
  text-transform: uppercase;
  font-size: 1.6em;
  font-weight: 400;
}
@media (min-width: 901px) {
  .competition.competition--winter section {
    transform: translateX(3em);
  }
  .competition.competition--winter .competition-image + p {
    text-align: left;
    max-width: 20em;
  }
}
@media (max-width: 900px) {
  .competition.competition--winter section {
    flex-direction: column;
  }
  .competition.competition--winter .competition-image {
    transform: translateX(3em);
  }
  .competition.competition--winter .competition-image + p {
    max-width: 25em;
    margin-bottom: 1.5em;
  }
}
@media (max-width: 520px) {
  .competition.competition--winter .competition-image {
    max-width: 65vw;
    transform: translateX(2.25em);
  }
  .competition.competition--winter .competition-image .splash {
    width: 6em;
    height: 6em;
    border-radius: 3em;
  }
  .competition.competition--winter .competition-image .splash p {
    font-size: 1em;
  }
}
.competition.competition--receipt {
  width: fit-content;
  padding: 1em;
  margin: 0 auto 2em auto;
  border-radius: 0.3125em;
}
.competition.competition--receipt h2, .competition.competition--receipt p {
  padding-bottom: 0;
}
.competition.competition--receipt h2 {
  font-size: 2em;
}
.competition.competition--receipt .competition-image {
  margin-bottom: 0;
}
.competition.competition--receipt .competition-image img {
  max-height: 7em;
}
.competition.competition--receipt .competition-image .splash {
  width: 6em;
  height: 6em;
  border-radius: 3em;
}
.competition.competition--receipt .competition-image .splash p {
  font-size: 1em;
}
@media (min-width: 521px) {
  .competition.competition--receipt .competition-image {
    transform: translateX(3em);
  }
}

.gavekort-lille {
  max-width: 150px;
  position: absolute;
  top: 0px;
}
@media (max-width: 520px) {
  .gavekort-lille {
    display: none;
  }
}

@media (max-width: 416px) {
  .hero-ribbon--competition h3 .hide-on-small-devices {
    display: none;
  }
}
@media (max-width: 372px) {
  .hero-ribbon--competition img {
    height: 60px;
  }
}
@media (max-width: 345px) {
  .hero-ribbon--competition img {
    display: none;
  }
}
@media (min-width: 346px) {
  .hero-ribbon--competition {
    padding: 0.25em 0.5em;
  }
}

.spies-banner-container {
  transition: margin-top 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.spies-banner {
  z-index: 35;
  padding: 1.4em 0.8em;
  background-color: #303a4b;
  color: white;
  width: 100%;
  text-align: center;
  line-height: 3em;
}
.spies-banner .banner-text {
  font-size: 1em;
}
@media (max-width: 768px) {
  .spies-banner .banner-text {
    font-size: 0.9em;
  }
}
@media (max-width: 350px) {
  .spies-banner .banner-text {
    font-size: 0.6em;
  }
}
.spies-banner .spies-logo {
  max-width: 90px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
@media (max-width: 520px) {
  .spies-banner .spies-logo {
    max-width: 140px;
    margin-top: 0.5em;
  }
}
.spies-banner .btn {
  padding: 0.3em 0.8em 0.3em 0.8em;
  background-color: #FE8C00;
  color: white;
  border-radius: 0.8em;
}
@media (max-width: 520px) {
  .spies-banner .btn {
    display: block;
    margin-top: 0.5em;
  }
}

.page-campaign-spies .hero-container .spies-hero-image {
  max-width: 122em;
}
@media (max-width: 768px) {
  .page-campaign-spies .hero-container .spies-hero-image {
    transform: translateX(-14%) translateY(-40px);
    max-width: 70em;
  }
}
@media (max-width: 520px) {
  .page-campaign-spies .hero-container .spies-hero-image {
    max-width: 55em;
    transform: translateX(-149px) translateY(59px);
  }
}
.page-campaign-spies .hero-container .hero-content .hero-fille-spies-h1 {
  font-size: 5.5em;
  font-weight: 300;
  line-height: 0.9;
  margin-left: -0.07em;
}
@media (max-width: 768px) {
  .page-campaign-spies .hero-container .hero-content .hero-fille-spies-h1 {
    font-size: 5em;
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
  }
}
@media (max-width: 520px) {
  .page-campaign-spies .hero-container .hero-content .hero-fille-spies-h1 {
    font-size: 3.4em;
  }
}
.page-campaign-spies .hero-container .hero-content .hero-pre-price {
  color: #FFFFFF;
  padding-top: 0.5em;
  line-height: 1.05em;
}
@media (max-width: 768px) {
  .page-campaign-spies .hero-container .hero-content .hero-pre-price {
    font-size: 1.3em;
    padding-top: 0;
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
  }
}
@media (max-width: 520px) {
  .page-campaign-spies .hero-container .hero-content .hero-pre-price {
    font-size: 1em;
  }
}
.page-campaign-spies .giftcard {
  display: block;
  padding: 0.8em;
  background-color: #FE8C00;
  color: white;
  border-radius: 0.8em;
}
.page-campaign-spies .splash-item {
  background-color: #FE8C00;
  transform: rotate(15deg);
  top: 30%;
  left: 60%;
}
.page-campaign-spies .conditions {
  font-size: 0.7em;
}
.page-campaign-spies section.light-grey-campaign-section {
  background-color: #f7f7f7;
}
.page-campaign-spies .campaign-section-coop {
  position: relative;
  padding: 1.5em 1em;
}
.page-campaign-spies .campaign-section-coop .left {
  text-align: left;
}
.page-campaign-spies .campaign-section-coop .left h2 {
  font-size: 1em;
}
.page-campaign-spies .campaign-section-coop .left h2, .page-campaign-spies .campaign-section-coop .left h2 > span {
  display: block;
  text-align: left;
}
.page-campaign-spies .campaign-section-coop .left .speed {
  font-size: 5em;
  font-weight: 400;
  line-height: 1;
  padding: 0;
  text-align: left;
}
.page-campaign-spies .campaign-section-coop .left .price {
  font-size: 10em;
  text-align: left;
  position: relative;
  font-weight: 400;
}
@media (max-width: 768px) {
  .page-campaign-spies .campaign-section-coop .left .price {
    text-align: center;
  }
}
.page-campaign-spies .campaign-section-coop .left .price .price-md {
  right: 2.5em;
  top: 34%;
}
@media (max-width: 768px) {
  .page-campaign-spies .campaign-section-coop .left .price .price-md {
    right: 0.5em;
  }
}
@media (max-width: 520px) {
  .page-campaign-spies .campaign-section-coop .left .price .price-md {
    top: 30%;
    right: 0.4em;
  }
}
.page-campaign-spies .campaign-section-coop .left .filler {
  font-size: 1.5em;
  line-height: 0.7em;
}
.page-campaign-spies .campaign-section-coop .left .filler,
.page-campaign-spies .campaign-section-coop .left .price-disclaimer {
  padding-bottom: 0.7em;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
}
.page-campaign-spies .campaign-section-coop .left .price-disclaimer {
  line-height: 1.6;
}
.page-campaign-spies .campaign-section-coop .right {
  position: relative;
}
.page-campaign-spies .campaign-section-coop .right .mvfp-splash {
  position: absolute;
  top: -0.8em;
  left: 1.1em;
}
.page-campaign-spies .campaign-section-coop .right .mvfp-splash .mvfp {
  max-width: 150px;
}
@media (max-width: 768px) {
  .page-campaign-spies .campaign-section-coop .right .mvfp-splash {
    left: -0.5em;
  }
  .page-campaign-spies .campaign-section-coop .right .mvfp-splash .mvfp {
    max-width: 125px;
  }
}
@media (max-width: 520px) {
  .page-campaign-spies .campaign-section-coop .right .mvfp-splash {
    left: -1em;
  }
  .page-campaign-spies .campaign-section-coop .right .mvfp-splash .mvfp {
    max-width: 100px;
  }
}
@media (max-width: 768px) {
  .page-campaign-spies .campaign-section-coop .speed,
.page-campaign-spies .campaign-section-coop .left,
.page-campaign-spies .campaign-section-coop .filler,
.page-campaign-spies .campaign-section-coop .price-disclaimer {
    text-align: center;
  }
}
@media (max-width: 900px) {
  .page-campaign-spies .campaign-section-coop .router {
    max-width: 400px;
  }
}
@media (max-width: 520px) {
  .page-campaign-spies .campaign-section-coop .router {
    max-width: 300px;
  }
}
.page-campaign-spies .campaign-section-coop .conditions {
  font-size: 0.7em;
  max-width: var(--hero-max-width);
}
@media (max-width: 768px) {
  .page-campaign-spies .campaign-section-coop .flex {
    flex-direction: column-reverse;
  }
}
.page-campaign-spies .usp {
  padding: 1em 0;
}
.page-campaign-spies .usp .text {
  width: 90%;
}
.page-campaign-spies .usp h4 {
  text-align: left;
  padding-top: 0;
}
.page-campaign-spies .usp p {
  font-size: medium;
}
.page-campaign-spies .usp img {
  width: 90%;
}
@media (max-width: 768px) {
  .page-campaign-spies .usp .flex {
    flex-direction: column;
  }
  .page-campaign-spies .usp img {
    width: 100%;
  }
  .page-campaign-spies .usp .text {
    width: 100%;
    margin-bottom: 1em;
  }
  .page-campaign-spies .usp h4 {
    padding-top: 1em;
  }
}
@media (max-width: 520px) {
  .page-campaign-spies .usp {
    padding: 0 0.5em 0 0.5em;
  }
}
.page-campaign-spies .footer {
  margin-top: 0;
}

@media (max-width: 520px) {
  .page-campaign-tdcnet .hero-container .hero-background-wrapper .hero-background,
.page-campaign-tdcnet .hero-container--products .hero-background-wrapper .hero-background,
.page-campaign-tdcnet .hero-container--products-fiber .hero-background-wrapper .hero-background {
    bottom: 10em;
  }
}
.page-campaign-tdcnet .hero-container .hero-content .sub-tdcnet,
.page-campaign-tdcnet .hero-container--products .hero-content .sub-tdcnet,
.page-campaign-tdcnet .hero-container--products-fiber .hero-content .sub-tdcnet {
  max-width: 19em;
}
.page-campaign-tdcnet .hero-container .conditions,
.page-campaign-tdcnet .hero-container--products .conditions,
.page-campaign-tdcnet .hero-container--products-fiber .conditions {
  color: #303a4b;
}

.page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content {
  padding: 0 1em;
  max-width: 48em;
  gap: 1em 2em;
}
.page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .stamp {
  position: relative;
}
.page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .splash {
  position: absolute;
  right: -6em;
  transform: rotate(5deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  font-size: 0.5em;
  box-shadow: var(--box-shadow);
  background: linear-gradient(0deg, #d61051 0%, #ed135a 60%);
}
.page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .splash p {
  margin-top: -0.5em;
  font-size: 1em;
  line-height: 1.1;
  color: #ffffff;
  text-align: center;
}
.page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .splash p > span, .page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .splash p > strong {
  display: block;
}
.page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .splash p > strong {
  font-size: 3.25em;
  line-height: 1;
}
.page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .splash p .comma-dash {
  margin-right: 0;
}
.page-campaign-tdcnet-migration .hero-container--tilbud-2 {
  gap: 1em;
  padding: 1em 0.5em;
}
.page-campaign-tdcnet-migration .hero-container--tilbud-2 .call-me-inline {
  background: #ed135a;
  border-radius: 2em;
  box-shadow: var(--box-shadow);
}
.page-campaign-tdcnet-migration .hero-container--tilbud-2 .call-me-inline form {
  margin: 0.5em 0 0;
}
.page-campaign-tdcnet-migration .hero-container--tilbud-2 .call-me-inline .icon {
  color: #ffffff;
  fill: #ed135a;
}
.page-campaign-tdcnet-migration .hero-container--tilbud-2 .conditions {
  padding: 0;
}
@media (min-width: 801px) {
  .page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .stamp {
    margin-bottom: -1.5rem;
  }
  .page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .splash {
    top: -1em;
  }
  .page-campaign-tdcnet-migration .hero-container--tilbud-2 .call-me-inline .content {
    padding: 1em 1.25em 1.25em;
    max-width: 36em;
  }
  .page-campaign-tdcnet-migration .hero-container--tilbud-2 .call-me-inline h3 strong {
    font-size: 1.2em;
  }
}
@media (max-width: 1150px) {
  .page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content {
    gap: 1em;
    max-width: 38em;
  }
}
@media (max-width: 800px) {
  .page-campaign-tdcnet-migration .hero-container--tilbud-1 {
    padding-top: 6.5em;
  }
  .page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .stamp {
    margin-bottom: -1rem;
  }
  .page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .splash {
    top: 0;
  }
  .page-campaign-tdcnet-migration .hero-container--tilbud-2 .call-me-inline .content {
    padding: 0.5em 1em 1em;
    max-width: 32em;
  }
  .page-campaign-tdcnet-migration .hero-container--tilbud-2 .call-me-inline h3 {
    font-size: 1em;
  }
  .page-campaign-tdcnet-migration .hero-container--tilbud-2 .call-me-inline h3 strong {
    font-size: 1.4em;
  }
}
@media (max-width: 520px) {
  .page-campaign-tdcnet-migration .hero-container--tilbud-1 .hero-content .stamp {
    margin-left: -2em;
  }
}

.hero-container--tilbud-1 .stamp,
.hero-container--tilbud-2 .stamp,
.hero-container--tilbud-simple-1 .stamp,
.hero-container--tilbud-simple-2 .stamp,
.hero-container--tilbud-sign-1 .stamp,
.hero-container--tilbud-sign-2 .stamp {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  max-width: none;
  max-height: none;
  border-radius: 50%;
  text-align: center;
}

.hero-container--tilbud-1 .hero-background,
.hero-container--tilbud-simple-1 .hero-background,
.hero-container--tilbud-sign-1 .hero-background {
  max-height: 1080px;
  top: 50%;
  left: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 470px) {
  .hero-container--tilbud-1 .hero-background,
.hero-container--tilbud-simple-1 .hero-background,
.hero-container--tilbud-sign-1 .hero-background {
    max-width: 1080px;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-position: center top;
    background-image: url("../images/pages/home/blank-scene-blur-mobile.jpg");
  }
  .hero-container--tilbud-1 .hero-background .desktop,
.hero-container--tilbud-simple-1 .hero-background .desktop,
.hero-container--tilbud-sign-1 .hero-background .desktop {
    display: none;
  }
}
@media (min-width: 471px) {
  .hero-container--tilbud-1 .hero-background,
.hero-container--tilbud-simple-1 .hero-background,
.hero-container--tilbud-sign-1 .hero-background {
    aspect-ratio: 16/9;
    max-width: 1920px;
    height: 120%;
    transform: translate(-50%, -50%);
    background-image: url("../images/pages/home/blank-scene-blur-desktop.jpg");
  }
  .hero-container--tilbud-1 .hero-background .mobile,
.hero-container--tilbud-simple-1 .hero-background .mobile,
.hero-container--tilbud-sign-1 .hero-background .mobile {
    display: none;
  }
}
@media (min-width: 701px) {
  .hero-container--tilbud-1 .hero-background::after,
.hero-container--tilbud-simple-1 .hero-background::after,
.hero-container--tilbud-sign-1 .hero-background::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: -1px;
    right: -1px;
    bottom: 0;
    width: calc(100% + 2px);
    height: 100%;
    pointer-events: none;
    background: linear-gradient(90deg, #4b848e 0%, rgba(75, 132, 142, 0) 10%, rgba(75, 132, 142, 0) 90%, #4b848e 100%);
  }
}
@media (min-width: 1201px) {
  .hero-container--tilbud-1 .hero-background,
.hero-container--tilbud-simple-1 .hero-background,
.hero-container--tilbud-sign-1 .hero-background {
    max-height: 150%;
    width: 100%;
    height: auto;
    background-position-y: 25%;
  }
}

.hero-container--tilbud-2,
.hero-container--tilbud-simple-2,
.hero-container--tilbud-sign-2 {
  background: linear-gradient(0deg, #eeeeee, #ffffff);
}
.hero-container--tilbud-2 .total-discount-container,
.hero-container--tilbud-2 .stamp-container,
.hero-container--tilbud-2 .cta-container,
.hero-container--tilbud-simple-2 .total-discount-container,
.hero-container--tilbud-simple-2 .stamp-container,
.hero-container--tilbud-simple-2 .cta-container,
.hero-container--tilbud-sign-2 .total-discount-container,
.hero-container--tilbud-sign-2 .stamp-container,
.hero-container--tilbud-sign-2 .cta-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-container--tilbud-2 .total-discount-container,
.hero-container--tilbud-simple-2 .total-discount-container,
.hero-container--tilbud-sign-2 .total-discount-container {
  margin-bottom: 1em;
}
.hero-container--tilbud-2 .total-discount-container .icon,
.hero-container--tilbud-simple-2 .total-discount-container .icon,
.hero-container--tilbud-sign-2 .total-discount-container .icon {
  display: block;
  color: transparent;
  fill: #303a4b;
}
.hero-container--tilbud-2 .total-discount,
.hero-container--tilbud-simple-2 .total-discount,
.hero-container--tilbud-sign-2 .total-discount {
  width: fit-content;
}
.hero-container--tilbud-2 .total-discount table td,
.hero-container--tilbud-simple-2 .total-discount table td,
.hero-container--tilbud-sign-2 .total-discount table td {
  padding: 0.2em 0.5em;
}
.hero-container--tilbud-2 .total-discount table td:first-child,
.hero-container--tilbud-simple-2 .total-discount table td:first-child,
.hero-container--tilbud-sign-2 .total-discount table td:first-child {
  padding-left: 0;
}
.hero-container--tilbud-2 .total-discount table td:nth-child(2),
.hero-container--tilbud-simple-2 .total-discount table td:nth-child(2),
.hero-container--tilbud-sign-2 .total-discount table td:nth-child(2) {
  padding-right: 0;
  text-align: right;
  white-space: nowrap;
}
.hero-container--tilbud-2 .total-discount table tbody td:nth-child(2),
.hero-container--tilbud-simple-2 .total-discount table tbody td:nth-child(2),
.hero-container--tilbud-sign-2 .total-discount table tbody td:nth-child(2) {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.hero-container--tilbud-2 .total-discount table tfoot td,
.hero-container--tilbud-simple-2 .total-discount table tfoot td,
.hero-container--tilbud-sign-2 .total-discount table tfoot td {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  padding-bottom: 0;
  vertical-align: middle;
}
.hero-container--tilbud-2 .total-discount table tfoot td strong,
.hero-container--tilbud-simple-2 .total-discount table tfoot td strong,
.hero-container--tilbud-sign-2 .total-discount table tfoot td strong {
  font-size: 1.5em;
}
.hero-container--tilbud-2 .total-discount table tfoot td,
.hero-container--tilbud-simple-2 .total-discount table tfoot td,
.hero-container--tilbud-sign-2 .total-discount table tfoot td {
  border-top: 1px solid #dad9d7;
}
.hero-container--tilbud-2 .total-discount table tfoot tr:only-child td,
.hero-container--tilbud-simple-2 .total-discount table tfoot tr:only-child td,
.hero-container--tilbud-sign-2 .total-discount table tfoot tr:only-child td {
  border-radius: 0;
}
.hero-container--tilbud-2 .stamp,
.hero-container--tilbud-simple-2 .stamp,
.hero-container--tilbud-sign-2 .stamp {
  background-color: #ed135a;
  color: #ffffff;
}
.hero-container--tilbud-2 .stamp p,
.hero-container--tilbud-simple-2 .stamp p,
.hero-container--tilbud-sign-2 .stamp p {
  font-size: 1.6em;
  line-height: 1.1;
}
.hero-container--tilbud-2 .stamp p > span, .hero-container--tilbud-2 .stamp p > strong,
.hero-container--tilbud-simple-2 .stamp p > span,
.hero-container--tilbud-simple-2 .stamp p > strong,
.hero-container--tilbud-sign-2 .stamp p > span,
.hero-container--tilbud-sign-2 .stamp p > strong {
  display: block;
}
.hero-container--tilbud-2 .stamp p > strong,
.hero-container--tilbud-simple-2 .stamp p > strong,
.hero-container--tilbud-sign-2 .stamp p > strong {
  font-size: 1.75em;
}
.hero-container--tilbud-2 .stamp .comma-dash,
.hero-container--tilbud-simple-2 .stamp .comma-dash,
.hero-container--tilbud-sign-2 .stamp .comma-dash {
  letter-spacing: -0.15em;
  margin-right: 0;
}
.hero-container--tilbud-2 .cta-container p,
.hero-container--tilbud-simple-2 .cta-container p,
.hero-container--tilbud-sign-2 .cta-container p {
  font-weight: 300;
  font-variation-settings: "wght" 300;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-big);
  font-size: 2.8em;
  line-height: 1;
}
.hero-container--tilbud-2 .cta-container p strong,
.hero-container--tilbud-simple-2 .cta-container p strong,
.hero-container--tilbud-sign-2 .cta-container p strong {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  color: #ed135a;
}
.hero-container--tilbud-2 .cta-container p a,
.hero-container--tilbud-simple-2 .cta-container p a,
.hero-container--tilbud-sign-2 .cta-container p a {
  border: 0;
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hero-container--tilbud-2 .cta-container p a:hover,
.hero-container--tilbud-simple-2 .cta-container p a:hover,
.hero-container--tilbud-sign-2 .cta-container p a:hover {
  opacity: 0.75;
}
.hero-container--tilbud-2 .cta-container p span:not(:last-child),
.hero-container--tilbud-simple-2 .cta-container p span:not(:last-child),
.hero-container--tilbud-sign-2 .cta-container p span:not(:last-child) {
  margin-right: 0.125em;
}
.hero-container--tilbud-2 .conditions,
.hero-container--tilbud-simple-2 .conditions,
.hero-container--tilbud-sign-2 .conditions {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  text-align: center;
  color: #303a4b;
  text-shadow: none;
}

.hero-container--tilbud-2 .total-discount,
.hero-container--tilbud-simple-2 .total-discount {
  padding: 1em 1.5em 0.75em;
  border-radius: 1em;
  box-shadow: 0 0 0 4px #ed135a;
  background-color: #ffffff;
}
.hero-container--tilbud-2 .total-discount h3,
.hero-container--tilbud-simple-2 .total-discount h3 {
  padding: 0;
}

.hero-container--tilbud-1,
.hero-container--tilbud-2 {
  gap: 0;
}

.hero-container--tilbud-1,
.hero-container--tilbud-sign-1 {
  padding-bottom: 1em;
}
.hero-container--tilbud-1 .hero-background-wrapper,
.hero-container--tilbud-sign-1 .hero-background-wrapper {
  pointer-events: none;
}
.hero-container--tilbud-1 .hero-content,
.hero-container--tilbud-sign-1 .hero-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  width: fit-content;
}
.hero-container--tilbud-1 .hero-content h1,
.hero-container--tilbud-sign-1 .hero-content h1 {
  padding: 0;
  color: #303a4b;
  font-size: 1em;
  text-align: center;
  line-height: 1;
}
.hero-container--tilbud-1 .hero-content h1 > *,
.hero-container--tilbud-sign-1 .hero-content h1 > * {
  width: unset !important;
}
.hero-container--tilbud-1 .hero-content h1 .pre-1,
.hero-container--tilbud-sign-1 .hero-content h1 .pre-1 {
  font-size: 0.8em;
}
.hero-container--tilbud-1 .hero-content h1 .pre-2,
.hero-container--tilbud-sign-1 .hero-content h1 .pre-2 {
  font-size: 0.6em;
}
.hero-container--tilbud-1 .hero-content h1 .price,
.hero-container--tilbud-sign-1 .hero-content h1 .price {
  font-size: 4.5em;
}
.hero-container--tilbud-1 .hero-content h1 .post,
.hero-container--tilbud-sign-1 .hero-content h1 .post {
  font-size: 0.6em;
}
.hero-container--tilbud-1 .hero-content h1 .price,
.hero-container--tilbud-sign-1 .hero-content h1 .price {
  position: relative;
  margin-left: 0.1em;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
}
.hero-container--tilbud-1 .hero-content h1 .price .md,
.hero-container--tilbud-sign-1 .hero-content h1 .price .md {
  position: absolute;
  top: calc(50% - .2em);
  right: -0.3em;
  transform: translateY(-100%);
  font-size: 0.25em;
  font-weight: 300;
  text-transform: lowercase;
}
.hero-container--tilbud-1 .hero-content h1, .hero-container--tilbud-1 .hero-content h2 .header-sub,
.hero-container--tilbud-sign-1 .hero-content h1,
.hero-container--tilbud-sign-1 .hero-content h2 .header-sub {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
  letter-spacing: normal;
}
.hero-container--tilbud-1 .hero-content h2,
.hero-container--tilbud-sign-1 .hero-content h2 {
  padding: 0;
  font-size: 3.6em;
  text-align: left;
  color: #ffffff;
}
.hero-container--tilbud-1 .hero-content h2 .header-main, .hero-container--tilbud-1 .hero-content h2 .header-sub,
.hero-container--tilbud-sign-1 .hero-content h2 .header-main,
.hero-container--tilbud-sign-1 .hero-content h2 .header-sub {
  display: block;
}
.hero-container--tilbud-1 .hero-content h2 .header-sub,
.hero-container--tilbud-sign-1 .hero-content h2 .header-sub {
  font-size: 0.4em;
  line-height: 1.4;
}
.hero-container--tilbud-1 .hero-content h2 .header-sub .small,
.hero-container--tilbud-sign-1 .hero-content h2 .header-sub .small {
  font-size: 0.75em;
}
@media (min-width: 1201px) {
  .hero-container--tilbud-1 .hero-content h2 .header-sub,
.hero-container--tilbud-sign-1 .hero-content h2 .header-sub {
    font-size: 0.4em;
  }
  .hero-container--tilbud-1.hero-container--aarhus .hero-content h2 .header-sub,
.hero-container--tilbud-sign-1.hero-container--aarhus .hero-content h2 .header-sub {
    font-size: 0.3em;
  }
}
@media (max-width: 1200px) {
  .hero-container--tilbud-1 .hero-content,
.hero-container--tilbud-sign-1 .hero-content {
    gap: 0.75em 2em;
  }
  .hero-container--tilbud-1 .hero-content h2,
.hero-container--tilbud-sign-1 .hero-content h2 {
    font-size: 2.6em;
  }
  .hero-container--tilbud-1 .hero-content h2 .header-sub,
.hero-container--tilbud-sign-1 .hero-content h2 .header-sub {
    font-size: 0.5em;
  }
  .hero-container--tilbud-1.hero-container--aarhus .hero-content h2 .header-sub,
.hero-container--tilbud-sign-1.hero-container--aarhus .hero-content h2 .header-sub {
    font-size: 0.4em;
  }
}
@media (max-width: 900px) {
  .hero-container--tilbud-1 .hero-content h2,
.hero-container--tilbud-sign-1 .hero-content h2 {
    font-size: 2.2em;
  }
}

.hero-container--tilbud-2 .total-discount-container,
.hero-container--tilbud-sign-2 .total-discount-container {
  padding: 0 1em;
}
.hero-container--tilbud-2 .total-discount-container,
.hero-container--tilbud-2 .stamp-container,
.hero-container--tilbud-2 .cta-container,
.hero-container--tilbud-sign-2 .total-discount-container,
.hero-container--tilbud-sign-2 .stamp-container,
.hero-container--tilbud-sign-2 .cta-container {
  gap: 1em 2em;
}
.hero-container--tilbud-2 .equals,
.hero-container--tilbud-sign-2 .equals {
  font-weight: 450;
  font-variation-settings: "wght" 450;
  font-size: 4em;
}
.hero-container--tilbud-2 .total-discount-container .icon,
.hero-container--tilbud-sign-2 .total-discount-container .icon {
  margin: 0 calc(-.2em * 5);
  width: 5em;
  height: 5em;
}
.hero-container--tilbud-2 .address-wrap,
.hero-container--tilbud-sign-2 .address-wrap {
  margin: 0 auto 0.5em;
}
.hero-container--tilbud-2 .address-wrap h2,
.hero-container--tilbud-sign-2 .address-wrap h2 {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  text-align: center;
  color: #303a4b;
}
.hero-container--tilbud-2 .address-wrap h2 .scroll-down-arrow::before,
.hero-container--tilbud-2 .address-wrap h2 .scroll-down-arrow::after,
.hero-container--tilbud-sign-2 .address-wrap h2 .scroll-down-arrow::before,
.hero-container--tilbud-sign-2 .address-wrap h2 .scroll-down-arrow::after {
  background-color: #303a4b;
}

.hero-container--tilbud-1 .hero-content {
  gap: 1em 4em;
}
.hero-container--tilbud-1 .hero-content .stamp {
  box-shadow: var(--box-shadow);
  background-color: #ffffff;
  font-size: 1.5em;
}
.hero-container--tilbud-1 .hero-content .stamp svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.hero-container--tilbud-2 {
  padding: 2em 0 0.5em;
}

.hero-container--tilbud-sign-1 .hero-content .offer-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 10em;
  height: 10em;
  filter: drop-shadow(var(--box-shadow));
}
.hero-container--tilbud-sign-1 .hero-content .offer-container img {
  position: absolute;
  left: 50%;
  bottom: 0;
  z-index: -1;
  transform: translateX(-50%);
  width: 100%;
}
.hero-container--tilbud-sign-1 .hero-content .offer-container h1 {
  font-size: 1.1em;
  color: #ffffff;
  text-align: center;
  hyphens: manual;
}
.hero-container--tilbud-sign-1 .hero-content .offer-container h1 > span {
  margin: 0 auto;
}
.hero-container--tilbud-sign-1 .hero-content .offer-container h1 strong {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
.hero-container--tilbud-sign-1 .hero-content .offer-container h1 .post {
  margin-top: -0.5em;
}
.hero-container--tilbud-sign-1 .hero-content .offer-container div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  background: linear-gradient(0deg, #cccccc 0%, #ffffff 60%);
}
.hero-container--tilbud-sign-1 .hero-content .offer-container p {
  margin-top: 0.5em;
  font-size: 1em;
  line-height: 1.1;
  color: #be1421;
  text-align: center;
}
.hero-container--tilbud-sign-1 .hero-content .offer-container p > span, .hero-container--tilbud-sign-1 .hero-content .offer-container p > strong {
  display: block;
}
.hero-container--tilbud-sign-1 .hero-content .offer-container p > strong {
  font-size: 3.25em;
}
.hero-container--tilbud-sign-1 .hero-content .offer-container p .hiper-text-stroke::after {
  background: linear-gradient(77.5deg, rgba(190, 20, 33, 0) 0, #be1421 80%);
}
.hero-container--tilbud-sign-1 .hero-content .offer-container .comma-dash {
  letter-spacing: -0.15em;
  margin-right: 0;
}
@media (min-width: 801px) and (max-width: 1080px) {
  .hero-container--tilbud-sign-1 .hero-content {
    padding-right: 5em;
  }
}

.hero-container--tilbud-sign-2 .total-discount h3 {
  padding-bottom: 0.25em;
}
.hero-container--tilbud-sign-2 .total-discount h3 .icon {
  display: inline-block;
  margin: 0 0 0 -0.25em;
  width: 1em;
  height: 1em;
  transform: rotate(90deg);
  vertical-align: top;
}
.hero-container--tilbud-sign-2 .total-discount table {
  padding: 0.75em 1em 0.5em;
  border-radius: 1em;
  box-shadow: 0 0 0 4px #ed135a;
  background-color: #ffffff;
}

.hero-container--tilbud-simple-1 {
  padding-bottom: 1em;
  gap: 1em;
}
.hero-container--tilbud-simple-1 .hero-content {
  margin-bottom: -1em;
}
.hero-container--tilbud-simple-1 .hero-content h1 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
  line-height: 1.1;
  text-align: center;
  hyphens: manual;
  margin: 0 auto;
}
.hero-container--tilbud-simple-1 .hero-content h1 span {
  display: inline-block;
}
.hero-container--tilbud-simple-1 .hero-content h1 .hiper-text-stroke::after {
  z-index: -1;
}
.hero-container--tilbud-simple-1 .hero-content h1 > strong {
  font-size: 1.2em;
}
.hero-container--tilbud-simple-1 .hero-content h1 span strong {
  font-size: 1.8em;
}
.hero-container--tilbud-simple-1 .hero-content h1 strong {
  font-weight: 700;
  font-variation-settings: "wght" 700;
}
.hero-container--tilbud-simple-1 .hero-content h1 .price-after {
  padding-top: 0.5em;
  font-size: 0.8em;
}
.hero-container--tilbud-simple-1 .address-wrap h2 {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 2em;
  text-align: center;
  padding: 0;
}
@media (min-width: 521px) {
  .hero-container--tilbud-simple-1 {
    padding-top: 6em;
  }
  .hero-container--tilbud-simple-1 .hero-content h1 {
    font-size: 2em;
  }
  .hero-container--tilbud-simple-1 .address-wrap {
    margin: 2.5em 0 -2.8em;
  }
  .hero-container--tilbud-simple-1 .address-wrap h2 {
    margin-bottom: 0.25rem;
  }
  .hero-container--tilbud-simple-1 .address-wrap h2 .scroll-down-arrow {
    top: 0.15em;
  }
  .hero-container--tilbud-simple-1 .stamp {
    font-size: 0.9em;
  }
}
@media (max-width: 520px) {
  .hero-container--tilbud-simple-1 {
    padding-top: 4em;
  }
  .hero-container--tilbud-simple-1 .hero-content h1 {
    font-size: 1.6em;
  }
  .hero-container--tilbud-simple-1 .address-wrap {
    margin: 1.5em 0 -2.8em;
  }
  .hero-container--tilbud-simple-1 .address-wrap h2 .scroll-down-arrow {
    top: 0.15em;
  }
}

.hero-container--tilbud-simple-2 {
  gap: 0;
  width: 100%;
  padding: 3.5em 1em 0.5em;
}
.hero-container--tilbud-simple-2 .total-discount-container {
  gap: 2em 0;
}
.hero-container--tilbud-simple-2 .total-discount-container .icon {
  width: 6em;
  height: 6em;
}
.hero-container--tilbud-simple-2 .total-discount {
  box-shadow: inset 0 0 0 4px #ed135a, var(--box-shadow);
}
.hero-container--tilbud-simple-2 .stamp {
  box-shadow: var(--box-shadow);
  font-size: 1.2em;
}

.page-campaign-aarhus .content--medium,
.page-campaign-ewii .content--medium,
.page-campaign-fibia .content--medium,
.page-campaign-fibia-fiber .content--medium,
.page-campaign-tilbud .content--medium {
  padding-bottom: 2em;
}
.page-campaign-aarhus .content--medium h3 strong,
.page-campaign-ewii .content--medium h3 strong,
.page-campaign-fibia .content--medium h3 strong,
.page-campaign-fibia-fiber .content--medium h3 strong,
.page-campaign-tilbud .content--medium h3 strong {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  color: #ed135a;
}
.page-campaign-aarhus .content--medium li,
.page-campaign-ewii .content--medium li,
.page-campaign-fibia .content--medium li,
.page-campaign-fibia-fiber .content--medium li,
.page-campaign-tilbud .content--medium li {
  padding: 0;
}
.page-campaign-aarhus .content--medium .content,
.page-campaign-ewii .content--medium .content,
.page-campaign-fibia .content--medium .content,
.page-campaign-fibia-fiber .content--medium .content,
.page-campaign-tilbud .content--medium .content {
  padding-left: 0;
  padding-right: 0;
}
.page-campaign-aarhus .content--medium .content:first-child,
.page-campaign-ewii .content--medium .content:first-child,
.page-campaign-fibia .content--medium .content:first-child,
.page-campaign-fibia-fiber .content--medium .content:first-child,
.page-campaign-tilbud .content--medium .content:first-child {
  padding-top: 0;
}
.page-campaign-aarhus .section-includes,
.page-campaign-ewii .section-includes,
.page-campaign-fibia .section-includes,
.page-campaign-fibia-fiber .section-includes,
.page-campaign-tilbud .section-includes {
  background-color: #f7f7f7;
}
.page-campaign-aarhus .section-includes .grid,
.page-campaign-ewii .section-includes .grid,
.page-campaign-fibia .section-includes .grid,
.page-campaign-fibia-fiber .section-includes .grid,
.page-campaign-tilbud .section-includes .grid {
  gap: 2em;
}
.page-campaign-aarhus .section-includes li,
.page-campaign-ewii .section-includes li,
.page-campaign-fibia .section-includes li,
.page-campaign-fibia-fiber .section-includes li,
.page-campaign-tilbud .section-includes li {
  margin: 0;
  padding: 0;
}
.page-campaign-aarhus .section-includes img,
.page-campaign-ewii .section-includes img,
.page-campaign-fibia .section-includes img,
.page-campaign-fibia-fiber .section-includes img,
.page-campaign-tilbud .section-includes img {
  display: block;
  margin: 0 auto;
  width: 16em;
}
.page-campaign-aarhus .section-includes ul:first-of-type,
.page-campaign-ewii .section-includes ul:first-of-type,
.page-campaign-fibia .section-includes ul:first-of-type,
.page-campaign-fibia-fiber .section-includes ul:first-of-type,
.page-campaign-tilbud .section-includes ul:first-of-type {
  margin-bottom: 4em;
}
.page-campaign-aarhus .section-includes ul:first-of-type img,
.page-campaign-ewii .section-includes ul:first-of-type img,
.page-campaign-fibia .section-includes ul:first-of-type img,
.page-campaign-fibia-fiber .section-includes ul:first-of-type img,
.page-campaign-tilbud .section-includes ul:first-of-type img {
  margin-top: -1.5em;
  margin-bottom: -1.5em;
}
.page-campaign-aarhus .section-includes ul:nth-of-type(2) img,
.page-campaign-ewii .section-includes ul:nth-of-type(2) img,
.page-campaign-fibia .section-includes ul:nth-of-type(2) img,
.page-campaign-fibia-fiber .section-includes ul:nth-of-type(2) img,
.page-campaign-tilbud .section-includes ul:nth-of-type(2) img {
  margin-top: -0.5em;
  margin-bottom: -0.5em;
}
@media (max-width: 660px) {
  .page-campaign-aarhus .section-includes ul:first-of-type,
.page-campaign-ewii .section-includes ul:first-of-type,
.page-campaign-fibia .section-includes ul:first-of-type,
.page-campaign-fibia-fiber .section-includes ul:first-of-type,
.page-campaign-tilbud .section-includes ul:first-of-type {
    margin-bottom: 4em;
  }
}

@media (min-width: 801px) {
  [class*=hero-container--tilbud] .hide-on-large-devices {
    display: none;
  }

  .hero-container--tilbud-1 .hero-content .stamp {
    margin-bottom: -2rem;
  }

  .hero-container--tilbud-sign-1 .hero-content .offer-container div {
    top: -1em;
    right: -7em;
    transform: rotate(5deg);
    font-size: 0.5em;
  }

  .hero-container--tilbud-sign-2 {
    padding: 2em 0 0.5em;
  }
}
@media (min-width: 1151px) {
  .hero-container--tilbud-simple-2 .cta-container {
    margin-left: 2em;
  }

  .hero-container--tilbud-sign-1 .hero-content {
    gap: 1em 2em;
  }
  .hero-container--tilbud-sign-1 .hero-content .offer-container {
    margin-bottom: -2em;
    font-size: 1.6em;
  }
}
@media (max-width: 1150px) {
  .hero-container--tilbud-2 .total-discount-container {
    margin-bottom: 0;
  }

  .hero-container--tilbud-sign-1 .hero-content {
    gap: 1em;
  }
  .hero-container--tilbud-sign-1 .hero-content .offer-container {
    font-size: 1.5em;
  }
}
@media (max-width: 1150px) and (min-width: 801px) {
  .hero-container--tilbud-2 .total-discount-container,
.hero-container--tilbud-simple-2 .total-discount-container {
    flex-wrap: wrap;
  }
  .hero-container--tilbud-2 .cta-container,
.hero-container--tilbud-simple-2 .cta-container {
    width: 100%;
  }
  .hero-container--tilbud-2 .cta-container p,
.hero-container--tilbud-simple-2 .cta-container p {
    margin: 0;
  }
  .hero-container--tilbud-2 .cta-container p br,
.hero-container--tilbud-simple-2 .cta-container p br {
    display: none;
  }

  .hero-container--tilbud-sign-1 .hero-content .offer-container {
    margin-bottom: -1.25em;
  }
}
@media (max-width: 800px) {
  [class*=hero-container--tilbud] .hide-on-small-devices {
    display: none;
  }

  .hero-container--tilbud-1 {
    padding-top: 7em;
  }
  .hero-container--tilbud-1 .hero-background-wrapper::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: calc(100% + 2px);
    pointer-events: none;
    background: linear-gradient(180deg, #4b848e 10%, rgba(75, 132, 142, 0) 40%, rgba(218, 217, 215, 0) 90%, white 100%);
  }
}
@media (max-width: 800px) and (max-width: 420px) {
  .hero-container--tilbud-1 .hero-background-wrapper::after {
    background: linear-gradient(180deg, #4b848e 10%, rgba(75, 132, 142, 0) 40%, rgba(238, 238, 238, 0) 80%, white 100%);
  }
}
@media (max-width: 800px) {
  .hero-container--tilbud-1 .hero-content {
    flex-direction: column-reverse;
    padding: 0;
  }
  .hero-container--tilbud-1 .hero-content h2 {
    text-align: center;
    font-size: 2.8em;
  }
}
@media (max-width: 800px) and (max-width: 520px) {
  .hero-container--tilbud-1 .hero-content h2 {
    font-size: 10vw;
  }
}
@media (max-width: 800px) {
  .hero-container--tilbud-1 .hero-content h2 .header-sub {
    font-size: 0.45em;
  }
}
@media (max-width: 800px) {
  .hero-container--tilbud-1 .hero-content .stamp {
    margin-bottom: -0.5rem;
  }
}
@media (max-width: 800px) {
  .hero-container--tilbud-sign-1 .hero-background-wrapper::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -1px;
    left: 0;
    right: 0;
    bottom: -1px;
    width: 100%;
    height: calc(100% + 2px);
    pointer-events: none;
    background: linear-gradient(0deg, rgba(75, 132, 142, 0.75) 0%, rgba(75, 132, 142, 0) 12em);
  }
  .hero-container--tilbud-sign-1 .hero-content {
    flex-direction: column;
  }
  .hero-container--tilbud-sign-1 .hero-content .offer-container {
    font-size: 1.5em;
  }
  .hero-container--tilbud-sign-1 .hero-content .offer-container div {
    top: 25%;
    right: -6.5em;
    transform: translateY(-50%) rotate(5deg);
    font-size: 0.5em;
  }
}
@media (max-width: 800px) {
  .hero-container--tilbud-2 .stamp-container,
.hero-container--tilbud-2 .total-discount-container .icon,
.hero-container--tilbud-2 .total-discount-container p br,
.hero-container--tilbud-simple-2 .stamp-container,
.hero-container--tilbud-simple-2 .total-discount-container .icon,
.hero-container--tilbud-simple-2 .total-discount-container p br {
    display: none;
  }
  .hero-container--tilbud-2 .total-discount-container,
.hero-container--tilbud-simple-2 .total-discount-container {
    flex-direction: column;
  }
  .hero-container--tilbud-2 .total-discount-container p,
.hero-container--tilbud-simple-2 .total-discount-container p {
    text-align: center;
  }
}
@media (max-width: 800px) {
  .hero-container--tilbud-2 {
    padding: 0.5em 0 0.5em;
  }
  .hero-container--tilbud-2 .total-discount {
    display: none;
  }
}
@media (max-width: 800px) {
  .hero-container--tilbud-sign-2 {
    gap: 0.5em;
    padding: 1em 0 0.5em;
  }
}
@media (max-width: 800px) {
  .hero-container--tilbud-simple-2 {
    padding: 2.5em 1em 0.5em;
  }
  .hero-container--tilbud-simple-2 .total-discount-container {
    margin-bottom: 0.5em;
  }
  .hero-container--tilbud-simple-2 .total-discount {
    padding: 1em 1em 0.5em;
  }
  .hero-container--tilbud-simple-2 .total-discount h3 {
    text-align: center;
  }
}
@media (max-width: 520px) {
  .hero-container--tilbud-sign-1 .hero-content .offer-container {
    margin-left: -2em;
  }
}
#Coi-Renew {
  display: none;
  box-sizing: border-box;
  position: fixed;
  left: 20px;
  bottom: 15px;
  width: 42px;
  height: 42px;
  background: #303a4b;
  z-index: 99999999999;
  padding: 7px;
  cursor: pointer;
  border: 0 !important;
  border-radius: 999rem;
}
#Coi-Renew svg {
  width: 100%;
}
#Coi-Renew .renew_path {
  fill: white !important;
}

/**
Template name: Standard
URI: https://cookieinformation.com
Version 1.0.0
**/
#coiConsentBanner {
  box-shadow: 0 -0.2109375em 0.16875em rgba(0, 0, 0, 0.022), 0 -0.5390625em 0.43125em rgba(0, 0, 0, 0.031), 0 -1.109375em 0.8875em rgba(0, 0, 0, 0.039), 0 -2.28125em 1.825em rgba(0, 0, 0, 0.048), 0 -6.25em 5em rgba(0, 0, 0, 0.07);
  position: fixed;
  width: 100%;
  max-height: 100%;
  z-index: 10000;
  font-size: 14px;
  line-height: 16px;
  background-color: #7ea7ad;
  box-sizing: border-box;
  overflow-y: auto;
}
#coiConsentBanner * {
  text-align: initial;
  white-space: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
#coiConsentBanner * .coi-consent-banner__agree-button,
#coiConsentBanner * .coi-consent-banner__decline-button,
#coiConsentBanner * .summary-texts__show-details,
#coiConsentBanner * .summary-texts__hide-details,
#coiConsentBanner * .summary-texts__title,
#coiConsentBanner * .coi-consent-banner__indicator,
#coiConsentBanner * .coi-consent-banner__category-name--slider,
#coiConsentBanner * .coi-consent-banner__why-cookies h2 {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-big);
}
#coiConsentBanner.BannerLeft {
  left: 0;
}
#coiConsentBanner.BannerRight {
  right: 0;
}
#coiConsentBanner.BannerBottom {
  bottom: 0;
}
#coiConsentBanner .coi-consent-banner__base {
  display: flex;
  width: 100%;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area {
  background-color: #9dbcc1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  color: #ffffff;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group {
  display: flex;
  margin-bottom: 1em;
  width: 100%;
  flex-wrap: wrap;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group .coi-consent-banner__agree-button,
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group .coi-consent-banner__decline-button {
  color: white;
  min-width: 100px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  line-height: 1;
  font-weight: 400;
  padding: 0.5em;
  text-align: center !important;
  flex: 1;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group .coi-consent-banner__agree-button {
  background: #3b8b3b;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group .coi-consent-banner__agree-button:hover {
  background: #337933;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group .coi-consent-banner__decline-button {
  background: #303a4b;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group .coi-consent-banner__decline-button:hover {
  background: #1c222c;
}
@media (max-width: 1200px) {
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group {
    flex-direction: column;
  }
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group .coi-consent-banner__agree-button {
    margin-bottom: 0.5em;
  }
}
@media (min-width: 1201px) {
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group {
    flex-direction: row;
  }
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group .coi-consent-banner__agree-button {
    margin-right: 0.5em;
  }
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group #updateButton {
  display: none;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name {
  position: relative;
  padding: 0 1em 0.5em 0;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 25%;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .coi-consent-banner__category-name:hover .coi-consent-banner__category-checkbox ~ .coi-consent-banner__checkmark {
  border: 2px solid #ccc;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .coi-consent-banner__category-checkbox:checked ~ .coi-consent-banner__checkmark {
  border: 2px solid #303a4b;
  background-color: #303a4b;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .coi-consent-banner__category-name:hover .coi-consent-banner__category-checkbox:checked ~ .coi-consent-banner__checkmark {
  background-color: #1c222c;
  border: 2px solid #1c222c;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .coi-consent-banner__category-checkbox:checked ~ .disabled {
  background-color: #303a4b;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .coi-consent-banner__category-name:hover .coi-consent-banner__category-checkbox:checked ~ .disabled {
  background-color: #1c222c;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .coi-consent-banner__category-checkbox {
  position: absolute;
  display: none;
  opacity: 0;
  cursor: pointer;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .coi-consent-banner__category-checkbox:checked ~ .coi-consent-banner__checkmark:after {
  display: block;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .coi-consent-banner__checkmark {
  position: relative;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #eeeeee;
  margin-right: 0.25em;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .coi-consent-banner__checkmark:after {
  position: absolute;
  display: none;
  content: "";
  left: 5px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories .coi-consent-banner__category-name .cookie_cat_necessary {
  cursor: default;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__category-name {
  color: #000;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .summary-texts__show-details,
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .summary-texts__hide-details {
  text-decoration: none;
  text-align: center;
  font-size: 1.2em;
  line-height: 0.8;
  font-weight: 300;
  color: #fff;
  background-color: #4b848e;
  border-radius: 3px;
  padding: 0.5em;
  cursor: pointer;
  border: none;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .summary-texts__show-details:hover,
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .summary-texts__hide-details:hover {
  color: #ffffff;
  background-color: #61a0ab;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .summary-texts__hide-details {
  display: none;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary .summary-texts__title {
  color: #303a4b;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary .coi-consent-banner__summary-texts {
  color: #000;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary .coi-consent-banner__summary-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary .summary-texts__title {
  font-size: 1.8em;
  font-weight: 500;
  line-height: 1;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary p,
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary ul {
  padding: 0;
  font-size: 1em;
  margin: 0 0 1em 0;
}
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary p:first-of-type,
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary p:last-child {
  margin-bottom: 0;
}
#coiConsentBanner .coi-consent-banner__bottom {
  width: 100%;
  max-height: 0;
  transition: max-height 0.2s;
  overflow: auto;
  background: #fff;
}
#coiConsentBanner .coi-consent-banner__bottom::-webkit-scrollbar {
  width: 0;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__indicators-container {
  display: flex;
  height: auto;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__indicators-container .coi-consent-banner__indicator {
  text-align: center !important;
  padding: 1em;
  font-size: 1.4em;
  font-weight: 500;
  line-height: 0.8;
  color: #303a4b;
  background-color: #f1f1f1;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__indicators-container .coi-consent-banner__indicator:focus {
  outline: initial;
  box-shadow: inset 0 0 0 2px #83afc5;
  border-radius: 3px;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__indicators-container .coi-consent-banner__indicator.active {
  background: #ed135a;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__indicators-container .coi-consent-banner__indicator.active {
  color: #fff;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns {
  width: 100%;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper {
  position: relative;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container {
  border-top: 1px solid #ccc;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-expander:checked ~ .coi-consent-banner__name-container,
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__name-container:hover {
  color: #fff;
  background-color: #303a4b;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-expander {
  position: absolute !important;
  opacity: 0;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-expander:focus + .coi-consent-banner__name-container {
  outline: 1px solid #6495ed;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__name-container {
  font-size: 1.2em;
  line-height: 0.8;
  font-weight: 400;
  color: #303a4b;
  background-color: #f1f1f1;
  cursor: pointer;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__name-container:last-child {
  border-bottom: none;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__name-container label {
  padding: 1em;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container {
  overflow: auto;
}
@media screen and (min-width: 761px) and (max-width: 1300px) {
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container {
    top: -94px;
  }
}
@media screen and (min-width: 1301px) {
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container {
    top: -47px;
  }
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__cookie-details {
  border-top: 1px solid #eee;
  color: #303a4b;
  display: flex;
  box-sizing: border-box;
  background-color: #fcfcfc;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__cookie-details .cookie-details__detail-container {
  font-size: 1em;
  padding: 0 0.5em;
  flex: 1;
  word-break: break-word;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__cookie-details .cookie-details__detail-container .cookie-details__detail-title {
  font-weight: 700;
  color: #303a4b;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__cookie-details a {
  border-bottom: 1px solid #ed135a;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column {
  display: none;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__left-column {
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__left-column .left-column__about-owner {
  font-size: 1em;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__left-column .left-column__website_domains {
  word-break: break-word;
  display: block;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__left-column .left-column__last-scan {
  margin-top: 1em;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__left-column .left-column__bold-text {
  font-weight: 700;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__right-column {
  height: auto;
}
@media screen and (min-width: 761px) and (max-width: 1300px) {
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__right-column {
    margin-top: -94px;
  }
}
@media screen and (min-width: 1301px) {
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__right-column {
    margin-top: -47px;
  }
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__right-column .coi-consent-banner__why-cookies {
  display: flex;
  flex-direction: column;
  color: #000;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__right-column .coi-consent-banner__why-cookies h2 {
  padding: 0;
  font-size: 1.8em;
  font-weight: 500;
  margin-top: 0.5em;
  line-height: 1;
  color: #303a4b;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__right-column .coi-consent-banner__why-cookies h2:first-of-type {
  margin-top: 0;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__right-column .coi-consent-banner__why-cookies p {
  text-align: left;
  padding: 0;
  font-size: 1em;
  margin: 0 0 0.5em 0;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__right-column .coi-consent-banner__why-cookies a {
  font-size: 1em;
  border-bottom: 1px solid #ed135a;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns .coi-consent-banner__about-owner-column .coi-consent-banner__right-column .coi-consent-banner__why-cookies .coi-renew-button {
  border: none;
  background: #ed135a;
  color: #fff;
  display: block;
  width: fit-content;
  padding: 0.5em;
  border-radius: 3px;
  margin-bottom: 0.5em;
  line-height: 1;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.5em;
  background-color: #4b848e;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-bar .coi-brand-logo {
  font-size: 9px;
  line-height: 11px;
  color: #f1f1f1;
  text-decoration: none;
  border: none;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-bar .coi-brand-logo:hover {
  color: #f1f1f1;
}
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-bar .coi-brand-logo span {
  display: block;
  color: #fff;
}
@media screen and (max-width: 760px) {
  #coiConsentBanner {
    overflow-y: auto !important;
  }
  #coiConsentBanner .coi-consent-banner__base {
    flex-direction: column-reverse;
  }
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area,
#coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary {
    width: 100%;
  }
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area {
    padding: 2em 1em;
    flex-direction: column;
  }
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-button-group {
    flex-direction: column;
  }
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__consent-area .coi-consent-banner__cookie-categories {
    margin-bottom: 0.25em;
  }
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary {
    padding: 2em 1em;
  }
  #coiConsentBanner .coi-consent-banner__base .coi-consent-banner__summary .summary-texts__title {
    text-align: center !important;
  }
  #coiConsentBanner .coi-consent-banner__bottom {
    flex-direction: column;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__indicators-container {
    width: 100%;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns {
    max-height: none;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container {
    height: 0;
    width: 100%;
    transition: height 0.9s;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container .coi-consent-banner__category-description {
    padding: 2em 1em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container .coi-consent-banner__cookie-details {
    flex-direction: column;
    padding: 1em 0.5em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container .coi-consent-banner__cookie-details .cookie-details__detail-container {
    line-height: 1.5;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__category-expander:checked ~ .coi-consent-banner__description-container {
    height: auto;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__about-owner-column {
    flex-wrap: wrap-reverse;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__about-owner-column .coi-consent-banner__left-column,
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__about-owner-column .coi-consent-banner__right-column {
    width: 100%;
    padding: 2em 1em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__about-owner-column .coi-consent-banner__left-column {
    padding: 0 1em 2em 1em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__about-owner-column .coi-consent-banner__right-column {
    margin-top: 0;
    padding: 2em 1em 1em 1em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-bar {
    width: 100% !important;
    border-right: none !important;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-bar .coi-brand-logo {
    position: initial;
  }
}
@media screen and (min-width: 761px) {
  #coiConsentBanner .coi-consent-banner__base,
#coiConsentBanner .coi-consent-banner__bottom {
    flex-direction: row;
  }
  #coiConsentBanner .coi-consent-banner__consent-area {
    flex-direction: column;
    width: 25%;
    padding: 1em 2em;
  }
  #coiConsentBanner .coi-consent-banner__cookie-categories {
    margin-bottom: 1em;
  }
  #coiConsentBanner .coi-consent-banner__category-name:last-child {
    padding-right: 0;
  }
  #coiConsentBanner .coi-consent-banner__checkmark {
    transition: background-color 0.2s;
  }
  #coiConsentBanner .coi-consent-banner__category-name:hover .coi-consent-banner__category-checkbox ~ .coi-consent-banner__checkmark {
    background-color: #ed135a;
  }
  #coiConsentBanner .coi-consent-banner__summary {
    flex: 3 0 0;
    padding: 1em 2em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__indicators-container {
    width: 25%;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__bottom-columns {
    max-height: 340px;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__name-container {
    width: 25%;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container {
    height: auto;
    bottom: 0;
    right: 0;
    position: absolute;
    width: 75%;
    background: #fff;
    z-index: 1;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container .coi-consent-banner__category-description {
    padding: 1em 2em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container .coi-consent-banner__cookie-details {
    flex-direction: row;
    padding: 1em 1.5em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container .coi-consent-banner__cookie-details .cookie-details__detail-container.cookie-details__detail-container-provider {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 25%;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container .coi-consent-banner__cookie-details .cookie-details__detail-container.cookie-details__detail-container-purpose {
    width: 50%;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container .coi-consent-banner__cookie-details .cookie-details__detail-container.cookie-details__detail-container-expiry {
    width: 10%;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__description-container .coi-consent-banner__cookie-details .cookie-details__detail-title {
    margin-right: 0.25em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__category-container:nth-child(1) .coi-consent-banner__description-container {
    z-index: 2;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__category-expander:checked ~ .coi-consent-banner__description-container {
    z-index: 100;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__left-column,
#coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__right-column {
    padding: 1em 2em;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__left-column {
    width: 25%;
  }
  #coiConsentBanner .coi-consent-banner__bottom .coi-consent-banner__right-column {
    flex: 4 0 0;
  }
}
@media screen and (max-width: 1300px) {
  #coiConsentBanner .coi-consent-banner__indicators-container {
    flex-direction: column;
  }
}
@media screen and (min-width: 1301px) {
  #coiConsentBanner .coi-consent-banner__indicators-container {
    flex-direction: row;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  #coiConsentBanner .coi-consent-banner__base {
    flex-direction: row !important;
  }
  #coiConsentBanner .coi-consent-banner__consent-area {
    width: 25%;
    flex: none;
  }
  #coiConsentBanner .coi-consent-banner__summary {
    text-align: left !important;
    width: 75%;
    display: block;
  }
}
@media screen and (-ms-high-contrast: active) and (max-width: 700px), screen and (-ms-high-contrast: none) and (max-width: 700px) {
  #coiConsentBanner .coi-consent-banner__base {
    display: block !important;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  #coiConsentBanner .coi-consent-banner__indicator {
    display: block;
    max-width: 50%;
  }
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  #coiConsentBanner .coi-consent-banner__left-column {
    width: 25%;
    flex: none;
  }
}

/**
Template name: Overlay v2
URI: https://cookieinformation.com
Version 1.1.0
**/
.noScroll,
.noScroll body {
  overflow: hidden !important;
  position: relative;
  height: 100%;
}

#coiOverlay {
  background: rgba(0, 0, 0, 0.7);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99999999999;
  padding: 1em;
  backdrop-filter: blur(4px);
}
@-webkit-keyframes ci-bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(2px);
  }
  25% {
    transform: translateY(-4px);
  }
  50% {
    transform: translateY(4px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes ci-bounce {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(2px);
  }
  25% {
    transform: translateY(-4px);
  }
  50% {
    transform: translateY(4px);
  }
  75% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}
#coiOverlay .ci-bounce {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-name: bounce;
  animation-name: ci-bounce;
}
#coiOverlay .coi-banner__wrapper {
  width: 100%;
  max-width: 600px;
  max-height: 100%;
  background-color: #42747d;
  color: #ffffff;
  overflow-x: hidden;
  border-radius: 0.3125em;
}
#coiOverlay .coi-banner__wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  z-index: 11000;
}
#coiOverlay .coi-banner__wrapper::-webkit-scrollbar-track {
  background-color: none;
}
#coiOverlay .coi-banner__wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
#coiOverlay .coi-banner__wrapper #coiConsentBannerCategoriesWrapper::-webkit-scrollbar-thumb,
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
#coiOverlay .coi-banner__wrapper #coiConsentBannerCategoriesWrapper::-webkit-scrollbar-track,
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper::-webkit-scrollbar-track {
  background-color: none;
}
#coiOverlay .coi-banner__wrapper #coiConsentBannerCategoriesWrapper::-webkit-scrollbar,
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__description-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__description-container::-webkit-scrollbar-track {
  background-color: none;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__description-container::-webkit-scrollbar {
  width: 6px;
  background-color: #42747d;
  border-radius: 2px;
  display: none;
}
#coiOverlay .coi-banner__wrapper * {
  white-space: normal;
  text-align: left;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
}
#coiOverlay .coi-banner__wrapper h2, #coiOverlay .coi-banner__wrapper h3 {
  color: #ffffff;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  letter-spacing: 0.025em;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.1;
}
#coiOverlay .coi-banner__wrapper p, #coiOverlay .coi-banner__wrapper a {
  color: #ffffff;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: normal;
}
#coiOverlay .coi-banner__wrapper a {
  border: none;
  text-decoration: underline 1px #ed135a;
  text-underline-offset: 0.1em;
  transition: text-decoration-color 0.2s ease;
}
#coiOverlay .coi-banner__wrapper a:hover {
  color: #ffffff;
  text-decoration-color: #ffffff;
}
#coiOverlay .coi-banner__wrapper ul, #coiOverlay .coi-banner__wrapper ol {
  padding: 0;
  list-style-position: inside;
}
#coiOverlay .coi-banner__wrapper table td,
#coiOverlay .coi-banner__wrapper table th {
  padding: 0;
  border: none;
  text-transform: none;
}
#coiOverlay .coi-banner__wrapper .coi-purpose-list {
  display: inline;
  list-style: none;
  padding: 0;
}
#coiOverlay .coi-banner__wrapper .coi-purpose-list li {
  display: inline;
}
#coiOverlay .coi-banner__wrapper .coi-purpose-list li::after {
  content: ", ";
}
#coiOverlay .coi-banner__wrapper .coi-purpose-list li:last-child::after {
  content: "\a";
  white-space: pre;
}
#coiOverlay .coi-banner__wrapper .coi-banner__page {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  display: block;
  height: 100%;
}
#coiOverlay .coi-banner__wrapper .coi-banner__page,
#coiOverlay .coi-banner__wrapper .coi-banner__summary,
#coiOverlay .coi-banner__wrapper .coi-banner__cookiedeclaration,
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper,
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper {
  width: 100%;
}
#coiOverlay .coi-banner__wrapper #hide_details,
#coiOverlay .coi-banner__wrapper #updateButton {
  display: none;
}
#coiOverlay .coi-banner__wrapper .coi-banner__summary,
#coiOverlay .coi-banner__wrapper .coi-banner__cookiedeclaration {
  background-color: #4b848e;
}
#coiOverlay .coi-banner__wrapper .coi-banner__headline {
  display: block;
  font-size: 2.4em;
  text-align: center;
  font-weight: 500;
  padding: 0;
}
#coiOverlay .coi-banner__wrapper .coi-banner__maintext a.coi-banner__policy,
#coiOverlay .coi-banner__wrapper .coi-toggle-group button {
  text-align: center;
  font-weight: 500;
  color: #ffffff;
}
#coiOverlay .coi-banner__wrapper .coi-banner__maintext {
  padding-top: 0.7em;
}
#coiOverlay .coi-banner__wrapper .coi-banner__maintext .top-column__website-domains {
  display: block;
  font-weight: 400;
}
#coiOverlay .coi-banner__wrapper .coi-banner__maintext .top-column__bold-text {
  font-weight: 700;
  display: block;
}
#coiOverlay .coi-banner__wrapper .coi-banner__maintext .top-column__bold-text:not(:first-child) {
  margin-top: 0.5em;
}
#coiOverlay .coi-banner__wrapper .coi-banner__maintext :last-child {
  margin-bottom: 0;
}
#coiOverlay .coi-banner__wrapper .coi-banner__maintext a.coi-banner__policy {
  display: block;
  width: fit-content;
  margin: 0 auto;
}
#coiOverlay .coi-banner__wrapper .coi-toggle-group {
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
}
#coiOverlay .coi-banner__wrapper .coi-toggle-group button {
  border: 0;
  padding: 0;
  background: none;
  text-decoration: underline 1px #ed135a;
  text-underline-offset: 0.1em;
  cursor: pointer;
  transition: text-decoration-color 0.2s ease;
}
#coiOverlay .coi-banner__wrapper .coi-toggle-group button:hover {
  text-decoration-color: #ffffff;
}
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper {
  overflow-x: hidden;
}
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper h2 {
  font-size: 1.8em;
}
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper h2:not(:first-child) {
  padding-top: 0.7rem;
}
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper ul:not(:last-child) {
  padding-bottom: 0.7em;
}
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper ul li:last-child {
  padding-bottom: 0;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper {
  display: none;
  transition: all 0.2s ease-in-out;
  overflow-x: hidden;
  position: relative;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container:last-child {
  margin-bottom: 0;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-controls .coi-consent-banner__category-name {
  cursor: pointer;
  display: flex;
  align-items: baseline;
  border: none;
  padding: 0;
  margin-bottom: 0.35em;
  background-color: transparent;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-controls .coi-consent-banner__category-name .ci-arrow {
  border: solid #ffffff;
  border-width: 0 0.2em 0.2em 0;
  display: inline-block;
  padding: 0.25em;
  transition: all 0.2s ease;
  margin: 0 0.6em 0.5em -0.1em;
  width: 0.5em;
  height: 0.5em;
  top: -0.225em;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  position: relative;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-controls .coi-consent-banner__category-name.ci-btn-tab-active .ci-arrow {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: -0.375em;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-controls .coi-consent-banner__category-name h3 {
  font-size: 1.5em;
  word-break: keep-all;
  padding: 0;
  margin: 0;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-controls .coi-consent-banner__category-description {
  padding: 0 0 0 1.2em;
  font-weight: 400;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container {
  display: none;
  width: 100%;
  align-self: flex-end;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container.tab-panel-active {
  display: block;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies {
  display: flex;
  flex-direction: column;
  padding-left: 1.2em;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .coi-consent-banner__cookie-details {
  flex-direction: column;
  padding: 1em 0;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .coi-consent-banner__cookie-details .cookie-details__detail-container,
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .coi-consent-banner__cookie-details .cookie-details__detail-container a {
  flex: 1;
  word-break: break-word;
  display: flex;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .coi-consent-banner__cookie-details .cookie-details__detail-container {
  font-weight: 400;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .coi-consent-banner__cookie-details .cookie-details__detail-container.cookie-details__detail-container-data-processor-name {
  font-weight: 500;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .coi-consent-banner__cookie-details .cookie-details__detail-container .cookie-details__detail-title {
  font-weight: 700;
  flex: 2;
  padding-right: 0.5em;
}
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .coi-consent-banner__cookie-details .cookie-details__detail-container .cookie-details__detail-content {
  flex: 4;
}
#coiOverlay .coi-banner__wrapper .coi-button-group button span,
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes .checkbox-text {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-align: center;
  color: #ffffff;
  line-height: 0.8;
}
#coiOverlay .coi-banner__wrapper .coi-button-group button span,
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes span {
  pointer-events: none;
}
#coiOverlay .coi-banner__wrapper .coi-banner__page-footer {
  background: #7acfe0;
  width: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  bottom: 3em;
  z-index: 5000;
}
#coiOverlay .coi-banner__wrapper .coi-button-group {
  display: flex;
  justify-content: center;
  gap: 0.5em 1em;
  width: 100%;
}
#coiOverlay .coi-banner__wrapper .coi-button-group button {
  padding: 0.5em;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125em;
  width: calc(50% - .5em);
  height: auto;
  min-height: 4em;
  border: 0;
  background: none;
  transition: all 0.2s ease;
  background-color: #4b848e;
}
#coiOverlay .coi-banner__wrapper .coi-button-group button:hover {
  background-color: #3f6e77;
}
#coiOverlay .coi-banner__wrapper .coi-button-group button span {
  font-size: 1.5em;
  font-weight: 500;
}
#coiOverlay .coi-banner__wrapper .coi-button-group button.coi-banner__accept {
  background-color: #3b8b3b;
}
#coiOverlay .coi-banner__wrapper .coi-button-group button.coi-banner__accept:hover {
  background-color: #388438;
}
#coiOverlay .coi-banner__wrapper .coi-button-group .coi-banner__accept:not(:disabled),
#coiOverlay .coi-banner__wrapper .coi-button-group .coi-banner__decline:not(:disabled),
#coiOverlay .coi-banner__wrapper .coi-button-group .coi-banner__lastpage:not(:disabled),
#coiOverlay .coi-banner__wrapper .coi-button-group .coi-banner__nextpage:not(:disabled) {
  cursor: pointer;
  display: flex;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background-color: #7acfe0;
  border-top: 1px solid #6caebb;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em 0.5em;
  border-right: solid 1px #6caebb;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field:last-child {
  border-right: 0;
}
@media (min-width: 576px) {
  #coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field {
    width: 25%;
  }
}
@media (min-width: 301px) and (max-width: 577px) {
  #coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field {
    width: 50%;
  }
  #coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field:first-child, #coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field:nth-child(2) {
    border-bottom: solid 1px #6caebb;
  }
  #coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field:nth-child(2) {
    border-right: 0;
  }
}
@media (max-width: 300px) {
  #coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field {
    width: 100%;
    border-right: 0;
  }
  #coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field:not(:last-child) {
    border-bottom: solid 1px #6caebb;
  }
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div {
  display: flex;
  flex: 1;
  justify-content: center;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  clip: initial;
  left: 0;
  top: 0;
  display: block;
  border: none;
  background-color: transparent;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes .checkbox-toggle {
  background: #6caebb;
  width: 3.5em;
  height: 2em;
  border-radius: 1em;
  position: relative;
  cursor: pointer;
  transition: 0.2s ease;
  margin-top: 0.5em;
  transform-origin: center;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes .checkbox-toggle::before {
  transition: 0.2s ease;
  content: "";
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  background: #ffffff;
  left: 0.25em;
  top: 0.25em;
  border-radius: 50%;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes input[type=checkbox]:disabled,
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes input[type=checkbox]:disabled + .checkbox-toggle {
  cursor: default;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes input[type=checkbox]:disabled + .checkbox-toggle {
  opacity: 0.5;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes input[type=checkbox]:checked + .checkbox-toggle {
  background-color: #303a4b;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes input[type=checkbox]:checked + .checkbox-toggle::before {
  left: 1.75em;
}
#coiOverlay .coi-banner__wrapper .coi-banner-consent-group .coi-banner-consent-field div .coi-checkboxes .checkbox-text {
  font-size: 1.25em;
  font-weight: 400;
}
#coiOverlay .coi-banner__wrapper .coi-banner__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 3em;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  bottom: 0;
  background-color: #39656d;
}
#coiOverlay .coi-banner__wrapper .coi-banner__footer svg {
  max-height: 100%;
}
#coiOverlay .coi-banner__wrapper .coi-banner__footer svg .logo-text {
  fill: #ffffff;
}
#coiOverlay .coi-banner__wrapper .coi-banner__footer svg .logo-stroke {
  fill: url(#logo-gradient);
}
#coiOverlay .coi-banner__wrapper .coi-banner__footer .coi-banner__branding {
  font-size: 0.6em;
  line-height: 0.8;
  color: rgba(255, 255, 255, 0.8);
  text-align: right;
}
#coiOverlay .coi-banner__wrapper .coi-banner__footer .coi-banner__branding a {
  display: block;
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2em;
  font-weight: bold;
}
@media (min-width: 521px) {
  #coiOverlay .coi-banner__wrapper .coi-banner__cookiedeclaration {
    padding: 2em;
  }
  #coiOverlay .coi-banner__wrapper .coi-banner__summary,
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper {
    padding: 2em;
  }
  #coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper {
    padding: 2em;
  }
  #coiOverlay .coi-banner__wrapper .coi-banner__page-footer {
    padding: 1em 2em;
  }
  #coiOverlay .coi-banner__wrapper .coi-banner__footer {
    padding: 0.5em 2em;
  }
}
@media (max-width: 520px) {
  #coiOverlay .coi-banner__wrapper .coi-banner__cookiedeclaration {
    padding: 2em 1em;
  }
  #coiOverlay .coi-banner__wrapper .coi-banner__summary,
#coiOverlay .coi-banner__wrapper .cookiedeclaration_wrapper {
    padding: 2em 1em;
  }
  #coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper {
    padding: 2em 1em;
  }
  #coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies {
    padding-left: 0.7em;
  }
  #coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .cookie-details__detail-container {
    flex-direction: column;
    padding: 0.25em 0.5em;
    border-radius: 0.1875em;
  }
  #coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .cookie-details__detail-container:nth-child(even) {
    background-color: #39656d;
  }
  #coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies .cookie-details__detail-container td {
    margin: 0;
    padding: 0;
  }
  #coiOverlay .coi-banner__wrapper .coi-banner__page-footer {
    padding: 1em;
  }
  #coiOverlay .coi-banner__wrapper .coi-button-group button {
    margin: 0;
  }
  #coiOverlay .coi-banner__wrapper .coi-banner__footer {
    padding: 0.5em 1em;
  }
}
@media (max-width: 400px) {
  #coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__category-controls .coi-consent-banner__category-description,
#coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies {
    padding-left: 0;
  }
  #coiOverlay .coi-banner__wrapper .coi-consent-banner__categories-wrapper .coi-consent-banner__category-container .coi-consent-banner__description-container .coi-consent-banner__found-cookies {
    width: calc(100% + 1em);
    margin-left: -0.5em;
  }
  #coiOverlay .coi-banner__wrapper .coi-button-group {
    flex-direction: column;
  }
  #coiOverlay .coi-banner__wrapper .coi-button-group button {
    width: 100%;
  }
}
#coiOverlay #coi-banner-categories {
  display: none;
}

/* Focus Ring Style START */
#coi-banner-wrapper *:focus-visible,
.coi-checkboxes input:focus-visible + .checkbox-toggle,
#Coi-Renew:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #303a4b;
  border-radius: 1px;
}

#coiConsentBannerCategoriesWrapper:focus-visible {
  outline-offset: -2px !important;
}

.coi-banner__wrapper:focus {
  outline: none !important;
}

/* Focus Ring Style END*/
.coi-consent-banner__no-cookies {
  margin: 0;
  font-size: 1em;
}

.coi-consent-banner__category-expander {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.carousel {
  position: relative;
  --carousel-width: 100vw;
  --carousel-height: 25em;
  --carousel-item-letter-spacing: .02rem;
  --carousel-shout-font-size: 3.75em;
  --line-display: inline;
}
@media (min-width: 901px) {
  .carousel {
    --line-display: block;
  }
}
@media (max-width: 900px) {
  .carousel {
    --carousel-height: 21.875em;
    --carousel-item-letter-spacing: -.05rem;
    --carousel-shout-font-size: 3.125em;
  }
}
@media (max-width: 400px) {
  .carousel {
    --carousel-height: 18.75em;
    --carousel-shout-font-size: 2.5em;
  }
}

.carousel-viewport {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.carousel-frame {
  width: 100vw;
  height: 25em;
  height: var(--carousel-height);
}

.carousel-scroll {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  list-style: none;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}
.carousel-scroll::-webkit-scrollbar {
  display: none;
}

.carousel-item-outer {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.carousel-item {
  display: flex;
  width: 100vw;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #ed135a;
  line-height: 1;
  letter-spacing: var(--carousel-item-letter-spacing);
}
.carousel-item * {
  color: inherit;
  line-height: inherit;
}
.carousel-item p {
  margin: 0;
  padding: 0;
}
.carousel-item .carousel-item__button {
  text-align: right;
}
.carousel-item .carousel-item__button .button {
  color: white;
}
.carousel-item .carousel-item__button .button .icon {
  display: inline-block;
  width: 0.55em;
  height: 0.55em;
  line-height: 0.55em;
}
.carousel-item .line {
  display: var(--line-display);
}
.carousel-item .carousel-item__shout {
  font-size: 2.5em;
  font-size: var(--carousel-shout-font-size);
  font-weight: 500;
  text-transform: uppercase;
}
.carousel-item .carousel-item__shout .bluegray {
  color: #344049;
}

@supports (scroll-snap-align: start) {
  .carousel-scroll {
    scroll-snap-type: x mandatory;
  }

  .carousel-item-outer {
    scroll-snap-align: center;
  }
}
@supports not (scroll-snap-align: start) {
  .carousel-scroll {
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: mandatory;
    -webkit-scroll-snap-destination: 0% center;
    scroll-snap-destination: 0% center;
    -webkit-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%);
  }

  .carousel-item-outer {
    scroll-snap-coordinate: 0 0;
  }
}
.carousel-controls {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  justify-content: center;
  margin: 0;
  padding: 0 0.3125em;
  list-style: none;
}

.carousel-control {
  padding: 0;
}
.carousel-control button {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  border: none;
  background: none;
  color: #444444;
  cursor: pointer;
}
.carousel-control button .icon {
  max-width: 1.875em;
  max-height: 1.875em;
  margin: 0.75em 0.9375em 1.0625em;
  font-size: 1.125em;
  color: inherit;
  transition: 0.15s all cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.carousel-control button .play,
.carousel-control button .circle {
  opacity: 0;
}
.carousel-control button[aria-pressed=true] .play,
.carousel-control button[aria-pressed=true] .circle {
  opacity: 1;
}
.carousel-control button[aria-pressed=true] .pause {
  opacity: 0;
}
.carousel-control button:hover {
  color: #ed135a;
}
.carousel-control button:hover .icon {
  transition-duration: 0.1s;
}
.carousel-control button:active {
  color: #d61051;
}
.carousel-control button:focus {
  box-shadow: 0 0 0 3px #7ea7ad;
}

.carousel-item__giga-249 {
  background: #d4eada;
}

.carousel-item__support {
  background: #b4dcea;
}

.carousel-item__win-tv {
  background: #b4dcea;
  --carousel-shout-font-size: 2.5em;
}
.carousel-item__win-tv .tv {
  display: flex;
  align-items: center;
}
.carousel-item__win-tv .tv-image {
  background-image: url("../images/carousel/tv.png");
  background-size: cover;
  min-width: 15em;
  min-height: 15em;
  margin-right: 2em;
}
.carousel-item__win-tv .tv-text p {
  font-size: 1.4em;
  color: #303a4b;
}
.carousel-item__win-tv .conditions {
  color: #666666;
  max-width: 55em;
  font-size: 0.8em;
}
@media (max-width: 780px) {
  .carousel-item__win-tv {
    --carousel-shout-font-size: 2.1875em;
  }
  .carousel-item__win-tv .tv-image {
    min-width: 10em;
    min-height: 10em;
  }
  .carousel-item__win-tv .conditions {
    padding-top: 1em;
  }
}
@media (max-width: 550px) {
  .carousel-item__win-tv {
    --carousel-shout-font-size: 1.75em;
  }
  .carousel-item__win-tv .tv-image {
    min-width: 6em;
    min-height: 6em;
    margin-right: 1em;
  }
  .carousel-item__win-tv .conditions {
    font-size: 0.9em;
  }
}
@media (max-width: 370px) {
  .carousel-item__win-tv .tv-image {
    display: none;
  }
}

.carousel-item__stream-big {
  background-color: black;
  background-image: url("../images/carousel/one-400.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-style: italic;
  color: white;
  --carousel-shout-font-size: 8.75em;
}
@media (min-width: 1024px) {
  .carousel-item__stream-big {
    background-image: url("../images/carousel/one-600.jpg");
  }
}
@media (min-width: 1200px) {
  .carousel-item__stream-big {
    background-image: url("../images/carousel/one-800.jpg");
  }
}
@media (max-width: 900px) {
  .carousel-item__stream-big {
    --carousel-shout-font-size: 6.25em;
  }
}
@media (max-width: 400px) {
  .carousel-item__stream-big {
    --carousel-shout-font-size: 4.375em;
  }
}

.carousel-item__stream-big-uke {
  background-color: black;
  background-image: url("../images/carousel/stream-big-uke-400.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-style: italic;
  color: white;
  --carousel-shout-font-size: 8.75em;
}
@media (min-width: 1024px) {
  .carousel-item__stream-big-uke {
    background-image: url("../images/carousel/stream-big-uke-600.jpg");
  }
}
@media (min-width: 1200px) {
  .carousel-item__stream-big-uke {
    background-image: url("../images/carousel/stream-big-uke-800.jpg");
  }
}
@media (max-width: 900px) {
  .carousel-item__stream-big-uke {
    --carousel-shout-font-size: 6.25em;
  }
}
@media (max-width: 400px) {
  .carousel-item__stream-big-uke {
    --carousel-shout-font-size: 4.375em;
  }
}

.page-home .carousel {
  --carousel-height: 18.75em;
}
@media (min-width: 1500px) {
  .page-home .carousel {
    --carousel-height: 25em;
  }
}
@media (max-width: 900px) {
  .page-home .carousel {
    --carousel-height: 15.625em;
  }
}
@media (max-width: 400px) {
  .page-home .carousel {
    --carousel-height: 15.625em;
  }
}

.carousel-item__new_homepage {
  background-color: black;
  background-image: url("../images/carousel/stream-big-uke-400.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  text-shadow: 0 0.15em 0.5em rgba(0, 0, 0, 0.75);
}
.carousel-item__new_homepage .carousel-item__shout {
  font-style: italic;
  text-align: left;
  text-shadow: 0 0.1em 0.25em rgba(190, 0, 90, 0.75);
}
@media (min-width: 901px) {
  .carousel-item__new_homepage {
    background-image: url("../images/carousel/stream-big-uke-600.jpg");
  }
  .carousel-item__new_homepage .carousel-item__shout {
    min-width: 10em;
  }
}
@media (min-width: 1200px) {
  .carousel-item__new_homepage {
    background-image: url("../images/carousel/stream-big-uke-800.jpg");
  }
  .carousel-item__new_homepage .carousel-item__shout {
    min-width: 10em;
  }
}

/*
.page-home .usp-section .usps,
.page-home .usp-section,
.page-products .products .content {

    transition: blur, opacity .18s $ease-in-out-cubic;

    @at-root {
        .address-lookup-typing &,
        .show-manual-address & {
            filter:  blur(.075em);
            opacity: .35;
        }
    }
}

.below-address-lookup {

    transition: blur, opacity .18s $ease-in-out-cubic;

    @at-root {
        .address-lookup-typing & {
            filter:   blur(.075em);
            opacity:  .35;
            position: relative;
            z-index:  -1;
        }
    }
}
*/
#address-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
#address-overlay.active {
  opacity: 1;
  pointer-events: all;
}

.address-widget-wrap {
  position: relative;
  height: 3.5em;
}

.address-widget {
  max-width: var(--hero-max-width);
  width: 100%;
  min-height: 1em;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 5;
  margin: auto;
  padding: 0.4em;
  border-radius: 1.65em;
  background-color: #303a4b;
  transition: background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), padding 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-radius 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), top 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), width 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
html:not(.address-lookup-typing) .address-widget-wrap.address-widget-red .address-widget:not(.focus) {
  background-color: #ed135a;
}

.address-lookup-typing .address-widget, .show-manual-address .address-widget {
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.3);
}
.address-widget li,
.address-widget input {
  text-align: center;
}
.address-widget .error {
  box-shadow: 0 0 0 1px #303a4b, 0 0 0 3px #ed135a, 0 0 0 4px #303a4b;
}
.address-widget .dawa-address,
.address-widget .manual-address {
  display: flex;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  position: relative;
}
.address-widget__left {
  width: 100%;
}
.address-widget__left #address-field {
  width: calc(100% - 2.9rem);
  border: none;
  border-radius: 1.25rem 0.5rem 0.5rem 1.25rem;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), width 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), height 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.address-widget-wrap.address-widget-red .address-widget__left #address-field {
  background-color: rgba(0, 0, 0, 0.1);
}

.address-widget-wrap .address-widget.typing .address-widget__left #address-field, .address-widget-wrap .address-widget__left #address-field:focus, .address-widget-wrap .address-widget__left #address-field:not(:placeholder-shown) {
  background-color: #ffffff;
  color: #000000;
}
.address-widget-wrap .address-widget.typing .address-widget__left #address-field::placeholder, .address-widget-wrap .address-widget__left #address-field:focus::placeholder, .address-widget-wrap .address-widget__left #address-field:not(:placeholder-shown)::placeholder {
  color: #83aec4;
}

.address-widget__left #address-field::placeholder {
  color: #fff;
  transition: color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.address-widget__left .dawa-address__propositions-wrap.visible {
  height: auto;
  max-height: 13.75em;
}
.address-widget__left li {
  color: rgba(255, 255, 255, 0.8);
}

.address-widget__right {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  transition: background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.address-widget__right .icon {
  width: 2.5em;
  height: 2.5em;
  margin: 0;
  transition: width 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), height 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.state-search .state-search {
  display: block;
}
.state-search .state-go {
  display: none;
}

.state-go .state-go {
  display: block;
  animation: 2s cubic-bezier(0.215, 0.61, 0.355, 1) button-state-go infinite;
}
@keyframes button-state-go {
  10% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(3px);
  }
  30% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(0);
  }
}
.state-go .state-search {
  display: none;
}

.address-widget .search-failed .dawa-address__address-list {
  display: none;
  background-image: none;
}
.address-widget .search-failed .dawa-address__bottom-bar {
  display: none;
}
.address-widget .search-failed .dawa-address__search-error {
  display: block;
}

.address-widget-floating .address-widget .address-widget-shimmer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: 1.65em;
  transition: border-radius 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.address-widget-floating .address-widget .address-widget-shimmer::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  pointer-events: none;
  animation: addressLookupShimmer 10s ease 6;
}
@media (min-width: 521px) {
  .address-widget-floating .address-widget .address-widget-shimmer::after {
    background: linear-gradient(120deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 60%);
  }
  @keyframes addressLookupShimmer {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(-100%);
    }
    70% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
@media (max-width: 520px) {
  .address-widget-floating .address-widget .address-widget-shimmer::after {
    background: linear-gradient(120deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 70%);
  }
  @keyframes addressLookupShimmer {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(-100%);
    }
    65% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}
html.address-lookup-typing .address-widget-floating .address-widget .address-widget-shimmer, html.show-manual-address .address-widget-floating .address-widget .address-widget-shimmer, .address-widget-floating .address-widget.focus .address-widget-shimmer {
  display: none;
}
.address-widget-floating .address-widget.sticky {
  position: fixed;
  top: 3.75em;
}
@media (max-width: 520px) {
  .address-widget-floating .address-widget.sticky:not(.focus):not(.typing) {
    padding: 0.25em;
  }
  .address-widget-floating .address-widget.sticky:not(.focus):not(.typing) .dawa-address__propositions-wrap.visible {
    margin-top: 0.25em;
  }
  .address-widget-floating .address-widget.sticky:not(.focus):not(.typing) .dawa-address__bottom-bar {
    padding-top: 0.25em;
  }
  .address-widget-floating .address-widget.sticky:not(.focus):not(.typing) .address-widget__left {
    margin-right: 0;
  }
  .address-widget-floating .address-widget.sticky:not(.focus):not(.typing) .address-widget__left #address-field {
    width: calc(100% - 2.25rem);
    height: 2rem;
  }
  html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.focus):not(.typing) .address-widget__right .icon {
    width: 2em;
    height: 2em;
  }
}
@media (min-width: 865px) {
  .address-widget-floating .address-widget.sticky:not(.focus), html.show-manual-address .address-widget-floating .address-widget.sticky.focus {
    border-radius: 0 0 1.65em 1.65em;
  }
  html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.focus):not(.typing),
.address-widget-floating .address-widget.sticky .address-widget-shimmer {
    border-radius: 0 0 1.35em 1.35em;
  }
}
@media (max-width: 864px) {
  .address-widget-floating .address-widget.sticky,
.address-widget-floating .address-widget.sticky .address-widget-shimmer {
    border-radius: 0;
  }
}
@media (min-width: 521px) {
  html:not(.show-manual-address) .address-widget-floating .address-widget.sticky.focus {
    top: calc(50% - 1.75em);
  }
}
html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.typing), html:not(.show-manual-address) .address-widget-floating .address-widget.focus:not(.typing) {
  background-color: #ed135a;
}
html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.typing) .address-widget__left #address-field, html:not(.show-manual-address) .address-widget-floating .address-widget.focus:not(.typing) .address-widget__left #address-field {
  background-color: transparent;
  color: #ffffff;
}
html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.typing) .address-widget__left #address-field::placeholder, html:not(.show-manual-address) .address-widget-floating .address-widget.focus:not(.typing) .address-widget__left #address-field::placeholder {
  color: #ffffff;
}
html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.typing) .address-widget__left #address-field:focus, html:not(.show-manual-address) .address-widget-floating .address-widget.focus:not(.typing) .address-widget__left #address-field:focus {
  background-color: #ffffff;
  color: #000000;
}
html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.typing) .address-widget__left #address-field:focus::placeholder, html:not(.show-manual-address) .address-widget-floating .address-widget.focus:not(.typing) .address-widget__left #address-field:focus::placeholder {
  color: #ed135a;
}
@media (min-width: 521px) {
  html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.typing):not(.focus) .address-widget__left, html:not(.show-manual-address) .address-widget-floating .address-widget.focus:not(.typing):not(.focus) .address-widget__left {
    margin-right: 0;
  }
  html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.typing):not(.focus) .address-widget__left #address-field, html:not(.show-manual-address) .address-widget-floating .address-widget.focus:not(.typing):not(.focus) .address-widget__left #address-field {
    width: calc(100% - 2.35rem);
    height: 2rem;
  }
  html:not(.show-manual-address) .address-widget-floating .address-widget.sticky:not(.typing):not(.focus) .address-widget__right .icon, html:not(.show-manual-address) .address-widget-floating .address-widget.focus:not(.typing):not(.focus) .address-widget__right .icon {
    width: 2em;
    height: 2em;
  }
}

.hide-dawa-address .dawa-address {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  height: 0;
}
.dawa-address .loading {
  background: transparent url("../images/loader.gif") center center no-repeat;
}
.dawa-address li {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  display: block;
  padding: 0.5em 0.25em;
  border-radius: 0.25em;
  line-height: 1.625em;
  letter-spacing: 0.02rem;
  cursor: pointer;
}
.dawa-address li:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.1);
}
.dawa-address li.focus:hover, .dawa-address li.focus:focus, .dawa-address li.focus {
  background-color: #ed135a;
  color: #ffffff;
}
.dawa-address li:hover, .dawa-address li:focus {
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
}
.dawa-address__propositions-wrap {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  display: flex;
  flex-flow: column;
  height: 0;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  z-index: 15;
}
.dawa-address__propositions-wrap.visible {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  height: 13.75em;
  margin-top: 0.4em;
}

.dawa-address__address-list {
  flex: 1 1 auto;
  overflow-y: scroll;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.dawa-address__address-list::-webkit-scrollbar {
  display: none;
}

.dawa-address__bottom-bar {
  flex: 0 1 auto;
  padding: 0.4em 0 0 0;
  text-align: center;
}

.dawa-address__search-error {
  display: none;
  padding: 1em;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
}

.dawa-address__address-not-found {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.dawa-address__address-not-found:hover, .dawa-address__address-not-found:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.manual-address {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  height: 0;
}
.show-manual-address .manual-address {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  height: auto;
}
.manual-address .street input,
.manual-address .manual-address__row:last-child .manual-address__field:first-child input,
.manual-address .manual-address__row:last-child .manual-address__field:last-child input {
  transition: border-radius 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.manual-address .street {
  width: 100%;
}
.address-widget:not(.sticky) .manual-address .street input {
  border-radius: 1.25rem 0.5rem 0.5rem 0.5rem;
}
.manual-address .city {
  width: 66.666666666%;
}
.manual-address__row {
  display: flex;
  max-width: 100%;
  padding-bottom: 0.4em;
}
@media (max-width: 520px) {
  .manual-address__row {
    padding-bottom: 0.25em;
  }
}
.manual-address__row:first-child {
  padding-right: 2.9em;
}
@media (max-width: 520px) {
  .manual-address__row:first-child {
    padding-right: 2.75em;
  }
}
.manual-address__row:last-child {
  padding-bottom: 0;
}
.manual-address__row:last-child .manual-address__field:first-child input {
  border-radius: 0.5rem 0.5rem 0.5rem 1.25rem;
}
.manual-address__row:last-child .manual-address__field:last-child input {
  border-radius: 0.5rem 0.5rem 1.25rem 0.5rem;
}
@media (max-width: 864px) {
  .address-widget.sticky .manual-address__row:last-child .manual-address__field:first-child input,
.address-widget.sticky .manual-address__row:last-child .manual-address__field:last-child input {
    border-radius: 0.5rem;
  }
}

.manual-address__field {
  width: 33.333333333%;
  padding-right: 0.4em;
}
@media (max-width: 520px) {
  .manual-address__field {
    padding-right: 0.25em;
  }
}
.manual-address__field:last-child {
  padding-right: 0;
}

.no-js .manual-address {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  height: auto;
}
.no-js .dawa-address {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  height: 0;
}

.manual-address-forced body:not(.page-order-contact) .address-widget-wrap,
.show-manual-address body:not(.page-order-contact) .address-widget-wrap {
  height: 9.5em;
}

.page-order-products {
  background-color: #f7f7f7;
}

@media (min-width: 751px) {
  .ab-test-container-b .product-list-container.regional + .product-list-container.standard {
    margin-top: 2em;
  }
}
.ab-test-container-b .product-list-container {
  /*
  centered x-scrolling with max-width container
  https://ryanmulligan.dev/blog/x-scrolling-centered-max-width-container/
  */
}
@media (min-width: 751px) {
  .ab-test-container-b .product-list-container {
    --space: 1em;
    --space-md: calc(var(--space) * 2);
    display: grid;
    grid-template-columns: [full-start] 1fr [content-start] min(var(--content-max-width), 100% - var(--space-md) * 2) [content-end] 1fr [full-end];
  }
}
@media (min-width: 751px) and (min-width: 1634px) {
  .ab-test-container-b .product-list-container.regional {
    --content-max-width: 77em;
  }
  .ab-test-container-b .product-list-container.regional.count-1 .product-list, .ab-test-container-b .product-list-container.regional.count-2 .product-list {
    justify-content: center;
  }
  .ab-test-container-b .product-list-container.regional.count-3 .product-list, .ab-test-container-b .product-list-container.regional.count-4 .product-list, .ab-test-container-b .product-list-container.regional.count-5 .product-list {
    justify-content: flex-end;
  }
  .ab-test-container-b .product-list-container.regional.count-3 .product-list::before, .ab-test-container-b .product-list-container.regional.count-4 .product-list::before, .ab-test-container-b .product-list-container.regional.count-5 .product-list::before {
    content: "";
  }
}
@media (min-width: 751px) and (max-width: 1633px) {
  .ab-test-container-b .product-list-container.regional {
    --content-max-width: 56em;
  }
  .ab-test-container-b .product-list-container.regional.count-1 .product-list {
    justify-content: center;
  }
  .ab-test-container-b .product-list-container.regional.count-2 .product-list, .ab-test-container-b .product-list-container.regional.count-3 .product-list, .ab-test-container-b .product-list-container.regional.count-4 .product-list, .ab-test-container-b .product-list-container.regional.count-5 .product-list {
    justify-content: flex-end;
  }
  .ab-test-container-b .product-list-container.regional.count-2 .product-list::before, .ab-test-container-b .product-list-container.regional.count-3 .product-list::before, .ab-test-container-b .product-list-container.regional.count-4 .product-list::before, .ab-test-container-b .product-list-container.regional.count-5 .product-list::before {
    content: "";
  }
}
@media (min-width: 751px) and (min-width: 1756px) {
  .ab-test-container-b .product-list-container.standard {
    --content-max-width: 83em;
  }
  .ab-test-container-b .product-list-container.standard.count-1 .product-list, .ab-test-container-b .product-list-container.standard.count-2 .product-list, .ab-test-container-b .product-list-container.standard.count-3 .product-list, .ab-test-container-b .product-list-container.standard.count-4 .product-list {
    justify-content: center;
  }
  .ab-test-container-b .product-list-container.standard.count-5 .product-list {
    justify-content: flex-end;
  }
  .ab-test-container-b .product-list-container.standard.count-5 .product-list::before {
    content: "";
  }
}
@media (min-width: 751px) and (min-width: 1271px) and (max-width: 1755px) {
  .ab-test-container-b .product-list-container.standard {
    --content-max-width: 62em;
  }
  .ab-test-container-b .product-list-container.standard.count-1 .product-list, .ab-test-container-b .product-list-container.standard.count-2 .product-list, .ab-test-container-b .product-list-container.standard.count-3 .product-list {
    justify-content: center;
  }
  .ab-test-container-b .product-list-container.standard.count-4 .product-list, .ab-test-container-b .product-list-container.standard.count-5 .product-list {
    justify-content: flex-end;
  }
  .ab-test-container-b .product-list-container.standard.count-4 .product-list::before, .ab-test-container-b .product-list-container.standard.count-5 .product-list::before {
    content: "";
  }
}
@media (min-width: 751px) and (max-width: 1270px) {
  .ab-test-container-b .product-list-container.standard {
    --content-max-width: 41em;
  }
  .ab-test-container-b .product-list-container.standard.count-1 .product-list, .ab-test-container-b .product-list-container.standard.count-2 .product-list {
    justify-content: center;
  }
  .ab-test-container-b .product-list-container.standard.count-3 .product-list, .ab-test-container-b .product-list-container.standard.count-4 .product-list, .ab-test-container-b .product-list-container.standard.count-5 .product-list {
    justify-content: flex-end;
  }
  .ab-test-container-b .product-list-container.standard.count-3 .product-list::before, .ab-test-container-b .product-list-container.standard.count-4 .product-list::before, .ab-test-container-b .product-list-container.standard.count-5 .product-list::before {
    content: "";
  }
}
@media (min-width: 751px) {
  .ab-test-container-b .product-list-container > * {
    grid-column: content;
  }
}
@media (min-width: 751px) {
  .ab-test-container-b .product-list-container .product-list-wrapper {
    grid-column: full;
    display: grid;
    grid-template-columns: inherit;
    overflow-x: auto;
    overflow-y: hidden;
    --size: .5em;
    --border-radius: calc(var(--size) / 2);
  }
  .ab-test-container-b .product-list-container .product-list-wrapper::-webkit-scrollbar, .ab-test-container-b .product-list-container .product-list-wrapper::-webkit-scrollbar-track, .ab-test-container-b .product-list-container .product-list-wrapper::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  .ab-test-container-b .product-list-container .product-list-wrapper::-webkit-scrollbar {
    height: var(--size);
  }
  .ab-test-container-b .product-list-container .product-list-wrapper::-webkit-scrollbar-track, .ab-test-container-b .product-list-container .product-list-wrapper::-webkit-scrollbar-track-piece {
    border-radius: var(--border-radius);
  }
  .ab-test-container-b .product-list-container .product-list-wrapper::-webkit-scrollbar-thumb {
    border-radius: var(--border-radius);
    background-color: rgba(0, 0, 0, 0.1);
  }
  .ab-test-container-b .product-list-container .product-list-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 751px) {
  .ab-test-container-b .product-list-container .product-list {
    grid-column: content;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    gap: var(--space);
  }
  .ab-test-container-b .product-list-container .product-list::before {
    align-self: stretch;
    padding-inline-end: max(var(--space), (100vw - var(--content-max-width)) / 2 - var(--space));
  }
}
@media (max-width: 750px) {
  .ab-test-container-b .product-list-container.regional {
    margin-bottom: 1.5em;
  }
}

.ab-test-container-a .product-list {
  --padding: .5rem;
  --border-radius: .5rem;
  --font-size-heading: 1.5em;
  --font-size-speed: 2.75em;
  --font-size-regulatory: 1em;
  --font-size-price: 4.5em;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  gap: 1em 2em;
  padding-bottom: 1em;
}
@media (max-width: 1024px) {
  .ab-test-container-a .product-list {
    --font-size-speed: 2.25em;
    --font-size-price: 3.75em;
  }
}
@media (max-width: 768px) {
  .ab-test-container-a .product-list {
    --font-size-speed: 2em;
    --font-size-price: 4em;
  }
}
@media (max-width: 520px) {
  .ab-test-container-a .product-list {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1.5em 2em;
    --font-size-speed: 2.75em;
    --font-size-price: 5em;
  }
}
.ab-test-container-b .product-list {
  --padding: 1rem;
  --border-radius: 1rem;
  --font-size-heading: 1.6em;
  --font-size-speed: 2.75em;
  --font-size-regulatory: 1em;
  --font-size-price: 5em;
}
@media (max-width: 1024px) {
  .ab-test-container-b .product-list {
    --font-size-speed: 2.25em;
    --font-size-price: 3.75em;
  }
}
@media (max-width: 750px) {
  .ab-test-container-b .product-list {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5em;
    --font-size-speed: 2em;
    --font-size-price: 4em;
  }
}
@media (max-width: 520px) {
  .ab-test-container-b .product-list {
    --font-size-speed: 2.75em;
    --font-size-price: 5em;
  }
}
.product-list .discount {
  color: #40b577;
}

.product-list-item {
  margin: 0;
  padding: 0;
  flex-shrink: 0;
}
.ab-test-container-b .standard .product-list-item {
  width: 20em;
}

.ab-test-container-a .product-list-dsl-speed {
  display: block;
  width: 100%;
  position: static;
  margin: 0;
  font-size: 1em;
  text-align: center;
}
.ab-test-container-a .product-list-dsl-speed .loader-label {
  margin: 0;
  padding: 0;
  letter-spacing: 0;
}
.ab-test-container-a .product-list-dsl-speed .form__loader {
  display: inline-block;
  margin: 0 0 0 2em;
  padding: 0;
  font-size: 0.5em;
}
.ab-test-container-b .product-list-dsl-speed {
  font-size: 0.5em;
}
.ab-test-container-b .product-list-dsl-speed .loader-label {
  margin: 0;
  padding: 0;
  letter-spacing: 0;
}
.ab-test-container-b .product-list-dsl-speed .form__loader {
  display: inline-block;
  margin: 0 0 0 2em;
  padding: 0;
  font-size: 0.5em;
}

.product-list-pre-link-wrap {
  position: relative;
  text-align: center;
}
.product-list-pre-link-wrap .pre-product {
  max-width: 12em;
  border-radius: 0.5rem 0.5rem 0 0;
  font-size: 0.9em;
  text-align: center;
  color: #ffffff;
}
@media (min-width: 521px) {
  .ab-test-container-a .product-list-pre-link-wrap {
    min-height: 1.6em;
  }
}
.ab-test-container-a .product-list-pre-link-wrap .pre-product {
  padding: 0.4em 0.8em;
  background-color: #303a4b;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 300;
  line-height: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}
.ab-test-container-b .product-list-pre-link-wrap {
  line-height: 1.2;
}
@media (min-width: 751px) {
  .ab-test-container-b .product-list-pre-link-wrap {
    bottom: -1px;
    min-height: 1.6em;
  }
}
.ab-test-container-b .product-list-pre-link-wrap .pre-product {
  display: block;
  margin: 0 auto;
  padding: 0.25em 0.8em;
  width: fit-content;
  background-color: #4b848e;
  font-size: 0.9em;
}

.product-list-pre-link {
  display: block;
  width: fit-content;
  width: -moz-fit-content;
  margin: 0 auto;
  padding: 0.4em 0.8em;
  border-radius: 0.5em 0.5em 0 0;
  background-color: #303a4b;
  font-size: 0.8em;
  text-align: center;
  color: #ffffff;
}

.product-list-post-link {
  margin-top: 1em;
  margin-right: calc(0.9375em + var(--padding));
  text-align: right;
}
.product-list-post-link .button {
  font-size: 1em;
}

.product-list-link {
  display: block;
  border-bottom: 0;
  background: #ffffff;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}
.product-list-link:hover {
  box-shadow: var(--box-shadow-hover-inverse);
  color: inherit;
}

.product-list-card {
  margin: 0;
  padding: 0;
}
.ab-test-container-a .product-list-card {
  width: 17em;
}
.ab-test-container-b .product-list-card {
  overflow: hidden;
}
.ab-test-container-a .regional .product-list-card {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}
.ab-test-container-b .standard .product-list-card {
  width: 100%;
}
.ab-test-container-b .regional .product-list-card {
  width: 20em;
  border-radius: var(--border-radius);
}

.product-list-heading {
  font-weight: 300;
  font-size: var(--font-size-heading);
}
.ab-test-container-a .product-list-heading {
  padding: var(--padding);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  color: #ffffff;
  text-align: left;
}
.ab-test-container-b .product-list-heading {
  background-color: transparent;
}
.ab-test-container-b .standard .product-list-heading {
  padding: 1rem 0 0;
  font-size: 1.6em;
}

.product-list-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #303a4b;
}
.product-list-middle .product-list-dsl-speed,
.product-list-middle .speed,
.product-list-middle .before-price,
.product-list-middle .price {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
}
.product-list-middle .speed,
.product-list-middle .price {
  letter-spacing: -0.075rem;
}
.ab-test-container-a .product-list-middle .speed,
.ab-test-container-a .product-list-middle .price {
  font-weight: 400;
}
.ab-test-container-b .product-list-middle .speed,
.ab-test-container-b .product-list-middle .price {
  font-weight: 300;
}
.ab-test-container-b .product-list-middle .speed.discount,
.ab-test-container-b .product-list-middle .price.discount {
  font-weight: 400;
}
.product-list-middle .speed {
  font-size: var(--font-size-speed);
}
.product-list-middle .before-price {
  margin-right: 0.5rem;
  font-size: 2.2em;
  font-weight: 300;
}
.product-list-middle .price {
  font-size: var(--font-size-price);
}
@media (max-width: 768px) {
  .product-list-middle .price {
    line-height: 0.8em;
  }
}
.product-list-middle .speed-star {
  color: rgba(48, 58, 75, 0.75);
}
.product-list-middle .mbit,
.product-list-middle .md {
  font-size: 0.8em;
}
.product-list-middle .mbit {
  margin-left: 0.25rem;
}
.product-list-middle .md {
  margin-left: -0.5rem;
}
@media (min-width: 701px) {
  .ab-test-container-a .product-list-middle {
    gap: 0.25em;
    padding: calc(var(--padding) * 2) var(--padding);
  }
}
@media (max-width: 700px) {
  .ab-test-container-a .product-list-middle {
    gap: 0.5em;
    padding: calc(var(--padding) * 1.5) var(--padding);
  }
}
.ab-test-container-a .product-list-middle .product-list-speed {
  height: calc(calc(var(--font-size-speed) / 5) * 4);
}
.ab-test-container-a .product-list-middle .product-list-price {
  height: calc(calc(var(--font-size-price) / 5) * 4);
}
.ab-test-container-a .product-list-middle .post-price {
  margin-bottom: -0.5em;
  padding-top: 0.5em;
  font-size: 0.9em;
  line-height: 1.2;
}
.ab-test-container-b .product-list-middle {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  padding: var(--padding);
  min-height: 8em;
  overflow: hidden;
}
.ab-test-container-b .product-list-middle .product-list-price-container {
  z-index: 1;
}
.ab-test-container-b .product-list-middle .product-list-price-container .pre-price,
.ab-test-container-b .product-list-middle .product-list-price-container .post-price,
.ab-test-container-b .product-list-middle .product-list-price-container .min-price {
  line-height: 1.25;
}
.ab-test-container-b .product-list-middle .product-list-price-container .pre-price,
.ab-test-container-b .product-list-middle .product-list-price-container .post-price {
  font-size: 0.9em;
  padding: 0;
}
.ab-test-container-b .product-list-middle .product-list-price-container .post-price {
  padding-top: 0.5em;
}
.ab-test-container-b .product-list-middle .product-list-price-container .min-price {
  font-size: 0.75em;
}
.ab-test-container-b .product-list-middle .product-list-price {
  justify-content: flex-start;
  margin-bottom: -0.25em;
  text-align: left;
}
.ab-test-container-b .product-list-middle .product-list-image {
  position: relative;
  flex-grow: 1;
  height: 8em;
}
.ab-test-container-b .product-list-middle .product-list-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: unset;
  height: 100%;
}
.ab-test-container-b .product-list-middle .product-list-image .mbb-stamp {
  position: absolute;
  top: 50%;
  right: 25%;
  transform: translate(50%, -50%) rotate(7.5deg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  font-size: 1.6em;
  background-color: #303a4b;
  box-shadow: var(--box-shadow);
}
.ab-test-container-b .product-list-middle .product-list-image .mbb-stamp span {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  color: #ffffff;
}
.ab-test-container-b .fiber .product-list-middle .product-list-image img {
  height: 8em;
}
.ab-test-container-b .mbb .product-list-middle .product-list-image img, .ab-test-container-b .coax .product-list-middle .product-list-image img {
  height: 10em;
}
.ab-test-container-b .dsl .product-list-middle {
  justify-content: center;
}

.product-list-card hr {
  margin: 0 auto;
  max-width: calc(100% - calc(var(--padding) * 2));
}

.product-list-tech {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 0.5em;
  padding: 0.25em var(--padding) 0.1em;
  width: 100%;
}
.product-list-tech .product-list-speed {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 0.1em;
  font-size: 1.5em;
}
.product-list-tech .product-list-speed .speed {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 300;
}
.product-list-tech .product-list-speed .mbit {
  font-size: 0.45em;
}
.product-list-tech .product-list-network {
  font-size: 0.7em;
}
.product-list-tech .product-list-network.mbb {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  font-size: 1em;
}

.product-list-speed,
.product-list-price,
.product-list-speed-range,
.product-list-price-range {
  line-height: 1;
}

.product-list-speed,
.product-list-price {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.product-list-speed > span,
.product-list-price > span {
  line-height: 1;
}

.product-list-speed-regulatory-range {
  font-size: var(--font-size-regulatory);
}

.product-list-speed,
.product-list-speed-regulatory-range,
.product-list-price {
  text-align: center;
}

.product-list-speed-range,
.product-list-price-range {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.product-list-speed-range > span:first-of-type,
.product-list-price-range > span:first-of-type {
  font-size: 0.8em;
  margin-right: 0.25rem;
}

.product-list-footer {
  padding: var(--padding);
  font-size: 0.8em;
  text-align: left;
}
.product-list-footer p {
  padding-bottom: 0;
  line-height: 1.4;
}
.product-list-footer strong,
.product-list-footer .discount {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.product-list-footer small {
  color: #999999;
}

.product-list-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  width: 100%;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  text-align: left;
  color: #ffffff;
}
.product-list-button .icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
  color: transparent;
  fill: #ffffff;
}
.ab-test-container-a .product-list-button {
  padding: calc(var(--padding) / 2) var(--padding);
  font-size: 1.5em;
}
.ab-test-container-a .product-list-link:hover .product-list-button {
  background-color: #40b577;
}
.ab-test-container-b .product-list-button {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  padding: 0.6em 1em;
  background-color: #4b848e;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  text-transform: none;
  letter-spacing: normal;
}
.ab-test-container-b .product-list-link:hover .product-list-button {
  background-color: #42747d;
}

.product-list-disclaimer p {
  padding: 0;
  font-size: 0.8em;
  line-height: 1.25;
  text-align: center;
}
.product-list-disclaimer p:first-child {
  padding-top: 0.75em;
}
.product-list-disclaimer p.price-steps {
  text-transform: lowercase;
}
.product-list-disclaimer p .discount {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
@media (min-width: 751px) {
  .ab-test-container-b .product-list-disclaimer {
    min-height: 1em;
  }
}

.product-list-splash {
  margin-top: calc(-1 * var(--padding));
  padding: calc(2.5 * var(--padding)) var(--padding) var(--padding);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  box-shadow: inset 0 1.25em 0.5em -0.5em rgba(0, 0, 0, 0.3);
  background: #ed135a;
  color: #ffffff;
  text-align: center;
  width: 17em;
}
.product-list-splash .heading {
  font-size: 1.2em;
}
.product-list-splash p {
  font-weight: 300;
  font-variation-settings: "wght" 300;
  font-size: 0.8em;
  line-height: 1.4;
}

.product-list-badge {
  display: block;
  width: 4.6666666667em;
  height: 4.6666666667em;
  position: absolute;
  top: -2.3333333333em;
  right: -2.3333333333em;
  margin: 0;
  padding: 0.8333333333em 0 0 0;
  border-radius: 4.6666666667em;
  background: #2e3139;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 0.75em;
  color: #ffffff;
  line-height: 1em;
  text-align: center;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
  transform: rotate(10deg);
}
.product-list-badge.green {
  background: #40b577;
}
.product-list-badge.fiber-first {
  padding-top: 1.35em;
  background: #ed135a;
}
.product-list-badge.free-technician {
  padding-top: 1em;
  background: #303a4b;
}

.product-list-usp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5em;
  margin: 0 auto;
  padding: var(--padding);
  width: fit-content;
}

.product-list-usp-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
  margin: 0;
  padding: 0;
}
.product-list-usp-item .icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
  color: transparent;
  fill: #303a4b;
}
.product-list-usp-item .text {
  font-size: 0.9em;
}

.product-list-disclaimers {
  padding-top: 0;
  max-width: 56em;
  text-align: center;
}
.product-list-disclaimers .heading {
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: var(--letter-spacing-small);
}
.product-list-disclaimers p {
  font-size: 0.7em;
}

.product-list-disclaimers .speed-star,
.product-list .speed-star {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: inherit;
}

.wl-middle {
  gap: 0;
  border-bottom: none;
  background: #ffffff;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.wl-middle .wl-middle-top {
  padding-bottom: 0;
}
@media (min-width: 701px) {
  .wl-middle .wl-middle-top {
    font-size: 1.4em;
  }
}
.wl-middle .wl-middle-bottom {
  font-size: 0.8em;
}

.regional .product-list-item {
  width: 38em;
}

.regional-campaign-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1em 2em;
  padding: var(--padding);
}
.regional-campaign-container .product-list-card {
  transition: box-shadow 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.ab-test-container-a .regional-campaign-container .product-list-card {
  box-shadow: var(--box-shadow-small);
}
.ab-test-container-b .regional-campaign-container .product-list-card {
  box-shadow: 0 0 0 4px #4b848e, var(--box-shadow);
}

.regional-campaign-text {
  margin: 0;
  padding: 0;
  max-width: 17em;
}
.regional-campaign-text h1, .regional-campaign-text h2 {
  padding-bottom: 0;
  font-size: 2em;
  text-align: left;
}
.regional-campaign-text h1 {
  border-bottom: 1px solid #999999;
  margin-bottom: 0.5em;
}
.regional-campaign-text h1 .pre-save {
  position: absolute;
  transform: translateY(1.2em);
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 0.29em;
  text-transform: none;
}
.regional-campaign-text h1 .save {
  font-size: 1.5em;
}
.regional-campaign-text h1 .amount {
  font-size: 2.5em;
  font-weight: 400;
  color: #ed135a;
}
.regional-campaign-text h2 {
  margin-bottom: 0.5rem;
}
.regional-campaign-text h2 .icon {
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  transform: scale(calc(1.25 * 2)) translate(0.05em, -0.05em);
  color: transparent;
  fill: #303a4b;
}
.regional-campaign-text h2 .icon svg {
  display: block;
}
.regional-campaign-text p {
  position: relative;
  padding: 0 0 0.5rem 1.5rem;
  line-height: 1.2;
}
.regional-campaign-text p.disclaimer {
  font-size: 0.7em;
  color: #999999;
}
.regional-campaign-text p:last-child {
  padding-bottom: 0;
}
.regional-campaign-text p .icon {
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 0;
  left: -0.25em;
  transform: translateY(-0.15em);
  color: transparent;
  fill: #303a4b;
}
.regional-campaign-text p .after-price {
  font-size: 0.9em;
}
.ab-test-container-b .regional-campaign-text h2 {
  font-size: 2.4em;
  line-height: 1;
}
.ab-test-container-b .regional-campaign-text h2 .small {
  font-size: 0.75em;
}

@media (min-width: 701px) {
  .ab-test-container-a .regional .product-list-link {
    border-radius: 1em 1em var(--border-radius) var(--border-radius);
  }
  .ab-test-container-a .regional-campaign-container {
    padding: 1em;
  }
  .ab-test-container-a .regional-campaign-text h2 {
    margin-bottom: 1rem;
  }
}
@media (max-width: 700px) {
  .ab-test-container-a .regional .product-list-item {
    max-width: 17em;
  }
  .ab-test-container-a .regional .product-list-link {
    border-radius: var(--border-radius);
  }
  .ab-test-container-a .regional .product-list-card {
    max-width: 17em;
    width: 100%;
  }
  .ab-test-container-a .regional-campaign-container {
    flex-direction: column;
    padding: 0.5em 1em 1em;
  }
  .ab-test-container-a .regional-campaign-text h1 {
    text-align: center;
  }
  .ab-test-container-a .regional-campaign-text h2 {
    margin-bottom: 0.5rem;
  }
  .ab-test-container-a .regional-campaign-text h2 .icon {
    transform: scale(calc(1 * 2)) translateY(-0.05em) rotate(90deg);
  }
  .ab-test-container-a .regional-campaign-text .disclaimer {
    padding-left: 0;
  }
}
@media (max-width: 520px) {
  .ab-test-container-a .regional-campaign-container {
    padding: 0.5em;
  }
}

@media (max-width: 750px) {
  .ab-test-container-b .chosen-address {
    font-size: 0.9em;
    line-height: 1.4;
  }
  .ab-test-container-b .product-list {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .ab-test-container-b .product-list-item {
    width: 100%;
    max-width: 20em;
  }
  .ab-test-container-b .product-list-card,
.ab-test-container-b .regional .product-list-card {
    width: 100%;
  }
  .ab-test-container-b .product-list-middle .product-list-image .mbb-stamp {
    right: -0.5rem;
    transform: translateY(-50%) rotate(7.5deg);
    font-size: 2em;
  }
  .ab-test-container-b .mbb .product-list-middle .product-list-image,
.ab-test-container-b .coax .product-list-middle .product-list-image {
    height: 10em;
  }
  .ab-test-container-b .mbb .product-list-middle .product-list-image img,
.ab-test-container-b .coax .product-list-middle .product-list-image img {
    height: 11em;
  }
  .ab-test-container-b .regional-campaign-container {
    flex-direction: column;
    align-items: center;
    padding: calc(var(--padding) / 2);
  }
  .ab-test-container-b .regional-campaign-text h2 {
    text-align: center;
  }
}

.biper-visible.public-hidden .product-list-pre-link-wrap,
.biper-visible.public-hidden .product-list-heading,
.biper-visible.public-hidden .product-list-button {
  opacity: 0.75;
}
.biper-visible.public-hidden .product-list-image {
  mix-blend-mode: multiply;
}
.biper-visible.public-hidden .product-list-link,
.biper-visible.public-hidden .table--plain {
  background-image: repeating-linear-gradient(-45deg, #ffffff, #ffffff 1.5em, #ffdddd 1.5em, #ffdddd 3em);
}

span.affiliate-coupon {
  padding: 0 0.25em;
  border-radius: 0.125em;
  background-color: #ed135a;
  color: #ffffff;
  letter-spacing: 0.07em;
  font-weight: 400;
  white-space: nowrap;
}

.rentdesk-logo {
  width: 25em;
  margin-bottom: 0.2em;
}

.rentdesk-heading {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 0.5em;
  text-transform: none;
  margin-bottom: 1em;
  display: block;
  line-height: 1.7em;
}

.affiliate-coupon-rentdesk {
  padding: 0.3em;
  border-radius: 0.5em;
  background-color: #ed135a;
  color: #ffffff;
  letter-spacing: 0.07em;
  font-weight: 400;
}

.trustpilot--stars {
  display: inline-block;
  width: 100%;
  height: 1.45em;
  max-width: 7.34375em;
  line-height: 1.45em;
}
.trustpilot--stars .fg {
  fill: white;
}
.trustpilot--stars .bg-on {
  fill: #62a078;
}
.trustpilot--stars .bg-off {
  fill: #c7c7c6;
}

.call-me .content {
  position: relative;
}
.call-me h2, .call-me h3, .call-me p, .call-me a {
  padding: 0;
  text-align: center;
  hyphens: manual;
}
.call-me h2 {
  font-size: 2em;
}
.call-me h3 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  text-transform: none;
  line-height: 1.6;
  letter-spacing: normal;
}
.call-me h3, .call-me p {
  width: 100%;
  font-size: 1.2em;
  line-height: 1.4;
  text-align: center;
}
.call-me .no-wrap {
  white-space: nowrap;
}
.call-me .grid {
  gap: 0.5em;
  width: fit-content;
}
.call-me .item input:not(.continue-button-submit),
.call-me .item .icon {
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.call-me .item input:not(.continue-button-submit):hover,
.call-me .item .icon:hover {
  box-shadow: var(--box-shadow-hover);
}
.call-me .item input:not(.continue-button-submit) {
  min-width: 10em;
}
.call-me .item input:not(.continue-button-submit).error {
  box-shadow: 0 0 0 2px #ed135a, var(--box-shadow);
}
.call-me .item input:not(.continue-button-submit):hover.error {
  box-shadow: 0 0 0 2px #ed135a, var(--box-shadow-hover);
}
.call-me .item .icon {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}
.call-me .heading {
  padding-bottom: 0.25em;
  line-height: 1;
}
.call-me .form__not-sent-overlay p, .call-me .form__not-sent-overlay h4,
.call-me .form__sent-overlay p,
.call-me .form__sent-overlay h4,
.call-me .form__sending-overlay p,
.call-me .form__sending-overlay h4 {
  margin: 0;
  padding: 0;
}
.call-me .form__not-sent-overlay p,
.call-me .form__sent-overlay p,
.call-me .form__sending-overlay p {
  max-width: unset;
  font-size: 1em;
}
.call-me .closed {
  padding: 0.25em 0;
}
@media (min-width: 521px) {
  .call-me .hide-on-large-devices {
    display: none;
  }
  .call-me form {
    margin-top: 0.5em;
  }
  .call-me .form__not-sent-overlay,
.call-me .form__sent-overlay,
.call-me .form__sending-overlay {
    padding: 0.5em 1em;
  }
}
@media (max-width: 520px) {
  .call-me .hide-on-small-devices {
    display: none;
  }
  .call-me h2 {
    font-size: 2.2em;
  }
  .call-me .form__not-sent-overlay,
.call-me .form__sent-overlay,
.call-me .form__sending-overlay {
    padding: 0.5em;
  }
}

.call-me-inline {
  background-color: #4b848e;
}
.call-me-inline .content {
  padding: 2em 1em;
}
.call-me-inline h2, .call-me-inline h3, .call-me-inline p, .call-me-inline a {
  color: #ffffff;
}
.call-me-inline .call-now a {
  background-color: #ed135a;
  border: none;
  position: relative;
}
.call-me-inline .call-now a > span > span:not(:last-child) {
  margin-right: 0.175em;
}
.call-me-inline form {
  margin: 0.4em 0;
}
.call-me-inline form + p {
  margin-top: 1em;
  font-size: 0.9em;
}
.call-me-inline .item input:not(.continue-button-submit) {
  border: none;
}
.call-me-inline .form__not-sent-overlay,
.call-me-inline .form__sent-overlay,
.call-me-inline .form__sending-overlay {
  background-color: #4b848e;
  color: white;
}
.hero-container + .call-me-inline {
  background-color: #42747d;
}
.hero-container + .call-me-inline .form__not-sent-overlay,
.hero-container + .call-me-inline .form__sent-overlay,
.hero-container + .call-me-inline .form__sending-overlay {
  background-color: #42747d;
}
@media (min-width: 521px) {
  .call-me-inline .call-now {
    margin: 0.5em 0;
  }
  .call-me-inline .call-now h3 {
    font-weight: 600;
    font-variation-settings: "wght" 600;
    font-size: 1.4em;
  }
  .call-me-inline .call-now a {
    font-weight: 600;
    font-variation-settings: "wght" 600;
    padding: 0.15em 0.25em;
    border-radius: 0.5em;
  }
  .call-me-inline .call-now a:hover {
    color: #ffffff;
    background-color: #d61051;
  }
}
@media (max-width: 520px) {
  .call-me-inline .call-now {
    margin: 0.25em 0 0.5em;
  }
  .call-me-inline .call-now h3 {
    line-height: 1;
  }
  .call-me-inline .call-now a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1em 0.1em 0.1em 0.4em;
    border-radius: 1em;
    font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
    font-size: 1.6em;
    font-weight: 300;
  }
  .call-me-inline .call-now a > span:first-child {
    padding-top: 0.15em;
    letter-spacing: 0.05em;
  }
  .call-me-inline .call-now a .icon {
    width: 1.25em;
    height: 1.25em;
    color: transparent;
    fill: #ffffff;
    transform: scaleX(-1);
    animation: ring-move 2s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  }
  .call-me-inline .call-now a .icon svg {
    animation: ring-shake 2s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
  }
  @keyframes ring-move {
    0% {
      transform: scaleX(-1) translateY(0.1em);
    }
    50% {
      transform: scaleX(-1) translateY(-0.1em);
    }
    100% {
      transform: scaleX(-1) translateY(0.1em);
    }
  }
  @keyframes ring-shake {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(0deg);
    }
    30% {
      transform: rotate(-10deg);
    }
    35% {
      transform: rotate(10deg);
    }
    40% {
      transform: rotate(-10deg);
    }
    45% {
      transform: rotate(10deg);
    }
    50% {
      transform: rotate(-10deg);
    }
    55% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(-10deg);
    }
    65% {
      transform: rotate(10deg);
    }
    70% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

.call-me-exit-rmo,
.call-me-dawa-down {
  position: fixed;
  z-index: 7;
  max-width: calc(100% - 1em);
  border-radius: 1em;
  background-color: #ffffff;
}
.call-me-exit-rmo .close-float,
.call-me-dawa-down .close-float {
  padding: 0;
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  z-index: 7;
  display: flex;
  font-size: 1em;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.call-me-exit-rmo .close-float .icon,
.call-me-dawa-down .close-float .icon {
  width: 1.8em;
  height: 1.8em;
  color: transparent;
  fill: #303a4b;
}
.call-me-exit-rmo .item input:not(.continue-button-submit),
.call-me-exit-rmo .item .icon,
.call-me-dawa-down .item input:not(.continue-button-submit),
.call-me-dawa-down .item .icon {
  box-shadow: var(--box-shadow-small);
}
.call-me-exit-rmo .form__not-sent-overlay,
.call-me-exit-rmo .form__sent-overlay,
.call-me-exit-rmo .form__sending-overlay,
.call-me-dawa-down .form__not-sent-overlay,
.call-me-dawa-down .form__sent-overlay,
.call-me-dawa-down .form__sending-overlay {
  z-index: 7;
  border-radius: 1em;
  background-color: #ffffff;
}

.call-me-exit-rmo {
  bottom: 0;
  transform: translate(-50%, 100%);
  box-shadow: 0 0 0 2px #ed135a, var(--box-shadow);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.call-me-exit-rmo form {
  margin: 0.5em 0 0.25em;
}
.exit-rmo-hide .call-me-exit-rmo {
  box-shadow: 0 0 0 #ed135a, 0 0 0 rgba(0, 0, 0, 0);
}

@media (min-width: 751px) {
  .call-me-exit-rmo {
    right: 0;
  }
  .exit-rmo-hide .call-me-exit-rmo {
    transform: translate(100%, -120px);
  }

  .exit-rmo-show .call-me-exit-rmo {
    transform: translate(-20px, -120px);
  }
}
@media (max-width: 750px) {
  .call-me-exit-rmo {
    left: 50%;
    width: 100%;
  }
  .exit-rmo-hide .call-me-exit-rmo {
    transform: translate(-50%, 100%);
  }

  .exit-rmo-show .call-me-exit-rmo {
    transform: translate(-50%, -120px);
  }

  .call-me-exit-rmo h2 {
    padding: 0 0.25em;
  }
}

.call-me-dawa-down {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32em;
}
.call-me-dawa-down .flex h2 {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  color: #303a4b;
}
.call-me-dawa-down .flex p {
  font-size: 1em;
}
.call-me-dawa-down form {
  margin: 0.5em 0 0;
}
@media (min-width: 521px) {
  .call-me-dawa-down {
    padding: 1.5em 2em;
  }
  .call-me-dawa-down .flex h2 {
    padding: 0 0 0.25em;
    font-size: 1.25em;
  }
}
@media (max-width: 520px) {
  .call-me-dawa-down {
    padding: 1em;
  }
  .call-me-dawa-down .flex h2 {
    padding: 0 1em 0.25em;
    font-size: 1.2em;
  }
}

.rto.rto--box.page-home {
  background-color: #42747d;
}
.rto.rto--box.page-home .content {
  box-shadow: var(--box-shadow);
}
.rto.rto--box .content {
  border-radius: 1em;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 4px #4b848e, var(--box-shadow);
}
.rto.rto--box input:not(.continue-button-submit) {
  box-shadow: inset 0 0 0 1px #999999, var(--box-shadow);
}
.rto.rto--box input:not(.continue-button-submit).error {
  box-shadow: inset 0 0 0 1px #999999, 0 0 0 2px #ed135a, var(--box-shadow);
}
.rto.rto--box input:not(.continue-button-submit):hover {
  box-shadow: inset 0 0 0 1px #999999, var(--box-shadow-hover);
}
.rto.rto--box input:not(.continue-button-submit):hover.error {
  box-shadow: inset 0 0 0 1px #999999, 0 0 0 2px #ed135a, var(--box-shadow-hover);
}
.rto.rto--box .form__not-sent-overlay,
.rto.rto--box .form__sent-overlay,
.rto.rto--box .form__sending-overlay {
  background-color: #ffffff;
  color: #303a4b;
  border-radius: 1em;
  box-shadow: inset 0 0 0 4px #4b848e;
}
.rto.rto--section {
  background-color: #4b848e;
}
.rto.rto--section h2, .rto.rto--section p {
  color: #ffffff;
}
.rto.rto--section .form__not-sent-overlay,
.rto.rto--section .form__sent-overlay,
.rto.rto--section .form__sending-overlay {
  background-color: #4b848e;
  color: #ffffff;
}
.rto .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rto .heading {
  padding: 0;
  line-height: 1.2;
}
.rto .call-button {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 0.4em 0.2em 0.75em;
  border-radius: 1em;
  border: none;
  background-color: #ed135a;
  box-shadow: var(--box-shadow);
  color: #ffffff;
  transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rto .call-button:hover {
  box-shadow: var(--box-shadow-hover);
}
.rto .call-button > span > span:not(:last-child) {
  margin-right: 0.175em;
}
.rto .call-button .icon {
  width: 1.5em;
  height: 1.5em;
  color: transparent;
  fill: #ffffff;
  transform: scaleX(-1);
  animation: ring-move 2s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}
.rto .call-button .icon svg {
  animation: ring-shake 2s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}
@keyframes ring-move {
  0% {
    transform: scaleX(-1) translateY(0.1em);
  }
  50% {
    transform: scaleX(-1) translateY(-0.1em);
  }
  100% {
    transform: scaleX(-1) translateY(0.1em);
  }
}
@keyframes ring-shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  35% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  45% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  55% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  65% {
    transform: rotate(10deg);
  }
  70% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.rto form {
  margin: 0 0 0.4em;
}
.rto .item input:not(.continue-button-submit) {
  border: none;
}
.rto.rto--contact form {
  margin: 0.5em 0 0;
}
.rto.rto--contact form input, .rto.rto--contact form input::placeholder {
  text-align: left;
}
.rto.rto--contact form .grid {
  gap: 0.5em;
}
.rto.rto--contact form .item input:not(.continue-button-submit) {
  border: 1px solid #999999;
}
.rto.rto--contact form .item .icon {
  width: 2.5em;
  height: 2.5em;
}
@media (min-width: 521px) {
  .rto.rto--box {
    padding: 1em;
  }
  .rto.rto--box.page-home {
    padding: 1em 2em 2em;
  }
  .rto.rto--box.open .content {
    padding: 1.5em 2em 1em;
  }
  .rto.rto--box.closed .content {
    padding: 1.5em 2em;
  }
  .rto.rto--mbb {
    padding: 2em 1em;
  }
  .rto.rto--section .content {
    padding: 2em;
  }
  .rto.page-order {
    padding: 0 1em 2em;
  }
  .rto.open .content {
    gap: 1em;
  }
  .rto.closed .content {
    gap: 0.5em;
  }
  .rto .content {
    width: fit-content;
  }
  .rto h2, .rto .call-button {
    font-size: 1.4em;
  }
  .rto p {
    font-size: 1em;
  }
  .rto.rto--contact .call-button {
    font-size: 1.2em;
  }
  .rto .call-button.hide-on-large-devices {
    display: none;
  }
}
@media (max-width: 520px) {
  .rto.rto--box {
    padding: 0.5em;
  }
  .rto.rto--box.page-home {
    padding: 1em 1em 2em;
  }
  .rto.rto--box .content {
    padding: 1.25em calc(.5em + 4px) 1em;
  }
  .rto.rto--mbb {
    padding: 1em 0.5em;
  }
  .rto.rto--section .content {
    padding: 1em 0.5em;
  }
  .rto.rto--contact .grid, .rto.rto--contact .item {
    width: 100%;
  }
  .rto.rto--contact .item:last-child {
    width: 2.5em;
  }
  .rto.page-order {
    padding: 0 0.5em 1em;
  }
  .rto .content {
    gap: 0.5em;
    width: fit-content;
  }
  .rto h2, .rto .call-button {
    font-size: 1.25em;
  }
  .rto p {
    font-size: 0.9em;
  }
  .rto.rto--contact .call-button {
    font-size: 1.1em;
  }
  .rto .call-button.hide-on-small-devices {
    display: none;
  }
}

#dawa-down-overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.usp-container {
  margin: 0;
  width: 100%;
  max-height: calc(960px + 2em);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em 2em;
  background-color: #f7f7f7;
}
.usp-container .usp-content,
.usp-container .usp-image {
  max-width: 40em;
  width: 50%;
}
.usp-container .usp-content h2, .usp-container .usp-content h3 {
  line-height: 1;
}
.usp-container .usp-content h2, .usp-container .usp-content h3, .usp-container .usp-content p, .usp-container .usp-content a {
  text-align: left;
}
.usp-container .usp-content h2 {
  font-size: 1.8em;
}
.usp-container .usp-content h3 {
  padding-top: 0;
  padding-bottom: 0.5rem;
  font-size: 4.8em;
}
.usp-container .usp-content p {
  font-size: 0.7em;
  margin-top: 1em;
}
.usp-container .usp-image {
  overflow: hidden;
  position: relative;
  aspect-ratio: 5/3;
}
.usp-container .usp-image img,
.usp-container .usp-image .usp-video {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
@media (max-width: 1300px) and (min-width: 1201px) {
  .usp-container .usp-image {
    width: 40%;
  }
  .usp-container .usp-video {
    width: 100%;
  }
  .usp-container .usp-content {
    width: 60%;
  }
}
@media (max-width: 1200px) and (min-width: 1101px) {
  .usp-container .usp-image {
    width: 30%;
  }
  .usp-container .usp-video {
    width: 100%;
  }
  .usp-container .usp-content {
    width: 70%;
  }
}
@media (min-width: 1101px) {
  .usp-container {
    padding: 2em;
  }
}
@media (max-width: 1100px) {
  .usp-container {
    padding: 2em 1em;
    flex-direction: column;
    max-height: none;
  }
  .usp-container .usp-image,
.usp-container .usp-content {
    width: 100%;
  }
}
@media (max-width: 520px) {
  .usp-container .usp-video {
    width: 100%;
  }
  .usp-container .usp-content {
    --fz-multiplier: 1;
  }
  .usp-container .usp-content h2 {
    padding-bottom: 0.5em;
    font-size: calc(calc(calc(100vw - 2em ) * .0825) * var(--fz-multiplier));
  }
  .usp-container .usp-content h3 {
    padding-bottom: 0;
    font-size: calc(calc(calc(100vw - 2em ) * .165) * var(--fz-multiplier));
  }
  .usp-container .usp-content .disclaimer {
    padding-top: 0.5em;
  }
  .usp-container .usp-content .button {
    display: block;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    font-size: 1.2em;
  }
}

.usp-campaign-container {
  margin: 0 auto;
}
.usp-campaign-container .image {
  position: relative;
  overflow: hidden;
}
.usp-campaign-container .image img {
  position: absolute;
  top: 50%;
  left: 50%;
}
@media (min-width: 1201px) {
  .usp-campaign-container {
    max-width: calc(calc(750px * 2) + 6em);
  }
  .usp-campaign-container .usp-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2em;
    margin: 1em 0 2em;
  }
  .usp-campaign-container .image, .usp-campaign-container .text {
    flex: 0 0 50%;
  }
  .usp-campaign-container .image {
    aspect-ratio: 5/3;
  }
  .usp-campaign-container .image img {
    transform: translate(-50%, -50%);
  }
  .usp-campaign-container .text p {
    font-size: 0.9em;
  }
}
@media (max-width: 1200px) {
  .usp-campaign-container {
    max-width: calc(750px + 4em);
  }
  .usp-campaign-container .usp-content {
    margin-bottom: 1em;
  }
  .usp-campaign-container .image {
    margin: 1em 0;
    aspect-ratio: 2/1;
  }
  .usp-campaign-container .image .mvfp {
    transform: translate(-50%, -47.5%);
  }
  .usp-campaign-container .image .trophies {
    transform: translate(-50%, -60%);
  }
}
@media (min-width: 521px) {
  .usp-campaign-container {
    padding: 2em 2em 0;
  }
}
@media (max-width: 520px) {
  .usp-campaign-container {
    padding: 2em 0.5em;
  }
}

.tp-best {
  position: fixed;
  z-index: 99;
  bottom: 0.7em;
  left: 0px;
  padding: 0.5em;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  color: #000000;
  text-align: left;
}
.tp-best h1, .tp-best h2 {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: none;
  text-align: left;
  letter-spacing: normal;
}
.tp-best h1 {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
.tp-best h2 {
  font-weight: 400;
  font-variation-settings: "wght" 400;
}
.tp-best .tp-img {
  display: block;
}
@media (min-width: 521px) {
  .tp-best {
    padding: 0.5em;
    border-radius: 0 1.5em 1.5em 0;
  }
  .tp-best h1 {
    font-size: 0.8em;
  }
  .tp-best h2 {
    font-size: 0.55em;
  }
  .tp-best .tp-img {
    width: 4em;
  }
}
@media (max-width: 520px) {
  .tp-best {
    padding: 0.25em 0.75em 0.5em 0.5em;
    border-radius: 0 1em 1em 0;
  }
  .tp-best h1 {
    font-size: 0.6em;
  }
  .tp-best h2 {
    font-size: 0.6em;
  }
  .tp-best .tp-img {
    width: 3em;
  }
}

.about-container {
  width: 100%;
  max-height: 960px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  background-color: #303a4b;
  margin: 0;
  overflow: hidden;
}
.about-container .flex-item {
  max-width: 960px;
}
.about-container .about-left {
  width: 50%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-container .about-left h1,
.about-container .about-left h2,
.about-container .about-left p {
  color: #fff;
}
.about-container .about-left .pre-about {
  font-size: 1.8em;
  text-transform: none;
  font-weight: 400;
  padding: 0;
}
@media (max-width: 1150px) {
  .about-container .about-left .pre-about {
    font-size: 1.5em;
  }
}
.about-container .about-left .about {
  font-size: 3em;
  line-height: 1;
  font-weight: 500;
  padding: 0.25em 0;
}
@media (max-width: 1150px) {
  .about-container .about-left .about {
    padding: 0.1em 0;
  }
}
.about-container .about-left .about-heading {
  line-height: 1;
  padding: 1em 0 0.2em 0;
}
.about-container .about-left p {
  max-width: 24em;
}
.about-container .about-right {
  width: 50%;
  min-height: 40vw;
  max-height: 960px;
  background: url(../images/pages/home/about.jpg) center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 450px) {
  .about-container .about-right {
    display: none;
  }
}
@media (max-width: 768px) {
  .about-container {
    flex-direction: column-reverse;
    max-height: none;
  }
  .about-container .about-left,
.about-container .about-right {
    width: 100%;
  }
  .about-container .about-left {
    min-height: 50vw;
    padding: 2em 1em;
  }
  .about-container .about-right {
    height: 60vw;
  }
}

.help-search,
.contact-search {
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
.help-search .chatbot-animate,
.contact-search .chatbot-animate {
  transform: translateY(4px);
  z-index: -1;
}
@media (max-width: 520px) {
  .help-search .chatbot-animate,
.contact-search .chatbot-animate {
    width: 40%;
    height: auto;
  }
}
@media (max-width: 375px) {
  .help-search .chatbot-animate,
.contact-search .chatbot-animate {
    display: none;
  }
}
.help-search .text-wrap,
.contact-search .text-wrap {
  align-self: center;
}
@media (max-width: 520px) {
  .help-search .text-wrap,
.contact-search .text-wrap {
    padding-bottom: 0.5em;
  }
}
.help-search p .hibot-red,
.contact-search p .hibot-red {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-size: 1.2em;
  color: #ed135a;
}

#help-embedded-search,
#contact-embedded-search {
  position: relative;
  z-index: 5;
  min-height: 45px;
}
#help-embedded-search::before, #help-embedded-search::after,
#contact-embedded-search::before,
#contact-embedded-search::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  pointer-events: none;
}
#help-embedded-search::before,
#contact-embedded-search::before {
  border-radius: 10px;
  box-shadow: var(--box-shadow);
}
#help-embedded-search::after,
#contact-embedded-search::after {
  z-index: 100;
  border-radius: 7px;
  box-shadow: inset 0 0 0 1px #ed135a, 0 0 0 1px #ed135a;
}

#chatbot-container {
  display: none;
  position: fixed;
  text-align: center;
  z-index: 6;
}
#chatbot-container.chatbot-show {
  display: block;
}
#chatbot-container .close-chatbot-icon {
  position: absolute;
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1em;
}
#chatbot-container .close-chatbot-icon .icon {
  color: transparent;
}
#chatbot-container .chatbot {
  position: absolute;
  display: block;
  pointer-events: none;
}
#chatbot-container .chatbot-text {
  background-color: #303a4b;
  box-shadow: var(--box-shadow);
}
#chatbot-container .chatbot-text p {
  color: #ffffff;
  text-align: left;
}
#chatbot-container i {
  position: absolute;
}
@media (min-width: 521px) {
  #chatbot-container {
    right: calc(40px + 62px + .75em - .1em + .25em);
    bottom: calc(40px + calc(62px / 2));
    transform: translateY(50%);
    transition: bottom 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  #chatbot-container.chatbot-temp-hide {
    bottom: -10em;
  }
  #chatbot-container .hide-on-large-devices {
    display: none;
  }
  #chatbot-container .close-chatbot-icon {
    top: 0;
    right: 0;
    width: 1.5em;
    height: 1.5em;
  }
  #chatbot-container .chatbot {
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    max-width: 4em;
  }
  #chatbot-container .chatbot-text {
    border-radius: 0.75em;
    padding: 0.5em 1em 0.5em 0.75em;
  }
  #chatbot-container .chatbot-text p {
    font-size: 0.5em;
    line-height: 1.4;
  }
  #chatbot-container .chatbot-text strong {
    font-size: 1.2em;
  }
  #chatbot-container i {
    top: 50%;
    right: 0.1em;
    transform: translate(100%, -50%);
    display: block;
    width: 1.5em;
    height: 1em;
    border-left: 0.75em solid #303a4b;
    border-top: 0.5em solid transparent;
    border-bottom: 0.5em solid transparent;
    border-right: 0;
  }
}
@media (max-width: 520px) {
  #chatbot-container {
    right: calc(15px + calc(56px / 2));
    bottom: calc(40px + .1em);
    width: fit-content;
    transform-origin: right;
    opacity: 1;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  #chatbot-container.chatbot-temp-hide {
    opacity: 0;
    transform: scaleX(0);
  }
  #chatbot-container .hide-on-small-devices {
    display: none;
  }
  #chatbot-container, #chatbot-container .chatbot-text {
    height: calc(56px - .2em);
  }
  #chatbot-container .close-chatbot-icon {
    top: 50%;
    left: 0.5em;
    transform: translateY(-50%);
    width: 2em;
    height: 2em;
  }
  #chatbot-container .chatbot {
    bottom: 0;
    right: calc(56px / 2 - .8em);
    width: 3em;
  }
  #chatbot-container .chatbot-text {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3em 0 0 3em;
    background-color: #3a465b;
    background-image: radial-gradient(circle farthest-side at right center, #1c222c 0%, #3a465b calc(56px / 2 + .75em));
    padding: 0 calc(.2em + 2.5em + calc(56px / 2)) 0 calc(.25em + 2.5em);
  }
  #chatbot-container .chatbot-text p {
    padding-bottom: 0.2em;
    font-size: 0.9em;
    line-height: 1.2;
  }
  #chatbot-container .chatbot-text .small {
    font-size: 0.8em;
  }
}

.search-input-container {
  position: relative;
}
.search-input-container .search-input {
  border: none;
  box-shadow: 0 0 0 2px #ed135a, var(--box-shadow);
}
.search-input-container .search-input, .search-input-container .search-input::placeholder {
  text-align: left;
}
.search-input-container .search-input::placeholder {
  color: #4b848e;
}
.search-input-container h1, .search-input-container h2, .search-input-container h3, .search-input-container h4, .search-input-container h5, .search-input-container h6 {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding: 0;
  text-align: left;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: normal;
}
.search-input-container .form__loader {
  display: inline-block;
  margin: 0 0 0 2em;
  padding: 0;
  font-size: 0.5em;
}
.search-input-container .post-input-container {
  position: absolute;
  top: calc(100% + .25em);
  left: 0;
  right: 0;
  border-radius: 0.5em;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
}
.search-input-container .top-questions,
.search-input-container .search-summary,
.search-input-container .relevant-articles {
  padding: 0.5em;
}
.search-input-container .top-questions li,
.search-input-container .relevant-articles a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
  padding: 0.25em 0;
  border: none;
  border-top: 1px solid #dad9d7;
  color: #303a4b;
  line-height: 1.4;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.search-input-container .top-questions li:hover,
.search-input-container .relevant-articles a:hover {
  color: #ed135a;
}
.search-input-container .top-questions li:hover .icon,
.search-input-container .relevant-articles a:hover .icon {
  transform: translateX(0.2em);
}
.search-input-container .top-questions li .icon,
.search-input-container .relevant-articles a .icon {
  flex-shrink: 0;
  display: block;
  width: 1em;
  height: 1em;
  color: transparent;
  fill: #303a4b;
  transform: translateX(0);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.search-input-container .top-questions li .icon svg,
.search-input-container .relevant-articles a .icon svg {
  transform: scale(1.5);
}
.search-input-container .top-questions li {
  cursor: pointer;
}
.search-input-container .top-questions li:first-child {
  border-top: none;
}
.search-input-container .search-summary > h3,
.search-input-container .relevant-articles > h3 {
  padding-bottom: 0.25em;
}
.search-input-container .search-summary .summary {
  padding: 0.25em 0.5em;
  border-radius: 0.5em;
  background-color: #eeeeee;
  font-size: 0.9em;
}
.search-input-container .search-summary .summary h1, .search-input-container .search-summary .summary h2, .search-input-container .search-summary .summary h3, .search-input-container .search-summary .summary h4, .search-input-container .search-summary .summary h5, .search-input-container .search-summary .summary h6 {
  padding-top: 0.5em;
}
.search-input-container .search-summary .summary h1 + p, .search-input-container .search-summary .summary h1 + ul, .search-input-container .search-summary .summary h1 + a, .search-input-container .search-summary .summary h2 + p, .search-input-container .search-summary .summary h2 + ul, .search-input-container .search-summary .summary h2 + a, .search-input-container .search-summary .summary h3 + p, .search-input-container .search-summary .summary h3 + ul, .search-input-container .search-summary .summary h3 + a, .search-input-container .search-summary .summary h4 + p, .search-input-container .search-summary .summary h4 + ul, .search-input-container .search-summary .summary h4 + a, .search-input-container .search-summary .summary h5 + p, .search-input-container .search-summary .summary h5 + ul, .search-input-container .search-summary .summary h5 + a, .search-input-container .search-summary .summary h6 + p, .search-input-container .search-summary .summary h6 + ul, .search-input-container .search-summary .summary h6 + a {
  padding-top: 0.35em;
}
.search-input-container .search-summary .summary h1:first-child, .search-input-container .search-summary .summary h2:first-child, .search-input-container .search-summary .summary h3:first-child, .search-input-container .search-summary .summary h4:first-child, .search-input-container .search-summary .summary h5:first-child, .search-input-container .search-summary .summary h6:first-child {
  padding-top: 0;
}
.search-input-container .search-summary .summary h1 {
  font-size: 1.4em;
}
.search-input-container .search-summary .summary h2 {
  font-size: 1.2em;
}
.search-input-container .search-summary .summary h3, .search-input-container .search-summary .summary h4, .search-input-container .search-summary .summary h5, .search-input-container .search-summary .summary h6 {
  font-size: 1.1em;
}
.search-input-container .search-summary .summary .reference {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  border: none;
  color: #ed135a;
  font-size: 0.6em;
  vertical-align: super;
}
.search-input-container .search-summary .summary ul {
  padding-bottom: 0.7em;
}
.search-input-container .search-summary .summary ul:last-child {
  padding-bottom: 0;
}
.search-input-container .search-summary .summary ul li {
  position: relative;
  padding-left: 1.25em;
  padding-bottom: 0.35em;
}
.search-input-container .search-summary .summary ul li::before {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  content: "•";
  position: absolute;
  left: 0.25em;
  color: #ed135a;
}
.search-input-container .search-summary .summary ul li:last-child {
  padding-bottom: 0;
}
.search-input-container .relevant-articles {
  padding-bottom: 0;
}
.search-input-container .relevant-articles li {
  padding: 0;
}
.search-input-container .relevant-articles li:last-child a {
  border-bottom: 1px solid #dad9d7;
}
.search-input-container .contact-options {
  padding: 0.5em;
}
.search-input-container .contact-options h3 {
  font-size: 1.2em;
}
.search-input-container .contact-options p {
  font-size: 0.9em;
}
.search-input-container .contact-options .phone-number {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  display: block;
  margin: 0 auto;
  padding: 0.25em 1em;
  width: fit-content;
  border-radius: 2em;
  border: none;
  background-color: #ed135a;
  color: #ffffff;
  font-size: 1.2em;
}
.search-input-container .contact-options button {
  display: block;
  margin: 0 auto;
  padding: 0.5em 1em;
  border: 1px solid #dad9d7;
  border-radius: 0.5em;
  background-color: #ffffff;
  color: #303a4b;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  cursor: pointer;
}
.search-input-container .contact-options button.toggle-contact-button {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  border: none;
  background-color: #ed135a;
  color: #ffffff;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.search-input-container .contact-options button.toggle-contact-button:hover {
  background-color: #d61051;
}
.search-input-container .contact-options button.toggle-contact-button.back {
  flex-direction: row-reverse;
  margin-top: 1em;
}
.search-input-container .contact-options button.toggle-contact-button.back .icon {
  transform: rotate(180deg);
}
.search-input-container .contact-options button.toggle-contact-button .icon {
  flex-shrink: 0;
  display: block;
  width: 1em;
  height: 1em;
  color: transparent;
  fill: #ffffff;
  transition: fill 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.search-input-container .contact-options button.toggle-contact-button .icon svg {
  transform: scale(1.5);
}
.search-input-container .contact-options button.contact-button {
  width: 100%;
  margin-bottom: 0.25em;
  padding: 0.5em;
  text-align: left;
}
.search-input-container .contact-options button.contact-button small {
  font-size: 0.8em;
}

@media (min-width: 521px) {
  .search-input-container .post-input-container {
    max-height: 65vh;
  }
  .search-input-container .top-questions > h3,
.search-input-container .search-summary > h3,
.search-input-container .relevant-articles > h3 {
    font-size: 1.2em;
  }
}
@media (max-width: 520px) {
  .search-input-container .post-input-container {
    max-height: 55vh;
  }
  .search-input-container .top-questions > h3,
.search-input-container .search-summary > h3,
.search-input-container .relevant-articles > h3 {
    font-size: 1em;
  }
}
.form__not-sent-overlay,
.form__sent-overlay,
.form__sending-overlay {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form--sending .form__sending-overlay {
  opacity: 1;
  overflow: visible;
  visibility: visible;
}

.form__not-sent-overlay,
.form__sent-overlay {
  background: #ffffff;
}

.form--sent .form__sent-overlay {
  opacity: 1;
  overflow: visible;
  visibility: visible;
}
.form--sent .form__sending-overlay {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.form--not-sent .form__not-sent-overlay {
  opacity: 1;
  overflow: visible;
  visibility: visible;
}
.form--not-sent .form__sending-overlay {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

@keyframes throbberLoader {
  0% {
    background: #dad9d7;
  }
  10% {
    background: #999999;
  }
  40% {
    background: #dad9d7;
  }
}
.form__loader:not(:required) {
  animation: throbberLoader 2000ms 300ms infinite ease-out;
  position: relative;
  text-indent: -9999px;
}
.form__loader:not(:required), .form__loader:not(:required):before, .form__loader:not(:required):after {
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: #dad9d7;
}
.form__loader:not(:required):before, .form__loader:not(:required):after {
  position: absolute;
  top: 0;
  content: "";
}
.form__loader:not(:required):before {
  animation: throbberLoader 2000ms 150ms infinite ease-out;
  left: -1.5em;
}
.form__loader:not(:required):after {
  animation: throbberLoader 2000ms 450ms infinite ease-out;
  right: -1.5em;
}

[class^=page-selfcare] button:not(.button) {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
[class^=page-selfcare] button.button {
  padding: 0.2em 1em;
  font-size: 1.5em;
}

#react-root,
#react-root > .selfcare-loader {
  min-height: 50vh;
}

.selfcare-header-wrapper,
.selfcare-menu-button-container {
  background-color: #42747d;
}

.selfcare-header-wrapper {
  position: fixed;
  top: 3.75em;
  z-index: 25;
  width: 100%;
}

.selfcare-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 60em;
  height: 100%;
}

.selfcare-header-address {
  position: relative;
  z-index: 26;
  padding-right: 0.5em;
  width: fit-content;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}
.selfcare-header-address .text, .selfcare-header-address.single {
  line-height: 1.2;
  color: #ffffff;
}
.selfcare-header-address .text span, .selfcare-header-address.single span {
  display: block;
}
.selfcare-header-address .text span:not(:first-child), .selfcare-header-address.single span:not(:first-child) {
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.8);
}
.selfcare-header-address.multiple > .text, .selfcare-header-address.single {
  position: relative;
  padding-right: 0.5em;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.selfcare-header-address.multiple > .text::after, .selfcare-header-address.single::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0.5em;
  height: 100%;
  background: linear-gradient(90deg, rgba(66, 116, 125, 0), #42747d);
}
@media (max-width: 400px) {
  .selfcare-header-address.multiple > .text, .selfcare-header-address.single {
    font-size: 0.9em;
  }
}
@media (max-width: 350px) {
  .selfcare-header-address.multiple > .text, .selfcare-header-address.single {
    font-size: 0.8em;
  }
}
.selfcare-header-address.multiple {
  display: grid;
  grid-template-columns: auto 17px;
  align-items: center;
  justify-items: start;
  cursor: pointer;
}
.selfcare-header-address.multiple .dropdown-icon {
  display: block;
  position: relative;
  width: 17px;
  height: 9px;
  transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.selfcare-header-address.multiple .dropdown-icon::before, .selfcare-header-address.multiple .dropdown-icon::after {
  content: "";
  pointer-events: none;
  display: block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 10px;
  height: 1px;
  border-radius: 0.5px;
  background-color: #ffffff;
}
.selfcare-header-address.multiple .dropdown-icon::before {
  transform: translateX(0) rotate(45deg);
}
.selfcare-header-address.multiple .dropdown-icon::after {
  transform: translateX(7px) rotate(-45deg);
}
.selfcare-header-address.multiple .dropdown {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  top: 100%;
  min-width: 100%;
  width: max-content;
  background-color: #42747d;
  box-shadow: var(--box-shadow);
}
.selfcare-header-address.multiple .dropdown ul {
  max-height: 0;
}
.selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar, .selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-track, .selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-track-piece {
  background-color: #42747d;
}
.selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
}
.selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.6);
}
.selfcare-header-address.multiple .dropdown li {
  padding: 0;
  background-color: #4b848e;
  transition: background-color 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.selfcare-header-address.multiple .dropdown li:only-child {
  border-radius: 0.5rem;
}
.selfcare-header-address.multiple .dropdown li:first-child:not(:only-child) {
  border-radius: 0.5rem 0.5rem 0 0;
}
.selfcare-header-address.multiple .dropdown li:last-child:not(:only-child) {
  border-radius: 0 0 0.5rem 0.5rem;
}
.selfcare-header-address.multiple .dropdown li:not(:only-child):not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.selfcare-header-address.multiple .dropdown li:hover {
  background-color: #42747d;
}
.selfcare-header-address.multiple .dropdown li button {
  display: block;
  padding: 0.4em;
  width: 100%;
  background: none;
  cursor: pointer;
  font: inherit;
  text-align: left;
  line-height: 1.2;
}
.selfcare-header-address.multiple .dropdown li button, .selfcare-header-address.multiple .dropdown li button:hover {
  border: none;
  color: inherit;
}
.selfcare-header-address.multiple.no-hover {
  cursor: default;
}
.selfcare-header-address.multiple:not(.no-hover):hover .dropdown-icon {
  transform: rotate(180deg);
}
.selfcare-header-address.multiple:not(.no-hover):hover .dropdown {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  overflow: hidden;
}
.selfcare-header-address.multiple:not(.no-hover):hover .dropdown ul {
  max-height: calc(calc(100vh - 3.75em - 3.5em + .25em) - 2em);
  overflow-y: auto;
}
.selfcare-header-address.single {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.selfcare-header .pop-up-arrow,
.selfcare-header .pop-up-arrow::before,
.selfcare-header .pop-up,
.selfcare-header .pop-up-backdrop {
  position: absolute;
}

.selfcare-header .pop-up-arrow {
  z-index: 2;
  height: 1em;
  pointer-events: none;
}
.selfcare-header .pop-up-arrow::before {
  content: "";
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-top: 0 solid transparent;
  border-right: 0.75em solid transparent;
  border-left: 0.75em solid transparent;
  border-bottom: 1em solid #ffffff;
}
.selfcare-header .pop-up-arrow.multiple-addresses-hint {
  bottom: calc(-.25em - .9em);
  width: 100%;
}

.selfcare-header .pop-up {
  z-index: 1;
  margin: 0;
  padding: 1em;
  border-radius: 1em;
  border: none;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  color: #303a4b;
  cursor: default;
}
.selfcare-header .pop-up.multiple-addresses-hint {
  bottom: calc(.1em - .25em - .9em);
}
.selfcare-header .pop-up p {
  line-height: 1.4;
}
.selfcare-header .pop-up p > strong, .selfcare-header .pop-up p > span {
  display: block;
}
.selfcare-header .pop-up p > strong {
  padding-bottom: 0.25em;
}
.selfcare-header .pop-up p > span {
  font-size: 0.9em;
}
.selfcare-header .pop-up .checkbox-label {
  display: block;
}
.selfcare-header .pop-up .checkbox-label::before {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  font-size: 2em;
  line-height: 0.7;
  color: #303a4b;
}
.selfcare-header .pop-up button {
  display: block;
  margin: 0 auto;
  padding: 0 0 0.1em;
  background: transparent;
  border: none;
  border-bottom: 2px solid #ed135a;
  font-size: 1em;
  color: #303a4b;
  cursor: pointer;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), border-bottom-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-header .pop-up button:hover {
  color: #ed135a;
  border-bottom-color: transparent;
}

@media (min-width: 961px) {
  .selfcare-header .show-on-burger {
    display: none;
  }
}
@media (max-width: 960px) {
  .selfcare-header .hide-on-burger {
    display: none;
  }
}
@media (min-width: 521px) {
  .selfcare-header .pop-up {
    width: 22em;
  }
  .selfcare-header .pop-up.multiple-addresses-hint {
    transform: translate(-1em, 100%);
  }
}
@media (max-width: 520px) {
  .selfcare-header .pop-up {
    transform: translateY(100%);
    width: calc(100vw - 1em);
  }
  .selfcare-header .pop-up.multiple-addresses-hint {
    left: -1em;
  }
}
@media (max-width: 350px) {
  .selfcare-header .pop-up {
    padding: 1em 0.75em;
  }
  .selfcare-header .pop-up.multiple-addresses-hint {
    left: 0;
  }
}
.selfcare-header .pop-up-backdrop {
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  cursor: default;
}

.selfcare-menu-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  max-width: 60em;
  margin: 0 auto;
}

.selfcare-menu-button {
  padding: 0;
}

.selfcare-menu {
  transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.selfcare-menu-items {
  display: flex;
}
.selfcare-menu-items li {
  margin: 0;
  padding: 0;
}
.selfcare-menu-items .choose {
  display: flex;
  align-items: center;
  border-bottom: 0;
  line-height: 1.2;
  text-align: center;
  transition: background-color 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955), box-shadow 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955), color 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.selfcare-menu-items .choose .icon {
  display: block;
  color: transparent;
  transition: color 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955), fill 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.selfcare-page summary,
.selfcare-page details {
  box-sizing: border-box;
}
.selfcare-page hr {
  width: 64em;
}

.selfcare-page-error {
  padding-top: 4em;
  max-width: 38em;
}
.selfcare-page-error p {
  text-align: center;
}

.selfcare-page-content {
  max-width: 44em;
  margin: 0 auto;
}
@media (min-width: 961px) {
  .selfcare-page-content {
    padding: 0 2em;
  }
}
@media (max-width: 960px) {
  .selfcare-page-content {
    padding: 0 1em;
  }
}
@media (max-width: 520px) {
  .selfcare-page-content {
    padding: 0 0.75em;
  }
}

.selfcare-page-content--wide {
  max-width: 60em;
}

.selfcare-page-content--full {
  max-width: none;
  width: 100%;
}

.selfcare-page-summary {
  margin-bottom: 2em;
}
.selfcare-page-summary h1, .selfcare-page-summary h2, .selfcare-page-summary p {
  text-align: center;
}
.selfcare-page-summary h1:only-child, .selfcare-page-summary h2:only-child, .selfcare-page-summary p:only-child {
  padding-bottom: 0;
}
.selfcare-page-summary p {
  padding-bottom: 0.5em;
}
.selfcare-page-summary p:first-of-type {
  margin-top: -0.5em;
}
.selfcare-page-summary p:last-child {
  padding-bottom: 0;
}
.selfcare-page-summary .icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  color: transparent;
  fill: #303a4b;
  vertical-align: top;
}

.no-content {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  text-align: center;
}

.selfcare-explainer,
.selfcare-explainer-confirm {
  display: inline-block;
  font-size: 0.8em;
  line-height: 1.4;
}

.selfcare-explainer-confirm {
  font-style: italic;
}

.selfcare-popup-overlay,
.selfcare-popup {
  position: fixed;
}

.selfcare-popup-overlay {
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.selfcare-report-connection .buttons,
.selfcare-hardware-text .buttons,
.selfcare-popup .buttons {
  display: flex;
  align-items: center;
  gap: 0.25em;
  margin-top: 0.5em;
}
.selfcare-report-connection .buttons > button, .selfcare-report-connection .buttons > a,
.selfcare-hardware-text .buttons > button,
.selfcare-hardware-text .buttons > a,
.selfcare-popup .buttons > button,
.selfcare-popup .buttons > a {
  padding: 0.25em 0.75em;
  border-radius: 1em;
  border: none;
  font-size: 1em;
  line-height: 1.2;
  cursor: pointer;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.permission-banner {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 1em 2em;
  background-color: #4b848e;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  font-size: 1em;
  margin-bottom: 40px;
  max-width: 800px;
}
.permission-banner .banner-image {
  max-width: 150px;
}
@media (max-width: 520px) {
  .permission-banner .banner-image {
    max-width: 120px;
  }
}
@media (max-width: 520px) {
  .permission-banner {
    flex-direction: column;
    font-size: 1em;
    margin: 0 0.6em 2em;
  }
}
.permission-banner p {
  padding: 0;
}
.permission-banner .button, .permission-banner .button--montserrat {
  margin-top: 0.5em;
  font-size: 0.9em;
}
@media (max-width: 520px) {
  .permission-banner .button, .permission-banner .button--montserrat {
    font-size: 1em;
  }
}

.selfcare-popup {
  left: 50%;
  transform: translateX(-50%);
  overflow-y: auto;
  border-radius: 1em;
  background-color: #ffffff;
  z-index: 1;
}
.selfcare-popup .smaller {
  font-size: 0.7em;
}
@media (min-width: 500px) {
  .selfcare-popup {
    max-height: calc(100vh - calc(3.75em + 3.5em) - 2em - .5em - calc(40px + 62px));
  }
}
@media (max-width: 499px) {
  .selfcare-popup {
    max-height: calc(100vh - calc(3.75em + 3em) - 1em - .5em - calc(40px + 56px));
  }
}
.selfcare-popup > p, .selfcare-popup > ul {
  font-size: 0.9em;
}
.selfcare-popup ul > p {
  padding-bottom: 0.35em;
}
.selfcare-popup ul > ul {
  padding-left: 1em;
}
.selfcare-popup > ul {
  padding-bottom: 0.7em;
}
.selfcare-popup > ul:last-child {
  padding-bottom: 0;
}
.selfcare-popup li {
  position: relative;
  padding-left: 1em;
  padding-bottom: 0.35em;
  line-height: 1.4;
}
.selfcare-popup li:last-child {
  padding-bottom: 0;
}
.selfcare-popup li::before {
  font-weight: 800;
  font-variation-settings: "wght" 800;
  content: "•";
  color: #ed135a;
  position: absolute;
  left: 0;
}
.selfcare-popup ul.number {
  counter-reset: list;
}
.selfcare-popup ul.number li::before {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  counter-increment: list;
  content: counter(list) ".";
}
.selfcare-popup ul > ul li::before {
  color: transparent;
  -webkit-text-stroke: 1px #ed135a;
}
.selfcare-popup code {
  padding: 0.1em 0.25em;
  border-radius: 0.25em;
  background-color: #eeeeee;
  font-family: Monaco, Consolas, "Courier New", Courier, "Liberation Mono", monospace;
  white-space: pre-wrap;
  letter-spacing: 0.075em;
}
.selfcare-popup .buttons {
  justify-content: center;
}
.selfcare-popup .buttons:only-child {
  margin-top: 0;
}
.selfcare-popup .cancel {
  background-color: #eeeeee;
  box-shadow: inset 0 0 0 1px #999999;
  color: #303a4b;
}
.selfcare-popup .cancel:hover {
  background-color: #dad9d7;
}
.selfcare-popup .confirm {
  background-color: #4b848e;
  color: #ffffff;
}
.selfcare-popup .confirm:hover {
  background-color: #42747d;
}
.selfcare-popup .error-box {
  margin-top: 0.5em;
}
.selfcare-popup .error-box p {
  line-height: 1.4;
}

.back-button-react {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
}
.back-button-react .icon {
  margin-left: -0.2em;
  margin-right: 0.1em;
  width: 1.4em;
  height: 1.4em;
  vertical-align: middle;
  color: transparent;
  fill: #303a4b;
  transform: rotate(180deg);
  transition: inherit;
  transition-property: fill;
}
.back-button-react:hover .icon {
  fill: #ed135a;
}

.selfcare-page-summary + .back-button-react {
  display: flex;
  margin: -1.5em auto 2em;
  width: fit-content;
}

.selfcare-loading-content {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.selfcare-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;
  height: 100%;
  padding: 2em 1em;
}
.selfcare-loader p {
  text-align: center;
  line-height: 1.6;
}
.selfcare-loader .spinner {
  animation: rotation 2s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.selfcare-loader .path {
  stroke-dasharray: 200;
  stroke-dashoffset: 170;
  stroke: #ed135a;
  transform-origin: center;
  animation: stroke 3s ease-in-out infinite;
}
@keyframes stroke {
  0% {
    stroke-dashoffset: 170;
  }
  50% {
    stroke-dashoffset: 50;
  }
  100% {
    stroke-dashoffset: 170;
    transform: rotate(360deg);
  }
}

.pagination {
  display: grid;
  align-items: center;
  margin: 1em auto 0;
  text-align: center;
}
.pagination .disabled,
.pagination .enabled {
  padding: 0.4em 0.8em;
  border-radius: 0.5em;
  white-space: nowrap;
}
.pagination .disabled {
  background-color: #dad9d7;
  opacity: 0.75;
}
.pagination .enabled {
  border: none;
  cursor: pointer;
  background-color: #4b848e;
  color: #ffffff;
}
.pagination .enabled:hover {
  background-color: #42747d;
}
.pagination .previous-page {
  grid-area: previous;
  font: inherit;
}
.pagination .next-page {
  grid-area: next;
  font: inherit;
}
.pagination .current-page {
  grid-area: current;
  justify-self: center;
  line-height: 1;
}

.selfcare-button-link {
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 2px solid rgba(237, 19, 90, 0.75);
  background: none;
  transition: 0.2s all cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  font: inherit;
  color: inherit;
}
.selfcare-button-link:hover {
  border-bottom-color: rgba(237, 19, 90, 0);
  color: #ed135a;
}
.selfcare-button-link:focus {
  outline: 5px auto #83afc5;
}

.selfcare-messages {
  margin: 0 auto 1em;
  max-width: 26em;
}
.selfcare-messages .selfcare-message-success,
.selfcare-messages .selfcare-message-error {
  display: none;
  margin-bottom: 0.5em;
  padding: 0.5em;
  border-radius: 0.5em;
  color: #ffffff;
}
.selfcare-messages .selfcare-message-success.show,
.selfcare-messages .selfcare-message-error.show {
  display: block;
}
.selfcare-messages .selfcare-message-success {
  background-color: #40b577;
}
.selfcare-messages .selfcare-message-error {
  background-color: #ed135a;
}
.selfcare-messages p {
  text-align: center;
  white-space: pre-line;
}

.selfcare-login-form .icon-button,
.selfcare-login-resend .icon-button,
.selfcare-account-table .icon-button {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  font-size: 1em;
  cursor: pointer;
}
.selfcare-login-form .icon-button, .selfcare-login-form .icon-button .icon,
.selfcare-login-resend .icon-button,
.selfcare-login-resend .icon-button .icon,
.selfcare-account-table .icon-button,
.selfcare-account-table .icon-button .icon {
  width: 1em;
  height: 1em;
}
.selfcare-login-form .input-with-button, .selfcare-login-form .input-with-button .button-container,
.selfcare-login-resend .input-with-button,
.selfcare-login-resend .input-with-button .button-container,
.selfcare-account-table .input-with-button,
.selfcare-account-table .input-with-button .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selfcare-login-form .input-with-button input,
.selfcare-login-resend .input-with-button input,
.selfcare-account-table .input-with-button input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.selfcare-login-form .input-with-button .button-container,
.selfcare-login-resend .input-with-button .button-container,
.selfcare-account-table .input-with-button .button-container {
  padding: 0 0.2em;
  height: 2.5rem;
}
.selfcare-login-form .input-with-button .button-container,
.selfcare-login-resend .input-with-button .button-container,
.selfcare-account-table .input-with-button .button-container {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px solid #999999;
  border-left: 0;
}
.selfcare-login-form .input-with-button .icon-button,
.selfcare-login-resend .input-with-button .icon-button,
.selfcare-account-table .input-with-button .icon-button {
  font-size: 1.75em;
}
.selfcare-login-form .input-with-button .icon,
.selfcare-login-resend .input-with-button .icon,
.selfcare-account-table .input-with-button .icon {
  color: transparent;
  fill: #303a4b;
}
.selfcare-login-form span.password,
.selfcare-login-form input[type=password],
.selfcare-login-resend span.password,
.selfcare-login-resend input[type=password],
.selfcare-account-table span.password,
.selfcare-account-table input[type=password] {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  letter-spacing: 0.1em;
}
.selfcare-login-form input[type=password]::placeholder,
.selfcare-login-resend input[type=password]::placeholder,
.selfcare-account-table input[type=password]::placeholder {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  letter-spacing: var(--letter-spacing-big);
}
.selfcare-login-form .submit-button-block .button,
.selfcare-login-resend .submit-button-block .button,
.selfcare-account-table .submit-button-block .button {
  width: 100%;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
}
.selfcare-login-form .submit-button-block .text,
.selfcare-login-resend .submit-button-block .text,
.selfcare-account-table .submit-button-block .text {
  font-size: 1.5em;
}

.selfcare-page-login h1 {
  padding-bottom: 0;
}
.selfcare-page-login .selfcare-page-summary {
  margin: 0 auto 1rem;
  max-width: 26rem;
  font-size: 0.9em;
  line-height: 1.4;
}

.selfcare-login-form,
.selfcare-login-resend {
  max-width: 26em;
  margin: 0 auto;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
  text-align: center;
}
.selfcare-login-form p,
.selfcare-login-form input[type=text],
.selfcare-login-form input[type=password],
.selfcare-login-form ::placeholder,
.selfcare-login-resend p,
.selfcare-login-resend input[type=text],
.selfcare-login-resend input[type=password],
.selfcare-login-resend ::placeholder {
  text-align: left;
}
.selfcare-login-form .hidden,
.selfcare-login-resend .hidden {
  display: none;
}
.selfcare-login-form .selfcare-login__email-suggestion-p,
.selfcare-login-resend .selfcare-login__email-suggestion-p {
  display: none;
}
.selfcare-login-form .selfcare-login__email-suggestion-p button,
.selfcare-login-resend .selfcare-login__email-suggestion-p button {
  padding: 0;
  border: none;
  border-bottom: 2px solid #ed135a;
  background: none;
  cursor: pointer;
  font: inherit;
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-login-form .selfcare-login__email-suggestion-p button:hover,
.selfcare-login-resend .selfcare-login__email-suggestion-p button:hover {
  border-bottom-color: rgba(0, 0, 0, 0);
  color: #ed135a;
}
.selfcare-login-form .selfcare-login__email-suggestion-p strong,
.selfcare-login-resend .selfcare-login__email-suggestion-p strong {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.selfcare-login-form .selfcare-login__email-suggestion-p.visible,
.selfcare-login-resend .selfcare-login__email-suggestion-p.visible {
  display: block;
}
.selfcare-login-form .input-help-text,
.selfcare-login-form .selfcare-button-bottom,
.selfcare-login-resend .input-help-text,
.selfcare-login-resend .selfcare-button-bottom {
  font-size: 0.8em;
}
.selfcare-login-form .input-help-text,
.selfcare-login-resend .input-help-text {
  padding-bottom: 0;
}
.selfcare-login-form .selfcare-button-bottom,
.selfcare-login-resend .selfcare-button-bottom {
  padding-bottom: 0.25em;
}

.selfcare-login-form .submit-button-block {
  padding: 0 0 0.7em 0;
}

.selfcare-login-resend .submit-button-block {
  padding-top: 0;
}

.selfcare-page-overview .selfcare-overview-message.no-link,
.selfcare-page-overview .selfcare-overview-message > a,
.selfcare-page-overview .icon-with-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
}
.selfcare-page-overview .icon {
  display: block;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
}
.selfcare-page-overview .text {
  width: 100%;
}

.selfcare-overview-message {
  padding: 0;
  border-radius: 0.5em;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
  line-height: 1.4;
  text-align: left;
  transition: box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-overview-message:only-child, .selfcare-overview-message:last-child {
  margin-bottom: 0;
}
.selfcare-overview-message.no-link, .selfcare-overview-message > a {
  border: none;
}
.selfcare-overview-message .icon, .selfcare-overview-message .text {
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-overview-message .icon {
  color: transparent;
  fill: #303a4b;
}
.selfcare-overview-message .text {
  font-size: 0.9em;
}
.selfcare-overview-message.selfcare-overview-message--important .text, .selfcare-overview-message.selfcare-overview-message--highlight .text {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.selfcare-overview-message.selfcare-overview-message--important {
  box-shadow: inset 0 0 0 2px #ed135a, var(--box-shadow);
}
.selfcare-overview-message.selfcare-overview-message--important .icon {
  fill: #ed135a;
}
.selfcare-overview-message.selfcare-overview-message--important .text {
  color: #ed135a;
}
.selfcare-overview-message.selfcare-overview-message--highlight {
  box-shadow: inset 0 0 0 2px #4b848e, var(--box-shadow);
}
.selfcare-overview-message.selfcare-overview-message--highlight .icon {
  fill: #4b848e;
}
.selfcare-overview-message.selfcare-overview-message--highlight .text {
  color: #4b848e;
}
.selfcare-overview-message:hover:not(.no-link) {
  box-shadow: var(--box-shadow-hover);
}
.selfcare-overview-message:hover:not(.no-link).selfcare-overview-message--important {
  box-shadow: inset 0 0 0 3px #ed135a, var(--box-shadow-hover);
}
.selfcare-overview-message:hover:not(.no-link).selfcare-overview-message--highlight {
  box-shadow: inset 0 0 0 3px #4b848e, var(--box-shadow-hover);
}
.selfcare-overview-message:hover:not(.no-link) a {
  color: inherit;
}
.selfcare-overview-message:hover:not(.no-link) .icon, .selfcare-overview-message:hover:not(.no-link) .text {
  opacity: 0.75;
}

.selfcare-overview-permission-popup .selfcare-popup-overlay {
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.selfcare-overview-permission-popup .selfcare-popup {
  left: 50%;
  transform: translateX(-50%);
  overflow-y: auto;
  border-radius: 1em;
  background-color: #ffffff;
  z-index: 1;
  top: calc(3.75em + 3.5em + 2em);
  padding: 1.5em 1.5em 1em;
  max-width: calc(100vw - 2em);
  width: 36em;
  position: relative;
}
.selfcare-overview-permission-popup .close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.selfcare-overview-account .account-container {
  border-radius: 0.5em;
  box-shadow: var(--box-shadow);
}
.selfcare-overview-account .product,
.selfcare-overview-account .payment {
  padding-bottom: 1.5em;
}
.selfcare-overview-account .product:only-child, .selfcare-overview-account .product:last-child,
.selfcare-overview-account .payment:only-child,
.selfcare-overview-account .payment:last-child {
  padding-bottom: 0;
}
.selfcare-overview-account .title {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  padding-bottom: 0;
  font-size: 1.2em;
}
.selfcare-overview-account .price {
  padding-bottom: 0;
  line-height: 1.6;
}
.selfcare-overview-account .price > strong {
  font-weight: 550;
  font-variation-settings: "wght" 550;
}
.selfcare-overview-account .sub {
  padding-bottom: 0;
}
.selfcare-overview-account .sub, .selfcare-overview-account .text {
  font-size: 0.8em;
}
.selfcare-overview-account .icon {
  flex-shrink: 0;
}
.selfcare-overview-account .icon-with-text {
  margin: 0.25em 0;
  padding: 0;
}
.selfcare-overview-account .bullets {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  letter-spacing: 0.1em;
}
.selfcare-overview-account .discount-total {
  border-radius: 0.5em;
  background-color: #40b577;
  color: #ffffff;
}
.selfcare-overview-account .price .discount-total {
  padding: 0.2em 0.4em;
}
.selfcare-overview-account .pricing-details .discount-total {
  margin-top: 0.5em;
  padding: 0.5em 0.25em 0.25em;
}
.selfcare-overview-account .pricing-details .discount-total p {
  padding: 0;
  text-align: center;
  line-height: 1.4;
  color: #ffffff;
}
.selfcare-overview-account .pricing-details .discount-total p:nth-child(2) {
  font-size: 1.5em;
}
.selfcare-overview-account .pricing-details {
  padding-bottom: 0.25em;
}
.selfcare-overview-account summary {
  position: relative;
  display: block;
  width: fit-content;
  padding-left: 1em;
  border-bottom: 2px solid #ed135a;
  line-height: 1.4;
  color: #303a4b;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), border-bottom 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-overview-account summary i {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  width: 0.8em;
  height: 1em;
}
.selfcare-overview-account summary i::before, .selfcare-overview-account summary i::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  background-color: #303a4b;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-overview-account summary i::before {
  transform: translate(-50%, -50%);
  width: 0.5em;
  height: 0.1em;
}
.selfcare-overview-account summary i::after {
  transform: translate(-50%, -50%);
  width: 0.1em;
  height: 0.5em;
}
.selfcare-overview-account summary:hover {
  color: #ed135a;
  border-bottom-color: rgba(237, 19, 90, 0);
}
.selfcare-overview-account summary:hover i::before, .selfcare-overview-account summary:hover i::after {
  background-color: #ed135a;
}
.selfcare-overview-account details[open] summary i::after {
  transform: translate(-50%, -50%) scaleY(0);
}
.selfcare-overview-account .summary-table {
  margin-top: 1em;
}
.selfcare-overview-account .summary-table th[colspan="2"] {
  border-radius: 0.5em 0.5em 0 0;
  background-color: #4b848e;
  color: #ffffff;
}
.selfcare-overview-account .summary-table td {
  padding-left: 0;
  padding-right: 0;
  font-size: 0.9em;
}
.selfcare-overview-account .summary-table small {
  font-size: 0.9em;
  color: rgba(48, 58, 75, 0.9);
}
.selfcare-overview-account .summary-table tbody tr, .selfcare-overview-account .summary-table tfoot tr {
  background: transparent;
}
.selfcare-overview-account .disclaimer {
  padding-top: 1rem;
  font-size: 0.7em;
  line-height: 1.2;
  color: rgba(48, 58, 75, 0.75);
}

.selfcare-page-delivery-flow {
  padding-left: 0;
  padding-right: 0;
}
.selfcare-page-delivery-flow .toggle-delivery-flow,
.selfcare-page-delivery-flow .toggle-open {
  cursor: pointer;
}
.selfcare-page-delivery-flow .toggle-delivery-flow .arrow,
.selfcare-page-delivery-flow .toggle-open .arrow {
  display: inline-block;
  width: 20px;
  height: 1em;
  position: relative;
  vertical-align: middle;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-page-delivery-flow .toggle-delivery-flow .arrow::before, .selfcare-page-delivery-flow .toggle-delivery-flow .arrow::after,
.selfcare-page-delivery-flow .toggle-open .arrow::before,
.selfcare-page-delivery-flow .toggle-open .arrow::after {
  content: "";
  display: block;
  width: 12px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-page-delivery-flow .toggle-delivery-flow .arrow.arrow-down::before,
.selfcare-page-delivery-flow .toggle-open .arrow.arrow-down::before {
  transform: translate(0, -50%) rotate(45deg);
}
.selfcare-page-delivery-flow .toggle-delivery-flow .arrow.arrow-down::after,
.selfcare-page-delivery-flow .toggle-open .arrow.arrow-down::after {
  transform: translate(8px, -50%) rotate(-45deg);
}
.selfcare-page-delivery-flow .toggle-delivery-flow .arrow.arrow-up::before,
.selfcare-page-delivery-flow .toggle-open .arrow.arrow-up::before {
  transform: translate(8px, -50%) rotate(45deg);
}
.selfcare-page-delivery-flow .toggle-delivery-flow .arrow.arrow-up::after,
.selfcare-page-delivery-flow .toggle-open .arrow.arrow-up::after {
  transform: translate(0, -50%) rotate(-45deg);
}
.selfcare-page-delivery-flow .toggle-delivery-flow,
.selfcare-page-delivery-flow .mark-all-as-read {
  padding: 0.25em 1em;
  width: fit-content;
  border-radius: 2em;
  background-color: #4b848e;
}
.selfcare-page-delivery-flow .toggle-delivery-flow:hover,
.selfcare-page-delivery-flow .mark-all-as-read:hover {
  background-color: #42747d;
}
.selfcare-page-delivery-flow .toggle-delivery-flow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin: 0 auto;
  box-shadow: 0 0 0 1px transparent;
  font-size: 0.9em;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-page-delivery-flow .toggle-delivery-flow .text {
  color: #ffffff;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-page-delivery-flow .toggle-delivery-flow .arrow::before,
.selfcare-page-delivery-flow .toggle-delivery-flow .arrow::after {
  background-color: #ffffff;
}
.selfcare-page-delivery-flow .mark-all-as-read {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  display: block;
  margin: 0 auto 1em;
  border: none;
  cursor: pointer;
  font-size: 1em;
  color: #ffffff;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-page-delivery-flow .selfcare-delivery-flow-container.open .toggle-delivery-flow {
  background-color: #eeeeee;
  box-shadow: 0 0 0 1px #dad9d7;
}
.selfcare-page-delivery-flow .selfcare-delivery-flow-container.open .toggle-delivery-flow:hover {
  background-color: #dad9d7;
}
.selfcare-page-delivery-flow .selfcare-delivery-flow-container.open .toggle-delivery-flow .text {
  color: #303a4b;
}
.selfcare-page-delivery-flow .selfcare-delivery-flow-container.open .toggle-delivery-flow .arrow::before,
.selfcare-page-delivery-flow .selfcare-delivery-flow-container.open .toggle-delivery-flow .arrow::after {
  background-color: #303a4b;
}

.selfcare-delivery-flow-container {
  margin-bottom: 1.5em;
  transition: margin-bottom 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-delivery-flow-container:last-child, .selfcare-delivery-flow-container:only-child {
  margin-bottom: 0;
}
.selfcare-delivery-flow-container .selfcare-delivery-flow-header {
  text-align: center;
}
.selfcare-delivery-flow-container .selfcare-delivery-flow-header p, .selfcare-delivery-flow-container .selfcare-delivery-flow-header span {
  line-height: 1.4;
}
.selfcare-delivery-flow-container .selfcare-delivery-flow-header p {
  padding-bottom: 0.25em;
}
.selfcare-delivery-flow-container .selfcare-delivery-flow-header strong {
  font-size: 1.2em;
}
.selfcare-delivery-flow-container .selfcare-delivery-flow-header .toggle-delivery-flow {
  font-size: 0.9em;
}
.selfcare-delivery-flow-container .selfcare-delivery-flow {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: margin-top 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), max-height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-delivery-flow-container.open .selfcare-delivery-flow {
  max-height: 9999em;
  overflow: visible;
  opacity: 1;
  margin-top: 1em;
}

.selfcare-delivery-flow-status {
  display: grid;
  grid-template-areas: "icon text" "button button";
  margin: 0 auto;
  width: fit-content;
  max-width: calc(100% - 1em);
  border-radius: 1em;
  box-shadow: inset 0 0 0 2px #4b848e;
}
.selfcare-delivery-flow-status .icon {
  position: relative;
  grid-area: icon;
  align-content: center;
  width: 0.5em;
  height: 1em;
  color: transparent;
  fill: #303a4b;
}
.selfcare-delivery-flow-status .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1em;
  height: 1em;
}
.selfcare-delivery-flow-status p {
  grid-area: text;
  align-content: center;
  padding: 0;
  font-size: 0.9em;
  line-height: 1.4;
  text-align: center;
}
.selfcare-delivery-flow-status a {
  grid-area: button;
  justify-self: center;
  padding: 0.25em 0.75em;
  width: fit-content;
  border-radius: 2em;
  border: none;
  background-color: #4b848e;
  color: #ffffff;
  font-size: 1em;
}
.selfcare-delivery-flow-status a:hover {
  background-color: #42747d;
}
.selfcare-delivery-flow-status.selfcare-delivery-flow-status--alert {
  box-shadow: inset 0 0 0 2px #ed135a;
}
.selfcare-delivery-flow-status.selfcare-delivery-flow-status--alert .icon {
  fill: #ed135a;
}
.selfcare-delivery-flow-status.selfcare-delivery-flow-status--alert a {
  background-color: #ed135a;
}
.selfcare-delivery-flow-status.selfcare-delivery-flow-status--alert a:hover {
  background-color: #d61051;
}

.selfcare-delivery-flow-timeline {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0;
  overflow-x: auto;
  scroll-snap-type: x proximity;
  margin: 0 auto;
  margin-bottom: 1em;
  padding-bottom: 1em;
  max-width: 100%;
  width: fit-content;
}
.selfcare-delivery-flow-timeline li {
  scroll-snap-align: center;
  scroll-snap-stop: normal;
  display: block;
  margin: 0;
  padding: 0;
  flex: 0 0 16em;
  width: 16em;
}
.selfcare-delivery-flow-timeline li .icon {
  color: #dad9d7;
}
.selfcare-delivery-flow-timeline li.done .icon {
  color: #40b577;
}
.selfcare-delivery-flow-timeline .icon {
  display: block;
}
.selfcare-delivery-flow-timeline .status {
  position: relative;
  width: 100%;
  height: 1.5em;
  margin-bottom: 1em;
}
.selfcare-delivery-flow-timeline .status::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 2px);
  height: 1px;
  background-color: #999999;
}
.selfcare-delivery-flow-timeline .status .icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1.5em;
  height: 1.5em;
}
.selfcare-delivery-flow-timeline .procedure-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 1em 0.5em;
  height: calc(100% - 2.5em);
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
.selfcare-delivery-flow-timeline .procedure-card .icon {
  width: 4em;
  height: 4em;
  margin-bottom: 1em;
}
.selfcare-delivery-flow-timeline .procedure-card h3 {
  font-size: 1em;
}
.selfcare-delivery-flow-timeline .procedure-card p, .selfcare-delivery-flow-timeline .procedure-card > a {
  line-height: 1.4;
  font-size: 0.8em;
}
.selfcare-delivery-flow-timeline .procedure-card .date {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  padding-top: 0.5em;
  font-size: 0.9em;
}
.selfcare-delivery-flow-timeline .procedure-card p + .date {
  padding-top: 0;
}
.selfcare-delivery-flow-timeline li.start .status::before,
.selfcare-delivery-flow-timeline li.end .status::before {
  transform: translateY(-50%);
  width: calc(50% + 1px);
}
.selfcare-delivery-flow-timeline li.start .status::before {
  left: 50%;
}
.selfcare-delivery-flow-timeline li.end .status::before {
  left: -1px;
}

.selfcare-delivery-flow-notification-list {
  margin: 0 auto;
  width: 40em;
}

.selfcare-notification,
.selfcare-delivery-flow-notification-list .selfcare-loader {
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
.selfcare-notification:last-child,
.selfcare-delivery-flow-notification-list .selfcare-loader:last-child {
  margin-bottom: 0;
}

.selfcare-delivery-flow-notification-list + .selfcare-delivery-flow-notification-list {
  margin-top: 0.5em;
}

.selfcare-notification {
  display: grid;
}
.selfcare-notification.open .notification-buttons {
  margin-bottom: 0.25em;
}
.selfcare-notification.open .notification-body {
  max-height: 500em;
}
.selfcare-notification.important h3.heading.heading--montserrat {
  color: #ed135a;
}
.selfcare-notification.important .notification-icon .icon {
  fill: #ed135a;
}
.selfcare-notification.unread h3.heading.heading--montserrat,
.selfcare-notification.unread .timestamp,
.selfcare-notification.unread .notification-buttons button {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
.selfcare-notification.unread .notification-buttons button {
  background-color: #4b848e;
  box-shadow: none;
  color: #ffffff;
}
.selfcare-notification.unread .notification-buttons button:hover {
  background-color: #42747d;
}
.selfcare-notification.unread .notification-buttons .icon {
  fill: #ffffff;
}
.selfcare-notification.unread .notification-buttons .arrow::before,
.selfcare-notification.unread .notification-buttons .arrow::after {
  background-color: #ffffff;
}
.selfcare-notification.unread {
  box-shadow: 0 0 0 2px #4b848e, var(--box-shadow);
}
.selfcare-notification.important.unread {
  box-shadow: 0 0 0 2px #ed135a, var(--box-shadow);
}
.selfcare-notification .icon {
  display: block;
}
.selfcare-notification .notification-icon {
  grid-area: icon;
}
.selfcare-notification .notification-icon .icon {
  position: relative;
  width: 1em;
  height: 1em;
  color: transparent;
  fill: #303a4b;
}
.selfcare-notification .notification-icon .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.6em;
  height: 1.6em;
  transform: translate(-50%, -50%);
}
.selfcare-notification .notification-head {
  grid-area: head;
  line-height: 1.4;
  transition: margin-bottom 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-notification h3.heading.heading--montserrat {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  padding-bottom: 0;
  font-size: 1.1em;
  line-height: 1.2;
  word-wrap: word-break;
  word-wrap: auto-phrase;
  transition: font-variation-settings 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-notification .timestamp {
  display: block;
  font-size: 0.7em;
  transition: font-variation-settings 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-notification .notification-buttons {
  grid-area: btns;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.25em;
  margin-top: 0.25em;
  transition: margin-bottom 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-notification .notification-buttons button {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  padding: 0.25em 0.75em;
  border-radius: 1em;
  background-color: #eeeeee;
  border: none;
  box-shadow: inset 0 0 0 1px #dad9d7;
  font-size: 0.9em;
  color: #303a4b;
  transition: font-variation-settings 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.selfcare-notification .notification-buttons button:hover {
  background-color: #dad9d7;
}
.selfcare-notification .notification-buttons .icon {
  position: relative;
  width: 1.2em;
  height: 1.2em;
  color: transparent;
  fill: #303a4b;
}
.selfcare-notification .notification-buttons .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
}
.selfcare-notification .notification-buttons .arrow,
.selfcare-notification .notification-buttons .arrow::before,
.selfcare-notification .notification-buttons .arrow::after {
  transition: none;
}
.selfcare-notification .notification-buttons .arrow::before,
.selfcare-notification .notification-buttons .arrow::after {
  background-color: #303a4b;
}
.selfcare-notification .notification-body {
  grid-area: body;
  margin-bottom: -0.5em;
  max-height: 0;
  overflow: hidden;
  overflow-x: auto;
  font-size: 0.9em;
  transition: max-height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-notification .notification-body h1, .selfcare-notification .notification-body h2, .selfcare-notification .notification-body h3, .selfcare-notification .notification-body h4, .selfcare-notification .notification-body h5, .selfcare-notification .notification-body h6, .selfcare-notification .notification-body p, .selfcare-notification .notification-body li {
  word-wrap: word-break;
  word-wrap: auto-phrase;
}
.selfcare-notification .notification-body h1, .selfcare-notification .notification-body h2, .selfcare-notification .notification-body h3, .selfcare-notification .notification-body h4, .selfcare-notification .notification-body h5, .selfcare-notification .notification-body h6 {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  padding-top: 0.7rem;
  padding-bottom: 0.1rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-align: left;
  text-transform: none;
  line-height: 1.4;
  letter-spacing: normal;
}
.selfcare-notification .notification-body h1:first-child, .selfcare-notification .notification-body h2:first-child, .selfcare-notification .notification-body h3:first-child, .selfcare-notification .notification-body h4:first-child, .selfcare-notification .notification-body h5:first-child, .selfcare-notification .notification-body h6:first-child {
  padding-top: 0;
}
.selfcare-notification .notification-body h1, .selfcare-notification .notification-body h2, .selfcare-notification .notification-body h3, .selfcare-notification .notification-body h4 {
  font-size: 1.2em;
}
.selfcare-notification .notification-body h5 {
  font-size: 1.1em;
}
.selfcare-notification .notification-body h6 {
  font-size: 1em;
}
.selfcare-notification .notification-body em, .selfcare-notification .notification-body i {
  font-style: italic;
}
.selfcare-notification .notification-body ul {
  padding-bottom: 0.7em;
}
.selfcare-notification .notification-body ul:last-child {
  padding-bottom: 0;
}
.selfcare-notification .notification-body li {
  position: relative;
  padding-left: 1em;
  padding-bottom: 0.35em;
}
.selfcare-notification .notification-body li:last-child {
  padding-bottom: 0;
}
.selfcare-notification .notification-body li::before {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  content: "•";
  color: #ed135a;
  position: absolute;
  left: 0;
}
.selfcare-notification .notification-body *:empty {
  margin: 0;
  padding: 0;
}

.selfcare-report-connection {
  margin: 0 auto;
  padding: 1em;
  max-width: 32em;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
.selfcare-report-connection > h2 {
  font-size: 1.4em;
}
.selfcare-report-connection > p {
  line-height: 1.4;
  text-align: center;
}
.selfcare-report-connection > .buttons {
  justify-content: center;
  width: 100%;
}
.selfcare-report-connection > .buttons > button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  background-color: #4b848e;
  color: #ffffff;
}
.selfcare-report-connection > .buttons > button:hover {
  background-color: #42747d;
}
.selfcare-report-connection > .buttons > button:disabled {
  background-color: #dad9d7;
  cursor: not-allowed;
}
.selfcare-report-connection > .buttons > button:disabled:hover {
  background-color: #dad9d7;
}
.selfcare-report-connection > .buttons > button .icon {
  position: relative;
  width: 1.2em;
  height: 1.2em;
  color: transparent;
  fill: #ffffff;
}
.selfcare-report-connection > .buttons > button .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.333333);
}
.selfcare-report-connection .fejlmelding-startet * {
  pointer-events: none;
}

.selfcare-popup--report-connection h3, .selfcare-popup--report-connection h4 {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
.selfcare-popup--report-connection h3:first-child, .selfcare-popup--report-connection h4:first-child {
  padding-top: 0;
}
.selfcare-popup--report-connection .small {
  padding-top: 0.5em;
  font-size: 0.8em;
  line-height: 1.2;
}
.selfcare-popup--report-connection .attention-box {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  margin-bottom: 0.7em;
  padding: 0.5em;
  border-radius: 0.5em;
  background-color: #ed135a;
  color: #ffffff;
}
.selfcare-popup--report-connection .attention-box a {
  border-bottom-color: #ffffff;
}
.selfcare-popup--report-connection .attention-box a:hover {
  color: rgba(255, 255, 255, 0.75);
  border-bottom-color: rgba(255, 255, 255, 0.75);
}
.selfcare-popup--report-connection .input-container {
  padding-bottom: 0.7em;
}
.selfcare-popup--report-connection .input-container:last-child {
  padding-bottom: 0;
}
.selfcare-popup--report-connection .input-container p {
  padding-bottom: 0;
}
.selfcare-popup--report-connection .contact-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25em;
}
.selfcare-popup--report-connection .contact-row .contact-item {
  flex: calc(50% - .125em) 1 1;
}
.selfcare-popup--report-connection .contact-row .contact-item .checkbox-label {
  position: relative;
  line-height: 1;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: calc(.5rem + 1.4rem + .5rem);
}
.selfcare-popup--report-connection .contact-row .contact-item .checkbox-label::before {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
  margin: 0;
}
.selfcare-popup--report-connection .contact-row .contact-item .checkbox-label small {
  font-size: 0.8em;
}
.selfcare-popup--report-connection .buttons button {
  padding: 0.25em 0.75em 0.15em;
  border-radius: 0.5rem;
  font-size: 1.5em;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: var(--letter-spacing-medium);
}
.selfcare-popup--report-connection textarea {
  display: block;
  padding: 0.25em 0.5em;
  min-height: 5em;
  border-radius: 0.5em 0.5em 0.25em 0.5em;
  overflow-x: hidden;
  overflow-y: auto;
  resize: vertical;
  color: #303a4b;
  line-height: 1.4;
}
.selfcare-popup--report-connection textarea::placeholder {
  text-align: left;
}
.selfcare-popup--report-connection .error-message {
  color: #ed135a;
  line-height: 1.4;
}

.selfcare-hardware {
  margin: 0 auto;
  width: fit-content;
}

.selfcare-hardware-item {
  display: grid;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}
.selfcare-hardware-item:only-child, .selfcare-hardware-item:last-child {
  margin-bottom: 0;
}

.selfcare-hardware-item img {
  grid-area: image;
  z-index: -1;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
}
.selfcare-hardware-item img.netgear-c6250 {
  filter: none;
}

.selfcare-hardware-text {
  grid-area: text;
}
.selfcare-hardware-text h3 {
  padding-bottom: 0;
}
.selfcare-hardware-text > .buttons {
  flex-wrap: wrap;
}
.selfcare-hardware-text > .buttons > button, .selfcare-hardware-text > .buttons > a {
  background-color: #4b848e;
  color: #ffffff;
}
.selfcare-hardware-text > .buttons > button:hover, .selfcare-hardware-text > .buttons > a:hover {
  background-color: #42747d;
}
.selfcare-hardware-text > .buttons > button.disabled, .selfcare-hardware-text > .buttons > button:disabled, .selfcare-hardware-text > .buttons > a.disabled, .selfcare-hardware-text > .buttons > a:disabled {
  background-color: #dad9d7;
  cursor: not-allowed;
}
.selfcare-hardware-text > .buttons > button.disabled:hover, .selfcare-hardware-text > .buttons > button:disabled:hover, .selfcare-hardware-text > .buttons > a.disabled:hover, .selfcare-hardware-text > .buttons > a:disabled:hover {
  background-color: #dad9d7;
}
.selfcare-hardware-text .specifications-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25em;
  width: fit-content;
  border-bottom: 2px solid #ed135a;
  font-size: 0.9em;
  cursor: pointer;
  transition: border-bottom-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-hardware-text .specifications-button:hover {
  border-bottom-color: rgba(237, 19, 90, 0.5);
}
.selfcare-hardware-text .specifications-button .arrow {
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  vertical-align: middle;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-hardware-text .specifications-button .arrow::before, .selfcare-hardware-text .specifications-button .arrow::after {
  content: "";
  display: block;
  width: 0.6em;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #303a4b;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-hardware-text .specifications-button .arrow.arrow-down::before {
  transform: translate(0, -50%) rotate(45deg);
}
.selfcare-hardware-text .specifications-button .arrow.arrow-down::after {
  transform: translate(0.4em, -50%) rotate(-45deg);
}
.selfcare-hardware-text .specifications-button .arrow.arrow-up::before {
  transform: translate(0.4em, -50%) rotate(45deg);
}
.selfcare-hardware-text .specifications-button .arrow.arrow-up::after {
  transform: translate(0, -50%) rotate(-45deg);
}
.selfcare-hardware-text .buttons + .specifications-button {
  margin-top: 0.25em;
}

.selfcare-hardware-specifications {
  grid-area: spec;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: margin-top 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), max-height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-hardware-specifications li {
  position: relative;
  padding-left: 1em;
  padding-bottom: 0.25em;
  font-size: 0.9em;
}
.selfcare-hardware-specifications li:last-child {
  padding-bottom: 0;
}
.selfcare-hardware-specifications li::before {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  content: "•";
  color: #ed135a;
  position: absolute;
  left: 0;
}
.selfcare-hardware-specifications.open {
  max-height: 9999em;
  overflow: visible;
  opacity: 1;
}

.selfcare-hardware-specifications-table th, .selfcare-hardware-specifications-table td {
  border: 0;
  border-radius: 0;
  background-color: #ffffff;
  line-height: 1.4;
  color: #303a4b;
}
.selfcare-hardware-specifications-table tr:first-child th, .selfcare-hardware-specifications-table tr:first-child td,
.selfcare-hardware-specifications-table tr:last-child th,
.selfcare-hardware-specifications-table tr:last-child td {
  border-radius: 0;
}
.selfcare-hardware-specifications-table th {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.selfcare-hardware-specifications-table p {
  padding-bottom: 0;
  font-size: 0.9em;
  line-height: inherit;
}

.selfcare-popup--mesh .action-box {
  margin-top: 1em;
}
.selfcare-popup--mesh .action-box h4, .selfcare-popup--mesh .action-box p {
  padding-bottom: 0;
}
.selfcare-popup--mesh .action-box p, .selfcare-popup--mesh .action-box small {
  text-align: center;
}
.selfcare-popup--mesh .action-box small {
  display: block;
  font-size: 0.8em;
  color: rgba(48, 58, 75, 0.75);
}

.selfcare-help-articles {
  display: grid;
  justify-items: center;
  align-items: center;
}
.selfcare-help-articles a {
  padding: 1em;
  width: 100%;
  height: 100%;
  border-radius: 1em;
  border: 1px solid #999999;
  background-color: #f7f7f7;
  text-align: center;
}
.selfcare-help-articles a:hover {
  border: 1px solid transparent;
  background-color: #4b848e;
  color: #ffffff;
}

.selfcare-card-container {
  margin: 0 auto;
  width: fit-content;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}

.selfcare-card,
.change-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selfcare-card {
  gap: 0.5em 1em;
  padding: 1em;
  text-align: left;
}
.selfcare-card p {
  line-height: 1.2;
  padding-bottom: 0.5em;
}
.selfcare-card p:last-child {
  padding-bottom: 0;
}
.selfcare-card .label {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  display: inline-block;
  padding-right: 0.5em;
}
.selfcare-card .icon {
  display: block;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  font-size: 4em;
  color: #4b848e;
  fill: #ffffff;
}
.selfcare-card .icon svg {
  display: block;
}
.selfcare-card .bullets {
  font-weight: 900;
  font-variation-settings: "wght" 900;
  font-size: 1.25em;
  letter-spacing: 0.05em;
}

.change-card,
.add-card {
  color: #ffffff;
  background-color: #4b848e;
  border: 0;
}
.change-card:hover,
.add-card:hover {
  background-color: #42747d;
  color: #ffffff;
}

.change-card {
  width: 100%;
  padding: 0.5em;
  border-radius: 0 0 1em 1em;
}

.add-card {
  display: block;
  padding: 0.5em 1em;
  border-radius: 0.5rem;
  font-size: 1.2em;
}

.selfcare-invoices-table td, .selfcare-invoices-table th,
.selfcare-invoice-table td,
.selfcare-invoice-table th {
  border: 0;
  vertical-align: middle;
}
.selfcare-invoices-table th,
.selfcare-invoice-table th {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  color: #ffffff;
  background-color: #4b848e;
  text-align: left;
}
.selfcare-invoices-table td,
.selfcare-invoice-table td {
  color: #303a4b;
  background-color: #ffffff;
}

.selfcare-invoices-table {
  height: 1px;
}
.selfcare-invoices-table td {
  height: 100%;
}
.selfcare-invoices-table .selfcare-invoices-table-button {
  display: flex;
  align-items: center;
}
.selfcare-invoices-table .selfcare-invoices-table-button button, .selfcare-invoices-table .selfcare-invoices-table-button a {
  display: block;
  width: fit-content;
  border: 0;
  cursor: pointer;
  background: none;
  font-size: 1em;
}
.selfcare-invoices-table .selfcare-invoices-table-button button:hover, .selfcare-invoices-table .selfcare-invoices-table-button a:hover {
  color: inherit;
}
.selfcare-invoices-table .selfcare-invoices-table-button .icon {
  display: inline-block;
}
.selfcare-invoices-table .selfcare-invoices-table-button .selfcare-loader {
  padding: 0;
}
.selfcare-invoices-table .selfcare-invoices-table-button .selfcare-loader, .selfcare-invoices-table .selfcare-invoices-table-button .selfcare-loader svg {
  width: 1.5em;
  height: 1.5em;
}
.selfcare-invoices-table .invoice-status--overdue {
  color: #d61051;
}
.selfcare-invoices-table .invoice-status--inkasso {
  font-weight: 600;
  color: #d61051;
}
.selfcare-invoices-table .icon-arrow {
  color: #40b577;
}

.selfcare-invoice-table {
  border-radius: 0.5em;
  box-shadow: var(--box-shadow);
}
.selfcare-invoice-table th, .selfcare-invoice-table td {
  line-height: 1.2;
}
.selfcare-invoice-table th:first-child, .selfcare-invoice-table td:first-child {
  padding-left: 0.8rem;
}
.selfcare-invoice-table th:last-child, .selfcare-invoice-table td:last-child {
  padding-right: 0.8rem;
  text-align: right;
}
.selfcare-invoice-table tr:nth-child(even) td {
  background: #f7f7f7;
}
.selfcare-invoice-table thead th:first-child {
  border-radius: 0.5em 0 0 0;
}
.selfcare-invoice-table thead th:last-child {
  border-radius: 0 0.5em 0 0;
}
.selfcare-invoice-table tbody td {
  border-radius: 0;
}
.selfcare-invoice-table tfoot td {
  background-color: #ffffff;
  border-top: 1px solid #dad9d7;
}
.selfcare-invoice-table tfoot td:first-child {
  border-radius: 0 0 0 0.5em;
}
.selfcare-invoice-table tfoot td:last-child {
  border-radius: 0 0 0.5em 0;
}

.selfcare-page-pay-invoice p {
  text-align: center;
}
.selfcare-page-pay-invoice .button {
  display: block;
  margin: 0 auto;
}

.selfcare-communication-overview li {
  margin-bottom: 0.5em;
  padding: 0;
}
.selfcare-communication-overview a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.5em;
  border: none;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
}
.selfcare-communication-overview a:hover {
  color: rgba(48, 58, 75, 0.75);
  box-shadow: var(--box-shadow-hover);
}
.selfcare-communication-overview a:hover .icon {
  fill: rgba(48, 58, 75, 0.75);
}
.selfcare-communication-overview .icon, .selfcare-communication-overview .text, .selfcare-communication-overview .subject, .selfcare-communication-overview .date {
  display: block;
}
.selfcare-communication-overview .icon {
  position: relative;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  color: transparent;
  fill: #303a4b;
  transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.selfcare-communication-overview .icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.333333em;
  height: 1.333333em;
}
.selfcare-communication-overview .text {
  width: 100%;
  line-height: 1.2;
}
.selfcare-communication-overview .subject {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.selfcare-communication-overview .date {
  font-size: 0.8em;
  color: rgba(48, 58, 75, 0.75);
}

.selfcare-communication .back-button-react {
  display: flex;
  margin: 0 auto 1em;
  width: fit-content;
}
.selfcare-communication h1 {
  padding-bottom: 0.5rem;
}
.selfcare-communication .subject,
.selfcare-communication .date {
  display: block;
}
.selfcare-communication .subject {
  padding-bottom: 0.25rem;
  font-size: 1.2em;
}
.selfcare-communication .date {
  font-weight: 400;
  font-variation-settings: "wght" 400;
  font-size: 0.8em;
}
.selfcare-communication .attachments {
  flex-flow: row wrap;
  gap: 0.25em;
  margin-bottom: 0.5em;
}
.selfcare-communication .attachments, .selfcare-communication .attachments a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selfcare-communication .attachments a {
  gap: 0.5em;
  padding: 0.5em 1em;
  border-radius: 1.5em;
  background-color: #ed135a;
  font-size: 0.8em;
  color: #ffffff;
  white-space: nowrap;
  border: none;
}
.selfcare-communication .attachments a:hover {
  color: rgba(255, 255, 255, 0.9);
}
.selfcare-communication .attachments .icon {
  display: block;
  width: 1.5em;
  height: 1.5em;
  color: transparent;
  fill: #ffffff;
}
.selfcare-communication iframe {
  display: block;
}
.selfcare-communication iframe.iframe-sms {
  margin: 0 auto;
  max-width: 20em;
  border-radius: 0.5em;
  box-shadow: var(--box-shadow);
}

.selfcare-account-table {
  border-radius: 0.5em;
  box-shadow: var(--box-shadow);
}
.selfcare-account-table p {
  padding-top: 0.7em;
  padding-bottom: 0;
}
.selfcare-account-table p:first-child {
  padding-top: 0;
}
.selfcare-account-table .small {
  font-size: 0.8em;
}
.selfcare-account-table .smaller {
  font-size: 0.6em;
}
.selfcare-account-table td, .selfcare-account-table th {
  border: 0;
  background-color: #ffffff;
  line-height: 1.2;
  color: #303a4b;
}
.selfcare-account-table th {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}
.selfcare-account-table .selfcare-account-editable {
  position: relative;
}
.selfcare-account-table tr:nth-child(even) td,
.selfcare-account-table tr:nth-child(even) th {
  background: #f7f7f7;
}
.selfcare-account-table .text {
  display: block;
  word-break: break-word;
}
.selfcare-account-table .selfcare-account-edit-link {
  display: block;
  position: absolute;
  right: 1em;
  padding: 0;
  border: 0;
  background: none;
  font-size: 1em;
  cursor: pointer;
}
.selfcare-account-table .selfcare-account-edit-link > .icon {
  width: inherit;
  height: inherit;
  margin-left: 0;
  vertical-align: inherit;
  color: transparent;
  fill: #303a4b;
}
.js .selfcare-account-table .selfcare-account-edit-link.hidden {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.selfcare-account-table .nowrap {
  position: relative;
  padding-right: 1.5em;
  white-space: nowrap;
}
.selfcare-account-table .nowrap:not(:last-child) {
  margin-right: 1em;
}
.selfcare-account-table .nowrap .icon-cross,
.selfcare-account-table .nowrap .icon-check {
  position: absolute;
  top: -0.15em;
  right: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
  color: transparent;
  fill: #303a4b;
}
.selfcare-account-table .mit-wifi-credentials li {
  padding-bottom: 0;
  line-height: 1.2;
}
.selfcare-account-table .mit-wifi-credentials li span {
  letter-spacing: 0.1em;
}
.selfcare-account-table .selfcare-message-success,
.selfcare-account-table .selfcare-message-error {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.selfcare-account-table .selfcare-message-success {
  color: #40b577;
}
.selfcare-account-table .selfcare-message-error {
  color: #ed135a;
}

.selfcare-account-error {
  padding: 0.25em 0.5em;
  margin-bottom: 0.75em;
  border-radius: 0.5em;
  background-color: #ed135a;
  color: #ffffff;
  line-height: 1.4;
}

.selfcare-account-form {
  max-height: 0;
  margin-top: 0;
  transition: 0.2s max-height cubic-bezier(0.645, 0.045, 0.355, 1);
}
.js .selfcare-account-form {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
}

.selfcare-account-form.visible {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  max-height: 100em;
  margin-top: 0.7em;
}
.selfcare-account-form input,
.selfcare-account-form input::placeholder {
  text-align: left;
}
.selfcare-account-form p.checkbox {
  padding-top: 0;
  padding-bottom: 0.25em;
}
.selfcare-account-form .icon {
  width: 2.5em;
  height: 2.5em;
}
.selfcare-account-form .icon-arrow {
  color: #4b848e;
}

.selfcare-account-form-confirm {
  margin: 0;
  max-width: 12em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

@media (min-width: 961px) {
  .selfcare-header-wrapper,
.selfcare-header {
    min-height: 3.5em;
  }

  .selfcare-header-wrapper {
    height: 3.5em;
  }

  .selfcare-header .pop-up-backdrop {
    top: 3.5em;
    height: calc(100vh - 3.5em);
  }

  .selfcare-header {
    gap: 1em;
    padding: 0.25em 2em;
  }
}
@media (min-width: 961px) and (min-width: 1151px) {
  .selfcare-header-address .dropdown {
    max-width: 56em;
  }
}
@media (min-width: 961px) and (max-width: 1150px) {
  .selfcare-header-address .dropdown {
    max-width: calc(100vw - calc(2em * 2));
  }
}
@media (min-width: 961px) {
  .selfcare-menu-button-container,
.selfcare-menu-button {
    display: none;
  }
}
@media (min-width: 961px) {
  .selfcare-menu,
.selfcare-menu-items {
    height: 100%;
  }
}
@media (min-width: 961px) {
  .selfcare-menu-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.25em;
  }
  .selfcare-menu-items .choose {
    flex-direction: column;
    justify-content: center;
    gap: 0.1em;
    min-width: 5em;
    border-radius: 0.5em;
    padding: 0.25em 0.4em 0.1em;
    color: #ffffff;
  }
  .selfcare-menu-items .choose .icon {
    width: 1.5em;
    height: 1.5em;
  }
  .selfcare-menu-items .choose .text {
    font-size: 0.8em;
  }
  .selfcare-menu-items .choose:hover {
    background-color: #4b848e;
  }
  .selfcare-menu-items .choose.active, .selfcare-menu-items .choose.active:hover {
    background-color: #ed135a;
  }
  .selfcare-menu-items .selfcare-menu-sbbu {
    display: none;
  }
  .selfcare-menu-items .choose#selfcare-logout-link {
    padding-right: 0;
    padding-left: 1.25em;
    min-width: 0;
    background-color: transparent;
  }
  .selfcare-menu-items .choose#selfcare-logout-link .icon path {
    transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform: translateX(0);
  }
  .selfcare-menu-items .choose#selfcare-logout-link:hover .icon path:first-of-type {
    transform: translateX(100px);
  }
  .selfcare-menu-items .choose#selfcare-logout-link:hover .icon path:last-of-type {
    transform: translateX(-50px);
  }
}
@media (min-width: 961px) {
  .selfcare-page {
    padding-top: 6em;
  }
}
@media (min-width: 961px) {
  .selfcare-page hr {
    margin: 3em auto;
    max-width: calc(100% - 4em);
  }
}
@media (min-width: 961px) {
  .id-anchor.id-anchor--selfcare {
    transform: translateY(calc(-3.75em - 3.5em - 3em));
  }
}
@media (min-width: 961px) {
  .selfcare-page-content h1, .selfcare-page-content h2 {
    padding-bottom: 1rem;
  }
}
@media (min-width: 961px) {
  .selfcare-page-login {
    padding-top: 3em;
  }
}
@media (min-width: 961px) {
  .selfcare-communication {
    max-width: calc(600px + 4em);
  }
}
@media (min-width: 961px) {
  .selfcare-page-overview {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "messages account";
    gap: 2em 4em;
  }
  .selfcare-page-overview .selfcare-overview-messages-container {
    grid-area: messages;
  }
  .selfcare-page-overview .selfcare-overview-account {
    grid-area: account;
  }
}
@media (min-width: 961px) and (max-width: calc(19px * 66)) {
  .selfcare-page-overview {
    gap: 2em;
  }
}
@media (min-width: 961px) {
  .selfcare-page-overview .selfcare-overview-messages-container h1, .selfcare-page-overview .selfcare-overview-messages-container h2,
.selfcare-page-overview .selfcare-overview-account h1,
.selfcare-page-overview .selfcare-overview-account h2 {
    text-align: left;
  }
}
@media (min-width: 961px) {
  .selfcare-page-overview .selfcare-overview-message {
    margin-bottom: 0.5em;
  }
  .selfcare-page-overview .selfcare-overview-message.no-link, .selfcare-page-overview .selfcare-overview-message > a {
    padding: 0.75em;
  }
}
@media (min-width: 961px) {
  .selfcare-page-overview .account-container {
    padding: 1em 1.5em;
  }
}
@media (min-width: 961px) {
  .selfcare-report-connection > h2 {
    padding-bottom: 0.1em;
  }
}
@media (min-width: 961px) {
  .selfcare-hardware-item {
    grid-template-areas: "image text" "spec  spec";
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 0 2em;
    margin-bottom: 1em;
    padding: 1.5em 2em;
    max-width: 40em;
  }
  .selfcare-hardware-item.no-image {
    grid-template-areas: "text text" "spec spec";
  }
  .selfcare-hardware-item.no-image .selfcare-hardware-text {
    justify-self: center;
  }
}
@media (min-width: 961px) {
  .selfcare-hardware-text {
    grid-area: text;
    justify-self: start;
  }
  .selfcare-hardware-text h3 {
    text-align: left;
    font-size: 1.5em;
  }
  .selfcare-hardware-text > .buttons {
    justify-content: flex-start;
  }
}
@media (min-width: 961px) {
  .selfcare-hardware-item img {
    max-width: 100%;
  }
  .selfcare-hardware-item img.fiber {
    width: 16em;
  }
  .selfcare-hardware-item img.mbb {
    width: 8em;
  }
  .selfcare-hardware-item img.coax {
    width: 18em;
  }
  .selfcare-hardware-item img.dsl {
    width: 15em;
  }
  .selfcare-hardware-item img.ont {
    width: 8em;
  }
  .selfcare-hardware-item img.wifi-mesh {
    width: 11em;
  }
}
@media (min-width: 961px) {
  .selfcare-hardware-specifications.open {
    margin-top: 1.5em;
  }
}
@media (min-width: 961px) {
  .selfcare-hardware-specifications-table tr td, .selfcare-hardware-specifications-table tr th {
    border-top: 1px solid #dad9d7;
  }
  .selfcare-hardware-specifications-table tr:last-child td, .selfcare-hardware-specifications-table tr:last-child th {
    border-bottom: 1px solid #dad9d7;
  }
  .selfcare-hardware-specifications-table th {
    padding: 0.5em 0.25em 0.5em 0.5em;
    width: 11em;
  }
  .selfcare-hardware-specifications-table td {
    padding: 0.5em 0.5em 0.5em 0.25em;
  }
}
@media (min-width: 961px) {
  .selfcare-help-articles {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 1em;
    margin-top: 0.5em;
  }
  .selfcare-help-articles a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }
}
@media (max-width: 960px) {
  .selfcare-header-wrapper,
.selfcare-menu-button-container {
    height: 3em;
  }

  .selfcare-header {
    gap: 0.5em;
  }

  .selfcare-header .pop-up-backdrop {
    top: 3em;
    height: calc(100vh - 3em);
  }
}
@media (max-width: 960px) and (min-width: 601px) {
  .selfcare-header,
.selfcare-menu-button-container {
    padding: 0.25em 2em;
  }

  .selfcare-header-address .dropdown {
    max-width: calc(100vw - calc(2em * 2));
  }

  .selfcare-menu-items {
    padding: 1.75em 2em 4.6em;
  }
}
@media (max-width: 960px) and (max-width: 600px) and (min-width: 351px) {
  .selfcare-header,
.selfcare-menu-button-container {
    padding: 0.25em 1.5em;
  }

  .selfcare-header-address .dropdown {
    max-width: calc(100vw - calc(1.5em * 2));
  }

  .selfcare-menu-items {
    padding: 1.75em 1.5em 4.6em;
  }
}
@media (max-width: 960px) and (max-width: 350px) {
  .selfcare-header,
.selfcare-menu-button-container {
    padding: 0.25em 0.5em;
  }

  .selfcare-header-address .dropdown {
    max-width: calc(100vw - calc(.5em * 2));
  }

  .selfcare-menu-items {
    padding: 1.75em 0.5em 4.6em;
  }
}
@media (max-width: 960px) {
  .selfcare-menu-button {
    border-radius: 2em;
    cursor: pointer;
    border: none;
    font-size: 100%;
    background: none;
  }
  .selfcare-menu-button:hover {
    opacity: 0.9;
  }
  .selfcare-menu-button .icon {
    display: block;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    transition: opacity 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  .selfcare-menu-button.open .icon, .selfcare-menu-button.close .icon {
    color: transparent;
    fill: #ffffff;
  }
}
@media (max-width: 960px) {
  .selfcare-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 3.75em);
    background-color: #ffffff;
    transform: translateX(100%);
    overflow: hidden;
  }
  .selfcare-menu.menu-open {
    transform: translateX(0);
  }
}
@media (max-width: 960px) {
  .selfcare-menu-items {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1em;
    width: 100%;
    max-height: calc(100vh - 3.75em - 3em);
    overflow-y: auto;
  }
  .selfcare-menu-items .choose {
    justify-content: flex-start;
    gap: 0.5em;
    padding: 0.5em;
    border-radius: 0.5em;
    box-shadow: var(--box-shadow);
    text-align: left;
  }
  .selfcare-menu-items .choose .icon {
    width: 2em;
    height: 2em;
    fill: #303a4b;
  }
  .selfcare-menu-items .choose .text {
    font-size: 1.2em;
  }
  .selfcare-menu-items .choose:hover {
    background-color: #f7f7f7;
    box-shadow: var(--box-shadow-hover);
    color: #303a4b;
  }
  .selfcare-menu-items .choose.active, .selfcare-menu-items .choose.active:hover {
    background-color: #4b848e;
    color: #ffffff;
  }
  .selfcare-menu-items .choose.active .icon, .selfcare-menu-items .choose.active:hover .icon {
    fill: #ffffff;
  }
  .selfcare-menu-items .selfcare-menu-sbbu {
    margin-bottom: 1.5em;
  }
  .selfcare-menu-items .selfcare-menu-sbbu a {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.8em 0.6em;
    border-radius: 1.4em;
    border-bottom: 0;
    background-color: #ed135a;
    text-align: center;
    line-height: 1.2;
    color: #ffffff;
  }
  .selfcare-menu-items .selfcare-menu-sbbu a:hover {
    background-color: #ef2b6b;
  }
  .selfcare-menu-items .choose#selfcare-logout-link {
    margin-top: 1.5em;
    background-color: #ffffff;
    color: #ed135a;
  }
  .selfcare-menu-items .choose#selfcare-logout-link .icon {
    fill: #ed135a;
  }
  .selfcare-menu-items .choose#selfcare-logout-link:hover {
    background-color: #ed135a;
    color: #ffffff;
  }
  .selfcare-menu-items .choose#selfcare-logout-link:hover .icon {
    fill: #ffffff;
  }
}
@media (max-width: 960px) {
  .selfcare-page {
    padding-top: 5em;
  }
}
@media (max-width: 960px) {
  .selfcare-page hr {
    margin: 2em auto;
    max-width: calc(100% - 2em);
  }
}
@media (max-width: 960px) {
  .id-anchor.id-anchor--selfcare {
    transform: translateY(calc(-3.75em - 3em - 2em));
  }
}
@media (max-width: 960px) {
  .selfcare-page-content h1, .selfcare-page-content h2 {
    padding-bottom: 0.5rem;
  }
}
@media (max-width: 960px) {
  .selfcare-page-login {
    padding-top: 2em;
  }
}
@media (max-width: 960px) {
  .selfcare-communication {
    max-width: calc(600px + 1em);
  }
}
@media (max-width: 960px) {
  .selfcare-page-overview {
    margin: 0 auto;
    max-width: 30em;
  }
  .selfcare-page-overview .selfcare-overview-messages-container {
    margin-top: -0.5em;
    margin-bottom: 2em;
  }
  .selfcare-page-overview .selfcare-overview-messages-container h2 {
    padding-bottom: 0;
    font-size: 2em;
  }
  .selfcare-page-overview .selfcare-overview-messages-container:only-child, .selfcare-page-overview .selfcare-overview-messages-container:last-child {
    margin-bottom: 0;
  }
  .selfcare-page-overview .selfcare-overview-messages-container p {
    text-align: center;
  }
  .selfcare-page-overview .selfcare-overview-message {
    margin-bottom: 0.25em;
  }
  .selfcare-page-overview .selfcare-overview-message.no-link, .selfcare-page-overview .selfcare-overview-message > a {
    padding: 0.25em 0.5em;
  }
}
@media (max-width: 960px) {
  .selfcare-page-overview .account-container {
    padding: 1em;
  }
}
@media (max-width: 960px) {
  .selfcare-report-connection > h2 {
    padding-bottom: 0.1em;
  }
}
@media (max-width: 960px) {
  .selfcare-hardware-item {
    grid-template-areas: "image" "text" "spec";
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: start;
    gap: 0;
    margin-bottom: 0.5em;
    padding: 1em;
  }
}
@media (max-width: 960px) {
  .selfcare-hardware-item img {
    max-width: 80%;
  }
  .selfcare-hardware-item img.fiber {
    width: 14em;
  }
  .selfcare-hardware-item img.mbb {
    width: 6em;
  }
  .selfcare-hardware-item img.coax {
    width: 16em;
  }
  .selfcare-hardware-item img.dsl {
    width: 14em;
  }
  .selfcare-hardware-item img.ont {
    width: 7em;
  }
  .selfcare-hardware-item img.wifi-mesh {
    width: 10em;
  }
}
@media (max-width: 960px) {
  .selfcare-hardware-text {
    margin-top: 1em;
  }
  .selfcare-hardware-text h3 {
    font-size: 1.4em;
  }
  .selfcare-hardware-text > .buttons {
    justify-content: center;
  }
  .selfcare-hardware-text .specifications-button {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 960px) {
  .selfcare-hardware-specifications.open {
    margin-top: 0.5em;
  }
}
@media (max-width: 960px) {
  .selfcare-hardware-specifications-table tr th {
    border-top: 1px solid #dad9d7;
  }
  .selfcare-hardware-specifications-table tr:first-child th {
    border-top: 0;
    padding-top: 0;
  }
  .selfcare-hardware-specifications-table tr:last-child td {
    padding-bottom: 0;
  }
  .selfcare-hardware-specifications-table th, .selfcare-hardware-specifications-table td {
    display: block;
    width: 100%;
  }
  .selfcare-hardware-specifications-table th {
    padding: 0.5em 0.5em 0 0.5em;
  }
  .selfcare-hardware-specifications-table td {
    padding: 0 0.5em 0.5em 0.5em;
  }
}
@media (max-width: 960px) {
  .selfcare-help-articles {
    grid-template-columns: 100%;
    gap: 0.5em;
  }
  .selfcare-help-articles a {
    padding: 0.75em 0.5em;
    max-width: 36em;
  }
}
@media (min-width: 521px) {
  .selfcare-header-address.multiple .dropdown {
    left: -0.4rem;
    width: calc(100% + .8rem);
    border-radius: 0.3rem;
  }
  .selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar {
    width: 0.6rem;
  }
  .selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-track,
.selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-track-piece {
    border-radius: 0.3rem 0 0 0.3rem;
  }
  .selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-thumb {
    border-radius: 0.3rem;
  }

  .selfcare-popup {
    top: calc(3.75em + 3.5em + 2em);
    padding: 1.5em 1.5em 1em;
    max-width: calc(100vw - 2em);
    width: 36em;
  }
  .selfcare-popup .close-popup {
    background-color: transparent;
    border: none;
  }

  .pagination {
    grid-template-areas: "previous current next";
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
  }
  .pagination .previous-page {
    justify-self: start;
  }
  .pagination .next-page {
    justify-self: end;
  }

  .selfcare-login-form,
.selfcare-login-resend {
    padding: 2em 2em 1.25em;
  }

  #selfcare-create-input {
    padding-bottom: 2em;
  }

  .selfcare-page-delivery-flow .selfcare-page-summary {
    margin-bottom: 0.75em;
  }

  .selfcare-delivery-flow-status {
    grid-template-columns: calc(2em * .5) auto;
    gap: 0 1em;
    margin-bottom: 1em;
    padding: 1em 1.5em;
  }
  .selfcare-delivery-flow-status .icon {
    font-size: 2em;
  }
  .selfcare-delivery-flow-status a {
    margin-top: 0.25em;
  }

  .selfcare-delivery-flow-timeline .procedure-card {
    margin: 0 0.5em;
  }

  .selfcare-delivery-flow-notification-list {
    max-width: calc(100% - 2em);
  }

  .selfcare-notification {
    --icon-size: calc(4em * 0.625);
    grid-template-areas: "anchor anchor" "icon head" "icon btns" "icon body";
    gap: 0 1em;
    grid-template-columns: var(--icon-size) auto;
  }
  .selfcare-notification.open .notification-buttons {
    margin-bottom: 0.25em;
  }
  .selfcare-notification .id-anchor.id-anchor--selfcare-notification {
    transform: translateY(-50vh);
  }
  .selfcare-notification .notification-icon .icon {
    font-size: var(--icon-size);
  }
  .selfcare-notification h3.heading.heading--montserrat {
    margin-top: -0.15em;
  }

  .selfcare-delivery-flow hr {
    margin: 1.5em auto;
    max-width: calc(50% - 4em);
  }

  .selfcare-delivery-flow-notification-list .selfcare-loader {
    height: 6.1em;
  }

  .selfcare-popup--report-connection h3 {
    font-size: 2em;
  }
  .selfcare-popup--report-connection h4 {
    font-size: 1.1em;
  }
  .selfcare-popup--report-connection.step-start .buttons button {
    width: 4.75em;
  }
  .selfcare-popup--report-connection.step-form .buttons button {
    width: 7.3em;
  }

  .selfcare-popup--mesh h3 {
    font-size: 1.4em;
  }
  .selfcare-popup--mesh .action-box h4 {
    font-size: 1.2em;
  }

  .selfcare-invoices-table {
    border-radius: 0.5em;
    box-shadow: var(--box-shadow);
  }
  .selfcare-invoices-table th, .selfcare-invoices-table td {
    font-size: 0.8em;
    padding: 0.8em 0.4em;
  }
  .selfcare-invoices-table th:first-child, .selfcare-invoices-table td:first-child {
    padding-left: 0.8em;
  }
  .selfcare-invoices-table th:last-child, .selfcare-invoices-table td:last-child {
    padding-right: 0.8em;
  }
  .selfcare-invoices-table tr:nth-child(odd) td {
    background: #f7f7f7;
  }
  .selfcare-invoices-table .selfcare-invoices-table-button {
    justify-content: flex-end;
    gap: 0.5em;
  }
  .selfcare-invoices-table .selfcare-invoices-table-button button, .selfcare-invoices-table .selfcare-invoices-table-button a {
    padding: 0;
  }
  .selfcare-invoices-table .selfcare-invoices-table-button .icon {
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    fill: #303a4b;
    color: transparent;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), fill 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .selfcare-invoices-table .selfcare-invoices-table-button .icon:hover {
    transform: scale(1.1);
    fill: #ed135a;
  }
  .selfcare-invoices-table .hide-on-large {
    display: none;
  }

  .selfcare-invoice-table thead th,
.selfcare-invoice-table tfoot td {
    padding: 0.8em 0.4em;
    font-size: 0.9em;
  }
  .selfcare-invoice-table tbody td {
    padding: 0.6em 0.4em;
  }
  .selfcare-invoice-table th, .selfcare-invoice-table td {
    font-size: 0.8em;
  }

  .selfcare-communication-overview a {
    padding: 1em;
    gap: 1em;
  }
  .selfcare-communication-overview .icon {
    font-size: 2.5em;
  }
  .selfcare-communication-overview .date {
    margin-top: 0.25em;
  }

  .selfcare-account-table th {
    padding: 0.8em 0.5em 0.8em 1em;
    width: 11em;
  }
  .selfcare-account-table td {
    padding: 0.8em 1em 0.8em 0.5em;
  }
  .selfcare-account-table .selfcare-account-editable > .value,
.selfcare-account-table .selfcare-account-editable > .text {
    padding-right: 2.25em;
  }
  .selfcare-account-table .selfcare-account-edit-link {
    top: 0.65em;
    width: 1.5em;
    height: 1.5em;
  }
  .selfcare-account-table .submit-button-flex {
    justify-content: flex-start;
  }
}
@media (max-width: 520px) {
  .selfcare-header-address.multiple .dropdown {
    border-radius: 0.4em;
  }
  .selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar {
    width: 0.8em;
  }
  .selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-track,
.selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-track-piece {
    border-radius: 0.4em 0 0 0.4em;
  }
  .selfcare-header-address.multiple .dropdown ul::-webkit-scrollbar-thumb {
    border-radius: 0.4em;
  }

  .selfcare-popup {
    top: calc(3.75em + 3em + 1em);
    padding: 1em 0.5em 0.5em;
    width: calc(100vw - 1em);
  }
  .selfcare-popup .close-popup {
    background-color: transparent;
    border: none;
  }

  .selfcare-menu-items {
    gap: 0.5em;
  }

  .pagination {
    grid-template-areas: "current current" "previous next";
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
  }
  .pagination .previous-page,
.pagination .next-page {
    width: 100%;
  }
  .pagination .previous-page {
    justify-self: end;
  }
  .pagination .next-page {
    justify-self: start;
  }

  .selfcare-login-form,
.selfcare-login-resend {
    padding: 1.5em 1em;
  }

  #selfcare-create-input {
    padding-bottom: 1.5em;
  }

  .selfcare-page-delivery-flow .selfcare-page-summary {
    margin-bottom: 0.5em;
  }

  .selfcare-delivery-flow hr {
    margin: 1.5em auto;
    max-width: calc(80% - 2em);
  }

  .selfcare-delivery-flow-status {
    grid-template-columns: calc(1.5em * .5) auto;
    gap: 0 0.75em;
    margin-bottom: 1em;
    padding: 1em;
  }
  .selfcare-delivery-flow-status .icon {
    justify-self: center;
    font-size: 1.5em;
  }
  .selfcare-delivery-flow-status a {
    margin-top: 0.5em;
  }

  .selfcare-delivery-flow-timeline li {
    flex: auto 0 0;
    width: calc(100vw - 4em);
    min-width: 14em;
    max-width: 18em;
  }
  .selfcare-delivery-flow-timeline .procedure-card {
    margin: 0 0.75em;
  }

  .selfcare-delivery-flow-notification-list {
    max-width: calc(100% - 1.5em);
  }

  .selfcare-notification {
    --icon-size: calc(3.5em * 0.625);
    grid-template-areas: "anchor anchor" "icon head" "btns btns" "body body";
    gap: 0 0.5em;
    grid-template-columns: var(--icon-size) auto;
    transition: padding-bottom 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), grid-template-columns 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .selfcare-notification .id-anchor.id-anchor--selfcare-notification {
    transform: translateY(-50vh);
  }
  .selfcare-notification .notification-icon .icon {
    font-size: var(--icon-size);
  }
  .selfcare-notification .notification-head {
    align-content: center;
  }
  .selfcare-notification h3.heading.heading--montserrat {
    margin-top: -0.25em;
  }

  .selfcare-delivery-flow-notification-list .selfcare-loader {
    height: 6.075em;
  }

  .selfcare-popup--report-connection h3 {
    font-size: 1.8em;
  }
  .selfcare-popup--report-connection h4 {
    font-size: 1em;
    text-align: left;
  }
  .selfcare-popup--report-connection .buttons {
    flex-direction: column-reverse;
  }
  .selfcare-popup--report-connection .buttons button {
    width: 100%;
  }

  .selfcare-popup--mesh h3 {
    font-size: 1.2em;
  }
  .selfcare-popup--mesh .action-box h4 {
    font-size: 1em;
  }
}
@media (max-width: 520px) and (max-width: 350px) {
  .selfcare-card-container {
    width: 100%;
  }
}
@media (max-width: 520px) {
  .selfcare-card p {
    padding-bottom: 0.25em;
  }
  .selfcare-card .label {
    display: block;
    padding-right: 0;
    font-size: 0.8em;
    color: rgba(48, 58, 75, 0.9);
  }
}
@media (max-width: 520px) and (max-width: 280px) {
  .selfcare-card {
    flex-direction: column;
    text-align: center;
  }
  .selfcare-card .icon {
    font-size: 3em;
  }
}
@media (max-width: 520px) {
  .selfcare-invoices-table table, .selfcare-invoices-table thead, .selfcare-invoices-table tbody, .selfcare-invoices-table td, .selfcare-invoices-table tr {
    display: block;
  }
  .selfcare-invoices-table .hide-on-small {
    display: none;
  }
  .selfcare-invoices-table td {
    min-height: 1em;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: calc(50% + .25em);
    line-height: 1.4;
  }
  .selfcare-invoices-table td::before {
    font-weight: 600;
    font-variation-settings: "wght" 600;
    display: block;
    min-height: 1em;
    position: absolute;
    right: calc(50% + .25em);
    text-align: right;
    white-space: nowrap;
    content: attr(data-label);
  }
  .selfcare-invoices-table .selfcare-invoices-table-button {
    justify-content: center;
    padding: 0;
    height: 2.5em;
    text-align: center;
    color: #ffffff;
    background-color: #4b848e;
  }
  .selfcare-invoices-table .selfcare-invoices-table-button button, .selfcare-invoices-table .selfcare-invoices-table-button a {
    font-weight: 500;
    font-variation-settings: "wght" 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
    height: 100%;
  }
  .selfcare-invoices-table .selfcare-invoices-table-button button .icon, .selfcare-invoices-table .selfcare-invoices-table-button a .icon {
    width: 2em;
    height: 2em;
    fill: #ffffff;
    color: transparent;
  }
  .selfcare-invoices-table .selfcare-invoices-table-button .selfcare-loader .path {
    stroke: #ffffff;
  }
  .selfcare-invoices-table th {
    display: none;
  }
  .selfcare-invoices-table tr:nth-child(1n+1) {
    margin-bottom: 1em;
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow);
  }
  .selfcare-invoices-table tr:nth-child(1n+1):first-child {
    margin-bottom: 0;
  }
  .selfcare-invoices-table tr:nth-child(1n+1) td:first-child {
    padding-top: 0.5em;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .selfcare-invoices-table tr:nth-child(1n+1) td:nth-last-child(2) {
    padding-bottom: 0.5em;
  }
  .selfcare-invoices-table tr:nth-child(1n+1) td:last-child {
    margin-bottom: 0;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .selfcare-invoices-table .creditnote td:nth-last-child(3) {
    padding-bottom: 0.5em;
    border-radius: 0 0 0.5rem 0.5rem;
  }
  .selfcare-invoices-table .creditnote td:nth-last-child(2),
.selfcare-invoices-table .creditnote td:last-child {
    display: none;
  }
}
@media (max-width: 520px) {
  .selfcare-invoice-table thead th,
.selfcare-invoice-table tbody td {
    padding: 0.6em 0.4em;
  }
  .selfcare-invoice-table tfoot td {
    padding: 1em 0.4em;
  }
  .selfcare-invoice-table thead tr,
.selfcare-invoice-table tbody tr {
    display: grid;
    grid-template-columns: auto auto;
  }
  .selfcare-invoice-table thead tr th, .selfcare-invoice-table thead tr td,
.selfcare-invoice-table tbody tr th,
.selfcare-invoice-table tbody tr td {
    width: 100%;
  }
  .selfcare-invoice-table thead tr th:first-child,
.selfcare-invoice-table thead tr td:first-child,
.selfcare-invoice-table tbody tr th:first-child,
.selfcare-invoice-table tbody tr td:first-child {
    grid-row: 1;
    grid-column: 1;
    padding-bottom: 0.2em;
  }
  .selfcare-invoice-table thead tr th:nth-child(2),
.selfcare-invoice-table thead tr td:nth-child(2),
.selfcare-invoice-table tbody tr th:nth-child(2),
.selfcare-invoice-table tbody tr td:nth-child(2) {
    grid-row: 2;
    grid-column: 1;
    padding-top: 0;
    padding-left: 0.8rem;
  }
  .selfcare-invoice-table thead tr th:nth-child(2):not(:nth-last-child(2)),
.selfcare-invoice-table thead tr td:nth-child(2):not(:nth-last-child(2)),
.selfcare-invoice-table tbody tr th:nth-child(2):not(:nth-last-child(2)),
.selfcare-invoice-table tbody tr td:nth-child(2):not(:nth-last-child(2)) {
    padding-bottom: 0.2em;
  }
  .selfcare-invoice-table thead tr th:nth-child(3):not(:last-child),
.selfcare-invoice-table thead tr td:nth-child(3):not(:last-child),
.selfcare-invoice-table tbody tr th:nth-child(3):not(:last-child),
.selfcare-invoice-table tbody tr td:nth-child(3):not(:last-child) {
    grid-row: 3;
    grid-column: 1;
    padding-top: 0;
    padding-left: 0.8rem;
  }
  .selfcare-invoice-table thead tr th:last-child,
.selfcare-invoice-table thead tr td:last-child,
.selfcare-invoice-table tbody tr th:last-child,
.selfcare-invoice-table tbody tr td:last-child {
    grid-row: span 3;
    grid-column: 2;
    align-self: center;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }
  .selfcare-invoice-table tfoot tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .selfcare-invoice-table tfoot tr td {
    width: 100%;
  }
}
@media (max-width: 520px) {
  .selfcare-communication-overview a {
    padding: 0.5em;
    gap: 0.5em;
  }
  .selfcare-communication-overview .icon {
    font-size: 2em;
  }
}
@media (max-width: 520px) {
  .selfcare-account-table tr:first-child th:first-child,
.selfcare-account-table tr:first-child th:only-child {
    border-radius: 0.5em 0.5em 0 0;
  }
  .selfcare-account-table tr:first-child td:last-child {
    border-radius: 0;
  }
  .selfcare-account-table tr:last-child td:last-child,
.selfcare-account-table tr:last-child td:only-child {
    border-radius: 0 0 0.5em 0.5em;
  }
  .selfcare-account-table tr:last-child th:first-child {
    border-radius: 0;
  }
  .selfcare-account-table th, .selfcare-account-table td {
    display: block;
    width: 100%;
  }
  .selfcare-account-table th {
    padding: 0.8em 1em 0 1em;
  }
  .selfcare-account-table td {
    padding: 0.25em 1em 0.8em 1em;
  }
  .selfcare-account-table .selfcare-account-editable > .value,
.selfcare-account-table .selfcare-account-editable > .text {
    padding-right: 3em;
  }
  .selfcare-account-table .selfcare-account-edit-link {
    top: -0.8em;
    width: 2em;
    height: 2em;
  }
  .selfcare-account-table .submit-button-flex {
    justify-content: center;
  }
}
._hj-widget-container {
  position: relative;
  z-index: 99;
}

.prices .content > section:not(:first-of-type) {
  padding-top: 2em;
}

.prices .tool-tip,
.adjustments .tool-tip {
  display: inline-block;
  color: #ed135a;
}
.prices .tool-tip .tool-tip-text,
.adjustments .tool-tip .tool-tip-text {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  padding: 1em;
  border-radius: 0.5em;
  text-align: left;
  line-height: 1.6;
  color: #ffffff;
  white-space: normal;
  background-color: #303a4b;
}
.prices .tool-tip .tool-tip-text *,
.adjustments .tool-tip .tool-tip-text * {
  transition: all 0s;
}
.prices .tool-tip .tool-tip-text strong,
.adjustments .tool-tip .tool-tip-text strong {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 1.4em;
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.025em;
}
.prices .tool-tip:hover .tool-tip-text,
.adjustments .tool-tip:hover .tool-tip-text {
  visibility: visible;
}
.prices .prices-table .tool-tip .tool-tip-text,
.adjustments .prices-table .tool-tip .tool-tip-text {
  margin-top: 1.5em;
  width: 22em;
  transform: translateX(-100%);
}

.adjustments .ellipse {
  position: relative;
  top: 0.05em;
  z-index: 1;
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border-radius: 0.4em;
  text-align: right;
}
.adjustments .ellipse.ellipse-fiber {
  background-color: #ed135a;
}
.adjustments .ellipse.ellipse-mbb {
  background-color: #4b848e;
}
.adjustments .ellipse.ellipse-coax {
  background-color: #7acfe0;
}
.adjustments .ellipse.ellipse-dsl {
  background-color: #999999;
}
.adjustments .ellipse span {
  position: absolute;
  z-index: -1;
  top: -0.2rem;
  right: 0;
  font-size: 0.9em;
  padding: 0 0.4em;
  height: 1.2rem;
  border-radius: 0.6rem;
  color: #ffffff;
  background-color: inherit;
  white-space: nowrap;
  display: block;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}
@media (max-width: 520px) {
  .adjustments .ellipse span {
    line-height: 1.35;
  }
}
.adjustments .ellipse:hover span {
  opacity: 1;
}
.adjustments .ellipse-explainer {
  display: flex;
  justify-content: center;
  gap: 0 1.5em;
  margin-bottom: 0.5em;
}
.adjustments .ellipse-explainer li {
  margin: 0;
  padding: 0;
  line-height: 1.2;
}
.adjustments .ellipse-explainer .ellipse {
  top: 0.13em;
}
.adjustments .ellipse-explainer span {
  font-size: 0.8em;
}
@media (min-width: 641px) {
  .adjustments h3 {
    padding-bottom: 0.2rem;
  }
  .adjustments .ellipse-explainer {
    flex-direction: row;
    align-items: center;
  }
}
@media (max-width: 640px) {
  .adjustments h3 {
    padding-bottom: 0;
  }
  .adjustments .ellipse-explainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

.prices .blurb {
  max-width: none;
}

.prices-container.accordion-content, .prices-container.accordion-content.active {
  padding-left: 0;
  padding-right: 0;
}

.price-divider {
  max-width: none;
  margin: 1em auto;
}

.prices-container .coverage-map-wrapper p {
  font-size: 0.8em;
  line-height: 1.4;
}
.prices-container .coverage-map-wrapper.coverage-map-wrapper--mbb iframe {
  display: block;
  width: 100%;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}

.prices-table,
.adjustments-table {
  margin: 0;
}
.prices-table th, .prices-table td,
.adjustments-table th,
.adjustments-table td {
  padding: 0;
  border: 0;
  vertical-align: middle;
}
.prices-table td,
.adjustments-table td {
  line-height: 1.4;
}

.prices-table {
  max-width: 22em;
}
.prices-table tr:not(:only-child):not(:first-child) td:not(.prices-table-coax300) {
  padding-top: 1em;
}
.prices-table td:not(.prices-table-coax300):first-child:not(:only-child) {
  padding-right: 0.5em;
}
.prices-table td:not(.prices-table-coax300):last-child:not(:only-child) {
  text-align: right;
  white-space: nowrap;
}
.prices-table .prices-table-address-button {
  text-align: center;
}
.prices-table .prices-table-address-button p:first-child {
  text-align: left;
  font-size: 0.9em;
}
.prices-table .prices-table-address-button a {
  width: 100%;
}
.prices-table .prices-table-address-button a + p {
  padding-top: 0.25em;
}
.prices-table .prices-table-coax300 {
  padding: 0.9em;
  border-radius: 0.5em;
  background-color: #f7f7f7;
  text-align: center;
}
.prices-table .prices-table-coax300 .heading {
  margin: 0;
  color: inherit;
}
.prices-table .prices-table-coax300 p {
  font-size: 0.7em;
}
.prices-table .prices-table-dsl {
  color: rgba(48, 58, 75, 0.75);
}

.adjustments-table th {
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-size: 1.2em;
  text-transform: uppercase;
  padding: 0.2777777778em 1em;
  background: #4b848e;
}
.adjustments-table th:first-child {
  border-radius: 0.5em 0 0 0;
}
.adjustments-table th:last-child {
  border-radius: 0 0.5em 0 0;
}
.adjustments-table td {
  padding: 0.5em 1em;
}
.adjustments-table thead tr th:last-child {
  text-align: right;
}
.adjustments-table tbody tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.025);
}
.adjustments-table tbody tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.adjustments-table tbody tr td:first-child {
  padding-right: 0;
}
.adjustments-table tbody tr td:last-child {
  padding-left: 0.5em;
  text-align: right;
  white-space: nowrap;
}

@media (min-width: 769px) {
  .adjustments .adjustments-table .tool-tip {
    position: relative;
  }
  .adjustments .adjustments-table .tool-tip::before {
    display: none;
    content: "";
    position: absolute;
    top: 1.05em;
    left: 50%;
    margin-left: -0.4em;
    border-width: 0.4em;
    border-style: solid;
    border-color: transparent transparent #303a4b;
  }
  .adjustments .adjustments-table .tool-tip:hover::before {
    display: block;
  }
  .adjustments .adjustments-table .tool-tip .tool-tip-text {
    margin-top: 0.35em;
    margin-left: -8.5em;
    width: 17em;
    top: 100%;
    left: 50%;
  }

  .prices-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1em 2em;
  }
  .prices-container .coverage-map-wrapper.coverage-map-wrapper--mbb {
    width: 50%;
  }
  .prices-container .coverage-map-wrapper.coverage-map-wrapper--mbb iframe {
    height: 26em;
  }
  .prices-container .coverage-map {
    max-width: 18em;
  }
}
@media (max-width: 768px) and (min-width: 521px) {
  .adjustments .adjustments-table .tool-tip .tool-tip-text {
    width: 24em;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 768px) {
  .prices-container .coverage-map-wrapper p {
    text-align: center;
  }
  .prices-container .coverage-map-wrapper.coverage-map-wrapper--mbb {
    width: 100%;
  }
  .prices-container .coverage-map-wrapper.coverage-map-wrapper--mbb iframe {
    margin: 0 auto 1em;
    max-width: 32em;
    max-height: 60vh;
    height: 32em;
  }
  .prices-container .coverage-map {
    max-width: 10em;
    margin: 0 auto 0.5em;
    filter: drop-shadow(0.5px 1px 2px rgba(0, 0, 0, 0.3));
  }
  .prices-container.accordion-content .coverage-map {
    margin-top: 2px;
  }
  .prices-container .prices-table {
    width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 521px) {
  .prices-table {
    flex-shrink: 0;
  }
}
@media (max-width: 520px) {
  .prices .prices-table .tool-tip .tool-tip-text,
.adjustments .prices-table .tool-tip .tool-tip-text {
    transform: translateX(-50%);
    left: 50%;
    padding: 0.8em 0.5em;
    max-width: calc(100vw - 2em);
    width: 100%;
  }

  .adjustments .adjustments-table .tool-tip::before {
    display: none;
    content: "";
    position: absolute;
    margin-top: 1.05em;
    margin-left: 0.1em;
    border-width: 0.4em;
    border-style: solid;
    border-color: transparent transparent #303a4b;
  }
  .adjustments .adjustments-table .tool-tip:hover::before {
    display: block;
  }
  .adjustments .adjustments-table .tool-tip .tool-tip-text {
    margin-top: 1.75em;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .adjustments-table th, .adjustments-table td {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
.wl-overlay {
  display: none;
  z-index: 10;
}
.wl-overlay.visible {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.wl-overlay .error {
  box-shadow: 0 0 0 3px #ffffff, 0 0 0 5px #ed135a;
}
.wl-overlay .form__sending-overlay {
  background: rgba(255, 255, 255, 0.9);
}
.wl-overlay .form__sent-overlay {
  background: #ffffff;
}
.wl-overlay .form__not-sent-overlay {
  background: #ffffff;
}
.wl-overlay__close {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.1em 0.4em;
  color: #ed135a;
  font-size: 2.5em;
  line-height: 1em;
  cursor: pointer;
}

.wl-overlay__window {
  position: absolute;
  top: 5.75em;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  max-height: 75vh;
  border-radius: 0.5em;
  overflow-y: auto;
  -ms-overflow-style: none;
  background: #ffffff;
  text-align: center;
  box-shadow: var(--box-shadow);
}
@media (min-width: 521px) {
  .wl-overlay__window {
    padding: 3em 1.5em 2em;
    max-width: 52em;
    min-width: 22em;
    width: calc(100vw - 4em);
  }
}
@media (max-width: 520px) {
  .wl-overlay__window {
    padding: 2.5em 1em 1.5em;
    max-width: 22em;
    width: calc(100vw - 2em);
  }
}

.wl-overlay form {
  padding: 2em 0 0 0;
}

.price-calc {
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
}
.price-calc .heading {
  padding-bottom: 0.5rem;
}
.price-calc .summary-table {
  max-width: 20rem;
  margin-bottom: 1em auto;
}
.price-calc .summary-table thead th {
  padding-bottom: 0;
}
.price-calc .summary-table tbody td,
.price-calc .summary-table tfoot td {
  font-size: 0.9em;
}
.price-calc .summary-table tbody tr:last-child td {
  border-radius: 0;
}
.price-calc .summary-table.single tbody tr:last-child td {
  border-bottom: 1px solid #dad9d7;
}
.price-calc .summary-table.single tbody td:last-child, .price-calc .summary-table.single tbody td:last-child .discount {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.price-calc .summary-table.single tbody td:last-child .strikethrough {
  font-weight: 400;
  font-variation-settings: "wght" 400;
}
.price-calc .summary-table.monthly tbody tr:only-child td {
  border-bottom: 1px solid #dad9d7;
}
.price-calc .summary-table.monthly tbody tr:only-child td:last-child {
  font-weight: 500;
  font-variation-settings: "wght" 500;
}
.price-calc .summary-table + p {
  padding: 1rem 0 0;
  font-size: 1.1em;
}
.price-calc .vat-notice {
  font-size: 0.8em;
}

.site-is-updating .logo {
  max-width: 250px;
}
.site-is-updating .hiper-type {
  fill: #303a4b;
}
.site-is-updating .heading {
  margin-bottom: 0;
  padding-bottom: 0;
}

.maintenance-rocket-ftw {
  display: block;
  max-width: 100px;
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: none;
  fill: rgba(48, 58, 75, 0.5);
}

@keyframes rocket {
  0% {
    transform: translate(-50%, 0) rotate(-1deg);
  }
  25% {
    transform: translate(-50%, 3%) rotate(1deg);
  }
  50% {
    transform: translate(-50%, 5%) rotate(-1deg);
  }
  100% {
    transform: translate(-50%, 3%) rotate(1deg);
  }
}
@media (min-width: 750px) and (min-height: 625px) {
  .site-is-updating__section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .maintenance-rocket-ftw {
    max-width: 200px;
    position: fixed;
    animation: 0.5s linear 0s infinite alternate rocket;
  }
}
.service-status-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  grid-gap: 1em;
}
@media (max-width: 1029px) {
  .service-status-wrapper {
    grid-gap: 0.75em;
  }
}

.service-status--no-issues,
.service-status--mbb-button-container {
  margin-bottom: 1em;
}
.service-status--no-issues .content,
.service-status--mbb-button-container .content {
  border-radius: 1em;
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
}

.service-status--mbb-button {
  box-shadow: var(--box-shadow);
}

.service-status-issues {
  margin: auto;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.service-status-issue,
.service-status-messages {
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.service-status-issue .created_dtm,
.service-status-issue .status,
.service-status-issue .message_created_dtm,
.service-status-issue-message .created_dtm,
.service-status-issue-message .status,
.service-status-issue-message .message_created_dtm {
  min-width: 6.5em;
  padding: 0.2em 0.4em 0.1em;
  border-radius: 0.25em;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.035rem;
  text-transform: uppercase;
}

.service-status-issue {
  width: 100%;
  padding: 0.7em 1em;
  border: 0;
  border-radius: 0.5em;
  background: #ffffff;
  box-shadow: var(--box-shadow);
  text-align: left;
}
.service-status-issue.service-status-issue__pinned {
  box-shadow: 0 0 0 2px #ed135a, var(--box-shadow);
}
.service-status-issue .service-status-issue__main {
  display: flex;
  align-items: flex-start;
  gap: 0.5em;
  text-decoration: none;
}
.service-status-issue:hover {
  color: inherit;
  cursor: default;
}
.service-status-issue.service-status-issue__has-messages:hover:not(.service-status-issue__unfolded) {
  box-shadow: var(--box-shadow-hover);
  color: inherit;
  cursor: pointer;
}
.service-status-issue.service-status-issue__has-messages:hover:not(.service-status-issue__unfolded) .read-more {
  border-bottom-color: rgba(0, 0, 0, 0);
  color: #ed135a;
}
.service-status-issue .created_dtm-and-status {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  padding-top: 0.3em;
  transition: inherit;
}
.service-status-issue .created_dtm, .service-status-issue .status {
  color: #ffffff;
}
.service-status-issue .created_dtm {
  background: #303a4b;
}
.service-status-issue.service-status-issue__open .status {
  background-color: #ed135a;
}
.service-status-issue.service-status-issue__closed .status {
  background-color: #40b577;
}
.service-status-issue .details {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.service-status-issue .details * {
  line-height: 1.4;
}
.service-status-issue .details .subject {
  font-weight: 500;
  font-variation-settings: "wght" 500;
  padding: 0 0 0.35em 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1em;
  color: inherit;
  text-align: left;
  letter-spacing: normal;
  text-transform: none;
}
.service-status-issue .details span {
  padding-bottom: 0.35em;
}
.service-status-issue .details span:last-child {
  padding-bottom: 0;
}
.service-status-issue .details > *:not(.subject) {
  font-size: 0.9em;
}
.service-status-issue .read-more {
  border-bottom: 2px solid #ed135a;
  color: inherit;
  white-space: nowrap;
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.service-status-issue .read-more .hide {
  display: none;
}
.service-status-issue .read-more .show {
  display: inline;
}
@media (max-width: 700px) {
  .service-status-issue {
    text-align: center;
  }
  .service-status-issue .mobile-hide {
    display: none;
  }
  .service-status-issue .service-status-issue__main {
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 0.5em;
  }
  .service-status-issue .created_dtm-and-status {
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 0;
  }
  .service-status-issue .status,
.service-status-issue .created_dtm {
    display: block;
    min-width: 0;
    font-size: 1.2em;
  }
  .service-status-issue .details {
    gap: 0.25em;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
  }
  .service-status-issue .details .area, .service-status-issue .details .eta, .service-status-issue .details .subject, .service-status-issue .details .finished_dtm, .service-status-issue .details .updated_dtm {
    display: block;
    padding: 0;
    text-align: left;
  }
  .service-status-issue .updated_dtm--date {
    display: block;
  }
  .service-status-issue .read-more {
    display: inline-block;
  }
  .service-status-issue .read-more:first-letter {
    text-transform: capitalize;
  }
}

.service-status-map {
  width: 100%;
  max-width: 26em;
  max-height: 100%;
  margin: auto;
}
.service-status-map.service-status-map__empty {
  max-width: 16em;
}
.service-status-map:hover .area.active .issues {
  animation: none;
}
.service-status-map svg {
  width: 100%;
  max-height: 100%;
}
.service-status-map .area {
  fill: #dad9d7;
  cursor: default;
}
.service-status-map .area .issues {
  transition: inherit;
}
.service-status-map .area .count-circle {
  transition: inherit;
}
.service-status-map .area .count {
  font-weight: 600;
  font-variation-settings: "wght" 600;
  font-size: 5em;
  transition: inherit;
}
.service-status-map .area.active {
  fill: #ed135a;
  cursor: pointer;
}
.service-status-map .area.active .count-circle {
  fill: #303a4b;
}
.service-status-map .area.active .count {
  fill: #ffffff;
}
.service-status-map .area.active:hover {
  fill: #d61051;
}

.id-anchor--mbb-service-status {
  transform: translateY(calc(-3.75em - 2em));
}

#mbb-service-status p {
  max-width: 38em;
  text-align: center;
  line-height: 1.4;
}
#mbb-service-status iframe {
  border-radius: 0.5em;
  box-shadow: var(--box-shadow);
}
@media (min-width: 701px) {
  #mbb-service-status iframe {
    min-height: 560px;
    height: calc(100vh - 3.75em - 8em);
  }
}
@media (max-width: 700px) {
  #mbb-service-status iframe {
    height: calc(100vh - 3.75em - 2.5em - 56px - 40px - 3em);
  }
}

.issues-finished {
  padding-top: 1em;
}
.issues-finished .service-status-issue .read-more {
  border-bottom-color: #999999;
}
.issues-finished .service-status-issue.service-status-issue__has-messages:hover .read-more {
  border-bottom-color: rgba(153, 153, 153, 0);
  color: #999999;
}

.issues-finished--list {
  transition: padding-top 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), max-height 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), opacity 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.issues-finished--list__hidden .issues-finished--list {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  padding-top: 0;
  max-height: 0;
  opacity: 0;
}

.issues-finished--list__visible .issues-finished--list {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  padding-top: 1.5em;
  max-height: 200em;
  opacity: 1;
}

.issues-finished--button .hide {
  display: none;
}

.issues-finished--list__visible .issues-finished--button {
  background-color: #f7f7f7;
  box-shadow: inset 0 0 0 1px #999999;
  color: #303a4b;
}
.issues-finished--list__visible .issues-finished--button:hover, .issues-finished--list__visible .issues-finished--button:focus {
  background-color: #eeeeee;
}
.issues-finished--list__visible .issues-finished--button .show {
  display: none;
}
.issues-finished--list__visible .issues-finished--button .hide {
  display: inline;
}

.service-status-issue-messages {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  max-height: 0;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0;
}

.service-status-issue__messages-visible .service-status-issue.service-status-issue__has-messages:hover {
  background: #eeeeee;
  cursor: default;
}
.service-status-issue__messages-visible .read-more .show {
  display: none;
}
.service-status-issue__messages-visible .read-more .hide {
  display: inline;
}
.service-status-issue__messages-visible .service-status-issue-messages {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  max-height: 200em;
  transition: all 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  opacity: 1;
}

.service-status-issue-message {
  display: flex;
  align-items: flex-start;
  gap: 0.5em;
  padding: 0.9em 0 0.5em;
  border-top: 1px solid #dad9d7;
  text-align: left;
}
.service-status-issue-message:first-child {
  margin-top: 0.5em;
}
.service-status-issue-message:last-child {
  padding-bottom: 0;
}
.service-status-issue-message .message_created_dtm,
.service-status-issue-message .message {
  display: block;
}
.service-status-issue-message .message_created_dtm {
  background-color: #eeeeee;
  box-shadow: inset 0 0 0 1px #dad9d7;
}
.service-status-issue-message .message {
  font-size: 0.9em;
  line-height: 1.4;
}
@media (max-width: 700px) {
  .service-status-issue-message {
    flex-direction: column;
    align-items: center;
  }
  .service-status-issue-message .message_created_dtm {
    display: block;
    min-width: 0;
    font-size: 1.2em;
  }
}

.page-home .splash--free-initiation,
.page-home .splash--override-adjustment-price-for-product,
.page-home .splash--override-tariff-for-product,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-home .splash--switcharoo,
.page-products .splash--free-initiation,
.page-products .splash--override-adjustment-price-for-product,
.page-products .splash--override-tariff-for-product,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-products .splash--switcharoo,
.page-order-products .splash--free-initiation,
.page-order-products .splash--override-adjustment-price-for-product,
.page-order-products .splash--override-tariff-for-product,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-order-products .splash--switcharoo {
  top: -3.75em;
  right: -4.5em;
  font-size: 1em;
  letter-spacing: 0;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: rotate(10deg);
  opacity: 1;
}
@media (max-width: 768px) {
  .page-home .splash--free-initiation,
.page-home .splash--override-adjustment-price-for-product,
.page-home .splash--override-tariff-for-product,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-home .splash--switcharoo,
.page-products .splash--free-initiation,
.page-products .splash--override-adjustment-price-for-product,
.page-products .splash--override-tariff-for-product,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-products .splash--switcharoo,
.page-order-products .splash--free-initiation,
.page-order-products .splash--override-adjustment-price-for-product,
.page-order-products .splash--override-tariff-for-product,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-order-products .splash--switcharoo {
    position: absolute;
    font-size: 0.75em;
  }
}
@media (max-width: 700px) {
  .page-home .splash--free-initiation,
.page-home .splash--override-adjustment-price-for-product,
.page-home .splash--override-tariff-for-product,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-home .splash--switcharoo,
.page-products .splash--free-initiation,
.page-products .splash--override-adjustment-price-for-product,
.page-products .splash--override-tariff-for-product,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-products .splash--switcharoo,
.page-order-products .splash--free-initiation,
.page-order-products .splash--override-adjustment-price-for-product,
.page-order-products .splash--override-tariff-for-product,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-order-products .splash--switcharoo {
    top: -5.25em;
    right: 50%;
    font-size: 0.7em;
    transform: rotate(0deg) translate(50%);
  }
}
@media (max-width: 520px) {
  .page-home .splash--free-initiation,
.page-home .splash--override-adjustment-price-for-product,
.page-home .splash--override-tariff-for-product,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-home .splash--switcharoo,
.page-products .splash--free-initiation,
.page-products .splash--override-adjustment-price-for-product,
.page-products .splash--override-tariff-for-product,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-products .splash--switcharoo,
.page-order-products .splash--free-initiation,
.page-order-products .splash--override-adjustment-price-for-product,
.page-order-products .splash--override-tariff-for-product,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.page-order-products .splash--switcharoo {
    top: -6em;
    font-size: 0.65em;
  }
}
.address-lookup-typing .page-home .splash--free-initiation,
.address-lookup-typing .page-home .splash--override-adjustment-price-for-product,
.address-lookup-typing .page-home .splash--override-tariff-for-product,
.address-lookup-typing .page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.address-lookup-typing .page-home .splash--switcharoo,
.address-lookup-typing .page-products .splash--free-initiation,
.address-lookup-typing .page-products .splash--override-adjustment-price-for-product,
.address-lookup-typing .page-products .splash--override-tariff-for-product,
.address-lookup-typing .page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.address-lookup-typing .page-products .splash--switcharoo,
.address-lookup-typing .page-order-products .splash--free-initiation,
.address-lookup-typing .page-order-products .splash--override-adjustment-price-for-product,
.address-lookup-typing .page-order-products .splash--override-tariff-for-product,
.address-lookup-typing .page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product,
.address-lookup-typing .page-order-products .splash--switcharoo {
  opacity: 0;
}

.page-home .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--switcharoo.splash--switcharoo-double.splash--secondary {
  right: auto;
  left: -4.5em;
  transform: rotate(-10deg);
}
@media (max-width: 768px) {
  .page-home .splash--free-initiation.splash--switcharoo-double,
.page-home .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-home .splash--override-tariff-for-product.splash--switcharoo-double,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-home .splash--switcharoo.splash--switcharoo-double,
.page-products .splash--free-initiation.splash--switcharoo-double,
.page-products .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-products .splash--override-tariff-for-product.splash--switcharoo-double,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-products .splash--switcharoo.splash--switcharoo-double,
.page-order-products .splash--free-initiation.splash--switcharoo-double,
.page-order-products .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-order-products .splash--override-tariff-for-product.splash--switcharoo-double,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-order-products .splash--switcharoo.splash--switcharoo-double {
    position: absolute;
    font-size: 0.75em;
  }
  .page-home .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--switcharoo.splash--switcharoo-double.splash--secondary {
    position: absolute;
    font-size: 0.75em;
  }
}
@media (max-width: 700px) {
  .page-home .splash--free-initiation.splash--switcharoo-double,
.page-home .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-home .splash--override-tariff-for-product.splash--switcharoo-double,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-home .splash--switcharoo.splash--switcharoo-double,
.page-products .splash--free-initiation.splash--switcharoo-double,
.page-products .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-products .splash--override-tariff-for-product.splash--switcharoo-double,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-products .splash--switcharoo.splash--switcharoo-double,
.page-order-products .splash--free-initiation.splash--switcharoo-double,
.page-order-products .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-order-products .splash--override-tariff-for-product.splash--switcharoo-double,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-order-products .splash--switcharoo.splash--switcharoo-double {
    top: -5.25em;
    right: auto;
    left: 20%;
    z-index: 2;
    font-size: 0.7em;
    transform: rotate(-5deg) translate(0%);
  }
  .page-home .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--switcharoo.splash--switcharoo-double.splash--secondary {
    top: -5.25em;
    right: 20%;
    left: auto;
    z-index: 1;
    font-size: 0.7em;
    transform: rotate(5deg) translate(0%);
  }
}
@media (max-width: 550px) {
  .page-home .splash--free-initiation.splash--switcharoo-double,
.page-home .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-home .splash--override-tariff-for-product.splash--switcharoo-double,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-home .splash--switcharoo.splash--switcharoo-double,
.page-products .splash--free-initiation.splash--switcharoo-double,
.page-products .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-products .splash--override-tariff-for-product.splash--switcharoo-double,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-products .splash--switcharoo.splash--switcharoo-double,
.page-order-products .splash--free-initiation.splash--switcharoo-double,
.page-order-products .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-order-products .splash--override-tariff-for-product.splash--switcharoo-double,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-order-products .splash--switcharoo.splash--switcharoo-double {
    right: auto;
    left: 7%;
    transform: rotate(-5deg) translate(0%);
  }
  .page-home .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--switcharoo.splash--switcharoo-double.splash--secondary {
    right: 7%;
    left: auto;
    transform: rotate(5deg) translate(0%);
  }
}
@media (max-width: 520px) {
  .page-home .splash--free-initiation.splash--switcharoo-double,
.page-home .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-home .splash--override-tariff-for-product.splash--switcharoo-double,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-home .splash--switcharoo.splash--switcharoo-double,
.page-products .splash--free-initiation.splash--switcharoo-double,
.page-products .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-products .splash--override-tariff-for-product.splash--switcharoo-double,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-products .splash--switcharoo.splash--switcharoo-double,
.page-order-products .splash--free-initiation.splash--switcharoo-double,
.page-order-products .splash--override-adjustment-price-for-product.splash--switcharoo-double,
.page-order-products .splash--override-tariff-for-product.splash--switcharoo-double,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double,
.page-order-products .splash--switcharoo.splash--switcharoo-double {
    top: -6em;
    font-size: 0.65em;
  }
  .page-home .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-home .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-products .splash--switcharoo.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--free-initiation.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--switcharoo-double.splash--secondary,
.page-order-products .splash--switcharoo.splash--switcharoo-double.splash--secondary {
    top: -6em;
    font-size: 0.65em;
  }
}
.page-home .splash--free-initiation .splash__heading,
.page-home .splash--override-adjustment-price-for-product .splash__heading,
.page-home .splash--override-tariff-for-product .splash__heading,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading,
.page-home .splash--switcharoo .splash__heading,
.page-products .splash--free-initiation .splash__heading,
.page-products .splash--override-adjustment-price-for-product .splash__heading,
.page-products .splash--override-tariff-for-product .splash__heading,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading,
.page-products .splash--switcharoo .splash__heading,
.page-order-products .splash--free-initiation .splash__heading,
.page-order-products .splash--override-adjustment-price-for-product .splash__heading,
.page-order-products .splash--override-tariff-for-product .splash__heading,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading,
.page-order-products .splash--switcharoo .splash__heading {
  display: block;
  margin: 0;
  padding: 0.1em 0 0.2em 0;
  font-size: 1.05em;
  color: #ed135a;
  hyphens: none;
}
.page-home .splash--free-initiation.splash--blue .splash__heading,
.page-home .splash--override-adjustment-price-for-product.splash--blue .splash__heading,
.page-home .splash--override-tariff-for-product.splash--blue .splash__heading,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--blue .splash__heading,
.page-home .splash--switcharoo.splash--blue .splash__heading,
.page-products .splash--free-initiation.splash--blue .splash__heading,
.page-products .splash--override-adjustment-price-for-product.splash--blue .splash__heading,
.page-products .splash--override-tariff-for-product.splash--blue .splash__heading,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--blue .splash__heading,
.page-products .splash--switcharoo.splash--blue .splash__heading,
.page-order-products .splash--free-initiation.splash--blue .splash__heading,
.page-order-products .splash--override-adjustment-price-for-product.splash--blue .splash__heading,
.page-order-products .splash--override-tariff-for-product.splash--blue .splash__heading,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product.splash--blue .splash__heading,
.page-order-products .splash--switcharoo.splash--blue .splash__heading {
  color: white;
}
.page-home .splash--free-initiation .splash__message,
.page-home .splash--override-adjustment-price-for-product .splash__message,
.page-home .splash--override-tariff-for-product .splash__message,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message,
.page-home .splash--switcharoo .splash__message,
.page-products .splash--free-initiation .splash__message,
.page-products .splash--override-adjustment-price-for-product .splash__message,
.page-products .splash--override-tariff-for-product .splash__message,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message,
.page-products .splash--switcharoo .splash__message,
.page-order-products .splash--free-initiation .splash__message,
.page-order-products .splash--override-adjustment-price-for-product .splash__message,
.page-order-products .splash--override-tariff-for-product .splash__message,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message,
.page-order-products .splash--switcharoo .splash__message {
  display: block;
  max-width: 8em;
  margin: auto;
  padding: 0;
  font-size: 0.5em;
  color: inherit;
}
.page-home .splash--free-initiation .splash__message-secondary,
.page-home .splash--override-adjustment-price-for-product .splash__message-secondary,
.page-home .splash--override-tariff-for-product .splash__message-secondary,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message-secondary,
.page-home .splash--switcharoo .splash__message-secondary,
.page-products .splash--free-initiation .splash__message-secondary,
.page-products .splash--override-adjustment-price-for-product .splash__message-secondary,
.page-products .splash--override-tariff-for-product .splash__message-secondary,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message-secondary,
.page-products .splash--switcharoo .splash__message-secondary,
.page-order-products .splash--free-initiation .splash__message-secondary,
.page-order-products .splash--override-adjustment-price-for-product .splash__message-secondary,
.page-order-products .splash--override-tariff-for-product .splash__message-secondary,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message-secondary,
.page-order-products .splash--switcharoo .splash__message-secondary {
  display: block;
  max-width: 8em;
  margin: 0 auto;
  padding: 0;
  font-size: 0.6em;
  color: #ed135a;
  line-height: 1;
}
.page-home .splash--free-initiation .splash__message-info,
.page-home .splash--override-adjustment-price-for-product .splash__message-info,
.page-home .splash--override-tariff-for-product .splash__message-info,
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message-info,
.page-home .splash--switcharoo .splash__message-info,
.page-products .splash--free-initiation .splash__message-info,
.page-products .splash--override-adjustment-price-for-product .splash__message-info,
.page-products .splash--override-tariff-for-product .splash__message-info,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message-info,
.page-products .splash--switcharoo .splash__message-info,
.page-order-products .splash--free-initiation .splash__message-info,
.page-order-products .splash--override-adjustment-price-for-product .splash__message-info,
.page-order-products .splash--override-tariff-for-product .splash__message-info,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message-info,
.page-order-products .splash--switcharoo .splash__message-info {
  display: block;
  max-width: 8em;
  margin: 0 auto;
  padding: 0;
  font-size: 0.4em;
  line-height: 0.2;
}
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading {
  padding: 0;
  font-size: 0.85em;
  line-height: 0.8em;
}
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message-info,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message-info,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message-info {
  padding: 0;
  line-height: 1;
}
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading--secondary,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading--secondary,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading--secondary {
  margin: 0;
  padding: 0;
  font-size: 0.45em;
  line-height: 1;
}
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading--secondary .price,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading--secondary .price,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__heading--secondary .price {
  font-size: 2.5em;
  letter-spacing: -0.1rem;
}
.page-home .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message,
.page-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message,
.page-order-products .splash--override-tariff-for-product-and-override-adjustment-price-for-product .splash__message {
  font-size: 0.4em;
  line-height: 1;
}
.page-home .splash--switcharoo .splash__heading,
.page-products .splash--switcharoo .splash__heading,
.page-order-products .splash--switcharoo .splash__heading {
  padding: 0;
  padding-top: 0.2em;
  font-size: 0.85em;
  line-height: 0.8em;
}
.page-home .splash--switcharoo .splash__heading--secondary,
.page-products .splash--switcharoo .splash__heading--secondary,
.page-order-products .splash--switcharoo .splash__heading--secondary {
  margin: 0;
  padding: 0;
  font-size: 0.45em;
  line-height: 0.9;
}
.page-home .splash--switcharoo .splash__heading--secondary .price,
.page-products .splash--switcharoo .splash__heading--secondary .price,
.page-order-products .splash--switcharoo .splash__heading--secondary .price {
  font-size: 2.5em;
  letter-spacing: -0.1rem;
}
.page-home .splash--switcharoo .splash__message-info,
.page-products .splash--switcharoo .splash__message-info,
.page-order-products .splash--switcharoo .splash__message-info {
  padding: 0;
  padding-bottom: 0.2em;
  font-size: 0.55em;
  color: #ed135a;
  line-height: 0.9;
}
.page-home .splash--switcharoo .splash__message-info span,
.page-products .splash--switcharoo .splash__message-info span,
.page-order-products .splash--switcharoo .splash__message-info span {
  font-size: 0.8em;
}
.page-home .splash--switcharoo .splash__message,
.page-products .splash--switcharoo .splash__message,
.page-order-products .splash--switcharoo .splash__message {
  font-size: 0.4em;
  line-height: 1;
}
.page-home .splash--switcharoo-giga-249 .splash__heading:not(.splash__heading--secondary),
.page-products .splash--switcharoo-giga-249 .splash__heading:not(.splash__heading--secondary),
.page-order-products .splash--switcharoo-giga-249 .splash__heading:not(.splash__heading--secondary) {
  padding-top: 0.45em;
}
.page-home .splash--switcharoo-double.splash--white .splash__heading:not(.splash__heading--secondary),
.page-products .splash--switcharoo-double.splash--white .splash__heading:not(.splash__heading--secondary),
.page-order-products .splash--switcharoo-double.splash--white .splash__heading:not(.splash__heading--secondary) {
  padding-top: 0.45em;
}
.page-home .splash--switcharoo-double.splash--white .splash__heading--secondary-product,
.page-products .splash--switcharoo-double.splash--white .splash__heading--secondary-product,
.page-order-products .splash--switcharoo-double.splash--white .splash__heading--secondary-product {
  padding-top: 0.2em;
  padding-bottom: 0.1em;
  font-size: 0.55em;
}
.page-home .splash--switcharoo-double.splash--blue .splash__heading--secondary,
.page-products .splash--switcharoo-double.splash--blue .splash__heading--secondary,
.page-order-products .splash--switcharoo-double.splash--blue .splash__heading--secondary {
  padding: 0.2em 0 0.2em 0;
  font-size: 0.6em;
}
.page-home .splash--free-initiation.splash--free-initiation-surge,
.page-products .splash--free-initiation.splash--free-initiation-surge,
.page-order-products .splash--free-initiation.splash--free-initiation-surge {
  padding-top: 0.75em;
}
.page-home .splash--free-initiation.splash--free-initiation-surge .splash__heading,
.page-products .splash--free-initiation.splash--free-initiation-surge .splash__heading,
.page-order-products .splash--free-initiation.splash--free-initiation-surge .splash__heading {
  padding-bottom: 0;
  font-size: 1.1em;
}
.page-home .splash--free-initiation.splash--free-initiation-lastcall,
.page-products .splash--free-initiation.splash--free-initiation-lastcall,
.page-order-products .splash--free-initiation.splash--free-initiation-lastcall {
  padding-top: 0.35em;
}
.page-home .splash--free-initiation.splash--free-initiation-lastcall .splash__heading,
.page-products .splash--free-initiation.splash--free-initiation-lastcall .splash__heading,
.page-order-products .splash--free-initiation.splash--free-initiation-lastcall .splash__heading {
  padding-bottom: 0;
  font-size: 1.2em;
  line-height: 0.8em;
}
.page-home .splash--override-tariff-for-product.splash--free-initiation-surge,
.page-home .splash--override-adjustment-price-for-product.splash--free-initiation-surge,
.page-products .splash--override-tariff-for-product.splash--free-initiation-surge,
.page-products .splash--override-adjustment-price-for-product.splash--free-initiation-surge,
.page-order-products .splash--override-tariff-for-product.splash--free-initiation-surge,
.page-order-products .splash--override-adjustment-price-for-product.splash--free-initiation-surge {
  padding-top: 0.75em;
}
.page-home .splash--override-tariff-for-product.splash--free-initiation-surge .splash__heading,
.page-home .splash--override-adjustment-price-for-product.splash--free-initiation-surge .splash__heading,
.page-products .splash--override-tariff-for-product.splash--free-initiation-surge .splash__heading,
.page-products .splash--override-adjustment-price-for-product.splash--free-initiation-surge .splash__heading,
.page-order-products .splash--override-tariff-for-product.splash--free-initiation-surge .splash__heading,
.page-order-products .splash--override-adjustment-price-for-product.splash--free-initiation-surge .splash__heading {
  padding-top: 0.2em;
  padding-bottom: 0;
  font-size: 1.1em;
}
.page-home .splash--override-tariff-for-product.splash--free-initiation-lastcall,
.page-home .splash--override-adjustment-price-for-product.splash--free-initiation-lastcall,
.page-products .splash--override-tariff-for-product.splash--free-initiation-lastcall,
.page-products .splash--override-adjustment-price-for-product.splash--free-initiation-lastcall,
.page-order-products .splash--override-tariff-for-product.splash--free-initiation-lastcall,
.page-order-products .splash--override-adjustment-price-for-product.splash--free-initiation-lastcall {
  padding-top: 0.35em;
}
.page-home .splash--override-tariff-for-product.splash--free-initiation-lastcall .splash__heading,
.page-home .splash--override-adjustment-price-for-product.splash--free-initiation-lastcall .splash__heading,
.page-products .splash--override-tariff-for-product.splash--free-initiation-lastcall .splash__heading,
.page-products .splash--override-adjustment-price-for-product.splash--free-initiation-lastcall .splash__heading,
.page-order-products .splash--override-tariff-for-product.splash--free-initiation-lastcall .splash__heading,
.page-order-products .splash--override-adjustment-price-for-product.splash--free-initiation-lastcall .splash__heading {
  max-width: 4em;
  margin: auto;
  padding-top: 0.4em;
  padding-bottom: 0;
  font-size: 1em;
  line-height: 0.8em;
}
.page-home .splash--override-adjustment-price-for-product .splash__message-info,
.page-products .splash--override-adjustment-price-for-product .splash__message-info,
.page-order-products .splash--override-adjustment-price-for-product .splash__message-info {
  font-size: 0.6em;
}
.page-home .splash--override-adjustment-price-for-product .splash__message,
.page-products .splash--override-adjustment-price-for-product .splash__message,
.page-order-products .splash--override-adjustment-price-for-product .splash__message {
  font-size: 0.4em;
}

.page-home .splash--rebate,
.page-products .splash--rebate,
.page-order-products .splash--rebate {
  top: -3.75em;
  right: -4.5em;
  font-size: 1em;
  letter-spacing: 0;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: rotate(10deg);
  opacity: 1;
}
@media (max-width: 768px) {
  .page-home .splash--rebate,
.page-products .splash--rebate,
.page-order-products .splash--rebate {
    position: absolute;
    font-size: 0.75em;
  }
}
@media (max-width: 700px) {
  .page-home .splash--rebate,
.page-products .splash--rebate,
.page-order-products .splash--rebate {
    top: -5.25em;
    right: 50%;
    font-size: 0.7em;
    transform: rotate(0deg) translate(50%);
  }
}
@media (max-width: 520px) {
  .page-home .splash--rebate,
.page-products .splash--rebate,
.page-order-products .splash--rebate {
    top: -6em;
    font-size: 0.65em;
  }
}
.address-lookup-typing .page-home .splash--rebate,
.address-lookup-typing .page-products .splash--rebate,
.address-lookup-typing .page-order-products .splash--rebate {
  opacity: 0;
}

.page-home .splash--rebate .splash__heading,
.page-products .splash--rebate .splash__heading,
.page-order-products .splash--rebate .splash__heading {
  display: block;
  margin: 0;
  padding: 0.5em 0 0 0;
  font-size: 1em;
  hyphens: none;
}
.page-home .splash--rebate .splash__heading-middle,
.page-products .splash--rebate .splash__heading-middle,
.page-order-products .splash--rebate .splash__heading-middle {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 1em;
  hyphens: none;
}
.page-home .splash--rebate .splash__message,
.page-products .splash--rebate .splash__message,
.page-order-products .splash--rebate .splash__message {
  display: block;
  max-width: 8em;
  margin: auto;
  padding: 0;
  font-size: 0.8em;
}
.page-home .splash--rebate .splash__message-top,
.page-products .splash--rebate .splash__message-top,
.page-order-products .splash--rebate .splash__message-top {
  display: block;
  max-width: 8em;
  margin: auto;
  padding: 0.5em 0 0 0;
  font-size: 0.65em;
  letter-spacing: 0.04rem;
}
.page-home .splash--rebate .splash__message-secondary,
.page-products .splash--rebate .splash__message-secondary,
.page-order-products .splash--rebate .splash__message-secondary {
  display: block;
  max-width: 8em;
  margin: 0 auto;
  padding: 0.35em 0 0 0;
  font-size: 0.45em;
  line-height: 1;
}
.page-home .splash--rebate.splash--white .splash__message-secondary,
.page-products .splash--rebate.splash--white .splash__message-secondary,
.page-order-products .splash--rebate.splash--white .splash__message-secondary {
  color: #303a4b;
}
.page-home .splash--rebate.splash--white .splash__heading,
.page-home .splash--rebate.splash--white .splash__heading-middle,
.page-home .splash--rebate.splash--white .splash__message,
.page-home .splash--rebate.splash--white .splash__message-top,
.page-products .splash--rebate.splash--white .splash__heading,
.page-products .splash--rebate.splash--white .splash__heading-middle,
.page-products .splash--rebate.splash--white .splash__message,
.page-products .splash--rebate.splash--white .splash__message-top,
.page-order-products .splash--rebate.splash--white .splash__heading,
.page-order-products .splash--rebate.splash--white .splash__heading-middle,
.page-order-products .splash--rebate.splash--white .splash__message,
.page-order-products .splash--rebate.splash--white .splash__message-top {
  color: #ed135a;
}
.page-home .splash--rebate.black-friday,
.page-products .splash--rebate.black-friday,
.page-order-products .splash--rebate.black-friday {
  padding-top: 0.34em;
}
.page-home .splash--rebate.black-friday .splash__message,
.page-products .splash--rebate.black-friday .splash__message,
.page-order-products .splash--rebate.black-friday .splash__message {
  padding-top: 0em;
  font-size: 0.72em;
  line-height: 0.85em;
}
.page-home .splash--rebate.black-friday .splash__heading-middle,
.page-products .splash--rebate.black-friday .splash__heading-middle,
.page-order-products .splash--rebate.black-friday .splash__heading-middle {
  padding-top: 0.45em;
  font-size: 0.5em;
  color: #303a4b;
  line-height: 1.25em;
}
.page-home .splash--rebate.black-friday .splash__heading-middle span,
.page-products .splash--rebate.black-friday .splash__heading-middle span,
.page-order-products .splash--rebate.black-friday .splash__heading-middle span {
  display: inline-block;
  padding-bottom: 0.1em;
  font-size: 1.55em;
  color: #ed135a;
  vertical-align: middle;
}
.page-home .splash--rebate.black-friday .splash__message-secondary,
.page-products .splash--rebate.black-friday .splash__message-secondary,
.page-order-products .splash--rebate.black-friday .splash__message-secondary {
  padding-top: 0.55em;
  font-size: 0.4em;
}

.content--campaign-address {
  display: flex;
  position: relative;
  flex-flow: column;
  align-content: center;
  justify-content: center;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateY(12.5em);
}

.address-lookup-typing .content--campaign-address {
  transform: translateY(3.125em);
}

.product-link__rebate {
  /* common */
  /* top left*/
}
.product-link__rebate.ribbon {
  width: 5.5555555556em;
  height: 5.5555555556em;
  position: absolute;
  overflow: hidden;
}
.product-link__rebate.ribbon::before, .product-link__rebate.ribbon::after {
  display: block;
  position: absolute;
  z-index: -1;
  border: 5px solid #a70d3f;
  content: "";
}
.product-link__rebate.ribbon span {
  display: block;
  width: 8.3333333333em;
  position: absolute;
  padding: 0.7222222222em 0 0.6666666667em 1.1111111111em;
  background-color: #d61051;
  box-shadow: 0 0.2777777778em 0.5555555556em rgba(0, 0, 0, 0.1);
  font-size: 1.125em;
  color: white;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}
.product-link__rebate.ribbon-top-left {
  top: -0.2777777778em;
  left: -0.2777777778em;
}
.product-link__rebate.ribbon-top-left::before, .product-link__rebate.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.product-link__rebate.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.product-link__rebate.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.product-link__rebate.ribbon-top-left span {
  top: 0.8333333333em;
  right: -0.5555555556em;
  transform: rotate(-45deg);
}

.product-list-top-campaign-banner {
  font-size: 0.95em;
  max-width: 20em;
  margin: 0.5em auto 0.75em auto;
  background: #303a4b;
  padding: 0.55em 0.75em;
  border-radius: 0.25em;
  text-transform: uppercase;
  line-height: 0.95em;
}
.product-list-top-campaign-banner .line {
  display: block;
}
.product-list-top-campaign-banner .price {
  line-height: 2em;
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 0.3em;
}
.product-list-top-campaign-banner .price .number {
  font-size: 2em;
}
.product-list-top-campaign-banner .white {
  color: white;
}
.product-list-top-campaign-banner .red {
  color: #ed135a;
}

section.blue-campaign-section {
  background: #303a4b;
  --heading-font-size: 2.2em;
  --heading-prefix-font-size: .4em;
  --conditions-font-size: .7em;
  --badge-size: 6em;
  --badge-top: 1em;
  --badge-right: 1em;
  --speed-price-padding: .4em 0 0 0;
  --speed-price-font-size: 2.2em;
  --blue-price-font-size: 2em;
  --blue-price-display: block;
  --blue-price-line-height: 1;
}
section.blue-campaign-section .left, section.blue-campaign-section .left * {
  text-align: left;
}
section.blue-campaign-section .heading {
  font-size: var(--heading-font-size);
}
section.blue-campaign-section .heading .line {
  display: block;
}
section.blue-campaign-section .heading .prefix {
  font-size: var(--heading-prefix-font-size);
  color: white;
  padding-bottom: 0.2em;
}
section.blue-campaign-section .heading .blue {
  color: #a3d6df;
}
section.blue-campaign-section .heading .red {
  color: #ed135a;
}
section.blue-campaign-section .speed-price {
  font-size: var(--speed-price-font-size);
  padding: var(--speed-price-padding);
  text-transform: uppercase;
  font-weight: 500;
  color: white;
}
section.blue-campaign-section .red-price {
  display: var(--blue-price-display);
  color: #ed135a;
  line-height: var(--blue-price-line-height);
}
section.blue-campaign-section .red-price .number {
  font-size: var(--blue-price-font-size);
}
section.blue-campaign-section .conditions {
  width: 100%;
  font-size: var(--conditions-font-size);
  color: rgba(255, 255, 255, 0.75);
  color: rgba(255, 255, 255, 0.5);
}
section.blue-campaign-section.inverse-color {
  background: #a3d6df;
}
section.blue-campaign-section.inverse-color .speed-price,
section.blue-campaign-section.inverse-color .blue {
  color: #303a4b;
}

.red-price .number {
  position: relative;
}
.red-price .number .flag {
  position: absolute;
  top: 0.5em;
  right: 0;
  font-size: 0.2em;
  display: block;
  background: white;
  padding: 0.25em 0.5em 0.1em;
  border-radius: 0.2em;
  transform: translateX(70%);
  letter-spacing: var(--letter-spacing-small);
}

section.green-campaign-section,
section.teal-campaign-section {
  background: #42747d;
  text-align: center;
  padding: 1em 0;
}
section.green-campaign-section .heading,
section.teal-campaign-section .heading {
  font-size: 2.2em;
  font-weight: 400;
  line-height: 0.9;
  padding: 0;
}
section.green-campaign-section .speed,
section.teal-campaign-section .speed {
  color: #ffffff;
}
section.green-campaign-section .speed .sub,
section.teal-campaign-section .speed .sub {
  font-weight: 400;
}
section.green-campaign-section .price,
section.teal-campaign-section .price {
  line-height: 0.9;
  padding-bottom: 0.1em;
  position: relative;
}
section.green-campaign-section .price .md,
section.teal-campaign-section .price .md {
  font-size: 0.5em;
}
section.green-campaign-section .conditions,
section.teal-campaign-section .conditions {
  width: 100%;
  font-family: "Cervo", "League Gothic", "HelveticaNeue-CondensedBold", "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif;
  font-weight: 300;
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.9);
  padding: 1em 0 0 0;
}
section.green-campaign-section .insert-cta,
section.teal-campaign-section .insert-cta {
  font-size: 1.5em;
  margin-top: 1.5rem;
}
section.green-campaign-section.campaign-section--fibernet .speed,
section.teal-campaign-section.campaign-section--fibernet .speed {
  font-weight: 500;
  line-height: 0.6;
}
section.green-campaign-section.campaign-section--fibernet .speed.speed-500 .sub,
section.teal-campaign-section.campaign-section--fibernet .speed.speed-500 .sub {
  font-size: 0.69em;
}
section.green-campaign-section.campaign-section--fibernet .speed.speed-1000 .sub,
section.teal-campaign-section.campaign-section--fibernet .speed.speed-1000 .sub {
  font-size: 0.71em;
}
@media (min-width: 521px) {
  section.green-campaign-section.campaign-section--fibernet .speed,
section.teal-campaign-section.campaign-section--fibernet .speed {
    font-size: 5.2em;
    padding: 0.13em 0.5rem 0 0;
  }
  section.green-campaign-section.campaign-section--fibernet .price,
section.teal-campaign-section.campaign-section--fibernet .price {
    font-size: 8em;
  }
  section.green-campaign-section.campaign-section--fibernet .flex + .heading,
section.teal-campaign-section.campaign-section--fibernet .flex + .heading {
    padding-top: 0.35em;
  }
}
@media (max-width: 520px) {
  section.green-campaign-section.campaign-section--fibernet .heading,
section.teal-campaign-section.campaign-section--fibernet .heading {
    padding-bottom: 1rem;
  }
  section.green-campaign-section.campaign-section--fibernet .speed,
section.teal-campaign-section.campaign-section--fibernet .speed {
    font-size: 4.5em;
    padding: 0;
  }
  section.green-campaign-section.campaign-section--fibernet .price,
section.teal-campaign-section.campaign-section--fibernet .price {
    font-size: 12em;
  }
  section.green-campaign-section.campaign-section--fibernet .conditions,
section.teal-campaign-section.campaign-section--fibernet .conditions {
    padding-top: 0;
  }
  section.green-campaign-section.campaign-section--fibernet .flex + .heading,
section.teal-campaign-section.campaign-section--fibernet .flex + .heading {
    margin-top: -0.35em;
  }
}

.reseller .form-group {
  margin-bottom: 1em;
}
.reseller .link {
  margin-top: 1em;
  text-align: right;
}
.reseller .alert {
  text-align: center;
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 0.1875em;
}
.reseller .error {
  background: #d61051;
  color: white;
}
.reseller .success {
  background: #62a078;
}

@media print {
  html {
    font-size: 12pt;
  }

  *, *:before, *:after {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
    box-shadow: none !important;
  }

  a, a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="tel:"]:after,
a[href^="javascript:"]:after {
    content: "";
  }

  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  /* h5bp.com/t */
  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p, h2, h3, h4 {
    orphans: 3;
    widows: 3;
  }

  h2, h3, h4 {
    page-break-after: avoid;
  }
}