.form__not-sent-overlay,
.form__sent-overlay,
.form__sending-overlay {
    @include hidden;
    display: flex;
    position: absolute;
    top:     0;
    right:   0;
    bottom:  0;
    left:    0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form--sending {
    .form__sending-overlay {
        @include visible;
    }
}

.form__not-sent-overlay,
.form__sent-overlay {
    background: #ffffff;
}

.form--sent {
    .form__sent-overlay {
        @include visible;
    }

    .form__sending-overlay {
        @include hidden;
    }
}

.form--not-sent {
    .form__not-sent-overlay {
        @include visible;
    }

    .form__sending-overlay {
        @include hidden;
    }
}

$throbber-loader-color:           $hiper-gray !default;
$throbber-loader-highlight-color: $hiper-darkgray !default;
$throbber-loader-height:          1em !default;
$throbber-loader-segment-width:   1em  !default;
$throbber-loader-border-radius:   .5em  !default;
$throbber-loader-spacing:         .5em  !default;

@keyframes throbberLoader {
    0%  { background: $throbber-loader-color; }
    10% { background: $throbber-loader-highlight-color; }
    40% { background: $throbber-loader-color; }
}

.form__loader:not(:required) {
    animation:   throbberLoader 2000ms 300ms infinite ease-out;
    position:    relative;
    text-indent: -9999px;

    &, &:before, &:after {
        display:       inline-block;
        width:         $throbber-loader-segment-width;
        height:        $throbber-loader-height;
        border-radius: $throbber-loader-border-radius;
        background:    $throbber-loader-color;
    }

    &:before, &:after {
        position: absolute;
        top:      0;
        content:  '';
    }

    &:before {
        animation: throbberLoader 2000ms 150ms infinite ease-out;
        left:      -($throbber-loader-segment-width + $throbber-loader-spacing);
    }

    &:after {
        animation: throbberLoader 2000ms 450ms infinite ease-out;
        right:     -($throbber-loader-segment-width + $throbber-loader-spacing);
    }
}
