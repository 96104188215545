.page-order-products {
    background-color: $hiper-lightergray;
}

@media (min-width: 751px) {
    .ab-test-container-b .product-list-container.regional + .product-list-container.standard {
        //margin-top: var(--space-md);
        margin-top: 2em;
    }
}

.ab-test-container-b .product-list-container {
    /*
    centered x-scrolling with max-width container
    https://ryanmulligan.dev/blog/x-scrolling-centered-max-width-container/
    */

    @media (min-width: 751px) {
        --space:    1em;
        --space-md: calc(var(--space) * 2);

        &.regional {
            @media (min-width: 1634px) {
                --content-max-width: 77em;

                &.count-1 .product-list, &.count-2 .product-list {
                    justify-content: center;
                }

                &.count-3 .product-list, &.count-4 .product-list, &.count-5 .product-list {
                    justify-content: flex-end;

                    &::before {content: "";}
                }
            }

            @media (max-width: 1633px) {
                --content-max-width: 56em;

                &.count-1 .product-list {
                    justify-content: center;
                }

                &.count-2 .product-list, &.count-3 .product-list, &.count-4 .product-list, &.count-5 .product-list {
                    justify-content: flex-end;

                    &::before {content: "";}
                }
            }
        }

        &.standard {
            @media (min-width: 1756px) {
                --content-max-width: 83em;

                &.count-1 .product-list, &.count-2 .product-list, &.count-3 .product-list, &.count-4 .product-list {
                    justify-content: center;
                }

                &.count-5 .product-list {
                    justify-content: flex-end;

                    &::before {content: "";}
                }
            }

            @media (min-width: 1271px) and (max-width: 1755px) {
                --content-max-width: 62em;

                &.count-1 .product-list, &.count-2 .product-list, &.count-3 .product-list {
                    justify-content: center;
                }

                &.count-4 .product-list, &.count-5 .product-list {
                    justify-content: flex-end;

                    &::before {content: "";}
                }
            }

            @media (max-width: 1270px) {
                --content-max-width: 41em;

                &.count-1 .product-list, &.count-2 .product-list {
                    justify-content: center;
                }

                &.count-3 .product-list, &.count-4 .product-list, &.count-5 .product-list {
                    justify-content: flex-end;

                    &::before {content: "";}
                }
            }
        }

        display:               grid;
        grid-template-columns: [full-start] 1fr
                               [content-start]
                               min(var(--content-max-width), 100% - var(--space-md) * 2)
                               [content-end]
                               1fr [full-end];

        & > * {grid-column: content;}

        .product-list-wrapper {
            grid-column:           full;
            display:               grid;
            grid-template-columns: inherit;
            overflow-x:            auto;
            overflow-y:            hidden;
            //overscroll-behavior-x: contain;
            //scroll-snap-type:      x proximity;

            --size:          .5em;
            --border-radius: calc(var(--size) / 2);

            &::-webkit-scrollbar,
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-track-piece {background-color: transparent;}
            &::-webkit-scrollbar             {height:           var(--size);}
            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-track-piece {border-radius: var(--border-radius);}

            &::-webkit-scrollbar-thumb       {
                border-radius:    var(--border-radius);
                background-color: rgba(#000000, .1);

                &:hover {background-color: rgba(#000000, .2);}
            }
        }

        .product-list {
            grid-column:    content;
            display:        flex;
            flex-direction: row-reverse;
            align-items:    flex-start;
            gap:            var(--space);

            &::before {
                align-self:         stretch;
                padding-inline-end: max(
                    var(--space),
                    (100vw - var(--content-max-width)) / 2 - var(--space)
                );
            }
        }
    }

    @media (max-width: 750px) {
        &.regional {
            margin-bottom: 1.5em;
        }
    }
}

.product-list {
    .ab-test-container-a & {
        --padding:              .5rem;
        --border-radius:        .5rem;
        --font-size-heading:    1.5em;
        --font-size-speed:      2.75em;
        --font-size-regulatory: 1em;
        --font-size-price:      4.5em;

        display:                flex;
        justify-content:        center;
        flex-direction:         row-reverse;
        flex-wrap:              wrap-reverse;
        gap:                    1em 2em;
        padding-bottom:         1em;

        @media (max-width: $desktop) {
            --font-size-speed:   2.25em;
            --font-size-price:   3.75em;
        }
        @media (max-width: $tablet) {
            --font-size-speed:   2em;
            --font-size-price:   4em;
        }
        @media (max-width: $phone) {
            flex-direction:      column-reverse;
            align-items:         center;
            gap:                 1.5em 2em;

            --font-size-speed:   2.75em;
            --font-size-price:   5em;
        }
    }

    .ab-test-container-b & {
        --padding:              1rem;
        --border-radius:        1rem;
        --font-size-heading:    1.6em;
        --font-size-speed:      2.75em;
        --font-size-regulatory: 1em;
        --font-size-price:      5em;

        @media (max-width: $desktop) {
            --font-size-speed:   2.25em;
            --font-size-price:   3.75em;
        }
        @media (max-width: 750px) {
            display:             flex;
            flex-direction:      column-reverse;
            justify-content:     flex-start;
            align-items:         center;
            gap:                 1.5em;
            --font-size-speed:   2em;
            --font-size-price:   4em;
        }
        @media (max-width: $phone) {
            --font-size-speed:   2.75em;
            --font-size-price:   5em;
        }
    }

    .discount {
        color: $hiper-green;
    }
}

.product-list-item {
    margin:      0;
    padding:     0;
    flex-shrink: 0;

    .ab-test-container-b .standard & {
        width: 20em;
    }
}

.product-list-dsl-speed {
    .ab-test-container-a & {
        display:    block;
        width:      100%;
        position:   static;
        margin:     0;
        font-size:  1em;
        text-align: center;

        .loader-label {
            margin:         0;
            padding:        0;
            letter-spacing: 0;
        }

        .form__loader {
            display:   inline-block;
            margin:    0 0 0 2em;
            padding:   0;
            font-size: .5em;
        }
    }

    .ab-test-container-b & {
        font-size: .5em;

        .loader-label {
            margin:         0;
            padding:        0;
            letter-spacing: 0;
        }

        .form__loader {
            display:   inline-block;
            margin:    0 0 0 2em;
            padding:   0;
            font-size: .5em;
        }
    }
}

.product-list-pre-link-wrap {
    position:   relative;
    text-align: center;

    .pre-product {
        max-width:        12em;
        border-radius:    .5rem .5rem 0 0;
        font-size:        .9em;
        text-align:       center;
        color:            #ffffff;
    }

    .ab-test-container-a & {
        @media (min-width: 521px) {
            min-height: 1.6em;
        }

        .pre-product {
            padding:          .4em .8em;
            background-color: $hiper-blue;
            font-family:      $font-cervo;
            font-weight:      300;
            line-height:      .9em;
            text-transform:   uppercase;
            letter-spacing:   .06em;
        }
    }

    .ab-test-container-b & {
        line-height: 1.2;

        @media (min-width: 751px) {
            bottom:     -1px;
            min-height: 1.6em;
        }

        .pre-product {
            display:          block;
            margin:           0 auto;
            padding:          .25em .8em;
            width:            fit-content;
            background-color: $hiper-teal;
            font-size:        .9em;
        }
    }
}

.product-list-pre-link {
    display:          block;
    width:            fit-content;
    width:            -moz-fit-content;
    margin:           0 auto;
    padding:          .4em .8em;
    border-radius:    .5em .5em 0 0;
    background-color: $hiper-blue;
    font-size:        .8em;
    text-align:       center;
    color:            #ffffff;
}

.product-list-post-link {
    margin-top:   1em;
    margin-right: calc(#{em(15px)} + var(--padding));
    text-align:   right;

    .button {
        font-size: 1em;
    }
}

.product-list-link {
    display:       block;
    border-bottom: 0;
    background:    #ffffff;
    box-shadow:    var(--box-shadow);
    border-radius: var(--border-radius);

    &:hover {
        box-shadow: var(--box-shadow-hover-inverse);
        color:      inherit;
    }
}

.product-list-card {
    margin:  0;
    padding: 0;

    .ab-test-container-a & {
        width: 17em;
    }

    .ab-test-container-b & {
        overflow: hidden;
    }

    .ab-test-container-a .regional & {
        border-radius: 0 0 var(--border-radius) var(--border-radius);
    }

    .ab-test-container-b .standard & {
        width: 100%;
    }

    .ab-test-container-b .regional & {
        width:         20em;
        border-radius: var(--border-radius);
    }
}

.product-list-heading {
    font-weight: 300;
    font-size:   var(--font-size-heading);

    .ab-test-container-a & {
        padding:       var(--padding);
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        color:         #ffffff;
        text-align:    left;
    }

    .ab-test-container-b & {
        background-color: transparent;
    }

    .ab-test-container-b .standard & {
        padding:          1rem 0 0;
        font-size:        1.6em;
    }
}

.product-list-middle {
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     center;
    color:           $hiper-blue;

    .product-list-dsl-speed,
    .speed,
    .before-price,
    .price {
        font-family:    $font-cervo;
        text-transform: uppercase;
    }

    .speed,
    .price {
        letter-spacing: -.075rem;

        .ab-test-container-a & {
            font-weight: 400;
        }

        .ab-test-container-b & {
            font-weight: 300;

            &.discount {font-weight: 400;}
        }
    }

    .speed {font-size: var(--font-size-speed);}

    .before-price {
        margin-right: .5rem;
        font-size:    2.2em;
        font-weight:  300;
    }

    .price {
        font-size: var(--font-size-price);

        @media (max-width: $tablet) {
            line-height: .8em;
        }
    }

    .speed-star {color: rgba($hiper-blue, .75);}

    .mbit,
    .md   {font-size:   .8em;}
    .mbit {margin-left: .25rem;}
    .md   {margin-left: -.5rem;}

    .ab-test-container-a & {
        @media (min-width: 701px) {
            gap:     .25em;
            padding: calc(var(--padding) * 2) var(--padding);
        }

        @media (max-width: 700px) {
            gap:     .5em;
            padding: calc(var(--padding) * 1.5) var(--padding);
        }

        .product-list-speed {
            height: calc(calc(var(--font-size-speed) / 5) * 4);
        }

        .product-list-price {
            height: calc(calc(var(--font-size-price) / 5) * 4);
        }

        .post-price {
            margin-bottom: -.5em;
            padding-top:   .5em;
            font-size:     .9em;
            line-height:   1.2;
        }
    }

    .ab-test-container-b & {
        flex-direction:  row;
        align-items:     center;
        justify-content: flex-start;
        gap:             1em;
        padding:         var(--padding);
        min-height:      8em;
        overflow:        hidden;

        .product-list-price-container {
            z-index: 1;

            .pre-price,
            .post-price,
            .min-price {
                line-height: 1.25;
            }

            .pre-price,
            .post-price {
                font-size: .9em;
                padding:   0;
            }

            .post-price {padding-top: .5em;}
            .min-price  {font-size:   .75em}
        }

        .product-list-price {
            justify-content: flex-start;
            margin-bottom:   -.25em;
            text-align:      left;
        }

        .product-list-image {
            position:  relative;
            flex-grow: 1;
            height:    8em;

            img {
                position:   absolute;
                top:        50%;
                left:       50%;
                transform:  translate(-50%, -50%);
                max-width:  unset;
                height:     100%;
            }

            .mbb-stamp {
                position:         absolute;
                top:              50%;
                right:            25%;
                transform:        translate(50%, -50%) rotate(7.5deg);
                display:          flex;
                justify-content:  center;
                align-items:      center;
                width:            2em;
                height:           2em;
                border-radius:    1em;
                font-size:        1.6em;
                background-color: $hiper-blue;
                box-shadow:       var(--box-shadow);

                span {
                    @include font-weight-variable(600);
                    color:   #ffffff;
                }
            }
        }
    }

    .ab-test-container-b .fiber & .product-list-image img {
        height: 8em;
    }

    .ab-test-container-b .mbb & .product-list-image img,
    .ab-test-container-b .coax & .product-list-image img {
        height: 10em;
    }

    .ab-test-container-b .dsl & {
        justify-content: center;
    }
}

.product-list-card hr {
    margin:    0 auto;
    max-width: calc(100% - calc(var(--padding) * 2));
}

.product-list-tech {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    flex-wrap:       wrap;
    gap:             0 .5em;
    padding:         .25em var(--padding) .1em;
    width:           100%;

    .product-list-speed {
        display:         flex;
        justify-content: flex-start;
        align-items:     baseline;
        gap:             .1em;
        font-size:       1.5em;

        .speed {
            font-family: $font-cervo;
            font-weight: 300;
        }

        .mbit {font-size: .45em;}
    }

    .product-list-network {
        font-size: .7em;

        &.mbb {
            @include   font-weight-variable(500);
            font-size: 1em;
        }
    }
}

.product-list-speed,
.product-list-price,
.product-list-speed-range,
.product-list-price-range {
    line-height: 1;
}

.product-list-speed,
.product-list-price {
    display:         flex;
    justify-content: center;
    align-items:     baseline;

    & > span {line-height: 1;}
}

.product-list-speed-regulatory-range {
    font-size: var(--font-size-regulatory);
}

.product-list-speed,
.product-list-speed-regulatory-range,
.product-list-price {
    text-align: center;
}

.product-list-speed-range,
.product-list-price-range {
    display:         flex;
    align-items:     baseline;
    justify-content: space-between;

    & > span:first-of-type {
        font-size:    .8em;
        margin-right: .25rem;
    }
}

.product-list-footer {
    padding:    var(--padding);
    font-size:  .8em;
    text-align: left;

    p {
        padding-bottom: 0;
        line-height:    1.4;
    }

    strong,
    .discount {@include font-weight-variable(500);}
    small     {color:   $hiper-darkgray;}
}

.product-list-button {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    gap:             .5em;
    width:           100%;
    border-radius:   0 0 var(--border-radius) var(--border-radius);
    text-align:      left;
    color:           #ffffff;

    .icon {
        display:   block;
        width:     1.5em;
        height:    1.5em;
        color:     transparent;
        fill:      #ffffff;
    }

    .ab-test-container-a & {
        padding:   calc(var(--padding) / 2) var(--padding);
        font-size: 1.5em;
    }

    .ab-test-container-a .product-list-link:hover & {
        background-color: $hiper-green;
    }

    .ab-test-container-b & {
        @include          font-weight-variable(500);
        padding:          .6em 1em;
        background-color: $hiper-teal;
        font-family:      $font-montserrat;
        font-size:        1em;
        text-transform:   none;
        letter-spacing:   normal;
    }

    .ab-test-container-b .product-list-link:hover & {
        background-color: $hiper-darkteal;
    }
}

.product-list-disclaimer {
    p {
        padding:     0;
        font-size:   .8em;
        line-height: 1.25;
        text-align:  center;

        &:first-child {padding-top:    .75em;}
        &.price-steps {text-transform: lowercase;}

        .discount {@include font-weight-variable(500);}
    }

    @media (min-width: 751px) {
        .ab-test-container-b & {
            min-height: 1em;
        }
    }
}

.product-list-splash {
    margin-top:    calc(-1 * var(--padding));
    padding:       calc(2.5 * var(--padding)) var(--padding) var(--padding);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    box-shadow:    inset 0 1.25em .5em -.5em rgba(#000000, .3);
    background:    $hiper-red;
    color:         #ffffff;
    text-align:    center;
    width:         17em;

    .heading {font-size: 1.2em;}

    p {
        @include     font-weight-variable(300);
        font-size:   .8em;
        line-height: 1.4;
    }
}

.product-list-badge {
    $fs:            12px;
    $sq:            56px;
    display:        block;
    width:          em($sq, $fs);
    height:         em($sq, $fs);
    position:       absolute;
    top:            em(-$sq / 2, $fs);
    right:          em(-$sq / 2, $fs);
    margin:         0;
    padding:        em(10px, $fs) 0 0 0;
    border-radius:  em($sq, $fs);
    background:     $hiper-slate;
    font-family:    $font-cervo;
    font-size:      em($fs);
    color:          #ffffff;
    line-height:    em($fs, $fs);
    text-align:     center;
    letter-spacing: .01rem;
    text-transform: uppercase;
    transform:      rotate(10deg);

    &.green {
        background: $hiper-green;
    }

    &.fiber-first {
        padding-top: 1.35em;
        background:  $hiper-red;
    }

    &.free-technician {
        padding-top: 1em;
        background:  $hiper-blue;
    }
}

.product-list-usp {
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     flex-start;
    gap:             .5em;
    margin:          0 auto;
    padding:         var(--padding);
    width:           fit-content;
}

.product-list-usp-item {
    display:         flex;
    justify-content: center;
    align-items:     center;
    gap:             .25em;
    margin:          0;
    padding:         0;

    .icon {
        display: block;
        width:   1.5em;
        height:  1.5em;
        color:   transparent;
        fill:    $hiper-blue;
    }

    .text {
        font-size: .9em;
    }
}

.product-list-disclaimers {
    padding-top: 0;
    max-width:   56em;
    text-align:  center;

    .heading {
        font-size:      1.5em;
        font-weight:    300;
        letter-spacing: var(--letter-spacing-small);
    }

    p {font-size: .7em;}
}

.product-list-disclaimers,
.product-list {
    .speed-star {
        @include     font-weight-variable(400);
        font-family: $font-montserrat;
        color:       inherit;
    }
}

.wl-middle {
    gap:           0;
    border-bottom: none;
    background:    #ffffff;
    font-family:   $font-montserrat;

    .wl-middle-top {
        padding-bottom: 0;

        @media (min-width: 701px) {
            font-size: 1.4em;
        }
    }

    .wl-middle-bottom {
        font-size: .8em;
    }
}

// Region: Regional Campaign
.regional .product-list-item {
    width: 38em;
}

.regional-campaign-container {
    display:         flex;
    justify-content: space-between;
    align-items:     flex-start;
    gap:             1em 2em;
    padding:         var(--padding);

    .product-list-card {
        transition: box-shadow .15s $ease-in-out-quad;

        .ab-test-container-a & {
            box-shadow: var(--box-shadow-small);
        }

        .ab-test-container-b & {
            box-shadow:
                0 0 0 4px $hiper-teal,
                var(--box-shadow);
        }
    }
}

.regional-campaign-text {
    margin:    0;
    padding:   0;
    max-width: 17em;

    h1, h2 {
        padding-bottom: 0;
        font-size:      2em;
        text-align:     left;
    }

    h1 {
        border-bottom: 1px solid $hiper-darkgray;
        margin-bottom: .5em;

        .pre-save {
            position:       absolute;
            transform:      translateY(1.2em);
            font-family:    $font-montserrat;
            font-size:      .29em;
            text-transform: none;
        }

        .save   {font-size: 1.5em;}

        .amount {
            font-size:   2.5em;
            font-weight: 400;
            color:       $hiper-red;
        }
    }

    h2 {
        margin-bottom: .5rem;

        .icon {
            display:   inline-block;
            width:     .5em;
            height:    .5em;
            transform: scale(calc(1.25 * 2)) translate(.05em,-.05em);
            color:     transparent;
            fill:      $hiper-blue;

            svg {display: block}
        }
    }

    p {
        position:    relative;
        padding:     0 0 .5rem 1.5rem;
        line-height: 1.2;

        &.disclaimer {
            font-size: .7em;
            color:     $hiper-darkgray;
        }

        &:last-child {padding-bottom: 0;}

        .icon {
            width:     1.5em;
            height:    1.5em;
            position:  absolute;
            top:       0;
            left:      -.25em;
            transform: translateY(-0.15em);
            color:     transparent;
            fill:      $hiper-blue;
        }

        .after-price {font-size: .9em;}
    }

    .ab-test-container-b & {
        h2 {
            font-size:   2.4em;
            line-height: 1;

            .small {font-size: .75em;}
        }
    }
}

// Region: Media queries

.ab-test-container-a {
    @media (min-width: 701px) {
        .regional .product-list-link {
            border-radius: 1em 1em var(--border-radius) var(--border-radius);
        }

        .regional-campaign-container {
            padding: 1em;
        }

        .regional-campaign-text h2 {
            margin-bottom: 1rem;
        }
    }

    @media (max-width: 700px) {
        .regional {
            .product-list-item {
                max-width: 17em;
            }

            .product-list-link {
                border-radius: var(--border-radius);
            }

            .product-list-card {
                max-width: 17em;
                width:     100%;
            }
        }

        .regional-campaign-container {
            flex-direction: column;
            padding:        .5em 1em 1em;
        }

        .regional-campaign-text {
            h1 {text-align: center;}

            h2 {
                margin-bottom: .5rem;

                .icon {
                    transform: scale(calc(1 * 2)) translateY(-.05em) rotate(90deg);
                }
            }

            .disclaimer {padding-left: 0;}
        }
    }

    @media (max-width: $phone) {
        .regional-campaign-container {padding: .5em;}
    }
}

.ab-test-container-b {
    @media (max-width: 750px) {
        .chosen-address {
            font-size:   .9em;
            line-height: 1.4;
        }

        .product-list {
            padding-left:  .5em;
            padding-right: .5em;
        }

        .product-list-item {
            width:     100%;
            max-width: 20em;
        }

        .product-list-card,
        .regional .product-list-card {
            width: 100%;
        }

        .product-list-middle .product-list-image .mbb-stamp {
            right:     -.5rem;
            transform: translateY(-50%) rotate(7.5deg);
            font-size: 2em;
        }

        .mbb .product-list-middle .product-list-image,
        .coax .product-list-middle .product-list-image {
            height: 10em;

            img {height: 11em;}
        }

        .regional-campaign-container {
            flex-direction: column;
            align-items:    center;
            padding:        calc(var(--padding) / 2);
        }

        .regional-campaign-text h2 {
            text-align: center;
        }
    }
}

.biper-visible.public-hidden {
    .product-list-pre-link-wrap,
    .product-list-heading,
    .product-list-button {opacity: .75;}

    .product-list-image {mix-blend-mode: multiply;}

    .product-list-link,
    .table--plain {
        background-image: repeating-linear-gradient(
                              -45deg,
                              #ffffff, #ffffff 1.5em,
                              $hiper-candyfloss 1.5em, $hiper-candyfloss 3em
                          );
    }
}

span.affiliate-coupon {
    padding:          0 .25em;
    border-radius:    em(2px);
    background-color: $hiper-red;
    color:            #ffffff;
    letter-spacing:   .07em;
    font-weight:      400;
    white-space:      nowrap;
}

.rentdesk-logo {
    width:         25em;
    margin-bottom: .2em;
}

.rentdesk-heading {
    font-family:    'Montserrat', sans-serif;
    font-weight:    300;
    font-size:      .5em;
    text-transform: none;
    margin-bottom:  1em;
    display:        block;
    line-height:    1.7em;
}

.affiliate-coupon-rentdesk {
    padding:          .3em;
    border-radius:    .5em;
    background-color: $hiper-red;
    color:            #ffffff;
    letter-spacing:   .07em;
    font-weight:      400;
}
