.help-search,
.contact-search {
    border-radius: 1em;
    box-shadow:    var(--box-shadow);

    .chatbot-animate {
        transform: translateY(4px);
        z-index:   -1;

        @media (max-width: $phone) {
            width:  40%;
            height: auto;
        }

        @media (max-width: 375px) {
            display: none;
        }
    }

    .text-wrap {
        align-self: center;

        @media (max-width: $phone) {
            padding-bottom: .5em;
        }
    }

    p .hibot-red {
        @include font-weight-variable(600);
        font-size: 1.2em;
        color:     $hiper-red;
    }
}

#help-embedded-search,
#contact-embedded-search {
    position:   relative;
    z-index:    $z-index-address-picker;
    min-height: 45px;

    &::before,
    &::after {
        content:        '';
        position:       absolute;
        top:            0;
        left:           0;
        width:          100%;
        height:         45px;
        pointer-events: none;
    }

    &::before {
        border-radius: 10px;
        box-shadow:    var(--box-shadow);
    }

    &::after {
        z-index:       100;
        border-radius: 7px;
        box-shadow:    inset 0 0 0 1px $hiper-red,
                             0 0 0 1px $hiper-red;
    }
}

#chatbot-container {
    display:    none;
    position:   fixed;
    text-align: center;
    z-index:    6;

    &.chatbot-show {display: block;}

    .close-chatbot-icon {
        position:         absolute;
        display:          block;
        padding:          0;
        background-color: transparent;
        border:           none;
        cursor:           pointer;
        font-size:        1em;

        .icon {color: transparent;}
    }

    .chatbot {
        position:       absolute;
        display:        block;
        pointer-events: none;
    }

    .chatbot-text {
        background-color: $hiper-blue;
        box-shadow:       var(--box-shadow);

        p {
            color:      #ffffff;
            text-align: left;
        }
    }

    i {position: absolute;}

    @media (min-width: 521px) {
        right:      calc(40px + 62px + .75em - .1em + .25em);
        bottom:     calc(40px + calc(62px / 2));
        transform:  translateY(50%);
        transition: bottom .2s $ease-in-out-cubic;

        &.chatbot-temp-hide    {bottom:  -10em;}
        .hide-on-large-devices {display: none;}

        .close-chatbot-icon {
            top:    0;
            right:  0;
            width:  1.5em;
            height: 1.5em;
        }

        .chatbot {
            top:       0;
            left:      50%;
            transform: translate(-50%, -100%);
            max-width: 4em;
        }

        .chatbot-text {
            border-radius: .75em;
            padding:       .5em 1em .5em .75em;

            p {
                font-size:   .5em;
                line-height: 1.4;
            }

            strong {font-size: 1.2em;}
        }

        i {
            top:           50%;
            right:         .1em;
            transform:     translate(100%, -50%);
            display:       block;
            width:         1.5em;
            height:        1em;
            border-left:   .75em solid $hiper-blue;
            border-top:    .5em solid transparent;
            border-bottom: .5em solid transparent;
            border-right:  0;
        }
    }

    @media (max-width: $phone) {
        right:            calc(15px + calc(56px / 2));
        bottom:           calc(40px + .1em);
        width:            fit-content;
        transform-origin: right;
        opacity:          1;
        transition:       transform .3s $ease-in-out-cubic,
                          opacity .3s $ease-in-out-cubic;

        &.chatbot-temp-hide {
            opacity:   0;
            transform: scaleX(0);
        }

        .hide-on-small-devices {display: none;}

        &, .chatbot-text {
            height: calc(56px - .2em);
        }

        .close-chatbot-icon {
            top:       50%;
            left:      .5em;
            transform: translateY(-50%);
            width:     2em;
            height:    2em;
        }

        .chatbot {
            bottom: 0;
            right:  calc(56px / 2 - .8em);
            width:  3em;
        }

        .chatbot-text {
            display:          flex;
            justify-content:  center;
            align-items:      center;
            border-radius:    3em 0 0 3em;
            background-color: lighten($hiper-blue, 5%);
            background-image: radial-gradient(
                circle farthest-side at right center,
                darken($hiper-blue, 10%) 0%,
                lighten($hiper-blue, 5%) calc(56px / 2 + .75em)
            );
            padding:
                0
                calc(.2em + 2.5em + calc(56px / 2))
                0
                calc(.25em + 2.5em)
            ;

            p {
                padding-bottom: .2em;
                font-size:      .9em;
                line-height:    1.2;
            }

            .small {font-size: .8em;}
        }
    }
}

.search-input-container {
    position: relative;

    .search-input {
        border:     none;
        box-shadow: 0 0 0 2px $hiper-red,
                    var(--box-shadow);

        &, &::placeholder {
            text-align: left;
        }

        &::placeholder {
            color: $hiper-teal;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        @include font-weight-variable(600);
        font-family:    $font-montserrat;
        padding:        0;
        text-align:     left;
        text-transform: none;
        line-height:    1.2;
        letter-spacing: normal;
    }

    .form__loader {
        display:   inline-block;
        margin:    0 0 0 2em;
        padding:   0;
        font-size: .5em;
    }

    .post-input-container {
        position:         absolute;
        top:              calc(100% + .25em);
        left:             0;
        right:            0;
        border-radius:    .5em;
        overflow-y:       auto;
        background-color: #ffffff;
        box-shadow:       var(--box-shadow);
    }

    .top-questions,
    .search-summary,
    .relevant-articles {
        padding: .5em;
    }

    .top-questions li,
    .relevant-articles a {
        display:         flex;
        justify-content: space-between;
        align-items:     center;
        gap:             .5em;
        padding:         .25em 0;
        border:          none;
        border-top:      1px solid $hiper-gray;
        color:           $hiper-blue;
        line-height:     1.4;
        transition:      color .2s $ease-in-out-cubic;

        &:hover {
            color: $hiper-red;

            .icon {
                transform: translateX(.2em);
            }
        }

        .icon {
            flex-shrink: 0;
            display:     block;
            width:       1em;
            height:      1em;
            color:       transparent;
            fill:        $hiper-blue;
            transform:   translateX(0);
            transition:  transform .2s $ease-in-out-cubic;

            svg {
                transform: scale(1.5);
            }
        }
    }

    .top-questions {
        li {
            cursor: pointer;

            &:first-child {
                border-top: none;
            }
        }
    }

    .search-summary,
    .relevant-articles {
        & > h3 {
            padding-bottom: .25em;
        }
    }

    .search-summary {
        .summary {
            padding:          .25em .5em;
            border-radius:    .5em;
            background-color: $hiper-lightgray;
            font-size:        .9em;

            h1, h2, h3, h4, h5, h6 {
                padding-top: .5em;

                & + p, & + ul, & + a {
                    padding-top: .35em;
                }

                &:first-child {padding-top: 0;}
            }

            h1 {
                font-size: 1.4em;
            }

            h2 {
                font-size: 1.2em;
            }

            h3, h4, h5, h6 {
                font-size: 1.1em;
            }

            .reference {
                @include font-weight-variable(600);
                border:         none;
                color:          $hiper-red;
                font-size:      .6em;
                vertical-align: super;
            }

            ul {
                padding-bottom: .7em;

                &:last-child {padding-bottom: 0;}

                li {
                    position:       relative;
                    padding-left:   1.25em;
                    padding-bottom: .35em;

                    &::before {
                        @include font-weight-variable(900);
                        content:  '\2022';
                        position: absolute;
                        left:     .25em;
                        color:    $hiper-red;
                    }

                    &:last-child {padding-bottom: 0;}
                }
            }
        }
    }

    .relevant-articles {
        padding-bottom: 0;

        li {
            padding: 0;

            &:last-child a {
                border-bottom: 1px solid $hiper-gray;
            }
        }
    }

    .contact-options {
        padding: .5em;

        h3 {font-size: 1.2em;}

        p {font-size: .9em}

        .phone-number {
            @include font-weight-variable(600);
            display:          block;
            margin:           0 auto;
            padding:          .25em 1em;
            width:            fit-content;
            border-radius:    2em;
            border:           none;
            background-color: $hiper-red;
            color:            #ffffff;
            font-size:        1.2em;
        }

        button {
            display:          block;
            margin:           0 auto;
            padding:          .5em 1em;
            border:           1px solid $hiper-gray;
            border-radius:    .5em;
            background-color: #ffffff;
            color:            $hiper-blue;
            font-family:      $font-montserrat;
            font-size:        1em;
            cursor:           pointer;

            &.toggle-contact-button {
                @include font-weight-variable(500);
                display:          flex;
                justify-content:  center;
                align-items:      center;
                gap:              .5em;
                border:           none;
                background-color: $hiper-red;
                color:            #ffffff;
                transition:       background-color .2s $ease-in-out-cubic;

                &:hover {
                    background-color: $hiper-darkred;
                }

                &.back {
                    flex-direction: row-reverse;
                    margin-top:     1em;

                    .icon {
                        transform: rotate(180deg);
                    }
                }

                .icon {
                    flex-shrink: 0;
                    display:     block;
                    width:       1em;
                    height:      1em;
                    color:       transparent;
                    fill:        #ffffff;
                    transition:  fill .2s $ease-in-out-cubic;

                    svg {transform: scale(1.5);}
                }
            }

            &.contact-button {
                width:         100%;
                margin-bottom: .25em;
                padding:       .5em;
                text-align:    left;

                small {font-size: .8em;}
            }
        }
    }
}

@media (min-width: 521px) {
    .search-input-container {
        .post-input-container {
            max-height: 65vh;
        }

        .top-questions,
        .search-summary,
        .relevant-articles {
            & > h3 {
                font-size: 1.2em;
            }
        }
    }
}

@media (max-width: $phone) {
    .search-input-container {
        .post-input-container {
            max-height: 55vh;
        }

        .top-questions,
        .search-summary,
        .relevant-articles {
            & > h3 {
                font-size: 1em;
            }
        }
    }
}
