[class^="page-selfcare"] {
    button {
        &:not(.button) {font-family: $font-montserrat;}

        &.button {
            padding:   .2em 1em;
            font-size: 1.5em;
        }
    }
}

#react-root,
#react-root > .selfcare-loader {
    min-height: 50vh;
}

// Region: Nav
.selfcare-header-wrapper,
.selfcare-menu-button-container {
    background-color: $hiper-darkteal;
}

.selfcare-header-wrapper {
    position: fixed;
    top:      $height-header;
    z-index:  $z-index-selfcare-menu;
    width:    100%;
}

.selfcare-header {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    margin:          0 auto;
    max-width:       60em;
    height:          100%;
}

.selfcare-header-address {
    $color:        #ffffff;

    position:      relative;
    z-index:       $z-index-selfcare-menu + 1;
    padding-right: .5em;
    width:         fit-content;
    height:        100%;
    border-right:  1px solid rgba($color, .25);

    .text,
    &.single {
        line-height: 1.2;
        color:       $color;

        span {
            display: block;

            &:not(:first-child) {
                font-size: .8em;
                color:     rgba($color, .8);
            }
        }
    }

    &.multiple > .text,
    &.single {
        position:      relative;
        padding-right: .5em;
        max-width:     100%;
        overflow:      hidden;
        white-space:   nowrap;

        &::after {
            content:    '';
            position:   absolute;
            top:        0;
            bottom:     0;
            right:      0;
            width:      .5em;
            height:     100%;
            background: linear-gradient(90deg, rgba($hiper-darkteal, 0), rgba($hiper-darkteal, 1));
        }

        @media (max-width: 400px)        {font-size: .9em;}
        @media (max-width: $phone-small) {font-size: .8em;}
    }

    &.multiple {
        display:               grid;
        grid-template-columns: auto 17px;
        align-items:           center;
        justify-items:         start;
        cursor:                pointer;

        .dropdown-icon {
            display:    block;
            position:   relative;
            width:      17px;
            height:     9px;
            transition: transform .2s $ease-in-out-quad;

            &::before, &::after {
                content:          '';
                pointer-events:   none;
                display:          block;
                position:         absolute;
                top:              4px;
                left:             0;
                width:            10px;
                height:           1px;
                border-radius:    .5px;
                background-color: $color;
            }

            &::before {transform: translateX(0)   rotate(45deg);}
            &::after  {transform: translateX(7px) rotate(-45deg);}
        }

        .dropdown {
            @include          hidden;
            position:         absolute;
            top:              100%;
            min-width:        100%;
            width:            max-content;
            background-color: $hiper-darkteal;
            box-shadow:       var(--box-shadow);

            ul {
                max-height: 0;

                &::-webkit-scrollbar,
                &::-webkit-scrollbar-track,
                &::-webkit-scrollbar-track-piece {background-color: $hiper-darkteal;}
                &::-webkit-scrollbar-thumb       {background-color: rgba(#ffffff, .5);}
                &::-webkit-scrollbar-thumb:hover {background-color: rgba(#ffffff, .6);}
            }

            li {
                padding:          0;
                background-color: $hiper-teal;
                transition:       background-color .15s $ease-in-out-quad;

                &:only-child                   {border-radius: .5rem;}
                &:first-child:not(:only-child) {border-radius: .5rem .5rem 0 0;}
                &:last-child:not(:only-child)  {border-radius: 0 0 .5rem .5rem;}

                &:not(:only-child):not(:last-child) {
                    border-bottom: 1px solid rgba($color, .25);
                }

                &:hover {background-color: $hiper-darkteal;}

                button {
                    display:     block;
                    padding:     .4em;
                    width:       100%;
                    background:  none;
                    cursor:      pointer;
                    font:        inherit;
                    text-align:  left;
                    line-height: 1.2;

                    &, &:hover {
                        border: none;
                        color:  inherit;
                    }
                }
            }
        }

        &.no-hover {cursor: default;}

        &:not(.no-hover):hover {
            .dropdown-icon {transform: rotate(180deg);}

            .dropdown {
                @include  visible;
                overflow: hidden;

                ul {
                    max-height: calc(calc(100vh - 3.75em - 3.5em + .25em) - 2em);
                    overflow-y: auto;
                }
            }
        }
    }

    &.single {
        display:         flex;
        flex-direction:  column;
        justify-content: center;
        align-items:     flex-start;
    }
}

.selfcare-header .pop-up-arrow,
.selfcare-header .pop-up-arrow::before,
.selfcare-header .pop-up,
.selfcare-header .pop-up-backdrop {
    position: absolute;
}

.selfcare-header .pop-up-arrow {
    z-index:        2;
    height:         1em;
    pointer-events: none;

    &::before {
        content:       '';
        top:           0;
        left:          50%;
        transform:     translateX(-50%);
        border-top:    0 solid transparent;
        border-right:  .75em solid transparent;
        border-left:   .75em solid transparent;
        border-bottom: 1em solid #ffffff;
    }

    &.multiple-addresses-hint {
        bottom: calc(-.25em - .9em);
        width:  100%;
    }
}

.selfcare-header .pop-up {
    z-index:          1;
    margin:           0;
    padding:          1em;
    border-radius:    1em;
    border:           none;
    background-color: #ffffff;
    box-shadow:       var(--box-shadow);
    color:            $hiper-blue;
    cursor:           default;

    &.multiple-addresses-hint {
        bottom: calc(.1em - .25em - .9em);
    }

    p {
        line-height: 1.4;

        & > strong,
        & > span   {display:        block;}
        & > strong {padding-bottom: .25em;}
        & > span   {font-size:      .9em;}
    }

    .checkbox-label {
        display: block;

        &::before {
            @include     font-weight-variable(400);
            font-size:   2em;
            line-height: .7;
            color:       $hiper-blue;
        }
    }

    button {
        display:       block;
        margin:        0 auto;
        padding:       0 0 .1em;
        background:    transparent;
        border:        none;
        border-bottom: 2px solid $hiper-red;
        font-size:     1em;
        color:         $hiper-blue;
        cursor:        pointer;
        transition:    color .2s $ease-in-out-cubic,
                       border-bottom-color .2s $ease-in-out-cubic;

        &:hover {
            color:               $hiper-red;
            border-bottom-color: transparent;
        }
    }
}

@media (min-width: 961px) {
    .selfcare-header .show-on-burger {
        display: none;
    }
}

@media (max-width: 960px) {
    .selfcare-header .hide-on-burger {
        display: none;
    }
}

@media (min-width: 521px) {
    .selfcare-header .pop-up {
        width: 22em;

        &.multiple-addresses-hint {
            transform: translate(-1em, 100%);
        }
    }
}

@media (max-width: $phone) {
    .selfcare-header .pop-up {
        transform: translateY(100%);
        width:     calc(100vw - 1em);

        &.multiple-addresses-hint {left: -1em;}
    }
}

@media (max-width: 350px) {
    .selfcare-header .pop-up {
        padding: 1em .75em;

        &.multiple-addresses-hint {left: 0;}
    }
}

.selfcare-header .pop-up-backdrop {
    left:             0;
    right:            0;
    bottom:           0;
    width:            100%;
    background-color: rgba(#000000, .5);
    backdrop-filter:  blur(4px);
    cursor:           default;
}

.selfcare-menu-button-container {
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     flex-end;
    max-width:       60em;
    margin:          0 auto;
}

.selfcare-menu-button {padding:    0;}
.selfcare-menu        {transition: transform $header-transition;}

.selfcare-menu-items {
    display: flex;

    li {
        margin:  0;
        padding: 0;
    }

    .choose {
        display:       flex;
        align-items:   center;
        border-bottom: 0;
        line-height:   1.2;
        text-align:    center;
        transition:    background-color .15s $ease-in-out-quad,
                       box-shadow .15s $ease-in-out-quad,
                       color .15s $ease-in-out-quad;

        .icon {
            display:    block;
            color:      transparent;
            transition: color .15s $ease-in-out-quad,
                        fill .15s $ease-in-out-quad;
        }
    }
}
// End region: Nav

// Region: General
.selfcare-page {
    summary,
    details {box-sizing: border-box;}
    hr      {width:      64em;}
}

.selfcare-page-error {
    padding-top: 4em;
    max-width:   38em;

    p {text-align: center;}
}

.selfcare-page-content {
    max-width: 44em;
    margin:    0 auto;

    @media (min-width: 961px)  {padding: 0 2em;}
    @media (max-width: 960px)  {padding: 0 1em;}
    @media (max-width: $phone) {padding: 0 .75em;}
}

.selfcare-page-content--wide {
    max-width: 60em;
}

.selfcare-page-content--full {
    max-width: none;
    width:     100%;
}

.selfcare-page-summary {
    margin-bottom: 2em;

    h1, h2, p {
        text-align: center;

        &:only-child {padding-bottom: 0;}
    }

    p {
        padding-bottom: .5em;

        &:first-of-type {margin-top:     -.5em;}
        &:last-child    {padding-bottom: 0;}
    }

    .icon {
        display:        inline-block;
        width:          1.5em;
        height:         1.5em;
        color:          transparent;
        fill:           $hiper-blue;
        vertical-align: top;
    }
}

.no-content {
    @include    font-weight-variable(600);
    text-align: center;
}

.selfcare-explainer,
.selfcare-explainer-confirm {
    display:     inline-block;
    font-size:   .8em;
    line-height: 1.4;
}

.selfcare-explainer-confirm {
    font-style: italic;
}

.selfcare-popup-overlay,
.selfcare-popup {position: fixed;}

.selfcare-popup-overlay {
    z-index:          1;
    top:              0;
    left:             0;
    right:            0;
    bottom:           0;
    width:            100%;
    height:           100%;
    background-color: rgba(#000000, .5);
}

.selfcare-report-connection,
.selfcare-hardware-text,
.selfcare-popup {
    .buttons {
        display:     flex;
        align-items: center;
        gap:         .25em;
        margin-top:  .5em;

        & > button,
        & > a {
            padding:       .25em .75em;
            border-radius: 1em;
            border:        none;
            font-size:     1em;
            line-height:   1.2;
            cursor:        pointer;
            transition:    background-color .2s $ease-in-out-cubic;
        }
    }
}

.permission-banner {
    display:          flex;
    text-align:       center;
    align-items:      center;
    padding:          1em 2em;
    background-color: $hiper-teal;
    border-radius:    10px;
    cursor:           pointer;
    color:            #fff;
    font-size:        1em;
    margin-bottom:    40px;
    max-width:        800px;

    .banner-image {
        max-width: 150px;

        @media (max-width: $phone) {
            max-width: 120px;
        }

    }
   
    @media (max-width: $phone) {
            flex-direction: column;
            font-size:      1em;
            margin:         0 0.6em 2em;
        }

    p {
        padding: 0;
    }

    .button, .button--montserrat {
        margin-top: 0.5em;
        font-size:  .9em;

        @media (max-width: $phone) {
            font-size: 1em;
        }
    }
}

.selfcare-popup {
    left:             50%;
    transform:        translateX(-50%);
    overflow-y:       auto;
    border-radius:    1em;
    background-color: #ffffff;
    z-index:          1;

    .smaller {
        font-size:      .7em;
    }

    // Screen height - Nav and selfcare nav - top space - bottom space - chat button
    @media (min-width: 500px) {
        max-height: calc(100vh - calc(3.75em + 3.5em) - 2em - .5em - calc(40px + 62px));
    }
    @media (max-width: 499px) {
        max-height: calc(100vh - calc(3.75em + 3em) - 1em - .5em - calc(40px + 56px));
    }

    & > p, & > ul {font-size:      .9em;}
    ul > p        {padding-bottom: .35em;}
    ul > ul       {padding-left:   1em;}

    & > ul {
        padding-bottom: .7em;

        &:last-child {padding-bottom: 0;}
    }

    li {
        position:       relative;
        padding-left:   1em;
        padding-bottom: .35em;
        line-height:    1.4;

        &:last-child {padding-bottom: 0;}

        &::before {
            @include  font-weight-variable(800);
            content:  '\2022';
            color:    $hiper-red;
            position: absolute;
            left:     0;
        }
    }

    ul.number {
        counter-reset: list;

        li::before {
            @include           font-weight-variable(600);
            counter-increment: list;
            content:           counter(list) '.';
        }
    }

    ul > ul li::before {
        color:               transparent;
        -webkit-text-stroke: 1px $hiper-red;
    }

    code {
        padding:          .1em .25em;
        border-radius:    .25em;
        background-color: $hiper-lightgray;
        font-family:      $font-mono;
        white-space:      pre-wrap;
        letter-spacing:   .075em;
    }

    .buttons {
        justify-content: center;

        &:only-child {margin-top: 0;}
    }

    .cancel {
        background-color: $hiper-lightgray;
        box-shadow:       inset 0 0 0 1px $hiper-darkgray;
        color:            $hiper-blue;

        &:hover {background-color: $hiper-gray;}
    }

    .confirm {
        background-color: $hiper-teal;
        color:            #ffffff;

        &:hover {background-color: $hiper-darkteal;}
    }

    .error-box {
        margin-top: .5em;

        p {line-height: 1.4;}
    }
}
// End region: General

// Region: Components
.back-button-react {
    display:         inline-flex;
    justify-content: center;
    align-items:     center;
    line-height:     1.4;

    .icon {
        margin-left:         -.2em;
        margin-right:        .1em;
        width:               1.4em;
        height:              1.4em;
        vertical-align:      middle;
        color:               transparent;
        fill:                $hiper-blue;
        transform:           rotate(180deg);
        transition:          inherit;
        transition-property: fill;
    }

    &:hover .icon {
        fill: $hiper-red;
    }
}

.selfcare-page-summary + .back-button-react {
    display: flex;
    margin:  -1.5em auto 2em;
    width:   fit-content;
}

.selfcare-loading-content {
    opacity:        .5;
    pointer-events: none;
    user-select:    none;
}

.selfcare-loader {
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    align-items:     center;
    gap:             1em;
    width:           100%;
    height:          100%;
    padding:         2em 1em;

    p {
        text-align:  center;
        line-height: 1.6;
    }

    .spinner {
        animation: rotation 2s linear infinite;
    }

    @keyframes rotation {
        0%   {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}
    }

    .path {
        stroke-dasharray:  200;
        stroke-dashoffset: 170;
        stroke:            $hiper-red;
        transform-origin:  center;
        animation:         stroke 3s ease-in-out infinite;
    }

    @keyframes stroke {
        0%   {stroke-dashoffset: 170;}
        50%  {stroke-dashoffset: 50;}
        100% {stroke-dashoffset: 170;
              transform:         rotate(360deg);}
    }
}

.pagination {
    display:     grid;
    align-items: center;
    margin:      1em auto 0;
    text-align:  center;

    .disabled,
    .enabled {
        padding:       .4em .8em;
        border-radius: .5em;
        white-space:   nowrap;
    }

    .disabled {
        background-color: $hiper-gray;
        opacity:          .75;
    }

    .enabled {
        border:           none;
        cursor:           pointer;
        background-color: $hiper-teal;
        color:            #ffffff;

        &:hover {background-color: $hiper-darkteal;}
    }

    .previous-page {
        grid-area:  previous;
        font:       inherit;
    }
    .next-page     {
        grid-area:  next;
        font:       inherit;
    }

    .current-page {
        grid-area:    current;
        justify-self: center;
        line-height:  1;
    }
}

.selfcare-button-link {
    padding:       0;
    margin:        0;
    border:        none;
    border-bottom: 2px solid rgba($hiper-red, .75);
    background:    none;
    transition:    .2s all $ease-in-out-cubic;
    cursor:        pointer;
    font:          inherit;
    color:         inherit;

    &:hover {
        border-bottom-color: $color-transparent-red;
        color:               $hiper-red;
    }

    &:focus {outline: 5px auto $hiper-seablue;}
}

.selfcare-messages {
    margin:    0 auto 1em;
    max-width: 26em;

    .selfcare-message-success,
    .selfcare-message-error {
        display:       none;
        margin-bottom: .5em;
        padding:       .5em;
        border-radius: .5em;
        color:         #ffffff;

        &.show {display: block;}
    }

    .selfcare-message-success {background-color: $hiper-green;}
    .selfcare-message-error   {background-color: $hiper-red;}

    p {
        text-align: center;
        white-space: pre-line;
    }
}
// End region: Components

// Region: Login & Account
.selfcare-login-form,
.selfcare-login-resend,
.selfcare-account-table {
    .icon-button {
        margin:     0;
        padding:    0;
        border:     0;
        background: none;
        font-size:  1em;
        cursor:     pointer;

        &, .icon {
            width:  1em;
            height: 1em;
        }
    }

    .input-with-button {
        &, .button-container {
            display:         flex;
            justify-content: center;
            align-items:     center;
        }

        input {
            border-top-right-radius:    0;
            border-bottom-right-radius: 0;
            border-right:               0;
        }

        .button-container {
            padding: 0 .2em;
            height:  2.5rem;
        }

        .button-container {
            border-top-right-radius:    .5rem;
            border-bottom-right-radius: .5rem;
            border:                     1px solid $hiper-darkgray;
            border-left:                0;
        }

        .icon-button {font-size: 1.75em;}

        .icon {
            color: transparent;
            fill:  $hiper-blue;
        }
    }

    span.password,
    input[type=password] {
        @include        font-weight-variable(900);
        letter-spacing: .1em;
    }

    input[type=password]::placeholder {
        @include        font-weight-variable(400);
        letter-spacing: var(--letter-spacing-big);
    }

    .submit-button-block {
        .button {
            width:          100%;
            padding-top:    .4em;
            padding-bottom: .4em;
        }

        .text {font-size: 1.5em;}
    }
}

// Region: Login
.selfcare-page-login {
    h1 {padding-bottom: 0;}

    .selfcare-page-summary {
        margin:      0 auto 1rem;
        max-width:   26rem;
        font-size:   .9em;
        line-height: 1.4;
    }
}

.selfcare-login-form,
.selfcare-login-resend {
    max-width:     26em;
    margin:        0 auto;
    border-radius: 1em;
    box-shadow:    var(--box-shadow);
    text-align:    center;

    p,
    input[type=text],
    input[type=password],
    ::placeholder {text-align: left;}
    .hidden       {display:    none;}

    .selfcare-login__email-suggestion-p {
        display: none;

        button {
            padding:       0;
            border:        none;
            border-bottom: 2px solid $hiper-red;
            background:    none;
            cursor:        pointer;
            font:          inherit;
            transition:    border .2s $ease-in-out-cubic,
                           color .2s $ease-in-out-cubic;

            &:hover {
                border-bottom-color: $color-transparent-dark;
                color:               $hiper-red;
            }
        }

        strong    {@include font-weight-variable(500);}
        &.visible {display: block;}
    }

    .input-help-text,
    .selfcare-button-bottom {font-size:      .8em;}
    .input-help-text        {padding-bottom: 0;}
    .selfcare-button-bottom {padding-bottom: .25em;}
}

.selfcare-login-form .submit-button-block   {padding:     0 0 .7em 0;}
.selfcare-login-resend .submit-button-block {padding-top: 0;}
// End region: Login

// Region: Overview
.selfcare-page-overview {
    .selfcare-overview-message.no-link,
    .selfcare-overview-message > a,
    .icon-with-text {
        display:         flex;
        justify-content: flex-start;
        align-items:     center;
        gap:             .5em;
    }

    .icon {
        display:     block;
        flex-shrink: 0;
        width:       2em;
        height:      2em;
    }

    .text {width: 100%;}
}

.selfcare-overview-message {
    padding:          0;
    border-radius:    .5em;
    background-color: #ffffff;
    box-shadow:       var(--box-shadow);
    line-height:      1.4;
    text-align:       left;
    transition:       box-shadow .2s $ease-in-out-cubic;

    &:only-child,
    &:last-child {margin-bottom: 0;}

    &.no-link,
    & > a {border: none;}

    .icon, .text {
        transition: opacity .15s $ease-in-out-cubic;
    }

    .icon {
        color: transparent;
        fill:  $hiper-blue;
    }

    .text {font-size: .9em;}

    &.selfcare-overview-message--important,
    &.selfcare-overview-message--highlight {
        .text {@include font-weight-variable(500);}
    }

    &.selfcare-overview-message--important {
        box-shadow: inset 0 0 0 2px $hiper-red,
                    var(--box-shadow);

        .icon {fill:  $hiper-red;}
        .text {color: $hiper-red;}
    }

    &.selfcare-overview-message--highlight {
        box-shadow: inset 0 0 0 2px $hiper-teal,
                    var(--box-shadow);

        .icon {fill:  $hiper-teal;}
        .text {color: $hiper-teal;}
    }

    &:hover:not(.no-link) {
        box-shadow: var(--box-shadow-hover);

        &.selfcare-overview-message--important {
            box-shadow: inset 0 0 0 3px $hiper-red,
                        var(--box-shadow-hover);
        }

        &.selfcare-overview-message--highlight {
            box-shadow: inset 0 0 0 3px $hiper-teal,
                        var(--box-shadow-hover);
        }

        a {color: inherit;}

        .icon, .text {
            opacity: .75;
        }
    }
}

.selfcare-overview-permission-popup {
    .selfcare-popup-overlay {
        z-index:          1;
        top:              0;
        left:             0;
        right:            0;
        bottom:           0;
        width:            100%;
        height:           100%;
        background-color: rgba(#000000, .5);
    }

    .selfcare-popup {
        left:             50%;
        transform:        translateX(-50%);
        overflow-y:       auto;
        border-radius:    1em;
        background-color: #ffffff;
        z-index:          1;
        top:              calc(3.75em + 3.5em + 2em);
        padding:          1.5em 1.5em 1em;
        max-width:        calc(100vw - 2em);
        width:            36em;
        position:         relative;
    }

    .close-button {
        position: absolute;
        top: 10px;
        left: 10px;
        background: none;
        border: none;
        font-size: 1.5em;
        cursor: pointer;
    }
}

.selfcare-overview-account {
    .account-container {
        border-radius: .5em;
        box-shadow:    var(--box-shadow);
    }

    .product,
    .payment {
        padding-bottom: 1.5em;

        &:only-child,
        &:last-child {padding-bottom: 0;}
    }

    .title {
        @include        font-weight-variable(600);
        padding-bottom: 0;
        font-size:      1.2em;
    }

    .price {
        padding-bottom: 0;
        line-height:    1.6;

        & > strong {
            @include font-weight-variable(550);
        }
    }

    .sub         {padding-bottom: 0;}
    .sub, .text  {font-size:      .8em;}
    .icon        {flex-shrink:    0;}

    .icon-with-text {
        margin:  .25em 0;
        padding: 0;
    }

    .bullets {
        @include        font-weight-variable(900);
        letter-spacing: .1em;
    }

    .discount-total {
        border-radius:    .5em;
        background-color: $hiper-green;
        color:            #ffffff;
    }

    .price .discount-total {
        padding: .2em .4em;
    }

    .pricing-details .discount-total {
        margin-top: .5em;
        padding:    .5em .25em .25em;

        p {
            padding:     0;
            text-align:  center;
            line-height: 1.4;
            color:       #ffffff;

            &:nth-child(2) {font-size: 1.5em;}
        }
    }

    .pricing-details {
        padding-bottom: .25em;
    }

    summary {
        position:      relative;
        display:       block;
        width:         fit-content;
        padding-left:  1em;
        border-bottom: 2px solid $hiper-red;
        line-height:   1.4;
        color:         $hiper-blue;
        transition:    color .2s $ease-in-out-cubic,
                       border-bottom .2s $ease-in-out-cubic;

        i {
            position:  absolute;
            top:       50%;
            left:      0;
            transform: translateY(-50%);
            display:   block;
            width:     .8em;
            height:    1em;

            &::before, &::after {
                content:          '';
                position:         absolute;
                top:              50%;
                left:             50%;
                display:          block;
                background-color: $hiper-blue;
                transition:       transform .2s $ease-in-out-cubic,
                                  background-color .2s $ease-in-out-cubic;
            }

            &::before {
                transform: translate(-50%, -50%);
                width:     .5em;
                height:    .1em;
            }

            &::after {
                transform: translate(-50%, -50%);
                width:     .1em;
                height:    .5em;
            }
        }

        &:hover {
            color:               $hiper-red;
            border-bottom-color: $color-transparent-red;

            i::before, i::after {background-color: $hiper-red;}
        }
    }

    details[open] summary i::after {transform: translate(-50%, -50%) scaleY(0);}

    .summary-table {
        margin-top: 1em;

        th[colspan="2"] {
            border-radius:    .5em .5em 0 0;
            background-color: $hiper-teal;
            color:            #ffffff;
        }

        td {
            padding-left:  0;
            padding-right: 0;
            font-size:     .9em;
        }

        small {
            font-size: .9em;
            color:     rgba($hiper-blue, .9);
        }

        tbody, tfoot {
            tr {background: transparent;}
        }
    }

    .disclaimer {
        padding-top: 1rem;
        font-size:   .7em;
        line-height: 1.2;
        color:       rgba($hiper-blue, .75);
    }
}

// Region: Delivery Flow
.selfcare-page-delivery-flow {
    padding-left:  0;
    padding-right: 0;

    .toggle-delivery-flow,
    .toggle-open {
        cursor: pointer;

        .arrow {
            display:        inline-block;
            width:          20px;
            height:         1em;
            position:       relative;
            vertical-align: middle;
            transition:     transform .2s $ease-in-out-cubic;

            &::before, &::after {
                content:          '';
                display:          block;
                width:            12px;
                height:           1px;
                position:         absolute;
                top:              50%;
                left:             0;
                transition:       transform .2s $ease-in-out-cubic,
                                  background-color .2s $ease-in-out-cubic;
            }

            &.arrow-down {
                &::before {transform: translate(0, -50%)   rotate(45deg);}
                &::after  {transform: translate(8px, -50%) rotate(-45deg);}
            }

            &.arrow-up {
                &::before {transform: translate(8px, -50%) rotate(45deg);}
                &::after  {transform: translate(0, -50%)   rotate(-45deg);}
            }
        }
    }

    .toggle-delivery-flow,
    .mark-all-as-read {
        padding:          .25em 1em;
        width:            fit-content;
        border-radius:    2em;
        background-color: $hiper-teal;

        &:hover {background-color: $hiper-darkteal;}
    }

    .toggle-delivery-flow {
        display:         flex;
        justify-content: center;
        align-items:     center;
        gap:             .5em;
        margin:          0 auto;
        box-shadow:      0 0 0 1px transparent;
        font-size:       .9em;
        transition:      background-color .2s $ease-in-out-cubic,
                         box-shadow .2s $ease-in-out-cubic;

        .text {
            color:      #ffffff;
            transition: color .2s $ease-in-out-cubic;
        }

        .arrow::before,
        .arrow::after {background-color: #ffffff;}
    }

    .mark-all-as-read {
        @include    font-weight-variable(500);
        display:    block;
        margin:     0 auto 1em;
        border:     none;
        cursor:     pointer;
        font-size:  1em;
        color:      #ffffff;
        transition: background-color .2s $ease-in-out-cubic;
    }

    .selfcare-delivery-flow-container.open .toggle-delivery-flow {
        background-color: $hiper-lightgray;
        box-shadow:       0 0 0 1px $hiper-gray;

        &:hover {background-color: $hiper-gray;}

        .text {color: $hiper-blue;}

        .arrow::before,
        .arrow::after {background-color: $hiper-blue;}
    }
}

.selfcare-delivery-flow-container {
    margin-bottom: 1.5em;
    transition:    margin-bottom .2s $ease-in-out-cubic;

    &:last-child,
    &:only-child {margin-bottom: 0;}

    .selfcare-delivery-flow-header {
        text-align: center;

        p, span               {line-height:    1.4;}
        p                     {padding-bottom: .25em;}
        strong                {font-size:      1.2em;}
        .toggle-delivery-flow {font-size:      .9em;}
    }

    .selfcare-delivery-flow {
        max-height: 0;
        overflow:   hidden;
        opacity:    0;
        transition: margin-top .2s $ease-in-out-cubic,
                    max-height .2s $ease-in-out-cubic,
                    opacity .2s $ease-in-out-cubic;
    }

    &.open .selfcare-delivery-flow {
        max-height: 9999em;
        overflow:   visible;
        opacity:    1;
        margin-top: 1em;
    }
}

.selfcare-delivery-flow-status {
    display:             grid;
    grid-template-areas: "icon text"
                         "button button";
    margin:              0 auto;
    width:               fit-content;
    max-width:           calc(100% - 1em);
    border-radius:       1em;
    box-shadow:          inset 0 0 0 2px $hiper-teal;

    .icon {
        position:      relative;
        grid-area:     icon;
        align-content: center;
        width:         .5em;
        height:        1em;
        color:         transparent;
        fill:          $hiper-blue;

        svg {
            position:  absolute;
            top:       50%;
            left:      50%;
            transform: translate(-50%, -50%);
            width:     1em;
            height:    1em;
        }
    }

    p {
        grid-area:     text;
        align-content: center;
        padding:       0;
        font-size:     .9em;
        line-height:   1.4;
        text-align:    center;
    }

    a {
        grid-area:        button;
        justify-self:     center;
        padding:          .25em .75em;
        width:            fit-content;
        border-radius:    2em;
        border:           none;
        background-color: $hiper-teal;
        color:            #ffffff;
        font-size:        1em;

        &:hover {background-color: $hiper-darkteal;}
    }

    &.selfcare-delivery-flow-status--alert {
        box-shadow: inset 0 0 0 2px $hiper-red;

        .icon {fill: $hiper-red;}

        a {
            background-color: $hiper-red;

            &:hover {background-color: $hiper-darkred;}
        }
    }
}

.selfcare-delivery-flow-timeline {
    display:          flex;
    flex-direction:   row;
    justify-content:  flex-start;
    gap:              0;
    overflow-x:       auto;
    scroll-snap-type: x proximity;
    margin:           0 auto;
    margin-bottom:    1em;
    padding-bottom:   1em;
    max-width:        100%;
    width:            fit-content;

    li {
        scroll-snap-align: center;
        scroll-snap-stop:  normal;
        display:           block;
        margin:            0;
        padding:           0;
        flex:              0 0 16em;
        width:             16em;

        .icon        {color: $hiper-gray;}
        &.done .icon {color: $hiper-green;}
    }

    .icon {display: block;}

    .status {
        $height:       1.5em;

        position:      relative;
        width:         100%;
        height:        $height;
        margin-bottom: 1em;

        &::before {
            content:          '';
            position:         absolute;
            top:              50%;
            left:             50%;
            transform:        translate(-50%, -50%);
            width:            calc(100% + 2px);
            height:           1px;
            background-color: $hiper-darkgray;
        }

        .icon {
            position:  absolute;
            top:       0;
            left:      50%;
            transform: translateX(-50%);
            width:     $height;
            height:    $height;
        }
    }

    .procedure-card {
        display:         flex;
        flex-direction:  column;
        justify-content: flex-start;
        align-items:     center;
        text-align:      center;
        padding:         1em .5em;
        height:          calc(100% - 2.5em);
        border-radius:   1em;
        box-shadow:      var(--box-shadow);

        .icon {
            width:         4em;
            height:        4em;
            margin-bottom: 1em;
        }

        h3 {font-size: 1em;}

        p,
        & > a {
            line-height: 1.4;
            font-size:   .8em;
        }

        .date {
            @include     font-weight-variable(500);
            padding-top: .5em;
            font-size:   .9em;
        }

        p + .date {padding-top: 0;}
    }

    li.start .status::before,
    li.end   .status::before {
        transform: translateY(-50%);
        width:     calc(50% + 1px);
    }

    li.start .status::before {left: 50%;}
    li.end   .status::before {left: -1px;}
}

.selfcare-delivery-flow-notification-list {
    margin: 0 auto;
    width:  40em;
}

// Region: Delivery Flow Notifications
.selfcare-notification,
.selfcare-delivery-flow-notification-list .selfcare-loader {
    margin-bottom: 1em;
    padding:       1em;
    border-radius: 1em;
    box-shadow:    var(--box-shadow);

    &:last-child {margin-bottom: 0;}
}

.selfcare-delivery-flow-notification-list + .selfcare-delivery-flow-notification-list {
    margin-top: .5em;
}

.selfcare-notification {
    display: grid;

    &.open {
        .notification-buttons {
            margin-bottom: .25em;
        }

        .notification-body {
            max-height: 500em;
        }
    }

    &.important {
        h3.heading.heading--montserrat {
            color: $hiper-red
        }

        .notification-icon .icon {
            fill: $hiper-red;
        }
    }

    &.unread {
        h3.heading.heading--montserrat,
        .timestamp,
        .notification-buttons button {
            @include font-weight-variable(600);
        }

        .notification-buttons {
            button {
                background-color: $hiper-teal;
                box-shadow:       none;
                color:            #ffffff;

                &:hover {background-color: $hiper-darkteal;}
            }

            .icon {
                fill: #ffffff;
            }

            .arrow::before,
            .arrow::after {background-color: #ffffff;}
        }
    }

    &.unread {
        box-shadow: 0 0 0 2px $hiper-teal,
                    var(--box-shadow);
    }

    &.important.unread {
        box-shadow: 0 0 0 2px $hiper-red,
                    var(--box-shadow);
    }

    .icon {display: block;}

    .notification-icon {
        grid-area: icon;

        .icon {
            position: relative;
            width:    1em;
            height:   1em;
            color:    transparent;
            fill:     $hiper-blue;

            svg {
                position:  absolute;
                top:       50%;
                left:      50%;
                width:     1.6em;
                height:    1.6em;
                transform: translate(-50%, -50%);
            }
        }
    }

    .notification-head {
        grid-area:   head;
        line-height: 1.4;
        transition:  margin-bottom .2s $ease-in-out-cubic;
    }

    h3.heading.heading--montserrat {
        @include        font-weight-variable(400);
        padding-bottom: 0;
        font-size:      1.1em;
        line-height:    1.2;
        word-wrap:      word-break;
        word-wrap:      auto-phrase;
        transition:     font-variation-settings .2s $ease-in-out-cubic;
    }

    .timestamp {
        display:    block;
        font-size:  .7em;
        transition: font-variation-settings .2s $ease-in-out-cubic;
    }

    .notification-buttons {
        grid-area:       btns;
        display:         flex;
        flex-flow:       row wrap;
        justify-content: flex-start;
        align-items:     stretch;
        gap:             .25em;
        margin-top:      .25em;
        transition:      margin-bottom .2s $ease-in-out-cubic;

        button {
            @include          font-weight-variable(400);
            display:          flex;
            justify-content:  center;
            align-items:      center;
            gap:              .5em;
            padding:          .25em .75em;
            border-radius:    1em;
            background-color: $hiper-lightgray;
            border:           none;
            box-shadow:       inset 0 0 0 1px $hiper-gray;
            font-size:        .9em;
            color:            $hiper-blue;
            transition:       font-variation-settings .2s $ease-in-out-cubic;
            cursor:           pointer;

            &:hover {background-color: $hiper-gray;}
        }

        .icon {
            position: relative;
            width:    1.2em;
            height:   1.2em;
            color:    transparent;
            fill:     $hiper-blue;

            svg {
                position:  absolute;
                top:       50%;
                left:      50%;
                transform: translate(-50%, -50%) scale(1.5);
            }
        }

        .arrow,
        .arrow::before,
        .arrow::after {transition: none;}

        .arrow::before,
        .arrow::after {background-color: $hiper-blue;}
    }

    .notification-body {
        grid-area:     body;
        margin-bottom: -.5em;
        max-height:    0;
        overflow:      hidden;
        overflow-x:    auto;
        font-size:     .9em;
        transition:    max-height .2s $ease-in-out-cubic;

        h1, h2, h3, h4, h5, h6, p, li {
            word-wrap: word-break;
            word-wrap: auto-phrase;
        }

        h1, h2, h3, h4, h5, h6 {
            @include        font-weight-variable(600);
            padding-top:    .7rem;
            padding-bottom: .1rem;
            font-family:    $font-montserrat;
            text-align:     left;
            text-transform: none;
            line-height:    1.4;
            letter-spacing: normal;

            &:first-child {padding-top: 0;}
        }

        h1, h2, h3, h4 {
            font-size: 1.2em;
        }

        h5    {font-size:  1.1em;}
        h6    {font-size:  1em;}
        em, i {font-style: italic;}

        ul {
            padding-bottom: .7em;

            &:last-child {padding-bottom: 0;}
        }

        li {
            position:       relative;
            padding-left:   1em;
            padding-bottom: .35em;

            &:last-child {padding-bottom: 0;}

            &::before {
                @include  font-weight-variable(900);
                content:  '\2022';
                color:    $hiper-red;
                position: absolute;
                left:     0;
            }
        }

        *:empty {
            margin:  0;
            padding: 0;
        }
    }
}
// End Region: Delivery Flow Notifications

// End region: Delivery Flow

// Region: Report Connection

.selfcare-report-connection {
    margin:        0 auto;
    padding:       1em;
    max-width:     32em;
    border-radius: 1em;
    box-shadow:    var(--box-shadow);

    & > h2 {
        font-size: 1.4em;
    }

    & > p {
        line-height: 1.4;
        text-align:  center;
    }

    & > .buttons {
        justify-content: center;
        width:           100%;

        & > button {
            display:          flex;
            justify-content:  center;
            align-items:      center;
            gap:              .5em;
            background-color: $hiper-teal;
            color:            #ffffff;

            &:hover {background-color: $hiper-darkteal;}

            &:disabled {
                background-color: $hiper-gray;
                cursor:           not-allowed;

                &:hover {background-color: $hiper-gray;}
            }

            .icon {
                position: relative;
                width:    1.2em;
                height:   1.2em;
                color:    transparent;
                fill:     #ffffff;

                svg {
                    position:  absolute;
                    top:       50%;
                    left:      50%;
                    transform: translate(-50%, -50%) scale(1.333333);
                }
            }
        }
    }

    .fejlmelding-startet * {
        pointer-events: none; // Fix for GTM tracking issue
    }
}

.selfcare-popup--report-connection {
    h3, h4 {
        padding-top:    .7rem;
        padding-bottom: .7rem;

        &:first-child {padding-top: 0;}
    }

    .small {
        padding-top: .5em;
        font-size:   .8em;
        line-height: 1.2;
    }

    .attention-box {
        @include          font-weight-variable(500);
        margin-bottom:    .7em;
        padding:          .5em;
        border-radius:    .5em;
        background-color: $hiper-red;
        color:            #ffffff;

        a {
            border-bottom-color: #ffffff;

            &:hover {
                color:               rgba(#ffffff, .75);
                border-bottom-color: rgba(#ffffff, .75);
            }
        }
    }

    .input-container {
        padding-bottom: .7em;

        &:last-child {padding-bottom: 0;}

        p {padding-bottom: 0;}
    }

    .contact-row {
        display:   flex;
        flex-wrap: wrap;
        gap:       .25em;

        .contact-item {
            flex: calc(50% - .125em) 1 1;

            .checkbox-label {
                position:       relative;
                line-height:    1;
                padding-top:    .4em;
                padding-bottom: .4em;
                padding-left:   calc(.5rem + 1.4rem + .5rem);

                &::before {
                    position:  absolute;
                    top:       50%;
                    left:      .5rem;
                    transform: translateY(-50%);
                    margin:    0;
                }

                small {font-size: .8em;}
            }
        }
    }

    .buttons button {
        padding:        .25em .75em .15em;
        border-radius:  .5rem;
        font-size:      1.5em;
        font-family:    $font-cervo;
        text-transform: uppercase;
        font-weight:    300;
        letter-spacing: var(--letter-spacing-medium);
    }

    textarea {
        display:       block;
        padding:       .25em .5em;
        min-height:    5em;
        border-radius: .5em .5em .25em .5em;
        overflow-x:    hidden;
        overflow-y:    auto;
        resize:        vertical;
        color:         $hiper-blue;
        line-height:   1.4;

        &::placeholder {
            text-align: left;
        }
    }

    .error-message {
        color:       $hiper-red;
        line-height: 1.4;
    }
}

// End region: Report Connection

// Region: Hardware

.selfcare-hardware {
    margin: 0 auto;
    width:  fit-content;
}

.selfcare-hardware-item {
    display:       grid;
    justify-items: center;
    margin-left:   auto;
    margin-right:  auto;
    width:         100%;
    border-radius: 1em;
    box-shadow:    var(--box-shadow);

    &:only-child,
    &:last-child {margin-bottom: 0;}
}

.selfcare-hardware-item img {
    grid-area: image;
    z-index:   -1;
    filter:    drop-shadow(0 1px 2px rgba(#000000, .2));

    &.netgear-c6250 {filter: none;}
}

.selfcare-hardware-text {
    grid-area: text;

    h3 {padding-bottom: 0;}

    & > .buttons {
        flex-wrap: wrap;

        & > button,
        & > a {
            background-color: $hiper-teal;
            color:            #ffffff;

            &:hover {background-color: $hiper-darkteal;}

            &.disabled,
            &:disabled {
                background-color: $hiper-gray;
                cursor:           not-allowed;

                &:hover {background-color: $hiper-gray;}
            }
        }
    }

    .specifications-button {
        display:         flex;
        justify-content: center;
        align-items:     center;
        gap:             .25em;
        width:           fit-content;
        border-bottom:   2px solid $hiper-red;
        font-size:       .9em;
        cursor:          pointer;
        transition:      border-bottom-color .2s $ease-in-out-cubic;

        &:hover {
            border-bottom-color: rgba($hiper-red, .5);
        }

        .arrow {
            display:        inline-block;
            width:          1em;
            height:         1em;
            position:       relative;
            vertical-align: middle;
            transition:     transform .2s $ease-in-out-cubic;

            &::before, &::after {
                content:          '';
                display:          block;
                width:            .6em;
                height:           1px;
                position:         absolute;
                top:              50%;
                left:             0;
                background-color: $hiper-blue;
                transition:       transform .2s $ease-in-out-cubic;
            }

            &.arrow-down {
                &::before {transform: translate(0, -50%)   rotate(45deg);}
                &::after  {transform: translate(.4em, -50%) rotate(-45deg);}
            }

            &.arrow-up {
                &::before {transform: translate(.4em, -50%) rotate(45deg);}
                &::after  {transform: translate(0, -50%)   rotate(-45deg);}
            }
        }
    }

    .buttons + .specifications-button {margin-top: .25em;}
}

.selfcare-hardware-specifications {
    grid-area:  spec;
    max-height: 0;
    overflow:   hidden;
    opacity:    0;
    transition: margin-top .2s $ease-in-out-cubic,
                max-height .2s $ease-in-out-cubic,
                opacity .2s $ease-in-out-cubic;

    li {
        position:       relative;
        padding-left:   1em;
        padding-bottom: .25em;
        font-size:      .9em;

        &:last-child {padding-bottom: 0;}

        &::before {
            @include  font-weight-variable(900);
            content:  '\2022';
            color:    $hiper-red;
            position: absolute;
            left:     0;
        }
    }

    &.open {
        max-height: 9999em;
        overflow:   visible;
        opacity:    1;
    }
}

.selfcare-hardware-specifications-table {
    th, td {
        border:           0;
        border-radius:    0;
        background-color: #ffffff;
        line-height:      1.4;
        color:            $hiper-blue;
    }

    tr:first-child,
    tr:last-child {
        th, td {border-radius: 0;}
    }

    th {@include font-weight-variable(500);}

    p {
        padding-bottom: 0;
        font-size:      .9em;
        line-height:    inherit;
    }
}

.selfcare-popup--mesh {
    .action-box {
        margin-top: 1em;

        h4, p    {padding-bottom: 0;}
        p, small {text-align:     center;}

        small {
            display:   block;
            font-size: .8em;
            color:     rgba($hiper-blue, .75);
        }
    }
}
// End region: Hardware

// Region: Help
.selfcare-help-articles {
    display:       grid;
    justify-items: center;
    align-items:   center;

    a {
        padding:          1em;
        width:            100%;
        height:           100%;
        border-radius:    1em;
        border:           1px solid $hiper-darkgray;
        background-color: $hiper-lightergray;
        text-align:       center;

        &:hover {
            border:           1px solid transparent;
            background-color: $hiper-teal;
            color:            #ffffff;
        }
    }
}
// End region: Help

// Region: Card (Payment)
.selfcare-card-container {
    margin:        0 auto;
    width:         fit-content;
    border-radius: 1em;
    box-shadow:    var(--box-shadow);
}

.selfcare-card,
.change-card {
    display:         flex;
    justify-content: center;
    align-items:     center;
}

.selfcare-card {
    gap:        .5em 1em;
    padding:    1em;
    text-align: left;

    p {
        line-height:    1.2;
        padding-bottom: .5em;

        &:last-child {padding-bottom: 0;}
    }

    .label {
        @include       font-weight-variable(500);
        display:       inline-block;
        padding-right: .5em;
    }

    .icon {
        display:     block;
        flex-shrink: 0;
        width:       1em;
        height:      1em;
        font-size:   4em;
        color:       $hiper-teal;
        fill:        #ffffff;

        svg {display: block}
    }

    .bullets {
        @include        font-weight-variable(900);
        font-size:      1.25em;
        letter-spacing: .05em;
    }
}

.change-card,
.add-card {
    color:            #ffffff;
    background-color: $hiper-teal;
    border:           0;

    &:hover {
        background-color: $hiper-darkteal;
        color:            #ffffff;
    }
}

.change-card {
    width:         100%;
    padding:       .5em;
    border-radius: 0 0 1em 1em;
}

.add-card {
    display:       block;
    padding:       0.5em 1em;
    border-radius: .5rem;
    font-size:     1.2em;
}
// End region: Card (Payment)

// Region: Invoice
.selfcare-invoices-table,
.selfcare-invoice-table {
    td, th {
        border:         0;
        vertical-align: middle
    }

    th {
        @include          font-weight-variable(600);
        color:            #ffffff;
        background-color: $hiper-teal;
        text-align:       left;
    }

    td {
        color:            $hiper-blue;
        background-color: #ffffff;
    }
}

.selfcare-invoices-table {
    height: 1px;       //    WHY does this fix table cell...
    td {height: 100%;} // ...fill available height problems???

    .selfcare-invoices-table-button {
        display:     flex;
        align-items: center;

        button, a {
            display:    block;
            width:      fit-content;
            border:     0;
            cursor:     pointer;
            background: none;
            font-size:  1em;

            &:hover {color: inherit;}
        }

        .icon {display: inline-block;}

        .selfcare-loader {
            padding: 0;

            &, svg {
                width:  1.5em;
                height: 1.5em;
            }
        }
    }

    .invoice-status--overdue {
        color: $hiper-darkred;
    }

    .invoice-status--inkasso {
        font-weight: 600;
        color:       $hiper-darkred;
    }

    .icon-arrow {
        color: $hiper-green;
    }
}

.selfcare-invoice-table {
    border-radius: .5em;
    box-shadow:    var(--box-shadow);

    th, td {
        line-height: 1.2;

        &:first-child {padding-left: .8rem;}

        &:last-child {
            padding-right: .8rem;
            text-align:    right;
        }
    }

    tr:nth-child(even) td {
        background: $hiper-lightergray;
    }

    thead th {
        &:first-child {border-radius: .5em 0 0 0;}
        &:last-child  {border-radius: 0 .5em 0 0;}
    }

    tbody td {border-radius: 0;}

    tfoot td {
        background-color: #ffffff;
        border-top:       1px solid $hiper-gray;

        &:first-child {border-radius: 0 0 0 .5em;}
        &:last-child  {border-radius: 0 0 .5em 0;}
    }
}

.selfcare-page-pay-invoice {
    p {text-align: center;}

    .button {
        display: block;
        margin:  0 auto;
    }
}
// End region: Invoice

// Region: Communication
.selfcare-communication-overview {
    li {
        margin-bottom: .5em;
        padding:       0;
    }

    a {
        display:          flex;
        justify-content:  flex-start;
        align-items:      center;
        border-radius:    .5em;
        border:           none;
        background-color: #ffffff;
        box-shadow:       var(--box-shadow);

        &:hover {
            color:      rgba($hiper-blue, .75);
            box-shadow: var(--box-shadow-hover);

            .icon {fill: rgba($hiper-blue, .75);}
        }
    }

    .icon, .text, .subject, .date {
        display: block;
    }

    .icon {
        position:    relative;
        flex-shrink: 0;
        width:       1em;
        height:      1em;
        color:       transparent;
        fill:        $hiper-blue;
        transition:  color .2s $ease-in-out-cubic;

        svg {
            position:  absolute;
            top:       50%;
            left:      50%;
            transform: translate(-50%, -50%);
            width:     1.333333em;
            height:    1.333333em;
        }
    }

    .text {
        width:       100%;
        line-height: 1.2;
    }

    .subject {
        @include font-weight-variable(500);
    }

    .date {
        font-size: .8em;
        color:     rgba($hiper-blue, .75);
    }
}

.selfcare-communication {
    .back-button-react {
        display: flex;
        margin:  0 auto 1em;
        width:   fit-content;
    }

    h1 {padding-bottom: .5rem;}

    .subject,
    .date {display: block;}

    .subject {
        padding-bottom: .25rem;
        font-size:      1.2em;
    }

    .date {
        @include font-weight-variable(400);
        font-size: .8em;
    }

    .attachments {
        flex-flow:     row wrap;
        gap:           .25em;
        margin-bottom: .5em;

        &, a {
            display:         flex;
            justify-content: center;
            align-items:     center;
        }

        a {
            gap:              .5em;
            padding:          .5em 1em;
            border-radius:    1.5em;
            background-color: $hiper-red;
            font-size:        .8em;
            color:            #ffffff;
            white-space:      nowrap;
            border:           none;

            &:hover {color: rgba(#ffffff, .9);}
        }

        .icon {
            display: block;
            width:   1.5em;
            height:  1.5em;
            color:   transparent;
            fill:    #ffffff;
        }
    }

    iframe {
        display: block;

        &.iframe-sms {
            margin:        0 auto;
            max-width:     20em;
            border-radius: .5em;
            box-shadow:    var(--box-shadow);
        }
    }
}
// End region: Communication

// Region: Account
.selfcare-account-table {
    border-radius: .5em;
    box-shadow:    var(--box-shadow);

    p {
        padding-top:    .7em;
        padding-bottom: 0;

        &:first-child {padding-top: 0;}
    }

    .small {
        font-size: .8em;
    }

    .smaller {
        font-size: .6em;
    }

    td, th {
        border:           0;
        background-color: #ffffff;
        line-height:      1.2;
        color:            $hiper-blue;
    }

    th {@include font-weight-variable(600);}

    .selfcare-account-editable {
        position: relative;
    }

    tr:nth-child(even) td,
    tr:nth-child(even) th {
        background: $hiper-lightergray;
    }

    .text {
        display:    block;
        word-break: break-word;
    }

    .selfcare-account-edit-link {
        display:    block;
        position:   absolute;
        right:      1em;
        padding:    0;
        border:     0;
        background: none;
        font-size:  1em;
        cursor:     pointer;

        & > .icon {
            width:          inherit;
            height:         inherit;
            margin-left:    0;
            vertical-align: inherit;
            color:          transparent;
            fill:           $hiper-blue;
        }

        @at-root .js &.hidden {
            @include hidden;
        }
    }

    .nowrap {
        position:      relative;
        padding-right: 1.5em;
        white-space:   nowrap;

        &:not(:last-child) {
            margin-right: 1em;
        }

        .icon-cross,
        .icon-check {
            position: absolute;
            top:      -.15em;
            right:    0;
            display:  block;
            width:    1.5em;
            height:   1.5em;
            color:    transparent;
            fill:     $hiper-blue;
        }
    }

    .mit-wifi-credentials li {
        padding-bottom: 0;
        line-height:    1.2;

        span {letter-spacing: .1em;}
    }

    .selfcare-message-success,
    .selfcare-message-error {
        @include font-weight-variable(500);
    }

    .selfcare-message-success {color: $hiper-green;}
    .selfcare-message-error   {color: $hiper-red;}
}

.selfcare-account-error {
    padding:          .25em .5em;
    margin-bottom:    .75em;
    border-radius:    .5em;
    background-color: $hiper-red;
    color:            #ffffff;
    line-height:      1.4;
}

.selfcare-account-form {
    @at-root .js & {
        @include hidden;
    }

    max-height: 0;
    margin-top: 0;
    transition: .2s max-height $ease-in-out-cubic;

    &.visible {
        @include visible;
        max-height: 100em;
        margin-top: .7em;
    }

    input,
    input::placeholder {text-align: left;}

    p.checkbox {
        padding-top:    0;
        padding-bottom: .25em;
    }

    .icon {
        width:  2.5em;
        height: 2.5em;
    }

    .icon-arrow {color: $hiper-teal;}
}

.selfcare-account-form-confirm {
    margin:      0;
    max-width:   12em;
    display:     flex;
    align-items: center;
    gap:         .5em;
}
// End region: Account

// Region: Media Queries
@media (min-width: 961px) {
    // Sub region: Nav
    .selfcare-header-wrapper,
    .selfcare-header         {min-height: 3.5em;}
    .selfcare-header-wrapper {height:     3.5em;}

    .selfcare-header .pop-up-backdrop {
        top:    3.5em;
        height: calc(100vh - 3.5em);
    }

    .selfcare-header {
        gap:     1em;
        padding: .25em 2em;
    }

    .selfcare-header-address .dropdown {
        @media (min-width: 1151px) {max-width: 56em;}
        @media (max-width: 1150px) {max-width: calc(100vw - calc(2em * 2));}
    }

    .selfcare-menu-button-container,
    .selfcare-menu-button {
        display: none;
    }

    .selfcare-menu,
    .selfcare-menu-items {
        height: 100%;
    }

    .selfcare-menu-items {
        display:         flex;
        flex-direction:  row;
        justify-content: center;
        align-items:     center;
        gap:             .25em;

        .choose {
            flex-direction:  column;
            justify-content: center;
            gap:             .1em;
            min-width:       5em;
            border-radius:   .5em;
            padding:         .25em .4em .1em;
            color:           #ffffff;

            .icon {
                width:  1.5em;
                height: 1.5em;
            }

            .text {font-size: .8em;}

            &:hover {
                background-color: $hiper-teal;
            }

            &.active,
            &.active:hover {
                background-color: $hiper-red;
            }
        }

        .selfcare-menu-sbbu {display: none;}

        .choose#selfcare-logout-link {
            padding-right:    0;
            padding-left:     1.25em;
            min-width:        0;
            background-color: transparent;

            .icon path {
                transition: transform .15s $ease-in-out-quad;
                transform:  translateX(0);
            }

            &:hover .icon path {
                &:first-of-type {transform: translateX(100px);}
                &:last-of-type  {transform: translateX(-50px);}
            }
        }
    }

    // Sub region: General
    .selfcare-page {
        padding-top: 3.5em + 2.5em;
    }

    .selfcare-page hr {
        margin:    3em auto;
        max-width: calc(100% - 4em);
    }

    .id-anchor.id-anchor--selfcare {
        transform: translateY(calc(-3.75em - 3.5em - 3em)); // Nav - Selfcare Nav - Space based on <hr />
    }

    .selfcare-page-content {
        h1, h2 {padding-bottom: 1rem;}
    }

    // Sub region: Login & Communication
    .selfcare-page-login    {padding-top: 3em;}
    .selfcare-communication {max-width:   calc(600px + 4em);} // Based on email width

    // Sub region: Overview
    .selfcare-page-overview {
        display:               grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:   "messages account";
        gap:                   2em 4em;

        .selfcare-overview-messages-container {grid-area: messages;}
        .selfcare-overview-account            {grid-area: account;}

        @media (max-width: calc(19px * 66)) {
            gap: 2em;
        }

        .selfcare-overview-messages-container,
        .selfcare-overview-account {
            h1, h2 {text-align: left;}
        }

        .selfcare-overview-message {
            margin-bottom: .5em;

            &.no-link,
            & > a {padding: .75em;}
        }
    }

    .selfcare-page-overview .account-container {
        padding: 1em 1.5em;
    }

    // Sub region: Report Connection

    .selfcare-report-connection {
        & > h2 {padding-bottom: .1em;}
    }

    // Sub region: Hardware
    .selfcare-hardware-item {
        grid-template-areas:   "image text"
                               "spec  spec";
        grid-template-columns: 1fr 1fr;
        align-items:           center;
        gap:                   0 2em;
        margin-bottom:         1em;
        padding:               1.5em 2em;
        max-width:             40em;

        &.no-image {
            grid-template-areas: "text text"
                                 "spec spec";

            .selfcare-hardware-text {justify-self: center;}
        }
    }

    .selfcare-hardware-text {
        grid-area:    text;
        justify-self: start;

        h3 {
            text-align: left;
            font-size:  1.5em;
        }

        & > .buttons {justify-content: flex-start;}
    }

    .selfcare-hardware-item img {
        max-width: 100%;

        &.fiber     {width: 16em;}
        &.mbb       {width: 8em;}
        &.coax      {width: 18em;}
        &.dsl       {width: 15em;}
        &.ont       {width: 8em;}
        &.wifi-mesh {width: 11em;}
    }

    .selfcare-hardware-specifications.open {
        margin-top: 1.5em;
    }

    .selfcare-hardware-specifications-table {
        tr {
            td, th {border-top: 1px solid $hiper-gray;}

            &:last-child {
                td, th {border-bottom: 1px solid $hiper-gray;}
            }
        }

        th {
            padding: .5em .25em .5em .5em;
            width:   11em;
        }

        td {padding: .5em .5em .5em .25em;}
    }

    // Sub region: Help
    .selfcare-help-articles {
        grid-template-columns: 1fr 1fr;
        grid-template-rows:    auto;
        gap:                   1em;
        margin-top:            .5em;

        a {
            display:         flex;
            justify-content: center;
            align-items:     center;
            padding:         1em;
        }
    }
}

@media (max-width: 960px) {
    // Sub region: Nav
    .selfcare-header-wrapper,
    .selfcare-menu-button-container {height: 3em;}
    .selfcare-header                {gap: .5em;}

    .selfcare-header .pop-up-backdrop {
        top:    3em;
        height: calc(100vh - 3em);
    }

    $p-t: 1.75em;
    $p-b: 4.6em;
    @media (min-width: 601px) {
        $p: 2em;
        .selfcare-header,
        .selfcare-menu-button-container    {padding:   .25em $p;}
        .selfcare-header-address .dropdown {max-width: calc(100vw - calc(2em * 2));}
        .selfcare-menu-items               {padding:   $p-t  $p $p-b;}
    }
    @media (max-width: 600px) and (min-width: 351px) {
        $p: 1.5em;
        .selfcare-header,
        .selfcare-menu-button-container    {padding:   .25em $p;}
        .selfcare-header-address .dropdown {max-width: calc(100vw - calc(1.5em * 2));}
        .selfcare-menu-items               {padding:   $p-t  $p $p-b;}
    }
    @media (max-width: $phone-small) {
        $p: .5em;
        .selfcare-header,
        .selfcare-menu-button-container    {padding:   .25em $p;}
        .selfcare-header-address .dropdown {max-width: calc(100vw - calc(.5em * 2));}
        .selfcare-menu-items               {padding:   $p-t  $p $p-b;}
    }

    .selfcare-menu-button {
        border-radius: 2em;
        cursor:        pointer;
        border:        none;
        font-size:     100%;
        background:    none;

        &:hover {opacity: .9;}

        .icon {
            display:    block;
            flex-shrink: 0;
            width:      2em;
            height:     2em;
            transition: opacity .15s $ease-in-out-quad;
        }

        &.open .icon,
        &.close .icon {
            color: transparent;
            fill:  #ffffff;
        }
    }

    .selfcare-menu {
        position:         absolute;
        top:              0;
        left:             0;
        right:            0;
        bottom:           0;
        width:            100%;
        height:           calc(100vh - 3.75em); // Screen - Nav
        background-color: #ffffff;
        transform:        translateX(100%);
        overflow:         hidden;

        &.menu-open {transform: translateX(0);}
    }

    .selfcare-menu-items {
        flex-direction:  column;
        justify-content: flex-start;
        align-items:     stretch;
        gap:             1em;
        width:           100%;
        // Scrollable on low height
        max-height:      calc(100vh - 3.75em - 3em); // Screen - Nav - Selfcare Nav
        overflow-y:      auto;

        .choose {
            justify-content: flex-start;
            gap:             .5em;
            padding:         .5em;
            border-radius:   .5em;
            box-shadow:      var(--box-shadow);
            text-align:      left;

            .icon {
                width:  2em;
                height: 2em;
                fill:   $hiper-blue;
            }

            .text {font-size: 1.2em;}

            &:hover {
                background-color: $hiper-lightergray;
                box-shadow:       var(--box-shadow-hover);
                color:            $hiper-blue;
            }

            &.active,
            &.active:hover {
                background-color: $hiper-teal;
                color:            #ffffff;

                .icon {fill: #ffffff;}
            }
        }

        .selfcare-menu-sbbu {
            margin-bottom: 1.5em;

            a {
                display:          block;
                width:            100%;
                height:           100%;
                padding:          .8em .6em;
                border-radius:    1.4em;
                border-bottom:    0;
                background-color: $hiper-red;
                text-align:       center;
                line-height:      1.2;
                color:            #ffffff;

                &:hover {
                    background-color: lighten($hiper-red, 5%);
                }
            }
        }

        .choose#selfcare-logout-link {
            margin-top:       1.5em;
            background-color: #ffffff;
            color:            $hiper-red;

            .icon {fill: $hiper-red;}

            &:hover {
                background-color: $hiper-red;
                color:            #ffffff;

                .icon {fill: #ffffff;}
            }
        }
    }

    // Sub region: General
    .selfcare-page {
        padding-top: 3.5em + 1.5em;
    }

    .selfcare-page hr {
        margin:    2em auto;
        max-width: calc(100% - 2em);
    }

    .id-anchor.id-anchor--selfcare {
        transform: translateY(calc(-3.75em - 3em - 2em)); // Nav - Selfcare Nav - Space based on <hr />
    }

    .selfcare-page-content {
        h1, h2 {padding-bottom: .5rem;}
    }

    // Sub region: Login & Communication
    .selfcare-page-login    {padding-top: 2em;}
    .selfcare-communication {max-width:   calc(600px + 1em);} // Based on email width

    // Sub region: Overview
    .selfcare-page-overview {
        margin:    0 auto;
        max-width: 30em;

        .selfcare-overview-messages-container {
            margin-top:    -.5em;
            margin-bottom: 2em;

            h2 {
                padding-bottom: 0;
                font-size:      2em;
            }

            &:only-child,
            &:last-child {margin-bottom: 0;}
            p            {text-align:    center;}
        }

        .selfcare-overview-message {
            margin-bottom: .25em;

            &.no-link,
            & > a {padding: .25em .5em;}
        }
    }

    .selfcare-page-overview .account-container {
        padding: 1em;
    }

    // Sub region: Report Connection

    .selfcare-report-connection {
        & > h2 {padding-bottom: .1em;}
    }

    // Sub region: Hardware
    .selfcare-hardware-item {
        grid-template-areas:   "image"
                               "text"
                               "spec";
        grid-template-columns: 1fr;
        justify-items:         center;
        align-items:           start;
        gap:                   0;
        margin-bottom:         .5em;
        padding:               1em;
    }

    .selfcare-hardware-item img {
        max-width: 80%;

        &.fiber     {width: 14em;}
        &.mbb       {width: 6em;}
        &.coax      {width: 16em;}
        &.dsl       {width: 14em;}
        &.ont       {width: 7em;}
        &.wifi-mesh {width: 10em;}
    }

    .selfcare-hardware-text {
        margin-top: 1em;

        h3           {font-size:       1.4em;}
        & > .buttons {justify-content: center;}

        .specifications-button {
            margin-left:  auto;
            margin-right: auto;
        }
    }

    .selfcare-hardware-specifications.open {
        margin-top: .5em;
    }

    .selfcare-hardware-specifications-table {
        tr {
            th {border-top: 1px solid $hiper-gray;}

            &:first-child th {
                border-top:  0;
                padding-top: 0;
            }

            &:last-child td {
                padding-bottom: 0;
            }
        }

        th, td {
            display: block;
            width:   100%;
        }

        th {padding: .5em .5em 0 .5em;}
        td {padding: 0 .5em .5em .5em;}
    }

    // Sub region: Help
    .selfcare-help-articles {
        grid-template-columns: 100%;
        gap:                   .5em;

        a {
            padding:   .75em .5em;
            max-width: 36em;
        }
    }
}

@media (min-width: 521px) {
    // Sub region: Nav
    .selfcare-header-address.multiple .dropdown {
        $width:         .6rem;
        $border-radius: $width / 2;

        left:          -.4rem;
        width:         calc(100% + .8rem);
        border-radius: $border-radius;

        ul::-webkit-scrollbar             {width:         $width;}
        ul::-webkit-scrollbar-track,
        ul::-webkit-scrollbar-track-piece {border-radius: $border-radius 0 0 $border-radius;}
        ul::-webkit-scrollbar-thumb       {border-radius: $border-radius;}
    }

      // Sub region: General
    .selfcare-popup {
        top:       calc(3.75em + 3.5em + 2em);
        padding:   1.5em 1.5em 1em;
        max-width: calc(100vw - 2em);
        width:     36em;

        .close-popup {
            background-color: transparent;
            border:           none;
        }
    }

    // Sub region: Components
    .pagination {
        grid-template-areas:   "previous current next";
        grid-template-columns: 1fr 1fr 1fr;
        gap:                   1em;

        .previous-page {justify-self: start;}
        .next-page     {justify-self: end;}
    }

    // Sub region: Login
    .selfcare-login-form,
    .selfcare-login-resend {padding:        2em 2em 1.25em;}
    #selfcare-create-input {padding-bottom: 2em;}

    // Sub region: Delivery Flow
    .selfcare-page-delivery-flow .selfcare-page-summary {
        margin-bottom: .75em;
    }

    .selfcare-delivery-flow-status {
        grid-template-columns: calc(2em * .5) auto;
        gap:                   0 1em;
        margin-bottom:         1em;
        padding:               1em 1.5em;

        .icon {font-size: 2em;}

        a {
            margin-top: .25em;
        }
    }

    .selfcare-delivery-flow-timeline .procedure-card {
        margin: 0 .5em;
    }

    .selfcare-delivery-flow-notification-list {
        max-width: calc(100% - 2em);
    }

    .selfcare-notification {
        --icon-size:           calc(4em * 0.625);

        grid-template-areas:   "anchor anchor"
                               "icon head"
                               "icon btns"
                               "icon body";
        gap:                   0 1em;
        grid-template-columns: var(--icon-size) auto;

        &.open .notification-buttons {margin-bottom: .25em;}

        .id-anchor.id-anchor--selfcare-notification {
            transform: translateY(-50vh); // About center of screen
        }

        .notification-icon .icon {
            font-size: var(--icon-size);
        }

        h3.heading.heading--montserrat {
            margin-top: -.15em;
        }
    }

    .selfcare-delivery-flow hr {
        margin:    1.5em auto;
        max-width: calc(50% - 4em);
    }

    .selfcare-delivery-flow-notification-list .selfcare-loader {
        height: 6.1em;
    }

    // Sub region: Report Connection
    .selfcare-popup--report-connection {
        h3 {font-size: 2em;}
        h4 {font-size: 1.1em;}

        &.step-start .buttons button {
            width: 4.75em;
        }

        &.step-form .buttons button {
            width: 7.3em;
        }
    }

    // Sub region: Hardware
    .selfcare-popup--mesh {
        h3             {font-size: 1.4em;}
        .action-box h4 {font-size: 1.2em;}
    }

    // Sub region: Invoice
    .selfcare-invoices-table {
        border-radius: .5em;
        box-shadow:    var(--box-shadow);

        th, td {
            font-size: .8em;
            padding:   .8em .4em;

            &:first-child {padding-left: .8em;}
            &:last-child {padding-right: .8em;}
        }

        tr:nth-child(odd) td {
            background: $hiper-lightergray;
        }

        .selfcare-invoices-table-button {
            justify-content: flex-end;
            gap:             .5em;

            button, a {padding: 0;}

            .icon {
                width:          1.5em;
                height:         1.5em;
                vertical-align: middle;
                fill:           $hiper-blue;
                color:          transparent;
                transition:     transform .15s $ease-in-out-cubic,
                                fill .15s $ease-in-out-cubic;

                &:hover {
                    transform: scale(1.1);
                    fill:      $hiper-red;
                }
            }
        }

        .hide-on-large {display: none;}
    }

    .selfcare-invoice-table {
        thead th,
        tfoot td {
            padding:   .8em .4em;
            font-size: .9em;
        }

        tbody td {
            padding: .6em .4em;
        }

        th, td {font-size: .8em;}
    }

    // Sub region: Communication
    .selfcare-communication-overview {
        a {
            padding: 1em;
            gap:     1em;
        }

        .icon {font-size:  2.5em;}
        .date {margin-top: .25em;}
    }

    // Sub region: Account
    .selfcare-account-table {
        th {
            padding: .8em .5em .8em 1em;
            width:   11em;
        }

        td {padding: .8em 1em .8em .5em;}

        .selfcare-account-editable > .value,
        .selfcare-account-editable > .text {
            padding-right: 2.25em;
        }

        .selfcare-account-edit-link {
            top:    .65em;
            width:  1.5em;
            height: 1.5em;
        }

        .submit-button-flex {
            justify-content: flex-start;
        }
    }
}

@media (max-width: $phone) {
    // Sub region: Nav
    .selfcare-header-address.multiple .dropdown {
        $width:         .8em;
        $border-radius: $width / 2;

        border-radius:  $border-radius;

        ul::-webkit-scrollbar             {width:         $width;}
        ul::-webkit-scrollbar-track,
        ul::-webkit-scrollbar-track-piece {border-radius: $border-radius 0 0 $border-radius;}
        ul::-webkit-scrollbar-thumb       {border-radius: $border-radius;}
    }

    // Sub region: General
    .selfcare-popup {
        top:     calc(3.75em + 3em + 1em);
        padding: 1em .5em .5em;
        width:   calc(100vw - 1em);

        .close-popup {
            background-color: transparent;
            border: none;
        }

        
    }

    .selfcare-menu-items {gap: .5em;}

    // Sub region: Components
    .pagination {
        grid-template-areas:   "current current"
                               "previous next";
        grid-template-columns: 1fr 1fr;
        gap:                   .5em;

        .previous-page,
        .next-page {
            width: 100%;
        }

        .previous-page {justify-self: end;}
        .next-page     {justify-self: start;}
    }

    // Sub region: Login
    .selfcare-login-form,
    .selfcare-login-resend {padding:        1.5em 1em;}
    #selfcare-create-input {padding-bottom: 1.5em;}

    // Sub region: Delivery Flow
    .selfcare-page-delivery-flow .selfcare-page-summary {
        margin-bottom: .5em;
    }

    .selfcare-delivery-flow hr {
        margin:    1.5em auto;
        max-width: calc(80% - 2em);
    }

    .selfcare-delivery-flow-status {
        grid-template-columns: calc(1.5em * .5) auto;
        gap:                   0 .75em;
        margin-bottom:         1em;
        padding:               1em;

        .icon {
            justify-self: center;
            font-size:    1.5em;
        }

        a     {margin-top:   .5em;}
    }

    .selfcare-delivery-flow-timeline {
        li {
            flex:      auto 0 0;
            width:     calc(100vw - 4em);
            min-width: 14em;
            max-width: 18em;
        }

        .procedure-card {margin: 0 .75em;}
    }

    .selfcare-delivery-flow-notification-list {
        max-width: calc(100% - 1.5em);
    }

    .selfcare-notification {
        --icon-size:           calc(3.5em * 0.625);

        grid-template-areas:   "anchor anchor"
                               "icon head"
                               "btns btns"
                               "body body";
        gap:                   0 .5em;
        grid-template-columns: var(--icon-size) auto;
        transition:            padding-bottom .2s $ease-in-out-cubic,
                               grid-template-columns .2s $ease-in-out-cubic;

        .id-anchor.id-anchor--selfcare-notification {
            transform: translateY(-50vh); // About center of screen
        }

        .notification-icon .icon {
            font-size: var(--icon-size);
        }

        .notification-head {
            align-content: center;
        }

        h3.heading.heading--montserrat {
            margin-top: -.25em;
        }
    }

    .selfcare-delivery-flow-notification-list .selfcare-loader {
        height: 6.075em;
    }

    // Sub region: Report Connection
    .selfcare-popup--report-connection {
        h3 {font-size:  1.8em;}

        h4 {
            font-size:  1em;
            text-align: left;
        }

        .buttons {
            flex-direction: column-reverse;

            button {width: 100%;}
        }
    }

    // Sub region: Hardware
    .selfcare-popup--mesh {
        h3             {font-size: 1.2em;}
        .action-box h4 {font-size: 1em;}
    }

    // Sub region: Card
    @media (max-width: 350px) {
        .selfcare-card-container {width: 100%;}
    }

    .selfcare-card {
        p {padding-bottom: .25em;}

        .label {
            display:       block;
            padding-right: 0;
            font-size:     .8em;
            color:         rgba($hiper-blue, .9);
        }

        @media (max-width: 280px) {
            flex-direction: column;
            text-align:     center;

            .icon {font-size: 3em;}
        }
    }

    // Sub region: Invoice
    .selfcare-invoices-table {
        table, thead, tbody, td, tr {
            display: block;
        }

        .hide-on-small {display: none;}

        td {
            min-height:     1em;
            position:       relative;
            padding-top:    0;
            padding-bottom: 0;
            padding-left:   calc(50% + .25em);
            line-height:    1.4;

            &::before {
                @include       font-weight-variable(600);
                display:       block;
                min-height:    1em;
                position:      absolute;
                right:         calc(50% + .25em);
                text-align:    right;
                white-space:   nowrap;
                content:       attr(data-label);
            }
        }

        .selfcare-invoices-table-button {
            justify-content:  center;
            padding:          0;
            height:           2.5em;
            text-align:       center;
            color:            #ffffff;
            background-color: $hiper-teal;

            button, a {
                @include         font-weight-variable(500);
                display:         flex;
                justify-content: center;
                align-items:     center;
                padding:         0 .5em;
                height:          100%;

                .icon {
                    width:  2em;
                    height: 2em;
                    fill:   #ffffff;
                    color:  transparent;
                }
            }

            .selfcare-loader .path {stroke: #ffffff;}
        }

        th {display: none;}

        tr:nth-child(1n+1) {
            margin-bottom: 1em;
            border-radius: .5rem;
            box-shadow:    var(--box-shadow);

            &:first-child {margin-bottom: 0;}

            td:first-child {
                padding-top:   .5em;
                border-radius: .5rem .5rem 0 0;
            }

            td:nth-last-child(2) {padding-bottom: .5em;}

            td:last-child {
                margin-bottom: 0;
                border-radius: 0 0 .5rem .5rem;
            }
        }

        .creditnote {
            td:nth-last-child(3) {
                padding-bottom: .5em;
                border-radius:  0 0 .5rem .5rem;
            }

            td:nth-last-child(2),
            td:last-child {display: none;}
        }
    }

    .selfcare-invoice-table {
        thead th,
        tbody td {padding: .6em .4em;}
        tfoot td {padding: 1em .4em;}

        thead tr,
        tbody tr {
            display:               grid;
            grid-template-columns: auto auto;

            th, td {width: 100%;}

            th:first-child,
            td:first-child {
                grid-row:       1;
                grid-column:    1;
                padding-bottom: .2em;
            }

            th:nth-child(2),
            td:nth-child(2) {
                grid-row:     2;
                grid-column:  1;
                padding-top:  0;
                padding-left: .8rem;

                &:not(:nth-last-child(2)) {
                    padding-bottom: .2em;
                }
            }

            th:nth-child(3):not(:last-child),
            td:nth-child(3):not(:last-child) {
                grid-row:     3;
                grid-column:  1;
                padding-top:  0;
                padding-left: .8rem;
            }

            th:last-child,
            td:last-child {
                grid-row:        span 3;
                grid-column:     2;
                align-self:      center;
                display:         inline-flex;
                justify-content: flex-end;
                align-items:     center;
                height:          100%;
            }
        }

        tfoot tr {
            display:               grid;
            grid-template-columns: 1fr 1fr;

            td {width: 100%;}
        }
    }

    // Sub region: Communication
    .selfcare-communication-overview {
        a {
            padding: .5em;
            gap:     .5em;
        }

        .icon {font-size: 2em;}
    }

    // Sub region: Account
    .selfcare-account-table {
        tr:first-child {
            th:first-child,
            th:only-child {border-radius: .5em .5em 0 0;}
            td:last-child {border-radius: 0;}
        }

        tr:last-child {
            td:last-child,
            td:only-child  {border-radius: 0 0 .5em .5em;}
            th:first-child {border-radius: 0;}
        }

        th, td {
            display: block;
            width:   100%;
        }

        th {padding: .8em 1em 0 1em;}
        td {padding: .25em 1em .8em 1em;}

        .selfcare-account-editable > .value,
        .selfcare-account-editable > .text {
            padding-right: 3em;
        }

        .selfcare-account-edit-link {
            top:    -.8em;
            width:  2em;
            height: 2em;
        }

        .submit-button-flex {
            justify-content: center;
        }
    }
}
// End region: Media Queries

// Hotjar Survey
._hj-widget-container {
    position: relative;
    z-index:  99;
}
