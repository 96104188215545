.usp-container {
    margin:           0;
    width:            100%;
    max-height:       calc(960px + 2em);
    display:          flex;
    flex-direction:   row;
    justify-content:  center;
    align-items:      center;
    gap:              1em 2em;
    background-color: #f7f7f7;

    .usp-content,
    .usp-image {
        max-width: 40em;
        width:     50%;
    }

    .usp-content {
        h2, h3 {
            line-height: 1;
        }

        h2, h3, p, a {
            text-align: left;
        }

        h2 {
            font-size: 1.8em;
        }

        h3 {
            padding-top:    0;
            padding-bottom: .5rem;
            font-size:      4.8em;
        }

        p {
            font-size:  .7em;
            margin-top: 1em;
        }
    }

    .usp-image {
        overflow:     hidden;
        position:     relative;
        aspect-ratio: 5 / 3;

        img, 
        .usp-video  {
            position:  absolute;
            transform: translate(-50%, -50%);
            top:       50%;
            left:      50%;
        }
    }

    @media (max-width: 1300px) and (min-width: 1201px) {
        .usp-image   {width: 40%;}
        .usp-video   {width: 100%;}
        .usp-content {width: 60%;}
    }

    @media (max-width: 1200px) and (min-width: 1101px) {
        .usp-image   {width: 30%;}
        .usp-video   {width: 100%;}
        .usp-content {width: 70%;}
    }

    @media (min-width: 1101px) {
        padding: 2em;
    }

    @media (max-width: 1100px) {
        padding:        2em 1em;
        flex-direction: column;
        max-height:     none;

        .usp-image,
        .usp-content {
            width: 100%;
        }
    }

    @media (max-width: $phone) {

        .usp-video   {width: 100%;}

        .usp-content {
            --fz-multiplier: 1;

            h2 {
                padding-bottom: .5em;
                font-size:      calc(calc(calc(100vw - 2em ) * .0825) * var(--fz-multiplier));
            }

            h3 {
                padding-bottom: 0;
                font-size:      calc(calc(calc(100vw - 2em ) * .165) * var(--fz-multiplier));
            }

            .disclaimer {
                padding-top: .5em;
            }

            .button {
                display:    block;
                width:      fit-content;
                margin:     0 auto;
                text-align: center;
                font-size:  1.2em;
            }
        }
    }
}

.usp-campaign-container {
    margin: 0 auto;

    .image {
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top:      50%;
            left:     50%;
        }
    }

    @media (min-width: 1201px) {
        max-width: calc(calc(750px * 2) + 6em);

        .usp-content {
            display:         flex;
            flex-direction:  row;
            justify-content: center;
            align-items:     center;
            gap:             2em;
            margin:          1em 0 2em;
        }

        .image, .text {
            flex: 0 0 50%;
        }

        .image {
            aspect-ratio: 5 / 3;

            img {transform: translate(-50%, -50%);}
        }

        .text p {font-size: .9em;}
    }

    @media (max-width: 1200px) {
        max-width: calc(750px + 4em);

        .usp-content {margin-bottom: 1em;}

        .image {
            margin:       1em 0;
            aspect-ratio: 2 / 1;

            .mvfp     {transform: translate(-50%, -47.5%);}
            .trophies {transform: translate(-50%, -60%);}
        }
    }

    @media (min-width: 521px)  {padding: 2em 2em 0;}
    @media (max-width: $phone) {padding: 2em .5em;}
}


.tp-best {
    position:         fixed;
    z-index:          99;
    bottom:           .7em;
    left:             0px;
    padding:          .5em;
    background-color: #ffffff;
    box-shadow:       var(--box-shadow);
    color:            #000000;
    text-align:       left;

    h1, h2 {
        font-family:    $font-montserrat;
        text-transform: none;
        text-align:     left;
        letter-spacing: normal;
    }

    h1 {@include font-weight-variable(600);}
    h2 {@include font-weight-variable(400);}

    .tp-img {display: block;}

    @media (min-width: 521px)  {
        padding:       .5em;
        border-radius: 0 1.5em 1.5em 0;

        h1 {font-size: .8em;}
        h2 {font-size: .55em;}

        .tp-img {width: 4em;}
    }

    @media (max-width: $phone) {
        
        
        padding:       .25em .75em .5em .5em;
        border-radius: 0 1em 1em 0;

    
        h1 {font-size: .6em;}

        h2 {
            font-size: .6em;          
        }
        
        .tp-img {width: 3em;}
    }
}
