.call-me {
    .content {
        position: relative;
    }

    h2, h3, p, a {
        padding:    0;
        text-align: center;
        hyphens:    manual;
    }

    h2 {
        font-size: 2em;
    }

    h3 {
        font-family:    $font-montserrat;
        font-size:      1em;
        text-transform: none;
        line-height:    1.6;
        letter-spacing: normal;
    }

    h3, p {
        width:       100%;
        font-size:   1.2em;
        line-height: 1.4;
        text-align:  center;
    }

    .no-wrap {
        white-space: nowrap;
    }

    .grid {
        gap:   .5em;
        width: fit-content;
    }

    .item {
        input:not(.continue-button-submit),
        .icon {
            box-shadow: var(--box-shadow);
            transition: box-shadow .2s $ease-in-out-cubic;

            &:hover {box-shadow: var(--box-shadow-hover);}
        }

        input:not(.continue-button-submit) {
            min-width: 10em;

            &.error {
                box-shadow: 0 0 0 2px $hiper-red,
                            var(--box-shadow);
            }

            &:hover.error {
                box-shadow: 0 0 0 2px $hiper-red,
                            var(--box-shadow-hover);
            }
        }

        .icon {
            width:         2.5em;
            height:        2.5em;
            border-radius: 50%;
        }
    }

    .heading {
        padding-bottom: .25em;
        line-height:    1;
    }

    .form__not-sent-overlay,
    .form__sent-overlay,
    .form__sending-overlay {
        p, h4 {
            margin:  0;
            padding: 0;
        }

        p {
            max-width: unset;
            font-size: 1em;
        }
    }

    .closed {
        padding: .25em 0;
    }

    @media (min-width: 521px) {
        .hide-on-large-devices {
            display: none;
        }

        form {margin-top: .5em;}

        .form__not-sent-overlay,
        .form__sent-overlay,
        .form__sending-overlay {
            padding: .5em 1em;
        }
    }

    @media (max-width: $phone) {
        .hide-on-small-devices {
            display: none;
        }

        h2 {font-size: 2.2em;}

        .form__not-sent-overlay,
        .form__sent-overlay,
        .form__sending-overlay {
            padding: .5em;
        }
    }
}

.call-me-inline {
    $background-color: $hiper-teal;

    background-color:  $background-color;

    .content {
        padding: 2em 1em;
    }

    h2, h3, p, a {
        color: #ffffff;
    }

    .call-now {
        a {
            background-color: $hiper-red;
            border:           none;
            position:         relative;

            & > span > span:not(:last-child) {
                margin-right: .175em;
            }
        }
    }

    form {
        margin: .4em 0;
    }

    form + p {
        margin-top: 1em;
        font-size:  .9em;
    }

    .item input:not(.continue-button-submit) {
        border: none;
    }

    .form__not-sent-overlay,
    .form__sent-overlay,
    .form__sending-overlay {
        background-color: $background-color;
        color:            white;
    }

    .hero-container + & {
        $background-color: $hiper-darkteal;

        background-color:  $background-color;

        .form__not-sent-overlay,
        .form__sent-overlay,
        .form__sending-overlay {
            background-color: $background-color;
        }
    }

    @media (min-width: 521px) {
        .call-now {
            margin: .5em 0;

            h3 {
                @include   font-weight-variable(600);
                font-size: 1.4em;
            }

            a {
                @include       font-weight-variable(600);
                padding:       .15em .25em;
                border-radius: .5em;

                &:hover {
                    color:            #ffffff;
                    background-color: $hiper-darkred;
                }
            }
        }
    }

    @media (max-width: $phone) {
        .call-now {
            margin: .25em 0 .5em;

            h3 {
                line-height: 1;
            }

            a {
                display:         flex;
                justify-content: center;
                align-items:     center;
                padding:         .1em .1em .1em .4em;
                border-radius:   1em;
                font-family:     $font-cervo;
                font-size:       1.6em;
                font-weight:     300;

                & > span:first-child {
                    padding-top:    .15em;
                    letter-spacing: .05em;
                }

                .icon {
                    width:     1.25em;
                    height:    1.25em;
                    color:     transparent;
                    fill:      #ffffff;
                    transform: scaleX(-1);
                    animation: ring-move 2s $ease-in-out-cubic infinite;

                    svg {
                        animation: ring-shake 2s $ease-in-out-cubic infinite;
                    }
                }

                @keyframes ring-move {
                    0% {transform: scaleX(-1) translateY(.1em);}
                    50% {transform: scaleX(-1) translateY(-.1em);}
                    100% {transform: scaleX(-1) translateY(.1em);}
                }

                $shake-amount: 10deg;

                @keyframes ring-shake {
                    0% {transform: rotate(0deg);}
                    25% {transform: rotate(0deg);}
                    30% {transform: rotate(-$shake-amount);}
                    35% {transform: rotate($shake-amount);}
                    40% {transform: rotate(-$shake-amount);}
                    45% {transform: rotate($shake-amount);}
                    50% {transform: rotate(-$shake-amount);}
                    55% {transform: rotate($shake-amount);}
                    60% {transform: rotate(-$shake-amount);}
                    65% {transform: rotate($shake-amount);}
                    70% {transform: rotate(-$shake-amount);}
                    75% {transform: rotate(0deg);}
                    100% {transform: rotate(0deg);}
                }
            }
        }
    }
}

.call-me-exit-rmo,
.call-me-dawa-down{
    $border-radius:    1em;
    $background-color: #ffffff;

    position:          fixed;
    z-index:           $z-index-call-me;
    max-width:         calc(100% - 1em);
    border-radius:     $border-radius;
    background-color:  $background-color;

    .close-float {
        padding:          0;
        position:         absolute;
        top:              .25em;
        right:            .25em;
        z-index:          7;
        display:          flex;
        font-size:        1em;
        background-color: transparent;
        border:           none;
        cursor:           pointer;

        .icon {
            width:  1.8em;
            height: 1.8em;
            color:  transparent;
            fill:   $hiper-blue;
        }
    }

    .item input:not(.continue-button-submit),
    .item .icon {
        box-shadow: var(--box-shadow-small);
    }

    .form__not-sent-overlay,
    .form__sent-overlay,
    .form__sending-overlay {
        z-index:          7;
        border-radius:    $border-radius;
        background-color: $background-color;
    }
}

.call-me-exit-rmo {
    bottom:     0;
    transform:  translate(-50%, 100%);
    box-shadow: 0 0 0 2px $hiper-red,
                var(--box-shadow);
    transition: transform .3s $ease-in-out-cubic,
                box-shadow .3s $ease-in-out-cubic;

    form {margin: .5em 0 .25em;}

    @at-root .exit-rmo-hide & {
        box-shadow: 0 0 0 $hiper-red,
                    0 0 0 rgba(#000000, 0);
    }

    @media (min-width: 751px) {
        right: 0;

        @at-root .exit-rmo-hide & {
            transform: translate(100%, -120px);
        }

        @at-root .exit-rmo-show & {
            transform: translate(-20px, -120px);
        }
    }

    @media (max-width: 750px) {
        left:  50%;
        width: 100%;

        @at-root .exit-rmo-hide & {
            transform: translate(-50%, 100%);
        }

        @at-root .exit-rmo-show & {
            transform: translate(-50%, -120px);
        }

        h2 {padding: 0 .25em;}
    }
}

.call-me-dawa-down {
    top:       50%;
    left:      50%;
    transform: translate(-50%, -50%);
    width:     32em;

    .flex {
        h2 {
            @include font-weight-variable(600);
            color:     $hiper-blue;
        }

        p {font-size: 1em;}
    }

    form {margin: .5em 0 0;}

    @media (min-width: 521px) {
        padding: 1.5em 2em;

        .flex h2 {
            padding:   0 0 .25em;
            font-size: 1.25em;
        }
    }

    @media (max-width: $phone) {
        padding: 1em;

        .flex h2 {
            padding:   0 1em .25em;
            font-size: 1.2em;
        }
    }
}

.rto {
    &.rto--box {
        &.page-home {
            background-color: $hiper-darkteal;

            .content {box-shadow: var(--box-shadow);}
        }

        .content {
            border-radius:    1em;
            background-color: #ffffff;
            box-shadow:       inset 0 0 0 4px $hiper-teal,
                              var(--box-shadow);
        }

        input:not(.continue-button-submit) {
            box-shadow: inset 0 0 0 1px $hiper-darkgray,
                        var(--box-shadow);

            &.error {
                box-shadow: inset 0 0 0 1px $hiper-darkgray,
                            0 0 0 2px $hiper-red,
                            var(--box-shadow);
            }

            &:hover {
                box-shadow: inset 0 0 0 1px $hiper-darkgray,
                            var(--box-shadow-hover);

                &.error {
                    box-shadow: inset 0 0 0 1px $hiper-darkgray,
                                0 0 0 2px $hiper-red,
                                var(--box-shadow-hover);
                }
            }
        }

        .form__not-sent-overlay,
        .form__sent-overlay,
        .form__sending-overlay {
            background-color: #ffffff;
            color:            $hiper-blue;
            border-radius:    1em;
            box-shadow:       inset 0 0 0 4px $hiper-teal;
        }
    }

    &.rto--section {
        background-color: $hiper-teal;

        h2, p {color: #ffffff;}

        .form__not-sent-overlay,
        .form__sent-overlay,
        .form__sending-overlay {
            background-color: $hiper-teal;
            color:            #ffffff;
        }
    }

    .content {
        display:         flex;
        flex-direction:  column;
        justify-content: center;
        align-items:     center;
    }

    .heading {
        padding:     0;
        line-height: 1.2;
    }

    .call-button {
        @include          font-weight-variable(600);
        display:          flex;
        justify-content:  center;
        align-items:      center;
        padding:          .2em .4em .2em .75em;
        border-radius:    1em;
        border:           none;
        background-color: $hiper-red;
        box-shadow:       var(--box-shadow);
        color:            #ffffff;
        transition:       box-shadow .2s $ease-in-out-cubic;

        &:hover {
            box-shadow: var(--box-shadow-hover);
        }

        & > span > span:not(:last-child) {
            margin-right: .175em;
        }

        .icon {
            width:     1.5em;
            height:    1.5em;
            color:     transparent;
            fill:      #ffffff;
            transform: scaleX(-1);
            animation: ring-move 2s $ease-in-out-cubic infinite;

            svg {
                animation: ring-shake 2s $ease-in-out-cubic infinite;
            }
        }

        @keyframes ring-move {
            0% {transform: scaleX(-1) translateY(.1em);}
            50% {transform: scaleX(-1) translateY(-.1em);}
            100% {transform: scaleX(-1) translateY(.1em);}
        }

        $shake-amount: 10deg;

        @keyframes ring-shake {
            0% {transform: rotate(0deg);}
            25% {transform: rotate(0deg);}
            30% {transform: rotate(-$shake-amount);}
            35% {transform: rotate($shake-amount);}
            40% {transform: rotate(-$shake-amount);}
            45% {transform: rotate($shake-amount);}
            50% {transform: rotate(-$shake-amount);}
            55% {transform: rotate($shake-amount);}
            60% {transform: rotate(-$shake-amount);}
            65% {transform: rotate($shake-amount);}
            70% {transform: rotate(-$shake-amount);}
            75% {transform: rotate(0deg);}
            100% {transform: rotate(0deg);}
        }
    }

    form {margin: 0 0 .4em;}

    .item input:not(.continue-button-submit) {
        border: none;
    }

    &.rto--contact {
        form {
            margin: .5em 0 0;

            input, input::placeholder {
                text-align: left;
            }

            .grid {
                gap: .5em;
            }

            .item input:not(.continue-button-submit) {
                border: 1px solid $hiper-darkgray;
            }

            .item .icon {
                width:  2.5em;
                height: 2.5em;
            }
        }
    }

    @media (min-width: 521px) {
        &.rto--box {
            padding: 1em;

            &.page-home {padding: 1em 2em 2em;}

            &.open   .content {padding: 1.5em 2em 1em;}
            &.closed .content {padding: 1.5em 2em;}
        }

        &.rto--mbb {
            padding: 2em 1em;
        }

        &.rto--section .content {
            padding: 2em;
        }

        &.page-order {
            padding: 0 1em 2em;
        }

        &.open   .content {gap: 1em;}
        &.closed .content {gap: .5em;}

        .content {
            width: fit-content;
        }

        h2, .call-button {font-size: 1.4em;}
        p                {font-size: 1em;}

        &.rto--contact .call-button {font-size: 1.2em;}

        .call-button.hide-on-large-devices {display: none}
    }

    @media (max-width: $phone) {
        &.rto--box {
            padding: .5em;

            &.page-home {padding: 1em 1em 2em;}

            .content {
                padding: 1.25em calc(.5em + 4px) 1em;
            }
        }

        &.rto--mbb {
            padding: 1em .5em;
        }

        &.rto--section .content {
            padding: 1em .5em;
        }

        &.rto--contact {
            .grid, .item {
                width: 100%;
            }

            .item:last-child {
                width: 2.5em;
            }
        }

        &.page-order {
            padding: 0 .5em 1em;
        }

        .content {
            gap:     .5em;
            width:   fit-content;
        }

        h2, .call-button {font-size: 1.25em;}
        p                {font-size: .9em;}

        &.rto--contact .call-button {font-size: 1.1em;}

        .call-button.hide-on-small-devices {display: none}
    }
}

#dawa-down-overlay {
    position:         fixed;
    z-index:          1;
    top:              0;
    left:             0;
    right:            0;
    bottom:           0;
    width:            100%;
    height:           100%;
    background-color: rgba(#000000, .5);
    pointer-events:   none;
}
