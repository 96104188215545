// region: product page

.page-products {
    .products-hero {
        padding: 2em 0 1em;

        h1 {
            font-weight: 300;
            hyphens:     manual;
        }

        p {text-align: center;}
    }

    .subheader{
        p {text-align: center;}
    }

    .faq .accordion-container + .blurb .address-button {
        box-shadow: var(--box-shadow);

        &:hover {box-shadow: var(--box-shadow-hover);}
    }
}

// region: Product cards

.products-head {
    padding:          2em 1em;
    background-color: $hiper-lightergray;

    h2, p {
        color:      $hiper-blue;
        text-align: center;
    }
}

.products-wrapper {
    .page-home & {
        background-color: $hiper-lightergray;
    }

    .products {
        $scroll-start:     19px * 68;
        $scroll-stop:      $scroll-start + 1px;
        $border-radius:    1em;

        --padding:         1rem;
        --border-radius:   1rem;
        --font-size-price: 5em;

        @media (min-width: $scroll-stop) {
            justify-content:  center;
        }

        @media (max-width: $scroll-start) {
            justify-content:  flex-start;
            overflow-x:       auto;
            scroll-snap-type: x mandatory;
            padding-bottom:   .5em;

            .product-list-item {
                scroll-snap-align: center;
                scroll-snap-stop:  normal;
            }
        }

        padding:           0 1em;
        display:           flex;
        flex-direction:    row;

        @media (min-width: 521px)  {gap: 2em;}
        @media (max-width: $phone) {gap: 1em;}

        .discount {color: $hiper-green;}

        .product-list-item {
            width: 20em;

            &.product-list-item--discount {
                .product-list-link {
                    box-shadow: 0 0 0 4px $hiper-green,
                                var(--box-shadow);

                    &:hover {
                        box-shadow: 0 0 0 4px $hiper-green,
                                    var(--box-shadow-hover-inverse);
                    }
                }

                .product-list-pre-link-wrap .pre-product {
                    background-color: $hiper-green;
                }
            }

            .product-list-pre-link-wrap {
                line-height: 1.2;
                min-height:  1.6em;

                @media (min-width: 751px) {bottom: -1px;}

                .pre-product {
                    display:          block;
                    margin:           0 auto;
                    padding:          .25em .8em;
                    width:            fit-content;
                    background-color: $hiper-teal;
                    font-size:        .9em;
                }
            }

            .product-list-card {
                overflow: hidden;
                width:    100%;
            }

            .product-list-heading {
                padding:   1rem 0 0;
                font-size: 1.6em;
            }

            .product-list-middle {
                flex-direction:  row;
                align-items:     center;
                justify-content: flex-start;
                gap:             1em;
                padding:         var(--padding);
                min-height:      8em;
                overflow:        hidden;
            }

            .speed,
            .price {
                font-weight: 300;

                &.discount {font-weight: 400;}
            }

            .product-list-price-container {
                z-index: 1;

                .pre-price,
                .post-price,
                .min-price {
                    line-height: 1.25;
                }

                .pre-price,
                .post-price {
                    font-size: .9em;
                    padding:   0;
                }

                .post-price {padding-top: .5em;}
                .min-price  {font-size:   .75em}
            }

            .product-list-price {
                justify-content: flex-start;
                padding:         0;
                height:          calc(calc(var(--font-size-price) / 7) * 6);
                text-align:      left;
            }

            .product-list-image {
                position:  relative;
                flex-grow: 1;
                height:    8em;

                img {
                    position:   absolute;
                    top:        50%;
                    left:       50%;
                    transform:  translate(-50%, -50%);
                    max-width:  unset;
                    height:     100%;
                }

                .mbb-stamp {
                    position:         absolute;
                    top:              50%;
                    right:            25%;
                    transform:        translate(50%, -50%) rotate(7.5deg);
                    display:          flex;
                    justify-content:  center;
                    align-items:      center;
                    width:            2em;
                    height:           2em;
                    border-radius:    1em;
                    font-size:        1.6em;
                    background-color: $hiper-blue;
                    box-shadow:       var(--box-shadow);

                    span {
                        @include font-weight-variable(600);
                        color:   #ffffff;
                    }
                }
            }

            &.fiber .product-list-image img {
                left:   60%;
                height: 8em;
            }

            &.mbb .product-list-image img,
            &.coax .product-list-image img {
                height: 10em;
            }

            .product-list-tech {
                justify-content: center;
            }

            &.mbb .product-list-tech {
                justify-content: space-between;
            }

            .product-list-button {
                @include          font-weight-variable(500);
                padding:          .6em 1em;
                background-color: $hiper-teal;
                font-family:      $font-montserrat;
                font-size:        1em;
                text-transform:   none;
                letter-spacing:   normal;
            }

            .product-list-link:hover .product-list-button {
                background-color: $hiper-darkteal;
            }

            @media (max-width: 750px) {
                width:     100%;
                max-width: 20em;

                // 1em padding + 20em product width + 1em gap + 1em of next product = 23 (* 16px font-size)
                @media (max-width: calc(16px * 23)) {
                    width:     calc(100vw - 3em);
                    max-width: none;
                }

                .product-list-card   {width:      100%;}
                .product-list-middle {min-height: 12em;}

                .product-list-image .mbb-stamp {
                    right:     -.5rem;
                    transform: translateY(-50%) rotate(7.5deg);
                    font-size: 2em;
                }

                &.mbb .product-list-image,
                &.coax .product-list-image {
                    height: 10em;

                    img {height: 11em;}
                }
            }
        }
    }

    .products-showcase {
        --price-size: 6;

        @media (min-width: 1401px) {--space: 8em;}
        @media (max-width: 1400px) {--space: 4em;}

        @media (min-width: 961px) {
            --padding: 2em;

            @media (min-width: 1601px) {--padding: 3em;}
        }

        @media (max-width: 960px)  {--padding:    1em;}
        @media (max-width: $phone) {--price-size: 8;}

        max-width: 70em;
        margin:    0 auto;

        .product {padding-bottom: 0;}

        .product-box,
        .product-details {
            display:     flex;
            align-items: center;
        }

        .product-box,
        .image {
            border-radius: 1em;
            box-shadow:    var(--box-shadow);
        }

        .product-box {
            padding:          var(--padding);
            background-color: #ffffff;
        }

        .product-text {
            .post-heading,
            .pre-products-details {
                @include font-weight-variable(600);
            }

            .post-heading {font-size: 1.2em;}

            .pre-products-details {
                padding-bottom: 0;
                font-size:      1.4em;
            }
        }

        .product-details {
            gap:            2em;
            padding-bottom: 1em;
        }

        .price-details {
            p {
                padding-bottom: 0;
                line-height:    1.4;
            }

            .price-container {
                font-size:   calc(1em * var(--price-size));
                line-height: .8;
                white-space: nowrap;
            }

            .price {
                font-family:    $font-cervo;
                text-transform: uppercase;
                font-weight:    400;
                line-height:    1.1;
                letter-spacing: var(--letter-spacing-big);
                color:          $hiper-green;
            }

            .comma-dash {
                margin-right:   -.2em;
                letter-spacing: -.125em;
            }

            .md {font-size: calc(1em / var(--price-size));}
        }

        .product-list-usp {
            margin:  0;
            padding: 0;
        }

        .product-list-usp-item {
            justify-content: flex-start;
        }

        .button {
            display:   block;
            max-width: 100%;
            width:     10em;
        }

        .image {
            background-size:     cover;
            background-repeat:   no-repeat;
            background-position: center center;
        }

        .mbb .image {
            background-image: imgurl('pages/home/product-showcase-5g.jpg');
        }

        .fiber .image {
            background-image: imgurl('pages/home/product-showcase-fiber.jpg');
        }

        @media (min-width: 961px) {
            padding: 0 2em;

            .product {
                position:      relative;
                margin-bottom: var(--space);

                &:last-child {margin-bottom: 0;}

                .image {
                    position:     absolute;
                    right:        0;
                    aspect-ratio: 4 / 3;
                }

                &.alt {
                    .product-box {
                        justify-content: flex-end;
                        margin-left:     auto;
                    }

                    .image {
                        right: unset;
                        left:  0;
                    }
                }
            }

            .product-box,
            .product-details {justify-content: flex-start;}

            .product-box  {width: 83.25%;}
            .product-text {width: calc(75% * .9);}

            .price-details {
                .time      {font-size: .9em;}
                .min-price {font-size: .8em;}
            }

            @media (min-width: 1401px) {
                .product {
                    .image {
                        bottom: calc(var(--padding) * -1);
                        width:  calc(55% - var(--padding));
                    }

                    &.alt .image {
                        top:    calc(var(--padding) * -1);
                        bottom: unset;
                    }
                }

                .product-text {width: 50%;}
            }

            @media (max-width: 1400px) {
                .product {
                    .image {
                        top:       50%;
                        transform: translateY(-50%);
                        width:     calc(45% - var(--padding));
                    }
                }
            }
        }

        @media (max-width: 960px) {
            --image-offset: 5em;
            --padding-ver:  var(--padding);
            --padding-hor:  var(--padding);

            @media (min-width: 521px) {
                --padding-hor: calc(var(--padding) * 2);
            }

            padding: 0 1em;

            .product {
                margin-top:    calc(var(--image-offset) * -1);
                margin-bottom: 2em;
                width:         100%;
                max-width:     40em;

                &:last-child {margin-bottom: 0;}
            }

            .product-box {
                justify-content: center;
                padding:         calc(calc(var(--padding-ver) * 1.5) + var(--image-offset))
                                 var(--padding-hor)
                                 var(--padding-ver);
            }

            .price-details .min-price {font-size: .8em;}

            .button {margin: 0 auto;}

            .image {
                position:     relative;
                bottom:       calc(var(--image-offset) * -1);
                margin:       0 auto;
                max-width:    36em;
                width:        calc(100% - calc(var(--padding-hor) * 2));
                aspect-ratio: 16 / 9;
            }

            @media (max-width: $phone) {
                .product-box,
                .product-details {
                    justify-content: center;
                }

                h3, p {
                    text-align: center;
                    padding-bottom: 0;
                }

                .product-details  {gap: 1em;}
                .product-list-usp {display: none;}
            }
        }
    }

    .conditions {
        font-size:  .7em;
        text-align: center;
        color:      rgba(#000000, .75);
        max-width:  80em;
    }
}

// region: ab-test optimize

/* .js example
const elem = document.getElementById('ab-test-container');
elem.classList.remove("ab-test-container-a");
elem.classList.add("ab-test-container-b");
*/

.ab-test-container {
    &.ab-test-container-a {
        .ab-test-object-b {
            display: none;
        }
    }

    &.ab-test-container-b {
        .ab-test-object-a {
            display: none;
        }
    }

    &.ab-test-container-c {
        .ab-test-object-d {
            display: none;
        }
    }

    &.ab-test-container-d{
        .ab-test-object-c {
            display: none;
        }
    }
}

// end region: ab-test optimize

// start region: product pages

.hero-container.hero-container--products {
    gap:              0;
    background-color: $hiper-lightergray;

    .conditions {
        font-size:  .7em;
        color:      rgba(#000000, .75);
        max-width:  80em;
        margin-top: 1em;
    }

    .hero-background {
        @media (max-width: 920px) {
            .stamp {display: none;}
        }

        @media (max-width: $phone) and (min-width: 351px) {
            right: 1em;

            img {max-height: 100%;}
        }
    }

    .hero-content {
        h1, h2, p {
            color: $hiper-blue;
        }

        h1 {
            .header-tech {
                font-size:      5em;
                font-weight:    300;
                margin-left:    -0.05em;
                padding-bottom: .1em;
            }

            .header-sub {
                font-size:      1.5em;
                margin-left:    -0.1em;
                font-family:    $font-montserrat;
                text-transform: none;
                padding-bottom: 1em;
                line-height:    1.2;
                font-weight:    400;
            }

            .no-wrap {white-space: nowrap;}
        }

        ul {
            @media (max-width: 400px) {
                font-size: .9em;
            }

            li {
                position: relative;
                padding:  0 0 .5em 1.5em;

                &:last-child {
                    padding-bottom: 0;
                }

                .icon {
                    position: absolute;
                    left:     -.25em;
                    color:    transparent;
                    fill:     $hiper-blue;

                    &, svg {
                        width:  1.5em;
                        height: 1.5em;
                    }
                }
            }
        }

        h2 {
            text-align:     left;
            text-transform: none;
            padding-top:    .8rem;

            .before-after {
                font-size:   .45em;
                font-family: $font-montserrat;
            }
        }
    }

    &.hero-container--products-coax .hero-content h1 .header-tech {
        font-size: 4em;
    }

    @media (max-width: $phone) {
        &.hero-container--products-coax .hero-content h1 .header-tech {
            font-size: 14.5vw;
        }

        &.hero-container--products-dsl .hero-content h1 .header-tech {
            font-size: 20vw;
        }
    }

    .address-wrap {
        h2 {
            color: $hiper-blue;
        }

        .scroll-down-arrow::before,
        .scroll-down-arrow::after {
            background-color: $hiper-blue;
        }

        @media (min-width: 521px) {
            .scroll-down-arrow::before,
            .scroll-down-arrow::after {
                height: 1px;
            }

            .scroll-down-arrow::after {
                transform: translate(11px, 18px) rotate(-45deg);
            }
        }
    }

    &.hero-container--products-fiber {
        .hero-background {
            max-width: 496px;

            @media (min-width: 1201px) {
                width:     24em;
                left:      50%;
                bottom:    6.8em;
                transform: translateX(3em);

                .stamp {
                    top:       20%;
                    left:      0;
                    transform: translateY(-50%);
                }
            }

            @media (max-width: 1200px) and (min-width: 921px) {
                width:  24em;
                left:   50%;
                bottom: 6.8em;

                .stamp {
                    top:   0;
                    right: 0;
                }
            }

            @media (max-width: 920px) and (min-width: 521px) {
                width:     500px;
                left:      50%;
                bottom:    6.6em;
                transform: translateX(-10%);
            }

            @media (max-width: $phone) and (min-width: 351px) {
                display: none;
            }

            @media (max-width: $phone-small) {
                display: none;
            }
        }
    }

    &.hero-container--products-coax {
        .hero-background {
            max-width: 422px;

            @media (min-width: 921px) {
                width:     18em;
                left:      75%;
                bottom:    5em;
                transform: translateX(-50%);

                .stamp {
                    top:       50%;
                    right:     0;
                    transform: translateY(-50%);
                }
            }

            @media (max-width: 920px) and (min-width: 521px) {
                width:     20em;
                left:      75%;
                bottom:    2em;
                transform: translateX(calc(-50% + 2em));
            }

            @media (max-width: 760px) and (min-width: 521px) {
                width:  16em;
                bottom: 6em;
            }

            @media (max-width: $phone) and (min-width: 351px) {
                display: none;
            }

            @media (max-width: $phone-small) {
                display: none;
            }
        }
    }

    &.hero-container--products-dsl {
        .hero-background {
            max-width: 496px;

            @media (min-width: 1201px) {
                width:     18em;
                left:      50%;
                bottom:    5em;
                transform: translateX(9em);

                .stamp {
                    top:       40%;
                    right:     -2em;
                    transform: translateY(-50%);
                }
            }

            @media (max-width: 1200px) and (min-width: 921px) {
                width:     16em;
                left:      50%;
                bottom:    5.5em;
                transform: translateX(8em);

                .stamp {
                    top:       45%;
                    right:     -2em;
                    transform: translateY(-50%);
                }
            }

            @media (max-width: 920px) and (min-width: 521px) {
                width:  19em;
                left:   50%;
                bottom: 4.5em;
            }

            @media (max-width: $phone) and (min-width: 351px) {
                height: 8em;
                bottom: 7em;
            }

            @media (max-width: $phone-small) {
                display: none;
            }
        }
    }
}

.page-products-fiber,
.page-products-coax,
.page-products-dsl {
    .blurb {
        max-width: none;
        margin:    0;
    }

    .heading {
        hyphens: manual;
    }

    .grid {
        gap: 1em 2em;

        li {
            margin: 0;
        }

        @media (min-width: 661px) {
            &:not(.grid-triple-with-icons) li {
                width: 50%;
            }
        }

        @media (max-width: 660px) {
            align-items: center;

            li {
                width: 100%;
            }
        }
    }

    .hero-container + .section-white,
    .section-reasons {
        .content {
            padding-bottom: 2em;
        }
    }

    .hero-container + .section-white,
    .section-includes,
    .section-coverage {
        .grid li {
            padding: 0;
        }
    }

    .section-reasons {
        .grid li {
            padding:       1em .8em;
            border-radius: 1em;
            text-align:    center;
            background:    #ffffff;
            box-shadow:    0px 6px 18px -4px rgba(#000000, .2),
                           0px 2px 32px -12px rgba(#000000, .2);

            &::before {
                background: $hiper-blue;
                color:      #ffffff;
            }
        }
    }

    .section-includes {
        .grid {
            &:first-of-type {
                margin-bottom: 2em;
            }

            li {
                &:first-child {
                    display:          flex;
                    justify-content:  center;
                    align-items:      center;
                    background-color: $hiper-lightergray;
                    padding:          1em;
                    border-radius:    1em;
                }

                &:nth-child(2) {
                    align-self: center;
                }
            }
        }
    }

    .accordion-container {
        .button {
            display:       block;
            margin-bottom: .7em;
            width:         fit-content;

            &:not(:first-child) {
                margin-top: -.35em;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.page-products-fiber,
.page-products-dsl {
    .section-includes .grid:first-of-type li:first-child {
        padding-top:    0;
        padding-bottom: 0;
    }
}

.page-products-wifi-mesh {
    .hero-container {
        border-radius: 1em;
        box-shadow:    inset var(--box-shadow);
        background:    radial-gradient(
                           ellipse farthest-corner at center,
                           lighten(desaturate($hiper-teal, 10%), 10%) 0%,
                           $hiper-teal 100%
                       );

        .hero-content {
            display:               grid;
            grid-template-columns: 1.5fr 1fr;
            grid-template-areas:   "h1 img"
                                   "h2 img";
            margin:                0;
            padding:               0;
            align-items:           center;

            h1, h2 {
                hyphens: manual;
            }

            h1 {
                grid-area:   h1;
                line-height: 1;
            }

            h2     {grid-area: h2;}
            .image {grid-area: img;}
        }

        @media (min-width: 801px) {
            margin:  1em auto 0;
            padding: 4em 2em;
            width:   calc(100% - 2em);

            .hero-content {
                gap: 0 1em;

                h1 {
                    align-self: end;
                    font-size:  3em;

                    strong {font-size: 1.6em;}
                }

                h2 {
                    align-self: start;
                }

                .image {
                    max-width: 18em;
                }
            }
        }

        @media (max-width: 800px) {
            margin:   .5em auto 0;
            padding:  4em 1em;
            width:    calc(100% - 1em);
            overflow: hidden;

            .hero-content {
                gap: .5em;

                h1 {
                    font-size: 2.4em;

                    strong {font-size: 2em;}
                }

                .image {
                    max-width: 12em;
                    width:     200%;
                }
            }
        }

        @media (max-width: $phone) {
            padding: 2em .75em 1em;

            .hide-on-small-devices {
                display: none;
            }

            .hero-content {
                grid-template-columns: 2fr 1fr;
                grid-template-areas:   "h1 img"
                                       "h2 h2";

                h1 {
                    font-size: 11vw;

                    strong {font-size: 1.6em;}
                }

                h2 {font-size: 1em;}

                .image {
                    justify-self: center;
                    max-width:    7em;
                }
            }
        }
    }

    .section-lightgray {
        background-color: $hiper-lightgray;
    }

    .section-get,
    .section-how,
    .section-tips,
    .section-faq,
    .section-cta {
        padding-bottom: 1em;
    }

    .section-get {
        .address-widget-wrap {
            margin-bottom: .7em;
        }
    }

    .section-reasons {
        padding-bottom: 1.5em;

        .content {
            max-width: none;
        }

        ul {
            display:         flex;
            flex-wrap:       wrap;
            justify-content: center;
            justify-content: center;
            align-items:     stretch;
            gap:             1.5em;

            li {
                flex:             unset;
                margin:           0;
                padding:          1em;
                max-width:        20em;
                border-radius:    1em;
                box-shadow:       var(--box-shadow);
                background-color: #ffffff;

                .blurb {
                    padding: 0 0 .5em;
                }
            }
        }

        h3 {
            padding-bottom: 0;
        }
    }

    .section-how {
        .button {
            margin:        -.7em 0 1.4em;
            border-radius: 2em;
        }
    }

    .section-tips {
        padding-left:  .5em;
        padding-right: .5em;

        .content {
            padding-bottom:   2em;
            border-radius:    1em;
            background-color: $hiper-lightgray;
        }

        ul {
            margin-top:     -.35em;
            padding-bottom: .7em;

            li {
                position:       relative;
                padding-left:   1.5em;
                padding-bottom: .35em;

                &::before {
                    @include  font-weight-variable(900);
                    content:  '\2022';
                    position: absolute;
                    left:     0;
                    color:    $hiper-red;
                }

                &:last-child {padding-bottom: 0;}
            }
        }
    }

    .hardware-specifications-table {
        td, th {
            border:           0;
            background-color: #ffffff;
            line-height:      1.4;
            color:            $hiper-blue;
        }

        tr {
            &:first-child,
            &:last-child {
                td, th {border-radius: 0;}
            }

            &:nth-child(odd) {
                td, th {background-color: $hiper-lightergray;}
            }

            td, th {border-top: 1px solid $hiper-gray;}

            &:last-child {
                td, th {border-bottom: 1px solid $hiper-gray;}
            }
        }

        th {
            @include font-weight-variable(500);
            padding: .5em .25em .5em .5em;
        }

        td {padding: .5em .5em .5em .25em;}
    }

    .section-cta {
        .button {
            display:       block;
            margin:        0 auto;
            padding:       .5em 1em .3em;
            width:         fit-content;
            border-radius: .5em;
            font-size:     1.6em;
            hyphens:       manual;
            line-height:   1.2;

            .no-wrap {white-space: nowrap;}
        }
    }
}

// Region: 5G / MBB

.page-products-mobile,
.page-products-tdc5g {
    .hero-container.hero-container--5g-internet {
        @media (min-width: 801px) {
            &.without-bottom-fade .hero-background-wrapper::after {
                content: none;
            }

            .hero-background {
                background-image: imgurl('campaigns/mbb/5g-hero-background-desktop.jpg');
            }

            .hero-content {
                align-items:   flex-start;
                margin-bottom: 1em;

                @media (min-width: 1601px)                         {max-width: 52em;}
                @media (max-width: 1600px) and (min-width: 1201px) {max-width: 60%;}
                @media (max-width: 1200px)                         {max-width: 75%;}
            }
        }

        @media (max-width: 800px) {
            .hero-background-wrapper::after {
                content:        '';
                position:       absolute;
                z-index:        0;
                top:            0;
                left:           0;
                right:          0;
                bottom:         -1px;
                width:          100%;
                height:         calc(100% + 1px);
                pointer-events: none;
                background:     linear-gradient(0deg,
                    rgba($hiper-darkteal, .8) 8em,
                    rgba($hiper-darkteal, 0) 14em);
            }

            &.without-bottom-fade .hero-background-wrapper::after {
                content: none;
            }

            .hero-background {
                top:              unset;
                transform:        translateX(-50%);
                background-image: imgurl('campaigns/mbb/5g-hero-background-mobile.jpg');
                opacity:          .8;

                @media (min-width: 521px)  {bottom: -25%;}
                @media (max-width: $phone) {bottom: 0;}
            }

            .hero-content {
                align-items:   center;
                padding-left:  .5em;
                padding-right: .5em;

                h1 {
                    text-align: center;

                    & > span {
                        margin-left:  auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}

.mbb-hiper-quality .content,
.mbb-steps .content,
.mbb-includes .content,
.mbb-fact-box,
.mbb-router .content,
.mbb-advantages .content,
.mbb-what .content,
.mbb-faq .content {
    padding-top:    2em;
    padding-bottom: 2em;
}

.mbb-hiper-quality {
    // background: linear-gradient(0deg, $hiper-lightgray 0%, #ffffff 100%);
    background-color: $hiper-lightgray;

    .content {
        display:         flex;
        justify-content: center;
        align-items:     center;

        img {
            flex-shrink: 0;
            width:       12em;
            height:      12em;
            filter:      drop-shadow(var(--box-shadow));
        }
    }

    h2 {
        padding-bottom: .3em;

        .large {font-size: 1.4em;}
    }
}

.mbb-steps,
.mbb-advantages {
    .box-container, .box {
        display:         flex;
        justify-content: center;
        align-items:     center;
    }
}

.mbb-steps {
    h2 {padding-bottom: 1em;}

    .box-container {
        flex-wrap: wrap;
        gap:       2em;
    }

    .box {
        flex-direction: column;
        gap:            .5em;

        .image-container {
            position: relative;

            img {
                display:       block;
                border-radius: 1em;
                box-shadow:    var(--box-shadow);
            }

            .number {
                @include font-weight-variable(900);
                position:    absolute;
                top:         0;
                left:        .4em;
                font-size:   3em;
                line-height: 1.4;
                color:       #ffffff;
                text-shadow: var(--box-shadow-small);
            }
        }

        p {
            @include font-weight-variable(500);
            text-align: center;
        }
    }
}

.mbb-includes,
.mbb-what {
    .item {
        display:         flex;
        justify-content: center;
        align-items:     center;
        margin-bottom:   2em;

        &:last-child {margin-bottom: 0;}

        img {
            flex-shrink: 0;
            box-shadow:  var(--box-shadow);
        }
    }
}

.mbb-includes {
    h2 {padding-bottom: 1em;}

    img {
        max-width:     24em;
        width:         100%;
        border-radius: 1em;
    }
}

.mbb-fact-box {
    .content {
        border-radius:    1em;
        background-color: $hiper-teal;
        box-shadow:       var(--box-shadow);
        color:            #ffffff;
    }
}

.mbb-router {
    background-color: $hiper-teal;
    color:            #ffffff;

    h2 {padding-bottom: .5em;}

    .text-image-container {
        display:         flex;
        justify-content: center;
        align-items:     center;

        .img {
            display:     block;
            flex-shrink: 0;
        }
    }
}

.mbb-advantages {
    h2 {padding-bottom: .5em;}

    .box-container {
        flex-direction: column;
    }

    .box {
        border-radius: 1em;
        box-shadow:    var(--box-shadow);
    }

    .number {
        @include font-weight-variable(700);
        flex-shrink: 0;
        width:       .6em;
        font-size:   3em;
        text-align:  center;
        color:       $hiper-red;
    }
}

.mbb-faq {
    h2 {padding-bottom: .5em}
}

.mbb-coverage {
    .content:first-child {
        padding-top:    2em;
        padding-bottom: 0;
    }

    .content:last-child {
        padding-top:    0;
        padding-bottom: 2em;
    }
}

// End region: 5G / MBB

// Region: Media Queries

@media (min-width: 751px) {
    .mbb-hiper-quality .content {
        gap: 2em;
    }

    .mbb-includes,
    .mbb-what {
        .item {
            gap: 2em;

            &.alt {
                flex-direction: row-reverse;
            }
        }
    }

    .mbb-fact-box {
        .content {
            display:         flex;
            justify-content: center;
            align-items:     center;
            gap:             2em;
            padding:         1.5em 2em;

            .icon {
                flex-shrink: 0;
                display:     block;
                width:       5em;
                height:      5em;
                fill:        #ffffff;
                color:       transparent;

                svg {transform: scale(1.5);}
            }
        }
    }

    .mbb-router {
        .text-image-container {
            gap: 2em;

            .hide-on-large-devices {display: none;}

            img {max-height: 50vh;}
        }
    }

    .mbb-advantages {
        .box-container {
            gap: 1.25em;
        }

        .box {
            gap:     1.5em;
            padding: 1em 1.5em;
        }
    }

    .mbb-coverage iframe {
        min-height: 560px;
        height:     60vh;
    }
}

@media (max-width: 750px) {
    .mbb-hiper-quality .content {
        flex-direction: column-reverse;
        gap:            1em;
    }

    .mbb-steps .box-container,
    .mbb-includes .item,
    .mbb-what .item {
        flex-direction: column;
    }

    .mbb-includes,
    .mbb-what {
        .item {gap: .7em;}
    }

    .mbb-fact-box {
        .content {
            padding: 1em;

            .icon {display: none;}
        }
    }

    .mbb-router {
        .text-image-container {
            flex-direction: column;
            gap:            1em;

            .hide-on-small-devices {display: none;}

            img {
                max-width:     24em;
                width:         100%;
                border-radius: 1em;
                box-shadow:    var(--box-shadow);
            }
        }
    }

    .mbb-advantages {
        .box-container {
            gap: 1em;
        }

        .box {
            gap:     1em;
            padding: 1em;
        }
    }

    .mbb-coverage iframe {
        height: calc(100vh - 3.75em - 2.5em - 56px - 40px - 3em);
    }
}

@media (min-width: 661px) {
    .page-products-fiber,
    .page-products-coax {
        .section-includes .grid:first-of-type li:first-child img {
            max-height: 20em;
        }
    }

    .page-products-dsl .section-includes .grid:first-of-type li:first-child img {
        max-height: 18em;
    }
}

@media (max-width: 660px) {
    .page-products-fiber .section-includes .grid:first-of-type li:first-child img {
        max-width: 16em;
        width:     75%;
    }

    .page-products-coax .section-includes .grid:first-of-type li:first-child img {
        max-width: 6em;
    }

    .page-products-dsl .section-includes .grid:first-of-type li:first-child img {
        max-width: 12em;
        width:     60%;
    }
}

@media (min-width: 521px) {
    .hero-container.hero-container--products {
        padding: 4em 0 1.5em 0;
    }
}

@media (max-width: $phone) {
    .hero-container.hero-container--products {
        padding: 3em 0 1.5em 0;

        .hero-content h2 {
            font-size: 3em;
        }
    }

    .page-products-wifi-mesh {
        .blurb {padding-bottom: .5em;}

        .section-reasons ul {
            gap: .75em;
        }
    }
}